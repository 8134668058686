import AverageScoresSummary from "../AverageScoresSummary";
import BarChart, { BarChartProps } from "./BarChart";

function HistogramChart({
  widget,
  isExportingImage,
  onBarClick,
}: BarChartProps) {
  return (
    <>
      <BarChart
        layout="VERTICAL"
        widget={widget}
        isExportingImage={isExportingImage}
        onBarClick={onBarClick}
      />
      {widget.datasetAverageScores && (
        <AverageScoresSummary averageScores={widget.datasetAverageScores} />
      )}
    </>
  );
}

export default HistogramChart;
