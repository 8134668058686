import { t } from "i18next";
import CheckboxTreeApiResponseDto from "../../types/dtos/admin/CheckboxTreeApiResponseDto";
import { apiClient } from "../apiClient";

class jobTitleApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the job titles ready for a checkbox tree component */
  getJobTitleCheckboxTreeItemsByClientId(
    onSuccess: (jobTitles: CheckboxTreeApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/user/get-job-title-checkbox-tree-items-by-client-id"
    ).then(
      (data) => {
        const apiResponse = data as CheckboxTreeApiResponseDto;
        // Translate the labels
        apiResponse.nodes = apiResponse.nodes.map((node) => ({
          ...node,
          label: t(node.label),
        }));

        // Sort the labels
        apiResponse.nodes = apiResponse.nodes.filter(x => x.label != null).sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        // Return the response
        onSuccess(apiResponse);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default jobTitleApi;
