import { useTranslation } from "react-i18next";
import { ReactComponent as NoResultsImage } from "../../images/noTasks.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import taskTypeIconHelper from "../../helpers/taskTypeIconHelper";
import { advancedTaskHelper } from "../../helpers";

interface DashboardTaskBarEmptyStateProps {
  itemType: string;
  selectedTaskType: ClientTaskType | undefined;
  isJourneyOrCatchUpSelected: boolean;
  navigateToTaskPage(): void;
}

function DashboardTaskBarEmptyState({
  itemType,
  selectedTaskType,
  isJourneyOrCatchUpSelected,
  navigateToTaskPage,
}: DashboardTaskBarEmptyStateProps) {
  const { t } = useTranslation();

  let noJourneyOrCatchUpResultsTranslation = t(
    "DashboardTaskBar.NoResultsToDisplay"
  );
  switch (itemType) {
    case "JOURNEY":
      noJourneyOrCatchUpResultsTranslation =
        noJourneyOrCatchUpResultsTranslation.replace(
          "#ITEM_TYPE#",
          t("DashboardTaskBar.Filters.Journeys").toLowerCase()
        );
      break;
    case "CATCH-UP":
      noJourneyOrCatchUpResultsTranslation =
        noJourneyOrCatchUpResultsTranslation.replace(
          "#ITEM_TYPE#",
          t("DashboardTaskBar.Filters.CatchUps").toLowerCase()
        );
      break;
  }

  let noActiveTaskTypesTranslation = t(
    "DashboardTaskBar.TaskType.YouHaventGotAnyActive"
  );
  if (selectedTaskType) {
    noActiveTaskTypesTranslation = noActiveTaskTypesTranslation.replace(
      "#TASK_TYPE_NAME#",
      advancedTaskHelper.ToLowerCase(selectedTaskType.pluralNameTranslationKeyIdentifier)
    );
  }

  return (
    <div>
      {/* Empty state for Journey and CatchUps */}
      {isJourneyOrCatchUpSelected && (
        <div className="text-center text-gray-400 grow mt-2 pt-1 lg:mt-6">
          <NoResultsImage width={"7rem"} className="block m-auto" />
          <div className="text-sm px-4 pb-4">
            {noJourneyOrCatchUpResultsTranslation}
          </div>
        </div>
      )}
      {/* Empty state for a TaskType */}
      {!isJourneyOrCatchUpSelected && selectedTaskType && (
        <div className="text-center text-gray-400 grow mt-2">
          <div className="mt-3 lg:mt-5 ">
            <FontAwesomeIcon
              color={"#D1D5DB"}
              size="3x"
              icon={taskTypeIconHelper.getTaskTypeIcon(
                selectedTaskType.iconType
              )}
            />
          </div>
          <div className="mt-2 text-sm mb-2">
            <span className="cursor-pointer" onClick={navigateToTaskPage}>
              {noActiveTaskTypesTranslation}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardTaskBarEmptyState;
