import { isDate } from "date-fns";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TimePickerValue } from "react-time-picker";
dayjs.extend(utc);

const dateHelper = {
  /** Get the current UTC date (to take out timezones from times) */
  getCurrentDateUtc: function (): Date {
    return dayjs().utc().toDate();
  },
  /** Gets the current UTC date minus or plus the days from given */
  getDaysFromCurrentDateUtcs: function (daysFrom: number): Date {
    return dayjs.utc().add(daysFrom, "day").toDate();
  },
  /** Gets the current UTC date minus or plus the duration given */
  getFromCurrentDateUtcs: function (daysFrom: number, duration: string): Date {
    return dayjs.utc().add(daysFrom, duration).toDate();
  },
  /** Creates a new date object with a UTC date/time set to be the same as the current locale date's date/time.
   * Useful for things like Date Pickers where the user selects a specific date at midnight,
   * but the underlying UTC date is an hour before, because the selected date was GMT
   * !!Potentially redundant as it looks like local dates get converted to UTC when using JSON.stringify to pass to the server!!
   */
  convertDateToUtc: function (localDate: Date): Date {
    // Format the local date into the value that was chosen
    const formattedLocalDate = dayjs(localDate).format("YYYY-MM-DDTHH:mm:ss");
    // Construct a new UTC date which matches the input date/time
    const utcDate = dayjs(formattedLocalDate).utc();
    //console.log("ISO date check", JSON.stringify({originalUtcDate: localDate, editedUtcDate: utcDate.toDate()}));
    return utcDate.toDate();
  },

  /** Converts a string to UTC*/
  convertDateFromStringToUtc: function (inputDate: string | Date): Date {
    return dayjs.utc(inputDate).toDate();
  },

  /** Converts a string to a date*/
  convertDateFromString: function (
    inputDate: string | Date | null | undefined
  ): Date | null {
    if (!inputDate) return null;
    return dayjs(inputDate).toDate();
  },

  /** Converts a UTC date to a local date */
  convertUtcDateToLocal: function (utcDate: Date): Date {
    const dateObj = dayjs.utc(utcDate);
    if (dateObj.isUTC()) {
      return dateObj.local().toDate();
    }
    return dateObj.toDate();
  },

  /** Converts a date object into yyyy-MM-dd ready for sending to the API.
   * This should keep the local date, so removes any timezone offset (and avoids UTC conversion).
   * Useful when choosing a date from a datepicker and sending to the API.
   */
  convertDateObjectToServerString: function (date: Date | null): string | null {
    if (!date || !isDate(date)) return null;

    // Pad the date with zeros, e.g. for 1st Jan 2021, we want 2021-01-01, not 2021-1-1
    const zeroPad = (num: number, places: number) =>
      String(num).padStart(places, "0");

    return (
      date.getFullYear() +
      "-" +
      zeroPad(date.getMonth() + 1, 2) +
      "-" +
      zeroPad(date.getDate(), 2)
    );
  },

  /** Validates a time value */
  timeIsValid: function (
    inputValue: string | TimePickerValue | undefined
  ): boolean {
    if (inputValue === undefined || inputValue === null) return false;
    if (
      inputValue.toString().length === 5 &&
      inputValue.toString().charAt(2) === ":"
    )
      return true;
    return false;
  },
  /** Takes a selected date and time, and merges them into one object */
  combineDateAndTime: function (
    inputDate: Date,
    inputTime: string | TimePickerValue
  ): Date | null {
    if (!inputDate || !inputTime || inputTime.toString().length === 0)
      return null;

    if (!this.timeIsValid(inputTime)) return null;

    const timeParts = inputTime.toString().split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    const output = new Date(inputDate);
    output.setHours(hours);
    output.setMinutes(minutes);
    output.setSeconds(0);
    return output;
  },
};

export default dateHelper;
