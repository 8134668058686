import FilterEditIcon from "../analytics/filters/FilterEditIcon";
import { Badge } from "../common";
import SearchFilterInputs from "../../types/admin/SearchFilterInputs";
import SearchField from "../common/SearchField";
import React from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../state/UserContext";
import AdminFilters from "./AdminFilters";
import { faFilters } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SearchAndFiltersProps {
  isLoading: boolean;
  searchTerm?: string | null;
  inEditMode: boolean;
  selectedValues: SearchFilterInputs;
  onBeginEditMode(): void;
  handleSearchTermChange(searchTerm: string | null): void;
  onLocationPickerChange: (selectedLocationIds: number[]) => void;
  onJobTitlePickerChange: (selectedJobtitleIds: number[]) => void;
  onAppraisalLevelPickerChange: (selectedAppraisalLevelIds: number[]) => void;
  onEmployeeStatusPickerChange: (selectedEmployeeStatusIds: number[]) => void;
  onActiveOnlyChange: (newValue: boolean) => void;
  onApplyFilters(): void;
  onResetFilters(): void;
}

function SearchAndFilters({
  isLoading,
  searchTerm,
  inEditMode,
  selectedValues,
  onBeginEditMode,
  handleSearchTermChange,
  onLocationPickerChange,
  onJobTitlePickerChange,
  onAppraisalLevelPickerChange,
  onEmployeeStatusPickerChange,
  onActiveOnlyChange,
  onApplyFilters,
  onResetFilters
}: SearchAndFiltersProps) {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);

  let filterCount = 0;
  if (selectedValues.locationIds?.length ?? 0 > 0) {
    filterCount++;
  }
  if (selectedValues.jobTitleIds?.length ?? 0 > 0) {
    filterCount++;
  }
  if (selectedValues.appraisalLevelIds?.length ?? 0 > 0) {
    filterCount++;
  }
  if (selectedValues.employeeStatusIds?.length ?? 0 > 0) {
    filterCount++;
  }

  return (
    <div className="mt-2">
      <h4 className="mb-2 text-gray-600 text-sm">{t("Pages.Admin.Common.SelectUserToManageJourneys")}</h4>
      <div className="flex flex-col pb-4 gap-x-6 mt-2 mb-1 px-2 py-1 lg:px-4 lg:py-2 bg-gray-100 rounded-md md:pb-0 md:flex-row">
        {!inEditMode && (
          <>
            <div className="basis-5/6">
              <div className="mb-1 text-gray-500">
                <small>
                {t("Pages.Admin.Journeys.Search")}
                </small>
              </div>
              <SearchField
                onSearch={handleSearchTermChange}
                isLoading={isLoading}
                loaderColor={userContext.user.client.accentHexColour}
                searchTerm={searchTerm}
                includeSetWidth={false}
              />
            </div>
            <div className="basis-1/6">
              <div className="mb-1 text-gray-500">
                <small>
                {t("Pages.Admin.Journeys.AdvancedFilters")}
                </small>
              </div>
              <button className="mt-1 group" onClick={onBeginEditMode}>
                <FontAwesomeIcon icon={faFilters} />
                <Badge
                  text={filterCount.toString()}
                  backgroundColourClassName="accent-bg"
                  textColourClassName="text-white"
                />
                <span>{filterCount === 1 ? "Filter" : "Filters"}</span>
                <FilterEditIcon extraClassNames="ml-2" />
              </button>
            </div>
          </>
        )}
        {inEditMode && (
          <div className="grow">
            <div className="mb-1 text-gray-500">
              <small>
                {t("Pages.Admin.Journeys.AdvancedFilters")}
              </small>
            </div>
            <AdminFilters
              selectedValues={selectedValues}
              onLocationPickerChange={onLocationPickerChange}
              onJobTitlePickerChange={onJobTitlePickerChange}
              onAppraisalLevelPickerChange={onAppraisalLevelPickerChange}
              onEmployeeStatusPickerChange={onEmployeeStatusPickerChange}
              onActiveOnlyChange={onActiveOnlyChange}
              onApplyFilters={onApplyFilters}
              onResetFilters={onResetFilters}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchAndFilters;
