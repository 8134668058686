import { useContext, useState } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import DashboardSummaryTabDto from "../../types/dtos/dashboards/DashboardSummaryTabDto";
import { AlertPopup } from "../common";
import DualPrepFormSummaryButtons from "./DualPrepFormSummaryButtons";
import StandardFormSummaryButtons from "./StandardFormSummaryButtons";
import CollaborativeFormSummaryContent from "./CollaborativeFormSummaryContent";
import FormSummaryEmptyStandardState from "./FormSummaryEmptyStandardState";
import GoalFormSummaryContent from "./GoalFormSummaryContent";
import BehaviourFormSummaryContent from "./BehaviourFormSummaryContent";
import FormSummaryLoadingCard from "./FormSummaryLoadingCard";
import FormHistoryPopup from "./FormHistoryPopup";
import ActiveStandardDocumentStatusBadge from "./ActiveStandardDocumentStatusBadge";
import ActiveDualPrepDocumentStatusBadge from "./ActiveDualPrepDocumentStatusBadge";
import UserContext from "../../state/UserContext";
import { ManagerDashboardPersonDto } from "../../types/dtos/dashboards";
import { EditableTask } from "../../types/tasks/EditableTasks";
import { TaskType } from "../../types/tasks";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import FormSummaryEmptyDualPrepState from "./FormSummaryEmptyDualPrepState";
import AppRoutes from "../../routes/AppRoutes";
import dualPrepJourneyHelper from "../../helpers/dualPrepJourneyHelper";
import managerDashboardApi from "../../api/dashboard/managerDashboardApi";
import { useAuth } from "react-oidc-context";

interface FormSummaryProps {
  isManagerDashboard?: boolean;
  summaryTabData?: DashboardSummaryTabDto | null;
  user?: ManagerDashboardPersonDto;
  /** A function to call when the user has selected a different journey to complete from within a summary,
   * or the manager has chosen to update this section for their team member */
  onTriggerSectionUpdate?(
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | undefined | null
  ): void | undefined;
  /**A method which calls the api to save a Goal/Learning/Action */
  modifyTaskFromDashboard?(
    task: EditableTask<number>,
    onSuccess: () => void
  ): void;
  /**A method to call the api and load the task that has been clicked on in the timeline widget */
  onLoadGoalToEdit?(
    taskId: number,
    taskType: TaskType,
    successCallback: (data: ModifyTaskResponseDto<number>) => void
  ): void;
  handleSummaryRefresh?(): void;
}

const FormSummary = ({
  summaryTabData,
  isManagerDashboard = false,
  user = undefined,
  onTriggerSectionUpdate,
  modifyTaskFromDashboard,
  onLoadGoalToEdit,
  handleSummaryRefresh,
}: FormSummaryProps) => {
  // Context
  const auth = useAuth();
  const manDashboardApi = new managerDashboardApi(auth.user?.access_token);
  const userContext = useContext(UserContext);

  // Flag for when its the manage dashboard and the person viewing is the user's manager but yet its been delegated
  const isManagerButBeenDelegated =
    isManagerDashboard &&
    userContext.user.id == user?.managerId &&
    user.isDelegated;

  // Get the most relevant answer set guid for the dual prep form for this user
  // e.g. if the prep is still open go to that, else its the meeting doc
  const openAnswerSetGuidForDualPrep =
    summaryTabData?.dualPrepAnswerSet?.collabDocAnswerSetId ||
    (isManagerDashboard
      ? summaryTabData?.dualPrepAnswerSet?.managerPrepAnswerSetUniqueId
      : summaryTabData?.dualPrepAnswerSet?.employeePrepAnswerSetUniqueId);

  const navigate = useNavigate();

  // State
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState<boolean>(false);
  const [displayNoManagerAlert, setDisplayNoManagerAlert] =
    useState<boolean>(false);

  const getDualPrepJourneyRef = () => {
    let output = summaryTabData?.dualPrepAnswerSet?.journeyReference;
    if (
      !output &&
      summaryTabData?.clientSentJourneyDetails?.isDualPrep === true
    ) {
      output = summaryTabData.clientSentJourneyDetails.journeyReference;
    }
    return output;
  };

  const handleShowFormButtonClick = () => {
    if (!isManagerDashboard && !userContext.user.hasManager) {
      // If not the manager dashboard, and the user doesn't have a manager, they can't do a journey
      setDisplayNoManagerAlert(true);
      return;
    }

    // Manager is first to start dual prep planning, no existing dual prep answer set... so uses CurrentJourneyRef
    if (ManagerIsFirstToStartDualPrepPlanning()) {
      createNewManagerPlanningForDualPrepJourney(user?.userId, user?.currentJourneyReference);
    }
    // Manager is starting their prep but employee has already started dual prep answer set
    else if (ManagerNeedsToStartPrepOnExistingDualPrepAnswerSet()) {
      createNewManagerPlanningForDualPrepJourney(summaryTabData?.dualPrepAnswerSet?.subjectUserId, summaryTabData?.dualPrepAnswerSet?.journeyReference);
    }
    // There is an incomplete dual prep answer set but an open answer set the user can answer so go to that
    else if (DualPrepHasAnOpenAnswerSet()) {
      const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
        openAnswerSetGuidForDualPrep!,
        false,
        null
      );
      navigate(redirectUrl);
    }
    // Else if there is a trigger section update function with a valid clientFormId we need to call it
    else if (onTriggerSectionUpdate && summaryTabData?.clientFormId) {
      const dualPrepJourneyRef = getDualPrepJourneyRef();
      onTriggerSectionUpdate(summaryTabData, dualPrepJourneyRef);
    }
  };

  const createNewManagerPlanningForDualPrepJourney = (employeeId?: number | null, journeyReference?: string | null) => {
    if (employeeId != null && journeyReference != null) {
      const dualPrepHelper = new dualPrepJourneyHelper(manDashboardApi, userContext);
      dualPrepHelper.openNewCollabDocByJourneyRef(
        employeeId,
        journeyReference,
        (destinationUrl: string) => {
          navigate(destinationUrl);
        });
    }
  }

  // Conditions for when the manager is starting the prep for their employee, but before the employee has 
  // completed theirs and the dual prep answer set doesn't exist yet.
  const ManagerIsFirstToStartDualPrepPlanning = (): boolean => {
    return isManagerDashboard // Is manager dashboard
      && summaryTabData?.isDualPrepForm == true // Is dual prep form
      && summaryTabData?.dualPrepAnswerSet == null // No dual prep answer set yet
      && user?.currentJourneyClientFormIds?.includes(summaryTabData?.clientFormId) == true;
    // The client form id of this summary tab is contained within the ClientFormIds currently assigned to the users' CurrentJourneyReference
  }

  // Conditions for when the manager is starting the prep for their employee, after the employee has already
  // completed their prep and the dual prep answer set exists.
  const ManagerNeedsToStartPrepOnExistingDualPrepAnswerSet = (): boolean => {
    return isManagerDashboard // Is manager dashboard
      && summaryTabData?.isDualPrepForm == true // Is dual prep form
      && summaryTabData?.dualPrepAnswerSet != null // Has an incomplete dual prep answer set 
      && openAnswerSetGuidForDualPrep == null; // No open answer set, e.g. no open manager planning
  }

  // Conditions for when either user clicks the button with an incomplete dual prep but an open answer set
  const DualPrepHasAnOpenAnswerSet = (): boolean => {
    return summaryTabData?.isDualPrepForm == true // Is dual prep form
      && summaryTabData?.dualPrepAnswerSet != null // Has an incomplete dual prep answer set 
      && openAnswerSetGuidForDualPrep != null; // Has an open answer set, e.g. manager planning
  }

  const openTabHistory = () => {
    setHistoryModalIsOpen(true);
  };

  const closeTabHistory = () => {
    setHistoryModalIsOpen(false);
  };

  const closeNoManagerAlert = () => {
    setDisplayNoManagerAlert(false);
  };

  const hasHistoryItems: boolean =
    summaryTabData &&
      summaryTabData.history &&
      summaryTabData.history.length > 0
      ? true
      : false;

  const collabDocStatusBadgeComponent = summaryTabData?.dualPrepAnswerSet ? (
    <ActiveDualPrepDocumentStatusBadge
      dualPrepAnswerSetId={summaryTabData?.dualPrepAnswerSet?.id} // Id used here rather than GuidId due to it not actually being used apart from a check if it exists
      participants={summaryTabData?.dualPrepAnswerSet?.participants}
      status={summaryTabData?.dualPrepAnswerSet?.collabDocAnswerSetStatus} // This is used when its in the final stage, prep/planning is changed with props below
      subjectUserId={summaryTabData?.dualPrepAnswerSet?.subjectUserId}
      dateLastCompleted={summaryTabData.lastCompletedDate}
      isDualPrepEmpPlanningCompleted={
        summaryTabData?.dualPrepAnswerSet?.employeePrepCompleted
      }
      isDualPrepManPlanningCompleted={
        summaryTabData?.dualPrepAnswerSet?.managerPrepCompleted
      }
      isManagerButBeenDelegated={isManagerButBeenDelegated}
    />
  ) : (
    <ActiveStandardDocumentStatusBadge
      answerSetUniqueId={summaryTabData?.standardAnswerSet?.uniqueId}
      participants={summaryTabData?.standardAnswerSet?.participants}
      status={summaryTabData?.standardAnswerSet?.status}
      subjectUserId={summaryTabData?.standardAnswerSet?.subjectUserId}
      dateLastCompleted={summaryTabData?.lastCompletedDate}
      isManagerButBeenDelegated={isManagerButBeenDelegated}
    />
  );

  let formTitle = summaryTabData ? summaryTabData?.clientFormTitle : "";
  if (summaryTabData?.clientSentJourneyDetails) {
    formTitle = summaryTabData.clientSentJourneyDetails.title;
  }

  // If either standard/dualprep has been started, show the condition
  let showStatusBadgeCondition =
    summaryTabData?.standardAnswerSet?.uniqueId ||
      summaryTabData?.dualPrepAnswerSet?.id
      ? true
      : false;
  if (isManagerButBeenDelegated) {
    showStatusBadgeCondition = false;
  }

  const emptyStateComponent = summaryTabData?.dualPrepAnswerSet ? (
    <FormSummaryEmptyDualPrepState
      dualPrepAnswerSet={summaryTabData?.dualPrepAnswerSet}
      onButtonClick={handleShowFormButtonClick}
      formTitle={formTitle}
      incompleteAnswerSetUniqueId={summaryTabData?.standardAnswerSet?.uniqueId}
      isManagerDashboard={isManagerDashboard ? isManagerDashboard : false}
      activeDocumentStatusBadge={collabDocStatusBadgeComponent}
      showStatusBadge={showStatusBadgeCondition}
      isManagerViewingButHasBeenDelegated={isManagerButBeenDelegated}
      delegatedManagerName={user?.delegatedManagerName}
    />
  ) : (
    <FormSummaryEmptyStandardState
      onButtonClick={handleShowFormButtonClick}
      formTitle={formTitle}
      incompleteAnswerSetUniqueId={summaryTabData?.standardAnswerSet?.uniqueId}
      isManagerDashboard={isManagerDashboard ? isManagerDashboard : false}
      activeDocumentStatusBadge={collabDocStatusBadgeComponent}
      showStatusBadge={showStatusBadgeCondition}
      isManagerViewingButHasBeenDelegated={isManagerButBeenDelegated}
      delegatedManagerName={user?.delegatedManagerName}
      hideButton={
        isManagerButBeenDelegated &&
        summaryTabData?.standardAnswerSet?.uniqueId == null
      }
    />
  );

  const formSummaryButtonStateComponent = summaryTabData?.dualPrepAnswerSet ? (
    <DualPrepFormSummaryButtons
      formName={formTitle}
      dualPrepAnswerSet={summaryTabData?.dualPrepAnswerSet}
      incompleteAnswerSetUniqueId={openAnswerSetGuidForDualPrep}
      hasHistoryToShow={hasHistoryItems}
      onUpdateButtonClick={handleShowFormButtonClick}
      onHistoryButtonClick={openTabHistory}
      isManagerDashboard={isManagerDashboard ? isManagerDashboard : false}
      isManagerViewingButHasBeenDelegated={isManagerButBeenDelegated}
      hideButton={
        isManagerButBeenDelegated &&
        summaryTabData?.standardAnswerSet?.uniqueId == null
      }
    />
  ) : (
    <StandardFormSummaryButtons
      formName={formTitle}
      incompleteAnswerSetUniqueId={summaryTabData?.standardAnswerSet?.uniqueId}
      hasHistoryToShow={hasHistoryItems}
      onUpdateButtonClick={handleShowFormButtonClick}
      onHistoryButtonClick={openTabHistory}
      isManagerDashboard={isManagerDashboard ? isManagerDashboard : false}
      isManagerViewingButHasBeenDelegated={isManagerButBeenDelegated}
      hideButton={
        isManagerButBeenDelegated &&
        summaryTabData?.standardAnswerSet?.uniqueId == null
      }
    />
  );

  const showEmptyState = !summaryTabData?.formData && !summaryTabData?.goalData;

  return (
    <div className="basis-full md:basis-1/2">
      <div className="rounded bg-white">
        {/* COLLABORATIVE Form */}
        <div>
          {summaryTabData &&
            summaryTabData.tabType === "CLIENT-FORM-COLLABORATIVE" && (
              <div>
                {summaryTabData.formData ? (
                  <CollaborativeFormSummaryContent
                    formData={summaryTabData.formData}
                  />
                ) : (
                  emptyStateComponent
                )}
              </div>
            )}
        </div>

        {/* CLIENT-FORM-EMPLOYEE-ONLY Form */}
        <div>
          {summaryTabData &&
            summaryTabData.tabType === "CLIENT-FORM-EMPLOYEE-ONLY" && (
              <div>
                {summaryTabData.formData ? (
                  <CollaborativeFormSummaryContent
                    formData={summaryTabData.formData}
                  />
                ) : (
                  emptyStateComponent
                )}
              </div>
            )}
        </div>

        {/* DUAL PREP Form */}
        <div>
          {summaryTabData &&
            summaryTabData.tabType === "CLIENT-FORM-DUAL-PREP" && (
              <div>
                {summaryTabData.formData ? (
                  <CollaborativeFormSummaryContent
                    formData={summaryTabData.formData}
                  />
                ) : (
                  emptyStateComponent
                )}
              </div>
            )}
        </div>

        {/* GOALS Form */}

        {summaryTabData && summaryTabData.tabType === "GOALS" && (
          <div>
            <GoalFormSummaryContent
              goalData={summaryTabData.goalData}
              isManagerDashboard={isManagerDashboard}
              handleSummaryRefresh={handleSummaryRefresh}
              modifyTaskFromDashboard={modifyTaskFromDashboard}
              onLoadGoalToEdit={onLoadGoalToEdit}
            />
          </div>
        )}

        {/* BEHAVIOURS Form */}
        {summaryTabData && summaryTabData.tabType === "BEHAVIOURS" && (
          <div>
            {summaryTabData.formData ? (
              <BehaviourFormSummaryContent
                behaviourData={summaryTabData.formData?.behaviours}
              />
            ) : (
              emptyStateComponent
            )}
          </div>
        )}
        {!summaryTabData && <FormSummaryLoadingCard />}
      </div>
      {summaryTabData && !showEmptyState && (
        <div>
          <div className="text-center md:text-right">
            {collabDocStatusBadgeComponent}
          </div>
          <div className="flex flex-col my-2">
            <div className="flex flex-row-reverse gap-3">
              {formSummaryButtonStateComponent}
            </div>
          </div>
        </div>
      )}
      {hasHistoryItems && (
        <FormHistoryPopup
          isOpen={historyModalIsOpen}
          historyItems={summaryTabData?.history}
          onButtonClick={closeTabHistory}
          onOpenChange={setHistoryModalIsOpen}
        />
      )}
      <AlertPopup
        isOpen={displayNoManagerAlert}
        onOpenChange={setDisplayNoManagerAlert}
        onPrimaryButtonClick={closeNoManagerAlert}
        primaryButtonText={t("Common.OK")}
        bodyText="You don't have a manager assigned in the system. Talk to your HR team, once you have a manager assigned, you can complete this Journey"
        title="Not so fast..."
      />
    </div>
  );
};

export default FormSummary;
