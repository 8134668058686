import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalPopup from "./ModalPopup";

interface ErrorPopupProps {
  error: any;
  showPopup: boolean;
  onTogglePopup(open: boolean): void;
  customTitle?: string | null;
  customClickEvent?(): void | undefined;
}

/** A wrapper around ModalPopup for a common use-case */
const ErrorPopup = ({
  error,
  showPopup,
  onTogglePopup,
  customTitle = null,
  customClickEvent = undefined,
}: ErrorPopupProps) => {
  const [showErrorDetails, setShowErrorDetails] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (customClickEvent) {
      // Run the custom click event if there is one
      customClickEvent();
    } else {
      // Close the popup
      onTogglePopup(false);
    }
  };

  const buttonText =
    "Errors.GenericPopup." + (showErrorDetails ? "HideError" : "ShowError");

  return (
    <ModalPopup
      isOpen={showPopup}
      onOpenChange={onTogglePopup}
      primaryButtonText={t("Common.OK")}
      onPrimaryButtonClick={handleButtonClick}
      title={customTitle ? customTitle : t("Errors.GenericPopup.Title")}
    >
      <p>{t("Errors.GenericPopup.Body")}</p>
      <button
        className="my-2 text-xs"
        onClick={() => setShowErrorDetails(!showErrorDetails)}
      >
        {t(buttonText)}
      </button>
      {showErrorDetails && (
        <>
          <p className="text-xs italic">{error}</p>
        </>
      )}
    </ModalPopup>
  );
};

export default ErrorPopup;
