import { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import cx from "classnames";
import produce from "immer";
import { debounce } from "lodash";
import {
  DevelopmentListGroup,
  DevelopmentOtherItemForm,
  SelectedDevelopmentItem,
} from "./development";
import {
  BaseSelectableItem,
  FormQuestion,
  FormType,
  QuestionAnswer,
  QuestionTasks,
  ValidationResult,
} from "../../../types/forms";
import { DevelopmentListParentDto } from "../../../types/dtos/forms/DevelopmentOptionsDto";
import { stringHelper } from "../../../helpers";
import DevelopmentQuestionAnswerValue from "../../../types/forms/DevelopmentQuestionAnswerValue";
import AddFormTaskControl from "./AddFormTaskControl";
import ValidationWarning from "../../common/ValidationWarning";
import { Accordion, Badge } from "../../common";
import { AccordionItem } from "../../common/Accordion";

interface DevelopmentQuestionProps {
  /** Whether this question is being displayed in a collab doc or a journey. Controls styling (light vs dark bg) */
  formType: FormType;
  question: FormQuestion;
  /** The id of the client form */
  formId: number;
  /** The current select value(s) for the L&D question (including custom "Other" items) */
  currentValues: DevelopmentQuestionAnswerValue[] | undefined;
  isReadOnly: boolean;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** Any tasks added against this question */
  tasks: QuestionTasks | null;
  usesAdvancedTaskTypes: boolean;
  /** Notify the parent component(s) about a changed answer within this L&D question */
  onValueChange(newState: DevelopmentQuestionAnswerValue[]): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(questionTasks: QuestionTasks): void;
  /** An event to call when changes are committed (i.e. should be saved via the API) */
  onBlur?(
    answers: DevelopmentQuestionAnswerValue[],
    nonStateValue?: QuestionAnswer | null
  ): void | undefined;
}

/** Learning and Development question */
function DevelopmentQuestion({
  formType,
  question,
  formId,
  currentValues,
  isReadOnly,
  tasks,
  usesAdvancedTaskTypes,
  showValidationErrors,
  validationResult = null,
  onValueChange,
  onChangeQuestionTasks,
  onBlur,
}: DevelopmentQuestionProps) {
  let textColourClass = "text-white";
  let placeHolderColourClass = "placeholder-white";
  let accordionHeadingClassNames = "text-white";
  let accordionButtonClassNames = "text-white border-none";
  let accordionContainerBgClass =
    " border-0 bg-white/10 p-2 rounded-md mb-2 p-2";
  let accordionPrimaryTextColorClass = "text-white";
  if (formType !== "JOURNEY") {
    textColourClass = "text-gray-300";
    placeHolderColourClass = "placeholder-gray-300";
    accordionHeadingClassNames = "text-gray-500";
    accordionButtonClassNames = "bg-gray-100";
    accordionContainerBgClass = " border-0 bg-gray-200 rounded-md mb-2 p-2";
    accordionPrimaryTextColorClass = "text-gray-500";
  }

  // State
  const [displayItems, setDisplayItems] = useState<DevelopmentListParentDto[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [activeAccordionSections, setActiveAccordionSections] = useState<
    Array<string>
  >([]);

  const [newTaskPopupTitle, setNewTaskPopupTitle] = useState<string>("");
  const [displayNewTaskModal, setDisplayNewTaskModal] =
    useState<boolean>(false);

  // Setup function
  const getTranslatedDisplayItems = (
    sourceItems: DevelopmentListParentDto[],
    searchTerm: string | null,
    itemsAlreadySelected: DevelopmentQuestionAnswerValue[] | undefined
  ): DevelopmentListParentDto[] => {
    const filterBySearchTerm = searchTerm && searchTerm.trim().length > 0;

    let translatedList = produce(sourceItems, (draft) => {
      draft.forEach((parent) => {
        // Translate the group name
        parent.name = t(parent.name);

        // Translate the visible item names, and set whether they are selected
        parent.items.forEach((item) => {
          item.text = t(item.text);
          item.isSelected = false;

          // If there are any selected items, mark this one as selected if it's in the list
          if (itemsAlreadySelected && itemsAlreadySelected.length > 0) {
            item.isSelected =
              itemsAlreadySelected.findIndex(
                (x) => !x.isCustom && x.itemId === item.value
              ) >= 0;
          }
        });

        // Filter out anything that doesn't match the search term
        if (filterBySearchTerm) {
          parent.items = parent.items.filter((x) =>
            stringHelper.matchesSearch(x.text, searchTerm)
          );
        }
      });
    });

    // Filter out any groups without anything which matches the search term
    if (filterBySearchTerm) {
      translatedList = translatedList.filter((x) => x.items.length > 0);
    }

    return translatedList;
  };

  /** Find an L&D list item by id */
  const findListItemById = (
    searchId: number
  ): BaseSelectableItem | undefined => {
    if (question.developmentOptions) {
      for (
        var iGrp = 0;
        iGrp < question.developmentOptions.listGroups.length;
        iGrp++
      ) {
        const thisGroup = question.developmentOptions.listGroups[iGrp];
        if (thisGroup.items && thisGroup.items.length > 0) {
          const match = thisGroup.items.find((x) => x.value === searchId);
          if (match) {
            return match;
          }
        }
      }
    }

    // No match found
    return undefined;
  };

  // Lifecycle

  // Initial load
  useEffect(() => {
    if (!question.developmentOptions) return;
    const newState = getTranslatedDisplayItems(
      question.developmentOptions.listGroups,
      searchTerm,
      currentValues
    );
    setDisplayItems(newState);
  }, []);

  // Update the displayed items when a selection is changed (to update the selected items list)
  useEffect(() => {
    if (!question.developmentOptions) return;
    const newState = getTranslatedDisplayItems(
      question.developmentOptions.listGroups,
      searchTerm,
      currentValues
    );
    setDisplayItems(newState);
  }, [currentValues]);

  // Events
  const onSearch = (newSearchTerm: string) => {
    if (!question.developmentOptions) return;
    const newState = getTranslatedDisplayItems(
      question.developmentOptions.listGroups,
      newSearchTerm,
      currentValues
    );

    const activeGroupIds = newState.map((g) => g.id.toString());
    onAccordionActiveSectionChange(activeGroupIds);

    setDisplayItems(newState);
    setSearchTerm(newSearchTerm);
  };

  const onAccordionActiveSectionChange = (
    activeSections: string | Array<string>
  ) => {
    setActiveAccordionSections(activeSections as Array<string>);
  };

  // `onChange` handles state changes, `handleBlur` can be called to send the
  // current value to the API to be saved (if an onBlur event is defined)
  const handleBlur = (currentValue: DevelopmentQuestionAnswerValue[]) => {
    if (onBlur) {
      onBlur(currentValue);
    }
  };

  // use the `debouncedHandleBlur` function to avoid sending the possibility
  // of firing multiple onBlur events (potentially causing multiple calls to the API)
  // as users change the value multiple times in quick succession
  const debouncedHandleBlur = useMemo(
    () => debounce(handleBlur, 500),
    [onBlur]
  );

  /** Handle when a user selects an item to add to their selected list */
  const onItemClicked = (
    clickedItem: DevelopmentQuestionAnswerValue,
    selected: boolean
  ): void => {
    const previousAnswerState = currentValues ? currentValues : [];
    const nextSelectedItemsState = produce(previousAnswerState, (draft) => {
      // Check the item isn't already in the selected list, and if not, add it!
      const existingMatchIndex = draft.findIndex(
        (x) =>
          x.isCustom === clickedItem.isCustom &&
          x.itemId === clickedItem.itemId &&
          x.name === clickedItem.name
      );

      if (existingMatchIndex >= 0 && !selected) {
        // Remove from the selection
        draft.splice(existingMatchIndex, 1);
      } else if (existingMatchIndex === -1 && selected) {
        //Add to the selection
        draft.push(clickedItem);
      }
    });

    // Update the state
    onValueChange(nextSelectedItemsState);

    // Call the API to save
    debouncedHandleBlur(nextSelectedItemsState);
  };

  /** When a user attempts to add a custom item */
  const onAddCustomItem = (customItemName: string): void => {
    if (!question.developmentOptions?.allowCustomItems) return;
    // Check the item doesn't already exist as a custom item before adding it

    // Add the selected item to the answer state
    onItemClicked({ isCustom: true, itemId: null, name: customItemName }, true);
  };

  /** When a user clicks to select/de-select an item from the list of pre-defined options */
  const onDevelopmentListItemClicked = (
    clickedItem: BaseSelectableItem,
    selected: boolean
  ): void => {
    onItemClicked(
      {
        isCustom: false,
        itemId: clickedItem.value as number,
        name: clickedItem.text,
      },
      selected
    );
  };

  const onAddTaskClick = (newTaskName: string) => {
    setNewTaskPopupTitle(newTaskName);
    setDisplayNewTaskModal(true);
  };

  const onAddTaskModalClose = () => {
    setNewTaskPopupTitle("");
    setDisplayNewTaskModal(false);
  };

  /** Get the correct, translated display text, taking into account whether or not this is a custom item */
  const getSelectedItemDisplayText = (
    item: DevelopmentQuestionAnswerValue
  ): string => {
    // Return the name prop for custom items, as that's what the user typed in
    if (item.isCustom) {
      return item.name;
    }
    // If it's a list item, return the translated text for that
    else if (item.itemId) {
      const matchingItem = findListItemById(item.itemId);
      if (matchingItem) {
        return t(matchingItem.text);
      }
    }

    // Shouldn't happen, in theory
    return "";
  };

  // If the necessary data isn't supplied, don't render anything
  if (!question.developmentOptions?.listGroups) {
    return null;
  }

  // Get the selectable item count, we'll only display a search field if there are enough to bother searching over
  const minItemsRequiredToShowSearchField = 8;
  const selectableItemCount = question.developmentOptions.listGroups.reduce(
    (a, b) => a + b.items.length,
    0
  );

  const hasSelectedItemsToDisplay = currentValues && currentValues.length > 0;

  const showTasksHeading =
    tasks !== null &&
    tasks.tasks.filter((x) => x.modifyStatus !== "DELETED").length > 0;

  const accordionItemKeys = displayItems.map((grp) => grp.id.toString());

  return (
    <div>
      {!isReadOnly && (
        <>
          {/* Validation errors */}
          {showValidationErrors && validationResult && (
            <ValidationWarning
              isValid={validationResult.isValid}
              errors={validationResult.errors}
            />
          )}
          {/* Search form */}
          {selectableItemCount >= minItemsRequiredToShowSearchField && (
            <div className="flex flex-row-reverse mb-1 print:hidden">
              <div className="flex-initial">
                <input
                  type="text"
                  placeholder={`${t("Common.Search")}...`}
                  value={searchTerm}
                  onChange={(e) => onSearch(e.target.value)}
                  className={cx(
                    textColourClass,
                    placeHolderColourClass,
                    "text-sm border-0 border-b border-b-gray-200 py-1 bg-transparent focus:outline-none focus:ring-0 focus:border-b-gray-200"
                  )}
                />
              </div>
            </div>
          )}
          <Accordion
            allowMultipleActiveSections={true}
            activeSection={activeAccordionSections}
            // activeSection={displayItems.map((grp) => grp.id.toString())}
            onActiveSectionChange={onAccordionActiveSectionChange}
            allItemKeys={accordionItemKeys}
          >
            {displayItems.map((grp) => (
              <AccordionItem
                itemValue={grp.id.toString()}
                trigger={
                  <span
                    className={cx("text-sm mb-1", accordionHeadingClassNames)}
                  >
                    <span>{t(grp.name)}</span>
                    <Badge
                      text={grp.items.length + " " + "items"}
                      backgroundColourClassName="bg-transparent"
                      textColourClassName={accordionPrimaryTextColorClass}
                    />
                  </span>
                }
                content={
                  <DevelopmentListGroup
                    group={grp}
                    formType={formType}
                    onItemClicked={onDevelopmentListItemClicked}
                  />
                }
                classNames={accordionContainerBgClass}
                key={grp.id}
              />
            ))}
          </Accordion>

          {/* Custom item form */}
          {question.developmentOptions.allowCustomItems && (
            <DevelopmentOtherItemForm
              formType={formType}
              onAdd={onAddCustomItem}
            />
          )}
        </>
      )}

      {/* Selected items */}
      <div className="mt-2 px-2">
        <div className="font-medium">
          {t("Forms.LearningAndDevelopment.SelectedItemsHeading")}
        </div>
        {!hasSelectedItemsToDisplay && (
          <span className="italic text-sm opacity-70">
            {t("Forms.LearningAndDevelopment.NoSelectedItems")}
          </span>
        )}
        {/* Selected items from the list: */}
        {hasSelectedItemsToDisplay &&
          currentValues.map((selectedItem, ix) => (
            <SelectedDevelopmentItem
              name={getSelectedItemDisplayText(selectedItem)}
              itemId={selectedItem.itemId}
              isCustom={selectedItem.isCustom}
              onRemove={() => onItemClicked(selectedItem, false)}
              onAddTask={() => onAddTaskClick(selectedItem.name)}
              key={ix}
              formType={formType}
              usesAdvancedTaskTypes={usesAdvancedTaskTypes}
              isReadOnly={isReadOnly}
            />
          ))}
      </div>

      {/* Tasks */}
      {showTasksHeading && (
        <div className="mt-4 font-semibold">
          {t("Forms.LearningAndDevelopment.NewTasksHeading")}
        </div>
      )}
      <AddFormTaskControl
        questionId={question.questionId}
        formId={formId}
        questionTasks={tasks}
        onChange={onChangeQuestionTasks}
        isReadOnly={isReadOnly}
        mode="EXTERNALLY-TRIGGERED"
        newTaskModalDefaultValues={{
          taskType: "LEARNING",
          title: newTaskPopupTitle,
        }}
        modalDisplayedFromExternalTrigger={displayNewTaskModal}
        onModalClosedCallback={onAddTaskModalClose}
        formType={formType}
      />
    </div>
  );
}

export default DevelopmentQuestion;
