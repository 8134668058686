import cx from "classnames";
import Tooltip from "./Tooltip";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

interface BadgeProps {
  text: string;
  icon?: FontAwesomeIconProps["icon"] | undefined;
  iconPlacement?: "left" | "right" | undefined;
  tooltip?: string | undefined;
  backgroundColourClassName?: string;
  textColourClassName?: string;
  borderRadiusClassName?: string;
  marginClassName?: string;
  cursorClassName?: string;
  /** Any other class names where you don't want to override the default value like you do for margin, border etc */
  classNames?: string;
  textSize?: string;
}

/** A simple badge component which renders text within a small, coloured block */
function Badge({
  text,
  icon = undefined,
  iconPlacement = "left",
  tooltip,
  backgroundColourClassName = "bg-gray-200/50",
  textColourClassName = "text-gray-200",
  borderRadiusClassName = "rounded-sm",
  marginClassName = "mx-2",
  cursorClassName = "cursor-default",
  classNames = "",
  textSize = "text-xs",
}: BadgeProps) {
  const badgeElement = (
    <span
      className={cx(
        "badge-label px-2 py-0.5 leading-tight select-none rounded-sm whitespace-nowrap",
        backgroundColourClassName,
        textColourClassName,
        borderRadiusClassName,
        marginClassName,
        cursorClassName,
        classNames,
        textSize
      )}
    >
      {icon && iconPlacement === "left" && (
        <span className="mr-1">
          <FontAwesomeIcon icon={icon} size="xs" />
        </span>
      )}
      {text}
      {icon && iconPlacement === "right" && (
        <span className="ml-1">
          <FontAwesomeIcon icon={icon} size="xs" />
        </span>
      )}
    </span>
  );

  return tooltip === undefined || tooltip.length === 0 ? (
    badgeElement
  ) : (
    <Tooltip content={tooltip} triggerElement={badgeElement} />
  );
}

export default Badge;
