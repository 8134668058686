import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { Badge } from "../../../components/common";
import TinyLoader from "../../../components/loaders/TinyLoader";
import ExportableJourneyItem, { ExportableJourneyType } from "../../../types/analytics/custom-exports/ExportableJourneyItem";
import UserContext from "../../../state/UserContext";
import customExportsApi from "../../../api/analytics/customExportsApi";
import { fileHelper } from "../../../helpers";

interface JourneyExportItemProps {
  item: ExportableJourneyItem,
  isDownloading: boolean;
  downloadingJourneys: string[];
  setDownloadingJourneys: (newArray: string[]) => void;
  exportableJourneys: ExportableJourneyItem[];
}

function JourneyExportItem({
  item,
  isDownloading,
  downloadingJourneys,
  setDownloadingJourneys,
  exportableJourneys
}: JourneyExportItemProps) {
  // Context/APIs
  const auth = useAuth();
  const api = new customExportsApi(auth.user?.access_token);
  const userContext = useContext(UserContext);

  // Functions
  function downloadFile(title: string, specificJourneyRef: string | undefined) {
    // Get the guids to export data for
    // If no specific journey ref is defined, a button has been pressed for a Client Sent Journey,
    // which can have multiple journey refs
    const journeyRefs =
      specificJourneyRef !== undefined
        ? [specificJourneyRef]
        : exportableJourneys.find((x) => x.title === title)?.journeyRefs;
    if (!journeyRefs || journeyRefs.length === 0) return;

    // Create a filename from the title
    const filenameWithoutExtension = fileHelper.createFilenameFromString(
      title,
      true
    );

    const removeFromDownloadingJourneys = () => {
      const newArray = downloadingJourneys.filter((x) => x !== title);
      setDownloadingJourneys(newArray);
    };

    const onSuccess = () => {
      removeFromDownloadingJourneys();
    };

    const onError = () => {
      removeFromDownloadingJourneys();
    };

    // Do the download
    const newDownloadArray = downloadingJourneys.concat(title);
    setDownloadingJourneys(newDownloadArray);

    api.downloadExport(
      journeyRefs,
      filenameWithoutExtension,
      onSuccess,
      onError
    );
  }

  return (
    <div
      key={item.title}
      className="basis-full md:basis-1/2"
    >
      <div className="px-4 p-2 bg-gray-50 flex flex-row w-full items-center rounded-md border border-gray-200">
        <div className="grow">
          <span className="block font-medium primary-text">
            {item.title}
          </span>
          <div>
            {item.journeyType === "CLIENT-SENT-JOURNEY" && (
              <Badge
                text={"Last sent on " + dayjs(item.mostRecentDate).format(userContext.user.i18n.dateFormat.toUpperCase())}
                textColourClassName="text-gray-500"
                marginClassName="mr-2"
              />
            )}
          </div>
        </div>
        <div className="flex-none px-2">
          <button
            className="disabled:cursor-not-allowed px-2 bg-gray-200 rounded py-1 hover:bg-gray-300 text-center"
            disabled={isDownloading}
            onClick={() =>
              item.journeyType === "CLIENT-SENT-JOURNEY"
                ? downloadFile(item.title, undefined)
                : downloadFile(item.title, item.journeyRefs[0])
            }
          >
            {!isDownloading && (
              <FontAwesomeIcon icon={faDownload} size="sm" />
            )}
            {isDownloading && (
              <TinyLoader
                colour={userContext.user.client.accentHexColour}
              />
            )}
            <span className="ml-1 text-sm">Download</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default JourneyExportItem;
