import { Reducer, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { ModalPopup } from "../../common";
import Tabs, { TabDetails } from "../../common/Tabs";
import { ClientTaskTypeCategory } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import TaskDetailsPopupTab from "./TaskDetailsPopupTab";
import TaskCommentsPopupTab from "./TaskCommentsPopupTab";
import TaskHistoryPopupTab from "./TaskHistoryPopupTab";
import { UserTaskDetail } from "../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import { UserTaskHistoryItem } from "../../../types/dtos/tasks/advanced-tasks/UserTaskHistoryItem";
import { useAuth } from "react-oidc-context";
import advancedTasksApi from "../../../api/task/advancedTasksApi";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import {
  advancedTaskHelper,
  dateHelper,
  simpleFormHelper,
} from "../../../helpers";
import UserTaskComment from "../../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import { SimpleFormAnswer } from "../../../types/dtos/simple-forms";
import { UserTaskChangeLogResponse } from "../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskChangeLogResponse";
import taskHistoryHelper from "../../../helpers/taskHistoryHelper";
import { ExistingTaskManagementTabType } from "../../../types/tasks/ExistingTaskManagementTabType";
import { TaskPopupViewType } from "../../../types/tasks/TaskPopupViewType";
import { TaskEditPopupUsageScenario } from "../../../types/tasks/TaskEditPopupUsageScenario";
import { ExistingTaskWarningOrigin } from "../../../types/tasks/ExistingTaskWarningOrigin";

interface ExistingAdvancedTaskPopupState {
  isLoading: boolean;
  isReadOnly: boolean;
  details: UserTaskDetail | null;
  comments: UserTaskComment[] | null;
  history: UserTaskHistoryItem[] | null;
  allowPopupScroll: boolean;
  bodyFormAnswers: SimpleFormAnswer[];
  hideTabs: boolean;
  modalTitle: string | null;
  showTaskDetailsInAddCommentPopup: boolean;
  initialTab?: ExistingTaskManagementTabType | null;
  reviewInAnswerSetGuidId?: string | null;
  bodyFormIsDirty: boolean;
  closeFormIsDirty: boolean;
  cancelFormIsDirty: boolean;
  reviewFormIsDirty: boolean;
  reopenFormIsDirty: boolean;
  commentFormIsDirty: boolean;
  userIsTryingToClosePopup: boolean;
  closePopupOrigin: ExistingTaskWarningOrigin | null;
}

const blankPopupState = (): ExistingAdvancedTaskPopupState => {
  return {
    isLoading: false,
    isReadOnly: false,
    details: null,
    comments: null,
    history: null,
    allowPopupScroll: true,
    bodyFormAnswers: [],
    hideTabs: false,
    modalTitle: null,
    initialTab: null,
    reviewInAnswerSetGuidId: null,
    showTaskDetailsInAddCommentPopup: false,
    bodyFormIsDirty: false,
    closeFormIsDirty: false,
    cancelFormIsDirty: false,
    reviewFormIsDirty: false,
    reopenFormIsDirty: false,
    commentFormIsDirty: false,
    userIsTryingToClosePopup: false,
    closePopupOrigin: null
  };
};

interface ExistingAdvancedTaskPopupProps {
  usageScenario: TaskEditPopupUsageScenario;
  taskType: ClientTaskType | null;
  userTaskId: string | null;
  categories: ClientTaskTypeCategory[];
  isOpen: boolean;
  /* Only applicable when loaded from within a journey/collab-doc **/
  isTaskNewlyCreatedInJourney?: boolean;
  initialTab?: ExistingTaskManagementTabType | null;
  initialFormToDisplay?: TaskPopupViewType | null;
  onOpenChange(open: boolean): void;
  onSuccessfulUpdate(): void;
  onSuccessfulCancel(): void;
  onSuccessfulClose(): void;
  onSuccessfulReOpen(): void;
  onCommentCountChanged(userTaskId: string, newCommentCount: number): void;
  onSuccessfulRemove?(): void;
}

const ExistingAdvancedTaskPopup = ({
  usageScenario,
  taskType,
  userTaskId,
  categories,
  isOpen,
  isTaskNewlyCreatedInJourney = false,
  initialTab = null,
  initialFormToDisplay = null,
  onOpenChange,
  onSuccessfulUpdate,
  onSuccessfulCancel,
  onSuccessfulClose,
  onSuccessfulReOpen,
  onCommentCountChanged,
  onSuccessfulRemove = undefined,
}: ExistingAdvancedTaskPopupProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);

  const [initialTabToOpenTo, setInitialTabToOpenTo] =
    useState<ExistingTaskManagementTabType | null>(null);
  const [formToDisplay, setFormToDisplay] = useState<TaskPopupViewType | null>(
    null
  );

  const [state, setState] = useReducer<
    Reducer<
      ExistingAdvancedTaskPopupState,
      Partial<ExistingAdvancedTaskPopupState>
    >
  >((state, newState) => ({ ...state, ...newState }), blankPopupState());

  useEffect(() => {
    if (isOpen && userTaskId) {
      loadTaskDetails(userTaskId);
    }
  }, [isOpen, userTaskId]);

  useEffect(() => {
    setInitialTabToOpenTo(initialTab);
    setFormToDisplay(initialFormToDisplay);

    switch (initialTab) {
      case "DETAILS":
        loadTaskDetails(userTaskId);
        break;
      case "COMMENTS":
        // When specifically choosing to add a comment against an existing task in a journey/collab doc,
        // we don't want users to have to switch tabs to see the task details (as they can lose comments they've started to type).
        // So we hide the tab headers, and just show the comments, with a collapsible section for goal details

        const renderCommentsInTaskReviewMode =
          usageScenario === "JOURNEY-COLLAB-DOC" &&
          userTaskId !== null &&
          initialTab === "COMMENTS";

        loadTaskComments(userTaskId, renderCommentsInTaskReviewMode);
        break;
      case "HISTORY":
        loadTaskHistory(userTaskId);
        break;
      default:
        break;
    }
  }, [isOpen, initialTab, initialFormToDisplay]);

  const updateTaskDetailState = (detail: UserTaskDetail) => {
    setState({ ...state, details: detail, bodyFormIsDirty: true });
  };

  const updateComments = (newCommentState: UserTaskComment[], clearCommentFormIsDirtyFlag: boolean) => {
    setState({ 
      ...state, 
      comments: newCommentState,
      commentFormIsDirty: clearCommentFormIsDirtyFlag ? false : state.commentFormIsDirty
    });
    onCommentCountChanged(state.details!.userTaskId!, newCommentState.length);
  };

  const setCloseFormIsDirty = () => {
    setState({ ...state, closeFormIsDirty: true });
  };

  const setCancelFormIsDirty = () => {
    setState({ ...state, cancelFormIsDirty: true });
  };

  const setReviewFormIsDirty = () => {
    setState({ ...state, reviewFormIsDirty: true });
  };

  const setReopenFormIsDirty = () => {
    setState({ ...state, reopenFormIsDirty: true });
  };

  const setCommentFormIsDirty = () => {
    setState({ ...state, commentFormIsDirty: true });
  };

  const setUserIsTryingToClosePopup = (close: boolean) => {
    setState({ ...state, userIsTryingToClosePopup: close });
  };

  const onActiveTabChange = (tabName: string) => {
    switch (tabName) {
      case t("Common.Details"):
        loadTaskDetails(userTaskId);
        break;
      case t("Common.Comments"):
        loadTaskComments(userTaskId, false);
        break;
      case t("Common.History"):
        loadTaskHistory(userTaskId);
        break;
    }
  };

  const loadTaskDetails = (userTaskId: string | null) => {
    if (userTaskId) {
      setState({
        ...state,
        isLoading: true,
        allowPopupScroll: false,
        hideTabs: false,
        modalTitle: null,
      });
      tasksApi.getTaskDetails(
        userTaskId,
        (data: UserTaskDetail) => {
          if (data.targetDate) {
            data.targetDate = dateHelper.convertDateFromStringToUtc(
              data.targetDate
            );
          }
          setState({
            isLoading: false,
            isReadOnly:
              data.taskStatus === "COMPLETED" ||
              data.taskStatus === "CANCELLED" ||
              data.reviewedInAnswerSetGuidId != null,
            details: data,
            bodyFormAnswers: simpleFormHelper.prepServerAnswersForDisplay(
              data.bodyFormInstance.response?.answers
            ),
            reviewInAnswerSetGuidId: data.reviewedInAnswerSetGuidId,
          });
        },
        (error: any) => {
          console.error("Error getting task details", error);
        }
      );
    }
  };

  const loadTaskComments = (
    userTaskId: string | null,
    renderCommentsInTaskReviewMode: boolean
  ) => {
    if (userTaskId) {
      setState({
        ...state,
        isLoading: true,
        allowPopupScroll: true,
        hideTabs: renderCommentsInTaskReviewMode,
        modalTitle: renderCommentsInTaskReviewMode ? "Add comment" : "Comments",
        showTaskDetailsInAddCommentPopup: renderCommentsInTaskReviewMode,
      });
      tasksApi.listComments(
        userTaskId,
        (data: UserTaskComment[]) => {
          setState({
            isLoading: false,
            comments: data,
          });
        },
        (error: any) => {
          console.error("Error getting task details", error);
        }
      );
    }
  };

  const loadTaskHistory = (userTaskId: string | null) => {
    if (userTaskId) {
      setState({ ...state, isLoading: true, allowPopupScroll: true });
      tasksApi.changeLogs(
        userTaskId,
        (data: UserTaskChangeLogResponse) => {
          setState({
            isLoading: false,
            history: processHistoryData(data),
          });
        },
        (error: any) => {
          console.error("Error getting task history", error);
        }
      );
    }
  };

  const processHistoryData = (
    historyData: UserTaskChangeLogResponse
  ): UserTaskHistoryItem[] => {
    let history: UserTaskHistoryItem[] = [];

    // Add a item to reflect the task being reviewed (if applicable)
    // Note: userTaskDateReviewed is just the DateLastModified so can't use that solely
    if (
      historyData.userTaskDateReviewed &&
      historyData.userTaskReviewedInAnswerSetName
    ) {
      history.push({
        timestamp: historyData.userTaskDateReviewed,
        description: t("TaskType.History.TaskReviewed", {
          taskType: taskType?.singularNameTranslationKeyIdentifier
            ? t(taskType.singularNameTranslationKeyIdentifier)
            : "",
          journeyName:
            historyData.userTaskReviewedInAnswerSetName != undefined
              ? historyData.userTaskReviewedInAnswerSetName
              : "",
        }),
      });
    }

    // Loop over the logs from the API data returned and convert them into the history items
    historyData.logs.forEach((log) => {
      history.push(
        taskHistoryHelper.convertDtoToHistoryItem(
          taskType,
          log,
          categories,
          historyData.userTaskOwnerName,
          t
        )
      );
    });

    // Add an item to reflect the task being created
    history.push({
      timestamp: historyData.userTaskDateCreated,
      description: t("TaskType.History.TaskCreated")
        .replace(
          "#TASK_TYPE_NAME#",
          taskType?.singularNameTranslationKeyIdentifier
            ? t(taskType.singularNameTranslationKeyIdentifier)
            : ""
        )
        .replace("#CREATOR_FULL_NAME#", historyData.userTaskCreatedBy),
    });

    return history;
  };

  const handleOnOpenChange = (open: boolean) => {
    if (state.closeFormIsDirty
      || state.cancelFormIsDirty
      || state.reviewFormIsDirty
      || state.reopenFormIsDirty) {
      showWarningConfirmation("CLOSE-ICON");
    } else if (state.commentFormIsDirty) {
      showWarningConfirmation("CLOSE-ICON", true);
    } else {
      setState(blankPopupState());
      onOpenChange(open);
    }
  };

  const goBackFromCommentsWarning = () => {
    const viewLoadedDirectly =
      usageScenario === "JOURNEY-COLLAB-DOC" &&
      initialFormToDisplay !== null;

    // If the view was loaded directly, close the popup
    // Otherwise, toggle userTryingToClosePopup
    if (viewLoadedDirectly) {
      onOpenChange(false);
    } else {
      const renderCommentsInTaskReviewMode =
        usageScenario === "JOURNEY-COLLAB-DOC" &&
        userTaskId !== null &&
        initialTab === "COMMENTS";

      setState({
        ...state,
        hideTabs: false,
        userIsTryingToClosePopup: false,
        modalTitle: renderCommentsInTaskReviewMode ? "Add comment" : "Comments"
      })
    }
  };

  const showWarningConfirmation = (warningOrigin: ExistingTaskWarningOrigin, hideTabs?: boolean) => {
    setState({
      ...state,
      modalTitle: t("TaskType.Popup.UserLeavingConfirmation.AreYouSure"),
      userIsTryingToClosePopup: true,
      closePopupOrigin: warningOrigin,
      hideTabs: hideTabs ?? state.hideTabs,
    });
  }

  const actionLeaveWarningConfirmation = () => {
    switch (state.closePopupOrigin) {
      case "CLOSE-ICON":
        setState(blankPopupState());
        onOpenChange(false);
        break;
      case "BACK-BUTTON":
        const viewLoadedDirectly =
          usageScenario === "JOURNEY-COLLAB-DOC" &&
          initialFormToDisplay !== null;

        if (viewLoadedDirectly) {
          onOpenChange(false);
        } else {
          setFormToDisplay("BODY");
        }
        break;
    }
  };

  const handleBodyFormAnswerChange = (newAnswer: SimpleFormAnswer) => {
    const newAnswers = simpleFormHelper.updateAnswerState(
      newAnswer,
      state.bodyFormAnswers
    );

    // Conditionally automatically set the title (if there is an automatic title setting question id)
    let stateUpdated: boolean = false;
    if (taskType && state.details?.bodyFormInstance?.form) {
      stateUpdated = advancedTaskHelper.conditionallyAutomaticallySetTaskTitle(
        taskType,
        state.details.bodyFormInstance.form,
        newAnswers,
        state.details.title,
        (newTitle: string) => {
          // Update the title and the answers in one state change (can't run two in quick succession)
          const newDetails: UserTaskDetail = {
            ...state.details!,
            title: newTitle,
          };
          setState({
            ...state,
            details: newDetails,
            bodyFormAnswers: newAnswers,
            bodyFormIsDirty: true,
          });
        }
      );
    }

    // Update the answer state (if it wasn't done as part of the title update)
    if (!stateUpdated) {
      setState({ ...state, bodyFormAnswers: newAnswers, bodyFormIsDirty: true });
    }
  };

  const onBodyViewChange = (
    newModalTitle: string | null,
    tabsAreHidden: boolean,
    resetDirtyFormFlags: boolean,
    userIsTryingToClosePopup: boolean
  ) => {
    if (resetDirtyFormFlags) {
      setState({
        ...state,
        modalTitle: newModalTitle,
        hideTabs: tabsAreHidden,
        closeFormIsDirty: false,
        cancelFormIsDirty: false,
        reviewFormIsDirty: false,
        reopenFormIsDirty: false,
        commentFormIsDirty: false,
        userIsTryingToClosePopup: false,
      });
      // Clear the form to display too (to allow for 'Body' to be hit again if needed)
      setFormToDisplay(null);
    } else {
      setState({ ...state, modalTitle: newModalTitle, hideTabs: tabsAreHidden, userIsTryingToClosePopup: userIsTryingToClosePopup });
    }
  };

  const onResetDirtyFlags = () => {
    setState({
      ...state,
      closeFormIsDirty: false,
      cancelFormIsDirty: false,
      reviewFormIsDirty: false,
      reopenFormIsDirty: false,
      commentFormIsDirty: false,
      userIsTryingToClosePopup: false,
    });
  };

  const onSuccessfullyClosed = () => {
    onResetDirtyFlags();
    onSuccessfulClose();
  };

  const onSuccessfullyCancelled = () => {
    onResetDirtyFlags();
    onSuccessfulCancel();
  };

  const tabs: Array<TabDetails> = [
    {
      title: t("Common.Details"),
      type: "DETAILS",
      content: (
        <TaskDetailsPopupTab
          taskType={taskType}
          usageScenario={usageScenario}
          isLoading={state.isLoading}
          isReadOnly={state.isReadOnly}
          categories={categories}
          details={state.details}
          bodyFormAnswers={state.bodyFormAnswers}
          formToDisplay={formToDisplay}
          isDirectToStatusChangeForm={initialFormToDisplay !== null}
          onBodyFormAnswerChange={handleBodyFormAnswerChange}
          setDetails={updateTaskDetailState}
          onSuccessfulUpdate={onSuccessfulUpdate}
          onSuccessfulCancel={onSuccessfullyCancelled}
          onSuccessfulClose={onSuccessfullyClosed}
          onSuccessfulReOpen={onSuccessfulReOpen}
          onBodyViewChange={onBodyViewChange}
          onSuccessfulRemove={onSuccessfulRemove}
          isTaskNewlyCreatedInJourney={isTaskNewlyCreatedInJourney}
          bodyFormIsDirty={state.bodyFormIsDirty}
          closeFormIsDirty={state.closeFormIsDirty}
          cancelFormIsDirty={state.cancelFormIsDirty}
          reviewFormIsDirty={state.reviewFormIsDirty}
          reopenFormIsDirty={state.reopenFormIsDirty}
          setCloseFormIsDirty={setCloseFormIsDirty}
          setCancelFormIsDirty={setCancelFormIsDirty}
          setReviewFormIsDirty={setReviewFormIsDirty}
          setReopenFormIsDirty={setReopenFormIsDirty}
          userIsTryingToClosePopup={state.userIsTryingToClosePopup}
          setUserIsTryingToClosePopup={setUserIsTryingToClosePopup}
          warningConfirmationOrigin={state.closePopupOrigin}
          showWarningConfirmation={showWarningConfirmation}
          actionLeaveWarningConfirmation={actionLeaveWarningConfirmation}
        />
      ),
      displayAlertIcon: false,
    },
    {
      title: t("Common.Comments"),
      type: "COMMENTS",
      content: (
        <TaskCommentsPopupTab
          taskType={taskType}
          isLoading={state.isLoading}
          isReadOnly={state.isReadOnly}
          userTask={state.details}
          reviewInAnswerSetGuidId={state.reviewInAnswerSetGuidId}
          comments={state.comments}
          showTaskDetails={state.showTaskDetailsInAddCommentPopup}
          setComments={updateComments}
          commentFormIsDirty={state.commentFormIsDirty}
          setCommentFormIsDirty={setCommentFormIsDirty}
          userIsTryingToClosePopup={state.userIsTryingToClosePopup}
          goBackFromCommentsWarning={goBackFromCommentsWarning}
          actionLeaveWarningConfirmation={actionLeaveWarningConfirmation}
        />
      ),
      displayAlertIcon: false,
    },
    {
      title: t("Common.History"),
      type: "HISTORY",
      content: (
        <TaskHistoryPopupTab
          isLoading={state.isLoading}
          userTaskId={userTaskId}
          historyItems={state.history ?? []}
        />
      ),
      displayAlertIcon: false,
    },
  ];

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={() => { }}
      title={state.modalTitle}
      allowYOverflow={true}
      onAlternativeOpenChange={handleOnOpenChange}
      showCloseIcon={!state.userIsTryingToClosePopup}
    >
      <Tabs
        tabs={tabs}
        selectedTabClassNames={cx(
          "radix-state-inactive:border-0 radix-state-inactive:hover:border-b-2 radix-state-inactive:hover:!border-b-gray-200",
          "radix-state-active:border-b-2"
        )}
        maxTabsOnSmallScreens={3}
        contextClassNames="!py-0"
        tabListWidth="w-1/3"
        onActiveTabChangeEvent={onActiveTabChange}
        hideTabs={state.hideTabs}
        initialTabToOpenToUsingTabType={initialTabToOpenTo}
      />
    </ModalPopup>
  );
};

export default ExistingAdvancedTaskPopup;
