import { QuestionAnswer, QuestionAnswerType } from "../types/forms";
import developmentQuestionHelper from "./developmentQuestionHelper";
import goalReviewQuestionHelper from "./goalReviewQuestionHelper";

const mergeAnswerTypes: QuestionAnswerType[] = [
  "DEVELOPMENT-LIST",
  "GOAL-REVIEW",
];

const formAnswerHelper = {
  prepareAnswersForInitialDisplay: function (answers: QuestionAnswer[]) {
    let output = answers.filter(
      (x) => mergeAnswerTypes.indexOf(x.answerType) === -1
    );

    // Merge the development answers
    const mergedDevelopmentAnswers =
      developmentQuestionHelper.prepareDatabaseAnswersForDisplay(
        answers.filter((x) => x.answerType === "DEVELOPMENT-LIST")
      );
    if (mergedDevelopmentAnswers.length > 0) {
      output = output.concat(mergedDevelopmentAnswers);
    }

    // Merge the goal review answers
    const mergedGoalReviewAnswers =
      goalReviewQuestionHelper.prepareDatabaseAnswersForDisplay(
        answers.filter((x) => x.answerType === "GOAL-REVIEW")
      );
    if (mergedGoalReviewAnswers.length > 0) {
      output = output.concat(mergedGoalReviewAnswers);
    }

    return output;
  },
};

export default formAnswerHelper;
