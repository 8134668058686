import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";

interface HighlightDotProps {
  containerClassNames?: string | undefined;
  iconClassNames?: string | undefined;
}

/** An icon used inline to indicate there's an update/reason to pay attention to the thing it is next to */
export const HighlightDot = ({
  containerClassNames = undefined,
  iconClassNames = "",
}: HighlightDotProps) => (
  <span className={containerClassNames}>
    <FontAwesomeIcon
      className={cx("primary-color translate-y-px", iconClassNames ?? "")}
      icon={faCircleSmall}
      size="xs"
    />
  </span>
);

export default HighlightDot;
