import { TFunction } from "i18next";
import { UserTaskHistoryItem } from "../types/dtos/tasks/advanced-tasks/UserTaskHistoryItem";
import { UserTaskChangeLogOutputDto } from "../types/dtos/tasks/advanced-tasks/history/UserTaskChangeLogOutputDto";
import dateHelper from "./dateHelper";
import { ClientTaskTypeCategory } from "../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { ClientTaskType } from "../types/dtos/tasks/advanced-tasks/ClientTaskType";

const transformTaskHistoryItemDescription = (
  taskChangeLogDto: UserTaskChangeLogOutputDto,
  textTranslationKeyIdentifier: string,
  t: TFunction
): string => {
  let output = t(textTranslationKeyIdentifier).replace(
    "#FULL_NAME#",
    taskChangeLogDto.employeeFirstName + " " + taskChangeLogDto.employeeLastName
  );
  if (taskChangeLogDto.bodyFieldTranslationKeyIdentifier != null) {
    output = output.replace(
      "#FIELD#",
      "<strong>" +
      t(taskChangeLogDto.bodyFieldTranslationKeyIdentifier) +
      "</strong>"
    );
  }
  return output;
};

export const taskHistoryHelper = {
  convertDtoToHistoryItem(
    taskType: ClientTaskType | null,
    taskChangeLogDto: UserTaskChangeLogOutputDto,
    categories: ClientTaskTypeCategory[],
    taskOwnerName: string,
    t: TFunction
  ): UserTaskHistoryItem {
    let description = taskChangeLogDto.hasOldValueToDisplay
      ? t("TaskType.History.ItemWithPropertyChange")
      : t("TaskType.History.ItemWithNewValue");

    description = description.replace(
      "#FULL_NAME#",
      taskChangeLogDto.employeeFirstName +
      " " +
      taskChangeLogDto.employeeLastName
    );

    const titleFieldTranslation = taskType?.titleTranslationKeyIdentifier != null
      ? t(taskType.titleTranslationKeyIdentifier)
      : t("TaskType.Popup.Labels.Description");

    switch (taskChangeLogDto.propertyChanged) {
      case "TITLE":
        description = description.replace(
          "#FIELD#",
          "<strong>" + titleFieldTranslation + "</strong>"
        );
        if (taskChangeLogDto.oldStringValue) {
          description = description.replace(
            "#OLD_VALUE#",
            taskChangeLogDto.oldStringValue
          );
        }
        if (taskChangeLogDto.newStringValue) {
          description = description.replace(
            "#NEW_VALUE#",
            taskChangeLogDto.newStringValue
          );
        }
        break;
      case "TARGET-DATE":
        description = description.replace(
          "#FIELD#",
          "<strong>" + t("TaskType.Popup.Labels.DueDate") + "</strong>"
        );
        if (taskChangeLogDto.oldDateValue) {
          description = description.replace(
            "#OLD_VALUE#",
            dateHelper
              .convertUtcDateToLocal(taskChangeLogDto.oldDateValue)
              .toLocaleDateString(undefined)
          );
        }
        if (taskChangeLogDto.newDateValue) {
          description = description.replace(
            "#NEW_VALUE#",
            dateHelper
              .convertUtcDateToLocal(taskChangeLogDto.newDateValue)
              .toLocaleDateString(undefined)
          );
        }
        break;
      case "TASK-CATEGORY":
        description = description.replace(
          "#FIELD#",
          "<strong>" + t("TaskType.Popup.Labels.Type") + "</strong>"
        );

        if (taskChangeLogDto.oldStringValue != null) {
          const oldCategory = categories.find(
            (c) =>
              c.categoryId === taskChangeLogDto.oldStringValue?.toLowerCase()
          );
          description = description.replace(
            "#OLD_VALUE#",
            oldCategory?.nameTranslationKeyIdentifier
              ? t(oldCategory.nameTranslationKeyIdentifier)
              : ""
          );
        }

        if (taskChangeLogDto.newStringValue != null) {
          const newCategory = categories.find(
            (c) =>
              c.categoryId === taskChangeLogDto.newStringValue?.toLowerCase()
          );
          description = description.replace(
            "#NEW_VALUE#",
            newCategory?.nameTranslationKeyIdentifier
              ? t(newCategory.nameTranslationKeyIdentifier)
              : ""
          );
        }

        break;
      case "TASK-STATUS":
        description = description.replace(
          "#FIELD#",
          "<strong>" + t("TaskType.History.Fields.TaskStatus") + "</strong>"
        );

        if (taskChangeLogDto.oldNumericValue) {
          description = description.replace(
            "#OLD_VALUE#",
            this.convertTaskStatusIntToString(taskChangeLogDto.oldNumericValue)
          );
        }

        if (taskChangeLogDto.newNumericValue) {
          description = description.replace(
            "#NEW_VALUE#",
            this.convertTaskStatusIntToString(taskChangeLogDto.newNumericValue)
          );
        }

        break;
      case "BODY-FORM-FIELD":
      case "CLOSE-FORM-FIELD":
      case "CANCEL-FORM-FIELD":
      case "REVIEW-FORM-FIELD":
        switch (taskChangeLogDto.answerLogChangeType) {
          case "TEXT-INSERT":
          case "TEXT-UPDATE":
            if (taskChangeLogDto.bodyFieldTranslationKeyIdentifier != null) {
              description = description.replace(
                "#FIELD#",
                "<strong>" +
                t(taskChangeLogDto.bodyFieldTranslationKeyIdentifier) +
                "</strong>"
              );
            }

            if (taskChangeLogDto.newStringValue) {
              description = description.replace(
                "#NEW_VALUE#",
                taskChangeLogDto.newStringValue
              );
            }
            break;

          case "TEXT-DELETE":
            description = transformTaskHistoryItemDescription(
              taskChangeLogDto,
              "TaskType.History.ItemWithRemovedContent",
              t
            );
            break;

          case "MULTI-CHOICE-INSERT":
            description = transformTaskHistoryItemDescription(
              taskChangeLogDto,
              "TaskType.History.FormMultiChoiceInserted",
              t
            );
            if (taskChangeLogDto.newStringValue) {
              description = description.replace(
                "#OPTION_VALUE#",
                taskChangeLogDto.translateNewStringValue
                  ? t(taskChangeLogDto.newStringValue)
                  : taskChangeLogDto.newStringValue
              );
            }
            break;

          case "MULTI-CHOICE-DELETE":
            description = transformTaskHistoryItemDescription(
              taskChangeLogDto,
              "TaskType.History.FormMultiChoiceDeleted",
              t
            );
            if (taskChangeLogDto.newStringValue) {
              description = description.replace(
                "#OPTION_VALUE#",
                taskChangeLogDto.translateNewStringValue
                  ? t(taskChangeLogDto.newStringValue)
                  : taskChangeLogDto.newStringValue
              );
            }
            break;
        }
        break;
    }

    description = description.replace("#EMPLOYEE_NAME#", taskOwnerName);

    return {
      timestamp: taskChangeLogDto.timestamp,
      description: description,
    };
  },
  convertTaskStatusIntToString(taskStatus: number): string {
    switch (taskStatus) {
      case 0:
        return "Draft";
      case 1:
        return "Active";
      case 2:
        return "Closed";
      case 3:
        return "Cancelled";
      case 4:
        return "Archived";
      default:
        return "Unknown";
    }
  },
};

export default taskHistoryHelper;
