import { useContext } from "react";
import { FormattedDate, SummaryTable } from "../../common";
import { useTranslation } from "react-i18next";
import { ClientTaskTypeCategory } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { NewUserTaskDto } from "../../../types/dtos/tasks/advanced-tasks/add-popup/NewUserTaskDto";
import UserContext from "../../../state/UserContext";
import { simpleFormHelper } from "../../../helpers";
import { SummaryTableRow } from "../../common/SummaryTableRow";
import { SimpleFormContent, SimpleFormAnswer } from "../../../types/dtos/simple-forms";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";

interface LeaveTaskSummaryTableProps {
  taskType: ClientTaskType | null;
  categories: ClientTaskTypeCategory[];
  taskDetails: NewUserTaskDto | null;
  formContent: SimpleFormContent | null;
  formAnswers: SimpleFormAnswer[];
  ownerEmployee: BaseUserDetailsDto;
}

const LeaveTaskSummaryTable = ({
  taskType,
  categories,
  taskDetails,
  formContent,
  formAnswers,
  ownerEmployee
}: LeaveTaskSummaryTableProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  let summaryRows: SummaryTableRow[] = [];

  if (taskDetails?.categoryId != null) {
    const category = categories.find(
      (c) => c.categoryId === taskDetails?.categoryId
    );
    if (category != null) {
      summaryRows.push({
        name: t("TaskType.Popup.Labels.Type"),
        value: t(category.nameTranslationKeyIdentifier),
      });
    }
  }

  if (taskDetails?.targetDate != null) {
    summaryRows.push({
      name: t("TaskType.Popup.Labels.DueDate"),
      value: (
        <FormattedDate
          date={taskDetails.targetDate}
          displayMode="DATE-ONLY"
        />
      ),
    });
  }

  if (taskDetails?.title != null && taskDetails?.title !== "") {
    summaryRows.push({
      name: taskType?.titleTranslationKeyIdentifier != null 
        ? t(taskType.titleTranslationKeyIdentifier) 
        : t("TaskType.Popup.Labels.Description"),
      value: taskDetails.title,
    });
  }

  if (formAnswers != null && formAnswers.length > 0) {
    // Loop over each answer that has been given
    formAnswers.forEach((answer) => {
      // TEXT ANSWERS
      if (answer.textAnswer != null) {
        // Find the corresponding question for this answer
        const question = formContent?.questions.find(
          (q) => q.questionId === answer.questionId
        );

        if (question != null) {
          // Get the question label text
          const questionLabelText = simpleFormHelper.getQuestionText(
            question,
            ownerEmployee,
            userContext.user
          );

          summaryRows.push({
            name: questionLabelText,
            value: answer.textAnswer.answerText,
          });
        }
      }
    });
  }

  return (
    <SummaryTable
      containerClassNames="mb-2"
      rows={summaryRows}
      fixedFirstColumn={true}
    />
  );
};

export default LeaveTaskSummaryTable;
