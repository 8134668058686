import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import { apiClient } from "../apiClient";

class demoApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  resetManagerDemo(
    onSuccess: (data: GenericApiResult) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/demo/reset-manager-demo"
    ).then(
      (data) => {
        const result = data as GenericApiResult;
        onSuccess(result);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default demoApi;
