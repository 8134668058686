import { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// @ts-ignore
import Countable from "countable";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";

interface HtmlEditorProps {
  toolbarMode: "SIMPLE" | "ADVANCED";
  currentValue: string | null;
  onValueChange?(newValue: string): void;
  onValueChangeWithWordCount?(newValue: string, wordCount: number): void;
  heightPx?: number;
  inputId?: string | undefined;
  className?: string;
  isReadOnly?: boolean;
  placeholder?: string | undefined;

  /** Providing the currentWordCount and Maxwordcount will display a word counter underneath the editor */
  currentWordCount?: number;
  maxWordCount?: number;

  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

function HtmlEditor({
  toolbarMode,
  currentValue,
  onValueChange,
  onValueChangeWithWordCount,
  inputId = undefined,
  heightPx = 200,
  className = "",
  placeholder = undefined,
  isReadOnly = false,
  currentWordCount,
  maxWordCount,
  showValidationErrors = false,
  validationResult = null
}: HtmlEditorProps) {
  const [containerId, setContainerId] = useState<string | null>(null);
  const falseEmptyState = "<p><br></p>";
  const displayValue = currentValue ? currentValue : "";
  const counterInputRef = useRef<ReactQuill>(null);
  const showMaxCount = maxWordCount !== undefined;

  useEffect(() => {
    // On mount, give the container an id we can use to target the HTML editor height styles
    // (as ReactQuill doesn't give us a nice easy prop to use for height that works responsively
    // and the toolbar goes over multiple lines when on mobile)
    const randomId = "a" + uuidv4().replaceAll("-", ""); // "a" at the start as guids can start with a number, but that's not valid for an id attribute
    setContainerId(randomId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Specify the buttons to display in the toolbar */
  const getToolbarOptions = () => {
    let toolbar;
    if (toolbarMode === "SIMPLE") {
      toolbar = [
        ["bold", "italic", "underline"], // toggled buttons
        [{ header: [1, 2, 3, false] }], // headers
        ["clean"], // remove formatting button
      ];
    } else {
      toolbar = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        //["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        //[{ script: "sub" }, { script: "super" }], // superscript/subscript
        //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        //[{ direction: "rtl" }], // text direction

        //[{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        //[{ color: [] }, { background: [] }], // dropdown with defaults from theme
        //[{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ];
    }

    return {
      toolbar: toolbar,
    };
  };

  // Save re-rendering the text editor every time the value prop changes by using Memo
  const modulesProp = useMemo(() => getToolbarOptions(), [toolbarMode]);

  const handleValueChange = (newValue: string): void => {
    if (newValue === falseEmptyState) {
      if (onValueChange) {
        onValueChange("");
      } else if (onValueChangeWithWordCount) {
        onValueChangeWithWordCount("", 0);
      }
    } else {
      if (showMaxCount && onValueChangeWithWordCount) {
        if (counterInputRef.current?.editingArea != undefined) {
          // @ts-ignore
          Countable.count(counterInputRef.current?.editingArea, (counter) => {
            const latestWordCount = counter?.words ? (counter.words as number) : 0;
            onValueChangeWithWordCount(newValue, latestWordCount);
          });
        }
      } else {
        if (onValueChange) {
          onValueChange(newValue);
        }
      }
    }
  };

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      {containerId && (
        <style type="text/css">
          {`#${containerId} .ql-editor { min-height: ${heightPx}px; overflow: hidden; overflow-y: scroll; overflow-x: scroll; }`}
        </style>
      )}

      <div id={containerId || ""} className="flex flex-1 flex-col">
        <ReactQuill
          id={inputId}
          ref={counterInputRef}
          theme="snow"
          value={displayValue}
          onChange={handleValueChange}
          readOnly={isReadOnly}
          placeholder={placeholder}
          modules={modulesProp}
          className={cx(className, "flex-1 w-full overflow-y-auto")}
        />
      </div>
      {currentWordCount !== undefined && maxWordCount !== undefined && (
        <div className="text-right pr-1 pt-1">
          <span className="p-1 px-3 border rounded-full border-[#6b7280] text-sm">
            <span
              className={cx(
                currentWordCount > maxWordCount
                  ? "text-rose-500 font-bold"
                  : "text-black font-normal"
              )}
            >
              {currentWordCount}
            </span>{" "}
            / {maxWordCount}
          </span>
        </div>
      )}
    </>
  );
}

export default HtmlEditor;
