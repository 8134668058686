import { memo } from "react";
import produce from "immer";
import TextInput from "./TextInput";
import { KeyValuePair } from "../../types/generic";
import MultipleChoiceOptionNumericId, {
  MultipleChoiceOptionStringId,
} from "../../types/forms/MultipleChoiceOptions";

export interface CustomTextInputProps<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
> {
  itemCustomText: string | undefined;
  optionId: number | string;
  values: TOption[];
  /** The method to run when the value changes */
  onChange(newValues: TOption[]): void;

  optionCustomValues: KeyValuePair<number | string, string>[];
  onOptionCustomValueChange(
    newValues: KeyValuePair<number | string, string>[]
  ): void;

  /** An event to call when the user may have stopped interacting with the checkbox list */
  onBlur?(answerValue: TOption[]): void | undefined;
}

function CustomTextInput<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
>({
  itemCustomText,
  optionId,
  values,
  onChange,
  optionCustomValues,
  onOptionCustomValueChange,
  onBlur = undefined,
}: CustomTextInputProps<TOption>) {
  const onCustomTextChange = (value: string) => {
    const nextState = produce(optionCustomValues, (draft) => {
      const match = draft.find((x) => x.key === optionId);
      if (match !== undefined) {
        match.value = value;
      } else {
        draft.push({ key: optionId, value: value });
      }
    });

    onOptionCustomValueChange(nextState);
    updateOptionsState(nextState);
  };

  const onCustomTextInputBlur = () => {
    const updatedOptions = updateOptionsState(optionCustomValues);

    if (onBlur) {
      onBlur(updatedOptions);
    }
  };

  const updateOptionsState = (options: KeyValuePair<string | number, string>[]) => {
    const textValue = options.find((x) => x.key === optionId)?.value;

    const updatedOptions = produce(values, (draft) => {
      const match = draft.find((x) => x.optionId === optionId);
      if (match !== undefined) {
        match.customText = textValue;
      }
    });

    // Update the state
    onChange(updatedOptions);
    return updatedOptions;
  };

  return (
    <div className="flex flex-row m-2">
      <div className="ml-8 w-1/2">
        <TextInput
          onChange={onCustomTextChange}
          className="w-full text-black py-1 text-sm"
          value={itemCustomText}
          maxLength={500}
          placeholder="Please provide more detail"
          onBlur={onCustomTextInputBlur}
        />
      </div>
    </div>
  );
}

export default memo(CustomTextInput) as typeof CustomTextInput;
