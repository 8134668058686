import { useEffect, useState } from "react";
import { KeyValuePair } from "../../../types/generic";
import { dateHelper } from "../../../helpers";
import { Badge, GenericDropDownList } from "../../common";
import { UserDetailDropdownOptionAction } from "../../../types/admin/UserDetailDropdownOptionAction";
import UserDetailJourneyDto from "../../../types/dtos/admin/UserDetailJourneyDto";
import { useTranslation } from "react-i18next";

interface UserDetailJourneyHistoryItemProps {
  journey: UserDetailJourneyDto;
  allowViewOption: boolean;
  onDropdownChange(
    journey: UserDetailJourneyDto,
    newValue: UserDetailDropdownOptionAction
  ): void;
}

function UserDetailJourneyHistoryItem({
  journey,
  allowViewOption,
  onDropdownChange,
}: UserDetailJourneyHistoryItemProps) {
  const { t } = useTranslation();
  const [dropdownOptions, setDropdownOptions] = useState<
    KeyValuePair<UserDetailDropdownOptionAction, string>[]
  >([]);

  const journeyNotStartedOrPartiallyCompleteStatus =
    journey.hasNotBeenStarted ||
    (journey.statusEnum !== null &&
      journey.statusEnum === "PARTIALLY-SAVED-JOURNEY");

  useEffect(() => {
    setDropdownOptions(
      journeyNotStartedOrPartiallyCompleteStatus
        ? [
            {
              key: "SEND-REMINDER",
              value: t("Pages.Admin.UserDetails.PopupTitle.SendReminder"),
            },
            {
              key: "CANCEL",
              value: t("Pages.Admin.UserDetails.PopupTitle.CancelJourney"),
            },
          ]
        : journey.isCompleted
        ? allowViewOption
          ? [
              {
                key: "VIEW",
                value: t("Pages.Admin.UserDetails.PopupTitle.ViewAnswers"),
              },
              {
                key: "REOPEN",
                value: t("Pages.Admin.UserDetails.PopupTitle.ReopenJourney"),
              },
            ]
          : [
              {
                key: "REOPEN",
                value: t("Pages.Admin.UserDetails.PopupTitle.ReopenJourney"),
              },
            ]
        : allowViewOption
        ? [
            {
              key: "SEND-REMINDER",
              value: t("Pages.Admin.UserDetails.PopupTitle.SendReminder"),
            },
            {
              key: "VIEW",
              value: t("Pages.Admin.UserDetails.PopupTitle.ViewAnswers"),
            },
            {
              key: "REOPEN",
              value: t("Pages.Admin.UserDetails.PopupTitle.ReopenJourney"),
            },
            {
              key: "CANCEL",
              value: t("Pages.Admin.UserDetails.PopupTitle.CancelJourney"),
            },
          ]
        : [
            {
              key: "SEND-REMINDER",
              value: t("Pages.Admin.UserDetails.PopupTitle.SendReminder"),
            },
            {
              key: "REOPEN",
              value: t("Pages.Admin.UserDetails.PopupTitle.ReopenJourney"),
            },
            {
              key: "CANCEL",
              value: t("Pages.Admin.UserDetails.PopupTitle.CancelJourney"),
            },
          ]
    );
  }, [journey]);

  const handleDropdownChange = (newValue: UserDetailDropdownOptionAction) => {
    onDropdownChange(journey, newValue);
  };

  const getHiddenToEmployeeJourneyBadgeText = (dto: UserDetailJourneyDto) => {
    if (
      dto.approvalFlow === "HIDDEN-TO-EMPLOYEE" &&
      dto.statusEnum &&
      dto.statusEnum === "INITIAL-SAVE-FORM-INCOMPLETE"
    ) {
      return t("Pages.Admin.Common.NotStarted");
    }
    return t("Pages.Admin.Common.InProgress");
  };

  return (
    <div className="p-3 !pb-2 bg-white rounded-md mt-3 border border-gray-300">
      <div className="grid-cols-1 md:text-gray-500 text-xs pb-2">
        <div className="flex flex-row">
          <div className="grow">
            {/* Journey hasn't been started yet */}
            {journey.hasNotBeenStarted && (
              <Badge
                text={t("Pages.Admin.Common.NotStarted")}
                backgroundColourClassName="bg-rose-200/75 rounded-full"
                textColourClassName="text-rose-600"
                borderRadiusClassName="rounded"
                marginClassName="mr-1"
              />
            )}

            {/* Journey is partially saved */}
            {journey.statusEnum !== null &&
              journey.statusEnum === "PARTIALLY-SAVED-JOURNEY" && (
                <Badge
                  text={t("Pages.Admin.Common.InProgress")}
                  backgroundColourClassName="bg-blue-200/75 rounded-full"
                  textColourClassName="text-blue-600"
                  borderRadiusClassName="rounded"
                  marginClassName="mr-1"
                />
              )}

            {/* Journey is completed */}
            {journey.isCompleted && (
              <Badge
                text={t("Pages.Admin.Common.Completed")}
                backgroundColourClassName="bg-green-200/75 rounded-full"
                textColourClassName="text-green-600"
                borderRadiusClassName="rounded"
                marginClassName="mr-1"
              />
            )}

            {/* Journey is hidden to employee approval flow */}
            {journey.isHiddenToEmployeeFlow && !journey.isCompleted && (
              <Badge
                text={getHiddenToEmployeeJourneyBadgeText(journey)}
                backgroundColourClassName="bg-blue-200/75 rounded-full"
                textColourClassName="text-blue-600"
                borderRadiusClassName="rounded"
                marginClassName="mr-1"
              />
            )}

            {/* Non Dual Prep */}
            {/* If not a dual prep and it's been started and not completed */}
            {!journey.isDualPrepJourney &&
              !journeyNotStartedOrPartiallyCompleteStatus &&
              !journey.isCompleted &&
              !journey.isHiddenToEmployeeFlow && (
                <Badge
                  text={t("Pages.Admin.Common.Meeting")}
                  backgroundColourClassName="bg-blue-200/75 rounded-full"
                  textColourClassName="text-blue-600"
                  borderRadiusClassName="rounded"
                  marginClassName="mr-1"
                />
              )}

            {journey.latestAnswerSet && (
              <>
                {/* Dual Prep Planning Stage */}
                {/* If its a dual prep journey that hasn't been completed, and the latest form is planning */}
                {journey.isDualPrepJourney &&
                  !journey.isCompleted &&
                  (journey.latestAnswerSet.isDualPrepSubjectAnswerSet ||
                    journey.latestAnswerSet.isDualPrepOtherAnswerSet) && (
                    <Badge
                      text={t("Pages.Admin.Common.Planning")}
                      backgroundColourClassName="bg-blue-200/75 rounded-full"
                      textColourClassName="text-blue-600"
                      borderRadiusClassName="rounded"
                      marginClassName="mr-1"
                    />
                  )}

                {/* Dual Prep Meeting Stage */}
                {/* If its a dual prep journey that hasn't been completed, and latest form is the meeting doc */}
                {journey.isDualPrepJourney &&
                  !journey.isCompleted &&
                  journey.latestAnswerSet.isDualPrepMeetingDocAnswerSet && (
                    <Badge
                      text={t("Pages.Admin.Common.Meeting")}
                      backgroundColourClassName="bg-blue-200/75 rounded-full"
                      textColourClassName="text-blue-600"
                      borderRadiusClassName="rounded"
                      marginClassName="mr-1"
                    />
                  )}
              </>
            )}

            {/* Is Dual Prep Journey */}
            {journey.isDualPrepJourney && (
              <Badge
                text={t("Pages.Admin.Common.DualPrepJourney")}
                textColourClassName="text-gray-400"
                marginClassName="mr-2"
              />
            )}

            {/* Is Manager Only */}
            {journey.isHiddenToEmployeeFlow && (
              <Badge
                text={t("Pages.Admin.Common.ManagerOnly")}
                textColourClassName="text-gray-400"
                marginClassName="mr-2"
              />
            )}

            {/* Is Admin Sent */}
            {journey.isClientSentJourney && (
              <Badge
                text={t("Pages.Admin.Common.AdminSent")}
                textColourClassName="text-gray-400"
                marginClassName="mr-2"
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 md:text-gray-500">
        <p className="col-span-1 md:col-span-4 font-bold">{journey.title}</p>
        <div className="col-span-1">
          <GenericDropDownList
            className="w-full bg-gray-100 border-gray-300 text-sm py-1"
            applyBorder={true}
            items={dropdownOptions}
            currentValue="N/A"
            selectOptionCustomText="Select an option"
            onChange={handleDropdownChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 md:text-gray-500 text-xs">
        {journey.dateSent && (
          <p className="col-span-1">
            {t("Pages.Admin.Common.DateSent")}:{" "}
            {dateHelper
              .convertUtcDateToLocal(journey.dateSent)
              .toLocaleDateString()}
          </p>
        )}
        {journey.dateLastUpdated && (
          <p className="col-span-1">
            {t("Pages.Admin.Common.LastUpdated")}:{" "}
            {dateHelper
              .convertUtcDateToLocal(journey.dateLastUpdated)
              .toLocaleDateString()}
          </p>
        )}
        {!journey.isHiddenToEmployeeFlow && (
          <p className="col-span-1">
            {t("Pages.Admin.Common.Status")}:{" "}
            {t(journey.statusTranslationKeyIdentifier)}
          </p>
        )}
      </div>
    </div>
  );
}

export default UserDetailJourneyHistoryItem;
