import { mathsHelper, npsHelper } from "../../../../../helpers";
import { BarChartItemGroup } from "../../../../../types/analytics/charts/BarCharts";
import { Tooltip } from "../../../../common";
import NpsCompareTable from "./NpsCompareTable";
import PersonSVG from "./PersonSVG";

interface PeopleSectionPropTypes {
  widgetId: string;
  scores: Array<BarChartItemGroup>;
  onDrilldown?: undefined | ((score: number) => void);
}

/** A UI showing people SVGs, filled depending on how many people scored each value */
function NpsPeopleChart({
  widgetId,
  scores,
  onDrilldown = undefined,
}: PeopleSectionPropTypes) {
  if (!scores || scores.length < 10) {
    /* Should be 11 for 0-10 but some have been set up incorrectly */
    // Don't have sufficient data to render
    return null;
  }

  // If there is more than one value in each score item, we should
  // render a table instead of the people SVGs
  const isCompareMode = scores[0].items.length > 1;
  if (isCompareMode) {
    return <NpsCompareTable scores={scores} />;
  }

  // Set the colours
  const red = "#fc644d";
  const amber = "#fec830";
  const green = "#44c9b3";

  // Create an array of scores to map over to create the SVGs for
  const scoresArray = Array.from(Array(11).keys());

  // Calculate the results
  const npsResults = npsHelper.calculateValuesFromRawData(scores, 0);

  return (
    <div className="py-4">
      <div className="hidden md:flex flex-row pb-4 lg:px-16 xl:px-24 2xl:px-32">
        {scoresArray.map((ix) => {
          const matchingScoreIndex = scores.findIndex(
            (x) => x.label === ix.toString()
          );
          if (matchingScoreIndex === -1) {
            // Don't render if this index isn't in the scores
            // Useful when an NPS question has been configured without a zero scale item
            return null;
          }

          const scaleScore = Number(scores[matchingScoreIndex].label);
          const count = scores[matchingScoreIndex].items[0].value;
          const percentage =
            mathsHelper.getPercentage(count, npsResults.totalPeople, 1) ?? 0;
          let unique_id = widgetId + "_" + matchingScoreIndex;
          let fillColour: string;
          if (scaleScore <= npsHelper.maxDetractorScore) {
            fillColour = red;
          } else if (
            scaleScore > npsHelper.maxDetractorScore &&
            scaleScore < npsHelper.minPromoterScore
          ) {
            fillColour = amber;
          } else {
            fillColour = green;
          }

          return (
            <PersonSVG
              id={unique_id}
              key={unique_id}
              fillColour={fillColour}
              scoreValue={scaleScore}
              percentage={percentage}
              count={count}
              onDrilldown={onDrilldown}
            />
          );
        })}
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-center">
        <NpsClassification
          title="Detractors"
          count={npsResults.detractors}
          grandTotal={npsResults.totalPeople}
          colour={red}
        />
        <NpsClassification
          title="Passive"
          count={npsResults.passives}
          grandTotal={npsResults.totalPeople}
          colour={amber}
        />
        <NpsClassification
          title="Promoters"
          count={npsResults.promoters}
          grandTotal={npsResults.totalPeople}
          colour={green}
        />
      </div>
      <div className="text-center text-xl text-bold my-2">
        <span className="bg-gray-300 rounded-md p-2">
          Overall eNPS:{" "}
          <Tooltip
            triggerElement={
              <span className="cursor-default">
                {`${npsResults.npsScorePrefix}${mathsHelper.roundForDisplay(
                  npsResults.npsScore,
                  0
                )}`}
              </span>
            }
            content={`${npsResults.npsScorePrefix}${mathsHelper.roundForDisplay(
              npsResults.npsScore,
              3
            )}`}
          />
        </span>
      </div>
      <div className="font-semibold text-gray-500 text-center lg:text-right lg:pr-10">
        Total: {npsResults.totalPeople}
      </div>
    </div>
  );
}

function NpsClassification({
  title,
  count,
  grandTotal,
  colour,
}: {
  title: string;
  count: number;
  grandTotal: number;
  colour: string;
}) {
  return (
    <div className="text-center my-1 lg:mx-1">
      <span
        className="inline-block text-white font-semibold px-2 py-1 rounded-md"
        style={{ backgroundColor: colour }}
      >
        {title}:{" "}
        <Tooltip
          triggerElement={
            <span className="cursor-default">
              {mathsHelper.getPercentageForDisplay(count, grandTotal, 1)}
            </span>
          }
          content={mathsHelper.getPercentageForDisplay(count, grandTotal, 3)}
        />{" "}
        <span className="font-normal text-sm">({count})</span>
      </span>
    </div>
  );
}

export default NpsPeopleChart;
