import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { localStorageHelper } from "../../helpers";
import { ReactComponent as Logo } from "../../images/ttNavLogo.svg";
import UserContext from "../../state/UserContext";

interface AuthErrorPageProps {
  errorMessage: string;
}

function AuthErrorPage({ errorMessage }: AuthErrorPageProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userExistsInState = userContext.user && userContext.user.id > 0;

  useEffect(() => {
    if (errorMessage === "invalid_grant" || errorMessage === "login_required") {
      // User needs to log in again

      // Attempt to load the app url from the user state
      if (userExistsInState && userContext.user.client) {
        window.location.href = userContext.user.client.mvcAppUrl;
      } else {
        // Else, load it from local storage
        const localStorageMvcAppUrl =
          localStorageHelper.getItem("USER_MVC_APP_URL");
        if (localStorageMvcAppUrl) {
          window.location.href = localStorageMvcAppUrl;
        }
      }
    }
  }, []);

  // TODO:
  // See how this works in reality once we're using auth in dev
  // and maybe do different things depending on the error message.
  // If it logs out, check the UserContext, and if we still have a user's
  // client url segment, redirect to the tt login page

  return (
    <div className="w-2/3 m-auto">
      <div className="p-4 text-center">
        <div className="px-4 py-2 m-0 ">
          <Logo className="px-2 pb-2 m-auto" width={"12rem"} />
        </div>
        <div className="text-gray-700 mt-4">
          <div className="font-bold">{t("Common.Auth.Error")}:</div>
          <em className="block mt-1">{errorMessage}</em>
        </div>
      </div>
    </div>
  );
}

export default AuthErrorPage;
