import { BarChartItemGroup } from "../types/analytics/charts/BarCharts";

export interface NpsResults {
  detractors: number;
  promoters: number;
  passives: number;
  totalPeople: number;
  npsScore: number;
  /** "+" or blank for zero/negative, as the number will already have the "-" if it is negative */
  npsScorePrefix: string;
}

const npsHelper = {
  maxDetractorScore: 6,
  minPromoterScore: 9,
  calculateValuesFromRawData: function (
    scores: Array<BarChartItemGroup>,
    datasetIndex: number
  ): NpsResults {
    const totalDetractors = scores
      .filter((s) => Number(s.label) <= this.maxDetractorScore)
      .reduce((n, { items }) => n + items[datasetIndex].value, 0);
    const totalPassive = scores
      .filter(
        (s) =>
          Number(s.label) > this.maxDetractorScore &&
          Number(s.label) < this.minPromoterScore
      )
      .reduce((n, { items }) => n + items[datasetIndex].value, 0);
    const totalPromoters = scores
      .filter((s) => Number(s.label) >= this.minPromoterScore)
      .reduce((n, { items }) => n + items[datasetIndex].value, 0);

    const totalPeople = scores.reduce(
      (n, { items }) => n + items[datasetIndex].value,
      0
    );

    const detractorsPercentage = (100 / totalPeople) * totalDetractors;
    const promotersPercentage = (100 / totalPeople) * totalPromoters;
    const npsScore = promotersPercentage - detractorsPercentage;
    const npsScorePrefix = npsScore > 0 ? "+" : "";

    return {
      detractors: totalDetractors,
      promoters: totalPromoters,
      passives: totalPassive,
      totalPeople: totalPeople,
      npsScore: npsScore,
      npsScorePrefix: npsScorePrefix,
    };
  },
};

export default npsHelper;
