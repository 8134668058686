import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { faHistory } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedDate, Label, ModalPopup, TextArea } from "../../../common";
import {
  NineBoxGridEditorAxisSettings,
  NineBoxGridEditorDataItem,
  NineBoxGridEditorDataItemAxisScore,
  NineBoxGridEditorDataItemAxisScoreValues,
  NineBoxGridOverrideScorePopupData,
  NineBoxGridScoreOverrideAxis,
  NineBoxGridScoreOverrideRequest,
  NineBoxGridScoreOverrideResponse,
} from "../../../../types/analytics/charts/NineBoxGridEditorData";
import TinyLoader from "../../../loaders/TinyLoader";
import UserContext from "../../../../state/UserContext";
import { dateHelper } from "../../../../helpers";
import NineBoxGridScoreOverrideInput from "./NineBoxGridScoreOverrideInput";
import DangerBanner from "../../../common/DangerBanner";
import nineBoxGridEditorApi from "../../../../api/analytics/nineBoxGridEditorApi";
import analyticsDynamicWidgetApi from "../../../../api/analytics/analyticsDynamicWidgetApi";
import ComplexScoreOverrideLog from "../../../../types/analytics/ComplexScoreOverrideLog";
import nineBoxGridHelper from "../../../../helpers/nineBoxGridHelper";

interface NineBoxGridScoreOverridePopupProps {
  analyticsPageId: number;
  dynamicWidgetId: number;
  model: NineBoxGridOverrideScorePopupData;
  axisSettings: NineBoxGridEditorAxisSettings;
  /** The function to call to update the 9BG table view after the score has been updated via the API */
  onSuccessfulUpdate(
    row: NineBoxGridEditorDataItem,
    axis: NineBoxGridScoreOverrideAxis,
    newScore: NineBoxGridEditorDataItemAxisScore,
    newBoxNumber: number
  ): void;
  onCloseModal(): void;
}

const NineBoxGridScoreOverridePopup = ({
  analyticsPageId,
  dynamicWidgetId,
  model,
  axisSettings,
  onSuccessfulUpdate,
  onCloseModal,
}: NineBoxGridScoreOverridePopupProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const nineBoxGridApi = new nineBoxGridEditorApi(auth.user?.access_token);
  const dynamicWidgetApi = new analyticsDynamicWidgetApi(
    auth.user?.access_token,
    analyticsPageId
  );

  // Pick the correct score object based on the axis being edited
  const modelScore = model.dataItem
    ? model.editAxis === "X"
      ? model.dataItem.xScore
      : model.dataItem.yScore
    : null;

  const [justificationText, setJustificationText] = useState<string>("");
  const [showValidationWarning, setShowValidationWarning] =
    useState<boolean>(false);
  const [displayUpdateHistory, setDisplayUpdateHistory] =
    useState<boolean>(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState<boolean>(false);
  const [isScoreSaving, setIsScoreSaving] = useState<boolean>(false);
  const [scoreSaveErrored, setScoreSaveErrored] = useState<boolean>(false);
  const [updateHistory, setUpdateHistory] = useState<ComplexScoreOverrideLog[]>(
    []
  );
  const [editedScoreObject, setEditedScoreObject] =
    useState<NineBoxGridEditorDataItemAxisScore | null>(null);

  // Check for fundamental props
  if (!model.editAxis || !model.dataItem) {
    // Can't do anything without these values
    return null;
  }

  const justificationPlaceholder = t(
    `Analytics.Widgets.NineBoxGridEditor.Explain${model.editAxis.toUpperCase()}AxisScore`,
    {
      personName: model.dataItem.firstName,
    }
  );

  const handleOnOpenChange = () => {
    onCloseModal();
    resetState();
  };

  const handleJustificationChange = (newValue: string) => {
    setJustificationText(newValue);
  };

  const handleScoreInputChange = (
    newScoreValues: NineBoxGridEditorDataItemAxisScoreValues
  ) => {
    if (!modelScore) return;

    const newScoreObject: NineBoxGridEditorDataItemAxisScore = {
      ...modelScore,
      tooltipValue: newScoreValues.tooltipValue,
      displayValue: newScoreValues.displayValue,
      scorePercentage: newScoreValues.scorePercentage,
    };

    // Store the new score object in the state
    // so that when the user submits, it uses the new score
    setEditedScoreObject(newScoreObject);
  };

  const toggleDisplayUpdateHistory = (show: boolean) => {
    if (!modelScore || !model.dataItem) return;

    setDisplayUpdateHistory(show);
    setUpdateHistory([]); // Clear any existing history

    if (show) {
      setIsHistoryLoading(true);

      const successCallback = (data: ComplexScoreOverrideLog[]) => {
        setUpdateHistory(data);
        setIsHistoryLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
        setIsHistoryLoading(false);
      };

      dynamicWidgetApi.getScoreOverrideLogs(
        modelScore.complexScoreId,
        model.dataItem.employeeId,
        successCallback,
        errorCallback
      );

      // getScoreOverrideLogs
    }
  };

  const validateForm = (): boolean => {
    let isValid = true; // Innocent until proven guilty

    // Check there's an edited score object
    if (!editedScoreObject) {
      isValid = false;
    }

    // Check there's actually been a change in the score
    if (
      editedScoreObject &&
      modelScore &&
      editedScoreObject.displayValue === modelScore.displayValue
    ) {
      isValid = false;
    }

    // Check there's a justification
    if (!justificationText || justificationText.trim().length === 0) {
      isValid = false;
    }

    setShowValidationWarning(!isValid);

    return isValid;
  };

  const onUpdateClick = () => {
    if (!modelScore || !validateForm()) return;

    if (!model.dataItem || !model.editAxis || !editedScoreObject) return;

    setIsScoreSaving(true);
    setScoreSaveErrored(false);

    const successCallback = ({
      success,
      message,
      newBoxNumber,
    }: NineBoxGridScoreOverrideResponse) => {
      setIsScoreSaving(false);
      setScoreSaveErrored(false);

      if (success && newBoxNumber !== null) {
        onSuccessfulUpdate(
          model.dataItem!,
          model.editAxis!,
          editedScoreObject!,
          newBoxNumber
        );
        handleOnOpenChange();
      } else {
        errorCallback(message ?? "Failed to update score");
      }
    };

    const errorCallback = (error: any) => {
      // Show error message to user
      console.error(error);
      setScoreSaveErrored(true);
      setIsScoreSaving(false);
    };

    // Populate the model for the API call
    const requestModel: NineBoxGridScoreOverrideRequest = {
      nineBoxGridWidgetId: dynamicWidgetId,
      axis: model.editAxis,
      complexScoreId: modelScore.complexScoreId,
      employeeId: model.dataItem.employeeId,
      justification: justificationText,
      newScore: editedScoreObject.scorePercentage,
      displayedNineBoxGridComplexScoreId: model.dataItem.boxScoreId,
    };

    // Call the API to update the score and get the new grid position back
    nineBoxGridApi.updateAxisScore(
      requestModel,
      successCallback,
      errorCallback
    );
  };

  const resetState = () => {
    setJustificationText("");
    setDisplayUpdateHistory(false);
    setIsHistoryLoading(false);
    setUpdateHistory([]);
    setEditedScoreObject(null);
    setShowValidationWarning(false);
  };

  /** Transform the raw change log percentage back to the scale/drop down item that was selected,
   * if the axis has a scale conversion set up (otherwise fall back to display the raw percentage)
   */
  const transformLogPercentage = (logScoreValue: string): string => {
    const decimalScore = Number(logScoreValue);
    if (
      model.editAxis === "X" &&
      axisSettings.xAxis.hasValidScaleConversionProperties &&
      !isNaN(decimalScore)
    ) {
      return nineBoxGridHelper
        .getScaleValueForXAxis(decimalScore, axisSettings)
        .toString();
    } else if (
      model.editAxis === "Y" &&
      axisSettings.yAxis.valueSettings?.options?.length > 0
    ) {
      return nineBoxGridHelper.getDisplayValueForYAxisPercentageScore(
        decimalScore,
        axisSettings
      );
    }

    return logScoreValue + "%";
  };

  return (
    <ModalPopup
      isOpen={model.modalIsOpen}
      onOpenChange={handleOnOpenChange}
      title={`${model.dataItem.firstName} ${model.dataItem.lastName}`}
      width="SMALL"
      allowYOverflow={true}
      preventInitialAutoFocus
    >
      {/* Standard View */}
      {!displayUpdateHistory && (
        <>
          {showValidationWarning && (
            <DangerBanner text="Please ensure you've changed the score and entered your justification" />
          )}
          <div>
            <Label
              text={t(
                model.editAxis === "X"
                  ? "Common.PerformanceScore"
                  : "Common.PotentialScore"
              )}
              className="font-semibold text-gray-600 text-lg"
            />
            {modelScore && (
              <NineBoxGridScoreOverrideInput
                editAxis={model.editAxis}
                axisSettings={axisSettings}
                originalScoreObject={modelScore}
                onScoreChange={handleScoreInputChange}
              />
            )}
          </div>
          <div>
            <Label
              text={`${t(
                "Analytics.Widgets.NineBoxGridEditor.Justification"
              )}:`}
              className="font-semibold text-gray-600 text-lg"
            />
            <TextArea
              value={justificationText}
              onChange={handleJustificationChange}
              minRows={3}
              maxRows={6}
              className="mt-2 p-2 w-full rounded-md border border-gray-400"
              placeholder={justificationPlaceholder}
              maxLength={2000}
            />
          </div>
          {scoreSaveErrored && (
            <div className="my-2">
              <DangerBanner text="Failed to save score. Please try again." />
            </div>
          )}
          <div className="flex flex-row mt-4">
            <div className="flex-initial pr-2">
              <button
                className="text-sm cursor-pointer hover:underline"
                onClick={() => toggleDisplayUpdateHistory(true)}
              >
                <FontAwesomeIcon icon={faHistory} size="1x" className="pr-1" />
                <span>
                  {t("Analytics.Widgets.NineBoxGridEditor.UpdateHistory")}
                </span>
              </button>
            </div>
            <div className="flex-grow text-right">
              <button
                onClick={() => handleOnOpenChange()}
                className="btn-secondary mr-1"
                disabled={isScoreSaving}
              >
                {t("Common.Cancel")}
              </button>
              <button
                className="btn-primary"
                onClick={onUpdateClick}
                disabled={isScoreSaving}
              >
                {t("Common.Update")}
              </button>
            </div>
          </div>
        </>
      )}

      {/* Update History View */}
      {displayUpdateHistory && (
        <>
          {isHistoryLoading && (
            <div className="pl-4 text-center">
              <TinyLoader colour={userContext.user.client.accentHexColour} />
              <span className="ml-1 text-gray-500 text-sm">
                {t("Common.Loading")}...
              </span>
            </div>
          )}

          {!isHistoryLoading && (
            <>
              {updateHistory.length === 0 && (
                <p>
                  {t("Analytics.Widgets.NineBoxGridEditor.NoUpdateHistory")}
                </p>
              )}
              {updateHistory.length > 0 && (
                <table className="datatable mt-2 mb-4">
                  <thead>
                    <tr>
                      <th className="text-center py-1 px-2">
                        {t("Analytics.Widgets.NineBoxGridEditor.Previous")}
                      </th>
                      <th className="text-center py-1 px-2">
                        {t("Analytics.Widgets.NineBoxGridEditor.New")}
                      </th>
                      <th className="text-left py-1 px-2">
                        {t("Analytics.Widgets.NineBoxGridEditor.Justification")}
                      </th>
                      <th className="text-left py-1 px-2">
                        {t("Analytics.Widgets.NineBoxGridEditor.UpdatedBy")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {updateHistory.map((log) => (
                      <tr key={`${log.updatedDate.toISOString()}`}>
                        <td className="border-b-[1px] border-gray-200 py-1 px-2 text-center">
                          {transformLogPercentage(log.oldValue)}
                        </td>
                        <td className="border-b-[1px] border-gray-200 py-1 px-2 text-center">
                          {transformLogPercentage(log.newValue)}
                        </td>
                        <td className="border-b-[1px] border-gray-200 py-1 px-2">
                          {log.notes}
                        </td>
                        <td className="border-b-[1px] border-gray-200 py-1 px-2">
                          {log.updatedByName} (
                          <FormattedDate
                            date={log.updatedDate}
                            displayMode="DATE-AND-TIME"
                            convertFromUtc
                          />
                          )
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div>
                <button
                  className="btn-secondary float-right"
                  onClick={() => toggleDisplayUpdateHistory(false)}
                >
                  {t("TaskType.Popup.Buttons.GoBack")}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </ModalPopup>
  );
};

export default NineBoxGridScoreOverridePopup;
