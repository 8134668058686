import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import AnalyticsPage from "../../components/analytics/AnalyticsPage";
import { useParams } from "react-router-dom";
import { typeConversionHelper } from "../../helpers";
import analyticsDynamicWidgetApi from "../../api/analytics/analyticsDynamicWidgetApi";
import { DynamicAnalyticsPageMetaData } from "../../types/analytics";
import { AnalyticsPageJourneyFilterRestriction } from "../../types/analytics/AnalyticsFilterInputs";

function DynamicAnalyticsPage() {
  const auth = useAuth();
  const { pageId } = useParams();
  const numericPageId = typeConversionHelper.stringToNumber(pageId);

  const [pageTitle, setPageTitle] = useState<string>("");
  const [journeyRestriction, setJourneyRestriction] =
    useState<AnalyticsPageJourneyFilterRestriction>("SHOW-ALL");
  const [dynamicPageId, setDynamicPageId] = useState<number | undefined>(
    undefined
  );

  const pageIdIsValid =
    dynamicPageId && !isNaN(dynamicPageId) && dynamicPageId > 0;

  const dynamicWidgetApi = new analyticsDynamicWidgetApi(
    auth.user?.access_token,
    numericPageId ? numericPageId : 0
  );

  // On page load, and when the page changes
  useEffect(() => {
    loadPageMetaDataFromApi();
  }, [pageId]);

  function loadPageMetaDataFromApi() {
    if (numericPageId === undefined || isNaN(numericPageId)) {
      return;
    }

    const onSuccess = (data: DynamicAnalyticsPageMetaData) => {
      setPageTitle("Analytics: " + data.pageTitle);
      setJourneyRestriction(data.journeyFilterRestriction);
      setDynamicPageId(data.pageId);
    };

    const onError = (error: any) => {
      console.error("Unable to load analytics page meta data", error);
    };

    dynamicWidgetApi.getPageMeta(onSuccess, onError);
  }

  if (dynamicPageId == undefined || !pageIdIsValid) {
    // Don't render anything if the page id param is invalid
    return null;
  }

  return (
    <AnalyticsPage
      pageIdentifier={`Dynamic_${dynamicPageId.toString()}`}
      title={pageTitle}
      translateTitle={false}
      journeyFilterDefaultValueMode="MULTI"
      journeyFilterRestriction={journeyRestriction}
      contentType="WIDGETS"
      analyticsPageApi={dynamicWidgetApi}
      dynamicPageId={dynamicPageId}
    />
  );
}

export default DynamicAnalyticsPage;
