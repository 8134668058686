import {
  BarDatum,
  ComputedBarDatum,
  ComputedDatum,
  ResponsiveBar,
} from "@nivo/bar";
import { mathsHelper, widgetConfigsHelper } from "../../../helpers";
import { byPropertiesOf } from "../../../helpers/arraySorting";
import { AnalyticsWidgetUiDetailsDto } from "../../../types/analytics";
import {
  BarChartDataTransformer,
  BarChartItemGroup,
} from "../../../types/analytics/charts/BarCharts";
import { chartConstants } from "./ChartConstants";
import ChartLegend from "./ChartLegend";
import CustomChartTooltip from "./CustomChartTooltip";
import { BarChartGroupingType } from "../../../types/analytics/AnalyticsWidgetConfigs";
import { KeyValuePair } from "../../../types/generic";
import { BarChartDrilldownValue } from "../../../types/analytics/charts/BarChartDrilldownValue";
import BarChartMobileAlternativeDisplay from "./BarChartMobileAlternativeDisplay";

export type BarChartLayout = "HORIZONTAL" | "VERTICAL";

export interface BarChartProps {
  widget: AnalyticsWidgetUiDetailsDto;
  onBarClick?: (item: BarChartDrilldownValue) => void;
  /** Whether or not the image is being exported. When true, we show the legend because the user doesn't know what the bars are otherwise */
  isExportingImage: boolean;
}

interface GenericBarChartProps extends BarChartProps {
  layout: BarChartLayout;
}

function BarChart({
  layout,
  widget,
  isExportingImage,
  onBarClick = undefined,
}: GenericBarChartProps) {
  if (!widget || !widget.datasets || widget.datasets.length === 0) return null;

  const dataTransformer = new BarChartDataTransformer();
  const barData = dataTransformer.transformData(widget);
  const groupModeFromConfigs: BarChartGroupingType =
    widgetConfigsHelper.getBarGroupingMode(widget);
  const datasetKey = "groupKey";
  const groupTotalKey = "groupTotal";
  const patternFillKeySuffix = "_Fill";
  const nivoBarData: BarDatum[] = [];
  const itemKeys: string[] = [];

  /** Get a unique name for each dataset, otherwise
   * it merges data where the dataset groupKeys are the same
   */
  const getUniqueGroupName = (
    groupName: string,
    usedGroupNames: string[]
  ): string => {
    if (usedGroupNames.indexOf(groupName) === -1) {
      usedGroupNames.push(groupName);
      return groupName;
    }

    for (var i = 2; i < 100; i++) {
      const keyToCheck = `${groupName} (${i})`;
      if (usedGroupNames.indexOf(keyToCheck) === -1) {
        usedGroupNames.push(keyToCheck);
        return keyToCheck;
      }
    }

    // Very unlikely scenario with the loop above
    // If there are over 100 datasets anyway, that's a problem!
    // Let alone 99 with the same name.
    return "";
  };

  const currentGroupNames: string[] = [];

  // Get the count of datasets which have more than one item/group in
  const dataSetsWithMultiItems = barData
    .map((x) => x.items.length)
    .filter((x) => x > 1).length;

  // Group when there's more than one bar with more than group within it
  // (as the chart renders weird when there's only one group for each x-axis tick, the bars render off centred, plus the bars are really narrow)
  const barChartMode =
    groupModeFromConfigs === "GROUPED" && dataSetsWithMultiItems > 1
      ? "GROUPED"
      : "STACKED";

  // Sort the datasets either by alphabetical order, or if
  // all labels are numerical, by number order
  const allLabelsAreNumeric =
    barData.map((x) => isNaN(Number(x.label))).filter((x) => x === true)
      .length === 0;

  const allLabelsAreLowMedHigh =
    barData
      .map((x) => x.label)
      .filter((x) => x === "Low" || x === "Medium" || x === "High").length ===
    barData.length;

  const sortedBarData =
    allLabelsAreNumeric ||
    allLabelsAreLowMedHigh ||
    widget.displayType === "HISTOGRAM"
      ? barData
      : barData.sort(byPropertiesOf<BarChartItemGroup>(["totalItemCount"])); // `-label` for alphabetical

  // Transform the standardised bar data for each dataset into
  // the object format that the Nivo chart plugin expects
  let fullChartTotal = 0;
  const compareGroupTotals: KeyValuePair<string, number>[] = [];
  sortedBarData.forEach((group) => {
    const nivoItem: any = {};

    nivoItem[datasetKey] = getUniqueGroupName(group.label, currentGroupNames);

    let groupTotal = 0;
    group.items.forEach((item, itemIx) => {
      // Create a property on the object for this key/value
      nivoItem[item.label] = item.value;

      // Increment the running total for this group
      groupTotal += item.value;

      // Alternate patterns between solid, dots and lines
      nivoItem[`${item.label}${patternFillKeySuffix}`] =
        itemIx % 2 === 0 ? undefined : itemIx % 3 === 0 ? "lines" : "dots";

      // Add to the unique set of item keys if it's not already in that array
      if (itemKeys.indexOf(item.label) === -1) {
        itemKeys.push(item.label);
      }

      // Add to the compare group totals array
      const compareGroupTotalIndex = compareGroupTotals.findIndex(
        (x) => x.key === item.label
      );
      if (compareGroupTotalIndex === -1) {
        compareGroupTotals.push({ key: item.label, value: item.value });
      } else {
        compareGroupTotals[compareGroupTotalIndex].value += item.value;
      }
    });

    // Set the total
    nivoItem[groupTotalKey] = groupTotal;

    // Add to the grand total of all items in this chart
    fullChartTotal += groupTotal;

    nivoBarData.push(nivoItem);
  });

  /** Get the value from the object for which pattern to use */
  const getItemFillValue = (item: ComputedBarDatum<BarDatum>) => {
    if (item && item.data && item.data.id && item.data.data) {
      const fillValueKey = item.data.id + patternFillKeySuffix;
      return item.data.data[fillValueKey];
    }

    return undefined;
  };

  /** Calculate the percentage to display as the label/tooltip for the bar */
  const getBarPercentage = (
    itemId: string,
    itemValue: Number,
    stackTotal: Number
  ) => {
    let percentageOf: number = fullChartTotal;
    if (isTrueStackedMode) {
      percentageOf = Number(stackTotal);
    } else if (barChartMode === "GROUPED") {
      percentageOf = compareGroupTotals.find((x) => x.key === itemId)!.value;
    }

    return mathsHelper.getPercentageForDisplay(
      Number(itemValue),
      Number(percentageOf),
      0
    );
  };

  // This controls label and tooltip content
  // The chart renders in stacked mode even when there is only one item in each data point,
  // but is only in true stacked mode when there's one or more items with more than one
  // numeric data value within
  const isTrueStackedMode =
    barChartMode === "STACKED" && dataSetsWithMultiItems >= 1;

  const showLegend = barChartMode === "GROUPED" || isTrueStackedMode;

  // Increase the chart height if it's a horizontal bar chart with many bars
  const chartHeight =
    layout === "HORIZONTAL" && itemKeys.length > 15
      ? Number(chartConstants.height.replace("px", "")) * 1.5 + "px"
      : chartConstants.height;

  const handleBarClick = (item: ComputedDatum<BarDatum>) => {
    if (!onBarClick) return;

    if (barChartMode === "STACKED") {
      // Stacked charts have a different group key (the parent option name), so we pass the item id instead
      onBarClick({ value: item.id, stackedOuterValue: item.data.groupKey });
    } else {
      onBarClick({ value: item.data.groupKey, stackedOuterValue: undefined });
    }
  };

  const setBarCursor = (
    event: React.MouseEvent<SVGRectElement>,
    cursor: "pointer" | "default"
  ) => {
    // Don't set the cursor if it can't be clicked on anyway
    if (!onBarClick) return;
    const barElement = event.target as SVGRectElement;
    if (!barElement || !barElement.style) return;
    barElement.style.cursor = cursor;
  };

  const handleMouseEnter = (
    datum: ComputedDatum<BarDatum>,
    event: React.MouseEvent<SVGRectElement>
  ) => {
    // Set some styles when the user hovers over a bar
    setBarCursor(event, "pointer");
  };

  const handleMouseLeave = (
    datum: ComputedDatum<BarDatum>,
    event: React.MouseEvent<SVGRectElement>
  ) => {
    // Undo some styles when the user stops hovering over a bar
    setBarCursor(event, "default");
  };

  return (
    <>
      {/* The small (i.e. phone) screen display: */}
      <BarChartMobileAlternativeDisplay
        barChartMode={barChartMode}
        dataItems={sortedBarData}
        widget={widget}
      />

      {/* The large (non phone) screen display: */}
      <div className="hidden md:block" style={{ height: chartHeight }}>
        <ResponsiveBar
          data={nivoBarData}
          layout={layout === "HORIZONTAL" ? "horizontal" : "vertical"}
          keys={itemKeys}
          indexBy={datasetKey}
          margin={{ top: 20, right: 50, bottom: 50, left: 120 }}
          padding={0.25}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={chartConstants.coloursArray}
          defs={chartConstants.patterns}
          fill={[
            {
              match: (d) => getItemFillValue(d) === "dots",
              id: "dots",
            },
            { match: (d) => getItemFillValue(d) === "lines", id: "lines" },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          groupMode={barChartMode === "GROUPED" ? "grouped" : "stacked"}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend:
              widget.xAxisLabel !== null && widget.xAxisLabel.length > 0
                ? widget.xAxisLabel
                : undefined,
            legendPosition: "middle",
            legendOffset: 32,
            format: (e) =>
              layout === "HORIZONTAL" ? Math.floor(e) === e && e : undefined, // Whole number ticks only
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend:
              widget.yAxisLabel !== null && widget.yAxisLabel.length > 0
                ? widget.yAxisLabel
                : undefined,
            legendPosition: "middle",
            legendOffset: -50,
            format: (e) => {
              if (layout === "VERTICAL") {
                // Whole number ticks only
                return Math.floor(e) === e && e;
              } else {
                // Truncate long left axis labels and show a tooltip with the full text
                const leftLabelMaxLength = 15;
                return e.length > leftLabelMaxLength ? (
                  <tspan>
                    {e.substring(0, leftLabelMaxLength) + "..."}
                    <title>{e}</title>
                  </tspan>
                ) : (
                  e
                );
              }
            },
          }}
          label={(item) => {
            return getBarPercentage(
              item.id.toString(),
              item.value!,
              Number(item.data[groupTotalKey])
            );
          }}
          labelSkipWidth={20}
          labelSkipHeight={20}
          legends={[]}
          tooltip={(item) => {
            let tooltipContent = item.value.toString();
            const percentage = getBarPercentage(
              item.id.toString(),
              item.value!,
              Number(item.data[groupTotalKey])
            );
            if (percentage && percentage.length > 0) {
              tooltipContent = `${tooltipContent} (${percentage})`;
            }

            return <CustomChartTooltip text={item.id} value={tooltipContent} />;
          }}
          onClick={onBarClick ? handleBarClick : undefined}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      {/* Hide the legend if there are more legend items than there are unique colours */}
      {((isExportingImage && showLegend) ||
        (showLegend && itemKeys.length <= 11)) && (
        <ChartLegend keys={itemKeys} />
      )}
    </>
  );
}

export default BarChart;
