import { useNavigate } from "react-router-dom";
import { CollabDocLoadErrorType } from "../../types/dtos/collab-docs/CollabDocLoadResponseDto";
import { ModalPopup } from "../common";
import AppRoutes from "../../routes/AppRoutes";

interface CollabDocLoadErrorModalProps {
  errorType: CollabDocLoadErrorType;
  isOpen: boolean;
  onOpenChange(open: boolean): void;
}

/** A modal with error messages for the common loading error scenarios */
function CollabDocLoadErrorModal({
  errorType,
  isOpen,
  onOpenChange,
}: CollabDocLoadErrorModalProps) {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate(AppRoutes.yourJourney.root);
  };

  let errorMessage = (
    <p>There was an error loading this page. Please try again.</p>
  );
  if (errorType === "MISSING-PARTICIPANT") {
    errorMessage = (
      <>
        <p>
          This Journey is on hold as you are the only participant, this can
          happen when you don't have an active manager assigned.
        </p>
        <p>
          Please reach out to your people team or drop us a message at{" "}
          <a href="mailto:help@talenttoolbox.com" target="_blank">
            help@talenttoolbox.com
          </a>
          , and we'll get you back on track.
        </p>
      </>
    );
  }

  return (
    <ModalPopup
      title="Uh oh, something went wrong"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      width="SMALL"
      primaryButtonText="Back to Dashboard"
      onPrimaryButtonClick={goToDashboard}
      showCloseIcon={false}
    >
      {errorMessage}
    </ModalPopup>
  );
}

export default CollabDocLoadErrorModal;
