import {
  NineBoxGridScoreOverrideRequest,
  NineBoxGridScoreOverrideResponse,
} from "../../types/analytics/charts/NineBoxGridEditorData";
import { apiClient } from "../apiClient";

class nineBoxGridEditorApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  updateAxisScore(
    model: NineBoxGridScoreOverrideRequest,
    onSuccess: (data: NineBoxGridScoreOverrideResponse) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/nine-box-grid-editor/update-axis-score",
      {
        body: JSON.stringify(model),
      }
    ).then(
      (data) => {
        const responseData = data as NineBoxGridScoreOverrideResponse;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default nineBoxGridEditorApi;
