import collabDocApi from "../api/forms/collabDocApi";
import { CollabDocState } from "../state/CollabDoc/CollabDocState";
import { UserContextInterface } from "../state/UserContext";
import {
  NewBehaviourAnswerDto,
  NewGoalReviewAnswerDto,
  NewMultiChoiceAnswerDto,
  NewTextAnswerDto,
} from "../types/dtos/collab-docs/answers";
import NewDevelopmentAnswerDto from "../types/dtos/collab-docs/answers/NewDevelopmentAnswerDto";
import {
  BehaviourQuestionAnswerValue,
  DevelopmentQuestionAnswerValue,
  GoalReviewQuestionAnswerValue,
  MultipleChoiceQuestionAnswerValue,
  QuestionAnswer,
  QuestionAnswerValue,
} from "../types/forms";
import dateHelper from "./dateHelper";

export class collabDocAnswerHelper {
  constructor(api: collabDocApi, userContext: UserContextInterface) {
    this.api = api;
    this.userContext = userContext;
  }

  readonly api: collabDocApi;
  readonly userContext: UserContextInterface;

  /** Takes a single answer, and saves it, handling which answer type it
   * is and posting it to the correct API endpoint
   */
  saveAnswer(
    questionId: string,
    answerDetails: QuestionAnswer,
    answerSetId: string,
    answerSetDateCreated: Date,
    /** The success callback */
    onSuccess: () => void,
    /** The error callback */
    onError: () => void
  ): void {
    switch (answerDetails.answerType) {
      case "TEXT":
        const textAnswerDto = this._getNewTextAnswerDto(
          questionId,
          answerDetails.answer,
          answerSetId,
          answerSetDateCreated,
          answerDetails.formId
        );
        this.api.saveTextAnswer(textAnswerDto, onSuccess, onError);
        break;
      case "MULTICHOICE":
        const multiChoiceAnswerDto = this._getNewMultiChoiceAnswerDto(
          questionId,
          answerDetails.answer,
          answerSetId,
          answerSetDateCreated,
          answerDetails.formId
        );
        this.api.saveMultiChoiceAnswer(
          multiChoiceAnswerDto,
          onSuccess,
          onError
        );
        break;
      case "BEHAVIOUR":
        const behaviourAnswerDto = this._getNewBehaviourAnswerDto(
          questionId,
          answerDetails.answer,
          answerSetId,
          answerSetDateCreated,
          answerDetails.formId
        );
        this.api.saveBehaviourAnswer(behaviourAnswerDto, onSuccess, onError);
        break;
      case "GOAL-REVIEW":
        const goalReviewAnswerDto = this._getNewGoalReviewAnswerDto(
          questionId,
          answerDetails.answer,
          answerSetId,
          answerSetDateCreated,
          answerDetails.formId
        );
        this.api.saveGoalReviewAnswer(goalReviewAnswerDto, onSuccess, onError);
        break;
      case "DEVELOPMENT-LIST":
        const developmentAnswerDto = this._getNewDevelopmentAnswerDto(
          questionId,
          answerDetails.answer,
          answerSetId,
          answerSetDateCreated,
          answerDetails.formId
        );
        this.api.saveDevelopmentAnswer(
          developmentAnswerDto,
          onSuccess,
          onError
        );
        break;
      default:
        console.log(
          "Answer type save not implemented: " + answerDetails.answerType
        );
        break;
    }
  }

  getNewDefaultState(): CollabDocState {
    return {
      loadingErrorType: null,
      showLoadingErrorPopup: false,
      formIsDirty: false, // Whether or not the form has been edited since it was loaded
      dateLoadedUtc: dateHelper.getCurrentDateUtc(),
      commentToDelete: null,
      showUpdateDocBanner: false,
      isExitJourney: false,
      waitingForApiResult: true,
      discussionExists: false,
      showDifferentFormVersionsWarning: false,
      userCanDiscardAnswerSet: false,
      activeQuestionId: null,
      subjectUser: null,
      status: null,
      isReadOnly: true,
      isLocked: false,
      hasBeenDelegated: false,
      lastUpdatedDate: null,
      lastUpdatedByUserId: null,
      participants: [],
      managerPlanningWasSkipped: false,
      forms: [],
      formComplexities: { goalRolloverConfig: null },
      comments: [],
      flaggedChanges: [],
      backgroundColour: this.userContext.user.client.primaryHexColour,
      lockedForSubmissionUntil: null,
      mode: "STANDARD",
      isInPrepMode: false,
      visibleComments: null,
      journeyName: "",
      approvalFlow: "STANDARD",
      instantSignOffIsDisabled: null,
      instantlySignedOffByUser: null,
      managerPlanningIsOptional: false,
      answerState: [],
      taskState: [], // The state variable for tasks added in this document, but not yet pushed to the user's dashboard
      answerSetDateCreated: null,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewTextAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerSetId: string,
    answerSetDateCreated: Date,
    clientFormId: number
  ): NewTextAnswerDto {
    return {
      doc: answerSetId,
      docDateCreated: answerSetDateCreated,
      questionId: questionId,
      text: newValue as string,
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewMultiChoiceAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerSetId: string,
    answerSetDateCreated: Date,
    clientFormId: number
  ): NewMultiChoiceAnswerDto {
    // Convert the null/number/number[] value into a consistent MultipleChoiceQuestionAnswerValue[] value
    // for passing to the server
    let selectedValues: MultipleChoiceQuestionAnswerValue[] = [];
    if (newValue !== null) {
      if (Array.isArray(newValue)) {
        if (newValue.length > 0) {
          selectedValues = [
            ...(newValue as MultipleChoiceQuestionAnswerValue[]),
          ];
        }
      }
    }

    return {
      doc: answerSetId,
      docDateCreated: answerSetDateCreated,
      questionId: questionId,
      values: selectedValues,
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewBehaviourAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerSetId: string,
    answerSetDateCreated: Date,
    clientFormId: number
  ): NewBehaviourAnswerDto {
    return {
      doc: answerSetId,
      docDateCreated: answerSetDateCreated,
      questionId: questionId,
      answers: newValue as BehaviourQuestionAnswerValue[],
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewGoalReviewAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerSetId: string,
    answerSetDateCreated: Date,
    clientFormId: number
  ): NewGoalReviewAnswerDto {
    return {
      doc: answerSetId,
      docDateCreated: answerSetDateCreated,
      questionId: questionId,
      answers: newValue as GoalReviewQuestionAnswerValue[],
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewDevelopmentAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerSetId: string,
    answerSetDateCreated: Date,
    clientFormId: number
  ): NewDevelopmentAnswerDto {
    // Convert the DevelopmentQuestionAnswerValue[] value into a consistent DevelopmentQuestionAnswerValue[] value
    // for passing to the server
    let selectedValues: DevelopmentQuestionAnswerValue[] = [];
    if (newValue !== null) {
      selectedValues = [...(newValue as DevelopmentQuestionAnswerValue[])];
    }

    return {
      doc: answerSetId,
      docDateCreated: answerSetDateCreated,
      questionId: questionId,
      answers: selectedValues,
      formId: clientFormId,
    };
  }
}

export default collabDocAnswerHelper;
