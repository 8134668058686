import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { EmployeeDashboardUpdateDto } from "../../../types/dtos/dashboards";
import { FormattedDate } from "../../common";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../routes/AppRoutes";
import { t } from "i18next";

interface EmployeeUpdateBodyProps {
  /** The update to display information for */
  update: EmployeeDashboardUpdateDto;
  /** A function which changes the user's current journey and refreshes the journey widget */
  changeJourney: (journeyRef: string) => void;
}

function EmployeeUpdateBody({
  update,
  changeJourney,
}: EmployeeUpdateBodyProps) {
  const navigate = useNavigate();

  // Use different button text for different scenarios
  let buttonText = "Go";
  switch (update.updateType) {
    case "COLLABDOC":
      const isManagerOnlyApprovalDoc =
        update.journey?.approvalFlow === "MANAGER-ONLY-APPROVAL";
      // Only either "INITIAL-SAVE-FORM-INCOMPLETE" or a status where the manager last updated the doc,
      // so if it's in a status where we know the manager did the last update, change the button text accordingly
      if (
        update.journey !== null &&
        update.journey.answerSetApprovalStatus !== null &&
        !update.journey.showAsWaitingForManager &&
        update.journey.answerSetApprovalStatus !==
          "INITIAL-SAVE-FORM-INCOMPLETE"
      ) {
        buttonText = t("DashboardUpdate.Employee.Button.ReviewUpdates");
      } else {
        buttonText = t("DashboardUpdate.Employee.Button.Open");
      }
      break;
    case "DUALPREPMEETING":
      // Only either "INITIAL-SAVE-FORM-INCOMPLETE" or a status where the manager last updated the doc,
      // so if it's in a status where we know the manager did the last update, change the button text accordingly
      if (
        update.journey !== null &&
        update.journey.answerSetApprovalStatus !== null &&
        update.journey.answerSetApprovalStatus !==
          "INITIAL-SAVE-FORM-INCOMPLETE"
      ) {
        buttonText = t("DashboardUpdate.Employee.Button.ReviewUpdates");
      } else {
        buttonText = t("DashboardUpdate.Employee.Button.OpenMeeting");
      }
      break;
    case "DUALPREPPLANNING":
      buttonText = t("DashboardUpdate.Employee.Button.StartYourPlanning");
      break;
    case "NOTASKS":
    case "NOTENOUGHTASKS":
    case "OVERDUETASKS":
      if (update.task != null) {
        buttonText = t("DashboardUpdate.Employee.Button.GoToTasks").replace(
          "#TASKS#",
          t(update.task.pluralNameTranslationKeyIdentifier)
        );
      }
      break;
  }

  const onButtonClick = () => {
    if (
      update.updateType === "DUALPREPPLANNING" &&
      update.journey !== null &&
      update.journey.answerSetUniqueId === null
    ) {
      changeJourney(update.journey.journeyReference);
    } else if (update.journey !== null && update.journey.answerSetUniqueId) {
      const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
        update.journey.answerSetUniqueId,
        false,
        null
      );
      navigate(redirectUrl);
    } else if (update.task != null) {
      const redirectUrl = AppRoutes.yourJourney.tasks.generatePathRoute(
        update.task.taskTypeId
      );
      navigate(redirectUrl);
    }
  };

  return (
    <div className="px-2 pb-2 mt-1">
      <div className="flex flex-col md:flex-row md:flex-wrap md:items-end md:justify-between md:max-w-full">
        {update.journey && (
          <div className=" text-white/60 text-xs hidden md:flex md:flex-grow-1">
            <span className="mr-[2px]">
              {t("Common.UpdatedBySentence.LastUpdated")}:
            </span>
            <FormattedDate
              date={update.journey.timestamp}
              displayMode="FROM-NOW"
            />
          </div>
        )}
        <div></div>
        <div className="text-right mt-4 md:justify-self-end">
          <button
            onClick={onButtonClick}
            className="bg-white font-medium text-gray-700 rounded-md pb-1 pt-1 px-4 whitespace-nowrap flex-1 text-right mt-1 hover:shadow-md hover:shadow-white/20"
          >
            {buttonText}
            <FontAwesomeIcon icon={faArrowRight} className="ml-1" size="xs" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmployeeUpdateBody;
