import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import taskRestrictionHelper from "../../helpers/taskRestrictionHelper";

interface TaskBarRestrictionBannerProps {
  selectedTaskType: ClientTaskType;
  hasActiveTasks: boolean;
}

function TaskBarRestrictionBanner({
  selectedTaskType,
  hasActiveTasks,
}: TaskBarRestrictionBannerProps) {
  const { t } = useTranslation();
  const bannerText =
    taskRestrictionHelper.getRestrictionTextForDashboardTaskBar(
      selectedTaskType,
      hasActiveTasks,
      t
    );
  return (
    <div className="mb-2 py-1 mt-2 mx-1 text-sm py-2 px-4 bg-rose-400 text-white rounded-md text-center">
      <div className="fa-layers fa-fw mr-1">
        <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
      </div>
      <span className="text-sm">{bannerText}</span>
    </div>
  );
}

export default TaskBarRestrictionBanner;
