import SignedInUserDto from "../../types/dtos/users/SignedInUserDto";
import SubNavItem from "../../types/nav/SubNavItem";
import AppRoutes from "../AppRoutes";

const YourPeopleSubNavItems = (loggedInUser: SignedInUserDto): SubNavItem[] => {
  const output: SubNavItem[] = [];
  loggedInUser.client.taskTypes.forEach((clientTaskType) => {
    const taskTypeHighlights = loggedInUser.highlightedTasks.find(
      (x) => x.taskTypeId === clientTaskType.id
    );
    output.push({
      title: clientTaskType.shortTitleTranslationKeyIdentifier,
      translateTitle: true,
      url: AppRoutes.yourPeople.tasks.generateRoute(clientTaskType.id),
      badgeCount: taskTypeHighlights?.managerTaskIds
        ? taskTypeHighlights.managerTaskIds.length
        : undefined,
    });
  });
  return output;
};

export default YourPeopleSubNavItems;
