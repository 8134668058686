import { useContext } from "react";
import { useTranslation } from "react-i18next";
import UserTaskListItemDto from "../../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import { Badge, HighlightDot } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/pro-regular-svg-icons";
import { dateHelper } from "../../helpers";
import cx from "classnames";
import UserContext from "../../state/UserContext";
import TaskStatusBadge from "../forms/advanced/task-management/TaskStatusBadge";

interface TaskTabItemProps {
  task: UserTaskListItemDto;
  wasCompletedByLoggedInUser: boolean;
  isActiveTabSelected: boolean;
  isCompletedTabSelected: boolean;
  isHistoricTabSelected: boolean;
  onClickEvent: (userTaskId: string) => void;
}

const TaskTabItem = ({
  task,
  wasCompletedByLoggedInUser,
  isActiveTabSelected,
  isCompletedTabSelected,
  isHistoricTabSelected,
  onClickEvent,
}: TaskTabItemProps) => {
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const currentDate = dateHelper.getCurrentDateUtc();
  const targetDate = dateHelper.convertDateFromStringToUtc(task.targetDate);
  const showOverdueBadge = isActiveTabSelected && targetDate < currentDate;

  const showDueDateRow = !isCompletedTabSelected && !isHistoricTabSelected

  let completedByText = "";
  if (isCompletedTabSelected) {
    completedByText = t("TaskType.Common.CompletedBy")
      .replace(
        "#PERSON#",
        wasCompletedByLoggedInUser ? "you" : task.completedByUserFullName!
      )
      .replace(
        "#DATE_COMPLETED#",
        dateHelper
          .convertUtcDateToLocal(task.dateCompleted)
          .toLocaleDateString()
      );
  }

  const taskIsHighlightedForUser = (task: UserTaskListItemDto) => {
    if (task.highlightToOwner && task.ownerEmployeeId === userContext.user.id) {
      return true;
    }

    if (
      task.highlightToManager &&
      task.ownerEmployeeId !== userContext.user.id
    ) {
      return true;
    }

    return false;
  };

  return (
    <div
      className="gap-6 p-3 !pb-2 bg-white rounded-md mt-4 border border-gray-300 text-gray-500 text-sm hover:drop-shadow-md hover:!cursor-pointer"
      onClick={() => onClickEvent(task.userTaskId)}
    >
      {showOverdueBadge && (
        <div className="grid grid-cols-1 md:grid-cols-5 md:text-gray-500">
          <div className="grid-cols-1 md:text-gray-500 text-xs pb-2">
            <div className="flex flex-row">
              <div className="grow">
                <Badge
                  text={t("Common.Overdue")}
                  backgroundColourClassName="bg-orange-200/75 rounded-full"
                  textColourClassName="text-orange-600"
                  borderRadiusClassName="rounded"
                  marginClassName="mr-1"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 md:text-gray-500">
        <p
          className={cx(
            "col-span-1 font-bold text-base",
            task.categoryName != null && task.categoryName !== ""
              ? isHistoricTabSelected ? "md:col-span-8" : "md:col-span-9"
              : isHistoricTabSelected ? "md:col-span-10" : "md:col-span-11"
          )}
        >
          {taskIsHighlightedForUser(task) && (
            <HighlightDot containerClassNames="mr-1" />
          )}
          {task.title}
        </p>
        {isHistoricTabSelected && (
          <div className="col-span-1 md:text-sm md:pl-4">
            <TaskStatusBadge
              status={task.taskStatus}
              isNewlyCreatedInThisJourney={false}
              isOverdue={false}
            />
          </div>
        )}
        {task.categoryName != null && task.categoryName !== "" && (
          <div className="col-span-2 md:text-sm md:pl-4">
            <span>{t(task.categoryName!)}</span>
          </div>
        )}
        <div className="col-span-1 md:text-center">
          <FontAwesomeIcon icon={faComments}></FontAwesomeIcon>
          <span className="ml-1">{task.commentCount}</span>
        </div>
      </div>
      <div className="grid grid-cols-1 md:text-gray-500 text-sm pt-1">
        {showDueDateRow && (
          <p className="col-span-1">
            {t("Tasks.Common.TargetDate")}:{" "}
            {dateHelper
              .convertUtcDateToLocal(task.targetDate)
              .toLocaleDateString()}
          </p>
        )}
        {isCompletedTabSelected && (
          <p className="col-span-1 text-green-800 font-medium">
            {completedByText}
          </p>
        )}
        {isHistoricTabSelected && (
          <p className="col-span-1">
            {t("TaskType.Common.ReviewedWithin", {
              journeyName: task.reviewedInAnswerSetName != undefined ? task.reviewedInAnswerSetName : "",
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default TaskTabItem;
