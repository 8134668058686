import { useCallback, useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { faPencilAlt, faRefresh } from "@fortawesome/pro-regular-svg-icons";
//@ts-ignore
import Pagination from "react-js-pagination";
import { AnalyticsWidgetUiDetailsDto } from "../../../../types/analytics";
import {
  NineBoxGridEditorAxisSettings,
  NineBoxGridEditorDataItem,
  NineBoxGridEditorDataItemAxisScore,
  NineBoxGridEditorSummary,
  NineBoxGridOverrideScorePopupData,
  NineBoxGridScoreOverrideAxis,
} from "../../../../types/analytics/charts/NineBoxGridEditorData";

import TinyLoader from "../../../loaders/TinyLoader";
import UserContext from "../../../../state/UserContext";
import SearchField from "../../../common/SearchField";
import NineBoxGridScoreOverridePopup from "./NineBoxGridScoreOverridePopup";
import analyticsDynamicWidgetApi from "../../../../api/analytics/analyticsDynamicWidgetApi";
import { AnalyticsWidgetRefreshArguments } from "../../../../types/analytics/AnalyticsWidgetRefreshFilterParams";
import AnalyticsFilterInputs from "../../../../types/analytics/AnalyticsFilterInputs";
import { Tooltip } from "../../../common";

interface TableSearchPageDetails {
  pageNumber: number;
  searchPhrase: string | null;
  refreshDataFromApi: boolean;
}

export interface NineBoxGridEditorProps {
  widget: AnalyticsWidgetUiDetailsDto;
  analyticsPageId: number;
  filters: AnalyticsFilterInputs;
  /** If there's a 9BG widget on the same page, supply the ID so we can use it to refresh the data for that widget from this one */
  nineBoxGridWidgetId: number | null;
  onRequestOtherWidgetRefresh?: (
    dynamicWidgetId: number,
    onSuccess: () => void,
    onError: () => void
  ) => void;
}

function NineBoxGridEditor({
  widget,
  analyticsPageId,
  filters,
  nineBoxGridWidgetId,
  onRequestOtherWidgetRefresh,
}: NineBoxGridEditorProps) {
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const dynamicWidgetApi = new analyticsDynamicWidgetApi(
    auth.user?.access_token,
    analyticsPageId
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageSize = 15;
  const [pageSearchDetails, setPageSearchDetails] =
    useState<TableSearchPageDetails>({
      pageNumber: 1,
      searchPhrase: null,
      refreshDataFromApi: false,
    });
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [displayData, setDisplayData] = useState<NineBoxGridEditorDataItem[]>(
    []
  );
  const [axisSettings, setAxisSettings] =
    useState<NineBoxGridEditorAxisSettings | null>(null);

  const [scoreOverridePopupData, setScoreOverridePopupData] =
    useState<NineBoxGridOverrideScorePopupData | null>(null);

  const [hasChangedScores, setHasChangedScores] = useState<boolean>(false);
  const [isRefreshingNineBoxGrid, setIsRefreshingNineBoxGrid] =
    useState<boolean>(false);

  useEffect(() => {
    initialiseData(widget?.datasets);
  }, [widget.datasets]);

  useEffect(() => {
    if (pageSearchDetails.refreshDataFromApi) {
      refreshData();
    }
  }, [pageSearchDetails]);

  const initialiseData = (datasets: any[] | undefined) => {
    // Handle the "no data" scenario,
    // or scenarios where the data is not in the expected format
    // with the first dataset being the items to display,
    // and the second "dataset" object being the summary
    if (!datasets || datasets.length !== 2) {
      setDisplayData([]);
      setTotalItemCount(0);
      setAxisSettings(null);
      return;
    }

    const dataItems = datasets[0] as NineBoxGridEditorDataItem[];
    const summary = datasets[1] as NineBoxGridEditorSummary;
    setDisplayData(dataItems);
    setTotalItemCount(summary.totalItemCount);
    setAxisSettings(summary.axisSettings);
    setIsLoading(false);
  };

  const refreshData = () => {
    setIsLoading(true);

    const successCallback = (data: AnalyticsWidgetUiDetailsDto) => {
      initialiseData(data.datasets);
      setIsLoading(false);
    };

    const errorCallback = (error: any) => {
      setIsLoading(false);
      console.error(error);
    };

    const refreshParams: AnalyticsWidgetRefreshArguments = {
      analyticsPageId: analyticsPageId,
      widgetId: widget.dynamicWidgetId!,
      pageNumber: pageSearchDetails.pageNumber,
      pageSize: pageSize,
      searchValue: pageSearchDetails.searchPhrase,
    };

    dynamicWidgetApi.refreshWidgetData(
      filters,
      refreshParams,
      successCallback,
      errorCallback
    );
  };

  /** Debounce the actual state change so it doesn't call the API to refresh the data on each keystroke */
  const debouncedUpdateSearchState = useCallback(
    debounce((searchValue: string | null) => {
      // Reset page number too
      setPageSearchDetails({
        pageNumber: 1,
        searchPhrase: searchValue,
        refreshDataFromApi: true,
      });
    }, 300),
    []
  );

  const handleSearchChange = (newSearchPhrase: string | null) => {
    debouncedUpdateSearchState(newSearchPhrase);
  };

  const handlePageChange = (pageNumber: number) => {
    setPageSearchDetails({
      ...pageSearchDetails,
      pageNumber,
      refreshDataFromApi: true,
    });
  };

  const openScoreOverrideModal = (
    rowData: NineBoxGridEditorDataItem,
    axis: NineBoxGridScoreOverrideAxis,
    isOpen: boolean
  ) => {
    setScoreOverridePopupData({
      modalIsOpen: isOpen,
      dataItem: rowData,
      editAxis: axis,
    });
  };

  const clearScoreOverrideModalData = () => {
    setScoreOverridePopupData(null);
  };

  const onUpdateScore = (
    editRow: NineBoxGridEditorDataItem,
    axis: NineBoxGridScoreOverrideAxis,
    newScore: NineBoxGridEditorDataItemAxisScore,
    newBoxNumber: number
  ) => {
    // If there is popup data, then we need to update the score in the UI based on the mode
    if (scoreOverridePopupData && scoreOverridePopupData.editAxis) {
      // Find the complex score in the display data, update score and set display data
      const updatedData = displayData.map((row) => {
        if (row.employeeId === editRow.employeeId) {
          row.boxNumber = newBoxNumber;
          if (axis === "X") {
            row.xScore = newScore;
          } else {
            row.yScore = newScore;
          }
        }

        return row;
      });
      setDisplayData(updatedData);
      setHasChangedScores(true);

      // Clear the modal data
      clearScoreOverrideModalData();
    }
  };

  const onRefreshNineBoxGridClick = () => {
    if (!onRequestOtherWidgetRefresh || !nineBoxGridWidgetId) return;
    const onRefreshSuccess = () => {
      setIsRefreshingNineBoxGrid(false);
      // 9BG refreshed, so hide the button now there aren't pending changes
      setHasChangedScores(false);
    };

    const onRefreshError = () => {
      setIsRefreshingNineBoxGrid(false);
      console.error("Error refreshing Nine Box Grid widget");
    };

    setIsRefreshingNineBoxGrid(true);
    onRequestOtherWidgetRefresh(
      nineBoxGridWidgetId,
      onRefreshSuccess,
      onRefreshError
    );
  };

  const noResultsLoaded = !isLoading && totalItemCount === 0;
  return (
    <>
      <SearchField
        onSearch={handleSearchChange}
        isLoading={isLoading}
        loaderColor={userContext.user.client.accentHexColour}
      />
      {noResultsLoaded && (
        <em className="block text-center italic py-4">
          {t("Pages.Analytics.Common.NoTableData")}
        </em>
      )}
      {!noResultsLoaded && displayData.length > 0 && (
        <div className="mb-4">
          <table className="datatable mt-2 mb-4">
            <thead>
              <tr>
                <th className="text-left py-1 px-2">
                  {t("Analytics.Widgets.Common.Tables.Columns.FirstName")}
                </th>
                <th className="text-left py-1 px-2">
                  {t("Analytics.Widgets.Common.Tables.Columns.LastName")}
                </th>
                <th className="text-left py-1 px-2">
                  {t("Analytics.Widgets.Common.Tables.Columns.AppraisalLevel")}
                </th>
                <th className="text-left py-1 px-2">
                  {t("Analytics.Widgets.Common.Tables.Columns.JobTitle")}
                </th>
                <th className="text-left py-1 px-2">
                  {t("Analytics.Widgets.Common.Tables.Columns.Location")}
                </th>
                <th className="text-left py-1 px-2">
                  {t(
                    "Analytics.Widgets.Common.Tables.Columns.PerformanceScore"
                  )}
                </th>
                <th className="text-left py-1 px-2 ">
                  {t("Analytics.Widgets.Common.Tables.Columns.PotentialScore")}
                </th>
                <th className="text-left py-1 px-2">
                  {t("Analytics.Widgets.Common.Tables.Columns.Box")}
                </th>
              </tr>
            </thead>
            <tbody>
              {displayData.map((row, rowIx) => (
                <tr key={`${rowIx}`}>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    {row.firstName}
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    {row.lastName}
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    {t(row.appraisalLevel)}
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    {t(row.jobTitle)}
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    {row.location}
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    <NineBoxGridEditorEditButton
                      row={row}
                      axis="X"
                      openScoreOverrideModal={openScoreOverrideModal}
                    />
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    <NineBoxGridEditorEditButton
                      row={row}
                      axis="Y"
                      openScoreOverrideModal={openScoreOverrideModal}
                    />
                  </td>
                  <td className="border-b-[1px] border-gray-200 py-1 px-2">
                    {t(
                      `Analytics.Widgets.NineBoxGrid.BoxTitles.Box${row.boxNumber}`
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row">
            <div>
              {totalItemCount > pageSize && (
                <Pagination
                  activePage={pageSearchDetails.pageNumber}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={totalItemCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  activeClass="pagination-active-list-item"
                  itemClass="pagination-list-item"
                  itemClassFirst="pagination-first-item"
                  itemClassLast="pagination-last-item"
                />
              )}
            </div>
            {isLoading && (
              <div className="pl-4">
                <TinyLoader colour={userContext.user.client.accentHexColour} />
                <span className="ml-1 text-gray-500 text-sm">
                  {t("Common.Loading")}...
                </span>
              </div>
            )}
            {hasChangedScores && nineBoxGridWidgetId && (
              <div className="sm:hidden lg:block flex-grow pl-2 text-right">
                <button
                  className="btn-primary !px-2 group"
                  disabled={isRefreshingNineBoxGrid}
                >
                  <FontAwesomeIcon
                    icon={faRefresh}
                    size="xs"
                    className="group-hover:rotate-45 group-disabled:animate-spin"
                  />
                  <span className="ml-1" onClick={onRefreshNineBoxGridClick}>
                    Refresh Nine Box Grid
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Modal */}
      {scoreOverridePopupData &&
        scoreOverridePopupData.dataItem &&
        scoreOverridePopupData.editAxis &&
        axisSettings && (
          <NineBoxGridScoreOverridePopup
            analyticsPageId={analyticsPageId}
            dynamicWidgetId={widget.dynamicWidgetId!}
            model={scoreOverridePopupData}
            onSuccessfulUpdate={onUpdateScore}
            onCloseModal={clearScoreOverrideModalData}
            axisSettings={axisSettings}
          />
        )}
    </>
  );
}

interface NineBoxGridEditorEditButtonProps {
  row: NineBoxGridEditorDataItem;
  axis: NineBoxGridScoreOverrideAxis;
  openScoreOverrideModal(
    rowData: NineBoxGridEditorDataItem,
    axis: NineBoxGridScoreOverrideAxis,
    isOpen: boolean
  ): void;
}

const NineBoxGridEditorEditButton = ({
  row,
  axis,
  openScoreOverrideModal,
}: NineBoxGridEditorEditButtonProps) => {
  const displayValue =
    axis === "X" ? row.xScore.displayValue : row.yScore.displayValue;
  const tooltipValue =
    axis === "X" ? row.xScore.tooltipValue : row.yScore.tooltipValue;
  return (
    <button
      className="cursor-pointer primary-colour underline underline-offset-2 decoration-dotted hover:decoration-solid group"
      onClick={() => openScoreOverrideModal(row, axis, true)}
    >
      <Tooltip
        triggerElement={
          <span className="flex flex-row">
            <span className="flex-grow">{displayValue}</span>
            <span className="flex-initial">
              <FontAwesomeIcon
                icon={faPencilAlt}
                size="sm"
                className="ml-1 invisible group-hover:visible text-gray-500"
              />
            </span>
          </span>
        }
        content={tooltipValue}
      />
    </button>
  );
};

export default NineBoxGridEditor;
