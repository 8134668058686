import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface TinyLoaderProps {
  /** A hex colour code, without the # at the start */
  colour?: string;
  /** True by default */
  isLoading?: boolean;
  size?: SizeProp;
  classNames?: string | undefined;
}

function TinyLoader({
  colour = "cccccc",
  isLoading = true,
  size = "1x",
  classNames = undefined,
}: TinyLoaderProps) {
  if (!isLoading) return null;

  return (
    <FontAwesomeIcon
      icon={faSpinnerThird}
      size={size}
      spin
      style={{ color: `#${colour}` }}
      className={classNames}
    />
  );
}

export default TinyLoader;
