import UserWalkthroughSteps from "../../types/generic/UserWalkthroughSteps";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { t } from "i18next";
import userApi from "../../api/user/userApi";
import { useAuth } from "react-oidc-context";
import UserwalkthroughType from "../../types/generic/UserWalkthroughType";
import UserContext from "../../state/UserContext";
import { useCheckMobileScreen } from "../../hooks";
import React, { useState } from "react";
import UserWalkthroughIntroPopUp from "./UserWalkthroughIntroPopUp";
import UserWalkthroughFinalStep from "./UserWalkthroughFinalStep";

interface UserWalkthroughProps {
  walkthroughType: UserwalkthroughType;
  declinedWalkthrough: boolean;
  steps: UserWalkthroughSteps[];
  onFinished(): void;
}

function UserWalkthrough({
  walkthroughType,
  declinedWalkthrough,
  steps,
  onFinished,
}: UserWalkthroughProps) {
  const translatedSteps = steps.map((x) => {
    return {
      target: x.target,
      content: t(x.contentTranslationKey),
      disableBeacon: true,
    };
  });

  const auth = useAuth();
  const isMobile = useCheckMobileScreen();
  const apiUsers = new userApi(auth.user?.access_token);
  const userContext = React.useContext(UserContext);
  const primaryColour = userContext.user.client.primaryHexColour;
  const [showWalkthroughFinalStep, setShowWalkthroughFinalStep] =
    useState<boolean>(false);
  const [showWalkthroughIntroStep, setShowWalkthroughIntroStep] =
    useState<boolean>(!declinedWalkthrough);
  const [runWalkthrough, setRunWalkthrough] = useState<boolean>(false);

  const handleJoyrideCallback = (data: any) => {
    const { status, action } = data;
    if ([STATUS.FINISHED].includes(status)) {
      // Call the API to load the necessary state
      apiUsers.SetWalkThroughSeen(
        walkthroughType,
        () => {},
        (error) => {
          console.log("User api error", error); // TODO: Handle error properly
        }
      );
      if (!declinedWalkthrough) {
        setShowWalkthroughFinalStep(true);
      } else {
        onFinished();
      }
    } else if ([ACTIONS.CLOSE, ACTIONS.STOP].includes(action)) {
      setRunWalkthrough(false);
      onFinished();
    }
  };

  const handleShowHideFinalStep = (isVisible: boolean) => {
    setShowWalkthroughFinalStep(isVisible);
    if (!isVisible) {
      onFinished();
    }
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        steps={translatedSteps}
        run={declinedWalkthrough ? true : runWalkthrough}
        continuous={true}
        locale={{ last: declinedWalkthrough ? t("Walkthrough.Common.Finish") : t("Walkthrough.Common.Next") }}
        scrollDuration={500}
        spotlightPadding={0}
        disableScrolling={isMobile ? false : true}
        disableOverlayClose={true}
        disableScrollParentFix={true}
        showProgress={true}
        styles={{
          options: {
            primaryColor: `#${primaryColour}`,
            zIndex: 300,
          },
        }}
      />
      <UserWalkthroughIntroPopUp
        walkthroughType={walkthroughType}
        isOpen={showWalkthroughIntroStep}
        onOpenChange={setShowWalkthroughIntroStep}
        runWalkthrough={setRunWalkthrough}
        onWalkthroughFinished={onFinished}
      />
      <UserWalkthroughFinalStep
        walkthroughType={walkthroughType}
        isOpen={showWalkthroughFinalStep}
        onOpenChange={handleShowHideFinalStep}
        runWalkthrough={setRunWalkthrough}
      />
    </>
  );
}

export default UserWalkthrough;
