import { useEffect, useState } from "react";
import cx from "classnames";
import { EmployeeDashboardUpdateDto } from "../../../types/dtos/dashboards";
import { Accordion } from "../../common";
import { AccordionItem } from "../../common/Accordion";
import EmployeeUpdateHeader from "./EmployeeUpdateHeader";
import EmployeeUpdateBody from "./EmployeeUpdateBody";

interface EmployeeJourneyUpdatesProps {
  useShorterText: boolean;
  /** Any updates relating to open collab docs or dual prep flows */
  updates: EmployeeDashboardUpdateDto[];
  /** A function which changes the user's current journey and refreshes the journey widget */
  changeJourney: (journeyRef: string) => void;
}

function EmployeeJourneyUpdates({
  useShorterText,
  updates,
  changeJourney,
}: EmployeeJourneyUpdatesProps) {
  const [activeItemId, setActiveItemId] = useState<string>("0"); // Select the first update item for the active accordion item by default

  useEffect(() => {
    setActiveItemId("0");
  }, [updates]);

  // If no updates to render, return null
  if (!updates) return null;

  const onAccordionActiveSectionChange = (sectionId: string | string[]) => {
    // Don't allow multiple active sections, so we can safely assume it's a single string
    setActiveItemId(sectionId as string);
  };

  const accordionItemKeys = updates.map((upd, updIx) => updIx.toString());

  return (
    <div id="journey-updates-container">
      <h2 className="!text-white !mt-0">What's next&hellip;</h2>
      <Accordion
        allowMultipleActiveSections={false}
        activeSection={activeItemId}
        onActiveSectionChange={onAccordionActiveSectionChange}
        allowCollapseOpenItem={false}
        allItemKeys={accordionItemKeys}
      >
        {updates.map((update, updateIndex) => {
          const itemIsExpanded = updateIndex.toString() === activeItemId;
          return (
            <AccordionItem
              itemValue={updateIndex.toString()}
              showChevronIcons={false}
              trigger={
                <EmployeeUpdateHeader
                  useShorterText={useShorterText}
                  update={update}
                  isExpanded={itemIsExpanded}
                />
              }
              content={
                <EmployeeUpdateBody
                  update={update}
                  changeJourney={changeJourney}
                />
              }
              classNames={cx(
                "rounded-md my-2 p-4",
                itemIsExpanded ? "bg-white/20" : "bg-white/10"
              )}
              key={`${updateIndex}`}
            />
          );
        })}
      </Accordion>
    </div>
  );
}

export default EmployeeJourneyUpdates;
