import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-duotone-svg-icons";
import { t } from "i18next";

interface DottedLoaderProps {
  /** A hex colour code, without the # at the start */
  colour?: string;
  /** True by default */
  isLoading?: boolean;
}

function DottedLoader({
  colour = "737373",
  isLoading = true,
}: DottedLoaderProps) {
  if (!isLoading) return null;

  return (
    <div className="flex items-center justify-center" title={t("Common.Loading")}>
      <FontAwesomeIcon
        icon={faCircle}
        size="xs"    
        beat
        beatFade={true}
        className="dotted-loader-outers pr-1"
        style={{ color: `#${colour}` }}
      />
      <FontAwesomeIcon
        icon={faCircle}
        size="xs"    
        beat
        beatFade={true}  
        className="dotted-loader-inner pr-1"
        style={{ color: `#${colour}` }}
      />
      <FontAwesomeIcon
        icon={faCircle}
        size="xs"    
        beat
        beatFade={true}  
        className="dotted-loader-outers" 
        style={{ color: `#${colour}` }}
      />
    </div>
  );
}

export default DottedLoader;
