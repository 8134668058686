import { useCallback, useContext, useEffect, useState } from "react";
import cx from "classnames";
import { t } from "i18next";
import {
  FormQuestion,
  FormType,
  ValidationResult,
} from "../../../../types/forms";
import { TextArea } from "../../../common";
import CollabDocFlaggedChangeDto from "../../../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import CollabDocFlaggedChange from "../../../collab-docs/CollabDocFlaggedChange";
import { UserBasicDetailsDto } from "../../../../types/dtos/generic";
import UserContext from "../../../../state/UserContext";
import { FormConstants } from "../../../../config/forms/FormConstants";

interface GoalReviewEnforcedCommentProps {
  formId: number;
  goalId: number;
  questionText: string;
  placeholderText: string | null;
  commentValue: string | undefined;
  formType: FormType;
  isReadOnly: boolean;
  showValidationErrors: boolean;
  onChange(goalId: number, newValue: string, clientFormId: number): void;
  onBlur(goalId: number, newValue: string): void;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;
  /** Participant details are used in collab docs for flagged changes */
  participants?: Array<UserBasicDetailsDto> | undefined;
  question: FormQuestion;
}

function GoalReviewEnforcedComment({
  formId,
  goalId,
  questionText,
  placeholderText,
  commentValue,
  formType,
  isReadOnly,
  showValidationErrors,
  onChange,
  onBlur,
  flaggedChanges,
  participants,
  question,
}: GoalReviewEnforcedCommentProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);

  // Side-effects
  useEffect(() => {
    // Set the validation result
    if (commentValue && commentValue.trim().length > 0) {
      setValidationResult(null);
    } else {
      setValidationResult({
        isValid: false,
        errors: [{ errorType: "REQUIRED" }],
      });
    }
  }, [goalId, commentValue]);

  // Events
  const handleChange = useCallback(
    (newValue: string) => {
      onChange(goalId, newValue, formId);
    },
    [goalId, onChange]
  );

  const handleBlur = useCallback(
    (newValue: string) => {
      onBlur(goalId, newValue);
    },
    [goalId, onBlur]
  );

  // Calculated values
  const goalFlaggedChanges = flaggedChanges?.filter((x) => x.goalId === goalId);

  const commentFlaggedChange = goalFlaggedChanges?.find(
    (x) => x.changeType === "ENFORCED-COMMENT"
  );

  // If there's a comment change create
  // the component so we can render it before the text field
  const flaggedChangeComponent =
    commentFlaggedChange && participants ? (
      <CollabDocFlaggedChange
        change={commentFlaggedChange}
        loggedInUserId={userContext.user.id}
        participants={participants}
        question={question}
        relatedComments={[]}
        enforcedCommentText={undefined}
      />
    ) : null;

  return (
    <div>
      <label
        className={cx(
          "font-medium",
          formType === "JOURNEY" ? "text-white" : ""
        )}
      >
        {t(questionText)}
      </label>
      {flaggedChangeComponent}
      <TextArea
        minRows={2}
        maxRows={5}
        placeholder={
          placeholderText
            ? t(placeholderText)
            : t("Common.TextboxDefaultPlaceholder")
        }
        onChange={handleChange}
        onBlur={handleBlur}
        value={commentValue}
        isReadOnly={isReadOnly}
        showValidationErrors={showValidationErrors}
        validationResult={validationResult}
        inputId={`goalComment_${goalId}`}
        className={cx(
          "mt-2 p-2 w-full rounded-md bg-white/20  placeholder:text-white/40 placeholder:text-sm focus:outline-none focus:ring-0",
          formType === "COLLAB-DOC" ? "border-gray-400 border" : "border-0"
        )}
        maxLength={FormConstants.MaxLengths.AnswerSetComment}
      />
    </div>
  );
}

export default GoalReviewEnforcedComment;
