import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

interface SmallLoaderProps {
  /** A hex colour code, without the # at the start */
  colour?: string;
  /** True by default */
  isLoading?: boolean;
}

function SmallLoader({
  colour = "cccccc",
  isLoading = true,
}: SmallLoaderProps) {
  if (!isLoading) return null;

  return (
    <div className="flex items-center justify-center">
      <FontAwesomeIcon
        icon={faSpinnerThird}
        size="3x"
        spin
        className="loader-icon"
        style={{ color: `#${colour}` }}
      />
    </div>
  );
}

export default SmallLoader;
