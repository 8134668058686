import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { DateInput, GenericDropDownList, Label, TextInput } from "../../common";
import { ClientTaskTypeCategory } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { KeyValuePair } from "../../../types/generic";
import taskValidationHelper from "../../../helpers/taskValidationHelper";
import SimpleFormQuestionMoreInfo from "../../simple-forms/editable/SimpleFormQuestionMoreInfo";
import { AdvancedTaskMainDetailsValidationResult } from "../../../types/tasks/AdvancedTaskMainDetailsValidationResult";
import { advancedTaskHelper, dateHelper } from "../../../helpers";
import cx from "classnames";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { SimpleFormContent } from "../../../types/dtos/simple-forms";

interface AdvancedTaskCommonFieldSectionProps {
  isReadOnly: boolean;
  categories: ClientTaskTypeCategory[];
  categoryId?: string | null;
  setCategoryId?(category: string): void;
  validationResults: AdvancedTaskMainDetailsValidationResult;
  setValidationResults: (
    validationResults: AdvancedTaskMainDetailsValidationResult
  ) => void;
  targetDate: Date | null | undefined;
  setTargetDate(targetDate: Date): void;
  title: string | null | undefined;
  setTitle(category: string): void;
  showValidationErrors: boolean;
  taskType: ClientTaskType;
  /** Used to work out whether or not there's a valid automatic title setting question in the body form */
  bodyFormContent: SimpleFormContent | null;
}

const AdvancedTaskCommonFieldSection = ({
  isReadOnly,
  categories,
  categoryId,
  setCategoryId,
  targetDate,
  setTargetDate,
  title,
  setTitle,
  showValidationErrors,
  validationResults,
  setValidationResults,
  taskType,
  bodyFormContent,
}: AdvancedTaskCommonFieldSectionProps) => {
  const { t } = useTranslation();

  const titleTranslationText =
    taskType.titleTranslationKeyIdentifier !== null
      ? t(taskType.titleTranslationKeyIdentifier)
      : t("TaskType.Popup.Labels.Description");

  const titlePlaceholderTranslationText =
    taskType.titlePlaceholderTranslationKeyIdentifier !== null
      ? t(taskType.titlePlaceholderTranslationKeyIdentifier)
      : t("TaskType.Popup.Placeholder.Title");

  const selectedCategoryNameTranslationIdentifier = categories.find(
    (c) => c.categoryId === categoryId
  )?.nameTranslationKeyIdentifier;

  const [categoryOptions, setCategoryOptions] = useState<
    KeyValuePair<string, string>[]
  >([]);
  const [showTypeMoreInfoPanel, setShowTypeMoreInfoPanel] =
    useState<boolean>(false);
  const [showDescriptionMoreInfoPanel, setShowDescriptionMoreInfoPanel] =
    useState<boolean>(false);

  useEffect(() => {
    if (categories) {
      setCategoryOptions(
        categories.map((c) => {
          return {
            key: c.categoryId,
            value: t(c.nameTranslationKeyIdentifier),
          };
        })
      );
    }
  }, [categories]);

  const handleCategoryChange = (value: string) => {
    if (setCategoryId != null) {
      setCategoryId(value);
      if (showValidationErrors) {
        setValidationResults({
          ...validationResults,
          category: taskValidationHelper.isCategoryValid(value, categories),
        });
      }
    }
  };

  const handleTargetDateChange = (value: Date) => {
    setTargetDate(value);
    if (showValidationErrors) {
      setValidationResults({
        ...validationResults,
        targetDate: taskValidationHelper.isTargtDateValid(value),
      });
    }
  };

  const handleTitleChange = (value: string) => {
    setTitle(value);
    if (showValidationErrors) {
      setValidationResults({
        ...validationResults,
        title: taskValidationHelper.isTitleValid(value),
      });
    }
  };

  const toggleShowTypeMoreInfoPanel = () => {
    setShowTypeMoreInfoPanel(!showTypeMoreInfoPanel);
  };

  const toggleShowDescriptionMoreInfoPanel = () => {
    setShowDescriptionMoreInfoPanel(!showDescriptionMoreInfoPanel);
  };

  // Only show the title field if there is no automatic title setting question in the body form
  const showTitle =
    bodyFormContent !== null &&
    advancedTaskHelper.getAutomaticTitleSettingQuestion(
      taskType,
      bodyFormContent
    ) === null;

  return (
    <div className="text-sm flex flex-col">
      <div className="flex flex-row gap-4">
        {/* Show dropdown if there are categories to render  */}
        {categories.length > 0 && (
          <div className="w-1/2 py-2">
            <div className="esf-question-label">
              <Label
                text={t("TaskType.Popup.Labels.Type")}
                className={cx(
                  "text-gray-700 text-base font-medium",
                  isReadOnly ? "font-semibold" : ""
                )}
              />
              {(taskType.categoryPopupTranslationKeyIdentifier ||
                taskType.categoryPopupDestinationUrl) && (
                <SimpleFormQuestionMoreInfo
                  isReadOnly={isReadOnly}
                  hasPopupType={false}
                  popupTriggerText="TaskType.Popup.Tooltip.Type"
                  popupHtmlContent={
                    taskType.categoryPopupTranslationKeyIdentifier
                  }
                  popupUrlDestination={taskType.categoryPopupDestinationUrl}
                  showMoreInfoPanel={showTypeMoreInfoPanel}
                  toggleShowMoreInfoPanel={toggleShowTypeMoreInfoPanel}
                />
              )}
            </div>
            <div
              className={`task-field ${
                showValidationErrors &&
                validationResults.category &&
                validationResults.category.isValid === false
                  ? "invalid"
                  : ""
              }`}
            >
              {!isReadOnly ? (
                <GenericDropDownList
                  className="w-full bg-gray-100 border-0 text-sm mt-1"
                  applyBorder={true}
                  items={categoryOptions}
                  currentValue={categoryId ?? ""}
                  selectOptionCustomText={t(
                    "TaskType.Popup.Placeholder.SelectType"
                  )}
                  onChange={handleCategoryChange}
                  showValidationErrors={showValidationErrors}
                  validationResult={validationResults.category}
                  isReadOnly={isReadOnly}
                />
              ) : (
                <>
                  {selectedCategoryNameTranslationIdentifier && (
                    <div className="text-gray-600 block w-full border-0 text-sm rounded-md mb-2">
                      {t(selectedCategoryNameTranslationIdentifier)}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div className="w-1/2 py-2">
          <Label
            text={t("TaskType.Popup.Labels.DueDate")}
            className={cx(
              "text-gray-700 text-base font-medium",
              isReadOnly ? "font-semibold" : ""
            )}
          />
          <div
            className={`task-field ${
              showValidationErrors &&
              validationResults.targetDate &&
              validationResults.targetDate.isValid === false
                ? "invalid"
                : ""
            }`}
          >
            {!isReadOnly ? (
              <DateInput
                onChange={handleTargetDateChange}
                showValidationErrors={showValidationErrors}
                validationResult={validationResults.targetDate}
                value={targetDate}
                showTimeSelect={false}
                className="block w-full border-0 bg-gray-100 rounded-md focus:outline-0 focus:ring-0 text-sm mt-1"
                placeholder={t("TaskType.Popup.Placeholder.DueDate")}
                isReadOnly={isReadOnly}
              />
            ) : (
              <>
                {targetDate && (
                  <div className="text-gray-600 block w-full border-0 text-sm rounded-md mb-2">
                    {dateHelper
                      .convertUtcDateToLocal(targetDate)
                      .toLocaleDateString(undefined)}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showTypeMoreInfoPanel &&
        taskType.categoryPopupTranslationKeyIdentifier && (
          <div
            className={
              !isReadOnly
                ? "esf-question-more-info-panel"
                : "esf-question-more-info-panel-readonly"
            }
          >
            {parse(t(taskType.categoryPopupTranslationKeyIdentifier))}
          </div>
        )}
      {showTitle && (
        <div className="py-2">
          <div className="esf-question-label">
            <Label
              text={titleTranslationText}
              className={cx(
                "text-gray-700 text-base font-medium",
                isReadOnly ? "font-semibold" : ""
              )}
            />
            {(taskType.titlePopupTranslationKeyIdentifier ||
              taskType.titlePopupDestinationUrl) && (
              <SimpleFormQuestionMoreInfo
                isReadOnly={isReadOnly}
                hasPopupType={false}
                popupTriggerText="TaskType.Popup.Tooltip.Description"
                popupHtmlContent={taskType.titlePopupTranslationKeyIdentifier}
                popupUrlDestination={taskType.titlePopupDestinationUrl}
                showMoreInfoPanel={showDescriptionMoreInfoPanel}
                toggleShowMoreInfoPanel={toggleShowDescriptionMoreInfoPanel}
              />
            )}
          </div>
          {showDescriptionMoreInfoPanel &&
            taskType.titlePopupTranslationKeyIdentifier && (
              <div
                className={
                  !isReadOnly
                    ? "esf-question-more-info-panel"
                    : "esf-question-more-info-panel-readonly"
                }
              >
                {parse(t(taskType.titlePopupTranslationKeyIdentifier))}
              </div>
            )}
          <div
            className={`task-field ${
              showValidationErrors &&
              validationResults.title &&
              validationResults.title.isValid === false
                ? "invalid"
                : ""
            }`}
          >
            {!isReadOnly ? (
              <TextInput
                value={title ?? ""}
                onChange={handleTitleChange}
                placeholder={titlePlaceholderTranslationText}
                className="block p-2 w-full bg-gray-100 border-0 text-sm mt-1 py-2"
                showValidationErrors={showValidationErrors}
                validationResult={validationResults.title}
                isReadOnly={isReadOnly}
                maxLength={500}
              />
            ) : (
              <>
                {title && (
                  <div className="text-gray-600 block w-full border-0 text-sm rounded-md mb-2">
                    {title}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedTaskCommonFieldSection;
