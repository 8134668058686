interface CustomChartTooltipProps {
  text: any;
  value: any;
}

function CustomChartTooltip({ text, value }: CustomChartTooltipProps) {
  return (
    <div className="bg-white p-2 rounded-md text-center border-[1px] border-gray-600 border-dotted text-sm">
      <b>{text}</b>
      <br />
      {value}
    </div>
  );
}

export default CustomChartTooltip;
