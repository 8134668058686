import React, { useEffect, useReducer, useRef, Reducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { MainContainer } from "../../components/layout";
import UserContext from "../../state/UserContext";
import JourneyDashboardWidget from "../../components/journeys/JourneyDashboardWidget";
import TimelineWidget from "../../components/timeline/TimelineWidget";
import MyFormSummaries from "../../components/form-summaries/MyFormSummaries";
import MyDashboardApiResponseDto from "../../types/dtos/dashboards/MyDashboardApiResponseDto";
import JourneyFormGroupDto from "../../types/dtos/journeys/JourneyFormGroupDto";
import AppContext from "../../state/AppContext";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import DashboardSummaryTabDto from "../../types/dtos/dashboards/DashboardSummaryTabDto";
import { ErrorPopup, ModalPopup } from "../../components/common";
import { interactionHelper } from "../../helpers";
import myDashboardApi from "../../api/dashboard/myDashboardApi";
import { EditableTask } from "../../types/tasks/EditableTasks";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import { TaskType } from "../../types/tasks";
import { useCheckMobileScreen } from "../../hooks";
import TimelineApiResponseDto from "../../types/dtos/timeline/TimelineApiResponseDto";
import { EmployeeDashboardUpdateDto } from "../../types/dtos/dashboards";
import MyDashWalkthrough from "../../components/user-walkthrough/MyDashWalkthrough";
import userApi from "../../api/user/userApi";
import AppRoutes from "../AppRoutes";
import { JourneyAssignmentResponseDto } from "../../types/dtos/journeys";
import SmallLoader from "../../components/loaders/SmallLoader";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";
import { JourneyDeadlineMessage } from "../../components/journeys/JourneyDeadlineMessage";
import DashboardTaskBar from "../../components/dashboard-task-bar/DashboardTaskBar";

interface MyDashboardState {
  waitingForApiResult: boolean;
  userFormSummaries: DashboardSummaryTabDto[];
  journeyFormDetails: JourneyFormGroupDto | undefined | null;
  journeyUpdates: EmployeeDashboardUpdateDto[];
  showUpdates: boolean;
  summariesLoading: boolean;
  includeCompletedOnTimeline: boolean;
  initialTimelineData: TimelineApiResponseDto;
  allTimelineData: TimelineApiResponseDto | undefined;
  mobileTimelineData: TimelineApiResponseDto;
  timelineLoading: boolean;
  subjectUser: BaseUserDetailsDto | null;
  /** Used when we need the user to confirm they want to change their current journey */
  proposedNewJourneyClientFormId: number | null;
  proposedNewDualPrepJourney: string | null;
  showNewJourneyConfirmPrompt: boolean;
  showCannotChangeLockedJourneyPrompt: boolean;
  firstPageLoadCompleted: boolean;
  allowWalkthrough: boolean;
  showPreparingDocumentModal: boolean;
}

function MyDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const isMobile = useCheckMobileScreen();
  const userContext = React.useContext(UserContext);
  const appContext = React.useContext(AppContext);
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const mobileTimelineLoaded = useRef(false);
  const emptyTimelineData = {
    totalGoals: 0,
    totalActions: 0,
    totalLearnings: 0,
    items: [],
  };

  const [state, setState] = useReducer<
    Reducer<MyDashboardState, Partial<MyDashboardState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    userFormSummaries: [],
    journeyFormDetails: null,
    journeyUpdates: [],
    showUpdates: true,
    summariesLoading: true,
    includeCompletedOnTimeline: false,
    initialTimelineData: emptyTimelineData,
    allTimelineData: undefined,
    mobileTimelineData: emptyTimelineData,
    timelineLoading: true,
    subjectUser: null,
    proposedNewJourneyClientFormId: null,
    proposedNewDualPrepJourney: null,
    showNewJourneyConfirmPrompt: false,
    firstPageLoadCompleted: false,
    allowWalkthrough: true,
    showPreparingDocumentModal: false,
    showCannotChangeLockedJourneyPrompt: false,
  });

  const [dashboardMainLoadError, setDashboardMainLoadError] =
    useState<boolean>(false);
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
  const [errorPopupDetails, setErrorPopupDetails] = useState<any | null>(null);
  const [hasJourneyBeenSubmitted, setHasJourneyBeenSubmitted] =
    useState<boolean>(false);
  const [showManageCatchUpMeetingScreen, setShowManageCatchUpMeetingScreen] =
    useState<boolean>(false);
  const usersApi = new userApi(auth.user?.access_token);

  const reloadDashboard = () => {
    setShowErrorPopup(false);
    setDashboardMainLoadError(false);
    setJourneySubmitted(false);
    interactionHelper.deFocusJourney();

    // Call the API to load the necessary state
    const successCallback = (data: MyDashboardApiResponseDto) => {
      setState({
        userFormSummaries: data.summaries,
        summariesLoading: false,
        initialTimelineData: data.timeline,
        journeyFormDetails: data.journey,
        waitingForApiResult: false,
        timelineLoading: false,
        journeyUpdates: data.updates,
        showUpdates: true,
        firstPageLoadCompleted: true,
      });
    };

    const errorCallback = (error: any) => {
      console.error(error);
      setDashboardMainLoadError(true);
      setShowErrorPopup(true);
      setErrorPopupDetails(error);
    };

    dashboardApi.getFullDashboard(successCallback, errorCallback);
  };

  const reloadSummaries = () => {
    setShowErrorPopup(false);
    setDashboardMainLoadError(false);

    // Call the API to load the necessary state
    const successCallback = (summaries: DashboardSummaryTabDto[]) => {
      setState({
        userFormSummaries: summaries,
      });
    };

    const errorCallback = (error: any) => {
      console.error(error);
      setShowErrorPopup(true);
      setErrorPopupDetails(error);
    };

    dashboardApi.getSummaries(successCallback, errorCallback);
  };

  const setJourneySubmitted = (newValue: boolean) => {
    setHasJourneyBeenSubmitted(newValue);

    // If the newValue is true then we should reload the summaries
    if (newValue) {
      reloadSummaries();
    }
  };

  /** Checks whether this is a small screen, and if the mobile timeline items
   * aren't populated, it loads them from the API
   */
  const loadMobileTimelineItemsIfNotAlreadyLoaded = () => {
    if (isMobile && !mobileTimelineLoaded.current) {
      loadMobileTimelineItems();
    }
  };

  const refreshLoggedInUserDetails = (onlyRefreshIfStale: boolean) => {
    // Conditionally, only refresh if the user details are more than 15 seconds old,
    // to prevent unnecessary API calls when the user lands on this homepage
    if (onlyRefreshIfStale && userContext.user.dateLoaded) {
      // Bomb out if we work out it's stale
      const maxAgeInSeconds = 15;
      const secondsSinceLastRefresh =
        (new Date().getTime() - userContext.user.dateLoaded.getTime()) / 1000;
      if (secondsSinceLastRefresh < maxAgeInSeconds) return;
    }

    // Call the API to reload parts of the user's details
    usersApi.getRefreshedLoggedInUserDetails(
      userContext.setUpdatedUserDetails,
      () => {}
    );
  };

  useEffect(() => {
    reloadDashboard();
    loadMobileTimelineItemsIfNotAlreadyLoaded();
    appContext.setPageTitle(t("Pages.MyDashboard.PageTitle"));
    appContext.setActiveDashboardTypeForWalkthrough("MY-DASHBOARD");
    appContext.setShowPageTitleAccent(true);
    refreshLoggedInUserDetails(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadMobileTimelineItemsIfNotAlreadyLoaded();
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadJourneyWidget = () => {
    // Reload the whole dashboard, as summaries and tasks may have changed too
    reloadDashboard();
  };

  const handleSummaryRefresh = () => {
    reloadDashboard();
  };

  /** When the user scrolls to the end of the initial short list, load the full set */
  const loadFullTimeline = (
    callback: (newItems: TimelineApiResponseDto) => void
  ) => {
    setState({ timelineLoading: true });
    const successCallback = (data: TimelineApiResponseDto) => {
      setState({ timelineLoading: false, allTimelineData: data });
      callback(data);
    };

    const errorCallback = (error: any) => {
      console.error(error.message);
      setShowErrorPopup(true);
      setErrorPopupDetails(error.message);
    };

    dashboardApi.loadTimelineData(
      true,
      state.includeCompletedOnTimeline,
      successCallback,
      errorCallback
    );
  };

  /** Loading mobile time line items*/
  const loadMobileTimelineItems = () => {
    // Show the loading spinner, and set `mobileTimelineLoaded` to true
    // to prevent it being called more than once
    setState({ timelineLoading: true });
    mobileTimelineLoaded.current = true;

    const successCallback = (data: TimelineApiResponseDto) => {
      setState({
        timelineLoading: false,
        mobileTimelineData: data,
      });
    };

    const errorCallback = (error: any) => {
      console.error(error.message);
      setShowErrorPopup(true);
      setErrorPopupDetails(error.message);
    };

    dashboardApi.loadTimelineData(
      true,
      state.includeCompletedOnTimeline,
      successCallback,
      errorCallback
    );
  };

  /** Call the API and find and assign the relevant journey to the user,
   * and return the new journey details, and refresh the displayed journey
   */
  const assignDifferentJourneyToUser = (
    clientFormId: number | null,
    exactJourneyRef: string | null
  ) => {
    const onSuccess = (assignmentResult: JourneyAssignmentResponseDto) => {
      if (assignmentResult?.redirectToAnswerSet) {
        // Redirect to the answer set
        const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
          assignmentResult.redirectToAnswerSet,
          false,
          null
        );
        navigate(redirectUrl);
      } else if (assignmentResult?.newJourney) {
        setState({
          journeyFormDetails: assignmentResult.newJourney,
          waitingForApiResult: false,
          showUpdates: false,
        });
      } else {
        setState({
          waitingForApiResult: false,
        });
        setShowErrorPopup(true);
        setErrorPopupDetails(
          assignmentResult?.errorMessage ??
            "Couldn't assign the requested journey to the current user"
        );
      }

      interactionHelper.scrollMainContainerToTop();
    };

    const onError = (error: any) => {
      console.log("Unable to change journey", error);
      setShowErrorPopup(true);
      setErrorPopupDetails(error);
    };

    // Reset the submitted prop
    setJourneySubmitted(false);

    // Show the spinner
    setState({
      waitingForApiResult: true,
    });

    dashboardApi.assignDifferentJourneyToUser(
      clientFormId,
      exactJourneyRef,
      onSuccess,
      onError
    );
  };

  /**Call the api to Add/Edit/Delete task object*/
  const modifyTaskFromDashboard = (
    task: EditableTask<number>,
    successCallback: (taskData: ModifyTaskResponseDto<number>) => void
  ) => {
    const onError = (error: any) => {
      console.log("Unable to update task", error);
    };

    let modifyTaskType = "ADD";
    if (task.taskId != null) {
      modifyTaskType = "EDIT";
    }

    dashboardApi.modifyTaskFromDashboard(
      task,
      modifyTaskType,
      successCallback,
      onError
    );
  };

  /**Call the api to load the editable task */
  const loadEditableTaskDetails = (
    taskId: number,
    taskType: TaskType,
    successCallback: (data: ModifyTaskResponseDto<number>) => void
  ) => {
    const onError = (error: any) => {
      console.log("Unable to update task", error);
    };
    dashboardApi.loadEditableTaskDetails(
      taskId,
      taskType,
      successCallback,
      onError
    );
  };

  //**When a new task is added or edited we want to reload the timeline to display the updated task list */
  const handleTimelineListRefresh = () => {
    const successCallback = (data: TimelineApiResponseDto) => {
      setState({
        initialTimelineData: data,
        mobileTimelineData: data,
      });
    };
    const errorCallback = (error: any) => {
      console.log(error);
    };
    dashboardApi.loadTimelineData(
      false,
      state.includeCompletedOnTimeline,
      successCallback,
      errorCallback
    );
  };

  function onUserClickedSummaryUpdateButton(
    summaryTab: DashboardSummaryTabDto,
    exactJourneyRef: string | null
  ) {
    const hasBeenUpdatedBefore = !!summaryTab.lastCompletedDate;
    if (hasBeenUpdatedBefore) {
      // User has completed this form before, so take them to the most recent completed version
      // so they can use that as a base to edit
      if (summaryTab.lastCompletedAnswerSetId) {
        const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
          summaryTab.lastCompletedAnswerSetId,
          false,
          summaryTab.clientFormId
        );
        navigate(redirectUrl);
      } else {
        console.error("Logical flaw... completed, but no completed answer set");
      }
    } else {
      // User has never completed this form before, so trigger it in the journey UI,
      // unless the current journey is locked (i.e. has a future deadline)

      // If the user is on a journey, then we need to prompt them to confirm they want to start a new one
      // If they're not on a journey, we can just assign the new one
      handleManualNewJourneyAssignment(
        summaryTab.clientFormId,
        exactJourneyRef
      );
    }
  }

  /** When the journey needs to be reloaded, because the user has selected to start a new journey
   * from within a summary section
   */
  function handleManualNewJourneyAssignment(
    clientFormId: number | null,
    exactJourneyRef: string | null
  ) {
    if (!state.journeyFormDetails) {
      // No journey, can assign a new one straight away
      assignDifferentJourneyToUser(clientFormId, exactJourneyRef);
      return;
    }

    if (
      // Form is part of the current dual prep journey, or...
      (exactJourneyRef &&
        exactJourneyRef.length > 0 &&
        state.journeyFormDetails?.journeyReference === exactJourneyRef) ||
      // Form is the one already being completed
      (!exactJourneyRef &&
        clientFormId !== null &&
        state.journeyFormDetails?.forms &&
        state.journeyFormDetails.forms.length === 1 &&
        state.journeyFormDetails.forms[0].formId === clientFormId)
    ) {
      // Form id is already being completed, just scroll the user to the journey and focus it
      interactionHelper.scrollMainContainerToTop();
      interactionHelper.focusJourney();
    } else {
      // There is a journey already
      // If the current journey has a deadline, then we can't change the assigned journey
      const currentJourneyIsLocked =
        state.journeyFormDetails.journeyDeadline &&
        new Date(state.journeyFormDetails.journeyDeadline) > new Date();

      if (currentJourneyIsLocked) {
        setState({ showCannotChangeLockedJourneyPrompt: true });
        return;
      }

      // If it hasn't been submitted then we prompt the user to make sure they want to change journeys
      if (!hasJourneyBeenSubmitted) {
        setState({
          showNewJourneyConfirmPrompt: true,
          proposedNewJourneyClientFormId: clientFormId,
          proposedNewDualPrepJourney: exactJourneyRef,
        });
      } else {
        // If the current journey has been submitted then we can just take the user straight to that
        assignDifferentJourneyToUser(clientFormId, exactJourneyRef);
      }
    }
  }

  const handleChangeToSpecificJourney = (journeyRef: string) => {
    if (
      state.journeyFormDetails &&
      state.journeyFormDetails.journeyReference === journeyRef
    ) {
      // The intended journey is already assigned
      setState({ showUpdates: false });
      interactionHelper.scrollMainContainerToTop();
    } else {
      // The journey is changing, so call the API to change it
      handleManualNewJourneyAssignment(null, journeyRef);
    }
  };

  const setJourneyChangePromptVisibility = (isVisible: boolean) => {
    setState({ showNewJourneyConfirmPrompt: isVisible });
    if (!isVisible) {
      // Reset to clear the proposed journey
      setState({
        proposedNewJourneyClientFormId: null,
        proposedNewDualPrepJourney: null,
      });
    }
  };

  const setJourneyIsLockedPromptVisibility = (isVisible: boolean) => {
    setState({ showCannotChangeLockedJourneyPrompt: isVisible });
  };

  const startCreateAndPrefillCollabDocProcess = () => {
    if (state.journeyFormDetails != null) {
      setShowPreparingDocumentModal(true);
      const journeyDetails = state.journeyFormDetails;

      const successCallback = (data: GenericApiResult) => {
        if (data.successful) {
          const destinationUrl = AppRoutes.collaborativeDocument.generateRoute(
            data.data as string,
            false,
            null
          );
          navigate(destinationUrl);
        }
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      dashboardApi.createAndPrefillDoc(
        journeyDetails.subjectUser.userId,
        journeyDetails.journeyReference,
        successCallback,
        errorCallback
      );
    }
  };

  const setShowPreparingDocumentModal = (isVisible: boolean) => {
    setState({ showPreparingDocumentModal: isVisible });
  };

  const confirmJourneyChange = () => {
    // If a valid form/journey is specified, call the API to change the journey
    if (
      (state.proposedNewJourneyClientFormId &&
        state.proposedNewJourneyClientFormId > 0) ||
      (state.proposedNewDualPrepJourney &&
        state.proposedNewDualPrepJourney.length > 0)
    ) {
      // Load the new form
      assignDifferentJourneyToUser(
        state.proposedNewJourneyClientFormId,
        state.proposedNewDualPrepJourney
      );

      // Hide the modal and clear the state variable for the proposed new form
      setJourneyChangePromptVisibility(false);
    }
  };

  const cancelJourneyChange = () => {
    // Hide the modal and clear the state variable for the proposed new form
    setJourneyChangePromptVisibility(false);
  };

  const onIncludeCompletedChange = (value: boolean) => {
    setState({ includeCompletedOnTimeline: value, timelineLoading: true });

    dashboardApi.loadTimelineData(
      true,
      value,
      (data: TimelineApiResponseDto) => {
        setState({
          timelineLoading: false,
          allTimelineData: data,
          mobileTimelineData: data,
        });
      },
      (error: any) => {
        console.error(error.message);
        setShowErrorPopup(true);
        setErrorPopupDetails(error.message);
      }
    );
  };

  /** Control whether or not to display the list of updates
   * within the journey widget (e.g. a task to complete a collab doc)
   */
  const onToggleJourneyWidgetUpdatesVisibility = (
    shouldShowUpdates: boolean
  ) => {
    setState({ showUpdates: shouldShowUpdates });
  };

  // Get the count of summary tabs displayed on the dashboard
  let userSummaryTabCount = 0;
  if (userContext.user.myDashboardMode !== "EXIT" && state.userFormSummaries) {
    userSummaryTabCount = state.userFormSummaries.length;
  }

  const handleWalkthroughFinished = () => {
    setState({ allowWalkthrough: false });
  };

  return (
    <MainContainer
      rightColumnChildren={
        <>
          {userContext.user.client.usesTaskTypes && (
            <DashboardTaskBar
              taskTypes={userContext.user.client.taskTypes}
              clientColour={"#" + userContext.user.client.primaryHexColour}
              formDetails={state.journeyFormDetails}
              hasJourneyBeenSubmitted={hasJourneyBeenSubmitted}
            />
          )}
          {!userContext.user.client.usesTaskTypes && (
            <TimelineWidget
              itemData={
                state.allTimelineData
                  ? state.allTimelineData
                  : state.initialTimelineData
              }
              mobileItemData={state.mobileTimelineData}
              onRequestAllItems={loadFullTimeline}
              isLoading={state.timelineLoading}
              clientColour={"#" + userContext.user.client.primaryHexColour}
              includeCompleted={state.includeCompletedOnTimeline}
              onIncludeCompletedChange={onIncludeCompletedChange}
              modifyTaskFromDashboard={modifyTaskFromDashboard}
              onLoadTaskToEdit={loadEditableTaskDetails}
              handleTimelineListRefresh={handleTimelineListRefresh}
            />
          )}
        </>
      }
    >
      <div className="pt-2 md:pt-4 lg:pt-6">
        <JourneyDashboardWidget
          formDetails={state.journeyFormDetails}
          isLoading={state.waitingForApiResult}
          onLoadNextJourney={reloadJourneyWidget}
          hasJourneyBeenSubmitted={hasJourneyBeenSubmitted}
          onJourneySubmissionSuccess={setJourneySubmitted}
          showManageCatchUpMeetingScreen={showManageCatchUpMeetingScreen}
          onShowManageCatchUpMeetingScreenChange={
            setShowManageCatchUpMeetingScreen
          }
          userSummaryTabCount={userSummaryTabCount}
          updates={state.journeyUpdates}
          showUpdates={state.showUpdates}
          onToggleUpdatesVisibility={onToggleJourneyWidgetUpdatesVisibility}
          onChangeJourney={handleChangeToSpecificJourney}
          createAndPrefillCollabDoc={startCreateAndPrefillCollabDocProcess}
        />
      </div>

      <MyFormSummaries
        summaries={state.userFormSummaries}
        isLoading={state.summariesLoading}
        onTriggerFormFromSummaryTab={onUserClickedSummaryUpdateButton}
        handleSummaryRefresh={handleSummaryRefresh}
        modifyTaskFromDashboard={modifyTaskFromDashboard}
        onLoadGoalToEdit={loadEditableTaskDetails}
      />

      {/* Confirm journey change prompt */}
      <ModalPopup
        isOpen={state.showNewJourneyConfirmPrompt}
        onOpenChange={setJourneyChangePromptVisibility}
        onPrimaryButtonClick={cancelJourneyChange}
        primaryButtonText={t(
          "Pages.MyDashboard.JourneyChangePrompt.PrimaryButtonText"
        )}
        title={t("Pages.MyDashboard.JourneyChangePrompt.Title")}
        secondaryButtonText={t(
          "Pages.MyDashboard.JourneyChangePrompt.SecondaryButtonText"
        )}
        onSecondaryButtonClick={confirmJourneyChange}
      >
        <p>{t("Pages.MyDashboard.JourneyChangePrompt.Body")}</p>
      </ModalPopup>

      <ModalPopup
        isOpen={state.showPreparingDocumentModal}
        onOpenChange={setShowPreparingDocumentModal}
        title={t("Pages.MyDashboard.PreparingYourDocModal.Title")}
        showCloseIcon={false}
        width="SMALL"
      >
        <div className="text-center pt-4">
          <SmallLoader colour={userContext.user.client.accentHexColour} />
          <p className="mt-4 mb-2">
            {t("Pages.MyDashboard.PreparingYourDocModal.Body")}
          </p>
        </div>
      </ModalPopup>

      <ModalPopup
        isOpen={state.showCannotChangeLockedJourneyPrompt}
        onOpenChange={setJourneyIsLockedPromptVisibility}
        title={t("Pages.MyDashboard.JourneyLockedPrompt.Title")}
        width="SMALL"
        onPrimaryButtonClick={() => {
          setJourneyIsLockedPromptVisibility(false);
        }}
        primaryButtonText={t("Common.OK")}
      >
        <p className="py-4">
          {t("Pages.MyDashboard.JourneyLockedPrompt.Body")}
          {state.journeyFormDetails?.journeyDeadline && (
            <JourneyDeadlineMessage
              classnames="ml-1"
              deadline={state.journeyFormDetails.journeyDeadline}
            />
          )}
        </p>
      </ModalPopup>

      <ErrorPopup
        error={errorPopupDetails}
        onTogglePopup={setShowErrorPopup}
        showPopup={showErrorPopup}
        customClickEvent={dashboardMainLoadError ? reloadDashboard : undefined}
      />

      {state.firstPageLoadCompleted &&
        state.allowWalkthrough &&
        userContext.user.walkthroughSeen != null &&
        !userContext.user.walkthroughSeen.includes("MY-DASHBOARD") && (
          <MyDashWalkthrough
            hasClientGotAdvancedTaskTypes={
              userContext.user.client.taskTypes.length > 0
            }
            onFinished={handleWalkthroughFinished}
          />
        )}
    </MainContainer>
  );
}

export default MyDashboard;
