import { SavedCommentDto } from "../types/dtos/forms";

export const commentsHelper = {
  getUnseenCommentCount: (
    comments: SavedCommentDto[] | null,
    loggedInUserId: number
  ) => {
    return comments
      ? comments.filter(
          (x) =>
            !x.seen &&
            x.authorId !== loggedInUserId &&
            x.commentType === "STANDARD"
        ).length
      : 0;
  },
};

export default commentsHelper;
