import download from "downloadjs";
import AnalyticsFilterInputs from "../../types/analytics/AnalyticsFilterInputs";
import IAnalyticsDataItem from "../../types/analytics/IAnalyticsDataItem";
import { PagedAnalyticsTableDataDto } from "../../types/analytics/tables/AnalyticsTables";
import { apiClient } from "../apiClient";
import { KeyValuePair } from "../../types/generic";

class analyticsCustomEndpointApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the datatable for the widget */
  getUiTableData<TDataItem extends IAnalyticsDataItem>(
    /** Should not start with a forward slash */
    apiEndpoint: string,
    filters: AnalyticsFilterInputs,
    employeeSearchPhrase: string | null,
    pageNumber: number,
    pageSize: number,
    otherQueryParams: KeyValuePair<string, string>[] | null,
    onSuccess: (data: PagedAnalyticsTableDataDto<TDataItem>) => void,
    onError: (error: any) => void
  ) {
    const hasSearchValue =
      employeeSearchPhrase && employeeSearchPhrase.trim().length > 0;
    const hasQuestionMark = apiEndpoint.indexOf("?") > -1;
    let apiUrl = `${apiEndpoint}${
      hasQuestionMark ? "&" : "?"
    }pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (employeeSearchPhrase && employeeSearchPhrase.trim().length > 0) {
      apiUrl += `&empSearch=${encodeURIComponent(employeeSearchPhrase)}`;
    }

    // If there are any bespoke query params, append them to the querystring
    if (otherQueryParams && otherQueryParams.length > 0) {
      otherQueryParams.forEach((kvp) => {
        apiUrl += `&${encodeURIComponent(kvp.key)}=${encodeURIComponent(
          kvp.value
        )}`;
      });
    }

    return apiClient(this.accessToken, apiUrl, {
      body: JSON.stringify(filters),
    }).then(
      (data) => {
        if (data !== null) {
          const pageData = data as PagedAnalyticsTableDataDto<TDataItem>;
          onSuccess(pageData);
        } else {
          onError(hasSearchValue ? "No search results" : "No data");
        }
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Download the spreadsheet from the specified url */
  downloadSpreadsheetFile(
    /** Should not start with a forward slash */
    apiEndpoint: string,
    filenameWithoutExtension: string,
    filters: AnalyticsFilterInputs,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      apiEndpoint,
      {
        body: JSON.stringify(filters),
      },
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        download(
          blob,
          `${filenameWithoutExtension}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }
}

export default analyticsCustomEndpointApi;
