import { useContext, useEffect, useRef, useState } from "react";
import { t } from "i18next";

import JourneyEmptyState from "./JourneyEmptyState";
import JourneyForm from "./JourneyForm";
import JourneyFormGroupDto from "../../types/dtos/journeys/JourneyFormGroupDto";
import { JourneyFormDto } from "../../types/dtos/journeys";
import SmallLoader from "../loaders/SmallLoader";
import { ScrollPageMainContainerToTop } from "../layout/MainContainer";
import UserContext from "../../state/UserContext";
import FocusedJourneyCloseButton from "./FocusedJourneyCloseButton";
import { EmployeeDashboardUpdateDto } from "../../types/dtos/dashboards";
import JourneyWidgetLayout from "./JourneyWidgetLayout";

interface JourneyWidgetProps {
  isLoading: boolean;
  formDetails: JourneyFormGroupDto | undefined | null;
  onLoadNextJourney(): void;
  hasJourneyBeenSubmitted: boolean;
  onJourneySubmissionSuccess(value: boolean): void;
  showManageCatchUpMeetingScreen: boolean;
  onShowManageCatchUpMeetingScreenChange(value: boolean): void;
  /** The count of form summary tabs displayed on the user's dashboard */
  userSummaryTabCount: number;
  /** Any updates relating to open collab docs or dual prep flows */
  updates: EmployeeDashboardUpdateDto[];
  showUpdates: boolean;
  onToggleUpdatesVisibility: (showShouldUpdates: boolean) => void;
  /** A function which changes the user's current journey and refreshes the journey widget */
  onChangeJourney: (journeyRef: string) => void;
  createAndPrefillCollabDoc: () => void;
}

function JourneyDashboardWidget({
  formDetails,
  isLoading,
  onLoadNextJourney,
  hasJourneyBeenSubmitted,
  onJourneySubmissionSuccess,
  showManageCatchUpMeetingScreen,
  onShowManageCatchUpMeetingScreenChange,
  userSummaryTabCount,
  updates,
  showUpdates,
  onToggleUpdatesVisibility,
  onChangeJourney,
  createAndPrefillCollabDoc,
}: JourneyWidgetProps) {
  const widgetRef = useRef(null);
  const userContext = useContext(UserContext);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(true);
  const [welcomeMessageSeen, setWelcomeMessageSeen] = useState<boolean>(false);

  const [showContinueJourneyMessage, setShowContinueJourneyMessage] =
    useState<boolean>(false);
  const [continueJourneyMessageSeen, setContinueJourneyMessageSeen] =
    useState<boolean>(false);

  const [formTitle, setFormTitle] = useState<string | undefined>(
    formDetails?.title != null
      ? formDetails?.title
      : formDetails?.forms[0]?.title
  );
  const [formBgColour, setFormBgColour] = useState<string>(
    formDetails?.backgroundColour
      ? formDetails?.backgroundColour
      : userContext.user.client.primaryHexColour
  );

  // When the form details load for the first time, show the continue journey message (if relevant) or the welcome message (if there is one)
  // and set whether the arrange meeting screen should show after submission.
  useEffect(() => {
    const hasFormDetails = formDetails !== undefined && formDetails !== null;

    // Work out whether or not to display the "continue journey" screen
    const shouldShowContinueJourneyMessage =
      hasFormDetails &&
      formDetails.loadedAsPartiallyComplete &&
      !continueJourneyMessageSeen;
    setShowContinueJourneyMessage(shouldShowContinueJourneyMessage);

    // Each time the form changes, reset this flag
    setWelcomeMessageSeen(false);

    // Work out whether or not to display the journey welcome message
    const shouldShowWelcomeMessage =
      hasFormDetails &&
      !shouldShowContinueJourneyMessage &&
      formDetails.welcomeMessage !== null &&
      formDetails.welcomeMessage.length > 0;
    setShowWelcomeMessage(shouldShowWelcomeMessage);

    onShowManageCatchUpMeetingScreenChange(
      formDetails?.journeyType !== "INSTANT-SUBMIT"
    );
  }, [formDetails]);

  useEffect(() => {
    setFormBgColour(
      formDetails?.backgroundColour
        ? formDetails?.backgroundColour
        : userContext.user.client.primaryHexColour
    );
  }, [formDetails, userContext.user.client.primaryHexColour]);

  const onFormChange = (formDto: JourneyFormDto) => {
    setFormTitle(
      formDetails?.title != null ? formDetails?.title : formDto.title
    );
  };

  const onHideWelcomeMessage = () => {
    setShowWelcomeMessage(false);
    setWelcomeMessageSeen(true);
    ScrollPageMainContainerToTop();
    onToggleUpdatesVisibility(false);
  };

  const onHideContinueJourneyMessage = () => {
    setShowContinueJourneyMessage(false);
    setContinueJourneyMessageSeen(true);
    setShowWelcomeMessage(false); // Don't show the welcome message on continued journeys
    ScrollPageMainContainerToTop();
    onToggleUpdatesVisibility(false);
  };

  const scrollToWidgetTop = () => {
    if (widgetRef.current) {
      // @ts-ignore
      widgetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const hasUpdatesToDisplay = updates && updates.length > 0;
  const onlyUpdateIsForAssignedJourney =
    hasUpdatesToDisplay &&
    updates.length === 1 &&
    formDetails !== null &&
    formDetails !== undefined &&
    updates[0].journey != null &&
    updates[0].journey.journeyReference === formDetails.journeyReference;
  const renderUpdates =
    showUpdates && hasUpdatesToDisplay && !onlyUpdateIsForAssignedJourney;
  const displayHeader = formTitle && formTitle.length > 0 && !renderUpdates;

  return (
    <>
      <div id="focused-journey-bg"></div>
      <div id="focused-journey-wrapper" ref={widgetRef}>
        <FocusedJourneyCloseButton />
        <div
          id="journey-widget"
          className={displayHeader ? "with-header" : ""}
          style={{
            backgroundColor: `#${formBgColour}`,
          }}
        >
          {displayHeader && (
            <div className="rounded-t-lg px-2 py-2 lg:px-6 !text-white/90 font-normal bg-white/20">
              {formDetails?.isInPrepMode
                ? `${t(formTitle)} (${t("Common.Planning")})`
                : t(formTitle)}
            </div>
          )}
          <div
            id="journey-form-scroller"
            style={{
              background:
                "linear-gradient(to bottom right, #fff -280%,#" +
                `${formBgColour} 30%` +
                `,#${formBgColour} 75%` +
                ",#fff 250%)",
            }}
          >
            <div
              id="journey-widget-inner"
              className={
                !formDetails && !renderUpdates
                  ? "flex flex-col justify-center items-center"
                  : "flex flex-col justify-stretch items-stretch"
              }
            >
              {isLoading && <SmallLoader />}
              {!isLoading && (
                <JourneyWidgetLayout
                  updates={updates}
                  showUpdates={renderUpdates}
                  isShowingEmptyState={!formDetails}
                  changeJourney={onChangeJourney}
                  showNoManagerWarning={userContext.user.isFullyManagerless}
                >
                  <>
                    {!formDetails && !renderUpdates && (
                      <JourneyEmptyState
                        userSummaryTabCount={userSummaryTabCount}
                        userStatus={userContext.user.myDashboardMode}
                      />
                    )}
                    {formDetails && (
                      <JourneyForm
                        formTitle={formTitle}
                        formDetails={formDetails}
                        onFormChange={onFormChange}
                        isInPrepMode={formDetails.isInPrepMode}
                        showContinueJourneyMessage={showContinueJourneyMessage}
                        updatesAreVisible={renderUpdates}
                        onHideContinueJourneyMessage={
                          onHideContinueJourneyMessage
                        }
                        showWelcomeMessage={showWelcomeMessage}
                        onHideWelcomeMessage={onHideWelcomeMessage}
                        onLoadNextJourney={onLoadNextJourney}
                        hasJourneyBeenSubmitted={hasJourneyBeenSubmitted}
                        onJourneySubmissionSuccess={onJourneySubmissionSuccess}
                        showManageCatchUpMeetingScreen={
                          showManageCatchUpMeetingScreen
                        }
                        onShowManageCatchUpMeetingScreenChange={
                          onShowManageCatchUpMeetingScreenChange
                        }
                        onToggleUpdatesVisibility={onToggleUpdatesVisibility}
                        updateCount={updates ? updates.length : 0}
                        onlyUpdateIsForAssignedJourney={
                          onlyUpdateIsForAssignedJourney
                        }
                        createAndPrefillCollabDoc={createAndPrefillCollabDoc}
                        scrollToWidgetTop={scrollToWidgetTop}
                      />
                    )}
                  </>
                </JourneyWidgetLayout>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JourneyDashboardWidget;
