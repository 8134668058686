import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilters } from "@fortawesome/pro-duotone-svg-icons";
import { Badge, CheckBox, ModalPopup } from "../../common";
import FilterEditIcon from "./FilterEditIcon";
import AdvancedFilterModal from "./AdvancedFilterModal";
import CheckboxTreeNodeDto from "../../../types/dtos/generic/CheckboxTreeNodeDto";
import FilterCheckboxTreeApiResponseDto from "../../../types/analytics/FilterCheckboxTreeApiResponseDto";
import locationApi from "../../../api/dashboard/locationApi";
import jobTitleApi from "../../../api/dashboard/jobTitleApi";
import appraisalLevelApi from "../../../api/dashboard/appraisalLevelApi";
import AnalyticsFilterInputs from "../../../types/analytics/AnalyticsFilterInputs";

interface AdvancedFiltersProps {
  inEditMode: boolean;
  selectedValues: AnalyticsFilterInputs;
  onBeginEditMode(): void;
  onLocationPickerChange: (selectedLocationIds: number[]) => void;
  onAppraisalLevelPickerChange: (selectedAppraisalLevelIds: number[]) => void;
  onJobTitlePickerChange: (selectedJobTitleIds: number[]) => void;
  onChangeApprovedDataOnly: (newValue: boolean) => void;
  onChangeIncludeLeavers: (newValue: boolean) => void;
}

type AnalyticsAdvancedFilterType = "JOB-TITLE" | "APPRAISAL-LEVEL" | "LOCATION";

function AdvancedFilters({
  inEditMode,
  selectedValues,
  onBeginEditMode,
  onLocationPickerChange,
  onAppraisalLevelPickerChange,
  onJobTitlePickerChange,
  onChangeApprovedDataOnly,
  onChangeIncludeLeavers,
}: AdvancedFiltersProps) {
  const advancedFilterItems: Array<{
    type: AnalyticsAdvancedFilterType;
    text: string;
  }> = [
    { type: "JOB-TITLE", text: "Job Titles" },
    { type: "APPRAISAL-LEVEL", text: "Levels" },
    { type: "LOCATION", text: "Locations" },
  ];

  const auth = useAuth();
  const appLevelApi = new appraisalLevelApi(auth.user?.access_token);
  const locApi = new locationApi(auth.user?.access_token);
  const jTitleApi = new jobTitleApi(auth.user?.access_token);

  // State
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [filterType, setFilterType] =
    useState<AnalyticsAdvancedFilterType | null>(null);
  const [approvedOnlyTicked, setApprovedOnlyTicked] = useState<boolean>(
    selectedValues.includePartiallyApproved === false
  );
  const [includeLeaversTicked, setIncludeLeaversTicked] = useState<boolean>(
    selectedValues.activeUsersOnly === false
  );

  const [locations, setLocations] = useState<CheckboxTreeNodeDto[]>();
  const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);
  const [locationTreeChecked, setLocationTreeChecked] = useState<string[]>([]);
  const [locationTreeExpanded, setLocationTreeExpanded] = useState<string[]>(
    []
  );
  const [selectedLocationCount, setSelectedLocationCount] = useState<number>(
    selectedValues.locationIds?.length ?? 0
  );

  const [jobTitles, setJobTitles] = useState<CheckboxTreeNodeDto[]>();
  const [isJobTitlesLoading, setIsJobTitlesLoading] = useState<boolean>(false);
  const [jobTitleTreeChecked, setJobTitleTreeChecked] = useState<string[]>([]);
  const [jobTitleTreeExpanded, setJobTitleTreeExpanded] = useState<string[]>(
    []
  );
  const [selectedJobTitleCount, setSelectedJobTitleCount] = useState<number>(
    selectedValues.jobTitleIds?.length ?? 0
  );

  const [appraisalLevels, setAppraisalLevels] =
    useState<CheckboxTreeNodeDto[]>();
  const [isAppraisalLevelsLoading, setIsAppraisalLevelsLoading] =
    useState<boolean>(false);
  const [appraisalLevelTreeChecked, setAppraisalLevelTreeChecked] = useState<
    string[]
  >([]);
  const [appraisalLevelTreeExpanded, setAppraisalLevelTreeExpanded] = useState<
    string[]
  >([]);
  const [selectedAppraisalLevelCount, setSelectedAppraisalLevelCount] =
    useState<number>(selectedValues.appraisalLevelIds?.length ?? 0);

  // Hooks
  useEffect(() => {
    // As the applied filters change in the parent state, ensure the modals launch with a matching state,
    // e.g. so the ticked appraisal levels remain ticked when the modal is re-opened
    setSelectedAppraisalLevelCount(
      selectedValues.appraisalLevelIds?.length ?? 0
    );
    setAppraisalLevelTreeChecked(
      selectedValues.appraisalLevelIds?.map((x) => x.toString()) ?? []
    );

    setSelectedLocationCount(selectedValues.locationIds?.length ?? 0);
    setLocationTreeChecked(
      selectedValues.locationIds?.map((x) => x.toString()) ?? []
    );

    setSelectedJobTitleCount(selectedValues.jobTitleIds?.length ?? 0);
    setJobTitleTreeChecked(
      selectedValues.jobTitleIds?.map((x) => x.toString()) ?? []
    );
  }, [
    selectedValues.jobTitleIds,
    selectedValues.appraisalLevelIds,
    selectedValues.locationIds,
  ]);

  /** When the user clicks the primary button from inside an advanced filter modal */
  const onAddFiltersClick = () => {
    setModalIsOpen(false);
    switch (filterType) {
      case "JOB-TITLE":
        setSelectedJobTitleCount(jobTitleTreeChecked.length);
        onJobTitlePickerChange(jobTitleTreeChecked.map((x) => parseInt(x)));
        break;
      case "APPRAISAL-LEVEL":
        setSelectedAppraisalLevelCount(appraisalLevelTreeChecked.length);
        onAppraisalLevelPickerChange(
          appraisalLevelTreeChecked.map((x) => parseInt(x))
        );
        break;
      case "LOCATION":
        setSelectedLocationCount(locationTreeChecked.length);
        onLocationPickerChange(locationTreeChecked.map((x) => parseInt(x)));
        break;
      default:
        break;
    }
  };

  const onLocationFilterClick = (): void => {
    setFilterType("LOCATION");
    if (locations === undefined) {
      setIsLocationsLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
        setLocations(data.nodes);
        setIsLocationsLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      locApi.getLocationCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const onJobTitleFilterClick = (): void => {
    setFilterType("JOB-TITLE");
    if (jobTitles === undefined) {
      setIsJobTitlesLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
        setJobTitles(data.nodes);
        setIsJobTitlesLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      jTitleApi.getJobTitleCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const onAppraisalFilterClick = (): void => {
    setFilterType("APPRAISAL-LEVEL");
    if (appraisalLevels === undefined) {
      setIsAppraisalLevelsLoading(true);

      // Call the API to load the necessary state
      const successCallback = (data: FilterCheckboxTreeApiResponseDto) => {
        setAppraisalLevels(data.nodes);
        setIsAppraisalLevelsLoading(false);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      appLevelApi.getAppraisalLevelCheckboxTreeItemsByClientId(
        successCallback,
        errorCallback
      );
    }
  };

  const clearSelections = (filterType: AnalyticsAdvancedFilterType) => {
    if (filterType === "JOB-TITLE") {
      setJobTitleTreeChecked([]);
      setJobTitleTreeExpanded([]);
      setSelectedJobTitleCount(0);
    } else if (filterType === "APPRAISAL-LEVEL") {
      setAppraisalLevelTreeChecked([]);
      setAppraisalLevelTreeExpanded([]);
      setSelectedAppraisalLevelCount(0);
    } else if (filterType === "LOCATION") {
      setLocationTreeChecked([]);
      setLocationTreeExpanded([]);
      setSelectedLocationCount(0);
    }
  };

  const onOpenFilterModal = (filterType: AnalyticsAdvancedFilterType) => {
    switch (filterType) {
      case "JOB-TITLE":
        setModalTitle("Filter by Job Titles");
        onJobTitleFilterClick();
        break;
      case "APPRAISAL-LEVEL":
        setModalTitle("Filter by Levels");
        onAppraisalFilterClick();
        break;
      case "LOCATION":
        setModalTitle("Filter by Locations");
        onLocationFilterClick();
        break;
      default:
        setFilterType(null);
    }
    setModalIsOpen(true);
  };

  const onCheckChangedApprovedDataOnly = (isChecked: boolean) => {
    setApprovedOnlyTicked(isChecked);
    onChangeApprovedDataOnly(isChecked);
  };

  const onCheckChangedIncludeLeavers = (isChecked: boolean) => {
    setIncludeLeaversTicked(isChecked);
    onChangeIncludeLeavers(isChecked);
  };

  if (!inEditMode) {
    // Get the count of applied filters via the state held for the actual applied values
    let filterCount = 0;
    if (selectedValues.locationIds?.length ?? 0 > 0) {
      filterCount++;
    }
    if (selectedValues.jobTitleIds?.length ?? 0 > 0) {
      filterCount++;
    }
    if (selectedValues.appraisalLevelIds?.length ?? 0 > 0) {
      filterCount++;
    }
    return (
      <button className="group" onClick={onBeginEditMode}>
        <FontAwesomeIcon icon={faFilters} />
        <Badge
          text={filterCount.toString()}
          backgroundColourClassName="accent-bg"
          textColourClassName="text-white"
        />
        <span>{filterCount === 1 ? "Filter" : "Filters"}</span>
        <FilterEditIcon extraClassNames="ml-2" />
      </button>
    );
  }

  return (
    <>
      <div className="pt-3">
        {advancedFilterItems.map((filterItem) => {
          const selectedItemCount =
            filterItem.type === "JOB-TITLE"
              ? selectedJobTitleCount
              : filterItem.type === "APPRAISAL-LEVEL"
              ? selectedAppraisalLevelCount
              : filterItem.type === "LOCATION"
              ? selectedLocationCount
              : 0;
          const selectedItemBadgeText =
            selectedItemCount >= 100 ? "99+" : selectedItemCount.toString();
          return (
            <div
              key={filterItem.type}
              className="text-sm mb-2 bg-gray-200 py-2 px-6 cursor-pointer group rounded-sm hover:bg-gray-300"
              onClick={() => onOpenFilterModal(filterItem.type)}
            >
              <div className="flex flex-row">
                <div className="grow">
                  <span>{filterItem.text}</span>
                  {selectedItemCount > 0 && (
                    <Badge
                      text={selectedItemBadgeText}
                      backgroundColourClassName="accent-bg"
                      textColourClassName="text-white"
                    />
                  )}
                </div>
                <div className="grow-0 text-right w-2">
                  <FilterEditIcon />
                </div>
              </div>
            </div>
          );
        })}
        <div className="px-2 mb-2">
          <hr className="border-gray-300" />
        </div>
        <div className="mb-2 pl-2 pr-4">
          <CheckBox
            labelText="Approved data only"
            onChange={onCheckChangedApprovedDataOnly}
            selected={approvedOnlyTicked}
            labelClassNames="text-sm pt-1"
            checkboxClassNames="bg-white"
            controlId="adv-filters-approved-only"
          />
        </div>
        <div className="mb-2 pl-2 pr-4">
          <CheckBox
            labelText="Include leavers"
            onChange={onCheckChangedIncludeLeavers}
            selected={includeLeaversTicked}
            labelClassNames="text-sm pt-1"
            checkboxClassNames="bg-white"
            controlId="adv-filters-include-leavers"
          />
        </div>
      </div>
      {filterType !== null && (
        <ModalPopup
          isOpen={modalIsOpen}
          onOpenChange={setModalIsOpen}
          onPrimaryButtonClick={onAddFiltersClick}
          title={modalTitle}
          showCloseIcon={true}
          width="SMALL"
          primaryButtonText="Done"
        >
          <AdvancedFilterModal
            filterType={filterType}
            onClearSelections={() => clearSelections(filterType)}
            locations={locations}
            isLocationsLoading={isLocationsLoading}
            locationTreeChecked={locationTreeChecked}
            locationTreeExpanded={locationTreeExpanded}
            onLocationTreeChecked={setLocationTreeChecked}
            onLocationTreeExpanded={setLocationTreeExpanded}
            appraisalLevels={appraisalLevels}
            isAppraisalLevelsLoading={isAppraisalLevelsLoading}
            appraisalLevelTreeChecked={appraisalLevelTreeChecked}
            appraisalLevelTreeExpanded={appraisalLevelTreeExpanded}
            onAppraisalLevelTreeChecked={setAppraisalLevelTreeChecked}
            onAppraisalLevelTreeExpanded={setAppraisalLevelTreeExpanded}
            jobTitles={jobTitles}
            isJobTitlesLoading={isJobTitlesLoading}
            jobTitleTreeChecked={jobTitleTreeChecked}
            jobTitleTreeExpanded={jobTitleTreeExpanded}
            onJobTitleTreeChecked={setJobTitleTreeChecked}
            onJobTitleTreeExpanded={setJobTitleTreeExpanded}
          />
        </ModalPopup>
      )}
    </>
  );
}

export default AdvancedFilters;
