import { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Logo } from "../../images/ttNavLogo.svg";
import {
  faHouseUser,
  faUserFriends,
  faTools,
  faAnalytics,
  faQuestionCircle,
  faRocket,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faHouseUser as fasHouseUser,
  faUserFriends as fasUserFriends,
  faTools as fasTools,
  faAnalytics as fasAnalytics,
  faQuestionCircle as fasQuestionCircle,
  faThLarge as fasThLarge,
} from "@fortawesome/pro-solid-svg-icons";
import { faThLarge } from "@fortawesome/pro-duotone-svg-icons";
// import { useTranslation } from "react-i18next";
import AppRoutes from "../../routes/AppRoutes";
import UserContext from "../../state/UserContext";
import { useTranslation } from "react-i18next";
import AppContext from "../../state/AppContext";
import OverrideNavItem from "../../types/nav/OverrideNavItem";
import {
  imageHelper,
  moduleAccessHelper,
  userDetailsHelper,
} from "../../helpers";
import ManagerDashWalkthrough from "../user-walkthrough/ManagerDashWalkthrough";
import MyDashWalkthrough from "../user-walkthrough/MyDashWalkthrough";
import UserwalkthroughType from "../../types/generic/UserWalkthroughType";
import LeftSubNavGroup from "./LeftSubNavGroup";
import { AnalyticsSubNavItems } from "../../routes/analytics";
import ttModulesList from "../../config/modules/ttModulesList";
import { AdminSubNavItems } from "../../routes/admin";
import adminHelper from "../../helpers/adminHelper";
import YourJourneySubNavItems from "../../routes/your-journey/YourJourneySubNavItems";
import YourPeopleSubNavItems from "../../routes/your-people/YourPeopleSubNavItems";

function LeftNav() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const [
    activeDashboardTypeForWalkthrough,
    setActiveDashboardTypeForWalkthrough,
  ] = useState<UserwalkthroughType | null>(
    appContext.activeDashboardTypeForWalkthrough
  );
  const { t } = useTranslation();
  const [launchWalkthrough, setLaunchWalkthrough] = useState<boolean>(false);

  useEffect(() => {
    setActiveDashboardTypeForWalkthrough(
      appContext.activeDashboardTypeForWalkthrough
    );
    setLaunchWalkthrough(false);
  }, [appContext.activeDashboardTypeForWalkthrough]);

  const isInExitMode = userContext.user.myDashboardMode === "EXIT";

  const shouldShowAnalyticsNavItem =
    userContext.user.hasAnalyticsAccess || userContext.user.isManager;

  const shouldShowCompanyDashboardNavItem = moduleAccessHelper.userHasModule(
    userContext.user,
    ttModulesList.CompanyDash
  );
  const shouldShowClassicAnalyticsNavItem =
    shouldShowAnalyticsNavItem &&
    moduleAccessHelper.userHasModule(userContext.user, ttModulesList.Reviews);

  const showAdminNavForManagersThatAdminTheirPeople =
    adminHelper.doesUserHaveAdminModuleAccess(userContext, "USER-JOURNEY");

  const headerLogo = userContext.user.client.logoFilename ? (
    <img
      src={imageHelper.getImagePath(userContext.user.client.logoFilename, 200)}
      alt="Logo"
      className="px-2 pb-2 m-auto w-48"
    />
  ) : (
    <Logo className="px-2 pb-2 m-auto" width={"12rem"} />
  );

  const handleLaunchWalkthrough = () => {
    setLaunchWalkthrough(true);
  };

  const handleCloseWalkthrough = () => {
    setLaunchWalkthrough(false);
  };

  const analyticsSubNavItems = AnalyticsSubNavItems(
    userContext.user.analyticsNavItems,
    shouldShowClassicAnalyticsNavItem,
    "LEFT-NAV"
  );

  const adminSubNavItems = AdminSubNavItems("LEFT-NAV");
  const yourDashboardSubNavItems = YourJourneySubNavItems(userContext.user);
  const yourPeopleSubNavItems = YourPeopleSubNavItems(userContext.user);
  const managerBadgeNumber = userDetailsHelper.getManagerUpdateCount(
    userContext.user
  );

  const myDashboardBadgeNumber = userDetailsHelper.getOwnUpdateCount(
    userContext.user
  );

  return (
    <div>
      <Link
        className="block px-4 py-2 m-0 text-center"
        to={AppRoutes.yourJourney.root}
      >
        {headerLogo}
      </Link>

      <nav id="left-nav" className="flex flex-col">
        <LeftSubNavGroup
          subNavId="your-dashboard-subnav"
          items={yourDashboardSubNavItems}
          baseRoute={AppRoutes.yourJourney.root}
          baseLinkText={t("Nav.LargeScreen.MyDashboard")}
          activeIcon={fasHouseUser}
          inactiveIcon={faHouseUser}
          showRootLevelBadge={myDashboardBadgeNumber > 0}
          rootLevelBadgeText={myDashboardBadgeNumber.toString()}
          rootLevelBadgeTooltip={t("Nav.LargeScreen.EmployeeUpdateTooltip")}
        />

        <>
          {(userContext.user.isManager ||
            userContext.user.isJourneyManager) && (
            <LeftSubNavGroup
              subNavId="your-people-subnav"
              items={yourPeopleSubNavItems}
              baseRoute={AppRoutes.yourPeople.root}
              baseLinkText={t("Nav.LargeScreen.ManagerDashboard")}
              activeIcon={fasUserFriends}
              inactiveIcon={faUserFriends}
              showRootLevelBadge={managerBadgeNumber > 0}
              rootLevelBadgeText={managerBadgeNumber.toString()}
              rootLevelBadgeTooltip={t("Nav.LargeScreen.ManagerUpdateTooltip")}
            />
          )}
          {shouldShowCompanyDashboardNavItem && (
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-nav-link" : "inactive-nav-link"
              }
              to={AppRoutes.companyDashboard}
            >
              <FontAwesomeIcon
                icon={fasThLarge}
                size="lg"
                className="nav-icon-active fa-fw"
              />
              <FontAwesomeIcon
                icon={faThLarge}
                size="lg"
                className="nav-icon-inactive fa-fw"
              />

              <span className="px-2">
                {t("Nav.LargeScreen.CompanyDashboard")}
              </span>
            </NavLink>
          )}

          {(userContext.user.isAdmin ||
            showAdminNavForManagersThatAdminTheirPeople) &&
            !isInExitMode && (
              <LeftSubNavGroup
                subNavId="admin-subnav"
                items={adminSubNavItems}
                baseRoute={AppRoutes.admin.journeys.path}
                baseLinkText={t("Nav.LargeScreen.Admin.Root")}
                activeIcon={fasTools}
                inactiveIcon={faTools}
              />
            )}
          {shouldShowAnalyticsNavItem && (
            <LeftSubNavGroup
              subNavId="analytics-subnav"
              items={analyticsSubNavItems}
              baseRoute={AppRoutes.analytics.root}
              baseLinkText={t("Nav.LargeScreen.Analytics.Root")}
              activeIcon={fasAnalytics}
              inactiveIcon={faAnalytics}
            />
          )}
        </>

        <a className="inactive-nav-link" href={AppRoutes.help} target="_blank">
          <FontAwesomeIcon
            icon={fasQuestionCircle}
            size="lg"
            className="nav-icon-active fa-fw"
          />
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="lg"
            className="nav-icon-inactive fa-fw"
          />

          <span className="px-2">{t("Nav.LargeScreen.Help")}</span>
        </a>

        {(activeDashboardTypeForWalkthrough === "MY-DASHBOARD" ||
          activeDashboardTypeForWalkthrough === "MANAGER-DASHBOARD") && (
          <button
            id="walkthrough-button"
            className=" absolute bottom-10 left-0 right-0 text-center text-xs bg-gray-200 rounded m-4 py-1 px-2"
            onClick={handleLaunchWalkthrough}
          >
            <span className="px-2 text-gray-500">{"Introduction"}</span>
            <FontAwesomeIcon
              icon={faRocket}
              size="lg"
              className="nav-icon-inactive fa-fw text-gray-500"
            />
          </button>
        )}
      </nav>
      {launchWalkthrough &&
        appContext.activeDashboardTypeForWalkthrough ===
          "MANAGER-DASHBOARD" && (
          <ManagerDashWalkthrough onFinished={handleCloseWalkthrough} />
        )}
      {launchWalkthrough &&
        appContext.activeDashboardTypeForWalkthrough === "MY-DASHBOARD" && (
          <MyDashWalkthrough
            hasClientGotAdvancedTaskTypes={
              userContext.user.client.taskTypes.length > 0
            }
            onFinished={handleCloseWalkthrough}
          />
        )}
    </div>
  );
}

export default LeftNav;
