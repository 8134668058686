import { t } from "i18next";
import {
  FormQuestion,
  FormType,
  QuestionTasks,
  ValidationResult,
} from "../../../types/forms";
import ValidationWarning from "../../common/ValidationWarning";
import AddFormTaskControl from "./AddFormTaskControl";
import { InfoBanner } from "../../common";

interface GoalSettingQuestionProps {
  question: FormQuestion;
  /** The id of the client form */
  formId: number;
  isReadOnly: boolean;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** The class name for the add task button which might changed depending in if it's in the collaborative documents or the journey widget */
  addTaskButtonClassName?: string;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** Any tasks added against this question */
  tasks: QuestionTasks | null;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(questionTasks: QuestionTasks): void;
  /** Whether this is being rendered in a journey or a collab doc */
  formType: FormType;
  /** Whether or not to distinguish rolled over goals from fresh new goals. Used when goal review and goal setting are in the same form */
  separateRolledOverGoals: boolean;
}

function GoalSettingQuestion({
  question,
  formId,
  isReadOnly,
  showValidationErrors,
  addTaskButtonClassName = "btn-primary",
  validationResult,
  tasks,
  onChangeQuestionTasks,
  formType,
  separateRolledOverGoals = false,
}: GoalSettingQuestionProps) {
  let infoText: string | null = null;
  if (question.validation.min && question.validation.max) {
    infoText = t("Forms.Goals.Setting.GoalCountInfo.Between")
      .replace("#MIN#", question.validation.min.toString())
      .replace("#MAX#", question.validation.max.toString());
  } else if (question.validation.min) {
    const minTransKey =
      question.validation.min === 1
        ? "Forms.Goals.Setting.GoalCountInfo.Required"
        : "Forms.Goals.Setting.GoalCountInfo.MinOnly";
    infoText = t(minTransKey).replace(
      "#MIN#",
      question.validation.min.toString()
    );
  } else if (question.validation.max) {
    infoText = t("Forms.Goals.Setting.GoalCountInfo.MaxOnly").replace(
      "#MAX#",
      question.validation.max.toString()
    );
  } else if (question.validation.required) {
    infoText = t("Forms.Goals.Setting.GoalCountInfo.Required");
  }

  const hasTasks = tasks !== null && tasks.tasks !== null;
  const disableAddTaskButton =
    question.validation.max !== null &&
    hasTasks &&
    tasks.tasks.filter((x) => x.modifyStatus !== "DELETED").length >=
      question.validation.max;

  return (
    <>
      {/* Validation errors */}
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      {infoText && <InfoBanner text={infoText} />}
      <AddFormTaskControl
        questionId={question.questionId}
        formId={formId}
        questionTasks={tasks}
        onChange={onChangeQuestionTasks}
        isReadOnly={isReadOnly}
        classNames={addTaskButtonClassName}
        mode="WITH-BUTTON"
        buttonTextOverride={t("Tasks.Popup.TriggerButtons.AddGoal")}
        restrictToTaskType={"GOAL"}
        disableAddTaskButton={disableAddTaskButton}
        formType={formType}
        separateRolledOverGoals={separateRolledOverGoals}
      />
    </>
  );
}

export default GoalSettingQuestion;
