import { t } from "i18next";

interface PreJourneyScreenProps {
  journeyTitle: string;
  onAdvanceClick: () => void;
}

/** This displays alongside the updates when there's an active journey,
 * hiding the welcome message to avoid overloading the user and making it
 * difficult for them to choose what to do next
 */
function PreJourneyScreen({
  journeyTitle,
  onAdvanceClick,
}: PreJourneyScreenProps) {
  return (
    <div
      id="pre-journey-screen"
      className="xl:min-h-full lg:flex lg:flex-col lg:justify-center"
    >
      <h4 className="!text-white/80 !font-normal">Your next journey...</h4>
      <h2 className="!text-white text-center !my-4 !md:my-8">
        {t(journeyTitle)}
      </h2>
      <div className="text-center md:mt-4">
        <button
          className="bg-white w-full font-medium text-gray-700 rounded-md py-2 px-4 hover:shadow-md hover:shadow-white/20 text-center"
          onClick={onAdvanceClick}
        >
          Start journey
        </button>
      </div>
    </div>
  );
}

export default PreJourneyScreen;
