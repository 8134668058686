import ClientFormAutomationUnitEnum from "../types/admin/ClientFormAutomationUnitEnum";
import { ClientFormModeEnum } from "../types/admin/ClientFormModeEnum";
import ClientFormAutomationUnitType from "../types/dtos/admin/ClientFormAutomationUnitType";
import ClientFormModeType from "../types/dtos/admin/ClientFormModeType";
import { OverrideDto } from "../types/dtos/admin/OverrideDto";

const overrideHelper = {
  createCleanOverrideDto: function (overrideId: number,
    clientFormId: number,
    appraisalLevelId: number,
    appraisalLevelName: string,
    mode: ClientFormModeType,
    dto : OverrideDto
  ): OverrideDto {
    const newOverride: OverrideDto = {
      overrideId: overrideId,
      clientFormId: clientFormId,
      appraisalLevelId: appraisalLevelId != undefined ? appraisalLevelId : 0,
      appraisalLevelName: appraisalLevelName != undefined ? appraisalLevelName : "",
      mode: mode,
      modeEnum: this.getModeEnumValue(mode),
      disableJourney: dto.disableJourney
    }

    switch (mode) {
      case "AUTOMATED":
        newOverride.automationAmount = dto.automationAmount;
        newOverride.automationUnit = dto.automationUnit;
        if (dto.automationUnit != undefined) {
          newOverride.automationUnitEnum = this.getAutomationUnitEnumValue(dto.automationUnit);
        }
        break;
      case "WINDOWED":
        newOverride.windows = dto.windows;
        break;
      case "INDUCTION":
        // If disableJourney is false then we save the daysTo and daysFrom
        if (newOverride.disableJourney === false) {
          newOverride.inductionDaysTo = dto.inductionDaysTo;
          newOverride.inductionDaysFrom = dto.inductionDaysFrom
        } 
        break;
    }

    return newOverride;
  },
  getModeEnumValue: function (mode: ClientFormModeType): number {
    switch (mode) {
      case "HIDDEN-ON-DASHBOARD":
        return ClientFormModeEnum.HiddenOnDashboard;
      case "AUTOMATED":
        return ClientFormModeEnum.Automated;
      case "WINDOWED":
        return ClientFormModeEnum.Windowed;
      case "MANUAL-ONLY":
        return ClientFormModeEnum.ManualOnly;
      case "INDUCTION":
        return ClientFormModeEnum.Induction;
      case "EXIT":
        return ClientFormModeEnum.Exit;
    }
  },
  getAutomationUnitEnumValue: function (unit: ClientFormAutomationUnitType): number {
    switch (unit) {
      case "YEARS":
        return ClientFormAutomationUnitEnum.Years;
      case "MONTHS":
        return ClientFormAutomationUnitEnum.Months;
      case "WEEKS":
        return ClientFormAutomationUnitEnum.Weeks;
      case "DAYS":
        return ClientFormAutomationUnitEnum.Days;
    }
  }
};

export default overrideHelper;
