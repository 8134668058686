import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle
} from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import { ValidationResult } from "../../types/forms";
import ModalPopup from "./ModalPopup";
import TextInput from "./TextInput";
import ValidationWarning from "./ValidationWarning";
import { colourHelper } from "../../helpers";
import IconButton from "./IconButton";
import SuccessAlert from "../alerts/SuccessAlert";
import DangerAlert from "../alerts/DangerAlert";
import Avatar from "./Avatar";

interface ColourPickerProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The current value for the input */
  value: string;
  /** The hex colour for the code used in the 'Preview' popup (and contrast checker) */
  fontColour?: string;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

/** A text input field, with common styling already applied
 */
const ColourPicker = ({
  onChange,
  inputId = "",
  value = "",
  fontColour = "#FFFFFF",
  showValidationErrors = false,
  validationResult = null,
}: ColourPickerProps) => {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pickerColour, setPickerColour] = useState(value);
  const [primaryButtonIsDisabled, setPrimaryButtonIsDisabled] = useState(false);
  const [doesColourPassContrastCheck, setDoesColourPassContrastCheck] = useState(false);


  useEffect(() => {
    if (colourHelper.doesColourPassContrastCheck(fontColour, pickerColour)) {
      setDoesColourPassContrastCheck(true);
      setPrimaryButtonIsDisabled(false);
    } else {
      setDoesColourPassContrastCheck(false);
      setPrimaryButtonIsDisabled(true);
    }
  }, [pickerColour]);

  useEffect(() => {
    setPickerColour(value);
  }, [value]);

  const handleTextChange = (newValue: string) => {
    if (newValue.length == 0) {
      onChange("#");
    } else {
      onChange(newValue);
    }
  };

  const handleSaveClick = () => {
    onChange(pickerColour);
    setModalIsOpen(false);
  };

  const handleCloseClick = () => {
    setPickerColour(value);
    setModalIsOpen(false);
  };

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div className="grid grid-cols-12 gap-1">
        <div style={{ backgroundColor: value }} className="col-span-2 md:col-span-1">
          &nbsp;
        </div>
        <div className="col-span-10 md:col-span-11">
          <TextInput
            onChange={handleTextChange}
            inputId={inputId}
            className="w-full"
            value={value} />
          <span className="float-right mr-3 -mt-9 relative z-2">
            <IconButton
              buttonType="PALETTE"
              displayMode="ICON-ONLY"
              buttonClassName="px-1 mr-2"
              iconClassName="text-gray-800"
              onClick={() => { setModalIsOpen(true) }}
            />
          </span>
        </div>
      </div>
      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={setModalIsOpen}
        onPrimaryButtonClick={handleSaveClick}
        onSecondaryButtonClick={handleCloseClick}
        primaryButtonText={t("Common.Save")}
        secondaryButtonText={t("Common.Close")}
        title={t("Common.ColourPicker")}
        allowYOverflow={false}
        showCloseIcon={false}
        isPrimaryButtonDisabled={primaryButtonIsDisabled}
      >
        {doesColourPassContrastCheck && (
          <SuccessAlert
            prefix={""}
            message={t("Common.Validation.Successful.IsAvailable")}
          />
        )}
        {!doesColourPassContrastCheck && (
          <DangerAlert
            prefix={""}
            message={t("Common.Validation.Errors.NotAvailableDueToAccessibility")}
          />
        )}
        {/* https://www.npmjs.com/package/react-colorful */}
        <div className="grid grid-cols-1 md:grid-cols-2">
          <HexColorPicker className="ml-4 sm:shrink-0 shrink" color={pickerColour} onChange={setPickerColour} />
          <div className="flex flex-col grow px-6 gap-2">
            <div>
              <span className="underline">{t("Common.Value")}</span>
              <br />
              <span>{pickerColour}</span>
            </div>
            <div>
              <span className="underline">{t("Common.Preview")}</span>
              <br />
              <div style={{ backgroundColor: pickerColour }} className="text-right pb-3 pt-1 px-2">
                <span style={{ color: fontColour }} className="text-sm mr-1">
                  {t("Pages.CollaborativeDocument.Heading.Participants")}:
                </span>
                <div className="-space-x-1 inline">
                  <Avatar
                    userFullName="Preview Employee"
                    userInitials="PE"
                    size={8}
                    imageUrl={null}
                  />
                  <Avatar
                    userFullName="Preview Manager"
                    userInitials="PM"
                    size={8}
                    imageUrl={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalPopup>
    </>
  );
};

export default ColourPicker;
