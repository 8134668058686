import { Markup } from "interweave";

interface SafeRenderHtmlProps {
  htmlText: string;
  containerClassName?: string | undefined;
  /** Any element you want to place after the custom HTML markup but inside the same container */
  otherChildren?: JSX.Element | undefined;
}

/** Renders custom HTML, with content parsed so to avoid XSS attacks (in case it was entered by frontend users) */
function SafeRenderHtml({
  htmlText,
  containerClassName = undefined,
  otherChildren = undefined,
}: SafeRenderHtmlProps) {
  return (
    <div className={containerClassName}>
      <Markup content={htmlText} />
      {otherChildren}
    </div>
  );
}

export default SafeRenderHtml;
