import CatchUpDto from "../../types/catch-ups/CatchUpDto";
import MeetingFormDto from "../../types/catch-ups/MeetingFormDto";
import { apiClient } from "../apiClient";
import download from "downloadjs";

class catchUpApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /**Load the summary tabs for user */
  CreateNewCatchUp(
    createMeetingDto: CatchUpDto,
    onSuccess: (data: CatchUpDto | null) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/catch-up/create-new-catch-up",
      {
        body: JSON.stringify(createMeetingDto),
      }
    ).then(
      (data) => {
        const meetingData = data as CatchUpDto;
        onSuccess(meetingData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  CreateCalendarFileForCatchUp(
    createMeetingDto: CatchUpDto,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/catch-up/create-calendar-file",
      {
        body: JSON.stringify(createMeetingDto),
      },
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        download(blob, "catch-up.ics", "text/calendar");
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }

  /** Used for creating a new catch up from the journey */
  CreateNewCatchUpFromJourney(
    createMeetingDto: CatchUpDto,
    onSuccess: (data: boolean | null) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/catch-up/create-new-catch-up-from-journey",
      {
        body: JSON.stringify(createMeetingDto),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /**Load the summary tabs for user */
  GetAvailableMeetingForms(
    otherParticipantId: number,
    onSuccess: (data: MeetingFormDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/catch-up/get-available-meeting-forms?otherParticipantId=" +
        otherParticipantId
    ).then(
      (data) => {
        const formData = data as MeetingFormDto[];
        onSuccess(formData);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default catchUpApi;
