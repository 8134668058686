import { useTranslation } from "react-i18next";
import DashboardSummaryTabDto from "../../../types/dtos/dashboards/DashboardSummaryTabDto";
import { TabDetails } from "../../common/Tabs";
import { Tabs } from "../../common";
import FormSummary from "../../form-summaries/FormSummary";
import { useState } from "react";
import ManagerDashboardPersonDto from "../../../types/dtos/dashboards/ManagerDashboardPersonDto";

interface PeopleContentProps {
  /** Whether or not the PeopleCard component is expanded to view the user's data */
  userIsExpanded: boolean;
  tabs: DashboardSummaryTabDto[];
  managedUser: ManagerDashboardPersonDto;
  /** A function to call when the manager chooses to update a form section for one of their people */
  onTriggerSectionUpdate(
    userId: number,
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | null
  ): void;
  onUserTabChange?(
    userId: number,
    clientFormId: number,
    onSuccess: () => void,
    onError: () => void
  ): void | undefined;
}

function PeopleContent({
  userIsExpanded,
  tabs,
  managedUser,
  onTriggerSectionUpdate,
  onUserTabChange = undefined,
}: PeopleContentProps) {
  const { t } = useTranslation();
  useState<null | DashboardSummaryTabDto>(null);

  const handleUpdateSectionClick = (
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | null
  ) => {
    onTriggerSectionUpdate(
      managedUser.userId,
      summaryTab,
      incompleteJourneyReference
    );
  };

  const getTabContent = (selectedTab: DashboardSummaryTabDto) => {
    if (!userIsExpanded) {
      return null;
    } else {
      const emptyTabData = <span>No data!</span>;

      return (
        <>
          {!selectedTab ? (
            emptyTabData
          ) : (
            <div className="flex flex-col">
              <FormSummary
                summaryTabData={selectedTab}
                isManagerDashboard
                user={managedUser}
                onTriggerSectionUpdate={handleUpdateSectionClick}
              />
              {/*<div className="flex flex-row-reverse gap-3">
                {selectedTab.tabType !== "CLIENT-FORM-EMPLOYEE-ONLY" && (
                  <button
                    className="btn-primary block"
                    onClick={() =>
                      handleUpdateSectionClick(selectedTab.clientFormId!)
                    }
                  >
                    <span>
                      {t("Common.Update") +
                        " " +
                        t(selectedTab.clientFormTitle)}
                    </span>
                  </button>
                )}
                {selectedTab.tabType === "GOALS" && (
                  <button className="btn-secondary block">
                    <span>{t("Common.EditGoals")}</span>
                  </button>
                )}
                <IconButton
                  buttonType="HISTORY"
                  displayMode="ICON-ONLY"
                  buttonClassName="px-1 mr-2"
                  iconClassName="text-gray-800"
                  onClick={() => {}}
                />
                </div>*/}
            </div>
          )}
        </>
      );
    }
  };

  const onTabChange = (clientFormId: number) => {
    const onSuccess = () => {
      // console.log("hide spinner");
    };

    const onError = () => {
      // console.log("error");
    };

    if (onUserTabChange) {
      onUserTabChange(managedUser.userId, clientFormId, onSuccess, onError);
    }
  };

  const displayTabs: Array<TabDetails> = [];
  tabs.map((tab) => {
    const tabName = t(tab.clientFormTitle);
    displayTabs.push({
      title: tabName,
      content: tab.clientFormId ? getTabContent(tab) : null,
      displayAlertIcon: tab.tabHasUpdates,
      onClickEvent: () => onTabChange(tab.clientFormId!),
    });
  });

  return (
    <>
      {displayTabs.length > 0 && (
        <div>
          <hr className="mt-3" />
          <Tabs
            tabs={displayTabs}
            selectFirstTabByDefault={userIsExpanded}
            selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] !py-1.5 rounded-b-md"
            tabTextClassNames="text-sm"
            maxTabsOnSmallScreens={2}
          />
        </div>
      )}
    </>
  );
}

export default PeopleContent;
