import { useEffect, useState } from "react";
import { useDebounce } from "../../../../hooks";
import { BaseSelectableItem, ValidationResult } from "../../../../types/forms";
import ScaleOption from "../../../../types/forms/ScaleOption";
import { SliderScoreDisplayType } from "../../../../types/generic";
import { Slider } from "../../../common";

interface BehaviourSliderProps {
  behaviourOrAttributeId: number;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** The slider options */
  scaleOptions: BaseSelectableItem[];
  /** Whether to show the numeric scale selected on the slider or words */
  sliderScoreDisplayType: SliderScoreDisplayType;
  isReadOnly: boolean;
  sliderValidationResult: ValidationResult;
  /** Specify a CSS class name for the track to the left hand side of the drag handle */
  selectedTrackBgColourClassName: string;

  /** The colour of the track to the right hand side of the drag handle */
  emptyTrackBgColourClassName: string;
  /**The hex of the form colour used in collaborative docs */
  formBackgroundColorStyle?: string | null;
  handleSliderChange: (
    behaviourId: number,
    scaleOptions: ScaleOption[]
  ) => void;

  /** An event to call when one of the inputs loses focus */
  onBlur?(behaviourId: number, scaleOptions: ScaleOption[]): void | undefined;
}

function BehaviourSlider({
  behaviourOrAttributeId,
  showValidationErrors = false,
  scaleOptions,
  sliderScoreDisplayType,
  isReadOnly,
  sliderValidationResult,
  selectedTrackBgColourClassName,
  emptyTrackBgColourClassName,
  formBackgroundColorStyle,
  handleSliderChange,
  onBlur,
}: BehaviourSliderProps) {
  const [valueHasBeenChanged, setValueHasBeenChanged] =
    useState<boolean>(false);
  const debouncedCurrentValue = useDebounce(scaleOptions, 500);

  useEffect(() => {
    if (valueHasBeenChanged && onBlur) {
      onBlur(behaviourOrAttributeId, debouncedCurrentValue);
    }
  }, [debouncedCurrentValue]);

  const onSliderChange = (newState: BaseSelectableItem[]) => {
    handleSliderChange(behaviourOrAttributeId, newState);
    setValueHasBeenChanged(true);
  };

  return (
    <div className="px-4">
      <Slider
        scaleOptions={scaleOptions}
        onChange={onSliderChange}
        showValidationErrors={showValidationErrors}
        validationResult={sliderValidationResult}
        selectedTrackBgColourClassName={selectedTrackBgColourClassName}
        formBackgroundColorStyle={formBackgroundColorStyle}
        emptyTrackBgColourClassName={emptyTrackBgColourClassName}
        topMarginClassName="mt-2"
        selectedValueDisplayMode={sliderScoreDisplayType}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

export default BehaviourSlider;
