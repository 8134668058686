import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import { differenceInDays } from "date-fns";
import { defaultStaticRanges } from "../../config/analytics/StaticDateRanges";
import AppContext from "../../state/AppContext";
import {
  AnalyticsWidgetUiDetailsDto,
  FilterAvailableValues,
  JourneyFilterDefaultValueMode,
} from "../../types/analytics";
import FilterDefaultSelectedValues from "../../types/analytics/FilterDefaultSelectedValues";
import AnalyticsFilterInputs, {
  AnalyticsFilterComparisonProperty,
  AnalyticsPageJourneyFilterRestriction,
} from "../../types/analytics/AnalyticsFilterInputs";
import { IDateRange } from "../../types/generic";
import AnalyticsFilters from "./AnalyticsFilters";
import analyticsFiltersApi from "../../api/analytics/analyticsFiltersApi";
import { FilterAvailableValuesDto } from "../../types/analytics/FilterAvailableValues";
import IAnalyticsPageApi from "../../types/analytics/IAnalyticsPageApi";
import { AnalyticsPageDataDto } from "../../types/analytics/AnalyticsPageDataDto";
import AnalyticsWidgetCollection from "./AnalyticsWidgetCollection";
import { AnalyticsLoadingSpinner } from "./AnalyticsLoadingSpinner";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { AnalyticsWidgetRefreshArguments } from "../../types/analytics/AnalyticsWidgetRefreshFilterParams";

interface AnalyticsPageProps {
  /** A unique identifier for this page, triggers a reload of data on change */
  pageIdentifier: string;
  /** If this page is a dynamic (database-loaded widgets) page, provide the AnaltyicsPageId */
  dynamicPageId?: number | undefined;
  title: string;
  translateTitle?: boolean;
  children?: React.ReactNode | undefined;
  journeyFilterDefaultValueMode: JourneyFilterDefaultValueMode;
  journeyFilterRestriction: AnalyticsPageJourneyFilterRestriction;
  /** The API to load data from when the filters change, or the page first loads */
  analyticsPageApi?: IAnalyticsPageApi | undefined;
  /** Whether widgets will be loaded, or something different */
  contentType: "WIDGETS" | "CUSTOM";
}

function AnalyticsPage({
  pageIdentifier,
  dynamicPageId = undefined,
  title,
  translateTitle = true,
  journeyFilterDefaultValueMode,
  journeyFilterRestriction,
  contentType,
  analyticsPageApi = undefined,
  children = undefined,
}: AnalyticsPageProps) {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const filtersApi = new analyticsFiltersApi(auth.user?.access_token);
  const appInsights = useAppInsightsContext();

  // ------------ Filter-related code ------------
  function filtersHaveBeenSet() {
    return filterAppliedValues && !!filterAppliedValues.dateRange;
  }

  /** Call the API to refresh the page data */
  function reloadData() {
    // Don't call the API if we're not ready
    if (
      !analyticsPageApi ||
      !filtersReady ||
      !filtersHaveBeenSet() ||
      contentType === "CUSTOM"
    )
      return;

    const onApiSuccess = (data: AnalyticsPageDataDto | null) => {
      if (!data) {
        setWidgets([]);
      } else {
        setWidgets(data.widgets);
      }
      setWidgetsAreLoading(false);
    };

    const onApiError = (err: any) => {
      console.error("load page data error", err);
      setWidgetsAreLoading(false);
    };

    setWidgetsAreLoading(true);

    analyticsPageApi.getPageData(filterAppliedValues, onApiSuccess, onApiError);
  }

  const emptyFilterSelection: AnalyticsFilterInputs = {
    dateRange: null,
    allJourneys: false,
    journeyKeys: [],
    activeUsersOnly: true,
    includePartiallyApproved: false,
    comparisonProperty: "NONE",
    locationIds: [],
    appraisalLevelIds: [],
    jobTitleIds: [],
  };

  // The options available in the filters
  const [filterAvailableValues, setFilterAvailableValues] =
    useState<FilterAvailableValues>({
      definedDateRanges: [],
      journeys: [],
    });

  // These are the values selected in the filters UI, but not necessarily applied yet
  const [filterSelectedValues, setFilterSelectedValues] =
    useState<AnalyticsFilterInputs>(emptyFilterSelection);

  // These are the values applied, which the data being shown is loaded for
  const [filterAppliedValues, setFilterAppliedValues] =
    useState<AnalyticsFilterInputs>(emptyFilterSelection);

  // Whether or not the filters are ready to use for querying the API with
  const [filtersReady, setFiltersReady] = useState<boolean>(false);

  const trackAnalyticsFilterChangeEvent = useTrackEvent(
    appInsights,
    "Analytics-FilterChange",
    {}
  );

  function loadAvailableFilterValues() {
    // Don't load filters for custom pages
    if (contentType === "CUSTOM") {
      return;
    }

    const onFilterLoadSuccess = (data: FilterAvailableValuesDto) => {
      const apiAvailableValues = {
        ...data,
        definedDateRanges: defaultStaticRanges.map((sr) => {
          const range = sr.range();
          return {
            startDate: range.startDate,
            endDate: range.endDate,
            label: sr.label!,
          };
        }),
      };

      // Store the available values in the state so we don't need to load them again
      setFilterAvailableValues(apiAvailableValues);

      // Set the default filter values
      setDefaultValuesForFilters(apiAvailableValues);
    };

    const onFilterLoadError = (error: any) => {
      console.log("Error loading filters", error);
    };

    filtersApi.getAvailableFilterValues(
      journeyFilterRestriction,
      onFilterLoadSuccess,
      onFilterLoadError
    );
  }

  function setDefaultValuesForFilters(
    apiAvailableValues: FilterAvailableValues
  ) {
    // Get then set the default filter values
    const filterDefaultValues = new FilterDefaultSelectedValues(
      journeyFilterDefaultValueMode,
      apiAvailableValues
    );

    // Set the state for the selected items, and the applied items
    setFilterSelectedValues(filterDefaultValues);
    setFilterAppliedValues(filterDefaultValues);
    setFiltersReady(true);
  }

  const handleJourneyPickerChange = (selectedJourneyKeys: string[]) => {
    // Check whether all items should be selected
    const allJourneysAreSelected =
      filterAvailableValues?.journeys.flatMap((x) => x.journeys).length ===
      selectedJourneyKeys.length;

    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      journeyKeys: selectedJourneyKeys,
      allJourneys: allJourneysAreSelected,
    };
    setFilterSelectedValues(newValues);
  };

  const handleLocationPickerChange = (selectedLocationIds: number[]) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      locationIds: selectedLocationIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleJobTitlePickerChange = (selectedJobTitleIds: number[]) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      jobTitleIds: selectedJobTitleIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleAppraisalPickerChange = (selectedAppraisalIds: number[]) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      appraisalLevelIds: selectedAppraisalIds,
    };
    setFilterSelectedValues(newValues);
  };

  const handleApprovedDataOnlyChange = (approvedOnly: boolean) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      includePartiallyApproved: !approvedOnly,
    };
    setFilterSelectedValues(newValues);
  };

  const handleActiveOnlyChange = (includeLeavers: boolean) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      activeUsersOnly: !includeLeavers,
    };
    setFilterSelectedValues(newValues);
  };

  const handleComparePropertyChange = (
    newValue: AnalyticsFilterComparisonProperty
  ) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      comparisonProperty: newValue,
    };
    setFilterSelectedValues(newValues);
  };

  const handleJourneyPickerAllSelectedChange = (allSelected: boolean) => {
    const allJourneyKeys = filterAvailableValues
      ? filterAvailableValues.journeys
          .flatMap((x) => x.journeys)
          .map((x) => x.key)
      : [];

    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      allJourneys: allSelected,
      journeyKeys: allSelected ? allJourneyKeys : [],
    };
    setFilterSelectedValues(newValues);
  };

  const handleDateRangeChange = (newValue: IDateRange | null) => {
    const newValues: AnalyticsFilterInputs = {
      ...filterSelectedValues,
      dateRange: newValue,
    };
    setFilterSelectedValues(newValues);
  };

  const onApplyFilters = () => {
    setFilterAppliedValues(filterSelectedValues);
    trackFilterUsage(filterSelectedValues);
  };

  const onResetFilters = () => {
    setDefaultValuesForFilters(filterAvailableValues);
  };

  // ------------ Chart data related code ------------
  const [widgets, setWidgets] = useState<AnalyticsWidgetUiDetailsDto[]>([]);
  const [widgetsAreLoading, setWidgetsAreLoading] = useState<boolean>(true);

  // ------------ Side effects ------------
  useEffect(() => {
    setPageTitle();
    appContext.setShowPageTitleAccent(true);
    loadAvailableFilterValues();
  }, [pageIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageTitle();
  }, [title, translateTitle]);

  useEffect(() => {
    // Reload the data when the applied filters change
    reloadData();
  }, [filterAppliedValues, filtersReady]);

  /** Log how the user is using the filters so we can make product decisions with the data */
  const trackFilterUsage = (filterValues: AnalyticsFilterInputs) => {
    trackAnalyticsFilterChangeEvent({
      dateRange: getDateRangeDays(filterValues.dateRange),
      journeys: getFilterArrayPropertyLength(filterValues.journeyKeys),
      locations: getFilterArrayPropertyLength(filterValues.locationIds),
      jobTitles: getFilterArrayPropertyLength(filterValues.jobTitleIds),
      appraisalLevels: getFilterArrayPropertyLength(
        filterValues.appraisalLevelIds
      ),
      approvedOnly: filterValues.includePartiallyApproved,
      activeOnly: filterValues.activeUsersOnly,
      comparisonProperty: filterValues.comparisonProperty,
    });
  };

  const getDateRangeDays = (dateRange: IDateRange | null): number => {
    if (dateRange === null || !dateRange.startDate || !dateRange.endDate) {
      return 0;
    }
    return differenceInDays(dateRange.endDate, dateRange.startDate);
  };

  const getFilterArrayPropertyLength = (array: any[] | null): number => {
    return array !== null && array.length > 0 ? array.length : 0;
  };

  const setPageTitle = () => {
    appContext.setPageTitle(translateTitle ? t(title) : title);
  };

  const handleOtherWidgetRefreshRequest = (
    dynamicWidgetId: number,
    onSuccess: () => void,
    onError: () => void
  ) => {
    if (!analyticsPageApi || !dynamicPageId) return;

    const successCallback = (data: AnalyticsWidgetUiDetailsDto) => {
      // Set the new widget data
      const newWidgetState = [...widgets];
      const widgetIndex = newWidgetState.findIndex(
        (x) => x.dynamicWidgetId === dynamicWidgetId
      );
      newWidgetState[widgetIndex] = data;
      setWidgets(newWidgetState);

      // Call the success callback
      onSuccess();
    };

    const errorCallback = (error: any) => {
      onError();
      console.error(error);
    };

    const refreshParams: AnalyticsWidgetRefreshArguments = {
      analyticsPageId: dynamicPageId,
      widgetId: dynamicWidgetId,
      pageNumber: null,
      pageSize: null,
      searchValue: null,
    };

    analyticsPageApi.refreshWidgetData(
      filterAppliedValues,
      refreshParams,
      successCallback,
      errorCallback
    );
  };

  const filtersHaveLoaded = filterAvailableValues.journeys.length > 0;

  return (
    <div
      className={
        "p-2 mb-12" /* Margin bottom prevents overlap of bottom touch nav */
      }
    >
      {contentType === "WIDGETS" && (
        <>
          {(!filtersHaveLoaded || filterSelectedValues === null) && (
            <AnalyticsLoadingSpinner />
          )}
          {filtersHaveLoaded && filterSelectedValues !== null && (
            <>
              {widgetsAreLoading && <AnalyticsLoadingSpinner />}
              {!widgetsAreLoading && (
                <>
                  <AnalyticsFilters
                    journeyFilterDefaultValueMode={
                      journeyFilterDefaultValueMode
                    }
                    journeyFilterRestriction={journeyFilterRestriction}
                    availableValues={filterAvailableValues}
                    selectedValues={filterSelectedValues}
                    onDateRangeChange={handleDateRangeChange}
                    onJourneyPickerChange={handleJourneyPickerChange}
                    onLocationPickerChange={handleLocationPickerChange}
                    onJobtitlePickerChange={handleJobTitlePickerChange}
                    onAppraisalLevelPickerChange={handleAppraisalPickerChange}
                    onComparePropertyChange={handleComparePropertyChange}
                    onAllJourneysSelectedChange={
                      handleJourneyPickerAllSelectedChange
                    }
                    onChangeApprovedDataOnly={handleApprovedDataOnlyChange}
                    onChangeIncludeLeavers={handleActiveOnlyChange}
                    onApplyFilters={onApplyFilters}
                    onResetFilters={onResetFilters}
                  />
                  <div id="analytics-widgets-container">
                    <AnalyticsWidgetCollection
                      widgets={widgets}
                      filters={filterSelectedValues}
                      journeys={filterAvailableValues.journeys}
                      dynamicPageId={dynamicPageId}
                      onRequestOtherWidgetRefresh={
                        handleOtherWidgetRefreshRequest
                      }
                    />
                    {children !== undefined ? children : null}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      {contentType === "CUSTOM" && <>{children}</>}
    </div>
  );
}

export default AnalyticsPage;
