import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

interface FullScreenLoaderWithMessageProps {
  message: string;
}

function FullScreenLoaderWithMessage({
  message,
}: FullScreenLoaderWithMessageProps) {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <div>
          <FontAwesomeIcon
            icon={faSpinnerThird}
            size="6x"
            spin
            className="loader-icon"
          />
        </div>
        <div className="mt-6 text-gray-700">{message}</div>
      </div>
    </div>
  );
}

export default FullScreenLoaderWithMessage;
