import { Link } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";

function LegacyReviewLink() {
  return (
    <div>
      <Link to={AppRoutes.reviewHistory.path}>
        <span>
          <span className="py-1 px-4 text-gray-600 bg-white border rounded hover:bg-gray-50">
            Previously completed reviews
          </span>
        </span>
      </Link>
    </div>
  );
}

export default LegacyReviewLink;
