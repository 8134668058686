import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import AppRoutes from "../AppRoutes";
import { localStorageHelper } from "../../helpers";

/** On successful login from TT */
function SsoAuthCallback() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Set a value in local storage for where to redirect to, if a `returnUrl` is specified in the query string
    const urlParams = new URLSearchParams(window.location.search);
    const returnUrl = urlParams.get("returnUrl");
    if (returnUrl) {
      localStorageHelper.setItem("AUTH_RETURN_URL", returnUrl);
    }

    if (!auth.isAuthenticated) {
      // User may have authenticated with the ID server,
      // but this React app isn't aware yet. Redirect to sign in,
      // and you'll be routed back, fully authenticated
      auth.signinRedirect();
    } else {
      navigate(AppRoutes.auth.callback, { replace: true });
    }
  }, []);

  return null;
}

export default SsoAuthCallback;
