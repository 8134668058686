import UserNotificationDetails from "../../types/dtos/notification/UserNotificationDetails";
import { apiClient } from "../apiClient";

class notificationApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  getNotificationPanelDataForEmployee(
    onSuccess: (data: UserNotificationDetails) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/notification/get-notification-panel-data-for-employee"
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  markGivenNotificationsAsRead(
    returnMostRecentNotificationsOnly : boolean,
    notificationIds: number[],
    onSuccess: (data: UserNotificationDetails) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/notification/mark-given-notifications-as-read?limitReturnToMostRecent=" +returnMostRecentNotificationsOnly,
      {
        body: JSON.stringify(notificationIds),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  markAllNotificationsAsRead(
    returnMostRecentNotificationsOnly : boolean,
    onSuccess: (data: UserNotificationDetails) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/notification/mark-all-notifications-as-read?limitReturnToMostRecent=" + returnMostRecentNotificationsOnly
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default notificationApi;
