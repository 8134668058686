import { Reducer, useReducer, useState } from "react";
//@ts-ignore
import React from "react";
import UserContext from "../../../state/UserContext";
import { useTranslation } from "react-i18next";
import UserDetailDto from "../../../types/dtos/admin/UserDetailDto";
import adminApi from "../../../api/dashboard/adminApi";
import { useAuth } from "react-oidc-context";
import { GenericDropDownList, InfoBanner } from "../../common";
import { KeyValuePair } from "../../../types/generic";
import UserDetailJourneyOptionPopup from "./UserDetailJourneyOptionPopup";
import { UserDetailDropdownOptionAction } from "../../../types/admin/UserDetailDropdownOptionAction";
import DangerAlert from "../../alerts/DangerAlert";
import SuccessAlert from "../../alerts/SuccessAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSend } from "@fortawesome/pro-solid-svg-icons";
import PaginationAndSearchCriteria from "../../../types/admin/PaginationAndSearchCriteria";
import UserDetailJourneyApiResponseDto from "../../../types/dtos/admin/UserDetailJourneyApiResponseDto";
import UserDetailClientSentJourneyDto from "../../../types/dtos/admin/UserDetailClientSentJourneyDto";
import { ClientSentJourneyApprovalFlow } from "../../../types/admin/ClientSentJourneyApprovalFlow";

interface PopupState {
  isOpen: boolean;
  option: UserDetailDropdownOptionAction;
  titleText: string;
  primaryButtonText: string;
  userName: string | null;
  journeyReference: string | null;
  journeyName: string | null;
  isProcessingRequest: boolean;
  enforceUserConfirmation: boolean;
  approvalFlow: ClientSentJourneyApprovalFlow | null;
}

const getDefaultPopupState = (): PopupState => {
  return {
    isOpen: false,
    option: "SEND-JOURNEY",
    titleText: "Send Journey",
    primaryButtonText: "Confirm",
    userName: null,
    journeyReference: null,
    journeyName: null,
    isProcessingRequest: false,
    enforceUserConfirmation: false,
    approvalFlow: null,
  };
};
interface UserDetailSendNewJourneySectionProps {
  user: UserDetailDto;
  clientSentJourneys: UserDetailClientSentJourneyDto[];
  sendableClientJourneyOptions: KeyValuePair<string, string>[];
  alreadySentJourneyReferences: string[];
  selectedClientSentJourney: KeyValuePair<string, string> | null;
  onClientSentJourneyDropdownChange?(
    selectedValue: KeyValuePair<string, string> | null
  ): void;
  criteriaForReload: PaginationAndSearchCriteria;
  reloadJourneyHistorySuccessCallback(
    data: UserDetailJourneyApiResponseDto
  ): void;
  onUpdateCurrentJourneyDetails(
    journeyReference: string | undefined,
    journeyName: string | undefined
  ): void;
}
function UserDetailSendNewJourneySection({
  user,
  clientSentJourneys,
  sendableClientJourneyOptions,
  alreadySentJourneyReferences,
  selectedClientSentJourney,
  onClientSentJourneyDropdownChange,
  criteriaForReload,
  reloadJourneyHistorySuccessCallback,
  onUpdateCurrentJourneyDetails,
}: UserDetailSendNewJourneySectionProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const [popupState, setPopupState] = useReducer<
    Reducer<PopupState, Partial<PopupState>>
  >((state, newState) => ({ ...state, ...newState }), getDefaultPopupState());
  const [dropdownCurrentValue, setDropdownCurrentValue] = useState<
    string | null
  >(null);

  const [showAlreadySentBanner, setShowAlreadySentBanner] =
    useState<boolean>(false);
  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [successNotificationMessage, setSuccessNotificationMessage] =
    useState<string>("");
  const [showErrorNotification, setShowErrorNotification] =
    useState<boolean>(false);
  const [errorNotificationMessage, setErrorNotificationMessage] =
    useState<string>("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] =
    useState<boolean>(true);

  const triggerSuccessNotification = (message: string) => {
    setShowSuccessNotification(true);
    setSuccessNotificationMessage(message);
    resetErrorNotification();
    handleClosePopup();
  };

  const triggerErrorNotification = (message: string) => {
    setShowErrorNotification(true);
    setErrorNotificationMessage(message);
    resetSuccessNotification();
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setPopupState(getDefaultPopupState());
  };

  const resetSuccessNotification = () => {
    setShowSuccessNotification(false);
    setSuccessNotificationMessage("");
  };

  const resetErrorNotification = () => {
    setShowErrorNotification(false);
    setErrorNotificationMessage("");
  };

  const handleClientSentJourneyOptionDropdownChange = (newValue: string) => {
    if (onClientSentJourneyDropdownChange) {
      const selectedValue = sendableClientJourneyOptions.find(
        (item) => item.key == newValue
      );
      onClientSentJourneyDropdownChange(selectedValue!);
      setDropdownCurrentValue(newValue);
      resetSuccessNotification();
      resetErrorNotification();

      // Show banner if the journey has already been sent to the user.
      const alreadySentThisJourney = alreadySentJourneyReferences.some(
        (item) => item == newValue
      );
      setShowAlreadySentBanner(alreadySentThisJourney);
      setIsSendButtonDisabled(
        selectedValue == null ||
          selectedValue == undefined ||
          alreadySentThisJourney
      );
    }
  };

  const handlePopupPrimaryButtonClick = () => {
    const successCallback = () => {
      if (onClientSentJourneyDropdownChange) {
        onClientSentJourneyDropdownChange(null);
      }

      // Set the popup state to processing so that the popup can show a spinner
      setPopupState({ ...popupState, isProcessingRequest: true });

      // Update the userDetails in state with the new current journey details
      onUpdateCurrentJourneyDetails(
        selectedClientSentJourney!.key,
        selectedClientSentJourney!.value
      );

      // Need to add a delay to the reloading of journey history so that it has time for the service
      // bus to process the message. IF we do it too soon it won't load the latest curent journey.
      setTimeout(() => {
        // On success we need to get the refresh journey history list
        admApi.paginateHistoricalJourneys(
          user.userId,
          criteriaForReload.pageSize,
          criteriaForReload.pageNumber,
          (data) => {
            reloadJourneyHistorySuccessCallback(data);
            // Then trigger the success notification, close the popup and set the dropdown back to default and
            // disable the send button
            triggerSuccessNotification(
              t(
                "Pages.Admin.UserDetails.SuccessNotification.SendJourney"
              ).replace("#JOURNEY_NAME#", popupState.journeyName ?? "")
            );
            setDropdownCurrentValue("default");
            setIsSendButtonDisabled(true);
          },
          (error) => {
            console.error(error);
          },
          criteriaForReload.searchTerm
        );
      }, 2500);
    };

    const errorCallback = () => {
      triggerErrorNotification(
        t("Pages.Admin.UserDetails.ErrorNotification.SendJourney")
      );
    };

    if (selectedClientSentJourney) {
      admApi.sendJourney(
        user.userId,
        selectedClientSentJourney.key,
        successCallback,
        errorCallback
      );
    }
  };

  const onSendButtonClick = () => {
    // Find the client sent journey that matches the selected journey reference
    const csj = clientSentJourneys.find(
      (item) => item.journeyReference == selectedClientSentJourney?.key
    );
    const journeyName =
      csj != null ? csj.journeyTitle : selectedClientSentJourney?.value;

    setPopupState({
      isOpen: true,
      option: "SEND-JOURNEY",
      titleText: t("Pages.Admin.UserDetails.PopupTitle.SendJourney"),
      primaryButtonText: t("Pages.Admin.Common.Confirm"),
      userName: user.fullName,
      journeyName: journeyName,
      enforceUserConfirmation:
        csj?.approvalFlow != "HIDDEN-TO-EMPLOYEE" &&
        user.currentJourneyReference != null &&
        user.currentJourneyTitle != null,
      approvalFlow: csj?.approvalFlow,
    });
  };

  const bannerInfo = (
    <span>
      {t("Pages.Admin.UserDetails.Banner.SendJourney")}{" "}
      <strong>{user.fullName}</strong>.
    </span>
  );

  return (
    <>
      <div className="p-3 !pb-2 bg-white rounded-md mt-3 border border-gray-300">
        <small className="text-gray-500">
          {t("Pages.Admin.Common.SendNewJourney")}
        </small>
        <div className="mt-1 text-gray-500 pb-2">
          <div className="mt-1 grid gap-4 grid-cols-1 md:grid-cols-6">
            <div className="md:col-span-5">
              <GenericDropDownList
                className="w-full bg-gray-100 border-0 text-sm py-1"
                items={sendableClientJourneyOptions}
                currentValue={dropdownCurrentValue}
                includeSelectOption={true}
                includeSelectOptionWithStringDefaultValue={true}
                selectOptionCustomText={t(
                  "Pages.Admin.Common.PleaseSelectAJourney"
                )}
                onChange={handleClientSentJourneyOptionDropdownChange}
              />
            </div>
            <div className="md:col-span-1 text-right text-gray-600 w-full">
              <button
                className="btn-primary text-sm"
                disabled={isSendButtonDisabled}
                onClick={onSendButtonClick}
              >
                <FontAwesomeIcon icon={faSend} />
                <span className="ml-1">{t("Pages.Admin.Common.Send")}</span>
              </button>
            </div>
          </div>
          {showAlreadySentBanner && (
            <InfoBanner children={bannerInfo} className="mt-2" />
          )}
          {showSuccessNotification && (
            <SuccessAlert
              prefix={t("Common.Success")}
              message={t(successNotificationMessage)}
              includeMarginTop={true}
            />
          )}
          {showErrorNotification && (
            <DangerAlert
              prefix={t("Common.Validation.Oops")}
              message={t(errorNotificationMessage)}
            />
          )}
        </div>
      </div>
      <UserDetailJourneyOptionPopup
        isOpen={popupState.isOpen}
        option={popupState.option}
        titleText={popupState.titleText}
        confirmButtonText={popupState.primaryButtonText}
        cancelButtonText={t("Common.Cancel")}
        onOpenChange={handleClosePopup}
        onConfirmButtonClick={handlePopupPrimaryButtonClick}
        onCancelButtonClick={handleClosePopup}
        isJourneyDualPrep={null}
        selectedSendReminderDropdownOption={null}
        sendReminderDropdownOptions={null}
        selectedReopenDropdownOption={null}
        reopenDropdownOptions={null}
        journeyName={popupState.journeyName}
        userName={popupState.userName}
        currentJourneyReference={user.currentJourneyReference}
        currentJourneyName={user.currentJourneyTitle}
        isProcessingSendJourneyRequest={popupState.isProcessingRequest}
        enforceUserConfirmation={popupState.enforceUserConfirmation}
        approvalFlow={popupState.approvalFlow}
      />
    </>
  );
}

export default UserDetailSendNewJourneySection;
