import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import cx from "classnames";
import { idServerConfig } from "../../config/auth/identityServerDetails";

interface AlertPopupProps {
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  onPrimaryButtonClick(): void;
  onSecondaryButtonClick?(): void;
  primaryButtonText: string;
  /** Optional. If you only want a single button, don't supply a value for this */
  secondaryButtonText?: string;
  title: string;
  bodyText: string;
  openedWithinModal?: boolean;
}

/** Create a warning/confirmation alert, with one or two buttons */
const AlertPopup = ({
  isOpen,
  onOpenChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  title,
  bodyText,
  openedWithinModal = false,
}: AlertPopupProps) => {
  return (
    <AlertDialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogPrimitive.Portal container={document.body}>
        <AlertDialogPrimitive.Overlay
          forceMount
          className="fixed inset-0 z-20 bg-black/50"
        />
        <AlertDialogPrimitive.Content
          forceMount
          className={cx(
            "fixed z-50",
            "w-[95vw] max-w-md rounded-lg p-4 md:w-full",
            "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
            "bg-white",
            "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
          )}
        >
          <AlertDialogPrimitive.Title className="text-lg font-medium text-gray-900">
            {title}
          </AlertDialogPrimitive.Title>
          <AlertDialogPrimitive.Description className="mt-2 text-sm font-normal text-gray-700">
            {bodyText}
          </AlertDialogPrimitive.Description>
          <div className="mt-4 flex justify-end space-x-2">
            {secondaryButtonText && onSecondaryButtonClick && (
              <AlertDialogPrimitive.Cancel
                className={cx("inline-flex btn-secondary")}
                onClick={onSecondaryButtonClick}
              >
                {secondaryButtonText}
              </AlertDialogPrimitive.Cancel>
            )}

            <AlertDialogPrimitive.Action
              className={cx("inline-flex btn-primary")}
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonText}
            </AlertDialogPrimitive.Action>
          </div>
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  );
};

export default AlertPopup;
