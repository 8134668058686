import { t } from "i18next";
import produce from "immer";
import { useContext, useEffect, useState } from "react";
import {
  ActorQuestionText,
  FormQuestion,
  FormType,
  QuestionAnswer,
  QuestionTasks,
  ValidationResult,
} from "../../../types/forms";
import ScaleOption from "../../../types/forms/ScaleOption";
import { SliderScoreDisplayType } from "../../../types/generic";
import { Label } from "../../common";
import BehaviourQuestionAnswerValue from "../../../types/forms/BehaviourQuestionAnswerValue";
import { questionTextHelper } from "../../../helpers";
import UserContext from "../../../state/UserContext";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../types/dtos/generic";
import AddFormTaskControl from "./AddFormTaskControl";
import {
  BaseCommentDto,
  BehaviourOptionsDto,
  SavedCommentDto,
} from "../../../types/dtos/forms";
import BehaviourMoreInfo from "./behaviours/BehaviourMoreInfo";
import BehaviourSlider from "./behaviours/BehaviourSlider";
import BehaviourComment from "./behaviours/BehaviourComment";
import CollabDocFlaggedChangeDto from "../../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import CollabDocFlaggedChange from "../../collab-docs/CollabDocFlaggedChange";
import PlanningResponses from "./dual-prep/PlanningResponses";
import BehaviourSubtext from "./behaviours/BehaviourSubtext";

interface MultipleBehaviourQuestionProps {
  question: FormQuestion;
  /** The id of the client form */
  formId: number;
  /** Whether this is being displayed in a journey or a collab doc */
  formType: FormType;
  /** The details about the behaviour set to render sliders for */
  behaviourOptions: BehaviourOptionsDto[];
  /** Whether or not to show the Add Task button */
  showAddTaskButton: boolean;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** The current value(s) for attributes or for the single behaviour slider */
  currentValues: BehaviourQuestionAnswerValue[] | undefined;
  /** Notify the parent component(s) about a changed answer within this behaviour */
  onValueChange(newState: BehaviourQuestionAnswerValue[]): void;
  /** Specify a CSS class name for the track to the left hand side of the drag handle */
  selectedTrackBgColourClassName: string;
  /**The hex of the form colour used in collaborative docs */
  formBackgroundColorStyle?: string | null;
  /** The class name for the add task button which might changed depending in if it's in the collaborative documents or the journey widget */
  addTaskButtonClassName?: string;
  /** The colour of the track to the right hand side of the drag handle */
  emptyTrackBgColourClassName: string;
  /** Who the form is regarding. Used to determine attribute text, e.g. "How is Chris doing?" before a slider */
  subjectUser: BaseUserDetailsDto;
  /** Whether to show the numeric scale selected on the slider or words */
  sliderScoreDisplayType: SliderScoreDisplayType;
  isReadOnly: boolean;
  participants: Array<BaseUserDetailsDto>;
  planningResponseParticipants: Array<UserBasicDetailsDto> | null;
  tasks: QuestionTasks | null;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(questionTasks: QuestionTasks): void;
  /** An event to call when one of the inputs loses focus */
  onBlur?(
    latestValue: BehaviourQuestionAnswerValue[],
    nonStateValue?: QuestionAnswer | null
  ): void | undefined;
  enforcedCommentIsOptional?: boolean | null;
  /** If a comment per behaviour is required, specify the question text */
  enforcedCommentQuestionText: string | null;
  /** The values for the enforced comments, if there are any */
  enforcedComments: BaseCommentDto[];
  /** The onChange event, for handling state changes to the enforced comment */
  onEnforcedCommentChange(
    newValue: string,
    behaviourId: number,
    clientFormId: number
  ): void;
  /** An event to call when the enforced comment textarea loses focus */
  onEnforcedCommentBlur?(
    behaviourId: number,
    nonStateValue: SavedCommentDto | null
  ): void | undefined;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;
  showPlanningResponses: boolean | null;
  onPlanningResponseCopyClick?(newValue: string, behaviourId: number): void;
}

interface BehaviourSliderDetails {
  behaviourId: number;
  scaleOptions: ScaleOption[];
  displayText: ActorQuestionText;
}

function MultipleBehaviourQuestion({
  question,
  formId,
  formType,
  behaviourOptions,
  showValidationErrors,
  currentValues,
  onValueChange,
  onBlur = undefined,
  selectedTrackBgColourClassName,
  formBackgroundColorStyle,
  addTaskButtonClassName = "btn-primary",
  emptyTrackBgColourClassName,
  showAddTaskButton,
  subjectUser,
  sliderScoreDisplayType,
  isReadOnly,
  tasks,
  participants,
  planningResponseParticipants = null,
  flaggedChanges,
  showPlanningResponses,
  onChangeQuestionTasks,
  enforcedCommentIsOptional,
  enforcedCommentQuestionText,
  enforcedComments,
  onEnforcedCommentChange,
  onEnforcedCommentBlur = undefined,
  onPlanningResponseCopyClick,
}: MultipleBehaviourQuestionProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [sliders, setSliders] = useState<BehaviourSliderDetails[]>([]);

  /** Create a copy of the scalePoints array with the scalePoint matching the answer value being selected */
  const getScalePointsCopyWithSelectedValue = (
    selectedValue: number | null,
    scalePoints: ScaleOption[]
  ) => {
    const output = produce(scalePoints, (draft) => {
      draft.forEach((scalePoint) => {
        scalePoint.isSelected = scalePoint.value === selectedValue;

        // Translate the scale point display text, for the slider
        if (scalePoint.text && scalePoint.text.length > 0) {
          scalePoint.text = t(scalePoint.text);
        }
      });
    });

    return output;
  };

  /** Use the answers, the scale points etc to create the state necessary to power the slider questions */
  const intialiseSliders = () => {
    const newState: BehaviourSliderDetails[] = [];

    behaviourOptions.forEach((bhv) => {
      const behaviourAnswer =
        currentValues && Array.isArray(currentValues)
          ? currentValues.find((x) => x.behaviourId === bhv.behaviour.key)
          : null;
      const sliderValue =
        behaviourAnswer && behaviourAnswer !== null
          ? behaviourAnswer.selectedScaleValue
          : null;

      newState.push({
        behaviourId: bhv.behaviour.key,
        scaleOptions: getScalePointsCopyWithSelectedValue(
          sliderValue,
          bhv.scale
        ),
        displayText: {
          manager: bhv.behaviour.value,
          employee: bhv.behaviour.value,
        },
      });
    });

    setSliders(newState);
  };

  // Initial mount
  useEffect(() => {
    intialiseSliders();
  }, []);

  // On answer change
  useEffect(() => {
    intialiseSliders();
  }, [currentValues, behaviourOptions]);

  /** Handle the behaviour slider value change, updating the answer state in the parent component */
  const handleSliderChange = (
    behaviourId: number,
    scaleOptions: ScaleOption[]
  ) => {
    const nextSliderState = produce(sliders, (draft) => {
      const match = draft.find((x) => x.behaviourId === behaviourId);
      if (match !== undefined) {
        match.scaleOptions = scaleOptions;
      }
    });

    // Convert the slider values into the expected answer type array
    const newAnswerState = getAnswersFromLocalSliderState(nextSliderState);

    // Call the onValueChange prop to store the answers in the form answerState
    onValueChange(newAnswerState);
  };

  /** Handle the behaviour slider value change completed, e.g. to call the API to save the new value */
  const handleSliderBlur = (
    behaviourId: number,
    scaleOptions: ScaleOption[]
  ) => {
    // Call the onBlur prop to handle the finished user interaction with this component
    if (onBlur) {
      const sliderValues = produce(sliders, (draft) => {
        const match = draft.find((x) => x.behaviourId === behaviourId);
        if (match !== undefined) {
          match.scaleOptions = scaleOptions;
        }
      });

      // Convert the slider values into the expected answer type array
      const latestValue = getAnswersFromLocalSliderState(sliderValues);

      onBlur(latestValue);
    }
  };

  /** Convert the slider values into the expected answer type array */
  const getAnswersFromLocalSliderState = (
    sliders: BehaviourSliderDetails[]
  ): BehaviourQuestionAnswerValue[] => {
    return sliders.map((slider) => {
      const selectedScaleItem = slider.scaleOptions.find((x) => x.isSelected);
      return {
        behaviourId: slider.behaviourId,
        attributeId: null,
        selectedScaleValue: selectedScaleItem
          ? parseInt(selectedScaleItem.value.toString())
          : null,
      };
    });
  };

  /** Validate the slider. Must have a value (i.e. not null) */
  const validateSlider = (slider: BehaviourSliderDetails): ValidationResult => {
    const selectedValue = slider.scaleOptions.find((x) => x.isSelected);
    const isValid = selectedValue !== undefined;
    return new ValidationResult(isValid, [{ errorType: "REQUIRED" }]);
  };

  return (
    <>
      <div className="pt-2">
        {sliders.map((bhv) => {
          const sliderValidationResult = validateSlider(bhv);
          const behaviourDisplayText = questionTextHelper.getActorText(
            bhv.displayText,
            subjectUser,
            participants!,
            userContext
          );

          const behaviourOption = behaviourOptions.find(
            (x) => x.behaviour.key === bhv.behaviourId
          );
          const behaviourEnforcedComment = enforcedComments.find(
            (x) => x.behaviourId === bhv.behaviourId
          );

          const behaviourFlaggedChanges = flaggedChanges?.filter(
            (x) => x.behaviourId === bhv.behaviourId
          );
          const scoreFlaggedChange = behaviourFlaggedChanges?.find(
            (x) => x.changeType === "BEHAVIOUR"
          );
          const commentFlaggedChange = behaviourFlaggedChanges?.find(
            (x) => x.changeType === "ENFORCED-COMMENT"
          );

          return (
            <div className="mb-2 px-4" key={`bhv${bhv.behaviourId}`}>
              <div className="lg:flex lg:flex-row lg:items-start">
                <Label text={behaviourDisplayText} className="font-medium" />
                <BehaviourMoreInfo
                  behaviour={behaviourOption!.behaviour}
                  infoTooltipContent={behaviourOption!.infoTooltipContent}
                  formType={formType}
                />
              </div>
              <BehaviourSubtext
                behaviour={behaviourOption!.behaviour}
                subtextContent={behaviourOption!.subtextContent}
                classNames={
                  formType === "JOURNEY" ? "text-white/80" : "text-gray-400"
                }
              />
              {showPlanningResponses && planningResponseParticipants && (
                <PlanningResponses
                  subjectUserId={subjectUser.userId}
                  participants={planningResponseParticipants}
                  questionId={question.questionId}
                  questionType={question.questionType}
                  formId={question.formId}
                  behaviourId={behaviourOption!.behaviour.key}
                  onCopyClick={onPlanningResponseCopyClick}
                />
              )}

              {scoreFlaggedChange && participants && (
                <CollabDocFlaggedChange
                  change={scoreFlaggedChange}
                  loggedInUserId={userContext.user.id}
                  participants={participants}
                  question={question}
                  relatedComments={[]}
                  enforcedCommentText={commentFlaggedChange?.previousValue}
                />
              )}

              <BehaviourSlider
                behaviourOrAttributeId={bhv.behaviourId}
                emptyTrackBgColourClassName={emptyTrackBgColourClassName}
                formBackgroundColorStyle={formBackgroundColorStyle}
                handleSliderChange={handleSliderChange}
                showValidationErrors={showValidationErrors}
                sliderValidationResult={sliderValidationResult}
                selectedTrackBgColourClassName={selectedTrackBgColourClassName}
                isReadOnly={isReadOnly}
                onBlur={handleSliderBlur}
                scaleOptions={bhv.scaleOptions}
                sliderScoreDisplayType={sliderScoreDisplayType}
              />

              {enforcedCommentQuestionText && (
                <div className="my-2 px-4">
                  <BehaviourComment
                    behaviourId={bhv.behaviourId}
                    formId={formId}
                    questionIsRequired={!enforcedCommentIsOptional}
                    questionTextTranslationKey={enforcedCommentQuestionText}
                    formType={formType}
                    currentValue={behaviourEnforcedComment?.comment}
                    showValidationErrors={showValidationErrors}
                    isReadOnly={isReadOnly}
                    onChange={onEnforcedCommentChange}
                    onBlur={onEnforcedCommentBlur}
                  />
                </div>
              )}
            </div>
          );
        })}
        {showAddTaskButton && formType != "JOURNEY" && (
          <AddFormTaskControl
            questionId={question.questionId}
            formId={formId}
            questionTasks={tasks}
            onChange={onChangeQuestionTasks}
            isReadOnly={isReadOnly}
            classNames={addTaskButtonClassName}
            formType={formType}
          />
        )}
      </div>
    </>
  );
}

export default MultipleBehaviourQuestion;
