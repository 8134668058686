import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import TimelineItemLoadingCard from "./TimelineItemLoadingCard";
import TimelineItemDetailDto, {
  TimelineItemType,
} from "../../types/dtos/timeline/TimelineItemDetailDto";
import { ReactComponent as NoTasksImage } from "../../images/noTasks.svg";
import { useTranslation } from "react-i18next";
import TimeLineWidgetControlArea from "./TimelineWidgetControlArea";
import { EditableTask } from "../../types/tasks/EditableTasks";
import { TaskType } from "../../types/tasks";
import { ManageTaskPopup } from "../tasks";
import { TaskPopupMode } from "../tasks/ManageTaskPopup";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import TimelineDesktopDisplay from "./TimelineDesktopDisplay";
import TimelineMobileDisplay from "./TimelineMobileDisplay";
import TimelineApiResponseDto from "../../types/dtos/timeline/TimelineApiResponseDto";
import { Switch } from "../common";

interface MyIndividualTimelineItemProps {
  /** The array of objects and counts to show in the timeline */
  itemData: TimelineApiResponseDto;
  /** A method which calls the API and provides a full set of timeline events for the user */
  onRequestAllItems(callback: (data: TimelineApiResponseDto) => void): void;
  /** The array of objects and counts to show in the timeline on the mobile display (the full list of items on desktop too) */
  mobileItemData: TimelineApiResponseDto;
  /** Enable us to show a spinner whilst the data is loading */
  isLoading: boolean;
  clientColour: string;
  /** Handles the changing state of the switch to include/exclude completed items */
  includeCompleted: boolean;
  onIncludeCompletedChange(value: boolean): void;
  /**A method which calls the api to save a Goal/Learning/Action */
  modifyTaskFromDashboard(
    task: EditableTask<number>,
    onSuccess: () => void
  ): void;
  /**A method to call the api and load the task that has been clicked on in the timeline widget */
  onLoadTaskToEdit(
    taskId: number,
    taskType: TaskType,
    successCallback: (data: ModifyTaskResponseDto<number>) => void
  ): void;
  handleTimelineListRefresh(): void;
}

const defaultFilterOption = "ALL";

type TimelineFilterDropdownOptions = TimelineItemType | "ALL";

/** Initially, the active index needs to be the first event in the future */
const getInitialIndexForTimeline = (itemsToFilter: TimelineItemDetailDto[]) => {
  let suggestedIndex = 0;
  const today = dayjs().startOf("day");

  // Relies on the filteredItems array being in date order ascending
  for (var i = 0; i < itemsToFilter.length; i++) {
    const thisItem = itemsToFilter[i];
    const thisItemDate = dayjs(thisItem.dueDate);
    if (thisItemDate >= today) {
      suggestedIndex = i;
      break;
    }
  }

  return suggestedIndex;
};

/** Filter the displayed items based on the value selected in the dropdown */
const filterTimelineDetails = (
  filteredOption: TimelineFilterDropdownOptions,
  itemsToFilter: TimelineItemDetailDto[]
): TimelineItemDetailDto[] => {
  let newItemList;

  if (filteredOption === "JOURNEY") {
    newItemList = itemsToFilter.filter((td) => td.type === filteredOption);
  } else {
    newItemList = itemsToFilter.filter(
      (td) =>
        (filteredOption === defaultFilterOption ||
          td.type === filteredOption) &&
        td.type !== "JOURNEY"
    );
  }

  return newItemList;
};
/** The scrollable timeline shown on the user's own dashboard with meetings, goals etc in */
function TimelineWidget({
  itemData,
  onRequestAllItems,
  mobileItemData,
  isLoading,
  clientColour,
  includeCompleted,
  onIncludeCompletedChange,
  modifyTaskFromDashboard,
  onLoadTaskToEdit,
  handleTimelineListRefresh,
}: MyIndividualTimelineItemProps) {
  const { t } = useTranslation();

  const [hasCalledLoadAll, setHasCalledLoadAll] = useState<boolean>(false);
  const [listVisibleStartingPoint, setListVisibleStartingPoint] = useState<
    number | undefined
  >();
  const [activeTimelineIndex, setActiveTimelineIndex] = useState(0);
  const [activeTimelineMobileIndex, setActiveTimelineMobileIndex] = useState(0);
  const [filterCategory, setFilterCategory] =
    useState<TimelineFilterDropdownOptions>(defaultFilterOption);
  // Use the collection of items supplied via props as the default set to show
  const [filteredItems, setFilteredItems] = useState<TimelineItemDetailDto[]>(
    itemData.items
  );
  const [filteredMobileItems, setFilteredMobileItems] = useState<
    TimelineItemDetailDto[]
  >(itemData.items);
  const [taskToEdit, setTaskToEdit] = useState<EditableTask<number> | null>(
    null
  );

  const [isReadOnlyPopup, setIsReadOnlyPopup] = useState<boolean>(false);
  const [taskLockedByJourney, setTaskLockedByJourney] = useState<string | null>(
    null
  );

  /** When the user changes the timeline filter dropdown */
  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilterValue = e.target.value as TimelineFilterDropdownOptions;
    setFilterCategory(newFilterValue);
  };

  /** Load all items if we haven't already requested them */
  const loadAllTimelineItems = (currentScrollIndex: number) => {
    if (itemData.items.length > 0 && !hasCalledLoadAll) {
      setHasCalledLoadAll(true);
      onRequestAllItems((data: TimelineApiResponseDto) => {
        const newStartIndex = getInitialIndexForTimeline(data.items);
        scrollToItemByIndex(newStartIndex + currentScrollIndex);
      });
    }
  };

  const scrollToItemByIndex = (newActiveIndex: number) => {
    const elementToScrollTo = document.getElementById(
      `timeline-item-${newActiveIndex}`
    );
    if (elementToScrollTo) {
      const container = document.getElementById(
        "scrollable-timeline"
      ) as HTMLDivElement;
      container.scrollTop =
        elementToScrollTo.offsetTop - elementToScrollTo.offsetHeight;
    }
  };

  /** Trigger the call to load the rest of the items if the user
   * reaches the start/end of the list
   */
  const onTimelineScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollAreaElement = event.target as HTMLDivElement;
    const top = scrollAreaElement.scrollTop < 200;
    const bottom =
      scrollAreaElement.scrollHeight - scrollAreaElement.scrollTop ===
      scrollAreaElement.clientHeight;
    if (top || bottom) {
      const visibleItemOffset = getCurrentTopMostVisibleItemOffset();
      loadAllTimelineItems(visibleItemOffset);
    }
  };

  /** Get the offset in terms of array position against the first visible item
   * e.g. if the first future item in the array is at index 4, and the first
   * visible item in the scrolled container is index 10, then the offset is 6
   */
  const getCurrentTopMostVisibleItemOffset = (): number => {
    const container = document.getElementById(
      "scrollable-timeline"
    ) as HTMLDivElement;
    for (var i = 0; i < container.children.length; i++) {
      const element = container.children[i] as HTMLDivElement;
      const yPosition =
        element.offsetTop - element.scrollTop + element.clientTop;

      if (yPosition >= container.scrollTop) {
        return i - listVisibleStartingPoint!; // TODO: Comment properly. Returns a value relative to the start index
      }
    }

    return 0;
  };

  const filterDesktopItems = () => {
    const filterResult = filterTimelineDetails(filterCategory, itemData.items);
    const newActiveIndex = getInitialIndexForTimeline(filterResult);
    setFilteredItems(filterResult);
    setActiveTimelineIndex(newActiveIndex);
    setListVisibleStartingPoint(newActiveIndex);
  };

  const filterMobileItems = () => {
    const filterMobileResult = filterTimelineDetails(
      filterCategory,
      mobileItemData.items
    );
    const newActiveIndex = getInitialIndexForTimeline(filterMobileResult);
    setFilteredMobileItems(filterMobileResult);
    setActiveTimelineMobileIndex(newActiveIndex);
  };

  /** When new items are supplied, update the filtered list */
  useEffect(() => {
    filterDesktopItems();
  }, [itemData]);

  /** When new mobile items are supplied, update the filtered list */
  useEffect(() => {
    filterMobileItems();
  }, [mobileItemData]);

  /** When the filter changes, update the filtered lists */
  useEffect(() => {
    filterDesktopItems();
    filterMobileItems();
  }, [filterCategory]);

  /** Set the scroll start to be the first timeline item with a date of today or onwards
   * so we can scroll back in time
   */
  useEffect(() => {
    if (listVisibleStartingPoint !== undefined && !hasCalledLoadAll) {
      scrollToItemByIndex(listVisibleStartingPoint);
    }
  }, [listVisibleStartingPoint, hasCalledLoadAll]);

  const [popupMode, setPopupMode] = useState<TaskPopupMode>("ADD");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [taskType, setTaskType] = useState<TaskType>();

  const onModalOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setTaskToEdit(null);
      setIsReadOnlyPopup(false);
    }
    setModalIsOpen(isOpen);
  };

  // When the user clicks to add a new task
  const triggerEditTaskPopup = (taskId: number, taskType: TaskType) => {
    const successCallback = (responseData: ModifyTaskResponseDto<number>) => {
      setTaskType(taskType);
      setPopupMode("EDIT");
      setModalIsOpen(true);
      setTaskToEdit(responseData.task);
      setTaskLockedByJourney(responseData.editLockedByJourney);

      if (
        responseData.task &&
        (responseData.task.status === "COMPLETED" ||
          responseData.task.status === "CANCELLED" ||
          responseData.editLockedByJourney)
      ) {
        setIsReadOnlyPopup(true);
      } else {
        setIsReadOnlyPopup(false);
      }
    };
    onLoadTaskToEdit(taskId, taskType, successCallback);
  };

  // When the user clicks to add a new task
  const triggerAddTaskPopup = () => {
    setTaskType(undefined);
    setPopupMode("ADD");
    setModalIsOpen(true);
  };

  const showGoalPopup = () => {
    triggerAddTaskPopup();
    setTaskType("GOAL");
  };

  const showLearningPopup = () => {
    triggerAddTaskPopup();
    setTaskType("LEARNING");
  };

  const showActionPopup = () => {
    triggerAddTaskPopup();
    setTaskType("ACTION");
  };

  const handleTaskSave = (task: EditableTask<number>) => {
    const onSuccess = () => {
      handleTimelineListRefresh();
    };
    modifyTaskFromDashboard(task, onSuccess);
  };

  return (
    <>
      <div className="flex flex-col h-full grow">
        <div id="timeline">
          <div className="flex mb-1">
            <select
              className="header-select grow text-left"
              value={filterCategory}
              onChange={handleCategoryChange}
            >
              <option value={defaultFilterOption}>
                {t("Timeline.Filters.All")}
              </option>
              <option value="TASK">{t("Timeline.Filters.Tasks")}</option>
              <option value="JOURNEY">{t("Timeline.Filters.Journeys")}</option>
              <option value="CATCH-UP">{t("Timeline.Filters.CatchUps")}</option>
            </select>
          </div>
          <div className="mb-1 text-right pr-1">
            <span className="text-xs pr-1 text-gray-500 font-medium">
              {t("Common.IncludeCompleted")}?
            </span>
            <Switch
              checked={includeCompleted}
              onChange={onIncludeCompletedChange}
              size="sm"
            />
          </div>
          {isLoading && !hasCalledLoadAll && (
            <>
              <TimelineItemLoadingCard />
              <TimelineItemLoadingCard />
            </>
          )}
          {!isLoading && filteredItems.length === 0 && (
            <div className="text-center text-gray-400 mt-16 mb-8 grow">
              <NoTasksImage width={"18rem"} className="block m-auto mb-2" />
              <div className=" font-medium text-gray-500 pt-2 pb-1">
                No upcoming tasks!
              </div>
              <div className=" text-gray-400">
                Change the filter option or add a task
              </div>
            </div>
          )}
          {!isLoading && filteredItems.length > 0 && (
            <>
              {/* Show for Desktop/Hide for mobile */}
              <TimelineDesktopDisplay
                items={filteredItems}
                clientColour={clientColour}
                triggerEditTaskPopup={triggerEditTaskPopup}
                onTimelineScroll={onTimelineScroll}
              />

              {/* Hide for Desktop/Show for mobile */}
              <TimelineMobileDisplay
                items={filteredMobileItems}
                clientColor={clientColour}
                triggerEditTaskPopup={triggerEditTaskPopup}
              />
            </>
          )}
        </div>
        <TimeLineWidgetControlArea
          activeActions={itemData.totalActions}
          activeGoals={itemData.totalGoals}
          activeLearnings={itemData.totalLearnings}
          showGoalPopup={showGoalPopup}
          showActionPopup={showActionPopup}
          showLearningPopup={showLearningPopup}
          triggerAddTaskPopup={triggerAddTaskPopup}
          refreshParentTaskList={handleTimelineListRefresh}
        />
        <ManageTaskPopup
          mode={popupMode}
          isOpen={modalIsOpen}
          onOpenChange={onModalOpenChange}
          onSaveClick={handleTaskSave}
          taskType={taskType}
          editObject={taskToEdit}
          popupOrigin="DASHBOARD"
          refreshParentTaskList={handleTimelineListRefresh}
          isReadOnly={isReadOnlyPopup}
          setIsReadOnly={setIsReadOnlyPopup}
          taskLockedByJourney={taskLockedByJourney}
        />
      </div>
    </>
  );
}

export default TimelineWidget;
