import { t } from "i18next";
import CheckboxTreeApiResponseDto from "../../types/dtos/admin/CheckboxTreeApiResponseDto";
import { apiClient } from "../apiClient";

class appraisalLevelApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the appraisal levels ready for a checkbox tree component */
  getAppraisalLevelCheckboxTreeItemsByClientId(
    onSuccess: (appraisalLevels: CheckboxTreeApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/appraisal-level/get-appraisal-level-checkbox-tree-items-by-client-id"
    ).then(
      (data) => {
        const apiResponse = data as CheckboxTreeApiResponseDto;
        // Translate the labels
        apiResponse.nodes = apiResponse.nodes.map((node) => ({
          ...node,
          label: t(node.label),
        }));
        // Sort the labels
        apiResponse.nodes = apiResponse.nodes.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        onSuccess(apiResponse);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default appraisalLevelApi;
