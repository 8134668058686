import cx from "classnames";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

export interface AccordionProps {
  /**Determines whether more than one section can be open at the same time */
  allowMultipleActiveSections: boolean;
  /**The value of the section that should be active (opened) this can be an array if allowMultipleActiveSections is true */
  activeSection: string | Array<string>;
  /**Each accordion item.  This should include the AccordionItem element */
  children: React.ReactNode;
  /**The function that changes the active section when an accordion item is clicked or searched for */
  onActiveSectionChange(newActiveSection: string | Array<string>): void;
  /** Whether or not an open item can be collapsed, leaving no open item. Defaults to true */
  allowCollapseOpenItem?: boolean;
  /** All the keys, used to display everything when in print mode */
  allItemKeys: string[];
  isPrinting?: boolean;
}

/** A form field containing multiple radio buttons */
const Accordion = ({
  allowMultipleActiveSections,
  allItemKeys,
  activeSection,
  children,
  onActiveSectionChange,
  allowCollapseOpenItem = true,
  isPrinting = false,
}: AccordionProps) => {
  if (allowMultipleActiveSections || isPrinting) {
    return (
      <AccordionPrimitive.Root
        type={"multiple"}
        value={isPrinting ? allItemKeys : (activeSection as string[])}
        onValueChange={onActiveSectionChange}
      >
        {children}
      </AccordionPrimitive.Root>
    );
  } else {
    return (
      <AccordionPrimitive.Root
        type={"single"}
        value={activeSection as string}
        onValueChange={onActiveSectionChange}
        collapsible={allowCollapseOpenItem}
      >
        {children}
      </AccordionPrimitive.Root>
    );
  }
};

interface AccordionItemProps {
  /**Unique value of the accordion item.  This is the value that is passed into the root of the element to open and close the accordion */
  itemValue: string;
  /**The content of the trigger element which is the title that can be clicked on to toggle the accordion item open and closed */
  trigger: React.ReactNode;
  /** The conent within each individual accordion item*/
  content: React.ReactNode;
  /** CSS class names to be applied to the whole item */
  classNames?: string;
  /** CSS class names to be applied to the trigger container only */
  triggerClassNames?: string;
  /** CSS class names to be applied to the chevron icons */
  iconClassNames?: string;
  showChevronIcons?: boolean;
  isPrinting?: boolean;
}
export const AccordionItem = ({
  itemValue,
  trigger,
  content,
  classNames = "",
  triggerClassNames = "grow text-left px-2",
  iconClassNames = "mr-4",
  showChevronIcons = true,
}: AccordionItemProps) => {
  return (
    <AccordionPrimitive.Item value={itemValue} className={cx(classNames)}>
      <AccordionPrimitive.Trigger className="block w-full">
        <div className="flex flex-row">
          <div className={triggerClassNames}>{trigger}</div>
          {showChevronIcons && (
            <div className="flex-initial print:hidden">
              <FontAwesomeIcon
                icon={faChevronDown}
                className={cx("icon-inactive", iconClassNames)}
              />
              <FontAwesomeIcon
                icon={faChevronUp}
                className={cx("icon-active", iconClassNames)}
              />
            </div>
          )}
        </div>
      </AccordionPrimitive.Trigger>
      <AccordionPrimitive.Content>{content}</AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
};

export default Accordion;
