import React from "react";
import UserwalkthroughType from "../types/generic/UserWalkthroughType";
import OverrideNavItem from "../types/nav/OverrideNavItem";

export interface AppContextInterface {
  pageTitle: string;
  setPageTitle(newTitle: string): void;
  showPageTitleAccent: boolean;
  setShowPageTitleAccent(value: boolean): void;
  overrideNavItem: OverrideNavItem | null;
  setOverrideNavItem(newItem: OverrideNavItem | null): void;
  activeDashboardTypeForWalkthrough: UserwalkthroughType | null;
  setActiveDashboardTypeForWalkthrough(
    dashboardType: UserwalkthroughType | null
  ): void;
}

const AppContext = React.createContext<AppContextInterface>({
  pageTitle: "",
  setPageTitle(newTitle: string) {},
  showPageTitleAccent: true,
  setShowPageTitleAccent(value: boolean) {},
  overrideNavItem: null,
  setOverrideNavItem(newItem: OverrideNavItem | null) {},
  activeDashboardTypeForWalkthrough: null,
  setActiveDashboardTypeForWalkthrough(value: UserwalkthroughType | null) {},
});

export default AppContext;
