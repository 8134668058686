import { useContext, useEffect, useRef, useState } from "react";
import { possessive } from "i18n-possessive";
import { useAuth } from "react-oidc-context";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import { ModalPopup } from "../common";
import SmallLoader from "../loaders/SmallLoader";
import UserContext from "../../state/UserContext";
import collabDocApi from "../../api/forms/collabDocApi";
import { NewCollabDocCreationResponse } from "../../types/collab-docs";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";

interface UpdateDocBannerProps {
  subjectUser: BaseUserDetailsDto;
  loggedInUserId: number;
  formTitle: string;
  docGuid: string;
  clientFormId: number;
  isDualPrep: boolean;
}

/** The banner which displays in the header of collab docs which allows the user to
 * enter edit mode (which clones the AnswerSet for the given ClientFormId)
 */
function UpdateDocBanner({
  subjectUser,
  loggedInUserId,
  formTitle,
  docGuid,
  clientFormId,
  isDualPrep,
}: UpdateDocBannerProps) {
  // Auth/API
  const auth = useAuth();
  const apiCollabDocs = new collabDocApi(auth.user?.access_token);

  // Context and constants
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  // State
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // Derived state
  const userIsSubjectUser = subjectUser.userId === loggedInUserId;
  const possessiveName = possessive(subjectUser.firstName, "en");

  // Functions
  function wait(time: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  async function doDelayedRedirect(redirectUrl: string) {
    await wait(2000);
    navigate(redirectUrl);
  }

  function handleButtonClick() {
    const onSuccess = (data: NewCollabDocCreationResponse) => {
      if (data.successful) {
        const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
          data.docGuid!,
          false,
          null
        );

        // Delay the redirect slightly so it doesn't flash and disappear before the user can read it
        doDelayedRedirect(redirectUrl);
      } else {
        console.error("Unable to clone doc. Error: " + data.errorMessage);
        setModalIsOpen(false);
        setIsCallingApi(false);
      }
    };

    const onError = (error: any) => {
      console.error("Unable to clone doc", error);
      setModalIsOpen(false);
      setIsCallingApi(false);
    };

    setModalIsOpen(true);
    setIsCallingApi(true);

    // Call the API
    apiCollabDocs.cloneDoc(
      {
        clientFormId: clientFormId,
        sourceDocGuid: docGuid,
      },
      onSuccess,
      onError
    );
  }

  // Header message text
  const headerMessageName = userIsSubjectUser ? "your" : possessiveName;
  const headerMessage = `This is ${headerMessageName} most recent completed '${formTitle}'.`;

  // Button text
  const buttonName = userIsSubjectUser ? "my" : possessiveName;
  const buttonText = `Update ${buttonName} '${formTitle}'`;

  // Modal message text
  const modalMessageName = userIsSubjectUser ? "your" : possessiveName;
  const modalMessage1 = `Preparing ${modalMessageName} '${formTitle}' for updates.`;

  return (
    <div className="p-2 my-1 bg-gray-300 rounded-sm flex flex-row content-center">
      <div className="grow leading-relaxed text-gray-500 font-medium text-sm">
        {headerMessage}
      </div>
      {/* If it's a dual prep journey, we don't want to allow the user to create a standard journey */}
      {!isDualPrep && (
        <div className="flex-none">
          <button
            onClick={handleButtonClick}
            disabled={isCallingApi}
            className="px-2 py-0.5 disabled:cursor-not-allowed accent-color text-white rounded-sm text-sm"
          >
            {buttonText}
          </button>
        </div>
      )}
      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={setModalIsOpen}
        onPrimaryButtonClick={() => setModalIsOpen(false)}
        title={"Entering edit mode"}
        showCloseIcon={false}
        width="SMALL"
      >
        <div className="text-center pt-4">
          <SmallLoader colour={userContext.user.client.accentHexColour} />
          <p className="mt-4 mb-2">{modalMessage1}</p>
          <p>This page will refresh and enter edit mode shortly&hellip;</p>
        </div>
      </ModalPopup>
    </div>
  );
}

export default UpdateDocBanner;
