import { apiClient } from "../apiClient";
import download from "downloadjs";
import ExportGenerationRequest from "../../types/exports/ExportGenerationRequest";
import ExportGenerationStatus from "../../types/exports/ExportGenerationStatus";
import IsExportReadyResponseDto from "../../types/exports/IsExportReadyResponseDto";

class exportsApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  isExportReadyToDownload(
    exportId: string,
    onSuccess: (data: IsExportReadyResponseDto) => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/export/is-export-ready-to-download?exportId=" + exportId;

    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  queueAdvancedTaskDetailExportForGeneration(
    request: ExportGenerationRequest,
    onSuccess: (data: string) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/export/queue-advanced-task-detail-export-for-generation", 
      {
        body: JSON.stringify(request),
      },
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  downloadExport(
    exportId: string,
    filenameWithoutExtension: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/export/download-export?exportId=" + exportId;

    return apiClient(
      this.accessToken,
      apiUrl,
      undefined,
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        download(
          blob,
          `${filenameWithoutExtension}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }
}

export default exportsApi;
