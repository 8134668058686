import { t } from "i18next";
import { SimpleFormQuestionPopupType } from "../../../types/dtos/simple-forms/SimpleFormQuestionPopupType";
import { Badge } from "../../common";
import {
  faPlus,
  faMinus,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-duotone-svg-icons";

interface SimpleFormQuestionMoreInfoProps {
  isReadOnly: boolean;
  hasPopupType: boolean;
  popupType?: SimpleFormQuestionPopupType;
  popupTriggerText?: string | null;
  popupHtmlContent?: string | null;
  popupUrlDestination?: string | null;
  showMoreInfoPanel: boolean;
  toggleShowMoreInfoPanel: () => void;
}

export const SimpleFormQuestionMoreInfo = ({
  isReadOnly,
  hasPopupType,
  popupType,
  popupTriggerText,
  popupHtmlContent,
  popupUrlDestination,
  showMoreInfoPanel,
  toggleShowMoreInfoPanel,
}: SimpleFormQuestionMoreInfoProps) => {
  const popupTriggerClassNames = {
    container: "ml-2",
    badgeText: !isReadOnly ? "text-sky-600" : "text-gray-600",
    badgeBg: !isReadOnly ? "bg-sky-200" : "bg-gray-200",
    badgeMargin: "m-0",
    badgeCursor: "cursor-pointer",
  };

  // If no trigger text is given, default to 'More info'.
  const triggerText =
    popupTriggerText != null
      ? t(popupTriggerText)
      : t("Forms.Generic.MoreInfoButtonText");

  // if there is no popup type given, we have to work it out based on the values given.
  //@ts-ignore
  const puType: SimpleFormQuestionPopupType = hasPopupType
    ? popupType
    : popupHtmlContent != null && popupHtmlContent != undefined
    ? "HTML-CONTENT"
    : popupUrlDestination != null && popupUrlDestination != undefined
    ? "LINK-TO-NEW-TAB"
    : "NO-POPUP";

  return (
    <>
      {puType === "HTML-CONTENT" && (
        <button
          className={popupTriggerClassNames.container}
          onClick={() => toggleShowMoreInfoPanel()}
          tabIndex={-1}
        >
          <Badge
            text={triggerText}
            backgroundColourClassName={popupTriggerClassNames.badgeBg}
            textColourClassName={popupTriggerClassNames.badgeText}
            marginClassName={popupTriggerClassNames.badgeMargin}
            cursorClassName={popupTriggerClassNames.badgeCursor}
            icon={showMoreInfoPanel ? faMinus : faPlus}
            iconPlacement="right"
          />
        </button>
      )}
      {puType === "LINK-TO-NEW-TAB" && popupUrlDestination && (
        <a
          target="_blank"
          href={popupUrlDestination}
          className={popupTriggerClassNames.container}
        >
          <Badge
            text={triggerText}
            backgroundColourClassName={popupTriggerClassNames.badgeBg}
            textColourClassName={popupTriggerClassNames.badgeText}
            marginClassName={popupTriggerClassNames.badgeMargin}
            cursorClassName={popupTriggerClassNames.badgeCursor}
            icon={faArrowUpRightFromSquare}
            iconPlacement="right"
          />
        </a>
      )}
    </>
  );
};

export default SimpleFormQuestionMoreInfo;
