import { t } from "i18next";
import {
  AnswerSetApprovalStatus,
  ApprovalFlow,
  CollabDocSubmitResponseErrorType,
} from "../types/collab-docs";
import { AlertContent } from "../types/generic";

const collabDocAlertHelper = {
  /** Gets the error message content for a given `CollabDocSubmitResponseErrorType` */
  getErrorMessage: function (
    errorType: CollabDocSubmitResponseErrorType | null
  ): AlertContent {
    let msgIdentifierKey: string = "GenericError";

    if (errorType) {
      switch (errorType) {
        case "ANSWERSET-ALREADY-APPROVED":
          msgIdentifierKey = "AlreadyApproved";
          break;
        case "INVALID-ANSWERSET":
          msgIdentifierKey = "InvalidAnswerSet";
          break;
        case "INVALID-PROPOSED-STATUS":
          msgIdentifierKey = "InvalidProposedStatus";
          break;
        case "UNRECOGNISED-PROPOSED-STATUS":
          msgIdentifierKey = "UnrecognisedStatus";
          break;
      }
    }

    const identifierPrefix = "Pages.CollaborativeDocument.Alerts.Submit.Error.";

    return {
      body: t(identifierPrefix + msgIdentifierKey + ".Body"),
      title: t(identifierPrefix + msgIdentifierKey + ".Title"),
    };
  },

  /** Gets the success message for a given `AnswerSetApprovalStatus` */
  getSuccessMessage: function (
    approvalStatus: AnswerSetApprovalStatus,
    approvalFlow: ApprovalFlow,
    otherParticipantFirstName: string,
    loggedInUserIsSubjectUser: boolean,
    isDualPrepMeetingAvailable?: boolean
  ): AlertContent {
    let msgIdentifierKey: string = "GenericSuccess";
    let buttonText: string = t("Common.GoBackHome");

    switch (approvalStatus) {
      case "APPROVED-BY-EMPLOYEE":
        msgIdentifierKey = "ApprovedByEmployee";
        break;
      case "APPROVED-BY-MANAGER":
        msgIdentifierKey = "ApprovedByManager";
        break;
      case "FULLY-APPROVED":
        if (approvalFlow === "HIDDEN-TO-EMPLOYEE") {
          msgIdentifierKey = "FullyApprovedNonMeeting";
        } else {
          msgIdentifierKey = "FullyApproved";
        }
        break;
      case "INITIAL-SAVE-FORM-INCOMPLETE":
        // Should never happen - this status should only be set at the end of a journey with a goal review in it, never via a collab doc
        break;
      case "NO-APPROVAL-NECESSARY":
        // Should never happen - single-sided journey submitted, no need for collab doc
        break;
      case "RETURNED-BY-EMPLOYEE":
        msgIdentifierKey = "ReturnedByEmployee";
        break;
      case "RETURNED-BY-MANAGER":
        msgIdentifierKey = "ReturnedByManager";
        break;
      case "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING":
        msgIdentifierKey = "EmployeeSavedWithoutApproving";
        break;
      case "SAVED-BY-MANAGER-WITHOUT-APPROVING":
        msgIdentifierKey = "ManagerSavedWithoutApproving";
        break;
      case "DUAL-PREP-SUBMITTED":
        if (!isDualPrepMeetingAvailable) {
          msgIdentifierKey = "ManagerDualPrepPlanningSubmitted";
        } else {
          msgIdentifierKey = "ManagerDualPrepSubmitted";
        }

        if (!loggedInUserIsSubjectUser) {
          // If the manager is the logged in user, then they will go back to the manager dashboard
          buttonText = t("Common.GoBackToMyPeople");
        }
        break;
    }

    const identifierPrefix =
      "Pages.CollaborativeDocument.Alerts.Submit.Success.";

    // Replace common tokens, in this case, the name of the other participant,
    // regardless of whether they are employee or manager
    const replaceTokens = (input: string): string => {
      if (!input) return input;

      let output = input.replace("#EMPLOYEE#", otherParticipantFirstName);
      output = output.replace("#MANAGER#", otherParticipantFirstName);
      return output;
    };

    return {
      body: replaceTokens(t(identifierPrefix + msgIdentifierKey + ".Body")),
      title: replaceTokens(t(identifierPrefix + msgIdentifierKey + ".Title")),
      button: buttonText,
    };
  },
};

export default collabDocAlertHelper;
