import React from "react";
import { debounce } from "lodash";
import TimelineItem from "./TimelineItem";
import TimelineItemDetailDto from "../../types/dtos/timeline/TimelineItemDetailDto";
import { TaskType } from "../../types/tasks";

interface TimelineDesktopDisplayProps {
  /** The array of objects to show in the timeline */
  items: TimelineItemDetailDto[];
  clientColour: string;
  triggerEditTaskPopup(taskId: number, taskType: TaskType): void;
  onTimelineScroll(event: React.UIEvent<HTMLDivElement>): void;
}

function TimelineDesktopDisplay({
  items,
  clientColour,
  triggerEditTaskPopup,
  onTimelineScroll,
}: TimelineDesktopDisplayProps) {
  const debouncedScrollHandler = debounce(onTimelineScroll, 250);
  return (
    <div className="hidden sm:contents">
      <div
        id="scrollable-timeline"
        className="snap-y overflow-scroll overflow-x-hidden grow ml-1"
        style={{ maxHeight: "340px" }}
        onScroll={debouncedScrollHandler}
      >
        {items.map((td, ix) => (
          <div
            className="snap-start"
            key={`timeline-item-${ix}`}
            id={`timeline-item-${ix}`}
          >
            <TimelineItem
              key={td.titleTranslationKey}
              titleTranslationKey={td.titleTranslationKey}
              customTitle={td.customTitle}
              dueDate={td.dueDate}
              type={td.type}
              taskId={td.taskId}
              catchUpId={td.catchUpId}
              taskType={td.taskType}
              triggerEditTaskPopup={triggerEditTaskPopup}
              catchUpOtherParticipantFullName={
                td.catchUpOtherParticipantFullName
              }
              dateCompleted={td.dateCompleted}
              isPlanning={td.isPlanning}
              isCompleted={td.isCompleted}
              clientColour={clientColour}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TimelineDesktopDisplay;
