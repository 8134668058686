import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import Tooltip from "../../common/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface UserTagProps {
  icon?: IconDefinition;
  tooltip?: string;
  text: string;
}

export const UserTag = ({
  icon,
  tooltip,
  text,
}: UserTagProps) => {
  const badgeElement = (
    <p className="inline-block text-xs text-[#5d5d5d] bg-[#F5F5F5] rounded-sm px-2 py-1 mr-2">
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          size="1x"
          className="text-[#C4C4C4] pr-1"
        />
      )}
      {text}
    </p>
  );

  return (
    <>
      {tooltip != undefined && (
        <Tooltip content={tooltip} triggerElement={badgeElement} />
      )}
      {tooltip == undefined && (
        badgeElement
      )}
    </>
  );
};

export default UserTag;
