function FormSummaryLoadingCard() {
  return (
    <>
      <div className="bg-gray-200 h-12 p-3 overflow-hidden animate-pulse">
        {" "}
      </div>
      <div className="h- p-3">
        <div className="grid grid-cols-3 gap-4 mt-2">
          <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-8 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
          <div className=" h-8 bg-gray-200 rounded animate-pulse"></div>
          <div className="..."></div>
          <div className="col-span-2 ..."></div>
        </div>
      </div>
    </>
  );
}

export default FormSummaryLoadingCard;
