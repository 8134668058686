import { useTranslation } from "react-i18next";
import ClientSentJourneyStatus from "../../types/dtos/admin/ClientSentJourneyStatus";

interface ClientSentJourneyStatusDisplayProps {
  value: ClientSentJourneyStatus;
  className?: string;
}

const ClientSentJourneyStatusDisplay = ({ value, className = "" }: ClientSentJourneyStatusDisplayProps) => {
  const { t } = useTranslation();

  switch (value) {
    case "CANCELLED":
      return <span className={className}>{t("SentJourney.Status.Cancelled")}</span>;
    case "ERROR-SENDING":
      return <span className={className}>{t("SentJourney.Status.ErrorSending")}</span>;
    case "AWAITING-TRANSLATIONS":
      return <span className={className}>{t("SentJourney.Status.AwaitingTranslations")}</span>;
    case "TRANSLATIONS-READY":
      return <span className={className}>{t("SentJourney.Status.TranslationsReady")}</span>;
    case "PROCESSING":
      return <span className={className}>{t("SentJourney.Status.Processing")}</span>;
    case "COMPLETED":
      return <span className={className}>{t("SentJourney.Status.Completed")}</span>;
    default:
      return <span className={className}>{t("SentJourney.Status.Unknown")}</span>;
  }
};

export default ClientSentJourneyStatusDisplay;
