import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";

interface DangerBannerProps {
  text?: string;
  children?: React.ReactNode;
}

/** Renders the children, if supplied, otherwise falls back to displaying the text prop */
function DangerBanner({ text, children }: DangerBannerProps) {
  const isUsingChildrenElements = children != null && children != undefined;
  return (
    <div className={cx(
      "mb-2 py-1 text-sm px-4 bg-rose-50 text-rose-500 rounded-y-md rounded-r-md border-l-2 border-l-rose-500",
      isUsingChildrenElements ? "flex" : ""
    )}>
      <div className={cx(
        "fa-layers fa-fw mr-1",
        isUsingChildrenElements ? "mt-1" : ""
      )}>
        <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
      </div>
      {children ? children : text}
    </div>
  );
}

export default DangerBanner;
