import { t } from "i18next";
import { arrayHelper, mathsHelper } from "../../../helpers";
import { byPropertiesOf } from "../../../helpers/arraySorting";
import { AnalyticsWidgetUiDetailsDto } from "../../../types/analytics";
import { Tooltip } from "../../common";
import { chartConstants } from "./ChartConstants";
import DonutChart from "./DonutChart";

interface ComplexScoreOverviewProps {
  widget: AnalyticsWidgetUiDetailsDto;
}

interface PrimaryScoreChartProps {
  percentage: number;
  hexColour: string;
  displayMode: "STANDALONE" | "WITH-CHILD-SCORES";
}

const PrimaryScoreDoughnut = ({
  percentage,
  hexColour,
  displayMode,
}: PrimaryScoreChartProps) => {
  return (
    <div
      className={
        displayMode === "WITH-CHILD-SCORES"
          ? "flex justify-center xl:justify-end xl:pr-4"
          : "flex justify-center"
      }
    >
      <div className="max-w-xs">
        <DonutChart
          percentage={percentage}
          label="Average"
          hexColour={hexColour}
        />
      </div>
    </div>
  );
};

function ComplexScoreOverview({ widget }: ComplexScoreOverviewProps) {
  if (
    !widget ||
    !widget.datasetAverageScores ||
    widget.datasetAverageScores.length === 0
  )
    return null;

  // Find the main average score
  const primaryAverage = widget.datasetAverageScores.find(
    (score) => !score.isChildAverage
  );
  if (!primaryAverage) return null;

  const colourToUse = arrayHelper.getRandom(chartConstants.coloursArray);

  const childAverages = widget.datasetAverageScores
    .filter((x) => x.isChildAverage)
    .map((x) => ({
      ...x,
      displayTitle:
        x.groupName && x.translateGroupName ? t(x.groupName) : x.groupName,
    }));

  const hasChildScores = childAverages.length > 0;
  return (
    <div
      data-widget-id={widget.widgetId}
      className={hasChildScores ? "flex flex-col mb-2 xl:flex-row" : ""}
    >
      <div
        className={
          hasChildScores ? "flex-auto w-full xl:w-1/2 py-2 xl:py-0" : ""
        }
      >
        <PrimaryScoreDoughnut
          percentage={primaryAverage.averageScore}
          hexColour={colourToUse}
          displayMode={hasChildScores ? "WITH-CHILD-SCORES" : "STANDALONE"}
        />
      </div>
      {hasChildScores && (
        <div className="flex-auto w-full xl:w-1/2">
          <div className="flex flex-col gap-y-2 items-center xl:items-start xl:justify-center xl:h-full xl:pl-2 cursor-default">
            {childAverages
              .sort(byPropertiesOf(["displayTitle"]))
              .map((childAverage, iChild) => (
                <div
                  key={childAverage.groupName}
                  className="text-center xl:text-left font-semibold text-gray-600 border-b border-dotted xl:px-4"
                  style={{
                    borderColor:
                      iChild === childAverages.length - 1
                        ? "transparent"
                        : colourToUse,
                  }}
                >
                  <span className="inline-block py-2 transition ease-in-out delay-150 hover:scale-105 origin-center">
                    {childAverage.groupName}:{" "}
                    <Tooltip
                      triggerElement={
                        <span className="text-lg text-gray-700 font-bold">
                          {mathsHelper.roundForDisplay(
                            childAverage.averageScore,
                            0
                          )}
                          %
                        </span>
                      }
                      content={
                        mathsHelper.roundForDisplay(
                          childAverage.averageScore,
                          2
                        ) + "%"
                      }
                    />
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ComplexScoreOverview;
