import { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import produce from "immer";
import { Accordion } from "../../../common";
import { AccordionItem } from "../../../common/Accordion";
import GoalReviewAccordionHeader from "./GoalReviewAccordionHeader";
import GoalReviewGoalDetail from "./GoalReviewGoalDetail";
import GoalReviewStatusRadios from "./GoalReviewStatusRadios";
import {
  FormQuestion,
  FormType,
  GoalReviewQuestionAnswerValue,
} from "../../../../types/forms";
import GoalReviewEnforcedComment from "./GoalReviewEnforcedComment";
import ReviewedGoalCount from "./ReviewedGoalCount";
import { BaseCommentDto } from "../../../../types/dtos/forms";
import CollabDocFlaggedChangeDto from "../../../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../../types/dtos/generic";
import PlanningResponses from "../dual-prep/PlanningResponses";

interface GoalReviewAccordionProps {
  question: FormQuestion;
  /** The ids of goals which have passed validation and have been successfully reviewed */
  reviewedGoalIds: number[];
  /** The type of goal review to display */
  goalReviewType: "STATUS-AND-COMMENT" | "<extendable for later use>";
  isReadOnly: boolean;
  showValidationErrors: boolean;
  formType: FormType;
  currentValues: GoalReviewQuestionAnswerValue[] | null;
  onValueChange(newValue: GoalReviewQuestionAnswerValue[]): void;
  onValueBlur(newValue: GoalReviewQuestionAnswerValue[]): void;
  enforcedComments: BaseCommentDto[];
  onEnforcedCommentChange(
    goalId: number,
    newValue: string | null,
    clientFormId: number
  ): void;
  /** An event to call when the enforced comment textarea loses focus */
  onEnforcedCommentBlur(goalId: number, newValue: string | null): void;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;
  /** Participant details are used in collab docs for flagged changes */
  participants?: Array<UserBasicDetailsDto> | undefined;
  subjectUser: BaseUserDetailsDto;
  showPlanningResponses: boolean | null;
  onPlanningResponseCopyClick?(newValue: string, goalId: number): void;
  isPrinting: boolean;
}

function GoalReviewAccordion({
  question,
  reviewedGoalIds,
  goalReviewType,
  showValidationErrors,
  isReadOnly,
  formType,
  currentValues,
  onValueChange,
  onValueBlur,
  enforcedComments,
  onEnforcedCommentChange,
  onEnforcedCommentBlur,
  flaggedChanges,
  participants,
  subjectUser,
  showPlanningResponses,
  isPrinting,
  onPlanningResponseCopyClick,
}: GoalReviewAccordionProps) {
  const [activeAccordionGoalId, setActiveAccordionGoalId] =
    useState<string>("");

  useEffect(() => {
    // On load, open the first goal accordion section
    if (
      !activeAccordionGoalId &&
      question.goalReviewOptions &&
      question.goalReviewOptions.goals &&
      question.goalReviewOptions.goals.length > 0
    ) {
      const firstGoal = question.goalReviewOptions.goals[0];
      setActiveAccordionGoalId(`goal_${firstGoal.toDoId}`);
    }
  }, [question.goalReviewOptions?.goals]);

  const onChangeActiveTab = (tabName: string) => {
    setActiveAccordionGoalId(tabName);
  };

  const getAnswerStateAfterStatusChange = (
    goalId: number,
    selectedOptionId: number | undefined
  ) => {
    const previousAnswerState = currentValues ? currentValues : [];
    const nextSelectedItemsState = produce(previousAnswerState, (draft) => {
      // Check the item isn't already in the selected list, and if not, add it!
      const match = draft.find((x) => x.toDoId === goalId);

      const selectedStatusOptionId = selectedOptionId ? selectedOptionId : null;

      if (match) {
        match.goalStatusOptionId = selectedStatusOptionId;
      } else {
        //Add to the selection
        draft.push({
          goalStatusOptionId: selectedStatusOptionId,
          toDoId: goalId,
        });
      }
    });

    return nextSelectedItemsState;
  };

  const handleGoalStatusChange = useCallback(
    (goalId: number, selectedOptionId: number | undefined) => {
      // Update the state
      const nextSelectedItemsState = getAnswerStateAfterStatusChange(
        goalId,
        selectedOptionId
      );
      onValueChange(nextSelectedItemsState);
    },
    [currentValues, onValueChange]
  );

  const handleGoalStatusBlur = useCallback(
    (goalId: number, selectedOptionId: number | undefined) => {
      // Save the answer via the API
      const nextSelectedItemsState = getAnswerStateAfterStatusChange(
        goalId,
        selectedOptionId
      );
      onValueBlur(nextSelectedItemsState);
    },
    [currentValues, onValueBlur]
  );

  // Don't render anything if there aren't any goals to review
  if (!question.goalReviewOptions) return null;

  const goals = question.goalReviewOptions!.goals;
  const statusOptions = question.goalReviewOptions!.goalStatusOptions;

  if (goals.length === 0) return null;

  /** The identifier for the comment text translation key */
  const commentQuestionText: string | null =
    question.goalReviewOptions!.commentQuestionText;

  /** The (optional) identifier to show in the textbox for the enforced comment */
  const commentPlaceholderText: string | null = question.placeholderText;

  const accordionItemKeys = goals.map((goal) => `goal_${goal.toDoId}`);

  return (
    <div>
      <ReviewedGoalCount
        goalCount={goals.length}
        reviewedGoalIds={reviewedGoalIds}
        formType={formType}
      />
      <div>
        <Accordion
          allowMultipleActiveSections={false}
          activeSection={activeAccordionGoalId}
          onActiveSectionChange={onChangeActiveTab}
          allItemKeys={accordionItemKeys}
          isPrinting={isPrinting}
        >
          {goals.map((goal, ix) => {
            let enforcedCommentValue: string | null = null;
            if (enforcedComments && enforcedComments.length > 0) {
              const commentMatch = enforcedComments.find(
                (x) => x.goalId !== null && x.goalId === goal.toDoId
              );
              if (commentMatch) {
                enforcedCommentValue = commentMatch.comment;
              }
            }

            let selectedStatusOptionId: number | undefined = undefined;
            if (currentValues && currentValues.length > 0) {
              const valueMatch = currentValues.find(
                (x) => x.toDoId === goal.toDoId
              );
              if (valueMatch && valueMatch.goalStatusOptionId) {
                selectedStatusOptionId = valueMatch.goalStatusOptionId;
              }
            }

            return (
              <AccordionItem
                key={`goal_${goal.toDoId}`}
                itemValue={`goal_${goal.toDoId}`}
                classNames="pb-2"
                triggerClassNames="grow text-left pr-2"
                iconClassNames=""
                trigger={
                  <GoalReviewAccordionHeader
                    goalNumber={ix + 1}
                    goalTitle={goal.title!}
                    isValid={reviewedGoalIds.indexOf(goal.toDoId!) >= 0}
                  />
                }
                content={
                  <div
                    className={cx(
                      "border-dotted border-[1px] border-opacity-40 mt-1 px-2 py-1 rounded-sm",
                      formType === "JOURNEY"
                        ? "border-white"
                        : "border-gray-400"
                    )}
                  >
                    <GoalReviewGoalDetail goal={goal} />
                    <hr
                      className={cx(
                        "border-dotted border-opacity-40",
                        formType === "JOURNEY"
                          ? "border-white"
                          : "border-gray-400"
                      )}
                    />
                    {showPlanningResponses && participants && (
                      <div className="pt-2">
                        <PlanningResponses
                          subjectUserId={subjectUser.userId}
                          participants={participants}
                          questionId={question.questionId}
                          questionType={question.questionType}
                          formId={question.formId}
                          goalId={goal.toDoId}
                          onCopyClick={onPlanningResponseCopyClick}
                        />
                      </div>
                    )}
                    <div className="p-1">
                      {goalReviewType === "STATUS-AND-COMMENT" && (
                        <>
                          <GoalReviewStatusRadios
                            goalId={goal.toDoId!}
                            selectedStatus={selectedStatusOptionId}
                            statusOptions={statusOptions}
                            isReadOnly={isReadOnly}
                            showValidationErrors={showValidationErrors}
                            formType={formType}
                            onChange={handleGoalStatusChange}
                            onBlur={handleGoalStatusBlur}
                            flaggedChanges={flaggedChanges}
                            participants={participants}
                            question={question}
                          />
                          {commentQuestionText && (
                            <div className="mt-2">
                              <GoalReviewEnforcedComment
                                formId={question.formId}
                                formType={formType}
                                goalId={goal.toDoId!}
                                questionText={commentQuestionText}
                                placeholderText={commentPlaceholderText}
                                isReadOnly={isReadOnly}
                                showValidationErrors={showValidationErrors}
                                commentValue={
                                  enforcedCommentValue
                                    ? enforcedCommentValue
                                    : undefined
                                }
                                onBlur={onEnforcedCommentBlur}
                                onChange={onEnforcedCommentChange}
                                flaggedChanges={flaggedChanges}
                                participants={participants}
                                question={question}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                }
              />
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}

export default GoalReviewAccordion;
