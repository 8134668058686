import { useTranslation } from "react-i18next";
import { ValidationResult } from "../../types/forms";
import { KeyValuePair } from "../../types/generic";
import GenericDropDownList from "./GenericDropDownList";
import NumberInput from "./NumberInput";
import InfoAlert from "../alerts/InfoAlert";
import ClientFormAutomationUnitType from "../../types/dtos/admin/ClientFormAutomationUnitType";

interface AutomationPickerProps {
  /** The Id attribute for the input element, to match the Label */
  numberInputId?: string;
  dropdownInputId?: string;
  /** The onChange event, for handling state changes */
  onAutomationAmountChange(newValue: number): void;
  onAutomationUnitChange(newValue: string): void;
  /** The current value for the input */
  value: number;
  selectedAutomationUnit: ClientFormAutomationUnitType;
  automationUnits: KeyValuePair<ClientFormAutomationUnitType, string>[];
  nextDate?: Date;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

const AutomationPicker = ({
  numberInputId,
  dropdownInputId,
  onAutomationAmountChange,
  onAutomationUnitChange,
  value,
  selectedAutomationUnit,
  automationUnits,
  nextDate,
}: AutomationPickerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-3 md:col-span-2">
          <NumberInput
            className="w-full text-base"
            onChange={onAutomationAmountChange}
            value={value}
            minimum={1}
            maximum={100}
            step={1}
            inputId={numberInputId}
          />
        </div>
        <div className="col-span-9 md:col-span-10">
          <GenericDropDownList
            currentValue={selectedAutomationUnit ? selectedAutomationUnit : null}
            items={automationUnits}
            onChange={onAutomationUnitChange}
            className="block w-full"
            inputId={dropdownInputId}
            isReadOnly={false}
            includeSelectOption={false}
            applyBorder={true}
          />
        </div>
      </div>
      <div className="pt-1">
        <InfoAlert
          prefix={""}
          message={t("Pages.Admin.Common.PrefixTextForNextJourneyDate")}
          optionalDate={nextDate}
        />
      </div>
    </>
  );
};

export default AutomationPicker;
