const ttModulesList = {
  Administration: "Administration",
  AdvDash: {
    Root: "Advanced Dashboard",
  },
  CoffeeChats: "Coffee Chats",
  CompanyDash: "Company Dashboard",
  Dashboard: {
    Root: "Dashboard",
  },
  Journeys: "Journeys",
  MobileApp: "Mobile App",
  Profiles: "Profiles",
  Reports: "Reports",
  Reviews: "Reviews",
  SSO: "SingleSignOn",
  Tasks: {
    Root: "Tasks",
  },
  TempChecks: "Temperature Checks",
};

export default ttModulesList;
