import { t } from "i18next";
import UserwalkthroughType from "../../types/generic/UserWalkthroughType";
import UserWalkthrough from "./UserWalkthrough";

interface DeclinedWalkthroughProps {
  walkthroughType: UserwalkthroughType;
  onFinished(): void;
}

function DeclinedWalkthrough({
  walkthroughType,
  onFinished,
}: DeclinedWalkthroughProps) {
  const steps = [
    {
      target: "#walkthrough-button",
      contentTranslationKey:
        t("Walkthrough.Declined.IfYouChangeYourMind"),
    },
  ];

  return (
    <UserWalkthrough
      walkthroughType={walkthroughType}
      steps={steps}
      declinedWalkthrough={true}
      onFinished={onFinished}
    />
  );
}

export default DeclinedWalkthrough;
