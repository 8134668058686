import download from "downloadjs";
import AudienceTabType from "../../types/admin/AudienceTabType";
import AdminConfigApiResponseDto from "../../types/dtos/admin/AdminConfigApiResponseDto";
import JourneyConfigurationTabApiResponseDto from "../../types/dtos/admin/JourneyConfigurationTabApiResponseDto";
import { DefaultSettingDto } from "../../types/dtos/admin/DefaultSettingDto";
import { OverrideDto } from "../../types/dtos/admin/OverrideDto";
import SendNowApiResponseDto from "../../types/dtos/admin/SendNowApiResponseDto";
import SendNowUserApiResponseDto from "../../types/dtos/admin/SendNowUserApiResponseDto";
import SentJourneyApiResponseDto from "../../types/dtos/admin/SentJourneyApiResponseDto";
import SentJourneyRecipientApiResponseDto from "../../types/dtos/admin/SentJourneyRecipientApiResponseDto";
import SendNowSubmissionFormDto from "../../types/dtos/admin/submission/SendNowSubmissionFormDto";
import { WindowDto } from "../../types/dtos/admin/WindowDto";
import { apiClient } from "../apiClient";
import { dateHelper } from "../../helpers";
import UserJourneysTabApiResponseDto from "../../types/dtos/admin/UserJourneysTabApiResponseDto";
import PaginationSearchAndFilterCriteria from "../../types/admin/PaginationSearchAndFilterCriteria";
import UserDetailPageLoadApiResponseDto from "../../types/dtos/admin/UserDetailPageLoadApiResponseDto";
import UserDetailJourneyApiResponseDto from "../../types/dtos/admin/UserDetailJourneyApiResponseDto";
import UserDetailHistoryPageApiResponseDto from "../../types/dtos/admin/UserDetailHistoryPageApiResponseDto";
import TaskManagementReviewDateType from "../../types/admin/TaskManagementReviewDateType";

class adminApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;
  
  getUsersForSearch(
    criteria: PaginationSearchAndFilterCriteria,
    isNonAdminUserWithManagerConfigAccess: boolean,
    onSuccess: (data: UserJourneysTabApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/admin/get-users-for-search?isNonAdminUserWithManagerConfigAccess=" + isNonAdminUserWithManagerConfigAccess;

    return apiClient(
      this.accessToken,
      apiUrl,
      {
        body: JSON.stringify(criteria),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  getUserPageInfo(
    userId: number,
    isNonAdminUserWithManagerConfigAccess: boolean,
    pageSize: number,
    pageNumber: number,
    onSuccess: (data: UserDetailPageLoadApiResponseDto) => void,
    onError: (error: any) => void,
    searchTerm?: string | null,
  ) {
    let apiUrl = "dashboards/admin/get-user-page-info?userId=" + userId + "&isNonAdminUserWithManagerConfigAccess=" + isNonAdminUserWithManagerConfigAccess + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber;
    if (searchTerm) {
      apiUrl += "&searchTerm=" + searchTerm;
    }
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  getUserAdminHistoryPage(
    userId: number,
    isNonAdminUserWithManagerConfigAccess: boolean,
    pageSize: number,
    pageNumber: number,
    onSuccess: (data: UserDetailHistoryPageApiResponseDto) => void,
    onError: (error: any) => void,
  ) {
    let apiUrl = "dashboards/admin/get-user-admin-history-page?userId=" + userId + "&isNonAdminUserWithManagerConfigAccess=" + isNonAdminUserWithManagerConfigAccess + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber;
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  isAllowedToViewThisUser(
    userId: number,
    isNonAdminUserWithManagerConfigAccess: boolean,
    onSuccess: (data: boolean) => void,
    onError: (error: any) => void,
  ) {
    let apiUrl = "dashboards/admin/is-allowed-to-view-this-user?userId=" + userId + "&isNonAdminUserWithManagerConfigAccess=" + isNonAdminUserWithManagerConfigAccess;
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  paginateHistoricalJourneys(
    userId: number,
    pageSize: number,
    pageNumber: number,
    onSuccess: (data: UserDetailJourneyApiResponseDto) => void,
    onError: (error: any) => void,
    searchTerm?: string | null,
  ) {
    let apiUrl = "dashboards/admin/paginate-historical-journeys?userId=" + userId + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber;
    if (searchTerm) {
      apiUrl += "&searchTerm=" + searchTerm;
    }
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  updateManagerForUserId(
    userId: number,
    proposedManagerId: number,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/admin/update-manager-for-user-id?userId=" + userId + "&proposedManagerId=" + proposedManagerId;
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  updateJourneyManagerForUserId(
    userId: number,
    proposedJourneyManagerId: number | null,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/admin/update-journey-manager-for-user-id?userId=" + userId + "&proposedJourneyManagerId=" + proposedJourneyManagerId;
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  updateStatusForUserId(
    userId: number,
    proposedStatusId: number,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
  ) {
    let apiUrl = "dashboards/admin/update-status-for-user-id?userId=" + userId + "&proposedStatusId=" + proposedStatusId;
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  sendReminder(
    journeyReference: string,
    sendToBoth: boolean,
    onSuccess: (data: any) => void,
    onError: (error: any) => void,
    userId?: number,
    managerRoleId?: number,
  ) {
    let apiUrl = "dashboards/admin/send-reminder?journeyReference=" + journeyReference + "&sendToBoth=" + sendToBoth;
    if (userId) {
      apiUrl += "&userId=" + userId;
    }
    if (managerRoleId) {
      apiUrl += "&managerRoleId=" + managerRoleId;
    }
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  cancelJourney(
    userId: number,
    journeyReference: string,
    isDualPrepJourney: boolean,
    shouldClearUsersCurrentJourneyFields: boolean,
    onSuccess: () => void,
    onError: () => void,
    id?: number | null,
  ) {
    let apiUrl = "dashboards/admin/cancel-journey?userId=" + userId + "&journeyReference=" + journeyReference + "&isDualPrepJourney=" + isDualPrepJourney + "&shouldClearUsersCurrentJourneyFields=" + shouldClearUsersCurrentJourneyFields;
    if (id) {
      apiUrl += "&id=" + id;
    }
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError();
      }
    );
  }

  reopenJourney(
    userId: number,
    journeyReference: string,
    answerSetId: number,
    isDualPrepJourney: boolean,
    onSuccess: () => void,
    onError: () => void,
    dualPrepAnswerSetId?: number | null,
  ) {
    let apiUrl = "dashboards/admin/reopen-journey?userId=" + userId + "&journeyReference=" + journeyReference + "&answerSetId=" + answerSetId + "&isDualPrepJourney=" + isDualPrepJourney;
    if (dualPrepAnswerSetId) {
      apiUrl += "&dualPrepAnswerSetId=" + dualPrepAnswerSetId;
    }
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError();
      }
    );
  }

  sendJourney(
    userId: number,
    journeyReference: string,
    onSuccess: () => void,
    onError: () => void,
  ) {
    let apiUrl = "dashboards/admin/send-journey?userId=" + userId + "&journeyReference=" + journeyReference;
    return apiClient(
      this.accessToken,
      apiUrl
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError();
      }
    );
  }

  /** Loads the client forms for the admins page */
  getClientFormsByClientId(
    onSuccess: (clientForms: JourneyConfigurationTabApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-client-forms-by-client-id"
    ).then(
      (data) => {
        const clientFormGroups = data as JourneyConfigurationTabApiResponseDto;
        onSuccess(clientFormGroups);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Loads the configuration for the client form and its overrides */
  getClientFormConfigById(
    clientFormId: number,
    onSuccess: (clientForms: AdminConfigApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-client-form-config-by-id?clientFormId=" +
      clientFormId
    ).then(
      (data) => {
        const configData = data as AdminConfigApiResponseDto;
        onSuccess(configData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Sends the Default Settings Dto to the server to be saved */
  saveDefaultSettings(
    clientFormId: number,
    defaultSettings: DefaultSettingDto,
    onSuccess: (data: WindowDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/save-default-settings?clientFormId=" + clientFormId,
      {
        body: JSON.stringify(defaultSettings),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Sends the Override Dto to the server to be saved/updated */
  saveOverride(
    clientFormId: number,
    overrideDto: OverrideDto,
    onSuccess: (data: OverrideDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/save-override?clientFormId=" + clientFormId,
      {
        body: JSON.stringify(overrideDto),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Send the Override Dto to the server to be deleted */
  deleteOverride(
    clientFormId: number,
    overrideDto: OverrideDto,
    onSuccess: (data: OverrideDto[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/delete-override?clientFormId=" + clientFormId,
      {
        body: JSON.stringify(overrideDto),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Send the Override Dto to the server to be deleted */
  getDetailsForSendPage(
    clientFormId: number,
    onSuccess: (data: SendNowApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-details-for-send-page?clientFormId=" + clientFormId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Retrieve the user count for the send now confirmation modal */
  getUserCountForSendPageConfirmation(
    audienceTabType: AudienceTabType,
    userIdsSwitchValue: boolean,
    excludeInductions: boolean,
    ids: string[],
    onSuccess: (data: number) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-user-count-for-send-page-confirmation?audienceTabType=" +
      audienceTabType +
      "&userIdsSwitchValue=" +
      userIdsSwitchValue +
      "&excludeInductions=" +
      excludeInductions,
      {
        body: JSON.stringify(ids),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Retrieve the user count for the send now confirmation modal */
  getUsersForSendPageConfirmation(
    audienceTabType: AudienceTabType,
    userIdsSwitchValue: boolean,
    excludeInductions: boolean,
    ids: string[],
    onSuccess: (data: SendNowUserApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-users-for-send-page-confirmation?audienceTabType=" +
      audienceTabType +
      "&userIdsSwitchValue=" +
      userIdsSwitchValue +
      "&excludeInductions=" +
      excludeInductions,
      {
        body: JSON.stringify(ids),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Sends the SendNowForm Dto over to the API to action */
  submitSendPageForm(
    submissionDto: SendNowSubmissionFormDto,
    onSuccess: (data: boolean) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/submit-send-page-form",
      {
        body: JSON.stringify(submissionDto),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Update a ClientJourney's deadline via a given ClientSentJourneyId */
  updateSentJourneyDeadline(
    clientSentJourneyId: number,
    deadline: Date | null,
    onSuccess: (successful: boolean) => void,
    onError: (error: any) => void
  ) {
    const dateStringValue =
      dateHelper.convertDateObjectToServerString(deadline);
    return apiClient(
      this.accessToken,
      "dashboards/admin/update-sent-journey-deadline?clientSentJourneyId=" +
        clientSentJourneyId +
        "&deadline=" +
        (dateStringValue ? dateStringValue : "")
    ).then(
      (success) => {
        onSuccess(success);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Update a ClientJourney's allow sending via a given ClientSentJourneyId */
  updateSentJourneyAllowAdminSending(
    clientSentJourneyId: number,
    allowSending: boolean,
    onSuccess: (successful: boolean) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/update-sent-journey-allow-admin-sending?clientSentJourneyId=" + clientSentJourneyId + "&allowSending=" + allowSending
    ).then(
      (success) => {
        onSuccess(success);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Update a ClientJourney's Task Management Question Configs via a given ClientSentJourneyId */
  updateSentJourneyTaskManagementConfigs(
    clientSentJourneyId: number,
    taskMinimumDate: Date | null,
    taskDateType: TaskManagementReviewDateType | null,
    onSuccess: (successful: boolean) => void,
    onError: (error: any) => void
  ) {
    const dateStringValue =
      dateHelper.convertDateObjectToServerString(taskMinimumDate);
      
    return apiClient(
      this.accessToken,
      "dashboards/admin/update-sent-journey-task-management-configs?clientSentJourneyId=" +
        clientSentJourneyId +
        "&taskMinimumDate=" +
        (dateStringValue ? dateStringValue : "") +
        "&taskDateType=" +
        (taskDateType ? taskDateType : "")
    ).then(
      (success) => {
        onSuccess(success);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the dtos of sent journeys to show on page */
  getSendingHistory(
    onSuccess: (data: SentJourneyApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-sending-history"
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Gets the dtos for recipients of a given sent journey id */
  getClientSentJourneyRecipients(
    clientSentJourneyId: number,
    onSuccess: (data: SentJourneyRecipientApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/get-recipients-for-client-sent-journey?clientSentJourneyId=" +
      clientSentJourneyId
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Download the export of data for the client form */
  downloadFormResponseData(
    clientFormId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/admin/export-form/" + clientFormId,
      undefined,
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        download(
          blob,
          `export_${clientFormId}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }
}

export default adminApi;
