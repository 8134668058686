import { useState } from "react";
import { useTranslation } from "react-i18next";
import ManageCatchUpPopup from "../catch-ups/ManageCatchUpPopup";
import AddCatchUpButtonArea from "./AddCatchUpButtonArea";
import TimelineTaskArea from "./TimelineTaskArea";

interface TimeLineWidgetControlAreaProps {
  activeGoals: number;
  activeLearnings: number;
  activeActions: number;
  showGoalPopup(): void;
  showActionPopup(): void;
  showLearningPopup(): void;
  triggerAddTaskPopup(): void;
  refreshParentTaskList(): void;
}

const TimeLineWidgetControlArea = ({
  activeGoals,
  activeLearnings,
  activeActions,
  showGoalPopup,
  showActionPopup,
  showLearningPopup,
  triggerAddTaskPopup,
  refreshParentTaskList,
}: TimeLineWidgetControlAreaProps) => {
  const { t } = useTranslation();
  //Modal States
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  // When the user clicks to add a new Catch Up
  const onModalOpen = () => {
    setModalIsOpen(true);
  };
  const onModalOpenChange = (isOpen: boolean) => {
    setModalIsOpen(isOpen);
  };

  return (
    <>
      <div className="mr-1">
        <div className="">
          <TimelineTaskArea
            activeGoalsCount={activeGoals}
            activeLearningCount={activeLearnings}
            activeActionsCount={activeActions}
            showGoalPopup={showGoalPopup}
            showActionPopup={showActionPopup}
            showLearningPopup={showLearningPopup}
            triggerAddTaskPopup={triggerAddTaskPopup}
          />
        </div>
        <hr className="mb-3 mt-3 ml-1" />
        <div>
          <AddCatchUpButtonArea onModalOpen={onModalOpen} />
        </div>
      </div>

      <ManageCatchUpPopup
        isOpen={modalIsOpen}
        onOpenChange={onModalOpenChange}
        refreshParentTaskList={refreshParentTaskList}
      />
    </>
  );
};

export default TimeLineWidgetControlArea;
