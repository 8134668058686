import { mathsHelper, npsHelper } from "../../../../../helpers";
import { byPropertiesOf } from "../../../../../helpers/arraySorting";
import { NpsResults } from "../../../../../helpers/npsHelper";
import { BarChartItemGroup } from "../../../../../types/analytics/charts/BarCharts";
import { Tooltip } from "../../../../common";
import { t } from "i18next";

interface NpsCompareTableProps {
  scores: Array<BarChartItemGroup>;
}

function NpsCompareTable({ scores }: NpsCompareTableProps) {
  const groupedResults = Array.from(Array(scores[0].items.length).keys()).map(
    (ix) => ({
      ...npsHelper.calculateValuesFromRawData(scores, ix),
      index: ix,
      compareItemTitle: scores[0].items[ix].translateLabel
        ? t(scores[0].items[ix].label)
        : scores[0].items[ix].label,
    })
  );

  // Sort by NPS score descending (highest score first)
  const rankedResults = groupedResults.sort(
    byPropertiesOf<NpsResults>(["-npsScore"])
  );

  return (
    <div className="w-full overflow-x-auto">
      <table className="datatable mt-2 mb-4">
        <thead>
          <tr>
            <th className="text-center py-1 px-2">Rank</th>
            <th className="text-left py-1 px-2">Compare Option</th>
            <th className="text-center py-1 px-2">People</th>
            <th className="text-center py-1 px-2">Detractors</th>
            <th className="text-center py-1 px-2">Passive</th>
            <th className="text-center py-1 px-2">Promoters</th>
            <th className="text-center py-1 px-2">NPS Score</th>
          </tr>
        </thead>
        <tbody>
          {rankedResults.map((npsCalcResult, ix) => {
            const rank = ix + 1;
            return (
              <tr>
                <td className="text-center border-b-[1px] border-gray-200 py-1 px-2">
                  {rank}
                </td>
                <td className="text-left border-b-[1px] border-gray-200 py-1 px-2">
                  {npsCalcResult.compareItemTitle}
                </td>
                <td className="text-center border-b-[1px] border-gray-200 py-1 px-2">
                  {npsCalcResult.totalPeople}
                </td>
                <DetailCell
                  value={npsCalcResult.detractors}
                  total={npsCalcResult.totalPeople}
                />
                <DetailCell
                  value={npsCalcResult.passives}
                  total={npsCalcResult.totalPeople}
                />
                <DetailCell
                  value={npsCalcResult.promoters}
                  total={npsCalcResult.totalPeople}
                />
                <Tooltip
                  triggerElement={
                    <td className="text-center border-b-[1px] border-gray-200 py-1 px-2">
                      {`${
                        npsCalcResult.npsScorePrefix
                      }${mathsHelper.roundForDisplay(
                        npsCalcResult.npsScore,
                        0
                      )}`}
                    </td>
                  }
                  content={`Precise value: ${
                    npsCalcResult.npsScorePrefix
                  }${mathsHelper.roundForDisplay(npsCalcResult.npsScore, 3)}`}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function DetailCell({ value, total }: { value: number; total: number }) {
  return (
    <td className="text-center border-b-[1px] border-gray-200 py-1 px-2">
      {mathsHelper.getPercentageForDisplay(value, total, 0)}
      <span className="ml-2 text-gray-500 text-sm">({value})</span>
    </td>
  );
}

export default NpsCompareTable;
