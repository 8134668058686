import { arrayHelper, mathsHelper } from "../../../helpers";
import { Keyframes } from "../../common";
import { chartConstants } from "./ChartConstants";

interface DonutChartProps {
  percentage: number;
  /** Hex colour code with the hash */
  hexColour?: string | undefined;
  label?: string | undefined;
}

const DonutChart = ({
  percentage,
  label = undefined,
  hexColour = undefined,
}: DonutChartProps) => {
  var hexColourToUse: string =
    hexColour && hexColour.startsWith("#")
      ? hexColour
      : arrayHelper.getRandom(chartConstants.coloursArray);
  const displayPercentage = mathsHelper.roundForDisplay(percentage, 0);
  const tooltipPercentage = mathsHelper.roundForDisplay(percentage, 2) + "%";
  var svgDashArray = `${displayPercentage} ${100 - Number(displayPercentage)}`;

  const segmentAnimationName = `donut-segment-animation-${displayPercentage}`;
  const segmentAnimationDuration = percentage > 50 ? "3s" : "1.5s";

  return (
    <>
      <Keyframes
        name={segmentAnimationName}
        _0={{ strokeDasharray: "0, 100" }}
        _100={{
          strokeDasharray: `${displayPercentage}, ${
            100 - Number(displayPercentage)
          }`,
        }}
      />
      <div className="donut-container cursor-default">
        <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
          <circle
            className="donut-hole"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="#fff"
          ></circle>
          <circle
            className="donut-ring"
            style={{ stroke: "#ebebeb" }}
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="5"
          ></circle>
          <circle
            className="donut-segment"
            style={{
              stroke: hexColourToUse,
              animation: `${segmentAnimationName} ${segmentAnimationDuration}`,
            }}
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="5"
            stroke-dasharray={svgDashArray}
            stroke-dashoffset="25"
          ></circle>
          <g className="donut-text">
            <text y="50%" transform={`translate(0, ${label ? "2" : "3"})`}>
              <title>{tooltipPercentage}</title>
              <tspan
                x="50%"
                text-anchor="middle"
                className="donut-percent"
                style={{ fill: hexColourToUse }}
              >
                {displayPercentage}
                <tspan font-size="6">%</tspan>
              </tspan>
            </text>
            {label && (
              <text y="60%" transform="translate(0, 2)">
                <tspan x="50%" text-anchor="middle" className="donut-data">
                  {label}
                </tspan>
              </text>
            )}
          </g>
        </svg>
      </div>
    </>
  );
};

export default DonutChart;
