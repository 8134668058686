import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageHelper } from "../../helpers";
import AppRoutes from "../AppRoutes";

/** On successful login... */
function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    // If there's a `returnUrl` to redirect to instead of the standard home page,
    // remove that item from localstorage once it's been used, then redirect there
    const returnUrl = localStorageHelper.getItem("AUTH_RETURN_URL");
    if (returnUrl && returnUrl.indexOf("/") === 0) {
      localStorageHelper.removeItem("AUTH_RETURN_URL");
      navigate(decodeURIComponent(returnUrl), { replace: true });
    } else {
      navigate(AppRoutes.yourJourney.root, { replace: true });
    }
  }, []);

  return null;
}

export default AuthCallback;
