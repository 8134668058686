import { Markup } from "interweave";
import cx from "classnames";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { JourneyDeadlineMessage } from "./JourneyDeadlineMessage";

interface JourneyWelcomeMessageProps {
  htmlText: string;
  userHasUpdates: boolean;
  isPrefillAnswersEnabled: boolean;
  /** The deadline for the journey, not to be confused with the expiry date for the partial answer set */
  journeyDeadline: Date | null;
  onHide(): void;
  onBackToUpdates(): void;
  createAndPrefillCollabDoc(): void;
}

function JourneyWelcomeMessage({
  htmlText,
  userHasUpdates,
  isPrefillAnswersEnabled,
  journeyDeadline,
  onBackToUpdates,
  onHide,
  createAndPrefillCollabDoc,
}: JourneyWelcomeMessageProps) {
  return (
    <div className="flex flex-col justify-between h-full grow">
      <div className="journey-custom-content welcome-message flex flex-1 items-start py-4">
        <Markup content={htmlText} />
      </div>
      {journeyDeadline && (
        <div className="my-2 text-white">
          <JourneyDeadlineMessage deadline={journeyDeadline} />
        </div>
      )}
      <div
        className={cx(
          "mt-4 mb-4",
          userHasUpdates ? "text-center flex-end" : "text-right"
        )}
      >
        <div className={userHasUpdates ? "flex flex-row flex-end" : ""}>
          {userHasUpdates && (
            <>
              <button className="text-white" onClick={onBackToUpdates}>
                <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                <span className="ml-[2px]">Back</span>
              </button>
              <div className="grow"></div>
            </>
          )}
          <button
            className="bg-white font-medium text-gray-700 rounded-md px-6 py-2 shadow-md hover:shadow-md hover:shadow-white/20 text-center"
            onClick={
              !isPrefillAnswersEnabled ? onHide : createAndPrefillCollabDoc
            }
          >
            {t("Journey.Button.StartJourney")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default JourneyWelcomeMessage;
