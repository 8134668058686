import { Routes, Route } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import IndividualTaskManagement from "./IndividualTaskManagement";

function YourJourneyRoot() {
  return (
    <>
      <Routes>
        <Route
          path={AppRoutes.yourJourney.sub.tasks.path}
          element={<IndividualTaskManagement />}
        />
        <Route
          path={AppRoutes.yourJourney.sub.tasks.pathWithTaskId}
          element={<IndividualTaskManagement />}
        />
      </Routes>
    </>
  );
}

export default YourJourneyRoot;
