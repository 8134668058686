import { AnalyticsNavItem } from "../../types/dtos/users/SignedInUserDto";
import SubNavItem from "../../types/nav/SubNavItem";
import AppRoutes from "../AppRoutes";

const AnalyticsSubNavItems = (
  pages: AnalyticsNavItem[],
  canAccessClassicReports: boolean,
  navType: "LEFT-NAV" | "TOUCH"
): SubNavItem[] => {
  const translationIdentifierPrefix = `Nav.${
    navType === "LEFT-NAV" ? "LargeScreen" : "Touch"
  }.Analytics.`;

  const output = [
    {
      title: translationIdentifierPrefix + "Usage",
      translateTitle: true,
      url: AppRoutes.analytics.usage,
    },
  ];

  if (pages) {
    pages.forEach((p) =>
      output.push({
        title: p.pageTitle,
        translateTitle: false,
        url: AppRoutes.analytics.dynamicPage.generateRoute(p.pageId.toString()),
      })
    );
  }

  if (navType === "LEFT-NAV") {
    output.push({
      title: translationIdentifierPrefix + "Exports",
      translateTitle: true,
      url: AppRoutes.analytics.exports,
    });

    if (canAccessClassicReports) {
      output.push({
        title: translationIdentifierPrefix + "Classic",
        translateTitle: true,
        url: AppRoutes.analytics.classic,
      });
    }
  }

  return output;
};

export default AnalyticsSubNavItems;
