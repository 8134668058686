import { chunk } from "lodash";
import { mathsHelper } from "../../../helpers";
import AnalyticsWidgetUiDetailsDto from "../AnalyticsWidgetUiDetailsDto";

export interface NineBoxGridData {
  rows: NineBoxGridRowData[];
}

export interface NineBoxGridRowData {
  rowNumber: number;
  boxes: NineBoxGridBoxData[];
}

export interface NineBoxGridBoxData {
  number: number;
  count: number;
  percentage: number;
}

export class NineBoxGridDataTransformer {
  transformData(widget: AnalyticsWidgetUiDetailsDto): NineBoxGridData {
    const outputModel: NineBoxGridData = {
      rows: [],
    };

    try {
      // Convert the dataset items into a model we can use in the 9BG UI component
      if (widget?.datasets && widget.datasets[0]) {
        const boxCount = widget.datasets[0].items.length;
        if (boxCount > 0) {
          // Get the square root of the total number of boxes to work out width/height,
          // e.g. square root of 9 is 3. So if there are 9 boxes, we want a 3x3 grid.
          const gridDimensions = parseInt(
            Math.ceil(Math.sqrt(boxCount)).toString()
          );
          if (gridDimensions > 0) {
            // Get the total across all boxes (for calculating percentages)
            const allBoxesTotal: number = widget.datasets[0].items.reduce(
              (acc: number, item: any) => acc + Number(item.value),
              0
            );

            // Create a model for each box
            const boxes: NineBoxGridBoxData[] = widget.datasets[0].items.map(
              (item: any) => {
                const boxCount = isNaN(Number(item.value))
                  ? 0
                  : Number(item.value);
                const boxPercentage =
                  boxCount > 0
                    ? mathsHelper.getPercentage(boxCount, allBoxesTotal, 4)
                    : 0;
                const boxModel: NineBoxGridBoxData = {
                  number: isNaN(Number(item.label)) ? 0 : parseInt(item.label),
                  count: boxCount,
                  percentage: boxPercentage ? boxPercentage : 0,
                };
                return boxModel;
              }
            );

            // If every box has a valid box number, we can create the rows
            if (boxes.filter((x) => x.number === 0).length === 0) {
              // Create a model for each row, chunking the boxes into equal sized rows
              outputModel.rows = chunk<NineBoxGridBoxData>(
                boxes,
                gridDimensions
              ).map((box, ix) => {
                const rowModel = {
                  rowNumber: ix + 1, // 1-indexed
                  boxes: box,
                };
                return rowModel;
              });
            }
          }
        }
      }
    } catch (error) {
      console.error(
        "Unable to transform data for nine box grid: " + widget?.widgetId
      );
    }

    return outputModel;
  }
}
