import { useState } from "react";
import { dateHelper, userDetailsHelper } from "../../helpers";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import { FormattedDate } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faBell } from "@fortawesome/pro-regular-svg-icons";
import { FormQuestion } from "../../types/forms";
import { t } from "i18next";
import { SavedCommentDto } from "../../types/dtos/forms";

interface CollabDocFlaggedChangeProps {
  change: CollabDocFlaggedChangeDto;
  loggedInUserId: number;
  participants: Array<BaseUserDetailsDto>;
  relatedComments: Array<SavedCommentDto> | null;
  question: FormQuestion;
  enforcedCommentText?: string | undefined | null;
}

const getMultiChoicePreviousValue = (
  change: CollabDocFlaggedChangeDto,
  question: FormQuestion
): string | null => {
  const selectedFormOptionIdStrings = change.multiPreviousValues
    ? change.multiPreviousValues
    : [change.previousValue];

  const outputArray: string[] = [];
  selectedFormOptionIdStrings.forEach((idString) => {
    const thisId = Number(idString);
    if (!isNaN(thisId)) {
      const matchingOption = question.multiChoiceOptions?.find(
        (opt) => opt.optionId === thisId
      );
      if (matchingOption) {
        // Append a comma if this isn't the first option
        let valueToAdd: string;
        if (question.questionType === "SLIDER") {
          valueToAdd = matchingOption.value!.toString();
        } else {
          valueToAdd = t(matchingOption.text);
        }
        // Add the value if it isn't in the output already
        if (outputArray.indexOf(valueToAdd) === -1) {
          outputArray.push(valueToAdd);
        }
      }
    }
  });

  return outputArray.length > 0 ? outputArray.join(", ") : null;
};

const getGoalReviewStatusPreviousValue = (
  change: CollabDocFlaggedChangeDto,
  question: FormQuestion
): string | null => {
  let output: string | null = null;
  const previousSelectedOptionId = Number(change.previousValue);
  if (
    !isNaN(previousSelectedOptionId) &&
    question.goalReviewOptions &&
    question.goalReviewOptions.goalStatusOptions &&
    question.goalReviewOptions.goalStatusOptions.length > 0
  ) {
    const matchingOption = question.goalReviewOptions.goalStatusOptions.find(
      (x) => x.key === previousSelectedOptionId
    );
    if (matchingOption) {
      output = t(matchingOption.value);
    }
  }
  return output;
};

function CollabDocFlaggedChange({
  change,
  loggedInUserId,
  participants,
  relatedComments,
  question,
  enforcedCommentText = undefined,
}: CollabDocFlaggedChangeProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  if (change.authorId === loggedInUserId) {
    // Don't display your own flagged changes
    return null;
  }

  const authorName = userDetailsHelper.getDisplayName(
    change.authorId,
    participants
  );

  const localTimestamp = dateHelper.convertUtcDateToLocal(change.timestamp);

  const commentsToDisplay: string[] = [];
  if (enforcedCommentText && enforcedCommentText.length > 0) {
    commentsToDisplay.push(enforcedCommentText);
  } else if (relatedComments !== null && relatedComments.length > 0) {
    commentsToDisplay.push(...relatedComments.map((c) => c.comment));
  }

  let previousValueDisplayText: string | null = null;
  switch (change.changeType) {
    case "MULTI-CHOICE":
      previousValueDisplayText = getMultiChoicePreviousValue(change, question);
      break;
    case "GOAL-REVIEW-STATUS":
      previousValueDisplayText = getGoalReviewStatusPreviousValue(
        change,
        question
      );
      break;
    default:
      previousValueDisplayText = change.previousValue;
      break;
  }

  return (
    <div className=" py-1 px-4 bg-blue-100 my-1 mx-2 rounded-md print:hidden">
      <div className="flex flex-row justify-between">
        <div className="text-xs text-blue-500 inline-block self-center">
          <FontAwesomeIcon icon={faBell} className="mr-1 font-semibold" />
          {t("Common.UpdatedBySentence.LastUpdated")}{" "}
          <FormattedDate date={localTimestamp} displayMode="FROM-NOW" />{" "}
          {t("Common.UpdatedBySentence.UpdatedBy")} {authorName}
        </div>
        <button
          className="bg-white text-blue-500 text-xs font-normal text-center border drop-shadow-sm py-0.5 px-3 m-0 rounded-md mx-2"
          onClick={toggleIsExpanded}
        >
          {isExpanded ? (
            <>
              {t("Pages.CollaborativeDocument.Controls.HideChangeDetails")}
              <FontAwesomeIcon icon={faMinus} size={"xs"} className="ml-1" />
            </>
          ) : (
            <>
              {t("Pages.CollaborativeDocument.Controls.ShowChangeDetails")}
              <FontAwesomeIcon icon={faPlus} size={"xs"} className="ml-1" />
            </>
          )}
        </button>
      </div>
      {isExpanded && (
        <div className=" text-xs">
          <div className="overflow-hidden">
            <span className="text-gray-400 inline">
              {t(
                "Pages.CollaborativeDocument.Controls.FlaggedChangePreviousAnswer"
              ) + ":"}
            </span>
            <div className="italic text-gray-600 text-xs inline ml-1">
              {previousValueDisplayText}
            </div>
          </div>
          {commentsToDisplay && commentsToDisplay.length > 0 && (
            <div>
              <div className="text-gray-400 mt-1 inline">
                {t("Pages.CollaborativeDocument.Common.Comments")}:
              </div>
              {commentsToDisplay.map((comment, ix) => (
                <div
                  className="italic text-gray-600 text-xs inline ml-1"
                  key={`cmt_${ix}`}
                >
                  {comment}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CollabDocFlaggedChange;
