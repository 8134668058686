
import { t } from "i18next";
import { AdvancedTaskStatus } from "../../../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { Badge } from "../../../common";

interface TaskStatusBadgeProps {
  status: AdvancedTaskStatus;
  isNewlyCreatedInThisJourney: boolean;
  isOverdue: boolean;
  textSize?: string;
}

function TaskStatusBadge({
  status,
  isNewlyCreatedInThisJourney,
  isOverdue,
  textSize = "text-xs",
}: TaskStatusBadgeProps) { 
  let badgeComponent = null;

  if (isNewlyCreatedInThisJourney) {
    badgeComponent = (
      <Badge
        text={t('Common.New')}
        backgroundColourClassName="bg-blue-200/75 rounded-full"
        textColourClassName="text-blue-600"
        borderRadiusClassName="rounded"
        marginClassName="mr-1"
        textSize={textSize}
      />
    );
  }
  else {
    switch (status) {
      case "ACTIVE":
        if (isOverdue) {
          badgeComponent = (
            <Badge
              text={t('Common.Overdue')}
              backgroundColourClassName="bg-orange-200/75 rounded-full"
              textColourClassName="text-orange-600"
              borderRadiusClassName="rounded"
              marginClassName="mr-1"
              textSize={textSize}
            />
          );
        } else {
          badgeComponent = (
            <Badge
              text={t('TaskType.Tabs.Active')}
              backgroundColourClassName="bg-yellow-200/75 rounded-full"
              textColourClassName="text-yellow-600"
              borderRadiusClassName="rounded"
              marginClassName="mr-1"
              textSize={textSize}
            />
          );
        }        
        break;
      case "CANCELLED":
        badgeComponent = (
          <Badge
            text={t('TaskType.Tabs.Cancelled')}
            backgroundColourClassName="bg-red-200/75 rounded-full"
            textColourClassName="text-red-600"
            borderRadiusClassName="rounded"
            marginClassName="mr-1"
            textSize={textSize}
          />
        );
        break;
      case "COMPLETED":
        badgeComponent = (
          <Badge
            text={t('TaskType.Tabs.Completed')}
            backgroundColourClassName="bg-green-200/75 rounded-full"
            textColourClassName="text-green-600"
            borderRadiusClassName="rounded"
            marginClassName="mr-1"
            textSize={textSize}
          />
        );
        break;
      default:
        badgeComponent = null;
        break;
    }
  }

  return (
    <>
      {badgeComponent}
    </>
  );
}

export default TaskStatusBadge;
