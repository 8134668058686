import { JourneyType } from "../../types/journeys/JourneyType";
import { Link, useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import SuccessIcon from "../common/SuccessIcon";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { BaseUserDetailsDto } from "../../types/dtos/generic";

interface JourneySubmittedProps {
  journeyType: JourneyType;
  subjectUserId: number;
  answerSetParticipants: BaseUserDetailsDto[];
  answerSetUniqueId: string;
  /** Whether to force the user to navigate to the collab doc on submit */
  forceRedirectToCollabDoc: boolean;
  /** Whether or not there's another journey for the user to complete */
  newJourneyAssigned: boolean;
  /** Whether or not the journey that has just been submitted was an exit questionnaire */
  exitQuestionnaireCompleted: boolean;
  dualPrepCollabDocGuidId: string | null;
  /** A callback which loads the next journey into the widget,
   * so on button click we can load the next journey, but only
   * when a new journey has been assigned */
  onLoadNextJourney(): void;
  /** Whether or not to show the request catch up button. This is needed in the scenario a user has already booked a meeting in the previous screen */
  showRequestCatchUpButton: boolean;
  onShowManageCatchUpMeetingScreenChange(value: boolean): void;
}

function JourneySubmitted({
  journeyType,
  subjectUserId,
  answerSetParticipants,
  answerSetUniqueId,
  forceRedirectToCollabDoc,
  newJourneyAssigned,
  exitQuestionnaireCompleted,
  dualPrepCollabDocGuidId = null,
  onLoadNextJourney,
  showRequestCatchUpButton,
  onShowManageCatchUpMeetingScreenChange,
}: JourneySubmittedProps) {
  const collabDocAnswerSetUniqueId =
    dualPrepCollabDocGuidId == null
      ? answerSetUniqueId
      : dualPrepCollabDocGuidId;

  const collabDocLink = AppRoutes.collaborativeDocument.generateRoute(
    collabDocAnswerSetUniqueId,
    forceRedirectToCollabDoc,
    null
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  const nonSubjectUser = answerSetParticipants.find(
    (x) => x.userId != subjectUserId
  );

  // Force redirect to the collab doc on load, if necessary
  useEffect(() => {
    if (forceRedirectToCollabDoc) {
      navigate(collabDocLink);
    }
  }, [forceRedirectToCollabDoc]);

  // Force sign out if they've just completed an exit questionnaire
  useEffect(() => {
    if (exitQuestionnaireCompleted) {
      navigate(AppRoutes.auth.logout);
    }
  }, [exitQuestionnaireCompleted]);

  return (
    <div className="text-center">
      <SuccessIcon />
      <h3 className="light-heading">{t("Journey.Submit.Heading")}</h3>
      <div className="text-white/70">
        {journeyType === "COLLAB-DOC" && (
          <div>
            {t(newJourneyAssigned
              ? "Journey.Submit.LinkWithCollabDoc"
              : "Journey.Submit.LinkWithCollabDocNoFurtherJourney", {
              managerName: nonSubjectUser?.fullName!,
            })}
            <div className="flex flex-row justify-center pt-4">
              <Link to={collabDocLink}>
                <button className="journey-btn-primary">
                  {t("Journey.Submit.ViewResponsesButton")}
                </button>
              </Link>
              {showRequestCatchUpButton && (
                <button
                  className="journey-btn-primary"
                  onClick={() => onShowManageCatchUpMeetingScreenChange(true)}
                >
                  {t("Journey.Submit.RequestCatchUp")}
                </button>
              )}
            </div>
          </div>
        )}
        {/* When 'DUAL-PREP' and the final doc hasn't been generated yet */}
        {journeyType === "DUAL-PREP" && !dualPrepCollabDocGuidId && (
          <div>
            {t("Journey.Submit.LinkToViewCompletedPrep").replace(
              "#OTHER_USER_NAME#",
              nonSubjectUser?.fullName!
            )}
            <div className="flex flex-row justify-center pt-4">
              <Link to={collabDocLink}>
                <button className="journey-btn-primary">
                  {t("Journey.Submit.ViewResponsesButton")}
                </button>
              </Link>
            </div>
          </div>
        )}
        {/* When 'DUAL-PREP' and both planning has been completed so the final doc has just generated */}
        {journeyType === "DUAL-PREP" && dualPrepCollabDocGuidId && (
          <div>
            {t("Journey.Submit.LinkToViewMeetingDoc").replace(
              "#OTHER_USER_NAME#",
              nonSubjectUser?.fullName!
            )}
            <div className="flex flex-row justify-center pt-4">
              <Link to={collabDocLink}>
                <button className="journey-btn-primary">
                  {t("Journey.Submit.OpenMeetingButton")}
                </button>
              </Link>
            </div>
          </div>
        )}
        {newJourneyAssigned && (
          <div className="mt-4 text-center">
            <button onClick={onLoadNextJourney} className="journey-btn-primary">
              {t("Journey.Submit.NextJourneyButton")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default JourneySubmitted;
