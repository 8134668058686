import EmptyFilteredState from "../../images/EmptyFilteredState";
import { AnalyticsWidgetUiDetailsDto } from "../../types/analytics";
import AnalyticsFilterInputs from "../../types/analytics/AnalyticsFilterInputs";
import { AnalyticsPageWidgetSummary } from "../../types/analytics/AnalyticsWidgetUiDetailsDto";
import { GroupedJourneys } from "../../types/analytics/FilterAvailableValues";
import AnalyticsWidget from "./AnalyticsWidget";

interface AnalyticsWidgetCollectionProps {
  widgets: AnalyticsWidgetUiDetailsDto[];
  filters: AnalyticsFilterInputs;
  journeys: GroupedJourneys[];
  dynamicPageId?: number | undefined;
  onRequestOtherWidgetRefresh?: (
    dynamicWidgetId: number,
    onSuccess: () => void,
    onError: () => void
  ) => void;
}

function AnalyticsWidgetCollection({
  widgets,
  filters,
  journeys,
  dynamicPageId,
  onRequestOtherWidgetRefresh,
}: AnalyticsWidgetCollectionProps) {
  if (!widgets || widgets.length === 0) {
    return (
      <div className="text-center mt-8 text-gray-600">
        <div className="mb-4 flex justify-center">
          <EmptyFilteredState />
        </div>
        There isn't any data for your selected filters.
        <br />
        Please adjust them and try again.
      </div>
    );
  }

  const pageWidgets: AnalyticsPageWidgetSummary[] = widgets.map((widget) => ({
    widgetId: widget.widgetId,
    dynamicWidgetId: widget.dynamicWidgetId,
    displayType: widget.displayType,
  }));

  return (
    <div>
      {widgets.map((widget) => (
        <AnalyticsWidget
          key={widget.widgetId}
          widgetDetails={widget}
          filters={filters}
          journeys={journeys}
          dynamicPageId={dynamicPageId}
          allWidgetsForPage={pageWidgets}
          onRequestOtherWidgetRefresh={onRequestOtherWidgetRefresh}
        />
      ))}
    </div>
  );
}

export default AnalyticsWidgetCollection;
