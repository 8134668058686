import { t } from "i18next";
import { ModalPopup } from "../common";
import SmallLoader from "../loaders/SmallLoader";
import { UserContextInterface } from "../../state/UserContext";

interface PreparingDocumentPopupProps {
  isOpen: boolean;
  userContext: UserContextInterface;
  onOpenChange(open: boolean): void;
}

export const PreparingDocumentPopup = ({
  isOpen,
  userContext,
  onOpenChange,
}: PreparingDocumentPopupProps) => {
  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={t("Pages.MyDashboard.PreparingYourDocModal.Title")}
      showCloseIcon={false}
      width="SMALL"
    >
      <div className="text-center pt-4">
        <SmallLoader colour={userContext.user.client.accentHexColour} />
        <p className="mt-4 mb-2">{t("Pages.MyDashboard.PreparingYourDocModal.Body")}</p>
      </div>
    </ModalPopup>
  )
};

export default PreparingDocumentPopup;
