import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophyAlt,
  faGraduationCap,
  faClipboardListCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { t } from "i18next";
import { TaskType } from "../../types/tasks";
import { taskTypeHelper } from "../../helpers";
import { Tooltip } from "../common";

interface TaskTypeProps {
  taskType: TaskType;
  colourClassName?: string | undefined;
  iconSize?: SizeProp | undefined;
}

function TaskTypeIcon({
  taskType,
  colourClassName = "text-gray-600",
  iconSize = "xs",
}: TaskTypeProps) {
  const taskTypeTranslationKey =
    taskTypeHelper.getTranslationKeyForTaskType(taskType);
  let icon: IconProp;
  switch (taskType) {
    case "ACTION":
      icon = faClipboardListCheck;
      break;
    case "LEARNING":
      icon = faGraduationCap;
      break;
    case "GOAL":
      icon = faTrophyAlt;
      break;
    default:
      icon = faClipboardListCheck;
      break;
  }

  const iconComponent = (
    <FontAwesomeIcon icon={icon} size={iconSize} className={colourClassName} />
  );

  return (
    <Tooltip
      content={`${t("Tasks.Common.TaskType")}: ${t(taskTypeTranslationKey)}`}
      triggerElement={
        <span>
          {iconComponent}
        </span> /* Span is necessary otherwise tooltip doesn't work */
      }
    />
  );
}

export default TaskTypeIcon;
