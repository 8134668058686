interface StatusDotProps {
  colour: "GREEN" | "AMBER" | "RED";
}

export const StatusDot = ({ colour }: StatusDotProps) => {
  let colourClassName = "";
  switch (colour) {
    case "AMBER":
      colourClassName = "bg-amber-400";
      break;
    case "GREEN":
      colourClassName = "bg-emerald-400";
      break;
    case "RED":
      colourClassName = "bg-red-400";
      break;
  }

  return (
    <span
      className={"inline-block h-[9px] w-[9px] rounded-full " + colourClassName}
    ></span>
  );
};

export default StatusDot;
