import { ClientTaskType } from "../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { TFunction } from "i18next";
import { ClientTaskTypeCategory } from "../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { CountRestrictionType } from "../types/dtos/tasks/advanced-tasks/CountRestrictionType";
import UserTaskListItemDto from "../types/dtos/tasks/advanced-tasks/UserTaskListItemDto";
import TaskRelatedTaskBarItemDto from "../types/dtos/dashboard-task-bar/TaskRelatedTaskBarItemDto";
import { NewUserTaskDto } from "../types/dtos/tasks/advanced-tasks/add-popup/NewUserTaskDto";
import AdvancedTaskDto from "../types/dtos/forms/AdvancedTaskDto";
import advancedTaskHelper from "./advancedTaskHelper";

export const taskRestrictionHelper = {
  getRestrictionTextForDashboardTaskBar(
    taskType: ClientTaskType,
    hasActiveTasks: boolean,
    t: TFunction
  ): string {
    // If there are active tasks and the task type has min/max restrictions on the category level, show the tasks need to be updated. We don't have space on the widget to go into detail so its a generic message.
    const taskTypeHasCatergoryRestrictions =
      taskType?.categories &&
      taskType?.categories.length > 0 &&
      taskType?.categories.some((x) => x.minTaskCount || x.maxTaskCount);
    if (hasActiveTasks && taskTypeHasCatergoryRestrictions) {
      return t("DashboardTaskBar.Banner.UpdateYourX").replace(
        "#TASK_TYPE#",
        advancedTaskHelper.ToLowerCase(
          taskType.pluralNameTranslationKeyIdentifier
        )
      );
    } else {
      return taskRestrictionHelper.getOverallTaskRestrictionText(
        taskType.singularNameTranslationKeyIdentifier,
        taskType.pluralNameTranslationKeyIdentifier,
        taskType.countRestriction,
        taskType.minTaskCount,
        taskType.maxTaskCount,
        null,
        t
      );
    }
  },
  doesUserHaveTasksThatSatisfyRestrictions(
    /** Usually active tasks, but can be tasks added in this AnswerSet if that's what we're validating against */
    tasks:
      | UserTaskListItemDto[]
      | TaskRelatedTaskBarItemDto[]
      | AdvancedTaskDto[]
      | null,
    overallMinTaskCount: number | null,
    overallMaxTaskCount: number | null,
    overallCountRestriction: CountRestrictionType,
    categories: ClientTaskTypeCategory[] | null
  ): boolean {
    // If there are no tasks, the user can't have any that satisfy the restrictions
    if (!tasks) {
      return false;
    }

    // Go through various scenarios of overall + categories and if any of them are true, the user doesn't satisfy the restrictions
    if (
      overallCountRestriction === "MIN-ONLY" &&
      overallMinTaskCount &&
      tasks.length < overallMinTaskCount
    )
      return false;

    if (
      overallCountRestriction === "MAX-ONLY" &&
      overallMaxTaskCount &&
      tasks.length > overallMaxTaskCount
    )
      return false;

    if (
      overallCountRestriction === "MIN-AND-MAX" &&
      overallMinTaskCount &&
      overallMaxTaskCount &&
      (tasks.length < overallMinTaskCount || tasks.length > overallMaxTaskCount)
    )
      return false;

    // Perform category level validation
    if (
      (categories &&
        categories.length > 0 &&
        categories.some(
          (x) =>
            x.countRestriction === "MIN-ONLY" &&
            x.minTaskCount &&
            //@ts-ignore
            tasks.filter((y) => y.categoryId === x.categoryId).length <
              x.minTaskCount
        )) ||
      (categories &&
        categories.length > 0 &&
        categories.some(
          (x) =>
            x.countRestriction === "MAX-ONLY" &&
            x.maxTaskCount &&
            //@ts-ignore
            tasks.filter((y) => y.categoryId === x.categoryId).length >
              x.maxTaskCount
        )) ||
      (categories &&
        categories.length > 0 &&
        categories.some(
          (x) =>
            x.countRestriction === "MIN-AND-MAX" &&
            x.minTaskCount &&
            x.maxTaskCount &&
            //@ts-ignore
            (tasks.filter((y) => y.categoryId === x.categoryId).length <
              x.minTaskCount ||
              //@ts-ignore
              tasks.filter((y) => y.categoryId === x.categoryId).length >
                x.maxTaskCount)
        ))
    ) {
      return false;
    }

    return true;
  },
  canUserCreateNewTask(
    potentialNewTask: NewUserTaskDto,
    activeTasks:
      | UserTaskListItemDto[]
      | TaskRelatedTaskBarItemDto[]
      | AdvancedTaskDto[]
      | null,
    overallMaxTaskCount: number | null,
    categories: ClientTaskTypeCategory[] | null
  ): boolean {
    // If there is a max task limit and the active tasks are at the limit, the user can't create a new task
    if (
      overallMaxTaskCount &&
      activeTasks &&
      activeTasks.length >= overallMaxTaskCount
    )
      return false;

    // If the potential new task has a category then we ned to check the category restrictions and
    // make sure the user isn't at the limit for that category
    if (potentialNewTask.categoryId) {
      const category = categories?.find(
        (x) => x.categoryId === potentialNewTask.categoryId
      );

      if (
        category?.maxTaskCount &&
        activeTasks &&
        //@ts-ignore
        activeTasks.filter((x) => x.categoryId === potentialNewTask.categoryId)
          .length >= category.maxTaskCount
      ) {
        return false;
      }
    }

    return true;
  },
  getRestrictionBannerTextHtmlForManagementPages(
    taskType: ClientTaskType,
    categories: ClientTaskTypeCategory[] | null,
    countRestriction: CountRestrictionType,
    minTaskCount: number | null,
    maxTaskCount: number | null,
    personToken: string | null,
    t: TFunction
  ): string | null {
    let html = "";
    // First get the overall task restriction text (based off over min/max)
    const taskTypeRestrictionText =
      taskRestrictionHelper.getOverallTaskRestrictionText(
        taskType.singularNameTranslationKeyIdentifier,
        taskType.pluralNameTranslationKeyIdentifier,
        countRestriction,
        minTaskCount,
        maxTaskCount,
        personToken,
        t
      );

    // Then we need to work out if there are any category restrictions
    const categoriesWithRestrictions = categories?.filter(
      (x) => x.minTaskCount || x.maxTaskCount
    );
    if (
      categoriesWithRestrictions != null &&
      categoriesWithRestrictions.length > 0
    ) {
      const followTheBelowText = t(
        "TaskType.Restrictions.TheseNeedToFollowBelow"
      ).replace("#TASK_TYPE#", t(taskType.pluralNameTranslationKeyIdentifier));
      html =
        "<p>" +
        (taskTypeRestrictionText.length > 0
          ? taskTypeRestrictionText + ". "
          : "") +
        followTheBelowText +
        ":<ul>";

      // If there are category restrictions, we need to loop through them and get the restriction text for each one
      categoriesWithRestrictions.forEach((category) => {
        const categoryRestrictionText =
          taskRestrictionHelper.getCategoryRestrictionText(
            taskType.singularNameTranslationKeyIdentifier,
            taskType.pluralNameTranslationKeyIdentifier,
            category,
            t
          );
        html += "<li>" + categoryRestrictionText + "</li>";
      });

      html += "</ul></p>";
    } else {
      if (taskTypeRestrictionText.length > 0) {
        html = "<p>" + taskTypeRestrictionText + "</p>";
      } else {
        return null;
      }
    }

    return html;
  },
  getOverallTaskRestrictionText(
    singularNameIdentifier: string,
    pluralNameIdentifier: string,
    countRestriction: CountRestrictionType,
    minTaskCount: number | null,
    maxTaskCount: number | null,
    personToken: string | null,
    t: TFunction
  ): string {
    switch (countRestriction) {
      case "MIN-AND-MAX":
        return t("TaskType.Restrictions.MinAndMaxSet")
          .replace("#PERSON#", personToken != null ? personToken : "You")
          .replace("#MIN#", minTaskCount!.toString())
          .replace("#MAX#", maxTaskCount!.toString())
          .replace(
            "#TASK_TYPE#",
            advancedTaskHelper.ToLowerCase(pluralNameIdentifier)
          );
      case "MIN-ONLY":
        const usePluralTranslation = minTaskCount! > 1;
        return t("TaskType.Restrictions.OnlyOverallMinSet")
          .replace("#PERSON#", personToken != null ? personToken : "You")
          .replace("#MIN#", minTaskCount!.toString())
          .replace(
            "#TASK_TYPE#",
            usePluralTranslation
              ? t(pluralNameIdentifier)
              : advancedTaskHelper.ToLowerCase(singularNameIdentifier)
          );
      case "MAX-ONLY":
        return t("TaskType.Restrictions.OnlyOverallMaxSet")
          .replace("#PERSON#", personToken != null ? personToken : "You")
          .replace("#MAX#", maxTaskCount!.toString())
          .replace(
            "#TASK_TYPE#",
            advancedTaskHelper.ToLowerCase(pluralNameIdentifier)
          );
      default:
        return "";
    }
  },
  getCategoryRestrictionText(
    singularTaskTypeNameIdentifier: string,
    pluralTaskTypeNameIdentifier: string,
    category: ClientTaskTypeCategory,
    t: TFunction
  ): string {
    switch (category.countRestriction) {
      case "MIN-AND-MAX":
        return t("TaskType.Restrictions.BothCategoryMinAndMaxSet")
          .replace("#MIN#", category.minTaskCount!.toString())
          .replace("#MAX#", category.maxTaskCount!.toString())
          .replace(
            "#TASK_TYPE#",
            advancedTaskHelper.ToLowerCase(pluralTaskTypeNameIdentifier)
          )
          .replace("#CATEGORY_NAME#", t(category.nameTranslationKeyIdentifier));
      case "MIN-ONLY":
        const minOnlyUsePluralTranslation = category.minTaskCount! > 1;
        return t("TaskType.Restrictions.OnlyCategoryMinSet")
          .replace("#MIN#", category.minTaskCount!.toString())
          .replace(
            "#TASK_TYPE#",
            minOnlyUsePluralTranslation
              ? t(pluralTaskTypeNameIdentifier)
              : advancedTaskHelper.ToLowerCase(singularTaskTypeNameIdentifier)
          )
          .replace("#CATEGORY_NAME#", t(category.nameTranslationKeyIdentifier));
      case "MAX-ONLY":
        const maxOnlyUsePluralTranslation = category.maxTaskCount! > 1;
        return t("TaskType.Restrictions.OnlyCategoryMaxSet")
          .replace("#MAX#", category.maxTaskCount!.toString())
          .replace(
            "#TASK_TYPE#",
            maxOnlyUsePluralTranslation
              ? t(pluralTaskTypeNameIdentifier)
              : advancedTaskHelper.ToLowerCase(singularTaskTypeNameIdentifier)
          )
          .replace("#CATEGORY_NAME#", t(category.nameTranslationKeyIdentifier));
      default:
        return "";
    }
  },
};

export default taskRestrictionHelper;
