import { QuestionAnswerType, QuestionType } from "../types/forms";

const _advancedQuestionTypes: QuestionType[] = [
  "BEHAVIOUR",
  "GOAL-SETTING",
  "GOAL-REVIEW-STATUS-COMMENT",
  "LEARNING-AND-DEVELOPMENT",
];

export const questionTypeHelper = {
  /** Returns true if the question type is "advanced", i.e. not simple, like a goal review question */
  isAdvancedQuestionType: function (questionType: QuestionType): boolean {
    return _advancedQuestionTypes.indexOf(questionType) >= 0;
  },
  getAnswerTypeForQuestionType: function (
    questionType: QuestionType
  ): QuestionAnswerType | undefined {
    switch (questionType) {
      case "BEHAVIOUR":
        return "BEHAVIOUR";
      case "CHECKLIST":
      case "DROPDOWNLIST":
      case "RADIOLIST":
      case "SLIDER":
        return "MULTICHOICE";
      case "LEARNING-AND-DEVELOPMENT":
        return "DEVELOPMENT-LIST";
      case "LONGTEXT":
      case "SHORTTEXT":
        return "TEXT";
      case "GOAL-REVIEW-STATUS-COMMENT":
        return "GOAL-REVIEW";
      default:
      case "ADDTASK":
      case "READONLY":
        return undefined;
    }
  },
};

export default questionTypeHelper;
