import { t } from "i18next";
import { Markup } from "interweave";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import { ReactComponent as EmptySummaryState } from "../../images/emptySummaryState.svg";
import DashboardSummaryTabDualPrepAnswerSetDto from "../../types/dtos/dashboards/DashboardSummaryTabDualPrepAnswerSetDto";

interface FormSummaryEmptyDualPrepStateProps {
  formTitle: string;
  /** For the first time for a user/form combination there's an active collab doc, show a different empty state */
  incompleteAnswerSetUniqueId: string | null | undefined;
  dualPrepAnswerSet: DashboardSummaryTabDualPrepAnswerSetDto | null;
  isManagerDashboard: boolean;
  activeDocumentStatusBadge: JSX.Element;
  showStatusBadge: boolean;
  isManagerViewingButHasBeenDelegated?: boolean;
  delegatedManagerName?: string | null;
  onButtonClick(): void;
}

const FormSummaryEmptyDualPrepState = ({
  formTitle,
  incompleteAnswerSetUniqueId,
  dualPrepAnswerSet,
  isManagerDashboard,
  activeDocumentStatusBadge,
  showStatusBadge,
  isManagerViewingButHasBeenDelegated = false,
  delegatedManagerName,
  onButtonClick,
}: FormSummaryEmptyDualPrepStateProps) => {
  const navigate = useNavigate();
  const isDualPrepEmpPlanningCompleted =
    dualPrepAnswerSet?.employeePrepCompleted;
  const isDualPrepManPlanningCompleted =
    dualPrepAnswerSet?.managerPrepCompleted;
  let showViewCompletedPlanningNotesButton = false;

  const goToCollabDoc = () => {
    // Go to manager doc if not completed, else go to the final doc
    let answerSetUniqueId = !isDualPrepManPlanningCompleted
      ? incompleteAnswerSetUniqueId
      : dualPrepAnswerSet?.collabDocAnswerSetId;

    const collabDocUrl = AppRoutes.collaborativeDocument.generateRoute(
      answerSetUniqueId!,
      false,
      null
    );
    navigate(collabDocUrl);
  };

  const viewCompletedPlanningResponse = () => {
    let answerSetUniqueId = "";

    // If employee has completed, but waiting for manager to complete... hide button for employee
    if (
      !isManagerDashboard &&
      dualPrepAnswerSet?.employeePrepAnswerSetUniqueId != null &&
      isDualPrepEmpPlanningCompleted &&
      !isDualPrepManPlanningCompleted
    ) {
      answerSetUniqueId = dualPrepAnswerSet?.employeePrepAnswerSetUniqueId;
    }
    // If employee hasn't completed yet, but the manager has... hide button for manager
    else if (
      isManagerDashboard &&
      dualPrepAnswerSet?.managerPrepAnswerSetUniqueId != null &&
      !isDualPrepEmpPlanningCompleted &&
      isDualPrepManPlanningCompleted
    ) {
      answerSetUniqueId = dualPrepAnswerSet?.managerPrepAnswerSetUniqueId;
    }

    const collabDocUrl = AppRoutes.collaborativeDocument.generateRoute(
      answerSetUniqueId!,
      false,
      null
    );
    navigate(collabDocUrl);
  };

  let displayText: string;
  if (isManagerDashboard) {
    if (isManagerViewingButHasBeenDelegated && delegatedManagerName != null) {
      displayText = t("Delegation.FormSummaryEmptyState.ThisHasBeenDelegated");
      displayText = displayText.replace(
        "#DELEGATED_MANAGER_NAME#",
        delegatedManagerName
      );
    } else if (dualPrepAnswerSet?.id) {
      displayText =
        "It looks like there aren't any answers to show here.<br />Complete the open Journey to update this summary.";
    } else {
      displayText =
        "It looks like there aren't any answers to show here.<br />Complete a Journey to update this summary.";
    }
  } else {
    if (dualPrepAnswerSet?.id) {
      displayText =
        "There isn't anything to show here right now.<br />Your open Journey needs to be completed before you can view this summary.";
    } else {
      displayText =
        "It looks like you haven't got any answers to show here.<br />Complete a Journey to update this summary.";
    }
  }

  let hideButton = false;
  if (isManagerViewingButHasBeenDelegated && dualPrepAnswerSet == null) {
    hideButton = true;
  }
  // If employee has completed, but waiting for manager to complete... hide button for employee
  else if (
    !isManagerDashboard &&
    isDualPrepEmpPlanningCompleted &&
    !isDualPrepManPlanningCompleted
  ) {
    hideButton = true;
    showViewCompletedPlanningNotesButton = true;
    displayText =
      "There isn't anything to show here right now.<br /> Waiting for both planning steps to be completed.";
  }
  // If employee hasn't completed yet, but the manager has... hide button for manager
  else if (
    isManagerDashboard &&
    !isDualPrepEmpPlanningCompleted &&
    isDualPrepManPlanningCompleted
  ) {
    hideButton = true;
    showViewCompletedPlanningNotesButton = true;
    displayText =
      "It looks like there aren't any answers to show here.<br /> Waiting for both planning steps to be completed.";
  }

  const buttonFunction =
    incompleteAnswerSetUniqueId || dualPrepAnswerSet?.collabDocAnswerSetId
      ? goToCollabDoc
      : onButtonClick;

  const updateButtonText = !isManagerViewingButHasBeenDelegated
    ? `${t("Common.Update")} '${t(formTitle)}'`
    : `${t("Common.View")} '${t(formTitle)}'`;

  return (
    <div className="text-center w-full border rounded p-6">
      <span className="text-center text-gray-400 border-gray-200 relative">
        {showStatusBadge && (
          <div className="text-center md:text-right pb-2 mb-2 border-b-2 md:border-0 absolute right-0">
            {activeDocumentStatusBadge}
          </div>
        )}
        <EmptySummaryState className="m-auto" width={80} />
        <div className="mt-2 mb-4 text-sm">
          <Markup content={displayText} />
        </div>
      </span>
      {!hideButton && (
        <div className="mt-2 text-center">
          <button
            className="hover:[background-color:var(--color-primary)] hover:text-white bg-gray-200 text-gray-500 text-center border-0 drop-shadow-sm py-1 px-6 m-0 rounded-md font-medium text-sm"
            onClick={buttonFunction}
            role="link"
          >
            {updateButtonText}
          </button>
        </div>
      )}
      {showViewCompletedPlanningNotesButton && (
        <div className="mt-2 text-center text-gray-400 cursor-pointer">
          <span
            className="border-b-2 border-b-gray-200 hover:border-b-gray-400 p-1"
            onClick={viewCompletedPlanningResponse}
          >
            {t("Pages.CollaborativeDocument.DualPrep.ViewPlanningNotes")}
          </span>
        </div>
      )}
    </div>
  );
};

export default FormSummaryEmptyDualPrepState;
