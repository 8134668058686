import { NineBoxGridEditorAxisSettings } from "../types/analytics/charts/NineBoxGridEditorData";
import mathsHelper, { RoundingMethod } from "./mathsHelper";

export const nineBoxGridHelper = {
  getScaleValueForXAxis: (
    percentage: number,
    axisSettings: NineBoxGridEditorAxisSettings
  ) => {
    if (!axisSettings.xAxis.hasValidScaleConversionProperties)
      return percentage;

    const rangeMin = axisSettings.xAxis.scaleConversionMin!;
    const rangeMax = axisSettings.xAxis.scaleConversionMax!;

    let roundingMethod: RoundingMethod = "MID-POINT-UP";
    if (
      axisSettings.xAxis.scaleConversionRoundingConfig &&
      axisSettings.xAxis.scaleConversionRoundingConfig.toUpperCase().trim() ===
        "FLOOR"
    ) {
      roundingMethod = "FLOOR";
    }

    return mathsHelper.convertPercentageToScale(
      percentage,
      rangeMin,
      rangeMax,
      roundingMethod
    );
  },

  getDisplayValueForYAxisPercentageScore: (
    percentage: number,
    axisSettings: NineBoxGridEditorAxisSettings
  ) => {
    if (
      axisSettings.yAxis.valueSettings.options &&
      axisSettings.yAxis.valueSettings.options.length > 0
    ) {
      const matchingOption = axisSettings.yAxis.valueSettings.options.find(
        (option) =>
          option.rangeLowerBound <= percentage &&
          option.rangeUpperBound >= percentage
      );

      if (matchingOption) {
        return matchingOption.displayText;
      }
    }

    return percentage + "%";
  },
};

export default nineBoxGridHelper;
