import PeoplePickerSearchCriteria from "../../types/dtos/generic/PeoplePickerSearchCriteria";
import PeoplePickerUserDto from "../../types/dtos/generic/PeoplePickerUserDto";
import PeoplePickerUsersApiResponseDataDto from "../../types/dtos/generic/PeoplePickerUsersApiResponseDataDto";
import SignedInUserDto, {
  SignedInUserUpdatedDetailsDto,
} from "../../types/dtos/users/SignedInUserDto";
import { apiClient } from "../apiClient";

class userApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the logged in user's details, which are stored in context and used throughout this app.
   * So this should only be used once, then the value referenced from the context
   */
  getLoggedInUserDetails(
    onSuccess: (user: SignedInUserDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/user/get-logged-in-user-details"
    ).then(
      (data) => {
        const signedInUser = data as SignedInUserDto;
        onSuccess(signedInUser);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the logged in user's updated details */
  getRefreshedLoggedInUserDetails(
    onSuccess: (updates: SignedInUserUpdatedDetailsDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/user/get-updated-user-details"
    ).then(
      (data) => {
        const updates = data as SignedInUserUpdatedDetailsDto;
        onSuccess(updates);
      },
      (error) => {
        onError(error);
      }
    );
  }

  getUsersForPeoplePicker(
    searchCriteria: PeoplePickerSearchCriteria,
    onSuccess: (data: PeoplePickerUsersApiResponseDataDto) => void,
    onError: (error: any) => void,
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/user/get-users-for-people-picker",
      {
        body: JSON.stringify(searchCriteria),
      }
    ).then(
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
      }
    );
  }

  SetWalkThroughSeen(
    walkthrough: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/user/set-walkthrough-seen?walkthroughType=${walkthrough}`
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default userApi;
