import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import AppRoutes from "../../routes/AppRoutes";
import { Avatar, Badge, FormattedDate, ModalPopup } from "../common";
import { DashboardSummaryTabHistoryDto } from "../../types/dtos/dashboards/DashboardSummaryTabHistoryDto";
import { convertCompilerOptionsFromJson } from "typescript";
import smartTruncate from "smart-truncate";

interface FormHistoryPopupProps {
  isOpen: boolean;
  historyItems: DashboardSummaryTabHistoryDto[] | null | undefined;
  isManagerDashboard?: boolean | null | undefined;
  onOpenChange(open: boolean): void;
  onButtonClick(): void;
}

const FormHistoryPopup = ({
  isOpen,
  historyItems,
  isManagerDashboard,
  onOpenChange,
  onButtonClick,
}: FormHistoryPopupProps) => (
  <ModalPopup
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    onPrimaryButtonClick={onButtonClick}
    primaryButtonText="Close"
    title="History"
  >
    <table className="table-auto w-full">
      <thead>
        <tr>
          <th className="text-left hidden md:block">Participants</th>
          {isManagerDashboard && <th className="text-left">Title</th>}
          {!isManagerDashboard && (
            <th className="text-left hidden md:table-cell">Date Updated</th>
          )}
          <th className="text-center px-2 hidden md:block">Stage</th>
          <th className="text-center">Status</th>
          <th className="hidden md:table-cell w-24"></th>
        </tr>
      </thead>
      <tbody>
        {historyItems?.map((td) => {
          const collabDocLink = AppRoutes.collaborativeDocument.generateRoute(
            td.answerSetUniqueId,
            false,
            null
          );
          return (
            <tr key={td.answerSetUniqueId}>
              <td className="text-left hidden md:block md:py-3">
                {td.participants &&
                  td.participants.map((participant) => (
                    <Avatar
                      key={`p${participant.userId}`}
                      userFullName={participant.fullName}
                      userInitials={participant.initials}
                      size={10}
                      imageUrl={
                        participant.profileImageUrl
                          ? participant.profileImageUrl
                          : undefined
                      }
                    />
                  ))}
              </td>
              {isManagerDashboard && (
                <td>
                  <Link to={collabDocLink}>
                    <div className="text-blue-800 underline md:text-gray-500 md:no-underline md:font-semibold">
                      {smartTruncate(td.answerSetTitle, 20)}
                    </div>
                    <div className="text-xs">
                      <FormattedDate
                        date={td.dateUpdated}
                        displayMode="FROM-NOW"
                        convertFromUtc
                      />
                    </div>
                  </Link>
                </td>
              )}
              {!isManagerDashboard && (
                <td className="text-left hidden md:table-cell">
                  <Link to={collabDocLink}>
                    <FormattedDate
                      date={td.dateUpdated}
                      displayMode="FROM-NOW"
                      convertFromUtc
                    />
                  </Link>
                </td>
              )}
              <td className="text-center hidden md:table-cell">
                <Link to={collabDocLink}>
                  <Badge
                    backgroundColourClassName="bg-gray-200/60"
                    textColourClassName="text-gray-400"
                    text={
                      td.dualPrepAnswerSetId !== null &&
                      !td.isDualPrepFinalStage
                        ? "Planning Prep"
                        : "Collab Doc"
                    }
                  />
                </Link>
              </td>
              <td className="text-center align-top md:align-middle">
                <Link to={collabDocLink}>
                  {td.approvalStatus === "FULLY-APPROVED" ||
                  td.approvalStatus === "NO-APPROVAL-NECESSARY" ||
                  td.approvalStatus === "DUAL-PREP-SUBMITTED"
                    ? "Complete"
                    : "Open"}
                </Link>
              </td>
              <td className="text-right hidden md:table-cell">
                <Link to={collabDocLink}>
                  <button className="hover:text-gray-400">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </ModalPopup>
);

export default FormHistoryPopup;
