import { useEffect, useState } from "react";
import { AnalyticsWidgetUiDetailsDto } from "../../../../types/analytics";
import {
  NineBoxGridData,
  NineBoxGridDataTransformer,
} from "../../../../types/analytics/charts/NineBoxGrids";
import { chartConstants } from "../ChartConstants";
import NineBoxGridRow from "./NineBoxGridRow";

export interface NineBoxGridProps {
  widget: AnalyticsWidgetUiDetailsDto;
  onDrilldown: (boxNumber: number) => void;
}

function NineBoxGrid({ widget, onDrilldown }: NineBoxGridProps) {
  const [transformedData, setTransformedData] =
    useState<NineBoxGridData | null>(null);

  useEffect(() => {
    if (!widget) return;
    // Transform the raw data into a format that the 9BG UI can use
    const data = new NineBoxGridDataTransformer().transformData(widget);
    setTransformedData(data);
  }, [widget]);

  if (!transformedData) return null;

  // Get the axis titles from the widget configs, or if none are provided,
  // use the default Performance / Potential axis titles
  const xAxisTitle =
    widget.configs?.find((x) => x.key === "CUSTOM-X-AXIS-TITLE")?.value ||
    "Performance";
  const yAxisTitle =
    widget.configs?.find((x) => x.key === "CUSTOM-Y-AXIS-TITLE")?.value ||
    "Potential";

  const totalBoxCount = transformedData.rows.reduce(
    (acc, row) => (acc += row.boxes.length),
    0
  );

  return (
    <div className="lg:px-16 xl:px-24 2xl:px-32 pt-2">
      <div className="flex flex-row">
        <div className="flex-0 [writing-mode:vertical-lr]">
          <div className="text-center rotate-180 pl-2">{yAxisTitle}</div>
        </div>
        <div className="flex-1">
          <div className="border-l border-b axis-box">
            <div
              className="flex justify-center"
              style={{ height: chartConstants.height }}
            >
              <div className="flex flex-1 flex-col-reverse gap-3 m-8">
                {transformedData.rows.map((row) => {
                  return (
                    <NineBoxGridRow
                      row={row}
                      key={row.rowNumber}
                      gridBoxesCount={totalBoxCount}
                      onDrilldown={onDrilldown}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center pt-2">{xAxisTitle}</div>
    </div>
  );
}

export default NineBoxGrid;
