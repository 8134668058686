import { useState } from "react";
import { useEffectOnce } from "react-use";
import { t } from "i18next";
import parse from "html-react-parser";
import {
  SimpleFormAnswer,
  SimpleFormQuestion,
} from "../../../types/dtos/simple-forms";
import { Badge, Label } from "../../common";
import { SimpleFormRequiredBadgeMode } from "../../../types/simple-forms";
import { simpleFormHelper } from "../../../helpers";
import SignedInUserDto from "../../../types/dtos/users/SignedInUserDto";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";
import SimpleFormQuestionMoreInfo from "./SimpleFormQuestionMoreInfo";
import { MultipleChoiceOptionStringId } from "../../../types/forms/MultipleChoiceOptions";

interface ReadonlySimpleFormQuestionProps {
  /** Determines whether to use second-person text or third-person */
  loggedInUser: SignedInUserDto;
  subjectUser: BaseUserDetailsDto;
  question: SimpleFormQuestion;
  answer: SimpleFormAnswer | null;
  requiredBadgeMode: SimpleFormRequiredBadgeMode;
}

export const ReadonlySimpleFormQuestion = ({
  loggedInUser,
  subjectUser,
  question,
  answer,
  requiredBadgeMode,
}: ReadonlySimpleFormQuestionProps) => {
  //-- State
  const [localAnswer, setLocalAnswer] = useState<SimpleFormAnswer | null>(null);
  const [showMoreInfoPanel, setShowMoreInfoPanel] = useState<boolean>(false);

  //-- Setup
  useEffectOnce(() => {
    const answerObj: SimpleFormAnswer = answer || {
      questionId: question.questionId,
      textAnswer: null,
      multiChoiceAnswers: null,
    };
    setLocalAnswer(answerObj);
  });

  // Loop over the question options and using the given answers find the selected options
  let multiChoiceOptions: MultipleChoiceOptionStringId[] = [];
  question.options.forEach((option) => {
    const selectedOption = localAnswer?.multiChoiceAnswers?.find(
      (a) => a.selectedOptionId === option.optionId
    );

    if (selectedOption != null) {
      multiChoiceOptions.push({
        optionId: option.optionId,
        allowCustomText: option.showCustomValueTextbox,
        isSelected: true,
        text: !option.translateDisplayValue
          ? option.rawDisplayValue!
          : t(option.displayValueTranslationKeyIdentifier!),
        value: option.underlyingStringValue || option.underlyingNumericValue!,
        customText: option.showCustomValueTextbox ? undefined : undefined,
        translateDisplayValue: option.translateDisplayValue,
        rawDisplayValue: option.rawDisplayValue,
        displayValueTranslationKeyIdentifier:
          option.displayValueTranslationKeyIdentifier,
        categoryId: option.categoryId,
      });
    }
  });

  // Use the third person text if the user is not the subject and the question has different text for third person
  const questionLabelText = simpleFormHelper.getQuestionText(
    question,
    subjectUser,
    loggedInUser
  );

  //-- Events
  const toggleShowMoreInfoPanel = () => {
    setShowMoreInfoPanel(!showMoreInfoPanel);
  };

  let answerText: string | null = null;
  switch (question.questionType) {
    case "SHORT-TEXT":
      answerText = localAnswer?.textAnswer?.answerText
        ? localAnswer?.textAnswer?.answerText
        : "No answer provided";
      break;
    case "LONG-TEXT":
      answerText = localAnswer?.textAnswer?.answerText
        ? localAnswer?.textAnswer?.answerText
        : "No answer provided";
      break;
    case "DROPDOWN":
    case "CHECKBOX":
    case "SLIDER":
    case "RADIO":
      const checkboxAnswers: string[] = [];
      multiChoiceOptions.forEach((answer) => {
        let optionText = "";

        // Append the option text with the category name
        if (answer.categoryId) {
          const category = question.categories.find(
            (c) => c.id === answer.categoryId
          );
          if (category) {
            optionText += t(category.titleTranslationKeyIdentifier) + " > ";
          }
        }

        // Add the option text
        optionText += !answer.translateDisplayValue
          ? answer.rawDisplayValue!
          : t(answer.displayValueTranslationKeyIdentifier!);

        checkboxAnswers.push(optionText);
      });
      answerText = checkboxAnswers.concat().join(", ");
      break;
    default:
      // Unknown question type
      break;
  }

  return (
    <div className="esf-question-container">
      <div className="esf-question-label">
        <Label
          text={questionLabelText}
          className="text-base text-gray-700 font-semibold"
        />
        {question.isRequired && requiredBadgeMode === "SHOW-REQUIRED" && (
          <Badge
            text="Required"
            textColourClassName="text-gray-600"
            marginClassName="ml-2"
          />
        )}
        {!question.isRequired && requiredBadgeMode === "SHOW-OPTIONAL" && (
          <Badge
            text="Optional"
            textColourClassName="text-gray-600"
            marginClassName="ml-2"
          />
        )}
        <SimpleFormQuestionMoreInfo
          isReadOnly={true}
          hasPopupType={
            question.popupType == "HTML-CONTENT" ||
            question.popupType == "LINK-TO-NEW-TAB"
          }
          popupType={question.popupType}
          popupTriggerText={question.popupTriggerText}
          popupUrlDestination={question.popupTriggerValue}
          showMoreInfoPanel={showMoreInfoPanel}
          toggleShowMoreInfoPanel={toggleShowMoreInfoPanel}
        />
      </div>
      {showMoreInfoPanel && question.popupTriggerValue && (
        <div className="esf-question-more-info-panel-readonly">
          {parse(t(question.popupTriggerValue))}
        </div>
      )}
      <div className="esf-question-input text-gray-600 block w-full border-0 text-sm rounded-md mb-2 whitespace-pre-line">
        {answerText}
      </div>
    </div>
  );
};
