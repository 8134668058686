export const stringHelper = {
  /** Returns true if a string contains another string you're searching for
   * @param source the string to search within
   * @param find the string you're looking to match
   */
  matchesSearch(source: string, find: string): boolean {
    if (!source || !find) return false;
    const searchIn = source.trim().toLowerCase();
    const searchFor = find.trim().toLowerCase();

    if (!searchIn || !searchFor) return false;

    return searchIn.indexOf(searchFor) >= 0;
  },
};

export default stringHelper;
