import { t } from "i18next";
import { FormSummaryFormDataDto } from "../../types/dtos/form-summaries/FormSummaryFormDataDto";

interface CollaborativFormSummaryContentProps {
  formData: FormSummaryFormDataDto;
}

const CollaborativeFormSummaryContent = ({
  formData,
}: CollaborativFormSummaryContentProps) => {
  if (formData?.questions === null || formData.questions.length === 0) {
    return null;
  }

  return (
    <div className="bg-gray-100 rounded-md p-4">
      {!formData.questions && (
        <div>
          <span className="text-center text-gray-400">
            There is no current information to display. If you would like you
            update this section click the button below
          </span>
        </div>
      )}
      {formData.questions.map((fdq, index) => (
        <div className="flex flex-col mt-4 first:mt-0" key={index}>
          <div className="flex flex-col">
            <span className="uppercase text-xs text-gray-500 pb-1">
              {t(fdq.questionText)}
            </span>
            {fdq.multiAnswerValues &&
              Array.isArray(fdq.multiAnswerValues) &&
              fdq.multiAnswerValues.filter((x) => x.answerValue !== null)
                .length > 0 && (
                <div className="flex flex-row gap-2 mt-2">
                  {fdq.multiAnswerValues
                    .filter((x) => x.answerValue !== null)
                    .map((mav, index) => (
                      <div
                        className="border rounded-md py-1 px-6 bg-white text-sm"
                        key={index}
                      >
                        {mav.translate && mav.answerValue
                          ? t(mav.answerValue)
                          : mav.answerValue}
                      </div>
                    ))}
                </div>
              )}            
            {fdq.answerValue && (
              <span className="font-medium text-gray-700 pl-2 text-sm">
                {fdq.translate ? t(fdq.answerValue) : fdq.answerValue}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CollaborativeFormSummaryContent;
