import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane as PaperPlaneReg } from "@fortawesome/pro-regular-svg-icons";
import {
  faFaceSmile,
  faPaperPlane as PaperPlaneSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { Popover, TextArea } from "../../../common";

interface AddUserTaskCommentFormProps {
  unsavedNewCommentTextValue: string;
  setUnsavedNewCommentTextValue: (value: string) => void;
  clientColor: string;
  onSubmit(
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
  setFormIsDirty(): void;
}

export const newCommentFieldElementId = "new-comment-input";

function AddUserTaskCommentForm({
  unsavedNewCommentTextValue,
  setUnsavedNewCommentTextValue,
  clientColor,
  onSubmit,
  inputRef = undefined,
  setFormIsDirty,
}: AddUserTaskCommentFormProps) {
  const { t } = useTranslation();

  // State
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [erroredWhenSaving, setErroredWhenSaving] = useState<boolean>(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [isOverSendIcon, setIsOverSendIcon] = useState(false);

  // Events
  const handleSubmitClick = () => {
    const trimmedComment = unsavedNewCommentTextValue?.trim();
    if (trimmedComment && trimmedComment.length > 0) {
      // Submit the comment and update the list of comments
      const successCallback = () => {
        setIsSaving(false);
        setErroredWhenSaving(false);
        // Reset the form
        setUnsavedNewCommentTextValue("");
      };

      const errorCallback = () => {
        setIsSaving(false);
        setErroredWhenSaving(true);
      };

      // Reset variables
      setErroredWhenSaving(false);

      // Show the saving spinner
      setIsSaving(true);

      onSubmit(trimmedComment, successCallback, errorCallback);
    }
  };

  /** Handle when the user selects an emoji to add to their response */
  const addEmojiToTextArea = (emoji: EmojiClickData, event: MouseEvent) => {
    // Get the emoji as a string to add to the value
    let sym = emoji.unified.split("-");
    let codesArray: number[] = [];
    sym.forEach((el) => codesArray.push(Number("0x" + el)));
    let emojiString = String.fromCodePoint(...codesArray);

    // Insert the emoji at the carat
    if (inputRef?.current) {
      const selectionStart = inputRef.current.selectionStart;
      const newTextValue =
        unsavedNewCommentTextValue.slice(0, selectionStart) +
        emojiString +
        unsavedNewCommentTextValue.slice(selectionStart);
        setUnsavedNewCommentTextValue(newTextValue);
      setFormIsDirty();
    }
  };

  const onTextValueChange = (text: string) => {
    setUnsavedNewCommentTextValue(text);
    setFormIsDirty();
  };

  return (
    <div className="border border-gray-300 rounded-lg py-2 mb-2">
      <div className="flex">
        <div className="hidden md:inline-block md:w-1/12 text-center pt-2">
          <Popover
            openState={popoverIsOpen}
            onOpenStateChange={setPopoverIsOpen}
            placement="left"
            contentClassNames="w-fit bg-white"
            showCloseIcon={false}
            triggerElement={
              <button
                className="hidden md:inline-block text-gray-600 disabled:text-gray-400 disabled:cursor-not-allowed"
                disabled={isSaving}
              >
                <FontAwesomeIcon
                  icon={faFaceSmile}
                  size="lg"
                  title={t(
                    "Pages.CollaborativeDocument.Common.AddEmojiTooltip"
                  )}
                  className="self-center "
                />
              </button>
            }
          >
            <EmojiPicker onEmojiClick={addEmojiToTextArea} />
          </Popover>
        </div>
        <div className="pl-2 w-10/12 md:w-10/12 md:pl-0">
          <TextArea
            inputId={newCommentFieldElementId}
            placeholder={t(
              "Pages.CollaborativeDocument.Controls.NewCommentPlaceholder"
            )}
            value={unsavedNewCommentTextValue}
            onChange={onTextValueChange}
            className="block w-full border-0 bg-gray-100 rounded-lg text-sm"
            minRows={1}
            maxRows={4}
            inputRef={inputRef}
            showValidationErrors={false}
          />
        </div>
        <div className="w-2/12 md:w-1/12">
          <div className="text-center pt-2">
            <FontAwesomeIcon
              title="Add comment"
              className="cursor-pointer"
              onMouseEnter={() => setIsOverSendIcon(true)}
              onMouseLeave={() => setIsOverSendIcon(false)}
              onClick={handleSubmitClick}
              color={clientColor}
              icon={!isOverSendIcon ? PaperPlaneReg : PaperPlaneSolid}
            />
          </div>
        </div>
      </div>
      {erroredWhenSaving && (
        <small className="pl-2">
          <span className="text-red-500 mr-2">
            {t("Pages.CollaborativeDocument.Common.AddCommentErrored")}
          </span>
          <button className="underline" onClick={handleSubmitClick}>
            {t("Common.TryAgain")}
          </button>
        </small>
      )}
    </div>
  );
}

export default AddUserTaskCommentForm;
