import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { Avatar, FormattedDate } from "../common";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import React from "react";
import UserContext from "../../state/UserContext";

interface IndividualCommentProps {
  commentId: string | number;
  comment: string;
  commentTimestamp: Date;
  author: UserBasicDetailsDto | undefined;
  allowDelete: boolean;
  disableDeleteButton: boolean;
  allowReply: boolean;
  onDeleteClick(commentId: string | number): void;
  onTargetCommentForReply(commentId: string | number): void;
  /** Replies, if there are any */
  children?: React.ReactNode | null;
}

function IndividualComment({
  commentId,
  comment,
  commentTimestamp,
  author,
  allowDelete,
  disableDeleteButton,
  allowReply,
  onDeleteClick,
  onTargetCommentForReply,
  children = null,
}: IndividualCommentProps) {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  const isCommentFromLoggedInUser = userContext.user.id === author?.userId;

  return (
    <div className="my-2" key={`comment_${commentId}`}>
      {isCommentFromLoggedInUser && (
        <div className="grid grid-cols-12">
          <div className="col-span-2">
            {author.fullName && author.initials && (
              <div className="inline-block mr-2 pt-1">
                <Avatar
                  imageUrl={author.profileImageUrl}
                  userFullName={author.fullName}
                  userInitials={author.initials}
                  size={8}
                  initialsTextClassName="text-xs"
                />
              </div>
            )}
          </div>
          <div className="col-span-10">
            <div
              style={{ whiteSpace: "pre-wrap" }}
              className="user-task-comment-owner rounded-md inline-block text-sm p-2 flex-grow whitespace-normal break-words min-w-[1%]"
            >
              {comment}
            </div>
            <div className="text-xs pt-0.5 text-gray-400">
              <div className="flex flex-row mt-[2px]">
                <FormattedDate
                  date={commentTimestamp}
                  displayMode="FROM-NOW"
                  convertFromUtc
                />
                {allowReply && (
                  <>
                    <p className="pl-1">|</p>
                    <div className="print:hidden pl-1">
                      <button
                        title={t("Pages.CollaborativeDocument.Common.Reply")}
                        className="text-xs text-gray-400"
                        onClick={() => onTargetCommentForReply(commentId)}
                      >
                        {t("Pages.CollaborativeDocument.Common.Reply")}
                      </button>
                    </div>
                  </>
                )}
                {allowDelete && (
                  <>
                    <p className="pl-1">|</p>
                    <div className="print:hidden">
                      <button
                        title={t("Common.Delete")}
                        className="ml-1 disabled:text-gray-400 disabled:cursor-not-allowed"
                        disabled={disableDeleteButton}
                        onClick={() => onDeleteClick(commentId)}
                      >
                        <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!isCommentFromLoggedInUser && (
        <div className="grid grid-cols-12">
          <div className="col-span-10">
            <div className="float-right">
              <div className="user-task-comment-non-owner rounded-md inline-block text-sm p-2 flex-grow whitespace-normal break-words min-w-[1%]">
                {comment}
              </div>
              <div className="text-xs pt-0.5 text-gray-400">
                <div className="flex flex-row justify-end mt-[2px]">
                  <FormattedDate
                    date={commentTimestamp}
                    displayMode="FROM-NOW"
                    convertFromUtc
                  />
                  {allowReply && (
                    <>
                      <p className="pl-1">|</p>
                      <div className="print:hidden pl-1">
                        <button
                          title={t("Pages.CollaborativeDocument.Common.Reply")}
                          className="text-xs text-gray-400"
                          onClick={() => onTargetCommentForReply(commentId)}
                        >
                          {t("Pages.CollaborativeDocument.Common.Reply")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 text-right">
            {author && author.fullName && author.initials && (
              <div className="inline-block mr-2 pt-1">
                <Avatar
                  imageUrl={author.profileImageUrl}
                  userFullName={author.fullName}
                  userInitials={author.initials}
                  size={8}
                  initialsTextClassName="text-xs"
                />
              </div>
            )}
          </div>
        </div>
      )}

      {children}

      {/* <div className="flex flex-row">
        {author && (
          <div className="inline-block mr-2 pt-1">
            <Avatar
              imageUrl={author.profileImageUrl}
              userFullName={author.fullName}
              userInitials={author.initials}
              size={8}
              initialsTextClassName="text-xs"
            />
          </div>
        )}
        <div className="rounded-md inline-block text-sm text-gray-500 p-2 bg-white shadow-md flex-grow whitespace-normal break-words min-w-[1%]">
          {comment}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-2/3 text-gray-400">
          <small className="pl-2">
            <FormattedDate
              date={commentTimestamp}
              displayMode="FROM-NOW"
              convertFromUtc
            />
          </small>
        </div>
        {(allowReply || allowDelete) && (
          <div className="basis-1/3 text-right print:hidden">
            {allowReply && (
              <button
                className="text-xs text-gray-400"
                onClick={() => onTargetCommentForReply(commentId)}
              >
                {t("Pages.CollaborativeDocument.Common.Reply")}
              </button>
            )}

            {allowDelete && (
              <button
                title={t("Common.Delete")}
                className="ml-2 disabled:text-gray-400 disabled:cursor-not-allowed"
                disabled={disableDeleteButton}
                onClick={() => onDeleteClick(commentId)}
              >
                <FontAwesomeIcon size="xs" icon={faTrashAlt} />
              </button>
            )}
          </div>
        )}
      </div>

      {children} */}
    </div>
  );
}

export default IndividualComment;
