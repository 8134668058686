import CheckboxTreeApiResponseDto from "../../types/dtos/admin/CheckboxTreeApiResponseDto";
import { apiClient } from "../apiClient";

class employeeStatusApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the employee status ready for a checkbox tree component */
  getEmployeeStatusCheckboxTreeItems(
    activeOnly: boolean,
    onSuccess: (appraisalLevels: CheckboxTreeApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/user/get-employee-status-checkbox-tree-items?activeOnly=" + activeOnly
    ).then(
      (data) => {
        const apiResponse = data as CheckboxTreeApiResponseDto;
        // Sort the labels
        apiResponse.nodes = apiResponse.nodes.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        onSuccess(apiResponse);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default employeeStatusApi;
