import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

interface LockedBannerProps {
  name: string;
}

export const LockedBanner = ({
  name,
}: LockedBannerProps) => {
  let content = t(
    "Pages.CollaborativeDocument.Common.LockedBanner"
  );
  content = content.replace("#OTHER_USER_NAME#", name);
  return (
    <div className="bg-gray-50 py-1 px-4 mb-2 text-gray-700 text-sm rounded-sm border-l-2 border-l-gray-800">
      <div>
        <div className="fa-layers fa-fw mr-0.5">
          <FontAwesomeIcon icon={faLock} />
        </div>
        <span className="text-gray-800 font-medium"></span> {content}
      </div>
    </div>
  );
};

export default LockedBanner;
