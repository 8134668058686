import { faBullseye, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { dateHelper } from "../../helpers";
import { FormSummaryGoalDataDto } from "../../types/dtos/form-summaries/FormSummaryGoalDataDto";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import { TaskType } from "../../types/tasks";
import { EditableTask } from "../../types/tasks/EditableTasks";
import { StatusDot } from "../common";
import FormattedDate from "../common/FormattedDate";
import ManageTaskPopup from "../tasks/ManageTaskPopup";
import { IndividualGoalSummaryDto } from "../../types/dtos/form-summaries/IndividualGoalSummaryDto";
import { Markup } from "interweave";

interface GoalFormSummaryContentProps {
  goalData: FormSummaryGoalDataDto | null | undefined;
  isManagerDashboard: boolean;
  /**A method which calls the api to save a Goal/Learning/Action */
  modifyTaskFromDashboard?:
    | ((task: EditableTask<number>, onSuccess: () => void) => void)
    | undefined;
  /**A method to call the api and load the task that has been clicked on in the timeline widget */
  onLoadGoalToEdit?:
    | ((
        taskId: number,
        taskType: TaskType,
        successCallback: (data: ModifyTaskResponseDto<number>) => void
      ) => void)
    | undefined;
  handleSummaryRefresh?: (() => void) | undefined;
}

const GoalFormSummaryContent = ({
  goalData,
  isManagerDashboard,
  modifyTaskFromDashboard = undefined,
  onLoadGoalToEdit = undefined,
  handleSummaryRefresh = undefined,
}: GoalFormSummaryContentProps) => {
  const { t } = useTranslation();
  const currentDate = dateHelper.getCurrentDateUtc();
  const hasGoals = goalData && goalData.goals && goalData.goals.length > 0;
  const canEditGoals = onLoadGoalToEdit !== undefined;
  const taskType = "GOAL";

  //States
  const [taskToEdit, setTaskToEdit] = useState<EditableTask<number> | null>(
    null
  );
  const [isReadOnlyPopup, setIsReadOnlyPopup] = useState<boolean>(false);
  const [taskLockedByJourney, setTaskLockedByJourney] = useState<string | null>(
    null
  );
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const triggerEditTaskPopup = (taskId: number, taskType: TaskType) => {
    const successCallback = (responseData: ModifyTaskResponseDto<number>) => {
      setModalIsOpen(true);
      setTaskToEdit(responseData.task);
      setTaskLockedByJourney(responseData.editLockedByJourney);

      if (
        responseData.task &&
        (responseData.task.status === "COMPLETED" ||
          responseData.task.status === "CANCELLED" ||
          responseData.editLockedByJourney)
      ) {
        setIsReadOnlyPopup(true);
      } else {
        setIsReadOnlyPopup(false);
      }
    };

    if (!onLoadGoalToEdit) return;
    onLoadGoalToEdit(taskId, taskType, successCallback);
  };

  const onModalOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setTaskToEdit(null);
      setIsReadOnlyPopup(false);
    }
    setModalIsOpen(isOpen);
  };

  const handleTaskSave = (task: EditableTask<number>) => {
    const onSuccess = () => {
      onSummaryRefresh();
    };
    if (modifyTaskFromDashboard) {
      modifyTaskFromDashboard(task, onSuccess);
    }
  };

  const onSummaryRefresh = () => {
    if (handleSummaryRefresh) {
      handleSummaryRefresh();
    }
  };

  const emptyStateHeading =
    "Summaries.Goals.EmptyStateHeadings." +
    (isManagerDashboard ? "Manager" : "Employee");

  const statusDot = (g: IndividualGoalSummaryDto) => {
    const targetDate = dateHelper.convertDateFromStringToUtc(g.targetDate);

    const dot = g.dateCompleted ? (
      <StatusDot colour="GREEN" />
    ) : currentDate > targetDate ? (
      <StatusDot colour="RED" />
    ) : (
      <StatusDot colour="AMBER" />
    );

    return <span className="flex-col items-center">{dot}</span>;
  };

  return (
    <div className="text-center w-full border rounded p-6">
      {!hasGoals && (
        <div className="mt-2 mb-4 text-sm text-gray-400">
          <Markup content={t(emptyStateHeading)} />
        </div>
      )}
      {hasGoals && (
        <div className="pb-2">
          <div className="flex flex-col">
            <div className="flex flex-row text-xs uppercase justify-between primary-text px-2 md:px-4 mt-4 bg-gray-100 rounded-t-md">
              <div className="flex-none w-4 md:hidden"></div>
              <div className="w-10 flex-none py-2 mx-2"></div>
              <div className="flex-grow py-2 text-left">
                {t("Tasks.Common.TaskName")}
              </div>
              <div className="hidden md:block w-12 py-2 flex-none text-center">
                {t("Tasks.Common.Status")}
              </div>
              <div className="w-28 py-2 text-right flex-none mr-2">
                {t("Tasks.Common.TargetDate")}
              </div>
              {canEditGoals && <div className="w-8 flex-none py-2"></div>}
            </div>
          </div>
          {goalData.goals.map((g) => {
            const dot = statusDot(g);
            return (
              <div key={g.taskId}>
                <div className="flex flex-row text-gray-600 font-medium justify-between px-2 md:px-4 bg-gray-50 rounded-b-md">
                  <FontAwesomeIcon
                    icon={faBullseye}
                    className="primary-colour self-center w-10 mx-2"
                  />
                  <span className="flex-none w-4 md:hidden pt-1 self-center">
                    {dot}
                  </span>
                  <span className="text-sm py-2 flex-grow self-center text-left">
                    {canEditGoals && (
                      <button
                        className="inline-block text-left mr-1"
                        onClick={() => triggerEditTaskPopup(g.taskId, taskType)}
                      >
                        {g.goalTitle}
                      </button>
                    )}
                    {!canEditGoals && (
                      <span className="inline-block mr-1">{g.goalTitle}</span>
                    )}
                  </span>
                  <div className="hidden md:block w-12 py-2 flex-none pt-1 text-center self-center ">
                    {dot}
                  </div>
                  <div className="text-sm w-28 py-2 text-right flex-none self-center text-gray-400 mr-2">
                    <FormattedDate
                      date={g.targetDate}
                      displayMode="DATE-ONLY"
                    />
                  </div>
                  {canEditGoals && (
                    <div className="w-8 flex-none text-center py-2 self-center">
                      <button
                        className="px-2 text-gray-400 hover:cursor-pointer"
                        onClick={() => triggerEditTaskPopup(g.taskId, taskType)}
                      >
                        <FontAwesomeIcon icon={faPencil} size="xs" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {canEditGoals && (
            <ManageTaskPopup
              mode="EDIT"
              isOpen={modalIsOpen}
              onOpenChange={onModalOpenChange}
              onSaveClick={handleTaskSave}
              taskType={taskType}
              editObject={taskToEdit}
              popupOrigin="DASHBOARD"
              refreshParentTaskList={onSummaryRefresh}
              isReadOnly={isReadOnlyPopup}
              setIsReadOnly={setIsReadOnlyPopup}
              taskLockedByJourney={taskLockedByJourney}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GoalFormSummaryContent;
