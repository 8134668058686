import { useContext } from "react";
import { t } from "i18next";
import cx from "classnames";
import UserContext from "../../../state/UserContext";
import { EmployeeDashboardUpdateDto } from "../../../types/dtos/dashboards";
import { UserBasicDetailsDto } from "../../../types/dtos/generic";
import { advancedTaskHelper } from "../../../helpers";

interface EmployeeUpdateHeaderProps {
  useShorterText: boolean;
  update: EmployeeDashboardUpdateDto;
  isExpanded: boolean;
}

const getHeaderText = (update: EmployeeDashboardUpdateDto): string => {
  let headerText = "";
  switch (update.updateType) {
    case "COLLABDOC":
    case "DUALPREPMEETING":
    case "DUALPREPPLANNING":
      if (update.journey != null) {
        headerText = update.journey.journeyTitle;
      }
      break;
    case "NOTASKS":
      if (update.task != null) {
        headerText = t("DashboardUpdate.Employee.Header.NoTasks").replace(
          "#TASKS#",
          advancedTaskHelper.ToLowerCase(
            update.task.pluralNameTranslationKeyIdentifier
          )
        );
      }
      break;
    case "NOTENOUGHTASKS":
      if (update.task != null) {
        headerText = t(
          "DashboardUpdate.Employee.Header.NotEnoughTasks"
        ).replace(
          "#TASKS#",
          advancedTaskHelper.ToLowerCase(
            update.task.pluralNameTranslationKeyIdentifier
          )
        );
      }
      break;
    case "OVERDUETASKS":
      if (update.task != null) {
        headerText = t("DashboardUpdate.Employee.Header.OverdueTasks").replace(
          "#TASKS#",
          advancedTaskHelper.ToLowerCase(
            update.task.pluralNameTranslationKeyIdentifier
          )
        );
      }
      break;
  }
  return headerText;
};

const getSummaryText = (
  useShorterText: boolean,
  update: EmployeeDashboardUpdateDto,
  manager: UserBasicDetailsDto | null | undefined
): string => {
  // Use different text for different scenarios
  let summaryText = "";
  switch (update.updateType) {
    case "COLLABDOC":
      if (update.journey !== null) {
        // Only either "INITIAL-SAVE-FORM-INCOMPLETE" or a status where the manager last updated the doc,
        // so if it's in a status where we know the manager did the last update, change the text accordingly
        if (update.journey.showAsWaitingForManager) {
          summaryText = "Waiting for #MANAGER_NAME# to complete";
        } else if (
          update.journey.answerSetApprovalStatus !== null &&
          update.journey.answerSetApprovalStatus !==
            "INITIAL-SAVE-FORM-INCOMPLETE"
        ) {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.CollabDoc_ManagerMadeChanges"
          );
        } else {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.CollabDoc_SendChangesToManager"
          );
        }
      }

      break;
    case "DUALPREPMEETING":
      // Only either "INITIAL-SAVE-FORM-INCOMPLETE" or a status where the manager last updated the doc,
      // so if it's in a status where we know the manager did the last update, change the text accordingly
      if (
        update.journey !== null &&
        update.journey.answerSetApprovalStatus !== null &&
        update.journey.answerSetApprovalStatus !==
          "INITIAL-SAVE-FORM-INCOMPLETE"
      ) {
        summaryText = t(
          "DashboardUpdate.Employee.Summary.DualPrepMeeting_ManagerMadeChanges"
        );
      } else {
        summaryText = t(
          "DashboardUpdate.Employee.Summary.DualPrepMeeting_BothPlanningComplete"
        );
      }
      break;
    case "DUALPREPPLANNING":
      summaryText = t(
        "DashboardUpdate.Employee.Summary.DualPrepPlanning_CompletePlanning"
      );
      break;
    case "NOTASKS":
      if (update.task != null) {
        const taskName = advancedTaskHelper.ToLowerCase(
          update.task.pluralNameTranslationKeyIdentifier
        );

        if (useShorterText) {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.NoTasks_Short"
          ).replace("#TASKS#", taskName);
        } else {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.NoTasks_Long"
          ).replaceAll("#TASKS#", taskName);
        }
      }
      break;
    case "NOTENOUGHTASKS":
      if (update.task != null) {
        const taskName = advancedTaskHelper.ToLowerCase(
          update.task.pluralNameTranslationKeyIdentifier
        );

        if (useShorterText) {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.NotEnoughTasks_Short"
          ).replace("#TASKS#", taskName);
        } else {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.NotEnoughTasks_Long"
          ).replaceAll("#TASKS#", taskName);
        }
      }
      break;
    case "OVERDUETASKS":
      if (update.task != null) {
        const taskName = advancedTaskHelper.ToLowerCase(
          update.task.pluralNameTranslationKeyIdentifier
        );

        if (useShorterText) {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.OverdueTasks_Short"
          ).replace("#TASKS#", taskName);
        } else {
          summaryText = t(
            "DashboardUpdate.Employee.Summary.OverdueTasks_Long"
          ).replaceAll("#TASKS#", taskName);
        }
      }
      break;
  }

  // Calculate the manager display name
  const managerFirstName =
    (update.journey != null && update.journey.managerRoleFirstName
      ? update.journey.managerRoleFirstName
      : manager?.firstName) ?? "";

  // Replace tokens
  summaryText = summaryText.replace("#MANAGER_NAME#", managerFirstName);

  return summaryText;
};

function EmployeeUpdateHeader({
  useShorterText,
  update,
  isExpanded,
}: EmployeeUpdateHeaderProps) {
  const userContext = useContext(UserContext);
  const headerText = getHeaderText(update);
  const summaryText = getSummaryText(
    useShorterText,
    update,
    userContext.user.manager
  );

  return (
    <div className="text-white">
      <div
        className={cx(
          "font-semibold heading",
          isExpanded ? "text-xl" : "text-white/90"
        )}
      >
        {headerText}
      </div>
      <div
        className={
          isExpanded
            ? "mb-1 mt-1 text-white/90 text-sm"
            : "mb-1 mt-1 text-white/80 text-sm"
        }
      >
        {summaryText}
      </div>
    </div>
  );
}

export default EmployeeUpdateHeader;
