import {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { CollaborativeDoc, CommentsSideBar } from ".";
import { MainContainer } from "../layout";
import { AppContextInterface } from "../../state/AppContext";
import { UserContextInterface } from "../../state/UserContext";
import { AnswerSetApprovalStatus, ApprovalFlow } from "../../types/collab-docs";
import { CollabDocSubmitResponseDto } from "../../types/dtos/collab-docs";
import { AlertPopup, ModalPopup } from "../common";
import {
  FormQuestion,
  QuestionAnswer,
  QuestionAnswerType,
  QuestionAnswerValue,
  QuestionTasks,
} from "../../types/forms";
import {
  collabDocAlertHelper,
  dateHelper,
  goalReviewQuestionHelper,
  typeConversionHelper,
} from "../../helpers";
import AppRoutes from "../../routes/AppRoutes";
import { AlertContent } from "../../types/generic";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import SuccessIcon from "../common/SuccessIcon";
import collabDocApi from "../../api/forms/collabDocApi";
import collabDocAnswerHelper from "../../helpers/collabDocAnswerHelper";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";
import userApi from "../../api/user/userApi";
import { SignedInUserUpdatedDetailsDto } from "../../types/dtos/users/SignedInUserDto";
import {
  EnforcedCommentType,
  NewCommentDto,
  SavedCommentDto,
} from "../../types/dtos/forms";
import { EditableGoal, EditableTask } from "../../types/tasks/EditableTasks";
import { CollabDocState } from "../../state/CollabDoc/CollabDocState";
import SigningOffTheJourneyPopup from "../journeys/SigningOffTheJourneyPopup";
import SkipManagerPlanningPopUp from "../manager-dashboard/SkipManagerPlanningPopUp";

interface CollaborativeDocumentPageProps {
  state: CollabDocState;
  setState(value: Partial<CollabDocState>): void;
  showLoadingError: boolean;
  setShowLoadingError(value: boolean): void;
  loadData(): void;
  setOverrideActiveNavItem(subjectUser: BaseUserDetailsDto | null): void;
  userContext: UserContextInterface;
  appContext: AppContextInterface;
  collabDocHelper: collabDocAnswerHelper;
  apiCollabDocs: collabDocApi;
  usersApi: userApi;
  answerSetUniqueId: string | undefined;
  answerSetDateCreated: Date | undefined | null;
  singleFormId: string | undefined;
  journeyName: string;
  approvalFlow: ApprovalFlow;
}

interface SkipPlanningPopupState {
  isOpen: boolean;
  subjectUserName: string | null;
  subjectUserId: number | null;
  journeyRef: string | null;
  clientFormTitle: string | null;
  answerSetUniqueId: string | null;
  answerSetDateCreated: Date | null;
  skipPlanningConfirmationTicked: boolean;
  isLoading: boolean;
}

const getDefaultSkipPlanningPopupState = (): SkipPlanningPopupState => {
  return {
    isOpen: false,
    subjectUserName: null,
    subjectUserId: null,
    journeyRef: null,
    clientFormTitle: null,
    answerSetUniqueId: null,
    answerSetDateCreated: null,
    skipPlanningConfirmationTicked: false,
    isLoading: false,
  };
};

function CollaborativeDocumentPage({
  state,
  setState,
  showLoadingError,
  setShowLoadingError,
  loadData,
  setOverrideActiveNavItem,
  userContext,
  appContext,
  collabDocHelper,
  apiCollabDocs,
  usersApi,
  answerSetUniqueId,
  answerSetDateCreated,
  singleFormId,
  journeyName,
  approvalFlow,
}: CollaborativeDocumentPageProps) {
  // Constants / context / callbacks
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const postJourneyModalEnabled = urlParams.get("pj") === "1";

  // If a singleFormId parameter is passed in the url, the user is choosing
  // to view a specific form, so if this is a collab doc with multiple forms,
  // we need to filter out any other forms and not display those
  const specificFormId = typeConversionHelper.stringToNumber(singleFormId);
  const isSingleFormMode = specificFormId !== undefined;

  const navigateToDashboard = useCallback(() => navigate(AppRoutes.home), []);

  const refreshUserAndGoToDashboard = useCallback(() => {
    const loggedInUserIsSubjectUser =
      state.subjectUser!.userId === userContext.user.id;
    if (loggedInUserIsSubjectUser) {
      usersApi.getRefreshedLoggedInUserDetails(
        (updates: SignedInUserUpdatedDetailsDto) => {
          userContext.setUpdatedUserDetails(updates);
          navigate(AppRoutes.home);
        },
        () => {
          navigate(AppRoutes.home);
        }
      );
    } else if (
      !userContext.user.isManager &&
      !userContext.user.isJourneyManager
    ) {
      navigate(AppRoutes.home);
    } else {
      navigate(AppRoutes.yourPeople.root);
    }
  }, [userContext, state.subjectUser]);

  // State
  const [showSigningOffJourneyModal, setShowSigningOffJourneyModal] =
    useState<boolean>(false);
  const [showPostJourneyModal, setShowPostJourneyModal] =
    useState<boolean>(false);
  const [showDeleteCommentModal, setShowDeleteCommentModal] =
    useState<boolean>(false);
  const [showSubmitSuccessfulAlert, setShowSubmitSuccessfulAlert] =
    useState<boolean>(false);
  const [showSubmitErrorAlert, setShowSubmitErrorAlert] =
    useState<boolean>(false);
  const [submitSuccessAlertContent, setSubmitSuccessAlertContent] =
    useState<AlertContent>({
      title: "",
      body: "",
      button: "",
    });
  const [submitErrorAlertContent, setSubmitErrorAlertContent] =
    useState<AlertContent>({
      title: "",
      body: "",
    });
  const [skipPlanningPopUpState, setSkipPlanningPopUpState] = useReducer<
    Reducer<SkipPlanningPopupState, Partial<SkipPlanningPopupState>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    getDefaultSkipPlanningPopupState()
  );

  // Page load, load the form data
  useEffect(() => {
    // Call the API to load the necessary state
    loadData();

    // Show the post journey modal on page load, if specified in the path
    setShowPostJourneyModal(postJourneyModalEnabled);

    return () => {
      setOverrideActiveNavItem(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Ensure rolled over goals are kept in sync, if this is a goal review question
    if (state.activeQuestionId && !state.isReadOnly) {
      debouncedSyncRolloverGoals(state.answerState, state.taskState);
    }
  }, [state.answerState]);

  useEffect(() => {
    // If the user context isn't loaded, reload the form when the user details kick in
    loadData();
  }, [userContext.user.id]);

  useEffect(() => {
    // Calculate whether or not to display the update document banner
    const isApproved =
      state.status === "FULLY-APPROVED" ||
      state.status === "NO-APPROVAL-NECESSARY";
    const shouldShowUpdateDocBanner =
      isSingleFormMode && state.isReadOnly && isApproved;
    setState({ showUpdateDocBanner: shouldShowUpdateDocBanner });

    // Control whether or not to display the discard answer set feature.
    // As soon as the user submits it to the other party, they can no longer discard it
    if (
      state.userCanDiscardAnswerSet &&
      state.status !== "INITIAL-SAVE-FORM-INCOMPLETE"
    ) {
      setState({ userCanDiscardAnswerSet: false });
    }
  }, [state.status, state.isReadOnly]);

  // Interval Methods

  useInterval(
    () => {
      // Main Callback for the interval tick
      if (!state.isReadOnly) {
        apiCollabDocs.shouldCollabDocBeLocked(
          answerSetUniqueId!,
          (data: boolean) => {
            // If the document was locked, but is now unlocked then re-load form data.
            if (state.isLocked && !data) {
              loadData();
            }

            setState({ isLocked: data });
          },
          (error: any) => {}
        );
      }
    },
    // Secondary callback, purely because we have custom logic involving state for the clear/unmount
    () => {
      if (!state.isReadOnly) {
        apiCollabDocs.clearLockedFieldsWhenLoggedInUserIsLockedByUser(
          answerSetUniqueId!,
          (data: boolean) => {},
          (error: any) => {}
        );
      }
    },
    15000
  );

  // @ts-ignore
  function useInterval(callback, twoCallback, delay) {
    const savedCallback = useRef();
    const clearCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      clearCallback.current = twoCallback;
    }, [twoCallback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        // @ts-ignore
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => {
          clearInterval(id);
          // @ts-ignore
          clearCallback.current();
        };
      }
    }, [delay]);
  }

  // Functions/methods

  const filterVisibleComments = (questionId: string) => {
    const filteredComments = state.comments.filter(
      (x) => x.questionId === questionId && x.commentType !== "ENFORCED"
    );
    setState({ visibleComments: filteredComments });
  };

  /** Call this function when a goal review status radio button is changed
   * to ensure any related goal setting sections are kept in sync
   */
  const synchroniseRolledOverGoals = (
    currentAnswers: QuestionAnswer[],
    currentTasks: QuestionTasks[]
  ): void => {
    if (!state.activeQuestionId) return;

    const activeQuestionDto = state.forms
      .flatMap((x) => x.questions)
      .find((x) => x.questionId === state.activeQuestionId);
    if (!activeQuestionDto) return;

    const activeQuestion = new FormQuestion(activeQuestionDto);

    if (
      !activeQuestion ||
      !activeQuestion.isGoalReviewQuestion() ||
      !activeQuestion.goalReviewOptions
    )
      return;

    const checkResult = goalReviewQuestionHelper.checkRolledOverGoals(
      state.formComplexities,
      activeQuestion.goalReviewOptions,
      currentAnswers,
      currentTasks
    );

    // Either update an existing answer for the goal setting question, or create one
    const goalSettingQuestionId =
      state.formComplexities.goalRolloverConfig?.goalSettingQuestionId;

    // Can't do anything if there's no goal setting question
    if (!goalSettingQuestionId) return;

    let modifiedTasks: EditableGoal<string>[] = [];

    if (checkResult.goalsToSave) {
      // Save the new rolled over goals to goal setting
      modifiedTasks = modifiedTasks.concat(checkResult.goalsToSave);
    }

    if (checkResult.taskIdsToDelete) {
      // Remove the invalid goals from goal setting
      let questionTasks =
        currentTasks.find((x) => x.questionId === goalSettingQuestionId)
          ?.tasks || [];
      for (var iTask = 0; iTask < questionTasks.length; iTask++) {
        const loopTask = questionTasks[iTask];
        if (
          loopTask.taskId &&
          loopTask.taskType === "GOAL" &&
          checkResult.taskIdsToDelete.indexOf(loopTask.taskId) >= 0
        ) {
          loopTask.modifyStatus = "DELETED";
          modifiedTasks.push(loopTask as EditableGoal<string>);
        }
      }
    }

    if (modifiedTasks.length > 0) {
      onChangeQuestionTasksBatch(
        {
          formId: activeQuestion.formId,
          questionId: goalSettingQuestionId,
          tasks: modifiedTasks,
        },
        () => {
          // Tasks saved
        },
        () => {
          // Tasks save error
          console.log("Tasks save errored");
        }
      );
    }
  };

  const triggerSkipConfirmationModalChange = () => {
    setSkipPlanningPopUpState({
      isOpen: !skipPlanningPopUpState.isOpen,
      skipPlanningConfirmationTicked: false,
    });
  };

  const handleSkipConfirmationModalClick = () => {
    triggerSkipConfirmationModalChange();
    setSkipPlanningPopUpState({
      isOpen: true,
      subjectUserName: state.subjectUser!.firstName,
      subjectUserId: state.subjectUser!.userId,
      answerSetUniqueId: answerSetUniqueId,
      clientFormTitle: appContext.pageTitle,
    });
  };

  const handleSkipPlanningConfirmationTicked = (isTicked: boolean) => {
    setSkipPlanningPopUpState({ skipPlanningConfirmationTicked: isTicked });
  };

  const onConfirmSkipButtonClick = () => {
    setSkipPlanningPopUpState({ isLoading: true });
    onSubmitDocument("DUAL-PREP-SUBMITTED", true);
  };
  const onCancelSkipButtonClick = () => {
    setSkipPlanningPopUpState(getDefaultSkipPlanningPopupState());
  };

  // use the `debouncedSyncRolloverGoals` function to avoid a race condition
  // caused by quickfire clicking of goal status options to have rolled
  // over goals leftover if status quickly changed from "rollover" to "achieved"
  const debouncedSyncRolloverGoals = useMemo(
    () => debounce(synchroniseRolledOverGoals, 500),
    [
      state.activeQuestionId,
      state.forms,
      state.formComplexities,
      state.taskState,
      answerSetUniqueId,
    ]
  );

  // Update the visible comments when the comments collection changes
  // (to display new comments/deleted comments)
  useEffect(() => {
    if (state.activeQuestionId) {
      filterVisibleComments(state.activeQuestionId);
    }
  }, [state.comments]);

  // Event handlers

  const onActiveQuestionChange = (questionId: string) => {
    setState({ activeQuestionId: questionId });
    filterVisibleComments(questionId);
  };

  const getFormIdForQuestionId = (questionId: string): number => {
    return state.forms.find(
      (f) => f.questions.findIndex((q) => q.questionId === questionId) >= 0
    )!.formId;
  };

  /** Update the answer state for the matching question with the given value */
  const onValueChange = useCallback(
    (
      questionId: string,
      newValue: QuestionAnswerValue,
      answerType: QuestionAnswerType
    ): QuestionAnswer | null => {
      let output: QuestionAnswer | null = null;
      if (!questionId) return output;

      const answerFormId = getFormIdForQuestionId(questionId);

      // Update the existing state answer if there is one, otherwise add it to the state
      // if it's a new answer
      const nextState = produce(state.answerState, (draft) => {
        const matchIndex = draft.findIndex((x) => x.questionId === questionId);

        // Remove the old answer
        if (matchIndex >= 0) {
          draft.splice(matchIndex, 1);
        }

        // Add a new answer
        output = {
          id: null,
          questionId: questionId,
          answer: newValue,
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: userContext.user.id,
          answerType: answerType,
          formId: answerFormId,
        };
        draft.push(output);
      });

      setState({
        answerState: nextState,
        formIsDirty: true,
      });
      return output;
    },
    [state.forms, state.answerState]
  );

  /** When a task is added/edited/deleted */
  const onChangeQuestionTasks = (
    questionTasks: QuestionTasks,
    onSuccess: () => void,
    onError: () => void
  ) => {
    // Retrieve the modifiedTask and its index
    var modifiedTask = questionTasks.tasks.find(
      (x) => x.modifyStatus !== "ORIGINAL"
    );
    var modifiedTaskIndex = questionTasks.tasks.findIndex(
      (x) => x.modifyStatus !== "ORIGINAL"
    );

    if (modifiedTask && answerSetUniqueId) {
      // In the scenario the modifiedTask hits the server (e.g. when it's not DELETED) convert the Target Date to UTC
      if (modifiedTask.modifyStatus !== "DELETED") {
        modifiedTask.targetDate =
          modifiedTask.targetDate !== null
            ? dateHelper.convertDateToUtc(modifiedTask.targetDate)
            : modifiedTask.targetDate;
      }

      // Hit the API call on the server
      apiCollabDocs.saveFormTask(
        modifiedTask,
        answerSetUniqueId,
        questionTasks.questionId,
        questionTasks.formId,
        (data: ModifyTaskResponseDto<string>) => {
          const nextState = produce(state.taskState, (draft) => {
            const tasks = [...questionTasks.tasks];

            // Convert the target date to local, if any replacement/new task comes back from the server
            if (data.task && data.task.targetDate) {
              data.task.targetDate = dateHelper.convertUtcDateToLocal(
                data.task.targetDate
              );
            }

            if (modifiedTask) {
              switch (modifiedTask.modifyStatus) {
                case "NEW":
                  // Update the ModifiyStatus to show it as Original now its been saved
                  tasks[modifiedTaskIndex].modifyStatus = "ORIGINAL";
                  break;
                case "UPDATED":
                  // At the right index, remove one element and insert the updated one from server
                  tasks.splice(modifiedTaskIndex, 1, data.task);
                  break;
                case "DELETED":
                  // At the right index, remove the deleted element
                  tasks.splice(modifiedTaskIndex, 1);
                  break;
              }
            }

            const match = draft.find(
              (x) => x.questionId === questionTasks.questionId
            );

            if (match !== undefined) {
              match.tasks = tasks;
            } else {
              draft.push(questionTasks);
            }
          });

          setState({
            taskState: nextState,
            formIsDirty: true,
          });
          onSuccess();
        },
        (error: any) => {
          const nextState = produce(state.taskState, (draft) => {
            const tasks = [...questionTasks.tasks];

            const match = draft.find(
              (x) => x.questionId === questionTasks.questionId
            );

            if (match !== undefined) {
              match.tasks = tasks;
            } else {
              draft.push(questionTasks);
            }
          });

          setState({ taskState: nextState });
          console.error(error);
          onError();
        }
      );
    }
  };

  const onChangeQuestionTasksBatch = (
    questionTasks: QuestionTasks,
    onSuccess: () => void,
    onError: () => void
  ) => {
    var modifiedTasks = questionTasks.tasks.filter(
      (x) => x.modifyStatus !== "ORIGINAL"
    );

    if (!modifiedTasks || modifiedTasks.length === 0) return;

    // Hit the API call on the server
    apiCollabDocs.saveModifiedTasksBatch(
      modifiedTasks,
      answerSetUniqueId!,
      questionTasks.questionId,
      questionTasks.formId,
      (data: EditableTask<string>[]) => {
        // Prepare the fresh tasks from the db for display
        const newTasksArray: EditableTask<string>[] = data.map((x) => {
          x.modifyStatus = "ORIGINAL";
          x.targetDate =
            x.targetDate === null
              ? null
              : dateHelper.convertUtcDateToLocal(x.targetDate);
          return x;
        });

        // Update the tasks state
        const nextState = produce(state.taskState, (draft) => {
          const match = draft.find(
            (x) => x.questionId === questionTasks.questionId
          );

          if (match !== undefined) {
            match.tasks = newTasksArray;
          } else {
            draft.push({ ...questionTasks, tasks: newTasksArray });
          }
        });

        setState({
          taskState: nextState,
          formIsDirty: true,
        });
        onSuccess();
      },
      (error: any) => {
        console.error(error);
        onError();
      }
    );
  };

  /** Save a question's value via the API */
  /** The last parameter is optional as in some scenarios the state isn't updated as quickly as what we need
   *  when saving the answers. If not given it will look for the answer in the state.
   */
  const onValueSave = useCallback(
    (
      answer: QuestionAnswer,
      nonStateValue: QuestionAnswer | null,
      onSuccess: () => void,
      onError: () => void
    ) => {
      if (answer || nonStateValue) {
        const answerToSave = nonStateValue ? nonStateValue : answer;

        // Call the API
        collabDocHelper.saveAnswer(
          answerToSave.questionId,
          answerToSave,
          answerSetUniqueId!,
          answerSetDateCreated!,
          onSuccess,
          onError
        );
      } else {
        console.log("Couldn't find an answer to save for question");
        onError();
      }
    },
    [state.forms, state.answerState, answerSetUniqueId]
  );

  const onRetryValueSave = useCallback(
    (questionId: string, onSuccess: () => void, onError: () => void) => {
      const answerToSave = state.answerState.find(
        (x) => x.questionId === questionId
      );

      if (answerToSave) {
        // Call the API
        collabDocHelper.saveAnswer(
          questionId,
          answerToSave,
          answerSetUniqueId!,
          answerSetDateCreated!,
          onSuccess,
          onError
        );
      } else {
        console.log(
          "Couldn't find an answer to save for question: " + questionId
        );
        onError();
      }
    },
    [state.forms, state.answerState, answerSetUniqueId]
  );

  const onRetryTasksSave = useCallback(
    (questionId: string, onSuccess: () => void, onError: () => void) => {
      const tasksToSave = state.taskState.find(
        (x) => x.questionId === questionId
      );

      if (tasksToSave) {
        // Call the API
        onChangeQuestionTasks(tasksToSave, onSuccess, onError);
      } else {
        console.log(
          "Couldn't find an answer to save for question: " + questionId
        );
        onError();
      }
    },
    [state.forms, state.answerState, answerSetUniqueId, onChangeQuestionTasks]
  );

  const onRetryEnforcedCommentSave = useCallback(
    (
      questionId: string,
      behaviourId: number | null,
      goalId: number | null,
      nonStateValue: SavedCommentDto | null,
      onSuccess: () => void,
      onError: () => void
    ) => {
      // Find the comment to save for this behaviour/goal for this question
      const commentToSave = state.comments.find(
        (x) =>
          x.questionId === questionId &&
          (!behaviourId || x.behaviourId === behaviourId) &&
          (!goalId || x.goalId === goalId)
      );

      if (commentToSave) {
        // Call the API
        handleEnforcedCommentSave(
          questionId,
          behaviourId,
          goalId,
          nonStateValue,
          onSuccess,
          onError
        );
      } else {
        console.log(
          "Couldn't find an enforced comment to save for question: " +
            questionId
        );
        onError();
      }
    },
    [
      state.forms,
      state.comments,
      state.answerState,
      answerSetUniqueId,
      onChangeQuestionTasks,
    ]
  );

  const onCommentsSeen = (questionId: string) => {
    const onCommentSeenSuccess = () => {
      const nextState = produce(state.comments, (draft) => {
        const matches = draft.filter((x) => x.questionId === questionId);
        matches.forEach((m) => (m.seen = true));
      });

      setState({ comments: nextState });
    };

    const onCommentSeenError = (error: any) => {
      console.log("Error marking comment as seen", error);
    };

    // Call the API
    apiCollabDocs.markQuestionCommentsAsSeen(
      questionId,
      answerSetUniqueId!,
      onCommentSeenSuccess,
      onCommentSeenError
    );
  };

  const onCommentAdd = (
    newComment: NewCommentDto,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    const onCommentAddSuccess = (newComment: SavedCommentDto) => {
      const nextState = [...state.comments];
      nextState.push(newComment);
      setState({ comments: nextState });
      setState({ formIsDirty: true });
      successCallback();
    };

    const onCommentAddError = (error: any) => {
      console.log("Error saving new comment", error);
      errorCallback();
    };

    apiCollabDocs.submitNewComment(
      answerSetUniqueId!,
      newComment,
      onCommentAddSuccess,
      onCommentAddError
    );
  };

  interface CommentDeleteCallbacks {
    onError: undefined | (() => void);
    onSuccess: undefined | (() => void);
  }

  const [commentDeleteCallbacks, setCommentDeleteCallbacks] =
    useState<CommentDeleteCallbacks>({
      onError: undefined,
      onSuccess: undefined,
    });

  const onCommentDelete = (
    commentId: string,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    // Store the callbacks so we can call them when confirming the delete in the modal
    setCommentDeleteCallbacks({
      onError: errorCallback,
      onSuccess: successCallback,
    });
    setShowDeleteCommentModal(true);
    setState({ commentToDelete: commentId });
  };

  const onCancelCommentDelete = () => {
    setState({ commentToDelete: null });
    setShowDeleteCommentModal(false);
  };

  const onConfirmCommentDelete = () => {
    // Delete the comment
    const onSuccess = () => {
      const nextState = state.comments.filter(
        (x) => x.id !== state.commentToDelete
      );
      setState({ comments: nextState });

      // Close the modal and reset the commentId to delete
      setShowDeleteCommentModal(false);
      setState({
        commentToDelete: null,
        formIsDirty: true,
      });
      if (commentDeleteCallbacks?.onSuccess) {
        commentDeleteCallbacks?.onSuccess();
      }
    };

    const onError = () => {
      setState({ commentToDelete: null });
      setShowDeleteCommentModal(false);
      if (commentDeleteCallbacks?.onError) {
        commentDeleteCallbacks?.onError();
      }
    };

    apiCollabDocs.deleteComment(
      state.commentToDelete!,
      answerSetUniqueId!,
      onSuccess,
      onError
    );
  };

  /** Enforced comment functions */
  const handleEnforcedCommentChange = (
    questionId: string,
    newValue: string,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ): SavedCommentDto => {
    let output: SavedCommentDto | null = null;

    const nextState = produce(state.comments, (draft) => {
      const match = draft.find(
        (x) =>
          x.questionId === questionId &&
          x.commentType === "ENFORCED" &&
          ((commentFor === "BEHAVIOUR" &&
            x.behaviourId !== null &&
            x.behaviourId === objectId) ||
            (commentFor === "GOAL" &&
              x.goalId !== null &&
              x.goalId === objectId))
      );
      if (match !== undefined) {
        match.comment = newValue;
        match.clientFormId = clientFormId;
        output = { ...match };
      } else {
        output = {
          authorId: userContext.user.id,
          behaviourId: commentFor === "BEHAVIOUR" ? objectId : null,
          goalId: commentFor === "GOAL" ? objectId : null,
          comment: newValue,
          commentType: "ENFORCED",
          questionId: questionId,
          seen: false,
          id: "NEW", // This is how the server knows to insert, rather than update - providing an invalid GUID for the id
          replyToCommentId: null,
          timestamp: dateHelper.getCurrentDateUtc(),
          clientFormId: clientFormId,
        };
        draft.push(output);
      }
    });
    setState({
      comments: nextState,
      formIsDirty: true,
    });

    return output!;
  };

  const handleEnforcedCommentSave = (
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    /** If not provided, will get from state  */
    nonStateValue: SavedCommentDto | null,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    // Check there's a comment to save
    let stateComment = state.comments.find(
      (x) =>
        x.questionId === questionId &&
        x.commentType === "ENFORCED" &&
        (!behaviourId || x.behaviourId === behaviourId) &&
        (!goalId || x.goalId === goalId)
    );
    if (!stateComment && !nonStateValue) return;

    const commentToSave = nonStateValue ? nonStateValue : stateComment;

    const onApiSuccess = (newComment: SavedCommentDto | null | undefined) => {
      // Create a clone of existing comments, except for the existing enforced comment for this question
      const nextState = state.comments.filter(
        (x) =>
          !(
            x.questionId === questionId &&
            x.commentType === "ENFORCED" &&
            (!behaviourId || x.behaviourId === behaviourId) &&
            (!goalId || x.goalId === goalId)
          )
      );
      // Add the saved comment, if there is one
      if (newComment) {
        nextState.push(newComment);
      }
      setState({
        comments: nextState,
        formIsDirty: true,
      });
      successCallback();
    };

    const onApiError = (error: any) => {
      console.log("Error saving enforced comment", error);
      errorCallback();
    };

    apiCollabDocs.updateEnforcedComment(
      answerSetUniqueId!,
      commentToSave!,
      onApiSuccess,
      onApiError
    );
  };

  const onDiscardAnswerSet = () => {
    const onApiSuccess = () => {
      const userIsSubject = state.subjectUser!.userId === userContext.user.id;
      const navigateUrl = userIsSubject
        ? AppRoutes.yourJourney.root
        : AppRoutes.yourPeople.root;
      navigate(navigateUrl);
    };

    const onApiError = (error: any) => {
      console.error("Unable to cancel answer set", error);
    };

    apiCollabDocs.discardCollabDoc(
      answerSetUniqueId!,
      onApiSuccess,
      onApiError
    );
  };

  const onSubmitDocument = (
    proposedStatus: AnswerSetApprovalStatus,
    isInstantlySigningOff?: boolean
  ) => {
    // Validation is handled in the `CollabDoc` component, so this is just handling the actual save
    // First reset any modals
    setShowSubmitSuccessfulAlert(false);
    setShowSubmitErrorAlert(false);
    setShowSigningOffJourneyModal(proposedStatus == "FULLY-APPROVED");

    // The callback for when the API is successfully called (though the response could contain an error)
    const onSubmitSuccess = (data: CollabDocSubmitResponseDto) => {
      if (data.wasSaved) {
        const loggedInUserIsSubjectUser =
          state.subjectUser!.userId === userContext.user.id;
        const otherParticipantFirstName = loggedInUserIsSubjectUser
          ? userContext.user.manager
            ? userContext.user.manager.firstName
            : "Your manager"
          : state.subjectUser!.firstName;
        const successMsg = collabDocAlertHelper.getSuccessMessage(
          data.newStatus!,
          approvalFlow,
          otherParticipantFirstName,
          loggedInUserIsSubjectUser,
          data.isDualPrepMeetingAvailable
        );
        setSubmitSuccessAlertContent(successMsg);
        setShowSubmitSuccessfulAlert(true);
        setShowSigningOffJourneyModal(false);
        setSkipPlanningPopUpState(getDefaultSkipPlanningPopupState());

        // Update the related state values from the response object
        setState({
          isReadOnly: data.formNowReadOnly,
          status: data.newStatus,
        });

        // If the user just signed off their own exit review, boot them out
        if (data.shouldLogOutUser) {
          navigate(AppRoutes.logout);
        }
      } else {
        const errorMsg = collabDocAlertHelper.getErrorMessage(data.errorType);
        setSubmitErrorAlertContent(errorMsg);
        setShowSubmitErrorAlert(true);
      }
    };

    // The callback for when the API can't be called successfully
    const onSubmitError = (error: any) => {
      const errorMsg = collabDocAlertHelper.getErrorMessage(null);
      console.error("Error submitting collab doc", error);
      setSubmitErrorAlertContent(errorMsg);
      setShowSubmitErrorAlert(true);
      setShowSigningOffJourneyModal(false);
    };

    // Call the API
    apiCollabDocs.submitCollabDoc(
      answerSetUniqueId!,
      proposedStatus,
      onSubmitSuccess,
      onSubmitError,
      isInstantlySigningOff
    );
  };

  const closeSubmitSuccessPopup = () => {
    // Hide the alert
    setShowSubmitSuccessfulAlert(false);

    // Reload the page
    loadData();
  };

  // If no AnswerSetUniqueId is supplied, or we are missing key data, don't render anything (yet)
  const hideDocBecauseNoData =
    !answerSetUniqueId ||
    !answerSetDateCreated ||
    state.status === null ||
    state.subjectUser === null;

  // Right hand column comments control
  const commentsControl = (
    <CommentsSideBar
      activeQuestionId={state.activeQuestionId}
      participants={state.participants}
      comments={state.visibleComments}
      onCommentAdd={onCommentAdd}
      onCommentDelete={onCommentDelete}
      onCommentsSeen={onCommentsSeen}
      isReadOnly={state.isReadOnly || state.isLocked}
    />
  );

  return (
    <MainContainer
      rightColumnChildren={commentsControl}
      applySidePadding={false}
    >
      {!showLoadingError && !hideDocBecauseNoData && (
        <CollaborativeDoc
          collabDocTitle={appContext.pageTitle}
          isLoading={state.waitingForApiResult}
          answerSetUniqueId={answerSetUniqueId}
          answerSetDateCreated={answerSetDateCreated}
          discussionExists={state.discussionExists}
          backgroundColour={state.backgroundColour}
          approvalStatus={state.status!}
          lastUpdated={state.lastUpdatedDate}
          loggedInUserId={userContext.user.id}
          subjectUser={state.subjectUser!}
          lastUpdatedByUserId={state.lastUpdatedByUserId}
          isReadOnly={
            state.isReadOnly || state.isLocked || state.hasBeenDelegated
          } // Tag in docIsLocked & docHasBeenDelegated as we want to enforce the isReadOnly behaviour
          isLocked={state.isLocked || state.hasBeenDelegated} // Also pass this in separately so that we can use it to hide elements such as the locked badge agaisnt questions
          hasBeenDelegated={state.hasBeenDelegated}
          formIsDirty={state.formIsDirty}
          isExitJourney={state.isExitJourney}
          comments={state.comments}
          participants={state.participants}
          managerPlanningWasSkipped={state.managerPlanningWasSkipped}
          forms={state.forms}
          formComplexities={state.formComplexities}
          answers={state.answerState}
          flaggedChanges={state.flaggedChanges}
          activeQuestionId={state.activeQuestionId}
          dateLoaded={state.dateLoadedUtc}
          mode={state.mode}
          isInPrepMode={state.isInPrepMode}
          docState={state}
          setDocState={setState}
          onValueChange={onValueChange}
          onValueSave={onValueSave}
          onChangeActiveQuestion={onActiveQuestionChange}
          onCommentAdd={onCommentAdd}
          onCommentDelete={onCommentDelete}
          onCommentsSeen={onCommentsSeen}
          onSubmitDocument={onSubmitDocument}
          tasks={state.taskState}
          onChangeQuestionTasks={onChangeQuestionTasks}
          onEnforcedCommentEdit={handleEnforcedCommentChange}
          onEnforcedCommentSave={handleEnforcedCommentSave}
          onRetryValueSave={onRetryValueSave}
          onRetryTasksSave={onRetryTasksSave}
          onRetryEnforcedCommentSave={onRetryEnforcedCommentSave}
          lockedForSubmissionUntil={state.lockedForSubmissionUntil}
          showUpdateDocBanner={state.showUpdateDocBanner}
          specificFormId={specificFormId}
          warnAboutDifferentFormVersions={
            state.showDifferentFormVersionsWarning
          }
          userCanDiscardAnswerSet={state.userCanDiscardAnswerSet}
          onDiscardAnswerSet={onDiscardAnswerSet}
          journeyName={journeyName}
          approvalFlow={approvalFlow}
          instantSignOffIsDisabled={state.instantSignOffIsDisabled}
          instantlySignedOffByUser={state.instantlySignedOffByUser}
          managerPlanningIsOptional={state.managerPlanningIsOptional}
          handleSkipConfirmationModalClick={handleSkipConfirmationModalClick}
        />
      )}
      {/* Post journey modal */}
      <ModalPopup
        isOpen={!showLoadingError && showPostJourneyModal}
        onOpenChange={setShowPostJourneyModal}
        onPrimaryButtonClick={() => setShowPostJourneyModal(false)}
        primaryButtonText={t("Common.OK")}
        title={null}
        showCloseIcon={false}
      >
        <div className="text-center py-4 mt-2">
          <SuccessIcon darkMode />
        </div>
        <div className="text-center mt-2">
          <h2 className="text-xl font-semibold mb-2">
            {t("Pages.CollaborativeDocument.Controls.PostJourneyModalHeading")}
          </h2>
          <p>
            {t("Pages.CollaborativeDocument.Controls.PostJourneyModalBody")}
          </p>
        </div>
      </ModalPopup>
      {/* Signing off processing modal */}
      <SigningOffTheJourneyPopup
        isOpen={showSigningOffJourneyModal}
        userContext={userContext}
        onOpenChange={setShowSigningOffJourneyModal}
      />
      {/* Submit successful modal */}
      <AlertPopup
        isOpen={showSubmitSuccessfulAlert}
        onOpenChange={setShowSubmitSuccessfulAlert}
        onPrimaryButtonClick={refreshUserAndGoToDashboard}
        primaryButtonText={submitSuccessAlertContent.button!}
        onSecondaryButtonClick={
          state.isReadOnly ? undefined : closeSubmitSuccessPopup
        }
        secondaryButtonText={
          state.isReadOnly
            ? undefined
            : t(
                "Pages.CollaborativeDocument.Alerts.Submit.Success.SecondaryButtonText"
              )
        }
        bodyText={submitSuccessAlertContent.body}
        title={submitSuccessAlertContent.title}
      />
      {/* Submit error modal */}
      <AlertPopup
        isOpen={showSubmitErrorAlert}
        onOpenChange={setShowSubmitErrorAlert}
        onPrimaryButtonClick={navigateToDashboard}
        primaryButtonText={t("Common.GoBackHome")}
        bodyText={submitErrorAlertContent.body}
        title={submitErrorAlertContent.title}
      />
      {/* Comment delete modal */}
      <AlertPopup
        isOpen={showDeleteCommentModal}
        onOpenChange={setShowDeleteCommentModal}
        onPrimaryButtonClick={onConfirmCommentDelete}
        onSecondaryButtonClick={onCancelCommentDelete}
        primaryButtonText={t("Common.ConfirmDelete")}
        secondaryButtonText={t("Common.CancelAction")}
        bodyText={t(
          "Pages.CollaborativeDocument.Controls.CommentDeleteBodyText"
        )}
        title={t("Pages.CollaborativeDocument.Controls.NoteDeleteHeading")}
      />
      {/* Error modals */}
      <AlertPopup
        isOpen={showLoadingError}
        onOpenChange={setShowLoadingError}
        onPrimaryButtonClick={loadData}
        onSecondaryButtonClick={navigateToDashboard}
        primaryButtonText={t("Common.TryAgain")}
        secondaryButtonText={t("Common.GoBackHome")}
        bodyText={t("Errors.500.Body")}
        title={t("Errors.500.Title")}
      />
      <SkipManagerPlanningPopUp
        isOpen={skipPlanningPopUpState.isOpen}
        displayName={skipPlanningPopUpState.subjectUserName!}
        clientFormTitle={skipPlanningPopUpState.clientFormTitle!}
        onOpenChange={triggerSkipConfirmationModalChange}
        onConfirmButtonClick={onConfirmSkipButtonClick}
        onCancelButtonClick={onCancelSkipButtonClick}
        skipPlanningConfirmationTicked={
          skipPlanningPopUpState.skipPlanningConfirmationTicked
        }
        handleSkipPlanningConfirmationTicked={
          handleSkipPlanningConfirmationTicked
        }
        isLoading={skipPlanningPopUpState.isLoading}
      />
    </MainContainer>
  );
}

export default CollaborativeDocumentPage;
