import { t } from "i18next";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { collabDocStatusHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { AnswerSetApprovalStatus } from "../../types/collab-docs";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import { Badge, FormattedDate, ModalPopup, Tooltip } from "../common";
import LastCompletedDateLabel from "./LastCompletedDateLabel";

interface ActiveStandardDocumentStatusBadgeProps {
  answerSetUniqueId: string | null | undefined;
  status: AnswerSetApprovalStatus | null | undefined;
  subjectUserId: number | null | undefined;
  participants: UserBasicDetailsDto[] | null | undefined;
  dateLastCompleted: Date | null | undefined;
  isManagerButBeenDelegated: boolean;
}

const ActiveStandardDocumentStatusBadge = ({
  answerSetUniqueId,
  status,
  subjectUserId,
  participants,
  dateLastCompleted,
  isManagerButBeenDelegated,
}: ActiveStandardDocumentStatusBadgeProps) => {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // If there's no open document, there's no need for a tooltip
  if (!answerSetUniqueId) {
    if (dateLastCompleted) {
      return <LastCompletedDateLabel date={dateLastCompleted} />;
    } else {
      return null;
    }
  }
  // If there is a document but some of the required details are null, signal an issue (but don't error the page, it's not crucial)
  else if (
    !status ||
    !subjectUserId ||
    !participants ||
    participants.length < 2
  ) {
    return (
      <Badge
        backgroundColourClassName="bg-gray-200/60"
        textColourClassName="text-gray-400"
        text="[Unknown]"
      />
    );
  }

  // Get the details and set some conditional class names
  const statusDetails = collabDocStatusHelper.getDisplayDetails(
    status,
    userContext.user.id,
    subjectUserId,
    participants,
    isManagerButBeenDelegated
  );

  const bgColour = statusDetails.shouldHighlight
    ? "bg-blue-500"
    : "bg-emerald-200/30";
  const textColour = statusDetails.shouldHighlight
    ? "text-blue-50"
    : "text-emerald-400";

  const badgeElement = (
    <Badge
      backgroundColourClassName={bgColour}
      textColourClassName={textColour}
      text={statusDetails.statusText}
    />
  );

  return (
    <>
      {badgeElement}
      <div className="hidden md:inline-block">
        <Tooltip
          content={statusDetails.tooltipText}
          triggerElement={
            <span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="1x"
                className="text-gray-500"
              />
            </span>
          }
        />
      </div>
      <button className="md:hidden" onClick={openModal}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          size="1x"
          className="text-gray-500"
        />
      </button>
      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={setModalIsOpen}
        onPrimaryButtonClick={closeModal}
        primaryButtonText={t("Common.OK")}
        title={statusDetails.statusText}
      >
        <p>{statusDetails.tooltipText}</p>
      </ModalPopup>
    </>
  );
};

export default ActiveStandardDocumentStatusBadge;
