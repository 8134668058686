import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import cx from "classnames";
import smartTruncate from "smart-truncate";
import { FormType } from "../../../../types/forms";

interface SelectedDevelopmentItemProps {
  isCustom: boolean;
  name: string;
  itemId: number | null;
  isReadOnly: boolean;
  /** Whether this question is being displayed in a collab doc or a journey. Controls styling (light vs dark bg) */
  formType: FormType;
  usesAdvancedTaskTypes: boolean;
  onRemove(isCustom: boolean, itemId: number | null, name: string): void;
  onAddTask(itemName: string): void;
}

/** An item selected from the L&D list (or a custom item) */
function SelectedDevelopmentItem({
  isCustom,
  name,
  itemId,
  formType,
  isReadOnly,
  usesAdvancedTaskTypes,
  onRemove,
  onAddTask,
}: SelectedDevelopmentItemProps) {
  let containerBgClass = "";

  if (formType === "JOURNEY") {
    containerBgClass = "bg-white/40 text-white";
  } else {
    containerBgClass = "bg-gray-200 text-gray-500";
  }
  return (
    <div
      className={cx(
        containerBgClass,
        "my-1 py-1 px-2 flex flex-row rounded-md text-sm"
      )}
    >
      <div className="flex-grow">{smartTruncate(name, 40)}</div>
      {!isReadOnly && (
        <div className="flex-initial print:hidden">
          {(formType !== "JOURNEY" && !usesAdvancedTaskTypes) && (
            <button
              className="px-2 rounded-md mx-1 bg-white text-gray-500"
              onClick={() => onAddTask(name)}
            >
              {t("Tasks.Popup.TriggerButtons.Add")}
            </button>
          )}
          <button
            className="px-2 mx-1"
            onClick={() => onRemove(isCustom, itemId, name)}
          >
            <FontAwesomeIcon
              icon={faTimes}
              size="xs"
              className="text-white ml-1 hover:text-red-500"
            />
          </button>
        </div>
      )}
    </div>
  );
}

export default SelectedDevelopmentItem;
