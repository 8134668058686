import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Badge, FormattedDate, Tooltip } from "../../common";
import { useNavigate } from "react-router-dom";
import taskTypeIconHelper from "../../../helpers/taskTypeIconHelper";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import AppRoutes from "../../../routes/AppRoutes";
import { t } from "i18next";

interface TaskRelatedTaskBarItemProps {
  itemType: string;
  id: string;
  title: string;
  dueDate: Date;
  isOverdue: boolean;
  clientColour: string;
  selectedTaskType: ClientTaskType | undefined;
}

const TaskRelatedTaskBarItem = ({
  itemType,
  id,
  title,
  dueDate,
  isOverdue,
  clientColour,
  selectedTaskType,
}: TaskRelatedTaskBarItemProps) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(
      AppRoutes.yourJourney.tasks.generatePathWithTaskIdRoute(itemType, id)
    );
  };

  return (
    <div
      onClick={handleItemClick}
      className="bg-white rounded-md cursor-pointer mr-2 border border-gray-200 hover:drop-shadow-sm"
    >
      <div className="flex flex-col">
        <div className=" flex flex-row border-b px-5 pb-2 pt-3">
          <div className="flex flex-col grow">
            <div className="text-gray-500 flex flex-row">
              <div className="flex flex-col">
                <div className="font-semibold line-clamp-2">
                  <div>{title}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-start">
            <div className="justify-self-end">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-gray-300"
              />
            </div>
          </div>
        </div>
        <div className="text-gray-400 font-normal text-xs flex flex-row justify-between px-5 py-2">
          <Tooltip
            content=""
            htmlContent={
              <FormattedDate
                date={dueDate}
                convertFromUtc={true}
                displayMode="DATE-AND-TIME"
              />
            }
            triggerElement={
              <>
                <div className="flex flex-row">
                  {selectedTaskType && (
                    <FontAwesomeIcon
                      icon={taskTypeIconHelper.getTaskTypeIcon(
                        selectedTaskType.iconType
                      )}
                      className="mr-2"
                      style={{ paddingTop: "1px" }}
                      color={clientColour}
                    />
                  )}
                  <FormattedDate date={dueDate} displayMode="DATE-ONLY" />
                </div>
                <div>
                  {isOverdue && (
                    <Badge
                      text={t("Common.Overdue")}
                      backgroundColourClassName="bg-orange-200/75 rounded-full"
                      textColourClassName="text-orange-600"
                      borderRadiusClassName="rounded"
                      marginClassName="ml-2"
                    />
                  )}
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TaskRelatedTaskBarItem;
