import download from "downloadjs";
import ExportableJourneyItem from "../../types/analytics/custom-exports/ExportableJourneyItem";
import { apiClient } from "../apiClient";

class customExportsApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the filter values available to the logged in user */
  getExportableJourneys(
    onSuccess: (data: ExportableJourneyItem[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/custom-exports/exportable-journeys"
    ).then(
      (data) => {
        const filterValues = data as ExportableJourneyItem[];
        onSuccess(filterValues);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Download an export for one or more client sent journeys */
  downloadExport(
    journeyRefs: string[],
    filenameWithoutExtension: string,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/custom-exports/download",
      {
        body: JSON.stringify({ journeyRefs: journeyRefs }),
      },
      "BLOB"
    ).then(
      (blob) => {
        onSuccess();
        download(
          blob,
          `${filenameWithoutExtension}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error) => {
        onError(error);
        console.log(error);
      }
    );
  }
}

export default customExportsApi;
