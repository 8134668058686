import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

// This loader is used as a Suspense wrapper around the entire app
// for the i18next stuff... if there's a delay loading the translations
// then it should display a loader, otherwise it errors
// Note that Suspense works by finding the first <Suspense fallback= /> tag
// up the tree. So if this full screen flash is annoying, just put one above the
// compenent which is loading (which triggers the suspense) and the app will display
// that one instead

function FullScreenLoader() {
  return (
    <div className="flex items-center justify-center h-screen">
      <FontAwesomeIcon
        icon={faSpinnerThird}
        size="6x"
        spin
        className="loader-icon"
      />
    </div>
  );
}

export default FullScreenLoader;
