import { t } from "i18next";
import { Tooltip } from "../common";

interface DualPrepBadgeProps {
  isInPrepMode: boolean | undefined;
  otherParticipantName: string;
}

export const DualPrepBadge = ({
  isInPrepMode,
  otherParticipantName,
}: DualPrepBadgeProps) => {
  let badgeText, tooltipContent;
  if (isInPrepMode) {
    badgeText = t("Pages.CollaborativeDocument.DualPrep.PlanningMode");
    tooltipContent = t("Pages.CollaborativeDocument.DualPrep.PlanningModeTooltip");
  } else {
    badgeText = t("Pages.CollaborativeDocument.DualPrep.MeetingMode");
    tooltipContent = t("Pages.CollaborativeDocument.DualPrep.MeetingModeTooltip");
    tooltipContent = tooltipContent.replace("#OTHER_PARTICIPANT_NAME#", otherParticipantName);
  }

  return (
    <>
      <div className="pb-1">
        <Tooltip
          triggerElement={
            <span className="bg-white/20 text-sm text-white/70 mr-1 print:!text-gray-700 px-2 rounded self-center">
              <small className="text-sm">
                {badgeText}
              </small>
            </span>
          }
          content={tooltipContent}
        />
      </div>
    </>
  );
};

export default DualPrepBadge;
