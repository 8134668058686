import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../state/UserContext";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import TaskCommentDto from "../../types/tasks/TaskCommentDto";
import IndividualComment from "../collab-docs/IndividualComment";

interface TaskCommentsListProps {
  /** The comments list to render */
  comments: TaskCommentDto[] | null;

  /** The form participants, for rendering avatars with */
  participants: UserBasicDetailsDto[];

  /** Whether or not this is readonly - can show "Delete" controls etc if set to false */
  isReadOnly: boolean;

  /** Optional - add css class names to the containing div */
  containerClassNames?: string;

  /** The method to call when the delete button is clicked against a comment */
  onDeleteComment?(
    commentId: number,
    successCallback: () => void,
    errorCallback: () => void
  ): void | undefined;
}

/** A list of comments for the current question */
function TaskCommentsList({
  comments,
  participants,
  isReadOnly,
  onDeleteComment = undefined,
  containerClassNames = "",
}: TaskCommentsListProps) {
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDelete = (commentId: number) => {
    const successCallback = () => {
      setIsDeleting(false);
    };

    const errorCallback = () => {
      setIsDeleting(false);
    };

    if (onDeleteComment) {
      onDeleteComment(commentId, successCallback, errorCallback);
    }
  };

  if (!comments || comments.length === 0) {
    return null;
  }

  return (
    <>
      <div className={containerClassNames}>
        <h2 className="hidden">
          {t("Pages.CollaborativeDocument.Common.Comments")}
        </h2>
        {comments.map((comment, ix) => {
          const commentAuthor = participants.find(
            (x) => x.userId === comment.authorId
          );

          return (
            <IndividualComment
              key={`${ix}_${comment.id}`}
              author={commentAuthor}
              commentId={comment.id}
              comment={comment.comment}
              commentTimestamp={comment.timestamp}
              allowReply={false}
              allowDelete={
                !isReadOnly && commentAuthor?.userId === userContext.user.id
              }
              disableDeleteButton={isDeleting}
              onDeleteClick={() => handleDelete(comment.id)}
              onTargetCommentForReply={() => {}}
            ></IndividualComment>
          );
        })}
      </div>
    </>
  );
}

export default TaskCommentsList;
