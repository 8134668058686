import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  FilterAvailableValues,
  JourneyFilterDefaultValueMode,
} from "../../types/analytics";
import AnalyticsFilterInputs, {
  AnalyticsFilterComparisonProperty,
  AnalyticsPageJourneyFilterRestriction,
} from "../../types/analytics/AnalyticsFilterInputs";
import { AdvancedFilters, DateWindowPicker, JourneyPicker } from "./filters";
import { IDateRange } from "../../types/generic";
import AnalyticsComparePropertyPicker from "./filters/AnalyticsComparePropertyPicker";

interface FilterLabelProps {
  text: string;
  inEditMode: boolean;
  onClick(): void;
}

function FilterLabel({ text, inEditMode, onClick }: FilterLabelProps) {
  return (
    <div className="mb-1 text-gray-500">
      <small
        className={!inEditMode ? "cursor-pointer" : ""}
        onClick={!inEditMode ? onClick : undefined}
      >
        {text}
      </small>
    </div>
  );
}

interface AnalyticsFiltersProps {
  journeyFilterDefaultValueMode: JourneyFilterDefaultValueMode;
  journeyFilterRestriction: AnalyticsPageJourneyFilterRestriction;
  availableValues: FilterAvailableValues;
  selectedValues: AnalyticsFilterInputs;
  onJourneyPickerChange: (selectedJourneyKeys: string[]) => void;
  onLocationPickerChange: (selectedLocationIds: number[]) => void;
  onJobtitlePickerChange: (selectedJobtitleIds: number[]) => void;
  onAppraisalLevelPickerChange: (selectedAppraisalLevelIds: number[]) => void;
  onComparePropertyChange: (
    newValue: AnalyticsFilterComparisonProperty
  ) => void;
  onAllJourneysSelectedChange: (allSelected: boolean) => void;
  onChangeApprovedDataOnly: (newValue: boolean) => void;
  onChangeIncludeLeavers: (newValue: boolean) => void;
  onDateRangeChange: (newValue: IDateRange | null) => void;
  onApplyFilters(): void;
  onResetFilters(): void;
}

function AnalyticsFilters({
  journeyFilterDefaultValueMode,
  journeyFilterRestriction,
  availableValues,
  selectedValues,
  onJourneyPickerChange,
  onLocationPickerChange,
  onJobtitlePickerChange,
  onAppraisalLevelPickerChange,
  onComparePropertyChange,
  onDateRangeChange,
  onAllJourneysSelectedChange,
  onChangeApprovedDataOnly,
  onChangeIncludeLeavers,
  onApplyFilters,
  onResetFilters,
}: AnalyticsFiltersProps) {
  const [isEditingFilters, setIsEditingFilters] = useState<boolean>(false);

  const onBeginEditFilters = () => {
    setIsEditingFilters(true);
  };

  const onApplyClick = () => {
    setIsEditingFilters(false);
    onApplyFilters();
  };

  const onResetClick = () => {
    setIsEditingFilters(false);
    onResetFilters();
  };

  return (
    <div className="mb-2 px-2 py-1 lg:px-4 lg:py-2 bg-gray-100 rounded-md">
      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 justify-start">
        <div>
          <div className="max-w-sm lg:max-w-md">
            <FilterLabel
              text="Journeys"
              inEditMode={isEditingFilters}
              onClick={onBeginEditFilters}
            />
            <div>
              <JourneyPicker
                defaultValueMode={journeyFilterDefaultValueMode}
                restriction={journeyFilterRestriction}
                items={availableValues.journeys}
                onValueChange={onJourneyPickerChange}
                allSelected={selectedValues.allJourneys}
                selectedValues={selectedValues.journeyKeys}
                inEditMode={isEditingFilters}
                onBeginEditMode={onBeginEditFilters}
                onAllSelectedChange={onAllJourneysSelectedChange}
              />
            </div>
          </div>
        </div>
        <div>
          <FilterLabel
            text="Date Range"
            inEditMode={isEditingFilters}
            onClick={onBeginEditFilters}
          />
          <div>
            <DateWindowPicker
              definedRanges={availableValues.definedDateRanges}
              selectedValue={selectedValues.dateRange}
              isEditMode={isEditingFilters}
              onBeginEditMode={onBeginEditFilters}
              onSelectedValueChanged={onDateRangeChange}
            />
          </div>
        </div>

        <div>
          <FilterLabel
            text="Advanced Filters"
            inEditMode={isEditingFilters}
            onClick={onBeginEditFilters}
          />
          <div>
            <AdvancedFilters
              selectedValues={selectedValues}
              inEditMode={isEditingFilters}
              onBeginEditMode={onBeginEditFilters}
              onLocationPickerChange={onLocationPickerChange}
              onAppraisalLevelPickerChange={onAppraisalLevelPickerChange}
              onJobTitlePickerChange={onJobtitlePickerChange}
              onChangeApprovedDataOnly={onChangeApprovedDataOnly}
              onChangeIncludeLeavers={onChangeIncludeLeavers}
            />
          </div>
        </div>
        {journeyFilterDefaultValueMode === "MULTI" && (
          <div>
            <FilterLabel
              text="Comparison"
              inEditMode={isEditingFilters}
              onClick={onBeginEditFilters}
            />
            <div>
              <AnalyticsComparePropertyPicker
                selectedValue={selectedValues.comparisonProperty}
                isEditMode={isEditingFilters}
                onBeginEditMode={onBeginEditFilters}
                onSelectedValueChanged={onComparePropertyChange}
              />
            </div>
          </div>
        )}
      </div>
      {isEditingFilters && (
        <div className="text-left">
          <hr className="my-2" />
          <button className="btn-primary" onClick={onApplyClick}>
            <FontAwesomeIcon icon={faCheck} />
            <span className="ml-1">Apply filters</span>
          </button>
          <button className="ml-4" onClick={onResetClick}>
            Reset
          </button>
        </div>
      )}
    </div>
  );
}

export default AnalyticsFilters;
