import { isDate } from "lodash";
import { TimePickerValue } from "react-time-picker";
import CollabDocCatchUpDetails from "../types/catch-ups/CollabDocCatchUpDetails";
import MeetingFormFields from "../types/catch-ups/MeetingFormFields";
import dateHelper from "./dateHelper";

class CatchUpValidator {
  constructor(subjectFieldRequired: boolean) {
    this.subjectFieldRequired = subjectFieldRequired;
  }

  subjectFieldRequired: boolean;

  dateFieldIsValid = (dateValue: Date | undefined) => {
    return dateValue !== undefined && isDate(dateValue);
  };

  timeFieldIsValid = (timeValue: string | TimePickerValue | undefined) => {
    return timeValue !== undefined && dateHelper.timeIsValid(timeValue);
  };

  subjectFieldIsValid = (selectedClientFormId: number | undefined) => {
    if (!this.subjectFieldRequired) return true;
    return (
      selectedClientFormId !== undefined &&
      !isNaN(selectedClientFormId) &&
      selectedClientFormId > 0
    );
  };

  subjectUserFieldIsValid = (userId: number | undefined) => {
    return userId !== undefined && !isNaN(userId) && userId > 0;
  };

  participantFieldIsValid = (selectedParticipantId: number | undefined) => {
    return (
      selectedParticipantId !== undefined &&
      !isNaN(selectedParticipantId) &&
      selectedParticipantId > 0
    );
  };

  answerSetGuidIdIsValid = (answerSetGuidId: string | undefined) => {
    return answerSetGuidId !== undefined;
  };

  isValidCollabDocCatchUp = (
    scheduledDate: Date | undefined,
    catchUpDetails: CollabDocCatchUpDetails
  ) => {
    return (
      this.dateFieldIsValid(scheduledDate) &&
      this.participantFieldIsValid(catchUpDetails.participantEmployeeId) &&
      this.subjectUserFieldIsValid(catchUpDetails.subjectUserId) &&
      this.answerSetGuidIdIsValid(catchUpDetails.answerSetUniqueId)
    );
  };

  isFullyValid = (formFieldValues: MeetingFormFields) => {
    return (
      this.dateFieldIsValid(formFieldValues.targetDate) &&
      this.subjectFieldIsValid(formFieldValues.subjectClientFormId) &&
      this.participantFieldIsValid(formFieldValues.otherParticipantId) &&
      this.subjectUserFieldIsValid(formFieldValues.subjectUserId)
    );
  };
}

export default CatchUpValidator;
