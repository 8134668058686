import { t } from "i18next";

interface JourneyAnswerSaveIndicatorProps {
  isSaving: boolean;
  errored: boolean;
  didSave: boolean;
  retrySave(): void;
}

/** Display a save error when it fails, with an option to retry the save */
const JourneyAnswerSaveIndicator = ({
  isSaving,
  errored,
  didSave,
  retrySave,
}: JourneyAnswerSaveIndicatorProps) => {
  if (isSaving || didSave || !errored) {
    return null;
  }

  return (
    <div className="text-sm my-4">
      <div className="inline-block rounded-md px-2 bg-red-200 text-red-700">
        {t("Pages.CollaborativeDocument.Common.QuestionDidntSaveError")}
        {"... "}
        <button className="underline cursor-pointer" onClick={retrySave}>
          {t("Common.TryAgain")}
        </button>
      </div>
    </div>
  );
};

export default JourneyAnswerSaveIndicator;
