import { useContext, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import UserContext from "../../../../state/UserContext";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";
import { UserTaskDetail } from "../../../../types/dtos/tasks/advanced-tasks/UserTaskDetail";
import {
  FormattedDate,
  Label,
  ScrollableModalContentTemplate,
  SummaryTable,
  TextArea,
} from "../../../common";
import AdvancedTaskReOpenResult from "../../../../types/dtos/tasks/advanced-tasks/api-response/AdvancedTaskReOpenResult";
import DangerAlert from "../../../alerts/DangerAlert";
import { advancedTaskHelper } from "../../../../helpers";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import LeavingWarningFooter from "../LeavingWarningFooter";

interface AdvancedTaskReOpenViewProps {
  taskType: ClientTaskType | null,
  details: UserTaskDetail;
  userIsTryingToClosePopup: boolean;
  /** Whether or not this view has been launched directly in the popup, or from the main task details tab's "Complete" button  */
  viewLoadedDirectly: boolean;
  goBackToBodyDetails(): void;
  goBackFromWarning(): void;
  onReOpened(): void;
  /** When loaded directly (e.g. from the collab doc) and clicking the go back option, need to do check to show warning */
  onDirectBackReOpened(): void;
  setFormIsDirty(): void;
  proceedAfterWarning(): void;
}

const AdvancedTaskReOpenView = ({
  taskType,
  details,
  userIsTryingToClosePopup,
  viewLoadedDirectly,
  goBackToBodyDetails,
  goBackFromWarning,
  onReOpened,
  onDirectBackReOpened,
  setFormIsDirty,
  proceedAfterWarning,
}: AdvancedTaskReOpenViewProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);

  // - State
  const [reOpenComment, setReOpenComment] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState<string | null>(null);

  // - Refs
  const commentFormInputRef = useRef<HTMLTextAreaElement>(null);

  // - Effects
  useEffectOnce(() => {
    // Focus the text area
    if (commentFormInputRef) {
      commentFormInputRef.current?.focus();
    }
  });

  const matchingTaskType = userContext.user.client.taskTypes.find(
    (tt) => tt.id === details.taskTypeId
  );

  // Don't render anything until if the task type is invalid
  if (!matchingTaskType) return null;

  const onGoBackClick = () => {
    // Either go back to the main task details tab, or close the popup (as appropriate)
    if (viewLoadedDirectly) {
      onDirectBackReOpened();
    } else {
      goBackToBodyDetails();
    }
  };

  const singularTaskTypeName = advancedTaskHelper.ToLowerCase(
    matchingTaskType.singularNameTranslationKeyIdentifier
  );

  const onTextBoxAnswerChange = (comment: string) => {
    setReOpenComment(comment);
    setFormIsDirty();
  }

  const onReOpenUserTask = () => {
    if (!details.userTaskId) return;

    const onError = (error: any) => {
      setIsSaving(false);
      setSaveErrorMessage(t("TaskType.Popup.ReOpenView.Errors.Generic"));
      console.error("Unable to re-open task", error);
    };

    const onSuccess = (data: AdvancedTaskReOpenResult) => {
      setIsSaving(false);
      if (data.wasSuccessful) {
        onReOpened();
      } else if (data.failedOverallMaxLimit) {
        const taskLimitReachedWarning = t(
          "TaskType.Popup.ReOpenView.Errors.FailsOverallLimit",
          { taskType: singularTaskTypeName }
        );
        setSaveErrorMessage(taskLimitReachedWarning);
      } else if (data.failedCategoryMaxLimit) {
        const taskCategoryLimitReachedWarning = t(
          "TaskType.Popup.ReOpenView.Errors.FailsCategoryLimit",
          { taskType: singularTaskTypeName }
        );
        setSaveErrorMessage(taskCategoryLimitReachedWarning);
      } else {
        onError(data.errorMessage);
      }
    };

    // Call the API to re-open the task
    setIsSaving(true);
    setSaveErrorMessage(null);
    tasksApi.reOpenTask(details.userTaskId, reOpenComment, onSuccess, onError);
  };

  const inputId = "txt-re-open-comment";

  const bodyContent = userIsTryingToClosePopup ? (
    // Reopen Warning Content
    <DangerAlert
      prefix=""
      message={t("TaskType.Popup.Warning.ChangesMaybeLost")}
    />
  ) : (
    // Reopen Form Content
    <>
      {saveErrorMessage && (
        <DangerAlert message={saveErrorMessage} prefix={null} />
      )}
      <p className="mb-2">
        {t("TaskType.Popup.ReOpenView.Intro", {
          taskType: singularTaskTypeName,
        })}
      </p>
      <SummaryTable
        containerClassNames="mb-2"
        rows={[
          {
            name: taskType?.titleTranslationKeyIdentifier != null
              ? t(taskType.titleTranslationKeyIdentifier)
              : t("TaskType.Popup.Labels.Description"),
            value: details.title,
          },
          {
            name: t("TaskType.Popup.Labels.DueDate"),
            value: (
              <FormattedDate
                date={details.targetDate}
                displayMode="DATE-ONLY"
              />
            ),
          },
        ]}
      />

      <div className="mt-4">
        <Label htmlFor={inputId} className="font-medium">
          {t("TaskType.Popup.ReOpenView.TextAreaLabel")}
        </Label>
        <TextArea
          inputId={inputId}
          value={reOpenComment}
          onChange={onTextBoxAnswerChange}
          minRows={2}
          placeholder="Add comment..."
          className="block mt-2 p-2 w-full bg-gray-100 border-0"
          inputRef={commentFormInputRef}
        />
      </div>
    </>
  );

  const footerContent = userIsTryingToClosePopup ? (
    // Reopen Footer Content
    <LeavingWarningFooter
      goBackFromWarning={goBackFromWarning}
      proceedAfterWarning={proceedAfterWarning}
    />
  ) : (
    // Reopen Footer Content
    <div className="flex flex-row justify-end gap-4">
      <button onClick={onGoBackClick} className="hover:underline">
        {t("TaskType.Popup.Buttons.GoBack")}
      </button>
      <button
        onClick={onReOpenUserTask}
        className="btn-primary"
        disabled={
          !reOpenComment || reOpenComment.trim().length === 0 || isSaving
        }
      >
        {t("TaskType.Popup.Buttons.Confirm")}
      </button>
    </div>
  );

  return (
    <ScrollableModalContentTemplate footer={footerContent}>
      <div className="pt-2">{bodyContent}</div>
    </ScrollableModalContentTemplate>
  );
};

export default AdvancedTaskReOpenView;
