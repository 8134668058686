import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertPopup, Avatar, FormattedDate, InfoBanner } from "../common";
import { userDetailsHelper } from "../../helpers";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import { LockedBanner } from "./LockedBanner";
import DelegatedBanner from "./DelegatedBanner";
import {
  AnswerSetApprovalStatus,
  AnswerSetMode,
} from "../../types/collab-docs";
import DualPrepBadge from "./DualPrepBadge";
import CollabDocPrintPdfButton from "./CollabDocPrintPdfButton";
import UpdateDocBanner from "./UpdateDocBanner";
import { useAuth } from "react-oidc-context";

interface CollabDocHeaderProps {
  participants: UserBasicDetailsDto[];
  lastUpdated: Date;
  lastUpdatedByUserId: number;
  isReadOnly: boolean;
  isLocked: boolean;
  hasBeenDelegated: boolean;
  formBackgroundColour: string;
  subjectUser: BaseUserDetailsDto;
  loggedInUserId: number;
  mode: AnswerSetMode;
  managerPlanningWasSkipped: boolean;
  isInPrepMode: boolean;
  approvalStatus: AnswerSetApprovalStatus;
  discussionExists: boolean;
  isPrinting: boolean; // Potentially obsolete. Not used now the print is PDF not HTML. Remove after Sept 2024 if not used.
  /** Whether or not to display the banner which gives the user the option to start a new collab doc based on the current completed one */
  showUpdateDocBanner: boolean;
  collabDocTitle: string;
  answerSetUniqueId: string;
  /** If viewing one specific form from a summary tab, this should have a value */
  specificFormId: number | undefined;
  /** Whether or not to display a warning that not all questions may have been cloned */
  warnAboutDifferentFormVersions: boolean;
  /** Whether or not to allow the user to discard this answer set */
  userCanDiscardAnswerSet: boolean;
  instantlySignedOffByUser: BaseUserDetailsDto | null;
  managerPlanningIsOptional: boolean | null;
  onTogglePrinting: (doPrint: boolean) => void; // Potentially obsolete. Not used now the print is PDF not HTML. Remove after Sept 2024 if not used.
  onDiscardAnswerSet: () => void;
  handleSkipConfirmationModalClick: () => void;
}

function CollabDocHeader({
  participants,
  lastUpdated,
  lastUpdatedByUserId,
  isReadOnly,
  isLocked,
  hasBeenDelegated,
  formBackgroundColour,
  subjectUser,
  loggedInUserId,
  mode,
  managerPlanningWasSkipped,
  isInPrepMode,
  approvalStatus,
  discussionExists,
  showUpdateDocBanner,
  instantlySignedOffByUser,
  collabDocTitle,
  answerSetUniqueId,
  specificFormId,
  warnAboutDifferentFormVersions,
  userCanDiscardAnswerSet,
  managerPlanningIsOptional,
  onTogglePrinting,
  onDiscardAnswerSet,
  handleSkipConfirmationModalClick,
}: CollabDocHeaderProps) {
  // Constants
  const { t } = useTranslation();
  const auth = useAuth();

  // State
  const [showDiscardAnswerSetModal, setShowDiscardAnswerSetModal] =
    useState<boolean>(false);

  // Show the name of the user who last updated the form
  // (Take the firstname only if the names are unique, otherwise show the full name)
  let userLastUpdated = userDetailsHelper.getDisplayName(
    lastUpdatedByUserId,
    participants
  );

  const nonSubjectUserName = userDetailsHelper.getOtherUsersDisplayName(
    subjectUser.userId,
    participants
  );
  const otherParticipantName = userDetailsHelper.getOtherUsersDisplayName(
    loggedInUserId,
    participants
  );
  const readOnlyBannerTextIdentifier =
    approvalStatus === "FULLY-APPROVED"
      ? "Pages.CollaborativeDocument.Heading.ReadOnly"
      : "Pages.CollaborativeDocument.Heading.InReadOnlyMode";

  // Show the instructional text if there is more than one participant and the approval status isn't only
  // and its not got a discussion tied to it
  const showInstructionText =
    participants.length > 1 &&
    approvalStatus !== "FULLY-APPROVED" &&
    approvalStatus !== "NO-APPROVAL-NECESSARY" &&
    !discussionExists;
  const isLoggedInUserTheSubject = loggedInUserId == subjectUser.userId;
  const instructionalText = isLoggedInUserTheSubject
    ? t("CollabDoc.Context.Employee").replaceAll(
        "#OTHER_PARTICIPANT_NAME#",
        otherParticipantName
      )
    : t("CollabDoc.Context.Manager").replaceAll(
        "#SUBJECT_NAME#",
        subjectUser.firstName
      );

  return (
    <div
      className="p-2 lg:px-4 pb-20 lg:pt-1"
      style={{ backgroundColor: `#${formBackgroundColour}` }}
    >
      <div className="flex flex-col gap-10">
        <div className="text-center md:text-right mt-2 hidden lg:block print:block">
          <span className="text-sm text-white/70 mr-1 print:!text-gray-700">
            {t("Pages.CollaborativeDocument.Heading.Participants")}:
          </span>
          <div className="-space-x-1 inline">
            {participants.map((participant, pix) => (
              <Avatar
                key={`p${pix}`}
                userFullName={participant.fullName}
                userInitials={participant.initials}
                size={8}
                imageUrl={
                  participant.profileImageUrl
                    ? participant.profileImageUrl
                    : undefined
                }
              />
            ))}{" "}
          </div>
        </div>
      </div>
      <div>
        {showInstructionText && (
          <div className="py-1 text-white/80 print:hidden px-3 mb-2">
            <h3 className="!text-white/80 !mb-1">
              {t("CollabDoc.Context.Title")}
            </h3>
            <hr className="opacity-10 mb-3"></hr>
            {instructionalText}
          </div>
        )}
        <div className="flex flex-row px-3">
          <div className="flex flex-col w-1/2">
            {userLastUpdated && (
              <div className="hidden lg:block md:basis-1/2 text-xs text-white/60 leading-8 text-center md:text-left print:block print:!text-gray-700">
                {t("Common.UpdatedBySentence.LastUpdated")}{" "}
                <FormattedDate
                  date={lastUpdated}
                  displayMode="FROM-NOW"
                  convertFromUtc
                />{" "}
                {t("Common.UpdatedBySentence.UpdatedBy")} {userLastUpdated}
              </div>
            )}
            {!userLastUpdated && <span></span>}
          </div>
          <div className="w-1/2">
            <CollabDocPrintPdfButton
              answerSetUniqueId={answerSetUniqueId}
              answerSetTitle={collabDocTitle}
            />
          </div>
        </div>
      </div>
      {!showUpdateDocBanner && (
        <>
          {!isReadOnly && userCanDiscardAnswerSet && (
            <div className="mb-2 text-right px-3">
              <button
                className="px-2 py-0.5 disabled:cursor-not-allowed font-semibold bg-white rounded-sm hover:text-red-500 text-sm"
                onClick={() => setShowDiscardAnswerSetModal(true)}
              >
                Discard
              </button>
              <AlertPopup
                isOpen={showDiscardAnswerSetModal}
                onOpenChange={setShowDiscardAnswerSetModal}
                onPrimaryButtonClick={() => setShowDiscardAnswerSetModal(false)}
                primaryButtonText="No, keep what I have"
                onSecondaryButtonClick={onDiscardAnswerSet}
                secondaryButtonText="Yes, discard and delete my updates"
                bodyText="Any updates you've added since your last completed document will be discarded and cannot be retrieved."
                title="Are you sure?"
              />
            </div>
          )}
          {!isReadOnly &&
            warnAboutDifferentFormVersions &&
            approvalStatus === "INITIAL-SAVE-FORM-INCOMPLETE" && (
              <InfoBanner text="Some of the questions in this form have changed since you last answered it, so there may be some questions which are blank and will require a new answer" />
            )}
          {isReadOnly && !isLocked && !hasBeenDelegated && (
            <>
              <InfoBanner text={t(readOnlyBannerTextIdentifier)} />
            </>
          )}
          {isLocked &&
            !hasBeenDelegated &&
            approvalStatus !== "FULLY-APPROVED" && (
              <LockedBanner name={otherParticipantName} />
            )}
        </>
      )}
      {showUpdateDocBanner && (
        <UpdateDocBanner
          subjectUser={subjectUser}
          loggedInUserId={loggedInUserId}
          formTitle={collabDocTitle}
          clientFormId={specificFormId!}
          docGuid={answerSetUniqueId}
          isDualPrep={mode === "DUAL-PREP"}
        />
      )}
      {instantlySignedOffByUser && (
        <InfoBanner
          className="text-sm"
          text={t(
            "Pages.CollabDocs.Banner.ApprovedAndFullyCompletedBy"
          ).replace("#COMPLETED_BY_NAME#", instantlySignedOffByUser.fullName)}
        />
      )}
      {hasBeenDelegated && approvalStatus !== "FULLY-APPROVED" && (
        <DelegatedBanner otherUserName={nonSubjectUserName} />
      )}
      {mode == "DUAL-PREP" && !isReadOnly && (
        <div className="flex flex-row justify-between px-3 mb-2">
          <DualPrepBadge
            otherParticipantName={otherParticipantName}
            isInPrepMode={isInPrepMode}
          />
          {mode == "DUAL-PREP" &&
            isInPrepMode == false &&
            managerPlanningWasSkipped && (
              <div className="bg-white/20 text-sm text-white/70 mr-1 print:!text-gray-700 px-2 rounded self-center">
                No planning responses recorded by {nonSubjectUserName}
              </div>
            )}
          {isInPrepMode &&
            loggedInUserId != subjectUser.userId &&
            managerPlanningIsOptional &&
            !isReadOnly && (
              <div
                role="button"
                className="btn-secondary bg-white self-center border-none text-sm !rounded-sm"
                onClick={() => handleSkipConfirmationModalClick()}
              >
                Skip planning
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default memo(CollabDocHeader);
