import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import InfoAlert from "../alerts/InfoAlert";
import { WindowDto } from "../../types/dtos/admin/WindowDto";
import Label from "./Label";
import FormattedDate from "./FormattedDate";
import DateInput from "./DateInput";
import { useState } from "react";
import AlertPopup from "./AlertPopup";
import ModalPopup from "./ModalPopup";
import { dateHelper, journeyWindowHelper } from "../../helpers";
import DangerAlert from "../alerts/DangerAlert";
import IconButton from "./IconButton";
import WarningAlert from "../alerts/WarningAlert";
import WindowDatabaseStatusEnum from "../../types/admin/WindowDatabaseStatus";

interface WindowPickerProps {
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The button events */
  onAdd(startDate: Date | null, endDate: Date | null): void;
  onEdit(window: WindowDto): void;
  onDelete(window: WindowDto | undefined): void;
  windows?: WindowDto[];
}

const WindowPicker = ({
  inputId,
  onAdd,
  onEdit,
  onDelete,
  windows,
}: WindowPickerProps) => {
  const filteredWindows = windows?.filter(function (w) {
    return w.status !== WindowDatabaseStatusEnum.ToDelete;
  });

  const { t } = useTranslation();
  const todaysDate = dateHelper.getCurrentDateUtc();
  const startDateInput = inputId + "_start-date";
  const endDateInput = inputId + "_end-date";
  const [showAlterationWarningAlert, setShowAlterationWarningAlert] =
    useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showValidationError, setShowValidationError] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const [windowToEdit, setWindowToEdit] = useState<WindowDto>();
  const [editStartDate, setEditStartDate] = useState<Date | null>(null);
  const [editEndDate, setEditEndDate] = useState<Date | null>(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [showEditValidationError, setShowEditValidationError] = useState(false);
  const [editValidationMessage, setEditValidationMessage] = useState("");

  const [pendingWindowForDeletion, setPendingWindowForDeletion] =
    useState<WindowDto>();
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);

  const handleAddWindowClick = () => {
    if (startDate !== null && endDate !== null) {
      const validationStatus =
        journeyWindowHelper.validateStartAndEndDatesForWindow(
          startDate,
          endDate,
          filteredWindows
        );

      switch (validationStatus) {
        case "END-DATE_BEFORE_START-DATE":
          setShowValidationError(true);
          setValidationMessage(
            t("Common.Validation.Errors.WindowStartDateIsBeforeEndDate")
          );
          break;
        case "IN-PAST":
          setShowValidationError(true);
          setValidationMessage(t("Common.Validation.Errors.WindowIsInThePast"));
          break;
        case "OVERLAPS":
          setShowValidationError(true);
          setValidationMessage(t("Common.Validation.Errors.WindowOverlaps"));
          break;
        case "START-DATE-IS-TODAY":
          setShowValidationError(true);
          setValidationMessage(
            t("Common.Validation.Errors.WindowStartIsToday")
          );
          break;
        case "OK":
          setShowAlterationWarningAlert(true);
          onAdd(startDate, endDate);

          // Reset
          setShowValidationError(false);
          setValidationMessage("");
          setStartDate(null);
          setEndDate(null);
          break;
      }
    }
  };

  const loadEditModal = (window: WindowDto) => {
    setWindowToEdit(window);

    if (window.startDate && window.endDate) {
      setEditStartDate(dateHelper.convertDateToUtc(window.startDate));
      setEditEndDate(dateHelper.convertDateToUtc(window.endDate));
    }

    setEditModalIsOpen(true);
  };

  const handleSaveEditClick = () => {
    if (editStartDate !== null && editEndDate !== null) {
      const existingWindowsNotContainingEditingOne = filteredWindows?.filter(
        function (w) {
          return w !== windowToEdit;
        }
      );
      let validationStatus =
        journeyWindowHelper.validateStartAndEndDatesForWindow(
          editStartDate,
          editEndDate,
          existingWindowsNotContainingEditingOne
        );

      // We want to by-pass the 'IN-PAST' validation for active windows so that
      // the end date can be extended by the user if needed.
      if (validationStatus === "IN-PAST" && windowToEdit?.isActive) {
        validationStatus = "OK";
      }

      switch (validationStatus) {
        case "END-DATE_BEFORE_START-DATE":
          setShowEditValidationError(true);
          setEditValidationMessage(
            t("Common.Validation.Errors.WindowStartDateIsBeforeEndDate")
          );
          break;
        case "IN-PAST":
          setShowEditValidationError(true);
          setEditValidationMessage(
            t("Common.Validation.Errors.WindowIsInThePast")
          );
          break;
        case "OVERLAPS":
          setShowEditValidationError(true);
          setEditValidationMessage(
            t("Common.Validation.Errors.WindowOverlaps")
          );
          break;
        case "START-DATE-IS-TODAY":
          setShowEditValidationError(true);
          setEditValidationMessage(
            t("Common.Validation.Errors.WindowStartIsToday")
          );
          break;
        case "OK":
          if (windowToEdit !== undefined) {
            setShowAlterationWarningAlert(true);
            windowToEdit.startDate = new Date(editStartDate);
            windowToEdit.endDate = new Date(editEndDate);
            onEdit(windowToEdit);
            setEditModalIsOpen(false);

            // Reset
            setShowEditValidationError(false);
            setEditValidationMessage("");
          }

          break;
      }
    }
  };

  const handleSaveCancelClick = () => {
    setEditModalIsOpen(false);
    setShowEditValidationError(false);
    setEditValidationMessage("");
  };

  const handleDeleteWindowClick = (window: WindowDto) => {
    setPendingWindowForDeletion(window);
    setDeleteAlertIsOpen(true);
  };

  const handleConfirmDeleteWindowClick = () => {
    setShowAlterationWarningAlert(true);
    onDelete(pendingWindowForDeletion);

    // Reset
    setDeleteAlertIsOpen(false);
    setPendingWindowForDeletion(undefined);
  };

  return (
    <>
      <div className="pt-2">
        {showValidationError && (
          <DangerAlert prefix={""} message={validationMessage} />
        )}
        <div>
          {filteredWindows && filteredWindows.length > 0 && (
            <>
              {showAlterationWarningAlert && (
                <WarningAlert
                  prefix=""
                  message={t(
                    "Common.Validation.Warning.RememberToSaveAfterWindowAlterations"
                  )}
                />
              )}
              <Label text={t("Pages.Admin.Common.OpenWindowBetween")} />:
              <div className="flex flex-col">
                <div className="flex flex-row uppercase primary-text justify-between px-4 mt-4 bg-gray-100 rounded-t-md">
                  <span className="text-xs uppercase w-40 py-2">
                    {t("Common.StartDate")}
                  </span>
                  <span className="text-xs uppercase w-40 py-2">
                    {t("Common.EndDate")}
                  </span>
                  <span className="text-xs uppercase w-10 py-2 text-right">
                    &nbsp;
                  </span>
                  <span className="text-xs uppercase w-10 py-2 text-right">
                    &nbsp;
                  </span>
                </div>
              </div>
              {filteredWindows.map((window) => {
                return (
                  <div
                    key={"window_" + window.id + "_endDate_" + window.endDate}
                  >
                    <div className="flex flex-row text-gray-600 font-medium justify-between px-4 bg-gray-50 rounded-b-md">
                      <span className="text-sm w-40 py-3">
                        {window.startDate && (
                          <div>
                            {window.isActive && (
                              <span className="text-xs rounded bg-green-700 border border-green-800 text-white p-1 mr-1 uppercase">
                                {t("Common.Active")}
                              </span>
                            )}
                            {window.status ===
                              WindowDatabaseStatusEnum.ToAdd && (
                              <FontAwesomeIcon
                                icon={faCircle}
                                size="xs"
                                className="text-orange-500 pr-2"
                                title={t(
                                  "Pages.Admin.Common.SaveBeforeWindowAdded"
                                )}
                              />
                            )}
                            {window.status ===
                              WindowDatabaseStatusEnum.ToUpdate && (
                              <FontAwesomeIcon
                                icon={faCircle}
                                size="xs"
                                className="text-orange-500 pr-2"
                                title={t(
                                  "Pages.Admin.Common.SaveBeforeWindowUpdated"
                                )}
                              />
                            )}
                            <FormattedDate
                              date={window.startDate}
                              displayMode="DATE-ONLY"
                            />
                          </div>
                        )}
                      </span>
                      <span className="text-sm w-40 py-3">
                        {window.endDate && (
                          <FormattedDate
                            date={window.endDate}
                            displayMode="DATE-ONLY"
                          />
                        )}
                      </span>
                      {/* Want to remove the delete button and put the edit icon in it's plae 
                          for an active window */}
                      {window.isActive && (
                        <>
                          <span className="text-sm w-10 py-3 md:text-right">
                            &nbsp;
                          </span>
                          <span className="text-sm w-10 py-3">
                            <IconButton
                              buttonType="EDIT"
                              displayMode="ICON-ONLY"
                              iconClassName="text-yellow-700"
                              onClick={() => {
                                loadEditModal(window);
                              }}
                            />
                          </span>
                        </>
                      )}
                      {!window.isActive && (
                        <>
                          <span className="text-sm w-10 py-3 md:text-right">
                            <IconButton
                              buttonType="EDIT"
                              displayMode="ICON-ONLY"
                              iconClassName="text-yellow-700"
                              onClick={() => {
                                loadEditModal(window);
                              }}
                            />
                          </span>
                          <span className="text-sm w-10 py-3">
                            {window.startDate && (
                              <IconButton
                                buttonType="DELETE"
                                displayMode="ICON-ONLY"
                                iconClassName="text-rose-800"
                                onClick={() => {
                                  handleDeleteWindowClick(window);
                                }}
                              />
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              {filteredWindows && filteredWindows.length > 0 && (
                <div className="pt-3">
                  {filteredWindows[0].startDate &&
                    todaysDate <
                      dateHelper.convertDateToUtc(
                        filteredWindows[0].startDate
                      ) && (
                      <InfoAlert
                        prefix={""}
                        message={t(
                          "Pages.Admin.Common.PrefixTextForClosedWindowedJourneyDate"
                        )}
                        optionalDate={filteredWindows[0].startDate}
                      />
                    )}
                  {filteredWindows[0].endDate &&
                    todaysDate >
                      dateHelper.convertDateToUtc(
                        filteredWindows[0].endDate
                      ) && (
                      <InfoAlert
                        prefix={""}
                        message={t(
                          "Pages.Admin.Common.PrefixTextForOpenWindowedJourneyDate"
                        )}
                        optionalDate={filteredWindows[0].endDate}
                      />
                    )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="pt-4">
          <Label text={t("Pages.Admin.Common.AddNewWindow")} />
          <div className="flex">
            <div className="w-5/12">
              <DateInput
                onChange={setStartDate}
                inputId={startDateInput}
                value={startDate}
                placeholder={t("Common.StartDate")}
                showTimeSelect={false}
              />
            </div>
            <div className="w-1/12">&nbsp;</div>
            <div className="w-5/12">
              <DateInput
                onChange={setEndDate}
                inputId={endDateInput}
                value={endDate}
                placeholder={t("Common.EndDate")}
                showTimeSelect={false}
              />
            </div>
            <div className="w-1/12 text-center pt-2">
              <IconButton
                buttonType="ADD"
                displayMode="ICON-ONLY"
                iconSize="lg"
                onClick={handleAddWindowClick}
                isReadOnly={startDate == null || endDate == null}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertPopup
        isOpen={deleteAlertIsOpen}
        onOpenChange={setDeleteAlertIsOpen}
        onPrimaryButtonClick={() => handleConfirmDeleteWindowClick()}
        onSecondaryButtonClick={() => setDeleteAlertIsOpen(false)}
        primaryButtonText={t("Common.Delete")}
        secondaryButtonText={t("Common.Cancel")}
        bodyText={t("Pages.Admin.Common.IfChangeMind")}
        title={t("Pages.Admin.Common.AreYouSureDeleteWindow")}
      />
      <ModalPopup
        isOpen={editModalIsOpen}
        onOpenChange={setEditModalIsOpen}
        onPrimaryButtonClick={handleSaveEditClick}
        onSecondaryButtonClick={handleSaveCancelClick}
        primaryButtonText={t("Common.Save")}
        secondaryButtonText={t("Common.Cancel")}
        title={t("Pages.Admin.Common.EditWindow")}
      >
        {showEditValidationError && (
          <DangerAlert prefix={""} message={editValidationMessage} />
        )}
        <div>
          <Label text={t("Common.StartDate")} />
          {editStartDate && (
            <DateInput
              onChange={setEditStartDate}
              inputId="edit-start-date-picker"
              value={editStartDate}
              isReadOnly={editStartDate < todaysDate}
              showTimeSelect={false}
            />
          )}
        </div>
        <div>
          <Label text={t("Common.EndDate")} />
          <DateInput
            onChange={setEditEndDate}
            inputId="edit-end-date-picker"
            value={editEndDate}
            showTimeSelect={false}
          />
        </div>
      </ModalPopup>
    </>
  );
};

export default WindowPicker;
