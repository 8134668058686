import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment as CommentsReadIcon,
  faCommentPlus as AddCommentIcon,
} from "@fortawesome/pro-regular-svg-icons";
import { faCommentExclamation as CommentsUnseenIcon } from "@fortawesome/pro-regular-svg-icons";
import { t } from "i18next";

interface CommentIndicatorProps {
  /** Any additional class names, optional */
  className?: string;
  /** The total number of comments */
  totalComments: number;
  /** The count of unseen comments */
  unseenComments: number;
  /** Whether or not the user can add a comment (display the add button or not) */
  allowAddComment: boolean;
  /** What to do when the indicator is clicked */
  handleClick(): void;
}

function CommentIndicator({
  totalComments,
  unseenComments,
  allowAddComment,
  handleClick,
  className = "",
}: CommentIndicatorProps) {
  if (totalComments === 0 && !allowAddComment) {
    return null;
  }

  // Highlight the icon if there are any unseen comments
  let statusClassName = "text-gray-500 hover:text-gray-600";
  if (unseenComments > 0) {
    statusClassName = "text-emerald-500 hover:text-emerald-600";
  }

  const icon =
    totalComments === 0
      ? AddCommentIcon
      : unseenComments > 0
      ? CommentsUnseenIcon
      : CommentsReadIcon;

  return (
    <button
      className={cx(
        "ml-2 relative text-center bg-white shadow-lg-around border rounded px-2 py-1 print:hidden",
        className
      )}
      title={`${t(
        "Pages.CollaborativeDocument.Common.Comments"
      )}: ${totalComments}`}
      onClick={handleClick}
      tabIndex={-1}
    >
      <span className="fa-layers fa-fw text-center">
        <FontAwesomeIcon icon={icon} size="lg" className={statusClassName} />
        {totalComments > 0 && unseenComments === 0 && (
          <span
            className={cx(
              "fa-layers-text font-medium text-gray-500 hover:text-gray-600",
              totalComments >= 10 ? "text-xxs" : "text-xs"
            )}
          >
            {totalComments}
          </span>
        )}
      </span>
    </button>
  );
}

export default CommentIndicator;
