import { FormattedDate } from "../common";

interface JourneyDeadlineMessageProps {
  deadline: Date | null;
  classnames?: string | undefined;
}

export const JourneyDeadlineMessage = ({
  deadline,
  classnames = undefined,
}: JourneyDeadlineMessageProps) => {
  if (!deadline) return null;

  const dateObject = new Date(deadline);
  return (
    <span className={classnames}>
      Please complete this by{" "}
      <FormattedDate date={dateObject} displayMode="DATE-ONLY" />
    </span>
  );
};
