import { useTranslation } from "react-i18next";
import ClientFormCard from "./ClientFormCard";
import { ClientFormGroupDto } from "../../../types/dtos/admin/JourneyConfigurationTabApiResponseDto";

interface ClientFormsGridProps {
  clientFormGroups: ClientFormGroupDto[];
  isLoading: boolean;
}

function ClientFormsGrid({
  clientFormGroups,
  isLoading
}: ClientFormsGridProps) {
  const { t } = useTranslation();

  const getModeTranslationKey = (mode: string): string => {
    switch (mode) {
      case "HIDDEN-ON-DASHBOARD":
        return "Journey.Mode.HiddenOnDashboard";
      case "AUTOMATED":
        return "Journey.Mode.Automated";
      case "WINDOWED":
        return "Journey.Mode.Windowed";
      case "MANUAL-ONLY":
        return "Journey.Mode.ManualOnly";
      case "INDUCTION":
        return "Journey.Mode.Induction";
      case "EXIT":
        return "Journey.Mode.ExitQuestionnaire";
      default:
        return "Journey.Mode.Unknown";
    }
  }

  return (
    <>
      {isLoading && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
        </div>
      )}
      {!isLoading && clientFormGroups && clientFormGroups.length > 0 && (
        <div>
          {clientFormGroups.map((cfg, index) => {
            const clientForms = cfg.clientForms.map((cf) => {
              return (
                <ClientFormCard
                  key={`cfc_${cf.clientFormId}`}
                  clientForm={cf}
                />
              );
            });

            return (
              <div key={`cfg_${index}`} className="pt-2">
                <h3>{t(getModeTranslationKey(cfg.mode))}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {clientForms}
                </div>
                <hr className="mt-5"/>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ClientFormsGrid;
