import UserwalkthroughType from "../../types/generic/UserWalkthroughType";
import { t } from "i18next";
import { ReactComponent as LaunchWalkthrough } from "../../images/launchWalkthrough.svg";
import { ModalPopup } from "../common";
import { useState } from "react";
import DeclinedWalkthrough from "./DeclinedWalkthrough";
import { useCheckMobileScreen } from "../../hooks";

interface UserWalkthroughIntroPopUpProps {
  walkthroughType: UserwalkthroughType;
  isOpen: boolean;
  onOpenChange(isOpen: boolean): void;
  runWalkthrough(run: boolean): void;
  onWalkthroughFinished(): void;
}

function UserWalkthroughIntroPopUp({
  walkthroughType,
  isOpen,
  onOpenChange,
  runWalkthrough,
  onWalkthroughFinished,
}: UserWalkthroughIntroPopUpProps) {
  const isMobile = useCheckMobileScreen();
  const [runDeclinedWalkthrough, setRunDeclinedWalkthrough] =
    useState<boolean>(false);

  const confirmStartWalkthrough = () => {
    onOpenChange(false);
    runWalkthrough(true);
  };

  const confirmStopWalkthrough = () => {
    onOpenChange(false);
    runWalkthrough(false);
    setRunDeclinedWalkthrough(true);
  };

  const myDashboardModalContent = (
    <div className="text-center">
      <p className="text-2xl font-semibold primary-text mb-2 heading">
        {t("Walkthrough.MyDashboard.Title")}
      </p>
      <p className="p-4">{t("Walkthrough.MyDashboard.Intro.Text")}</p>
      <p className="text-gray-400">
        {t("Walkthrough.MyDashboard.WouldYouLikeATour")}
      </p>
    </div>
  );

  const managerDashboardModalContent = (
    <div className="text-center">
      <p className="text-2xl font-semibold primary-text mb-2 heading">
        {t("Walkthrough.ManagerDashboard.Title")}
      </p>
      <p className="p-4">{t("Walkthrough.ManagerDashboard.Intro.Text")}</p>
      <p className="text-gray-400">
        {t("Walkthrough.ManagerDashboard.WouldYouLikeATour")}
      </p>
    </div>
  );

  return (
    <>
      <ModalPopup
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        title={""}
        showCloseIcon={false}
        width="SMALL"
      >
        <LaunchWalkthrough
          width={isMobile ? "6rem" : "6rem"}
          height={isMobile ? "8rem" : "8rem"}
          className="m-auto"
        />
        {walkthroughType === "MANAGER-DASHBOARD"
          ? managerDashboardModalContent
          : myDashboardModalContent}
        <div className="flex flex-col mt-4 pt-4 items-center">
          <button
            className="btn-primary max-w-fit"
            onClick={confirmStartWalkthrough}
          >
            {t("Walkthrough.Intro.Options.YesPlease")}
          </button>

          <button
            className="text-gray-400 text-xs max-w-fit m-2 hover:cursor-pointer hover:underline"
            onClick={confirmStopWalkthrough}
          >
            {t("Walkthrough.Intro.Options.NoThanks")}
          </button>
        </div>
      </ModalPopup>

      {runDeclinedWalkthrough && (
        <DeclinedWalkthrough
          walkthroughType={walkthroughType}
          onFinished={onWalkthroughFinished}
        />
      )}
    </>
  );
}

export default UserWalkthroughIntroPopUp;
