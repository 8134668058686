import { t } from "i18next";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { ValidationSettings } from "../../../types/forms";

interface TaskCreationMinMaxCountPromptProps {
  validationSettings: ValidationSettings;
  taskType: ClientTaskType;
}

export const TaskCreationMinMaxCountPrompt = ({
  validationSettings,
  taskType,
}: TaskCreationMinMaxCountPromptProps) => {
  const { min, max } = validationSettings;
  const hasQuestionLevelMinValidation = min !== null && min > 0;
  const hasQuestionLevelMaxValidation = max !== null && max > 0;
  const singularName = t(taskType.singularNameTranslationKeyIdentifier);
  const pluralName = t(taskType.pluralNameTranslationKeyIdentifier);

  let validationText: string | null = null;
  if (hasQuestionLevelMinValidation && hasQuestionLevelMaxValidation) {
    validationText = `You need to have between ${min} and ${max} ${pluralName}`;
  } else if (hasQuestionLevelMinValidation) {
    validationText = `You need to have at least ${min} ${
      min === 1 ? singularName : pluralName
    }`;
  } else if (hasQuestionLevelMaxValidation) {
    validationText = `You need to have no more than ${max} ${
      max === 1 ? singularName : pluralName
    }`;
  }

  if (!validationText) return null;

  return (
    <p className="my-2">
      <FontAwesomeIcon icon={faInfoCircle} size="sm" className="mr-1" />
      {validationText}
    </p>
  );
};
