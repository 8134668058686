function TimelineItemLoadingCard() {
  return (
    <div className="bg-white rounded-md mb-8 px-3 py-6">
      <div className="grid grid-cols-6 gap-4">
        <div className="h-8 col-span-6 bg-gray-200 rounded animate-pulse"></div>
        <div className="h-8 col-span-4 bg-gray-200 rounded animate-pulse"></div>
        <div className="h-8 col-span-2 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  );
}

export default TimelineItemLoadingCard;
