import { taskTypeHelper } from "../../../helpers";
import { t } from "i18next";

import {
  FormQuestion,
  FormType,
  QuestionTasks,
  ValidationResult,
} from "../../../types/forms";
import AddFormTaskControl from "./AddFormTaskControl";
import ValidationWarning from "../../common/ValidationWarning";
import { InfoBanner } from "../../common";
import { TaskType } from "../../../types/tasks";

interface DevelopmentQuestionProps {
  question: FormQuestion;
  /** The id of the client form */
  formId: number;
  isReadOnly: boolean;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** Any tasks added against this question */
  tasks: QuestionTasks | null;
  /** Whether this question is being displayed in a collab doc or a journey. Controls styling (light vs dark bg) */
  formType: FormType;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(questionTasks: QuestionTasks): void;
}

/** Task question */
function TaskQuestion({
  question,
  formId,
  isReadOnly,
  tasks,
  showValidationErrors,
  validationResult = null,
  formType,
  onChangeQuestionTasks,
}: DevelopmentQuestionProps) {
  let restrictionType: TaskType | undefined = undefined;
  let buttonTextOverride = undefined;
  let infoText: string | null = taskTypeHelper.getTaskQuestionInfoBannerText(
    question.validation.toDoType,
    question.validation.min,
    question.validation.max,
    question.validation.required
  );

  let btnClass = "journey-btn-primary";
  if (formType !== "JOURNEY") {
    btnClass = "btn-primary";
  }

  const hasTasks = tasks !== null && tasks.tasks !== null;
  const disableAddTaskButton =
    question.validation.max !== null &&
    hasTasks &&
    tasks.tasks.filter((x) => x.modifyStatus !== "DELETED").length >=
      question.validation.max;

  // Set the restriction type based off the validation if its set, also set the relevant
  // button text override
  if (
    question.validation != undefined &&
    question.validation.toDoType != null
  ) {
    restrictionType = question.validation.toDoType;

    switch (question.validation.toDoType) {
      case "ACTION":
        buttonTextOverride = "Tasks.Popup.TriggerButtons.AddAction";
        break;
      case "LEARNING":
        buttonTextOverride = "Tasks.Popup.TriggerButtons.AddLearningAction";
        break;
      case "GOAL":
        buttonTextOverride = "Tasks.Popup.TriggerButtons.AddGoal";
        break;
    }
  }

  return (
    <>
      {/* Validation errors */}
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      {infoText && <InfoBanner text={infoText} />}
      <AddFormTaskControl
        questionId={question.questionId}
        formId={formId}
        questionTasks={tasks}
        onChange={onChangeQuestionTasks}
        isReadOnly={isReadOnly}
        classNames={btnClass}
        formType={formType}
        restrictToTaskType={restrictionType}
        separateRolledOverGoals={false}
        buttonTextOverride={
          buttonTextOverride != undefined ? t(buttonTextOverride) : undefined
        }
        disableAddTaskButton={disableAddTaskButton}
      />
    </>
  );
}

export default TaskQuestion;
