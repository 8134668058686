import { useContext, useState } from "react";
import { t } from "i18next";
import { Markup } from "interweave";
import { ErrorPopup, FormattedDate } from "../common";
import { JourneyDeadlineMessage } from "./JourneyDeadlineMessage";
import UserContext from "../../state/UserContext";

interface ContinueJourneyMessageProps {
  journeyTitle: string;
  expiryDate: Date;
  /** The deadline for the journey, not to be confused with the expiry date for the partial answer set */
  journeyDeadline: Date | null;
  onContinue(): void;
  onSnooze(
    successCallback: (newDate: Date) => void,
    errorCallback: () => void
  ): void;
}

function ContinueJourneyMessage({
  journeyTitle,
  expiryDate,
  journeyDeadline,
  onContinue,
  onSnooze,
}: ContinueJourneyMessageProps) {
  const userContext = useContext(UserContext);

  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [hasSnoozed, setHasSnoozed] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [newExpiryDate, setNewExpiryDate] = useState<Date | null>(null);

  const handleSnoozeClick = () => {
    setIsCallingApi(true);
    setHasSnoozed(false);
    setHasErrored(false);
    setNewExpiryDate(null);

    // Call the API to record down the snoozing action
    const onSnoozeSuccess = (newDate: Date) => {
      // Show the snooze successful banner
      setHasSnoozed(true);
      setIsCallingApi(false);
      setNewExpiryDate(newDate);
    };

    const onSnoozeError = () => {
      // Show the snooze error alert
      setHasErrored(true);
      setIsCallingApi(false);
    };

    onSnooze(onSnoozeSuccess, onSnoozeError);
  };

  const disableExpiryWarning = userContext.user.client.id === 60; // Disable for Page Group. Hacky but there'll be no need for this soon when we do the journey queue work, so this feature will disappear anyway

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="journey-custom-content flex flex-col justify-center grow">
        {!hasSnoozed && (
          <>
            <h3 className="!text-white">
              {t("Journey.ContinueIncompleteJourneyScreen.InitialTitle")}
            </h3>
            <Markup
              content={t(
                "Journey.ContinueIncompleteJourneyScreen.InitialContent"
              ).replace("#JOURNEY_NAME#", t(journeyTitle))}
            />
            {journeyDeadline && (
              <div className="mt-2">
                <JourneyDeadlineMessage deadline={journeyDeadline} />
              </div>
            )}
            {!disableExpiryWarning && (
              <div className="font-normal text-sm mt-4">
                {t("Journey.ContinueIncompleteJourneyScreen.ExpiryDate")}:{" "}
                <span className="font-medium">
                  {" "}
                  <FormattedDate
                    date={expiryDate}
                    displayMode="DATE-AND-TIME"
                    convertFromUtc
                  />
                </span>
              </div>
            )}
          </>
        )}
        {hasSnoozed && newExpiryDate && (
          <>
            <h3 className="!text-white">
              {t("Journey.ContinueIncompleteJourneyScreen.SnoozedTitle")}
            </h3>
            <Markup
              content={t(
                "Journey.ContinueIncompleteJourneyScreen.SnoozedContent"
              )}
            />
            <div className="font-medium mt-2">
              {t("Journey.ContinueIncompleteJourneyScreen.ExpiryDate")}:{" "}
              <FormattedDate
                date={newExpiryDate}
                displayMode="DATE-AND-TIME"
                convertFromUtc
              />
            </div>
          </>
        )}
      </div>
      <div className="mt-4 text-center md:text-right flex-col-reverse flex md:flex-row md:flex-wrap-reverse md:items-end md:justify-between md:max-w-full">
        {!hasSnoozed && !disableExpiryWarning && (
          <button
            className="font-medium underline text-white pb-2 md:pb-0 mt-2 md:mt-2"
            onClick={handleSnoozeClick}
            disabled={isCallingApi}
          >
            {t("Journey.Button.SnoozeIncompleteJourney")}
          </button>
        )}
        <div></div>
        <button
          className="bg-white font-medium text-gray-700 rounded-md px-2 py-1 hover:shadow-md hover:shadow-white/2 md:justify-self-end"
          onClick={onContinue}
        >
          {t("Journey.Button.ContinueIncompleteJourney")}
        </button>
      </div>
      <ErrorPopup
        error="Couldn't change expiration date of the journey"
        showPopup={hasErrored}
        onTogglePopup={setHasErrored}
      />
    </div>
  );
}

export default ContinueJourneyMessage;
