import { NavigateFunction, useNavigate } from "react-router-dom";
import AppRoutes from "../routes/AppRoutes";
import { UserContextInterface, UserContextIsPopulated } from "../state/UserContext";
import { AdminModule } from "../types/admin/AdminModule";

export const adminHelper = {
  // Checks if the user has admin access and if not redirects them to the dashboard.
  checkAdminAccessAndRedirectWhenNoAccess(userContext: UserContextInterface, module: AdminModule, nav: NavigateFunction): void {
    if (UserContextIsPopulated(userContext.user)) {
      if (!this.doesUserHaveAdminModuleAccess(userContext, module)) {
        nav(AppRoutes.yourJourney.root);
      }
    }
  },

  doesUserHaveAnyAdminModuleAccess(userContext: UserContextInterface): boolean {
    const enabledAdminModules = this.getEnabledAdminModulesForUser(userContext);
    return enabledAdminModules.length > 0;
  },

  doesUserHaveAdminModuleAccess(userContext: UserContextInterface, module: AdminModule): boolean {
    const enabledAdminModules = this.getEnabledAdminModulesForUser(userContext);
    return enabledAdminModules.includes(module);
  },

  // Ideally this needs to be moved onto the userContext (or something similar like SignedInUserDto), that way it only
  // needs to work these out once on log in... rather than on the fly all the time.
  getEnabledAdminModulesForUser(userContext: UserContextInterface): AdminModule[] {
    const adminModules: AdminModule[] = [];
    const managerAdminsTheirPeopleConfig = userContext.user.client.moduleConfigs.find((config) => {
      return config.key === "ManagersAdministerTheirEmployees";
    });

    // Give access to the user-journey tab (and corresponding pages) if the user is an admin, or if they 
    // are a manager with the 'ManagerAdminsTheirPeople' config.
    if (userContext.user.isAdmin || (managerAdminsTheirPeopleConfig != undefined &&
      managerAdminsTheirPeopleConfig.value === "true" &&
      userContext.user.isManager)) {
      adminModules.push("USER-JOURNEY");
    }

    // Give access to the journey-config tab (and correspending pages) if the user is an admin with the
    // 'JourneyAdministration' permission.
    if (userContext.user.isAdmin &&
      userContext.user.permissions.includes("JourneyAdministration")
    ) {
      adminModules.push("JOURNEY-CONFIG");
    }

    return adminModules;
  }
};

export default adminHelper;
