import { AnalyticsFilterComparisonProperty } from "../../../types/analytics/AnalyticsFilterInputs";
import { GenericDropDownList } from "../../common";
import FilterEditIcon from "./FilterEditIcon";

interface AnalyticsComparePropertyPickerProps {
  selectedValue: AnalyticsFilterComparisonProperty;
  isEditMode: boolean;
  onBeginEditMode(): void;
  onSelectedValueChanged: (newValue: AnalyticsFilterComparisonProperty) => void;
}

function AnalyticsComparePropertyPicker({
  selectedValue,
  isEditMode,
  onBeginEditMode,
  onSelectedValueChanged,
}: AnalyticsComparePropertyPickerProps) {
  const getSelectedValueDisplayText = (
    value: AnalyticsFilterComparisonProperty
  ) =>
    value === "NONE" ? "None" : value === "JOURNEY" ? "By Journey" : "Unknown";

  const handleDropdownChange = (newValue: string) => {
    onSelectedValueChanged(newValue as AnalyticsFilterComparisonProperty);
  };

  const availableProperties: Array<AnalyticsFilterComparisonProperty> = [
    "NONE",
    "JOURNEY",
  ];

  const dropDownItems = availableProperties.map((x) => ({
    key: x,
    value: getSelectedValueDisplayText(x),
  }));

  return (
    <div>
      <>
        {!isEditMode && (
          <button onClick={onBeginEditMode}>
            <span>{getSelectedValueDisplayText(selectedValue)}</span>
            <FilterEditIcon extraClassNames="ml-2" />
          </button>
        )}
        {isEditMode && (
          <GenericDropDownList
            currentValue={selectedValue}
            items={dropDownItems}
            onChange={handleDropdownChange}
            className="block w-full"
            bgColorClassName="bg-gray-100"
            inputId="filter-display-mode-picker"
            showValidationErrors={false}
          />
        )}
      </>
    </div>
  );
}

export default AnalyticsComparePropertyPicker;
