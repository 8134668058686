import { useState } from "react";
import Autosuggest from "react-autosuggest";
import { AutoCompleteSelectedValue, KeyValuePair } from "../../types/generic";
import { t } from "i18next";

interface AutoCompleteProps {
  /** Optional css class names for the text input */
  className?: string;
  /** The id attribute for the text input. Useful when pairing this control with a label */
  inputId?: string;
  /** Optional placeholder attribute to render inside the text input */
  placeholder?: string | undefined;
  selectedValue: string | undefined;
  /** Whether or not to raise the valueSelected event for custom typed values (i.e. not just ones in the suggestion list) */
  allowCustomTypedValues: boolean;
  /** Whether or not to disable the field */
  disabled?: boolean;
  /** The list of suggestions to pick from */
  valuesList: KeyValuePair<string, string>[];
  onValueSelected(newValue: AutoCompleteSelectedValue | null): void;
  handleCustomTypedTitle?: (customTitle: string) => void;
  /** Whether to display the key or the value of the selected item in the text input field once a selection has been made */
  suggestionPropertyToDisplay?: "KEY" | "VALUE";
  /** An optional custom method for the HTML to render for each suggestion */
  customSuggestionRender?(
    suggestion: KeyValuePair<string, string>
  ): React.ReactNode;
  /** If provided, instead of the standard searching for suggestions based on the input list provided, the logic for searching can be handled outside this component */
  customSearchFunction?:
    | undefined
    | ((searchValue: string) => KeyValuePair<string, string>[]);
}

interface AutoCompleteValueChangeEventProps {
  newValue: string;
}

interface AutoCompleteSearchEventProps {
  value: string;
}

/** An autocomplete/search text field */
const AutoComplete = ({
  inputId = "",
  selectedValue = "",
  placeholder,
  valuesList,
  onValueSelected,
  handleCustomTypedTitle,
  className = "",
  allowCustomTypedValues = false,
  suggestionPropertyToDisplay = "VALUE",
  customSuggestionRender,
  customSearchFunction,
  disabled = false,
}: AutoCompleteProps) => {
  const [displayText, setDisplayText] = useState<string>("");
  const [suggestions, setSuggestions] = useState<
    KeyValuePair<string, string>[]
  >([]);

  // Get the suggestions for the search value
  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return [];
    } else if (customSearchFunction) {
      // If a custom search function is provided, run that instead
      return customSearchFunction(value);
    } else {
      // Do the standard search
      return valuesList.filter(
        (kvp) => kvp.value.toLowerCase().slice(0, inputLength) === inputValue
      );
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({
    value,
  }: AutoCompleteSearchEventProps) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onInputValueChange = (
    event: any,
    { newValue }: AutoCompleteValueChangeEventProps
  ) => {
    setDisplayText(newValue);
    if (allowCustomTypedValues) {
      // If user clicks on a suggestion, we need to return the ClientFormId as the key
      if (event.type === "click") {
        let kvp: KeyValuePair<string, string> | undefined = undefined;
        if (suggestionPropertyToDisplay === "VALUE") {
          kvp = valuesList.find((x) => x.value === newValue);
        } else {
          kvp = valuesList.find((x) => x.key === newValue);
        }
        if (kvp) {
          onValueSelected({
            displayText: kvp.value,
            isCustomValue: true,
            key: kvp.key,
          });
        }
      } else {
        // If the event.type is anything else, like 'change' then treat it as before
        if (handleCustomTypedTitle) {
          handleCustomTypedTitle(newValue);
        }

        onValueSelected({
          displayText: newValue,
          isCustomValue: true,
          key: null,
        });
      }
    } else {
      // Only raise the onChange event if there's a selected item
      let kvp: KeyValuePair<string, string> | undefined = undefined;
      if (suggestionPropertyToDisplay === "VALUE") {
        kvp = valuesList.find((x) => x.value === newValue);
      } else {
        kvp = valuesList.find((x) => x.key === newValue);
      }
      if (kvp) {
        onValueSelected({
          displayText: kvp.value,
          isCustomValue: false,
          key: kvp.key,
        });
      }
    }
  };

  const inputProps = {
    id: inputId,
    autoComplete: "off",
    className: className,
    placeholder: placeholder,
    value: displayText,
    onChange: onInputValueChange,
    type: "search",
    disabled: disabled,
  };

  const defaultSuggestionRender = (
    suggestion: KeyValuePair<string, string>
  ) => (
    <div
      style={{ width: "calc(100% - 5rem)" }}
      className="z-50 origin-top-right cursor-pointer text-gray-500 fixed px-4 py-2 w-full hover:bg-gray-50 bg-white rounded-md shadow-lg focus:outline-none"
    >
      {suggestion.value}
    </div>
  );

  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) =>
          suggestionPropertyToDisplay === "VALUE"
            ? suggestion.value
            : suggestion.key
        }
        renderSuggestion={
          customSuggestionRender
            ? customSuggestionRender
            : defaultSuggestionRender
        }
        inputProps={inputProps}
      />
    </>
  );
};

export default AutoComplete;
