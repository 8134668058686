import {
  faBullseye,
  faGraduationCap,
  faNotdef,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import TaskTypeIcon from "../types/dtos/tasks/advanced-tasks/ClientTaskTypeIcon";

export const taskTypeIconHelper = {
  getTaskTypeIcon(taskTypeIcon: TaskTypeIcon): IconDefinition {
    switch (taskTypeIcon) {
      case "GOALS":
        return faBullseye;
      case "DEVELOPMENT":
        return faGraduationCap;
      default:
        return faNotdef;
    }
  },
};

export default taskTypeIconHelper;
