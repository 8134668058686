import cx from "classnames";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FormType } from "../../types/forms";
import { t } from "i18next";

interface MoreInfoBadgeProps {
  textTranslationKeyIdentifier: string;
  formType: FormType;
  onClick: () => void;
}

function MoreInfoBadge({
  textTranslationKeyIdentifier,
  formType,
  onClick,
}: MoreInfoBadgeProps) {
  const badgeElement = (
    <span
      onClick={onClick}
      className={cx(
        "badge-label px-2 py-0.5 text-xs mx-1 leading-tight select-none rounded-sm whitespace-nowrap cursor-pointer !font-normal",
        formType === "JOURNEY"
          ? "bg-slate-100/75 rounded-full"
          : "bg-gray-300/75 rounded-full",
        formType === "JOURNEY" ? "text-slate-800" : "text-slate-800",
        "rounded",
        "mr-1"
      )}
    >
      <span className="mr-1">
        <FontAwesomeIcon icon={faInfoCircle} size="1x" />
      </span>
      {parse(t(textTranslationKeyIdentifier))}
    </span>
  );

  return badgeElement;
}

export default MoreInfoBadge;
