import { t } from "i18next";
import {
  BehaviourResponseDto,
  GoalResponseDto,
} from "../../../../types/dtos/collab-docs/PlanningResponsesApiResponseDto";
import { UserBasicDetailsDto } from "../../../../types/dtos/generic";
import { Avatar } from "../../../common";

interface DesktopResponsesViewProps {
  subjectParticipant: UserBasicDetailsDto;
  subjectParticipantStandardAnswer: string | null;
  subjectParticipantBehaviourAnswer: BehaviourResponseDto | null;
  subjectParticipantGoalAnswer: GoalResponseDto | null;
  otherParticipant: UserBasicDetailsDto;
  otherParticipantStandardAnswer: string | null;
  otherParticipantBehaviourAnswer: BehaviourResponseDto | null;
  otherParticipantGoalAnswer: GoalResponseDto | null;
}

function DesktopResponsesView({
  subjectParticipant,
  subjectParticipantStandardAnswer,
  subjectParticipantBehaviourAnswer,
  subjectParticipantGoalAnswer,
  otherParticipant,
  otherParticipantStandardAnswer,
  otherParticipantBehaviourAnswer,
  otherParticipantGoalAnswer,
}: DesktopResponsesViewProps) {
  const isSubjectAnswer =
    subjectParticipantStandardAnswer !== null ||
    subjectParticipantBehaviourAnswer !== null ||
    subjectParticipantGoalAnswer !== null;
  const isOtherAnswer =
    otherParticipantStandardAnswer !== null ||
    otherParticipantBehaviourAnswer !== null ||
    otherParticipantGoalAnswer !== null;

  return (
    <div className="hidden sm:contents">
      <div className="flex flex-row justify-between pt-1 pb-2">
        {isSubjectAnswer && (
          <div className="w-full pl-2">
            <div className="flex flex-row font-medium text-gray-500">
              <Avatar
                key={`p_${subjectParticipant!.userId}`}
                userFullName={subjectParticipant!.fullName}
                userInitials={subjectParticipant!.initials}
                size={8}
                imageUrl={
                  subjectParticipant!.profileImageUrl
                    ? subjectParticipant!.profileImageUrl
                    : undefined
                }
              />

              {/* STANDARD ANSWER */}
              {subjectParticipantStandardAnswer === null &&
                subjectParticipantBehaviourAnswer === null &&
                subjectParticipantGoalAnswer === null && <></>}
              {subjectParticipantStandardAnswer && (
                <p className="pl-2 pt-1 ml-2 w-full bg-white rounded">
                  {subjectParticipantStandardAnswer}
                </p>
              )}

              {/* BEHAVIOUR ANSWER */}
              {subjectParticipantBehaviourAnswer && (
                <div className="pl-2 pt-1 ml-2 w-full bg-white rounded">
                  <p>
                    {subjectParticipantBehaviourAnswer.attributes && (
                      <p>
                        {subjectParticipantBehaviourAnswer.attributes.map(
                          (attr, i) => {
                            return (
                              <>
                                <span className="font-bold">
                                  {t(attr.name)}:
                                </span>{" "}
                                <span>{attr.answer}</span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </p>
                    )}
                    {subjectParticipantBehaviourAnswer.answer && (
                      <p>
                        <span className="font-bold">{t("Score")}:</span>{" "}
                        <span>{subjectParticipantBehaviourAnswer.answer}</span>
                      </p>
                    )}
                    {subjectParticipantBehaviourAnswer.commentQuestionText !=
                      null &&
                      subjectParticipantBehaviourAnswer.comment != null && (
                        <p className="pt-2">
                          <span className="font-bold">
                            {t(
                              subjectParticipantBehaviourAnswer.commentQuestionText
                            )}
                          </span>
                          <br />
                          <span>
                            {subjectParticipantBehaviourAnswer.comment}
                          </span>
                        </p>
                      )}
                  </p>
                </div>
              )}

              {/* GOAL ANSWER */}
              {subjectParticipantGoalAnswer && (
                <div className="pl-2 pt-1 ml-2 w-full bg-white rounded">
                  <p>
                    {subjectParticipantGoalAnswer.optionTranslationKey && (
                      <p>
                        <span>
                          {t(subjectParticipantGoalAnswer.optionTranslationKey)}
                        </span>
                      </p>
                    )}
                    {subjectParticipantGoalAnswer.commentQuestionTranslationKey !=
                      null &&
                      subjectParticipantGoalAnswer.comment != null && (
                        <p className="pt-2">
                          <span className="font-bold">
                            {t(
                              subjectParticipantGoalAnswer.commentQuestionTranslationKey
                            )}
                          </span>
                          <br />
                          <span>{subjectParticipantGoalAnswer.comment}</span>
                        </p>
                      )}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {isOtherAnswer && (
          <div className="w-full pl-2">
            <div className="flex flex-row font-medium text-gray-500">
              <Avatar
                key={`p_${otherParticipant!.userId}`}
                userFullName={otherParticipant!.fullName}
                userInitials={otherParticipant!.initials}
                size={8}
                imageUrl={
                  otherParticipant!.profileImageUrl
                    ? otherParticipant!.profileImageUrl
                    : undefined
                }
              />

              {/* STANDARD ANSWER */}
              {otherParticipantStandardAnswer === null &&
                otherParticipantBehaviourAnswer === null &&
                otherParticipantGoalAnswer === null && <></>}
              {otherParticipantStandardAnswer && (
                <p className="pl-2 pt-1 ml-2 w-full bg-white rounded">
                  {otherParticipantStandardAnswer}
                </p>
              )}

              {/* BEHAVIOUR ANSWER */}
              {otherParticipantBehaviourAnswer && (
                <div className="pl-2 pt-1 ml-2 w-full bg-white rounded">
                  <p>
                    {otherParticipantBehaviourAnswer.attributes && (
                      <p>
                        {otherParticipantBehaviourAnswer.attributes.map(
                          (attr, i) => {
                            return (
                              <>
                                <span className="font-bold">
                                  {t(attr.name)}:
                                </span>{" "}
                                <span>{attr.answer}</span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </p>
                    )}
                    {otherParticipantBehaviourAnswer.answer && (
                      <p>
                        <span className="font-bold">{t("Score")}:</span>{" "}
                        <span>{otherParticipantBehaviourAnswer.answer}</span>
                      </p>
                    )}
                    {otherParticipantBehaviourAnswer.commentQuestionText !=
                      null &&
                      otherParticipantBehaviourAnswer.comment != null && (
                        <p className="pt-2">
                          <span className="font-bold">
                            {t(
                              otherParticipantBehaviourAnswer.commentQuestionText
                            )}
                          </span>
                          <br />
                          <span>{otherParticipantBehaviourAnswer.comment}</span>
                        </p>
                      )}
                  </p>
                </div>
              )}

              {/* GOAL ANSWER */}
              {otherParticipantGoalAnswer && (
                <div className="pl-2 pt-1 ml-2 w-full bg-white rounded">
                  <p>
                    {otherParticipantGoalAnswer.optionTranslationKey && (
                      <p>
                        <span>
                          {t(otherParticipantGoalAnswer.optionTranslationKey)}
                        </span>
                      </p>
                    )}
                    {otherParticipantGoalAnswer.commentQuestionTranslationKey !=
                      null &&
                      otherParticipantGoalAnswer.comment != null && (
                        <p className="pt-2">
                          <span className="font-bold">
                            {t(
                              otherParticipantGoalAnswer.commentQuestionTranslationKey
                            )}
                          </span>
                          <br />
                          <span>{otherParticipantGoalAnswer.comment}</span>
                        </p>
                      )}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DesktopResponsesView;
