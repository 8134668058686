interface IChartConstants {
  coloursArray: string[];
  height: string;
  patterns: {
    id: string;
    [key: string]: any;
  }[];
}

export const chartConstants: IChartConstants = {
  coloursArray: [
    "#7eb0d5",
    "#ffb55a",
    "#bd7ebe",
    "#b2e061",
    "#fd7f6f",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
    "#e5c494",
    "#b3b3b3",
  ],
  height: "400px",
  patterns: [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.1)",
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.1)",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ],
};
