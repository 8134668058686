import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FormattedDate } from "../common";

interface InfoAlertProps {
  prefix: String | null;
  message: String | null;
  optionalDate?: Date | null;
}

function InfoAlert({ prefix, message, optionalDate = null }: InfoAlertProps) {
  return (
    <div className="bg-gray-100 py-1 px-4 mb-2 text-gray-700 text-sm rounded-sm border-l-2 border-l-gray-800">
      <div>
        <div className="fa-layers fa-fw mr-0.5">
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
        <span className="text-gray-800 font-medium">{prefix}</span> {message}
        {optionalDate && (
          <>
            {" "}
            <FormattedDate displayMode="DATE-ONLY" date={optionalDate} />
          </>
        )}
      </div>
    </div>
  );
}

export default InfoAlert;
