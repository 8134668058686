import { Tooltip } from "../../../../common";

interface PersonSVGProps {
  id: string;
  fillColour: string;
  strokeColour?: string | undefined;
  /** The score, generally between 0 and 10 */
  scoreValue: number;
  /** The number of people who chose this score */
  count: number;
  /** The percentage of people who chose this score */
  percentage: number;
  onDrilldown?: undefined | ((score: number) => void);
}

function PersonSVG({
  id,
  scoreValue,
  count,
  percentage,
  fillColour,
  strokeColour = "#CCCCCC",
  onDrilldown = undefined,
}: PersonSVGProps) {
  const strokeWidth = "20";
  const mask_id = "mask_" + id;

  // Offset by 5 to ensure that the fill starts a little higher to counter the outline.
  const remainingHeight = 100 - percentage - 5;

  const handleClick = () => {
    if (!onDrilldown) return;
    onDrilldown(scoreValue);
  };

  const personSvg = (
    <span className="px-1.5 md:px-2 lg:px-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-10 -10 729 1857"
        preserveAspectRatio="xMidYMid meet"
        style={{ width: "100%" }}
        version="1.0"
        role={onDrilldown ? "button" : undefined}
        onClick={handleClick}
        className={onDrilldown ? "cursor-pointer" : ""}
      >
        <mask x="0" y="0" id={mask_id}>
          <rect
            y={`${remainingHeight}%`}
            fill="white"
            width="100%"
            height={`${percentage}%`}
          />
        </mask>
        <path
          fill="transparent"
          stroke={strokeColour}
          strokeWidth={strokeWidth}
          d="M388.078 279.554C465.275 279.554 527.855 216.973 527.855 139.777C527.855 62.5802 465.275 0 388.078 0C310.881 0 248.301 62.5802 248.301 139.777C248.301 216.973 310.881 279.554 388.078 279.554Z M399.844 989.856V1624.09C399.844 1668.39 435.762 1704.31 480.079 1704.31C524.378 1704.31 560.297 1668.39 560.297 1624.09V539.785C560.297 531.569 566.921 524.927 575.155 524.927C583.334 524.927 590.012 531.588 590.012 539.785L589.354 930.553C589.354 964.129 616.563 991.32 650.139 991.32C683.679 991.32 710.888 964.129 710.888 930.553V511.844C710.888 403.576 623.095 315.783 514.827 315.783L422.825 314.887C411.334 314.887 402.039 324.2 402.039 335.691L399.844 989.856Z M74.6733 1129.36L252.235 518.98C252.894 517.279 253.242 515.467 253.242 513.564C253.242 505.166 246.416 498.341 238.036 498.341C231.412 498.341 225.758 502.549 223.672 508.441L116.886 879.648C110.189 905.119 86.9877 923.911 59.4129 923.911C26.605 923.911 0 897.306 0 864.498C0 856.3 1.68337 848.487 4.68421 841.388L115.843 479.787C127.719 397.355 213.425 314.905 304.512 314.905H353.019C364.492 314.905 373.806 324.219 373.806 335.71L374.3 1632.46C374.3 1669.36 344.365 1699.26 307.458 1699.26C270.551 1699.26 240.616 1669.34 240.616 1632.46V1151.65L90.9217 1151.72C81.48 1151.72 73.8316 1144.07 73.8316 1134.61C73.8316 1132.78 74.1061 1131.01 74.6733 1129.36Z"
        />
        <path
          fill={fillColour}
          mask={`url(#${mask_id})`}
          stroke={strokeColour}
          strokeWidth={strokeWidth}
          d="M388.078 279.554C465.275 279.554 527.855 216.973 527.855 139.777C527.855 62.5802 465.275 0 388.078 0C310.881 0 248.301 62.5802 248.301 139.777C248.301 216.973 310.881 279.554 388.078 279.554Z M399.844 989.856V1624.09C399.844 1668.39 435.762 1704.31 480.079 1704.31C524.378 1704.31 560.297 1668.39 560.297 1624.09V539.785C560.297 531.569 566.921 524.927 575.155 524.927C583.334 524.927 590.012 531.588 590.012 539.785L589.354 930.553C589.354 964.129 616.563 991.32 650.139 991.32C683.679 991.32 710.888 964.129 710.888 930.553V511.844C710.888 403.576 623.095 315.783 514.827 315.783L422.825 314.887C411.334 314.887 402.039 324.2 402.039 335.691L399.844 989.856Z M74.6733 1129.36L252.235 518.98C252.894 517.279 253.242 515.467 253.242 513.564C253.242 505.166 246.416 498.341 238.036 498.341C231.412 498.341 225.758 502.549 223.672 508.441L116.886 879.648C110.189 905.119 86.9877 923.911 59.4129 923.911C26.605 923.911 0 897.306 0 864.498C0 856.3 1.68337 848.487 4.68421 841.388L115.843 479.787C127.719 397.355 213.425 314.905 304.512 314.905H353.019C364.492 314.905 373.806 324.219 373.806 335.71L374.3 1632.46C374.3 1669.36 344.365 1699.26 307.458 1699.26C270.551 1699.26 240.616 1669.34 240.616 1632.46V1151.65L90.9217 1151.72C81.48 1151.72 73.8316 1144.07 73.8316 1134.61C73.8316 1132.78 74.1061 1131.01 74.6733 1129.36Z"
        />
      </svg>
      <div className="text-center pl-1">
        <div className="font-semibold">{scoreValue}</div>
        <div>{percentage}%</div>
      </div>
    </span>
  );

  return (
    <Tooltip
      triggerElement={personSvg}
      content=""
      htmlContent={
        <div className="text-center">
          <b>{scoreValue}/10</b>
          <br />
          {`${count} (${percentage}%)`}
        </div>
      }
    />
  );
}

export default PersonSVG;
