import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  approvalFlowHelper,
  dateHelper,
  userDetailsHelper,
} from "../../helpers";
import UserContext from "../../state/UserContext";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import { AnswerSetApprovalStatus, ApprovalFlow } from "../../types/collab-docs";
import {
  CheckBox,
  DropDownMenu,
  FormattedDate,
  InfoBanner,
  ModalPopup,
} from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import DangerBanner from "../common/DangerBanner";
import {
  CollabDocSaveButtonArg,
  CollabDocSaveButtonItemDto,
} from "../../types/collab-docs/CollabDocFooterSaveDropDownItems";
import { CollabDocSaveButtonOptionsFuncParams } from "../../helpers/approvalFlowHelper";
import CollabDocSaveButtonOptions from "../../types/collab-docs/CollabDocSaveButtonOptions";

interface CollabDocFooterProps {
  /** Whether or not the form has been modified since it was loaded */
  formIsDirty: boolean;
  /** Whether or not the form is an Exit Journey */
  isExitJourney: boolean;
  /** The current status of the collab doc */
  approvalStatus: AnswerSetApprovalStatus;
  participants: Array<UserBasicDetailsDto>;
  journeyName: string;
  onValidate(): void;
  /** When the user clicks the button to submit the document for approval etc */
  onSubmitDocument(
    proposedStatus: AnswerSetApprovalStatus,
    isInstantlySigningOff: boolean,
    skipValidation: boolean
  ): void;
  /** Whether the user is the employee or the manager */
  formRole: "EMPLOYEE" | "MANAGER";
  /** If the form can't be submitted yet due to form config settings, this date should have a value in the future */
  lockedForSubmissionUntil: Date | null;
  /** Instant sign off can be disabled via the client-form setting, or if its a dual prep planning stage */
  instantSignOffIsDisabled: boolean | null;
  approvalFlow: ApprovalFlow;
}

function CollabDocFooter({
  formIsDirty,
  isExitJourney,
  approvalStatus,
  participants,
  journeyName,
  onValidate,
  onSubmitDocument,
  formRole,
  lockedForSubmissionUntil,
  instantSignOffIsDisabled,
  approvalFlow,
}: CollabDocFooterProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const appInsights = useAppInsightsContext();
  const trackSaveButtonClick = useTrackEvent(appInsights, "CollabDocSave", {
    proposedStatus: "",
  });

  const [showInstantSignOffModal, setShowInstantSignOffModal] =
    useState<boolean>(false);
  const [instantSignOffAcknowledged, setInstantSignOffAcknowledged] =
    useState<boolean>(false);

  // Events
  const handleSaveButtonClick = (
    proposedStatus: AnswerSetApprovalStatus,
    isInstantlySigningOff: boolean
  ) => {
    onValidate();
    onSubmitDocument(proposedStatus, isInstantlySigningOff, false);
    trackSaveButtonClick({ proposedStatus: proposedStatus });
  };

  const handleSaveButtonClickWithoutValidation = (
    proposedStatus: AnswerSetApprovalStatus
  ) => {
    onSubmitDocument(proposedStatus, false, true);
    trackSaveButtonClick({ proposedStatus: proposedStatus });
  };

  // This is just for induction forms, to prevent submitting documents too early
  // which are intended to stay open for a lengthy period of time
  const lockedForSubmission =
    lockedForSubmissionUntil !== null &&
    dateHelper.getCurrentDateUtc() < lockedForSubmissionUntil;

  const otherUserId = participants.find(
    (x) => x.userId !== userContext.user.id
  )?.userId;
  const otherParticipantName = userDetailsHelper.getDisplayName(
    otherUserId!,
    participants
  );

  const instantSignOffContent =
    approvalFlowHelper.getBodyTextForInstantSignOffPopup(
      approvalFlow,
      otherParticipantName,
      journeyName
    );

  const instantSignOffAcknowledgement =
    approvalFlowHelper.getConfirmationTextForInstantSignOffPopup(
      approvalFlow,
      otherParticipantName,
      journeyName
    );

  const btnDropDownContents = (
    <button className="btn-primary !px-4 block w-full lg:inline-block lg:w-auto">
      {t("Pages.CollabDocs.Submit.Save")}
      <FontAwesomeIcon icon={faChevronDown} className="pl-2" />
    </button>
  );

  const handleDropDownItemClick = (
    index: number,
    arg: CollabDocSaveButtonArg
  ) => {
    const isSubjectEmployee = formRole === "EMPLOYEE";
    switch (approvalFlow) {
      case "HIDDEN-TO-EMPLOYEE":
        approvalFlowHelper.handleSaveDropDownButtonClickForHiddenToEmployeeApprovalFlow(
          arg,
          handleSaveButtonClick,
          setShowInstantSignOffModal
        );
        break;
      case "MANAGER-ONLY-APPROVAL":
        approvalFlowHelper.handleSaveDropDownButtonClickForManagerOnlyApprovalFlow(
          arg,
          isSubjectEmployee,
          handleSaveButtonClickWithoutValidation,
          setShowInstantSignOffModal
        );
        break;
      case "STANDARD":
      default:
        approvalFlowHelper.handleSaveDropDownButtonClickForStandardApprovalFlow(
          arg,
          formIsDirty,
          isSubjectEmployee,
          approvalStatus,
          handleSaveButtonClick,
          setShowInstantSignOffModal
        );
        break;
    }
  };

  const handleInstantSignOffConfirmationClick = () => {
    setShowInstantSignOffModal(!showInstantSignOffModal);
    setInstantSignOffAcknowledged(false); //Reset this
    handleSaveButtonClick("FULLY-APPROVED", true);
  };

  const handleCancelInstantSignOffClick = () => {
    setShowInstantSignOffModal(!showInstantSignOffModal);
    setInstantSignOffAcknowledged(false); //Reset this
  };

  const instantSignOffAcknowledgeElement = (
    <div className="pl-2">
      <CheckBox
        labelText={instantSignOffAcknowledgement}
        onChange={() =>
          setInstantSignOffAcknowledged(!instantSignOffAcknowledged)
        }
        selected={instantSignOffAcknowledged}
        labelClassNames="text-sm pt-1"
        checkboxClassNames="bg-white border border-rose-200"
        checkboxPosition="LEFT"
        includeCheckboxContainerClass={false}
        controlId="instantSignOffAcknowledgement"
      />
    </div>
  );

  // Load the correct save button text and options based on the approval flow, approval status and user role
  const saveButtonOptionFncParams: CollabDocSaveButtonOptionsFuncParams = {
    isSubjectEmployee: formRole === "EMPLOYEE",
    currentApprovalStatus: approvalStatus,
    formIsDirty,
    isExitJourney,
    otherParticipantName,
  };
  let nonDropdownSaveButtonOptions: CollabDocSaveButtonOptions;
  let saveButtonDropdownOptions: CollabDocSaveButtonItemDto[];

  switch (approvalFlow) {
    case "HIDDEN-TO-EMPLOYEE":
      saveButtonDropdownOptions =
        approvalFlowHelper.getCollabDocDropdownSaveButtonOptionsForHiddenToEmployeeApprovalFlow(
          saveButtonOptionFncParams
        );
      nonDropdownSaveButtonOptions =
        approvalFlowHelper.getCollabDocNonDropdownSaveButtonOptionsForHiddenToEmployeeApprovalFlow(
          saveButtonOptionFncParams
        );
      break;
    case "MANAGER-ONLY-APPROVAL":
      saveButtonDropdownOptions =
        approvalFlowHelper.getCollabDocDropdownSaveButtonOptionsForManagerOnlyApprovalFlow(
          saveButtonOptionFncParams
        );
      nonDropdownSaveButtonOptions =
        approvalFlowHelper.getCollabDocNonDropdownSaveButtonOptionsForManagerOnlyApprovalFlow(
          saveButtonOptionFncParams
        );
      break;
    case "STANDARD":
    default:
      saveButtonDropdownOptions =
        approvalFlowHelper.getCollabDocDropdownSaveButtonOptionsForStandardApprovalFlow(
          saveButtonOptionFncParams
        );
      nonDropdownSaveButtonOptions =
        approvalFlowHelper.getCollabDocNonDropdownSaveButtonOptionsForStandardApprovalFlow(
          saveButtonOptionFncParams
        );
      break;
  }

  const collabDocCanBeSaved =
    !lockedForSubmission &&
    nonDropdownSaveButtonOptions.primaryButtonText !== null &&
    nonDropdownSaveButtonOptions.primaryButtonProposedStatus !== null;

  const showDropdownSaveButton =
    collabDocCanBeSaved &&
    !instantSignOffIsDisabled &&
    saveButtonDropdownOptions.length > 0;

  const showNonDropdownSaveButtons =
    collabDocCanBeSaved &&
    instantSignOffIsDisabled &&
    nonDropdownSaveButtonOptions.primaryButtonText !== null &&
    nonDropdownSaveButtonOptions.primaryButtonProposedStatus !== null;

  const userIsntAllowedToSubmit =
    approvalFlow === "MANAGER-ONLY-APPROVAL" && formRole === "EMPLOYEE";

  return (
    <>
      {lockedForSubmission && (
        <div className="mb-2 px-2 lg:px-4">
          <div className="lg:flex flex-row bg-gray-200/60 rounded-lg p-2 lg:px-4">
            <div className="hidden lg:block lg:mr-2 lg:flex-0">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="text-center lg:text-left lg:flex-grow">
              <p>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="lg:hidden mr-1"
                />
                We'll keep this Journey in draft mode until{" "}
                <FormattedDate
                  date={lockedForSubmissionUntil}
                  displayMode="DATE-ONLY"
                />{" "}
                so you can keep it up to date as things change. We'll notify you
                when you're able to submit it.
              </p>
            </div>
          </div>
        </div>
      )}
      {userIsntAllowedToSubmit && (
        <div className="mx-4 mb-2">
          <InfoBanner
            className="text-gray-600 bg-gray-200 border-l-gray-200 rounded-md"
            textClassName="text-md"
            text={`You can make changes, but only ${otherParticipantName} can submit this Journey`}
          />
        </div>
      )}
      <footer className="flex flex-col lg:flex-row-reverse mb-14 lg:mb-0 px-2 lg:px-4 pb-16 print:hidden">
        {showDropdownSaveButton && (
          <div className="flex-grow lg:flex-initial mb-4 lg:mb-0">
            <DropDownMenu
              eventType="EVENT"
              items={saveButtonDropdownOptions}
              onItemClick={handleDropDownItemClick}
              menuButtonClassName="block w-full lg:inline-block lg:w-auto"
              customButtonContents={btnDropDownContents}
            />
          </div>
        )}
        {showNonDropdownSaveButtons && (
          <>
            <div className="flex-grow lg:flex-initial mb-4 lg:mb-0">
              <button
                className="btn-primary block w-full lg:inline-block lg:w-auto"
                onClick={() =>
                  handleSaveButtonClick(
                    nonDropdownSaveButtonOptions.primaryButtonProposedStatus!,
                    false
                  )
                }
              >
                {nonDropdownSaveButtonOptions.primaryButtonText}
              </button>
            </div>
            {formIsDirty &&
              nonDropdownSaveButtonOptions.saveForLaterStatus !== null && (
                <div className="flex-grow lg:flex-initial mb-3 lg:mb-0 mr-0 lg:mr-4">
                  <button
                    className="btn-secondary block w-full lg:inline-block lg:w-auto"
                    onClick={() =>
                      handleSaveButtonClick(
                        nonDropdownSaveButtonOptions.saveForLaterStatus!,
                        false
                      )
                    }
                  >
                    Save changes{" "}
                  </button>
                </div>
              )}
          </>
        )}
      </footer>

      <ModalPopup
        isOpen={showInstantSignOffModal}
        onOpenChange={(newValue) => setShowInstantSignOffModal(newValue)}
        onPrimaryButtonClick={handleInstantSignOffConfirmationClick}
        primaryButtonText={t("Common.Confirm")}
        title={t("Pages.CollabDocs.Submit.CompleteThisJourney")}
        onSecondaryButtonClick={handleCancelInstantSignOffClick}
        secondaryButtonText={t("Common.Close")}
        isPrimaryButtonDisabled={!instantSignOffAcknowledged}
        preventInitialAutoFocus={true}
        width="SMALL"
      >
        <p>{instantSignOffContent}</p>
        <DangerBanner children={instantSignOffAcknowledgeElement} />
      </ModalPopup>
    </>
  );
}

export default memo(CollabDocFooter);
