import { Link } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";

function LegacyTaskLink() {
  return (
    <div>
      <Link to={AppRoutes.legacyTaskList.path}>
        <span>
          <span className="py-1 px-4 text-gray-600 bg-white border rounded hover:bg-gray-50 mr-2">
            Tasks
          </span>
        </span>
      </Link>
    </div>
  );
}

export default LegacyTaskLink;
