import cx from "classnames";
import { Badge } from "../../../common";

interface GoalReviewAccordionHeaderProps {
  goalNumber: number;
  goalTitle: string;
  isValid: boolean;
}

function GoalReviewAccordionHeader({
  goalNumber,
  goalTitle,
  isValid,
}: GoalReviewAccordionHeaderProps) {
  return (
    <div className="flex flex-row">
      <span
        className={cx(
          "flex-none inline-block text-xs w-5 h-5 rounded-full text-center leading-normal font-semibold mt-1 border-[1px]",
          isValid
            ? "bg-emerald-400 text-white border-white"
            : "bg-white border-gray-500 text-gray-500"
        )}
      >
        {goalNumber}
      </span>
      <span className="grow pl-2 font-medium">{goalTitle}</span>
      {!isValid && (
        <span className="flex-none">
          <Badge text="To-do" classNames="font-semibold" />
        </span>
      )}
    </div>
  );
}

export default GoalReviewAccordionHeader;
