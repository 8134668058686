import {
  FormComplexities,
  GoalReviewOptionsDto,
  GoalRolloverCheckResult,
} from "../types/dtos/forms";
import {
  GoalReviewQuestionAnswerValue,
  QuestionAnswer,
  QuestionTasks,
} from "../types/forms";
import { EditableGoal } from "../types/tasks/EditableTasks";
import { GoalTask } from "../types/tasks/Task";

/** Merge the answers which come in an array from the database (one array item per answer/goal, so could be multiple per each goal review question),
 *  into arrays where there is more than one answer for a QuestionId */
export const prepareDatabaseAnswersForDisplay = (
  answers: QuestionAnswer[]
): QuestionAnswer[] => {
  const output: QuestionAnswer[] = [];

  for (var i = 0; i < answers.length; i++) {
    const thisAnswer = answers[i];

    const previousEntryIndex = output.findIndex(
      (x) => x.questionId === thisAnswer.questionId
    );

    const newAnswer =
      thisAnswer.answer as unknown as GoalReviewQuestionAnswerValue[];

    if (previousEntryIndex === -1) {
      const loopAnswerWithAnswerPropArray: QuestionAnswer = {
        ...thisAnswer,
        answer: [newAnswer[0]],
      };
      output.push(loopAnswerWithAnswerPropArray);
    } else {
      (
        output[previousEntryIndex].answer as GoalReviewQuestionAnswerValue[]
      ).push(newAnswer[0]);
    }
  }

  return output;
};

/** Create a new EditableGoal object from a goal review GoalTask.
 * Used when rolling over goals from a goal review question
 * into a goal setting question.
 * Returns null if the source task id isn't a valid number
 * (i.e. you can only roll over a dashboard goal, not a goal with a string id)
 */
const createNewRolledOverGoal = (
  sourceGoal: GoalTask
): EditableGoal<string> | null => {
  if (!sourceGoal.toDoId) return null;
  const numericTaskId = Number(sourceGoal.toDoId);
  if (isNaN(numericTaskId)) return null;
  return new EditableGoal<string>(
    "NEW",
    null,
    sourceGoal.title,
    sourceGoal.targetDate,
    sourceGoal.field1,
    sourceGoal.field2,
    sourceGoal.categoryId,
    null,
    null,
    "OPEN",
    numericTaskId
  );
};

/** A function to be called when a goal review status option is changed,
 * as it could lead to a rolled over goal needing to be created in the goal setting question,
 * or a previously rolled over goal needing to be removed now it's no longer marked as Ongoing
 */
const checkRolledOverGoals = (
  formComplexities: FormComplexities,
  goalReviewOptions: GoalReviewOptionsDto,
  answerState: QuestionAnswer[],
  tasks: QuestionTasks[]
): GoalRolloverCheckResult => {
  const output: GoalRolloverCheckResult = {
    goalsToSave: [],
    taskIdsToDelete: [],
  };

  if (
    !formComplexities ||
    !formComplexities.goalRolloverConfig ||
    !formComplexities.goalRolloverConfig.validConfig ||
    !goalReviewOptions ||
    !goalReviewOptions.goals
  ) {
    // Don't need to do anything, don't have the relevant config details
    return output;
  }
  const rolloverConfig = formComplexities.goalRolloverConfig;
  const goalReviewAnswers = answerState
    .filter(
      (x) =>
        x.questionId === rolloverConfig.goalReviewQuestionId &&
        x.answerType === "GOAL-REVIEW"
    )
    .flatMap((x) => x.answer as GoalReviewQuestionAnswerValue[]);
  const goalSettingTasks = tasks
    .filter((x) => x.questionId === rolloverConfig.goalSettingQuestionId)
    .flatMap((x) => x.tasks)
    .map((x) => x as EditableGoal<string>);

  // *** Check each rolled over goal has a task ***
  const rolledOverGoalIds = goalReviewAnswers
    .filter(
      (x) =>
        x.goalStatusOptionId &&
        rolloverConfig.rolloverOptionIds.indexOf(x.goalStatusOptionId) >= 0
    )
    .map((x) => x.toDoId);

  if (rolledOverGoalIds.length > 0) {
    rolledOverGoalIds.forEach((sourceGoalId) => {
      const matchingTask = goalSettingTasks.find(
        (x) => x.rolloverFromTaskId === sourceGoalId
      );
      if (!matchingTask) {
        // No goal setting task found for this rolled over goal, so create one (unless we already have in this function!)
        if (
          !output.goalsToSave.find((x) => x.rolloverFromTaskId === sourceGoalId)
        ) {
          // Find the reviewed goal....
          const matchingReviewedGoal = goalReviewOptions.goals.find(
            (x) => x.toDoId === sourceGoalId
          );
          if (matchingReviewedGoal) {
            // ... and clone it
            const newGoal = createNewRolledOverGoal(matchingReviewedGoal);
            if (newGoal) {
              output.goalsToSave.push(newGoal);
            }
          }
        }
      }
    });
  }

  // *** Check each task created from a rolled over goal, still has it's source goal set as "ONGOING" ***
  const duffRolledOverGoalSettingTasks = goalSettingTasks.filter(
    (x) =>
      x.rolloverFromTaskId &&
      x.rolloverFromTaskId > 0 &&
      rolledOverGoalIds.indexOf(x.rolloverFromTaskId) === -1
  );
  if (
    duffRolledOverGoalSettingTasks &&
    duffRolledOverGoalSettingTasks.length > 0
  ) {
    output.taskIdsToDelete = duffRolledOverGoalSettingTasks.map(
      (x) => x.taskId!
    );
  }

  return output;
};

const goalReviewQuestionHelper = {
  /** Convert the answers received from the database to the format needed for rendering */
  prepareDatabaseAnswersForDisplay,
  /** Check rolled over goals have goal setting tasks, check tasks created from rolled over goals still have their source goal set to be rolled over in the goal review section */
  checkRolledOverGoals,
};

export default goalReviewQuestionHelper;
