import { Routes, Route } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import Journeys from "./Journeys";
import UserDetail from "./UserDetail";
import SendingHistory from "./SendingHistory";
import EditConfiguration from "./EditConfiguration";
import SendPage from "./SendPage";
import UserAdminHistory from "./UserAdminHistory";

function AdminRoot() {
  return (
    <>
      <Routes>
        <Route
          path={AppRoutes.admin.sub.journeys.path}
          element={<Journeys />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.pathOpenWithConfigTabSelected}
          element={<Journeys />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.pathRedirectFromSendNowAfterSuccess}
          element={<Journeys />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.userDetail.path}
          element={<UserDetail />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.userDetail.adminHistory}
          element={<UserAdminHistory />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.sendingHistory}
          element={<SendingHistory />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.config.path}
          element={<EditConfiguration />}
        />
        <Route
          path={AppRoutes.admin.sub.journeys.send.path}
          element={<SendPage />}
        />
      </Routes>
    </>
  );
}

export default AdminRoot;
