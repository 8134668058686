import { FormattedDate } from "../common";

interface LastCompletedDateLabelProps {
  date: Date;
}

function LastCompletedDateLabel({ date }: LastCompletedDateLabelProps) {
  return (
    <span className="text-xs text-gray-400">
      Updated <FormattedDate date={date} displayMode="FROM-NOW" />
    </span>
  );
}

export default LastCompletedDateLabel;
