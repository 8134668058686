import { t } from "i18next";
import UserWalkthrough from "./UserWalkthrough";

interface ManagerDashWalkthroughProps {
  onFinished(): void;
}

function ManagerDashWalkthrough({ onFinished }: ManagerDashWalkthroughProps) {
  const steps = [
    {
      target: "#activity-box",
      contentTranslationKey: t("Walkthrough.ManagerDashboard.Step.One")
    },
    {
      target: "#manager-dashboard-search",
      contentTranslationKey: t("Walkthrough.ManagerDashboard.Step.Two")
    },
    {
      target: "#people",
      contentTranslationKey: t("Walkthrough.ManagerDashboard.Step.Three")
    },
    {
      target: "#manager-dashboard-option",
      contentTranslationKey: t("Walkthrough.ManagerDashboard.Step.Four")
    },
  ];

  return (
    <UserWalkthrough
      walkthroughType="MANAGER-DASHBOARD"
      declinedWalkthrough={false}
      steps={steps}
      onFinished={onFinished}
    />
  );
}

export default ManagerDashWalkthrough;
