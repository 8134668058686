import CheckboxTreeApiResponseDto from "../../types/dtos/admin/CheckboxTreeApiResponseDto";
import { apiClient } from "../apiClient";

class locationApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Loads the locations ready for a checkbox tree component */
  getLocationCheckboxTreeItemsByClientId(
    onSuccess: (locations: CheckboxTreeApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/location/get-location-checkbox-tree-items-by-client-id").then(
      (data) => {
        const locations = data as CheckboxTreeApiResponseDto;
        onSuccess(locations);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default locationApi;
