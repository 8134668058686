import { NineBoxGridRowData } from "../../../../types/analytics/charts/NineBoxGrids";
import NineBoxGridBox from "./NineBoxGridBox";

export interface NineBoxGridRowProps {
  row: NineBoxGridRowData;
  gridBoxesCount: number;
  onDrilldown: (boxNumber: number) => void;
}

function NineBoxGridRow({
  row,
  gridBoxesCount,
  onDrilldown,
}: NineBoxGridRowProps) {
  return (
    <div className="flex flex-1 flex-row gap-3">
      {row.boxes.map((box) => {
        return (
          <NineBoxGridBox
            box={box}
            key={box.number}
            gridBoxesCount={gridBoxesCount}
            onDrilldown={onDrilldown}
          />
        );
      })}
    </div>
  );
}
export default NineBoxGridRow;
