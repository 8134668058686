import { t } from "i18next";
import {
  MultipleChoiceQuestionAnswerValue,
  QuestionAnswerValue,
} from "../types/forms";
import { FormQuestion } from "../types/forms";
import { SliderScoreDisplayType } from "../types/generic";
import MultipleChoiceOptionNumericId from "../types/forms/MultipleChoiceOptions";

/** Get the collection of options with options marked as "selected" if they're in the currentValue (answer) */
export const initialiseMultipleChoiceOptions = (
  question: FormQuestion,
  currentValue: QuestionAnswerValue,
  translateText: boolean
): MultipleChoiceOptionNumericId[] => {
  let multipleChoiceQuestionOptions =
    question.isMultiChoiceQuestion() &&
    question.multiChoiceOptions !== null &&
    question.multiChoiceOptions !== undefined
      ? [...question.multiChoiceOptions]
      : [];

  const currentValueIsArray =
    currentValue !== null && Array.isArray(currentValue);

  const itemsWithValuesSelected = multipleChoiceQuestionOptions.map((item) => {
    let itemIsSelected: boolean;
    let customOptionText: string | undefined = undefined;
    if (
      currentValue === null ||
      (currentValueIsArray && currentValue.length === 0)
    ) {
      itemIsSelected = false;
    } else if (typeof currentValue === "string") {
      // Don't select... we're expecting one or more optionIds, not strings
      itemIsSelected = false;
    } else if (typeof currentValue === "number") {
      // Value is a single number
      itemIsSelected = item.optionId === currentValue;
    } else if (currentValueIsArray) {
      if (question.questionType === "BEHAVIOUR") {
        // Doesn't need to be handled here...multiple attributes complicate things so this method shouldn't be used
        itemIsSelected = false;
      } else {
        // Value could be multiple MultipleChoiceQuestionAnswerValues, e.g. checklist
        const matchedItem = (
          currentValue as MultipleChoiceQuestionAnswerValue[]
        ).find((x) => x.optionId === parseInt(item.optionId.toString()));

        if (matchedItem) {
          itemIsSelected = true;
          customOptionText = matchedItem.customText
            ? matchedItem.customText
            : undefined;
        } else {
          itemIsSelected = false;
        }
      }
    } else {
      // Unexpected
      itemIsSelected = false;
    }

    // Translate the display text, if necessary
    let displayText = item.text;
    if (translateText && displayText && displayText.length > 0) {
      displayText = t(displayText);
    }

    return {
      ...item,
      isSelected: itemIsSelected,
      text: displayText,
      customText: customOptionText,
    };
  });
  multipleChoiceQuestionOptions = itemsWithValuesSelected;

  return multipleChoiceQuestionOptions;
};

/** Convert the options array to a valid array of QuestionAnswerValues */
export const getAnswersFromMultiChoiceOptions = (
  updatedMultiChoiceOptions: MultipleChoiceOptionNumericId[],
  question: FormQuestion
): MultipleChoiceQuestionAnswerValue[] => {
  // Get the selected optionIds
  const selectedOptions: MultipleChoiceQuestionAnswerValue[] =
    updatedMultiChoiceOptions
      .filter((x) => x.isSelected)
      .map((x) => ({ optionId: x.optionId, customText: x.customText }));

  // If the question only allows one option to be selected,
  // only pick the first option in the array to avoid saving
  // multiple answers for a single choice question
  if (!question.multipleValuesAllowed() && selectedOptions.length > 0) {
    return [selectedOptions[0]];
  }

  return selectedOptions;
};

/** Determine whether to show scores or words next to the slider based on whether every item has text to display or not */
export const getSliderScoreDisplayMode = (
  options: MultipleChoiceOptionNumericId[]
): SliderScoreDisplayType => {
  if (!options || options.length === 0) {
    return "OFF";
  }

  if (options.every((x) => x.text && x.text.length > 0)) {
    return "WORD";
  }

  return "NUMERIC";
};

const multipleChoiceQuestionHelper = {
  /** Get the collection of options with options marked as "selected" if they're in the currentValue (answer) */
  initialiseMultipleChoiceOptions,
  /** Convert the options array to a valid array of QuestionAnswerValues */
  getAnswersFromMultiChoiceOptions,
  /** Determine whether to show scores or words next to the slider based on whether every item has text to display or not */
  getSliderScoreDisplayMode,
};

export default multipleChoiceQuestionHelper;
