import { t } from "i18next";
import { IconButton } from "../common";

interface FormSummaryActionButtonProps {
  /** The translation key identifier for the form's name */
  formName: string;
  showTriggerDocButton: boolean;
  hideButton: boolean;
  hasHistoryToShow: boolean;
  isManagerDashboard: boolean;
  isManagerViewingButHasBeenDelegated?: boolean;
  updateButtonClickEvent: () => void;
  onHistoryButtonClick: () => void;
}

function FormSummaryActionButton({
  formName,
  showTriggerDocButton,
  hideButton,
  hasHistoryToShow,
  isManagerDashboard,
  isManagerViewingButHasBeenDelegated = false,
  updateButtonClickEvent,
  onHistoryButtonClick,
}: FormSummaryActionButtonProps) {
  const updateButtonText =
    hasHistoryToShow || isManagerViewingButHasBeenDelegated
      ? `${t("Common.View")} '${t(formName)}'`
      : `${t("Common.Update")} '${t(formName)}'`;

  return (
    <>
      {showTriggerDocButton && !hideButton && (
        <button
          className="hover:[background-color:var(--color-primary)] hover:text-white bg-gray-200 text-gray-500 text-center border-0 drop-shadow-sm py-1 px-6 m-0 rounded-md font-medium text-sm"
          onClick={updateButtonClickEvent}
        >
          {updateButtonText}
        </button>
      )}

      {hasHistoryToShow && !isManagerDashboard && (
        <IconButton
          buttonType="HISTORY"
          displayMode="ICON-ONLY"
          buttonClassName="mr-1 border-0 rounded-md px-3 hover:bg-gray-100"
          iconClassName="text-gray-600"
          onClick={onHistoryButtonClick}
        />
      )}
    </>
  );
}

export default FormSummaryActionButton;
