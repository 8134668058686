import { Fragment, ReactNode, memo, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import produce from "immer";
import { debounce } from "lodash";
import ValidationWarning from "./ValidationWarning";
import { ValidationResult } from "../../types/forms";
import { KeyValuePair } from "../../types/generic";
import CustomTextInput from "./CustomTextInput";
import MultipleChoiceOptionNumericId, {
  MultipleChoiceOptionStringId,
} from "../../types/forms/MultipleChoiceOptions";
import FormQuestionOptionCategory from "../../types/forms/FormQuestionOptionCategory";
import MultiChoiceOptionItemWithCategory from "./MultiChoiceOptionItemWithCategory";
import { stringHelper } from "../../helpers";
import { t } from "i18next";
import MultiChoiceQuestionSearchField from "./MultiChoiceQuestionSearchField";

export interface RadioButtonGroupProps<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
> {
  /** Used for the `name` attribute for the radio buttons, and as a prefix for `id` attributes, so make this unique */
  uniqueFieldName: string;
  /** The question text for this radio button group */
  fieldLabel?: string;
  /** The options to show in the list */
  values: TOption[];
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  radioColourHexCode?: string | null;
  /** Custom class names to override the label text classes, e.g. to override colour */
  labelClassNames?: string | undefined;
  /** The onChange event, returning the new state of the options */
  onChange(updatedValues: TOption[]): void;
  isReadOnly?: boolean;
  /** An event to call when the user may have stopped interacting with the radio list */
  onBlur?(latestAnswer: TOption[]): void | undefined;
  /** Whether to display in a vertical list or horizontal (always horizontal on mobile) */
  layout?: "HORIZONTAL" | "VERTICAL";
  containerClassNames?: string;
  /** Used to render out categories and have the options appear under their relevant ones */
  categories?: FormQuestionOptionCategory[] | null;
}

/** A form field containing multiple radio buttons */
function RadioButtonGroup<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
>({
  uniqueFieldName,
  fieldLabel,
  values,
  showValidationErrors = false,
  validationResult = null,
  onChange,
  onBlur = undefined,
  isReadOnly = false,
  radioColourHexCode = null,
  labelClassNames = "text-gray-700",
  layout = "VERTICAL",
  containerClassNames = "mt-3 space-y-3",
  categories,
}: RadioButtonGroupProps<TOption>) {
  const [optionCustomValues, setOptionCustomValues] = useState<
    KeyValuePair<number | string, string>[]
  >([]);
  const [displayItems, setDisplayItems] = useState<TOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expandAllCategories, setExpandAllCategories] = useState<boolean>(false);

  // `onChange` handles state changes, `handleBlur` can be called to send the
  // current value to the API to be saved (if an onBlur event is defined)
  const handleBlur = (currentOptions: TOption[]) => {
    if (onBlur) {
      onBlur(currentOptions);
    }
  };

  // use the `debouncedHandleBlur` function to avoid sending the possibility
  // of firing multiple onBlur events (potentially causing multiple calls to the API)
  // as users change the value multiple times in quick succession
  const debouncedHandleBlur = useMemo(
    () => debounce(handleBlur, 500),
    [onBlur]
  );

  // Callback on value change, call on blur function (if one was provided)
  useEffect(() => {
    const customValues = values
      .filter((x) => x.allowCustomText && x.customText)
      .map((x) => ({
        key: x.optionId,
        value: x.customText!,
      }));

    setOptionCustomValues(customValues);
  }, [values]);

  /** Ensure that only one selected value exists in the state */
  const handleValueChange = (optionId: number | string) => {
    const nextState = produce(values, (draft) => {
      draft.forEach((option) => {
        option.isSelected = option.optionId === optionId;
      });
    });

    onChange(nextState);
    debouncedHandleBlur(nextState);
  };

  // Initial load (only has an effect if the categories are not null)
  useEffect(() => {
    if (!categories) return;
    onFilterAndSetDisplayItems(searchTerm);
  }, []);

  // On value change (only has an effect if the categories are not null)
  useEffect(() => {
    if (!categories) return;
    onFilterAndSetDisplayItems(searchTerm);
  }, [values]);

  // On search term change (only has an effect if the categories are not null)
  const onSearch = (newSearchTerm: string) => {
    if (!categories) return;

    onFilterAndSetDisplayItems(newSearchTerm);
    setSearchTerm(newSearchTerm);

    const hasSearchTerm = newSearchTerm.trim().length > 0;
    setExpandAllCategories(hasSearchTerm);
  };

  const onFilterAndSetDisplayItems = (searchTerm: string) => {
    const newState = filterBySearchTerm(
      values,
      searchTerm
    );

    setDisplayItems(newState);
  };

  const filterBySearchTerm = (
    sourceItems: TOption[],
    searchTerm: string | null
  ): TOption[] => {
    const filterBySearchTerm = searchTerm && searchTerm.trim().length > 0;

    const filteredOptions = sourceItems.filter((option) => {
      if (filterBySearchTerm) {
        const optionText = option.translateDisplayValue
          ? t(option.displayValueTranslationKeyIdentifier!)
          : option.text;
        return stringHelper.matchesSearch(optionText, searchTerm);
      }
      return true;
    });

    return filteredOptions;
  };

  const convertOptionsToRadioButtonGroup = (options: TOption[], hasCategories: boolean): ReactNode => {
    return <>
      {options.map(
        ({ value, text, isSelected, optionId, allowCustomText }) => {
          const itemCustomText = optionCustomValues.find(
            (x) => x.key === optionId
          )?.value;

          const uniqueItemKey = `${value}_${optionId}`;

          const radioButton = (
            <>
              <div className={cx(
                "flex items-center",
                hasCategories ? "my-2 ml-2" : ""
              )}>
                <input
                  id={`radio_${uniqueFieldName}_${optionId}`}
                  name={uniqueFieldName}
                  type="radio"
                  data-option-id={optionId}
                  checked={isSelected}
                  onChange={() => handleValueChange(optionId)}
                  value={value}
                  disabled={isReadOnly}
                  className={cx(
                    // Setting the background in dark properly requires a workaround (see css/tailwind.css)
                    "h-4 w-4 border border-transparent bg-gray-200 text-gray-500",
                    "focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring focus-visible:ring-opacity-75 focus-visible:ring-offset-2",
                    isReadOnly ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                />
                <label
                  htmlFor={`radio_${uniqueFieldName}_${optionId}`}
                  className={cx(
                    "ml-2 block text-sm select-none",
                    labelClassNames,
                    isReadOnly ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                >
                  {text}
                </label>
              </div>
              {isSelected && allowCustomText && (
                <CustomTextInput<TOption>
                  itemCustomText={itemCustomText}
                  optionId={optionId}
                  values={values}
                  onChange={onChange}
                  optionCustomValues={optionCustomValues}
                  onOptionCustomValueChange={setOptionCustomValues}
                  onBlur={debouncedHandleBlur}
                />
              )}
            </>
          );

          return layout === "HORIZONTAL" ? (
            <div
              className="lg:inline-block lg:mr-4 my-1 lg:my-0"
              key={uniqueItemKey}
            >
              {radioButton}
            </div>
          ) : (
            <Fragment key={uniqueItemKey}>{radioButton}</Fragment>
          );
        }
      )}
    </>
  }

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <fieldset>
        {fieldLabel && (
          <legend className="text-sm font-medium leading-4 text-gray-800">
            {fieldLabel}
          </legend>
        )}
        <div className={containerClassNames}>
          {/* No Categories */}
          {categories == undefined && (
            <div className={containerClassNames}>
              {convertOptionsToRadioButtonGroup(values, false)}
            </div>
          )}

          {/* With Categories */}
          {categories != null && categories.length > 0 && (
            <>
              <MultiChoiceQuestionSearchField
                searchTerm={searchTerm}
                onSearch={onSearch} />

              <div className={containerClassNames}>
                {/* No items to show */}
                {displayItems.length === 0 && (
                  <p className="text-center text-sm">
                    <span className="italic">{t('Common.EmptyState.Search')}</span>
                  </p>
                )}
                {/* Items to show */}
                {displayItems.length > 0 && (
                  <>
                    {categories.map(
                      ({ id, titleTranslationKeyIdentifier }) => {
                        // Retrieve all of the options that belong to this category
                        const categoryOptions = displayItems.filter(
                          (x) => x.categoryId === id
                        );

                        return (
                          <MultiChoiceOptionItemWithCategory
                            key={id}
                            categoryId={id}
                            titleTranslationKeyIdentifier={titleTranslationKeyIdentifier}
                            options={categoryOptions}
                            forceExpand={expandAllCategories}
                            getReactNodesForOptions={convertOptionsToRadioButtonGroup}
                          />
                        );
                      }
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </fieldset>
    </>
  );
}

export default memo(RadioButtonGroup) as typeof RadioButtonGroup;
