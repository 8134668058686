import { useAuth } from "react-oidc-context";
import analyticsUsageApi from "../../api/analytics/analyticsUsageApi";
import AnalyticsPage from "../../components/analytics/AnalyticsPage";

function UsageReport() {
  const auth = useAuth();
  const usageApi = new analyticsUsageApi(auth.user?.access_token);

  return (
    <AnalyticsPage
      pageIdentifier="UsageReport"
      title="Pages.Analytics.Usage.PageTitle"
      journeyFilterDefaultValueMode="SINGLE"
      contentType="WIDGETS"
      journeyFilterRestriction="SHOW-ALL"
      analyticsPageApi={usageApi}
    />
  );
}

export default UsageReport;
