import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollTopOnPageChangeProps {
  children: JSX.Element;
}

/** Prevents the browser getting stuck where the user last scrolled to when the url changes */
const ScrollTopOnPageChange = ({ children }: ScrollTopOnPageChangeProps) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default ScrollTopOnPageChange;
