import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellOn } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Popover from "./Popover";
import { useEffect, useState } from "react";
import notificationHelper from "../../helpers/notificationHelper";
import FormattedDate from "./FormattedDate";
import UserNotificationDetails from "../../types/dtos/notification/UserNotificationDetails";
import { useAuth } from "react-oidc-context";
import notificationApi from "../../api/notification/notificationApi";
import ModalPopup from "./ModalPopup";
import RecentNotification from "../../types/dtos/notification/RecentNotification";
//@ts-ignore
import Pagination from "react-js-pagination";

export interface NotificationMenuProps {
  defaultNotificationData: UserNotificationDetails;
}

/** A form field containing a dropdownlist (i.e. a `select` element) */
const NotificationMenu = ({
  defaultNotificationData,
}: NotificationMenuProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const notifApi = new notificationApi(auth.user?.access_token);

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalNotifications, setModalNotifications] =
    useState<RecentNotification[]>();
  const [modalDisplayNotifications, setModalDisplayNotifications] =
    useState<RecentNotification[]>();
  const [modalViewedNotificationIds, setModalViewedNotificationIds] = useState<
    number[]
  >([]);
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [totalItemCount, setTotalItemCount] = useState<number>();

  const [notificationData, setNotificationData] =
    useState<UserNotificationDetails>(defaultNotificationData);
  const [delayedUpdatedData, setDelayedUpdatedData] =
    useState<UserNotificationDetails>();
  const recentUnreadNotificationsCount =
    notificationData.recentNotifications.filter(
      (x) => x.dateRead == null
    ).length;

  useEffect(() => {
    // Only look to process and replace messages and href in notifications if there are any in the first place.
    if (defaultNotificationData.totalNotificationCount > 0) {
      notificationHelper.processNotificationDetails(defaultNotificationData);
      setNotificationData(defaultNotificationData);
    }
  }, [defaultNotificationData]);

  // |----- POPOVER EVENTS -----|
  const onOpenChangeHandler = (value: boolean) => {
    setPopoverIsOpen(value);

    if (notificationData != undefined) {
      // On true, we want to send the notification ids to the server for them to be flagged as marked,
      // however we don't want to update the notificationData state until the popup is closed
      if (value) {
        const anyRecentNotificationsAreUnread =
          notificationData.recentNotifications.filter((x) => x.dateRead == null)
            .length > 0;

        // Only hit the server with the recentNotificationIds if any are unread
        if (anyRecentNotificationsAreUnread) {
          const recentNotificationIds =
            notificationData.recentNotifications.map((x) => x.id);

          notifApi.markGivenNotificationsAsRead(
            true,
            recentNotificationIds,
            (json: UserNotificationDetails) => {
              notificationHelper.processNotificationDetails(json);
              setDelayedUpdatedData(json);
            },
            (error: any) => {
              console.error(error);
            }
          );
        }
      } else {
        if (delayedUpdatedData != undefined) {
          // Update the data with the delayed data, and then remove the delayed data.
          setNotificationData(delayedUpdatedData);
          setDelayedUpdatedData(undefined);
        }
      }
    }
  };

  const onPopoverMarkAllAsReadClickHandler = () => {
    notifApi.markAllNotificationsAsRead(
      true,
      (json: UserNotificationDetails) => {
        notificationHelper.processNotificationDetails(json);
        setNotificationData(json);

        // If there is any data held in the delayedUpdate we need to remove it now as its no longer relevant
        if (delayedUpdatedData != undefined) {
          setDelayedUpdatedData(undefined);
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const onViewMoreClickHandler = () => {
    notifApi.getNotificationPanelDataForEmployee(
      (json: UserNotificationDetails) => {
        notificationHelper.processNotificationDetails(json);
        setModalNotifications(json.recentNotifications);

        let displayItems = [...json.recentNotifications].splice(
          0,
          itemsPerPage
        );
        setModalDisplayNotifications(displayItems);

        // Add the notification ids from the display items to the viewed list
        updateViewedNotificationIds(displayItems);

        setActivePageNumber(1);
        setTotalItemCount(json.totalNotificationCount);
        setModalIsOpen(true);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  // |----- MODAL EVENTS -----|
  const onModalCloseChangeHandler = (value: boolean) => {
    setModalIsOpen(value);

    notifApi.markGivenNotificationsAsRead(
      false,
      modalViewedNotificationIds,
      (json: UserNotificationDetails) => {
        notificationHelper.processNotificationDetails(json);

        // Build a new state of notification data to ensure the popover information is updated
        const newPopoverState: UserNotificationDetails = {
          totalNotificationCount: json.totalNotificationCount,
          totalUnreadNotificationCount: json.totalUnreadNotificationCount,
          recentNotifications: json.recentNotifications.slice(0, 3),
          notificationTypes: json.notificationTypes,
        };
        setNotificationData(newPopoverState);

        // Need to wipe the viewed notification id array as its been actioned
        setModalViewedNotificationIds([]);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const onModalMarkAllAsReadClickHandler = () => {
    notifApi.markAllNotificationsAsRead(
      false,
      (json: UserNotificationDetails) => {
        // Process and update the relevant properties for the modal
        notificationHelper.processNotificationDetails(json);
        setModalNotifications(json.recentNotifications);
        setModalDisplayNotifications(
          [...json.recentNotifications].splice(0, itemsPerPage)
        );
        setActivePageNumber(1);
        setTotalItemCount(json.totalNotificationCount);

        // Build a new state of notification data to ensure the popover information is updated
        const newPopoverState: UserNotificationDetails = {
          totalNotificationCount: json.totalNotificationCount,
          totalUnreadNotificationCount: json.totalUnreadNotificationCount,
          recentNotifications: json.recentNotifications.slice(0, 3),
          notificationTypes: json.notificationTypes,
        };
        setNotificationData(newPopoverState);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const handlePaginationChange = (pageNumber: number) => {
    if (modalNotifications != undefined) {
      var startIndex = (pageNumber - 1) * itemsPerPage;
      let displayItems = [...modalNotifications].splice(
        startIndex,
        itemsPerPage
      );

      // Add the notification ids from the display items to the viewed list
      updateViewedNotificationIds(displayItems);

      setModalDisplayNotifications(displayItems);
      setActivePageNumber(pageNumber);
    }
  };

  const updateViewedNotificationIds = (notifications: RecentNotification[]) => {
    // Get only the notifications that are marked as unread, then put their ids in state
    let unreadNotificationsOnly = notifications.filter(
      (x) => x.dateRead == null
    );
    let unreadNotificationsIds = unreadNotificationsOnly.map((x) => x.id);
    setModalViewedNotificationIds([
      ...modalViewedNotificationIds,
      ...unreadNotificationsIds,
    ]);
  };

  return (
    <div className="print:hidden">
      <Popover
        openState={popoverIsOpen}
        onOpenStateChange={onOpenChangeHandler}
        placement="bottom"
        showCloseIcon={false}
        contentClassNames="w-72 bg-white"
        triggerElement={
          <button className="inline-flex justify-end lg:justify-center w-full bg-white text-sm font-medium text-gray-700 hover:lg:bg-gray-100 rounded-full focus:outline-none">
            {notificationData.totalUnreadNotificationCount == 0 && (
              <FontAwesomeIcon
                icon={faBell}
                size="lg"
                className="text-gray-500 m-4"
              />
            )}
            {notificationData.totalUnreadNotificationCount > 0 && (
              <FontAwesomeIcon
                icon={faBellOn}
                size="lg"
                className="text-yellow-400 m-4"
              />
            )}
          </button>
        }
      >
        <>
          <div className="flex flex-row justify-between py-1 px-4 border-b border-b-gray-200 bg-gray-100 rounded-t-lg">
            <span className="text-lg font-medium">
              {t("Notifications.Heading")}
            </span>

            {notificationData.totalUnreadNotificationCount > 0 && (
              <button
                onClick={onPopoverMarkAllAsReadClickHandler}
                className="justify-end text-xs font-normal underline self-center"
              >
                {t("Notifications.MarkAllAsRead")}
              </button>
            )}
          </div>
          {notificationData.totalNotificationCount == 0 && (
            <div className="p-2 px-4">
              <p className="text-sm font-medium text-gray-600">
                {t("Notifications.NoNotifications")}
              </p>
            </div>
          )}
          {notificationData.totalNotificationCount > 0 && (
            <>
              <div className="divide-y">
                {notificationData.recentNotifications.map((notification) => (
                  <div key={notification.id}>
                    {/* Clickable Notification */}
                    {notification.href != null && (
                      <Link
                        to={decodeURIComponent(notification.href)}
                        onClick={() => setPopoverIsOpen(false)}
                        className="block my-1 hover:bg-gray-100 py-2 px-4"
                      >
                        <div className="flex flex-row">
                          {/* Unread */}
                          {notification.dateRead == null && (
                            <div className="pr-2 pt-1">
                              <span className="flex h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-400"></span>
                              </span>
                            </div>
                          )}
                          {/* Read */}
                          {notification.dateRead != null && (
                            <div className="pr-4">&nbsp;</div>
                          )}
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-600">
                              {notification.message}
                            </span>
                            <span className="text-xs text-gray-400 underline">
                              <FormattedDate
                                date={notification.dateSent}
                                displayMode="DATE-ONLY"
                              />
                            </span>
                          </div>
                        </div>
                      </Link>
                    )}

                    {/* Non-clickable Notification */}
                    {notification.href == null && (
                      <div className="block py-2 px-4">
                        <div className="flex flex-row">
                          {/* Unread */}
                          {notification.dateRead == null && (
                            <div className="pr-2 pt-1">
                              <span className="flex h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-400"></span>
                              </span>
                            </div>
                          )}
                          {/* Read */}
                          {notification.dateRead != null && (
                            <div className="pr-4">&nbsp;</div>
                          )}
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-600">
                              {notification.message}
                            </span>
                            <span className="text-xs text-gray-400 underline">
                              <FormattedDate
                                date={notification.dateSent}
                                displayMode="DATE-ONLY"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* View More Footer */}
              {notificationData.totalNotificationCount > 3 && (
                <div className="!mt-0 border-t border-b-gray-200 bg-gray-100 rounded-b-lg py-0.5 text-center hover:bg-gray-300">
                  <div className="w-full">
                    <button
                      onClick={onViewMoreClickHandler}
                      className="w-full text-sm font-normal"
                    >
                      {t("Notifications.ViewMore")}{" "}
                      {notificationData.totalUnreadNotificationCount -
                        recentUnreadNotificationsCount !=
                        0 && (
                        <span>
                          (
                          {notificationData.totalUnreadNotificationCount -
                            recentUnreadNotificationsCount}
                          )
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </Popover>

      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={onModalCloseChangeHandler}
        onPrimaryButtonClick={() => onModalCloseChangeHandler(false)}
        primaryButtonText={t("Close")}
        title={t("Notifications.Heading")}
        showCloseIcon={false}
      >
        {notificationData.totalUnreadNotificationCount > 0 && (
          <div className="block text-right">
            <button
              onClick={onModalMarkAllAsReadClickHandler}
              className="text-xs font-normal underline self-center"
            >
              {t("Notifications.MarkAllAsRead")}
            </button>
          </div>
        )}
        <div className="divide-y">
          {modalDisplayNotifications &&
            modalDisplayNotifications.map((notification) => (
              <div key={notification.id}>
                {/* Clickable Notification */}
                {notification.href != null && (
                  <Link
                    to={decodeURIComponent(notification.href)}
                    onClick={() => setModalIsOpen(false)}
                    className="block my-1 hover:bg-gray-100 py-2 px-4"
                  >
                    <div className="flex flex-row">
                      {/* Unread */}
                      {notification.dateRead == null && (
                        <div className="pr-2 pt-1">
                          <span className="flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-400"></span>
                          </span>
                        </div>
                      )}
                      {/* Read */}
                      {notification.dateRead != null && (
                        <div className="pr-4">&nbsp;</div>
                      )}
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-600">
                          {notification.message}
                        </span>
                        <span className="text-xs text-gray-400 underline">
                          <FormattedDate
                            date={notification.dateSent}
                            displayMode="DATE-ONLY"
                          />
                        </span>
                      </div>
                    </div>
                  </Link>
                )}

                {/* Non-clickable Notification */}
                {notification.href == null && (
                  <div className="block py-2 px-4">
                    <div className="flex flex-row">
                      {/* Unread */}
                      {notification.dateRead == null && (
                        <div className="pr-2 pt-1">
                          <span className="flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-400"></span>
                          </span>
                        </div>
                      )}
                      {/* Read */}
                      {notification.dateRead != null && (
                        <div className="pr-4">&nbsp;</div>
                      )}
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-600">
                          {notification.message}
                        </span>
                        <span className="text-xs text-gray-400 underline">
                          <FormattedDate
                            date={notification.dateSent}
                            displayMode="DATE-ONLY"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>

        {modalDisplayNotifications && modalDisplayNotifications.length > 0 && (
          <div className="ml-9">
            {/* https://www.npmjs.com/package/react-js-pagination */}
            <Pagination
              activePage={activePageNumber}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={totalItemCount}
              pageRangeDisplayed={5}
              onChange={handlePaginationChange}
              activeClass="pagination-active-list-item"
              itemClass="pagination-list-item"
              itemClassFirst="pagination-first-item"
              itemClassLast="pagination-last-item"
            />
          </div>
        )}
      </ModalPopup>
    </div>
  );
};

export default NotificationMenu;
