import { t } from "i18next";
import { AnswerSetApprovalStatus, ApprovalFlow } from "../types/collab-docs";
import {
  CollabDocSaveButtonArg,
  CollabDocSaveButtonItemDto,
} from "../types/collab-docs/CollabDocFooterSaveDropDownItems";
import CollabDocSaveButtonOptions from "../types/collab-docs/CollabDocSaveButtonOptions";

/** A helper with various methods concerning the save buttons in the collab doc UI */
export const approvalFlowHelper = {
  /** Get the save button options for the current scenario for a standard approval flow */
  getCollabDocDropdownSaveButtonOptionsForStandardApprovalFlow: (
    params: CollabDocSaveButtonOptionsFuncParams
  ): CollabDocSaveButtonItemDto[] => {
    const { currentApprovalStatus, otherParticipantName } = params;

    const isAlreadySignedOff =
      currentApprovalStatus === "FULLY-APPROVED" ||
      currentApprovalStatus === "NO-APPROVAL-NECESSARY";

    if (isAlreadySignedOff) {
      // Return no options so the button is hidden
      return [];
    }

    return [
      {
        text: t("Pages.CollabDocs.Submit.SaveAndSignOff"),
        customArg: "SIGN-OFF",
      },
      {
        text: approvalFlowHelper.replaceTokensInText(
          t("Pages.CollabDocs.Submit.SaveAndSendToOtherForApproval"),
          otherParticipantName
        ),
        customArg: "SEND-FOR-APPROVAL",
      },
      {
        text: t("Pages.CollabDocs.Submit.SaveAndComeBackLater"),
        customArg: "SAVE-AND-COME-BACK",
      },
    ];
  },

  /** Get the save button options for the current scenario for a manager only approval flow */
  getCollabDocDropdownSaveButtonOptionsForManagerOnlyApprovalFlow: (
    params: CollabDocSaveButtonOptionsFuncParams
  ): CollabDocSaveButtonItemDto[] => {
    const { isSubjectEmployee, currentApprovalStatus, otherParticipantName } =
      params;

    const isAlreadySignedOff =
      currentApprovalStatus === "FULLY-APPROVED" ||
      currentApprovalStatus === "NO-APPROVAL-NECESSARY";

    if (isAlreadySignedOff) {
      // Return no options so the button is hidden
      return [];
    }

    if (isSubjectEmployee) {
      // Manager-only-approval, employee buttons
      return [
        {
          text: "Save my changes",
          customArg: "SAVE-AND-COME-BACK",
        },
      ];
    } else {
      // Manager-only-approval, manager buttons
      return [
        {
          text: t("Pages.CollabDocs.Submit.SaveAndComeBackLater"),
          customArg: "SAVE-AND-COME-BACK",
        },
        {
          text: approvalFlowHelper.replaceTokensInText(
            "Save and notify #OTHER_USER_NAME#",
            otherParticipantName
          ),
          customArg: "SAVE-AND-NOTIFY",
        },
        {
          text: t("Pages.CollabDocs.Submit.SaveAndSignOff"),
          customArg: "SIGN-OFF",
        },
      ];
    }
  },

  /** Get the save button options for the current scenario for a hidden to employee approval flow */
  getCollabDocDropdownSaveButtonOptionsForHiddenToEmployeeApprovalFlow: (
    params: CollabDocSaveButtonOptionsFuncParams
  ): CollabDocSaveButtonItemDto[] => {
    const { isSubjectEmployee, currentApprovalStatus } = params;

    const isAlreadySignedOff =
      currentApprovalStatus === "FULLY-APPROVED" ||
      currentApprovalStatus === "NO-APPROVAL-NECESSARY";

    if (isAlreadySignedOff) {
      // Return no options so the button is hidden
      return [];
    }

    if (isSubjectEmployee) {
      // Employee should never see this journey, but hide the button with no options
      return [];
    } else {
      // Hidden-to-employee, manager buttons
      return [
        {
          text: t("Pages.CollabDocs.Submit.SaveAndComeBackLater"),
          customArg: "SAVE-AND-COME-BACK",
        },
        {
          text: t("Pages.CollabDocs.Submit.SaveAndSignOff"),
          customArg: "SIGN-OFF",
        },
      ];
    }
  },

  /** Get the save button options for dual prep planning and forms with instant sign-off disabled, for a standard approval flow */
  getCollabDocNonDropdownSaveButtonOptionsForStandardApprovalFlow: (
    params: CollabDocSaveButtonOptionsFuncParams
  ): CollabDocSaveButtonOptions => {
    const {
      isSubjectEmployee,
      currentApprovalStatus,
      formIsDirty,
      isExitJourney,
      otherParticipantName,
    } = params;
    // Translate some text we'll use in the buttons
    const sendForApprovalText = approvalFlowHelper.replaceTokensInText(
      t("Pages.CollaborativeDocument.Controls.SubmitButton.SendForApproval"),
      otherParticipantName
    );
    const submitAgainText = approvalFlowHelper.replaceTokensInText(
      t("Pages.CollaborativeDocument.Controls.SubmitButton.SubmitAgain"),
      otherParticipantName
    );
    const signOffText = approvalFlowHelper.replaceTokensInText(
      t("Pages.CollaborativeDocument.Controls.SubmitButton.SignOff"),
      otherParticipantName
    );

    let primaryButtonText: string | null = null;
    let primaryButtonProposedStatus: AnswerSetApprovalStatus | null = null;
    let saveForLaterStatus: AnswerSetApprovalStatus;

    if (isSubjectEmployee) {
      saveForLaterStatus = "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING";
      switch (currentApprovalStatus) {
        case "APPROVED-BY-MANAGER":
        case "RETURNED-BY-MANAGER":
          if (formIsDirty) {
            // Changes have been made, mode changes to get approval from other party
            primaryButtonText = sendForApprovalText;
            primaryButtonProposedStatus = "RETURNED-BY-EMPLOYEE";
          } else {
            // No changes since the form was loaded, allow sign-off
            primaryButtonText = signOffText;
            primaryButtonProposedStatus = "FULLY-APPROVED";
          }
          break;
        case "INITIAL-SAVE-FORM-INCOMPLETE":
        case "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING":
        case "SAVED-BY-MANAGER-WITHOUT-APPROVING":
          primaryButtonText = formIsDirty
            ? sendForApprovalText
            : submitAgainText;
          primaryButtonProposedStatus = "APPROVED-BY-EMPLOYEE";
          break;
        case "APPROVED-BY-EMPLOYEE":
          primaryButtonText = formIsDirty
            ? sendForApprovalText
            : submitAgainText;
          primaryButtonProposedStatus = "APPROVED-BY-EMPLOYEE";

          break;
        case "RETURNED-BY-EMPLOYEE":
          if (formIsDirty) {
            primaryButtonText = sendForApprovalText;
            primaryButtonProposedStatus = "RETURNED-BY-EMPLOYEE";
          }
          break;
        case "DUAL-PREP-INCOMPLETE":
          saveForLaterStatus = "DUAL-PREP-INCOMPLETE";
          primaryButtonText = submitAgainText;
          primaryButtonProposedStatus = "DUAL-PREP-SUBMITTED";
          break;
        case "FULLY-APPROVED":
        case "NO-APPROVAL-NECESSARY":
        default:
          // Nothing - form should be readonly, and the footer should be hidden as a result
          break;
      }
    } else {
      // formRole is "Manager"
      saveForLaterStatus = "SAVED-BY-MANAGER-WITHOUT-APPROVING";
      switch (currentApprovalStatus) {
        case "APPROVED-BY-EMPLOYEE":
        case "RETURNED-BY-EMPLOYEE":
          if (isExitJourney) {
            primaryButtonText = signOffText;
            primaryButtonProposedStatus = "FULLY-APPROVED";
          } else if (formIsDirty) {
            // Changes have been made, mode changes to get approval from other party
            primaryButtonText = sendForApprovalText;
            primaryButtonProposedStatus = "RETURNED-BY-MANAGER";
          } else {
            // No changes since the form was loaded, allow sign-off
            primaryButtonText = signOffText; // "Sign off form";
            primaryButtonProposedStatus = "FULLY-APPROVED";
          }
          break;
        case "INITIAL-SAVE-FORM-INCOMPLETE":
        case "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING":
        case "SAVED-BY-MANAGER-WITHOUT-APPROVING":
          primaryButtonText = formIsDirty
            ? sendForApprovalText
            : submitAgainText;
          primaryButtonProposedStatus = "APPROVED-BY-MANAGER";
          break;
        case "APPROVED-BY-MANAGER":
          primaryButtonText = formIsDirty
            ? sendForApprovalText
            : submitAgainText;
          primaryButtonProposedStatus = "APPROVED-BY-MANAGER";
          break;
        case "RETURNED-BY-MANAGER":
          if (formIsDirty && !isExitJourney) {
            primaryButtonText = sendForApprovalText;
            primaryButtonProposedStatus = "RETURNED-BY-MANAGER";
          }
          break;
        case "DUAL-PREP-INCOMPLETE":
          saveForLaterStatus = "DUAL-PREP-INCOMPLETE";
          primaryButtonText = submitAgainText;
          primaryButtonProposedStatus = "DUAL-PREP-SUBMITTED";
          break;
        case "FULLY-APPROVED":
        case "NO-APPROVAL-NECESSARY":
        default:
          // Nothing - form should be readonly, and the footer should be hidden as a result
          break;
      }
    }

    return {
      primaryButtonText,
      primaryButtonProposedStatus,
      saveForLaterStatus,
    };
  },

  /** Get the save button options for dual prep planning and forms with instant sign-off disabled, for a manager-only approval flow */
  getCollabDocNonDropdownSaveButtonOptionsForManagerOnlyApprovalFlow: (
    params: CollabDocSaveButtonOptionsFuncParams
  ): CollabDocSaveButtonOptions => {
    const {
      isSubjectEmployee,
      currentApprovalStatus,
      formIsDirty,
      otherParticipantName,
    } = params;

    // Translate some text we'll use in the buttons
    const submitAgainText = approvalFlowHelper.replaceTokensInText(
      t("Pages.CollaborativeDocument.Controls.SubmitButton.SubmitAgain"),
      otherParticipantName
    );

    let primaryButtonText: string | null = null;
    let primaryButtonProposedStatus: AnswerSetApprovalStatus | null = null;
    let saveForLaterStatus: AnswerSetApprovalStatus | null = null;

    const isAlreadySignedOff =
      currentApprovalStatus === "FULLY-APPROVED" ||
      currentApprovalStatus === "NO-APPROVAL-NECESSARY";

    // Don't show buttons in any scenario if the form is readonly (already signed off)
    if (!isAlreadySignedOff) {
      // Options for the subject employee...
      if (isSubjectEmployee) {
        if (formIsDirty) {
          primaryButtonProposedStatus = "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING";
          primaryButtonText = "Save changes";
        } else {
          // Form is not dirty, don't show any save options to the employee
        }
      }
      // Options for the manager/journey manager...
      else {
        saveForLaterStatus = "SAVED-BY-MANAGER-WITHOUT-APPROVING";
        switch (currentApprovalStatus) {
          case "APPROVED-BY-EMPLOYEE":
          case "RETURNED-BY-EMPLOYEE":
          case "INITIAL-SAVE-FORM-INCOMPLETE":
          case "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING":
          case "SAVED-BY-MANAGER-WITHOUT-APPROVING":
          case "APPROVED-BY-MANAGER":
          case "RETURNED-BY-MANAGER":
            primaryButtonText = "Save and come back later";
            primaryButtonProposedStatus = "SAVED-BY-MANAGER-WITHOUT-APPROVING";
            break;
          case "DUAL-PREP-INCOMPLETE":
            saveForLaterStatus = "DUAL-PREP-INCOMPLETE";
            primaryButtonText = submitAgainText;
            primaryButtonProposedStatus = "DUAL-PREP-SUBMITTED";
            break;
          default:
            // Nothing - form should be readonly, and the footer should be hidden as a result
            break;
        }
      }
    }

    return {
      primaryButtonText,
      primaryButtonProposedStatus,
      saveForLaterStatus,
    };
  },

  /** Get the save button options for instant sign-off disabled, for a hidden to employee approval flow */
  getCollabDocNonDropdownSaveButtonOptionsForHiddenToEmployeeApprovalFlow: (
    params: CollabDocSaveButtonOptionsFuncParams
  ): CollabDocSaveButtonOptions => {
    const {
      isSubjectEmployee,
      currentApprovalStatus,
      formIsDirty,
      otherParticipantName,
    } = params;

    // Translate some text we'll use in the buttons
    const submitAgainText = approvalFlowHelper.replaceTokensInText(
      t("Pages.CollaborativeDocument.Controls.SubmitButton.SubmitAgain"),
      otherParticipantName
    );

    let primaryButtonText: string | null = null;
    let primaryButtonProposedStatus: AnswerSetApprovalStatus | null = null;
    let saveForLaterStatus: AnswerSetApprovalStatus | null = null;

    const isAlreadySignedOff =
      currentApprovalStatus === "FULLY-APPROVED" ||
      currentApprovalStatus === "NO-APPROVAL-NECESSARY";

    // Don't show buttons in any scenario if the form is readonly (already signed off)
    if (!isAlreadySignedOff) {
      // Options for the subject employee...
      if (isSubjectEmployee) {
        if (formIsDirty) {
          primaryButtonProposedStatus = "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING";
          primaryButtonText = "Save changes";
        } else {
          // Form is not dirty, don't show any save options to the employee
        }
      }
      // Options for the manager/journey manager...
      else {
        saveForLaterStatus = "SAVED-BY-MANAGER-WITHOUT-APPROVING";
        switch (currentApprovalStatus) {
          case "APPROVED-BY-EMPLOYEE":
          case "RETURNED-BY-EMPLOYEE":
          case "INITIAL-SAVE-FORM-INCOMPLETE":
          case "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING":
          case "SAVED-BY-MANAGER-WITHOUT-APPROVING":
          case "APPROVED-BY-MANAGER":
          case "RETURNED-BY-MANAGER":
            primaryButtonText = "Save and come back later";
            primaryButtonProposedStatus = "SAVED-BY-MANAGER-WITHOUT-APPROVING";
            break;
          case "DUAL-PREP-INCOMPLETE":
            saveForLaterStatus = "DUAL-PREP-INCOMPLETE";
            primaryButtonText = submitAgainText;
            primaryButtonProposedStatus = "DUAL-PREP-SUBMITTED";
            break;
          default:
            // Nothing - form should be readonly, and the footer should be hidden as a result
            break;
        }
      }
    }

    return {
      primaryButtonText,
      primaryButtonProposedStatus,
      saveForLaterStatus,
    };
  },

  replaceTokensInText: (
    translatedText: string,
    otherUserName: string,
    journeyName?: string
  ): string => {
    let output = translatedText.replaceAll("#OTHER_USER_NAME#", otherUserName);
    if (journeyName) {
      output = output.replaceAll("#JOURNEY_NAME#", journeyName);
    }
    return output;
  },

  handleSaveDropDownButtonClickForStandardApprovalFlow: (
    arg: CollabDocSaveButtonArg,
    formIsDirty: boolean,
    isSubjectEmployee: boolean,
    currentApprovalStatus: AnswerSetApprovalStatus,
    onSaveButtonClick: (
      newStatus: AnswerSetApprovalStatus,
      isInstantlySigningOff: boolean
    ) => void,
    setSignOffModalVisibility: (isVisible: boolean) => void
  ) => {
    switch (arg) {
      case "SIGN-OFF":
        // Check whether the user is the Employee or the Manager
        if (isSubjectEmployee) {
          // If no changes have been made to the form, and it was approved/returned by the manager then
          // signing off will be done by the natural flow... else they are going down the instant sign off route
          if (
            !formIsDirty &&
            (currentApprovalStatus === "APPROVED-BY-MANAGER" ||
              currentApprovalStatus === "RETURNED-BY-MANAGER")
          ) {
            onSaveButtonClick("FULLY-APPROVED", false); // Employee signing off via natural flow
          } else {
            setSignOffModalVisibility(true); // Show the instant sign off modal
          }
        } else {
          // If no changes have been made to the form, and it was approved/returned by the employee then
          // signing off will be done by the natural flow... else they are going down the instant sign off route
          if (
            (!formIsDirty &&
              currentApprovalStatus === "APPROVED-BY-EMPLOYEE") ||
            currentApprovalStatus === "RETURNED-BY-EMPLOYEE"
          ) {
            onSaveButtonClick("FULLY-APPROVED", false); // Manager signing off via natural flow
          } else {
            setSignOffModalVisibility(true); // Show the instant sign off modal
          }
        }
        break;
      case "SEND-FOR-APPROVAL":
        if (isSubjectEmployee) {
          onSaveButtonClick("APPROVED-BY-EMPLOYEE", false);
        } else {
          onSaveButtonClick("APPROVED-BY-MANAGER", false);
        }
        break;
      case "SAVE-AND-COME-BACK":
        if (isSubjectEmployee) {
          onSaveButtonClick("SAVED-BY-EMPLOYEE-WITHOUT-APPROVING", false);
        } else {
          onSaveButtonClick("SAVED-BY-MANAGER-WITHOUT-APPROVING", false);
        }
        break;
      case "SAVE-AND-NOTIFY":
        // Not supported - for manager only approval flow only
        break;
    }
  },

  handleSaveDropDownButtonClickForManagerOnlyApprovalFlow: (
    arg: CollabDocSaveButtonArg,
    isSubjectEmployee: boolean,
    handleSaveWithoutValidation: (newStatus: AnswerSetApprovalStatus) => void,
    setSignOffModalVisibility: (isVisible: boolean) => void
  ) => {
    // Subject employee can only save the changes, cannot submit/approve...
    if (isSubjectEmployee) {
      handleSaveWithoutValidation("RETURNED-BY-EMPLOYEE");
      return;
    }

    // Manager logic....
    switch (arg) {
      case "SIGN-OFF":
        setSignOffModalVisibility(true); // Show the instant sign off modal (to confirm they have had the meeting with the employee)
        break;
      case "SAVE-AND-NOTIFY":
        handleSaveWithoutValidation("RETURNED-BY-MANAGER");
        break;
      case "SAVE-AND-COME-BACK":
        handleSaveWithoutValidation("SAVED-BY-MANAGER-WITHOUT-APPROVING");
        break;
    }
  },

  handleSaveDropDownButtonClickForHiddenToEmployeeApprovalFlow: (
    arg: CollabDocSaveButtonArg,
    onSaveButtonClick: (
      newStatus: AnswerSetApprovalStatus,
      isInstantlySigningOff: boolean
    ) => void,
    setSignOffModalVisibility: (isVisible: boolean) => void
  ) => {
    switch (arg) {
      case "SIGN-OFF":
        setSignOffModalVisibility(true); // Show the instant sign off modal (to make sure the manager knows this is the end of the process)
        break;
      case "SAVE-AND-COME-BACK":
        onSaveButtonClick("SAVED-BY-MANAGER-WITHOUT-APPROVING", false);
        break;
    }
  },

  getBodyTextForInstantSignOffPopup: (
    approvalFlow: ApprovalFlow,
    otherParticipantName: string,
    journeyName: string
  ): string => {
    let translationKeyIdentifier: string;
    switch (approvalFlow) {
      case "HIDDEN-TO-EMPLOYEE":
        translationKeyIdentifier =
          "Pages.CollabDocs.Popup.InstantSignOff.HiddenToEmployeeContent";
        break;
      default:
        translationKeyIdentifier =
          "Pages.CollabDocs.Popup.InstantSignOff.Content";
        break;
    }
    return approvalFlowHelper.replaceTokensInText(
      t(translationKeyIdentifier),
      otherParticipantName,
      journeyName
    );
  },

  getConfirmationTextForInstantSignOffPopup: (
    approvalFlow: ApprovalFlow,
    otherParticipantName: string,
    journeyName: string
  ): string => {
    let translationKeyIdentifier: string;
    switch (approvalFlow) {
      case "HIDDEN-TO-EMPLOYEE":
        translationKeyIdentifier =
          "Pages.CollabDocs.Popup.InstantSignOff.HiddenToEmployeeConfirmation";
        break;
      default:
        translationKeyIdentifier =
          "Pages.CollabDocs.Popup.InstantSignOff.Confirmation";
        break;
    }
    return approvalFlowHelper.replaceTokensInText(
      t(translationKeyIdentifier),
      otherParticipantName,
      journeyName
    );
  },
};

export interface CollabDocSaveButtonOptionsFuncParams {
  isSubjectEmployee: boolean;
  currentApprovalStatus: AnswerSetApprovalStatus;
  formIsDirty: boolean;
  isExitJourney: boolean;
  otherParticipantName: string;
}

export default approvalFlowHelper;
