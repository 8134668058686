import AnswerSetRelatedTaskBarItemDto from "../../../types/dtos/dashboard-task-bar/AnswerSetRelatedTaskBarItemDto";
import AnswerSetRelatedTaskBarItem from "./AnswerSetRelatedTaskBarItem";

interface AnswerSetRelatedTaskBarDisplayProps {
  filterCategory: string;
  items: AnswerSetRelatedTaskBarItemDto[];
  clientColour: string;
}

function AnswerSetRelatedTaskBarDisplay({
  filterCategory,
  items,
  clientColour,
}: AnswerSetRelatedTaskBarDisplayProps) {
  return (
    <div>
      <div
        id="scrollable-dashboard-task-bar"
        className="snap-y overflow-y-auto overflow-x-hidden grow ml-1"
        style={{ maxHeight: "340px" }}
      >
        {items.map((item) => (
          <div
            className="snap-start my-2 first-of-type:mt-0 last-of-type:mb-0"
            key={`asr-dashboard-task-bar-item-${item.answerSetUniqueId}`}
          >
            <AnswerSetRelatedTaskBarItem
              key={item.answerSetUniqueId}
              itemType={filterCategory}
              title={item.title}
              answerSetGuidId={item.answerSetUniqueId}
              answerSetOtherParticipantFullName={
                item.answerSetOtherParticipantFullName
              }
              scheduledDate={item.scheduledDate}
              isPlanning={item.isPlanning}
              isCompleted={item.isCompleted}
              clientColour={clientColour}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AnswerSetRelatedTaskBarDisplay;
