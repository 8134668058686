import { SummaryTableRow } from "./SummaryTableRow";
import cx from "classnames";

interface SummaryTableProps {
  containerClassNames?: string | undefined;
  rows: SummaryTableRow[];
  fixedFirstColumn?: boolean;
}

/** A table showing some key properties of an object, e.g. as a summary before a call to action to delete it */
export const SummaryTable = ({
  rows,
  containerClassNames = undefined,
  fixedFirstColumn = false,
}: SummaryTableProps) => {
  if (!rows || rows.length === 0) return null;

  return (
    <div className={containerClassNames}>
      <table className={cx(
        "text-left text-sm border rounded-sm",
        !fixedFirstColumn ? "table-auto" : "table-fixed w-full"
      )}>
        <tbody>
          {rows.map((row) => (
            <tr key={row.name}>
              <td className={cx(
                "border-b font-medium px-2 py-1 bg-gray-100",
                !fixedFirstColumn ? "whitespace-nowrap" : "whitespace-normal w-3/12"
              )}>
                {row.name}
              </td>
              <td className={cx(
                "border-b border-l px-2 py-1 bg-gray-50",
                !fixedFirstColumn ? "w-full" : ""
              )}>
                {row.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;
