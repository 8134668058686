import { t } from "i18next";
import { useContext, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import userApi from "../api/user/userApi";
import { MainContainer } from "../components/layout";
import FullScreenLoaderWithMessage from "../components/loaders/FullScreenLoaderWithMessage";
import { localStorageHelper } from "../helpers";
import AppContext from "../state/AppContext";
import UserContext from "../state/UserContext";
import SignedInUserDto from "../types/dtos/users/SignedInUserDto";
import { useParams } from "react-router-dom";

type RedirectProps = {
  redirectTo:
    | "ADMIN"
    | "ADMIN-EMPLOYEE-DETAIL"
    | "ANALYTICS"
    | "COMPANY_DASHBOARD"
    | "HELP"
    | "PROFILE"
    | "CHANGE_PASSWORD"
    | "REVIEW_HISTORY"
    | "TASK_LIST"
    | "YOUR_GOALS"
    | "NOTIFICATION_PREFERENCES"
    | "LOGOUT";
};

const OgAppRedirect = ({ redirectTo }: RedirectProps) => {
  const auth = useAuth();
  const { userId } = useParams();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const doRedirect = (baseUrl: string) => {
    let page: string;
    switch (redirectTo) {
      case "ADMIN":
        page = "/admin/employees/search";
        break;
      case "ADMIN-EMPLOYEE-DETAIL":
        page = `/admin/employees/edit/${userId}`;
        break;
      case "ANALYTICS":
        page = "/analytics-redirect";
        break;
      case "HELP":
        page = "/help";
        break;
      case "PROFILE":
        page = "/profile";
        break;
      case "CHANGE_PASSWORD":
        page = "/usersettings";
        break;
      case "YOUR_GOALS":
        page = "/tasks/goaledit";
        break;
      case "REVIEW_HISTORY":
        page = "/review-history";
        break;
      case "TASK_LIST":
        page = "/tasks";
        break;
      case "NOTIFICATION_PREFERENCES":
        page = "/subscription";
        break;
      case "LOGOUT":
        page = "/auth/logout";
        break;
      case "COMPANY_DASHBOARD":
      default:
        page = "/dashboard";
        break;
    }

    window.location.href = baseUrl + page;
  };

  useEffect(() => {
    appContext.setPageTitle("");
    appContext.setShowPageTitleAccent(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Lifecycle events
  useEffect(() => {
    // Do the redirect if we have the base url in context (should be true when not a brand new tab),
    // otherwise wait for auth so we can load the current user from the API to get the base url
    if (
      userContext.user.client.mvcAppUrl &&
      userContext.user.client.mvcAppUrl.startsWith("http")
    ) {
      // Already have the base url in context, so use that
      doRedirect(userContext.user.client.mvcAppUrl);
    } else if (auth.isAuthenticated) {
      // Load the base url from the API
      const apiUsers = new userApi(auth.user?.access_token);
      apiUsers.getLoggedInUserDetails(
        (apiUserDto: SignedInUserDto) => {
          // Set the local storage value for the client MVC route,
          // so we can redirect there if the user gets logged out.
          // This gets done in App.tsx, but this is useful here for new tabs
          localStorageHelper.setItem(
            "USER_MVC_APP_URL",
            apiUserDto.client.mvcAppUrl
          );

          doRedirect(apiUserDto.client.mvcAppUrl);
        },
        (error) => {
          console.log("User api error", error);
        }
      );
    }
  }, [auth.isAuthenticated]);

  return (
    <MainContainer>
      <FullScreenLoaderWithMessage message={t("Common.Loading") + "..."} />
    </MainContainer>
  );
};

export default OgAppRedirect;
