import { useCallback, useContext, useEffect, useState } from "react";
import { t } from "i18next";
import {
  AnswerSetApprovalStatus,
  AnswerSetMode,
  ApprovalFlow,
} from "../../types/collab-docs";
import { CollabDocFormDto } from "../../types/dtos/collab-docs";
import CollabDocHeader from "./CollabDocHeader";
import SmallLoader from "../loaders/SmallLoader";
import CollabDocFooter from "./CollabDocFooter";
import {
  QuestionAnswer,
  QuestionAnswerType,
  QuestionAnswerValue,
  QuestionTasks,
} from "../../types/forms";
import CollabDocForm from "./CollabDocForm";
import collabDocValidator from "../../helpers/collabDocValidator";
import { AlertPopup } from "../common";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import UserContext from "../../state/UserContext";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import {
  EnforcedCommentType,
  FormComplexities,
  NewCommentDto,
  SavedCommentDto,
} from "../../types/dtos/forms";
import ManageCatchUpPopup from "../catch-ups/ManageCatchUpPopup";
import CollabDocCatchUpDetails from "../../types/catch-ups/CollabDocCatchUpDetails";
import { CollabDocState } from "../../state/CollabDoc/CollabDocState";

interface CollaborativeDocProps {
  isLoading: boolean;
  answerSetUniqueId: string;
  answerSetDateCreated: Date;
  /** If viewing one specific form from a summary tab, this should have a value */
  specificFormId: number | undefined;
  approvalStatus: AnswerSetApprovalStatus;
  discussionExists: boolean;
  lastUpdated: Date | null;
  loggedInUserId: number;
  subjectUser: BaseUserDetailsDto;
  lastUpdatedByUserId: number | null;
  isReadOnly: boolean;
  isLocked: boolean;
  hasBeenDelegated: boolean;
  comments: Array<SavedCommentDto>;
  participants: Array<UserBasicDetailsDto>;
  managerPlanningWasSkipped: boolean;
  forms: Array<CollabDocFormDto>;
  formComplexities: FormComplexities;
  answers: Array<QuestionAnswer>;
  tasks: Array<QuestionTasks>;
  collabDocTitle: string;
  activeQuestionId: string | null;
  /** A hex background colour, without the hash */
  backgroundColour: string;
  /** Whether or not the form has been edited by the user since it was loaded. Controls the "Revert changes" button */
  formIsDirty: boolean;
  /** Whether or not the form is an Exit Journey */
  isExitJourney: boolean;
  /** The date/time the form data was loaded (must be UTC) */
  dateLoaded: Date;
  /** If the form can't be submitted yet due to form config settings, this date should have a value in the future */
  lockedForSubmissionUntil: Date | null;
  /** Whether or not to display the banner which gives the user the option to start a new collab doc based on the current completed one */
  showUpdateDocBanner: boolean;
  flaggedChanges: CollabDocFlaggedChangeDto[];
  mode: AnswerSetMode;
  /** Whether or not we're in Dual Prep prep mode */
  isInPrepMode: boolean;
  /** Whether or not to display a warning that not all questions may have been cloned */
  warnAboutDifferentFormVersions: boolean;
  /** Whether or not to allow the user to discard this answer set */
  userCanDiscardAnswerSet: boolean;
  journeyName: string;
  instantSignOffIsDisabled: boolean | null;
  instantlySignedOffByUser: BaseUserDetailsDto | null;
  managerPlanningIsOptional: boolean | null;
  approvalFlow: ApprovalFlow;
  docState: CollabDocState;
  setDocState(value: Partial<CollabDocState>): void;
  /** A method to call to update the answer state */
  onValueChange(
    questionId: string,
    newValue: QuestionAnswerValue,
    answerType: QuestionAnswerType
  ): QuestionAnswer | null;
  /** The active question determines the state of the comments sidebar */
  onChangeActiveQuestion(activeQuestionId: string): void;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(
    newComment: NewCommentDto,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to delete a comment */
  onCommentDelete(
    commentId: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A method to call to update the state value (single) enforced comment for this question (if one exists, otherwise creates a new comment) */
  onEnforcedCommentEdit(
    questionId: string,
    commentText: string,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ): SavedCommentDto;
  /** A method to call to update the enforced comment in the database */
  onEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When the user clicks the button to submit the document for approval etc */
  onSubmitDocument(
    proposedStatus: AnswerSetApprovalStatus,
    isInstantlySigningOff?: boolean
  ): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(
    questionTasks: QuestionTasks,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When wanting to save a question's answer via the API */
  onValueSave(
    answer: QuestionAnswer,
    nonStateValue: QuestionAnswer | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the answer state (e.g. on save failure) */
  onRetryValueSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for tasks (e.g. on save failure) */
  onRetryTasksSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;
  /** When attempting to save again what is in the state for enforced comments (e.g. on save failure) */
  onRetryEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  onDiscardAnswerSet: () => void;
  handleSkipConfirmationModalClick: () => void;
}

const CollaborativeDoc = ({
  isLoading,
  isReadOnly,
  isLocked,
  hasBeenDelegated,
  answerSetUniqueId,
  answerSetDateCreated,
  discussionExists,
  comments,
  tasks,
  loggedInUserId,
  forms,
  formComplexities,
  answers,
  backgroundColour,
  participants,
  managerPlanningWasSkipped,
  lastUpdated,
  lastUpdatedByUserId,
  activeQuestionId,
  approvalStatus,
  dateLoaded,
  subjectUser,
  formIsDirty,
  isExitJourney,
  flaggedChanges,
  lockedForSubmissionUntil,
  mode,
  isInPrepMode,
  showUpdateDocBanner,
  collabDocTitle,
  specificFormId,
  warnAboutDifferentFormVersions,
  userCanDiscardAnswerSet,
  journeyName,
  instantSignOffIsDisabled,
  instantlySignedOffByUser,
  managerPlanningIsOptional,
  approvalFlow,
  docState,
  setDocState,
  onValueChange,
  onValueSave,
  onChangeActiveQuestion,
  onCommentsSeen,
  onCommentAdd,
  onCommentDelete,
  onSubmitDocument,
  onChangeQuestionTasks,
  onEnforcedCommentEdit,
  onEnforcedCommentSave,
  onRetryValueSave,
  onRetryTasksSave,
  onRetryEnforcedCommentSave,
  onDiscardAnswerSet,
  handleSkipConfirmationModalClick,
}: CollaborativeDocProps) => {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [showValidationFailedAlert, setShowValidationFailedAlert] =
    useState<boolean>(false);

  const [showChangeDetails, setShowChangeDetails] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [catchUpDetails, setCatchUpDetails] = useState<
    CollabDocCatchUpDetails | undefined
  >(undefined);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  useEffect(() => {
    const newCollabDocDiscussionDetails: CollabDocCatchUpDetails = {
      answerSetUniqueId: answerSetUniqueId,
      answerSetDateCreated: answerSetDateCreated,
      discussionExists: discussionExists,
      subjectUserId: subjectUser.userId,
      participantEmployeeId: participants.find(
        (p) => p.userId != subjectUser.userId
      )?.userId,
    };
    setCatchUpDetails(newCollabDocDiscussionDetails);
  }, [participants, answerSetUniqueId]);

  // When the user clicks to add a new Catch Up
  const onModalOpenChange = (isOpen: boolean) => {
    setModalIsOpen(isOpen);
  };

  // Callbacks
  const triggerValidationErrors = useCallback(
    () => setShowValidationErrors(true),
    []
  );

  // Events
  const handleSubmitClick = (
    proposedStatus: AnswerSetApprovalStatus,
    isInstantlySigningOff: boolean,
    skipValidation: boolean
  ) => {
    if (skipValidation) {
      onSubmitDocument(proposedStatus, false);
    } else {
      // Handle validation failure - don't allow to continue, alert user about any validation failures
      const valid = collabDocValidator.isValid(
        forms,
        answers,
        tasks,
        comments,
        userContext.user.id,
        subjectUser.userId,
        proposedStatus
      );
      if (valid) {
        onSubmitDocument(proposedStatus, isInstantlySigningOff);
      } else {
        setShowValidationFailedAlert(true);
      }
    }
  };

  // Show the loading spinner whilst the page is loading
  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <>
      {forms.map((form, formIndex) => {
        const questionIds = form.questions.map((x) => x.questionId);
        const formComments = comments.filter(
          (x) => questionIds.indexOf(x.questionId) >= 0
        );
        const formAnswers = answers.filter(
          (x) => questionIds.indexOf(x.questionId) >= 0
        );

        return (
          <div
            key={form.formId}
            className="flex flex-col -space-y-16 lg:-space-y-20"
          >
            {formIndex === 0 && (
              <div className="-z-1 h-50">
                <CollabDocHeader
                  formBackgroundColour={backgroundColour}
                  participants={participants}
                  lastUpdated={lastUpdated!}
                  lastUpdatedByUserId={lastUpdatedByUserId!}
                  isReadOnly={isReadOnly}
                  isLocked={isLocked}
                  hasBeenDelegated={hasBeenDelegated}
                  subjectUser={subjectUser}
                  loggedInUserId={userContext.user.id}
                  mode={mode}
                  isInPrepMode={isInPrepMode}
                  approvalStatus={approvalStatus}
                  isPrinting={isPrinting}
                  discussionExists={discussionExists}
                  onTogglePrinting={setIsPrinting}
                  showUpdateDocBanner={showUpdateDocBanner}
                  managerPlanningWasSkipped={managerPlanningWasSkipped}
                  collabDocTitle={collabDocTitle}
                  answerSetUniqueId={answerSetUniqueId}
                  specificFormId={specificFormId}
                  warnAboutDifferentFormVersions={
                    warnAboutDifferentFormVersions
                  }
                  userCanDiscardAnswerSet={userCanDiscardAnswerSet}
                  onDiscardAnswerSet={onDiscardAnswerSet}
                  instantlySignedOffByUser={instantlySignedOffByUser}
                  managerPlanningIsOptional={managerPlanningIsOptional}
                  handleSkipConfirmationModalClick={
                    handleSkipConfirmationModalClick
                  }
                />
              </div>
            )}

            <div className={formIndex > 0 ? "print-start-new-page" : ""}>
              <CollabDocForm
                answerSetUniqueId={answerSetUniqueId}
                answerSetDateCreated={answerSetDateCreated}
                form={form}
                approvalStatus={approvalStatus}
                activeQuestionId={activeQuestionId}
                answers={formAnswers}
                comments={formComments}
                flaggedChanges={flaggedChanges}
                isInPrepMode={isInPrepMode}
                isReadOnly={isReadOnly}
                isLocked={isLocked}
                docState={docState}
                setDocState={setDocState}
                onChangeActiveQuestion={onChangeActiveQuestion}
                onCommentAdd={onCommentAdd}
                onCommentDelete={onCommentDelete}
                onCommentsSeen={onCommentsSeen}
                onValueChange={onValueChange}
                onValueSave={onValueSave}
                participants={participants}
                showValidationErrors={showValidationErrors}
                dateLoaded={dateLoaded}
                showChangeDetails={showChangeDetails}
                subjectUser={subjectUser}
                tasks={tasks}
                isPrinting={isPrinting}
                onChangeQuestionTasks={onChangeQuestionTasks}
                backgroundColour={backgroundColour}
                formComplexities={formComplexities}
                onEnforcedCommentEdit={onEnforcedCommentEdit}
                onEnforcedCommentSave={onEnforcedCommentSave}
                onRetryValueSave={onRetryValueSave}
                onRetryTasksSave={onRetryTasksSave}
                onRetryEnforcedCommentSave={onRetryEnforcedCommentSave}
              />
            </div>
          </div>
        );
      })}
      {!isReadOnly && (
        <CollabDocFooter
          formIsDirty={formIsDirty}
          isExitJourney={isExitJourney}
          approvalStatus={approvalStatus}
          participants={participants}
          journeyName={journeyName}
          onSubmitDocument={handleSubmitClick}
          onValidate={triggerValidationErrors}
          formRole={
            subjectUser.userId === userContext.user.id ? "EMPLOYEE" : "MANAGER"
          }
          lockedForSubmissionUntil={lockedForSubmissionUntil}
          instantSignOffIsDisabled={instantSignOffIsDisabled}
          approvalFlow={approvalFlow}
        />
      )}
      {/* Validation failed alert */}
      <AlertPopup
        isOpen={showValidationFailedAlert}
        onOpenChange={setShowValidationFailedAlert}
        onPrimaryButtonClick={() => setShowValidationFailedAlert(false)}
        primaryButtonText={t("Common.OK")}
        bodyText={t(
          "Pages.CollaborativeDocument.Controls.ValidationFailedAlertBody"
        )}
        title={t(
          "Pages.CollaborativeDocument.Controls.ValidationFailedAlertTitle"
        )}
      />

      {/**Modal Pop up for adding a catch up */}
      <ManageCatchUpPopup
        isOpen={modalIsOpen}
        onOpenChange={onModalOpenChange}
        collabDocMeetingDetails={catchUpDetails}
      />
    </>
  );
};

export default CollaborativeDoc;
