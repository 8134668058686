import { useTranslation } from "react-i18next";

function ClientFormLoadingCard() {
  const { t } = useTranslation();
  const loadingText = t("Common.Loading");

  return (
    <>
      <div className="flex flex-col pt-2">
        <div className="flex flex-row">
          <div className="flex flex-col w-3/4">
            <h4 className="coloured-heading">{loadingText}...</h4>
            <hr />
            <div className="flex flex-col gap-2 pt-2">
              <div className="h-6 w-5/6 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-6 w-5/6 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="flex flex-col text-center">
              <div className="border rounded-lg rounded-bl-none rounded-br-none">
                <h3 className="coloured-heading !mb-0">0</h3>
                <h4 className="coloured-heading !text-sm !mt-0">{t("Pages.Admin.Common.Updates_Lower")}</h4>
              </div>
              <div className="border rounded-lg border-t-0 rounded-tl-none rounded-tr-none">
                <p className="text-xs">{loadingText}...</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 pt-4 pb-2">
          <div className="col-start-1 col-end-3 bg-gray-200 rounded animate-pulse"></div>
          <div className="col-end-6 col-span-2 bg-gray-200 rounded animate-pulse">&nbsp;</div>
        </div>
      </div>
      {/* <div className="w-1/4">
        <div>
          <div className="h-14 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
      <div className="w-3/4">
        <div className="pl-2">
          <p className="text-xs font-bold">{t("Common.Loading")}...</p>
          <div className="h-7 col-span-4 mt-3 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div> */}
    </>
  );
}

export default ClientFormLoadingCard;
