import { FallbackProps } from "react-error-boundary";

function ChartError({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div>
      <em>Error rendering chart!</em>
    </div>
  );
}

export default ChartError;
