import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "../common";

interface QuestionReadonlyBadgeProps {
  otherUserName: string;
  isFormCompleted: boolean;
  isInReadOnlyMode: boolean;
  /** Any other class names where you don't want to override the default value like you do for margin, border etc */
  classNames?: string;
}

/** A "Locked" badge to display when the current user can't edit a question due to actor restrictions */
export const QuestionReadonlyBadge = ({
  otherUserName,
  isFormCompleted,
  isInReadOnlyMode,
  classNames = "ml-1",
}: QuestionReadonlyBadgeProps) => {
  let tooltipContentIdentifier = isFormCompleted 
    ? "Pages.CollaborativeDocument.Common.FormCompletedQuestionLockedTooltip"
    : !isInReadOnlyMode 
      ? "Pages.CollaborativeDocument.Common.QuestionLockedBadgeTooltip"
      : "Pages.CollaborativeDocument.Heading.InReadOnlyMode";

  const tooltipContent = t(tooltipContentIdentifier).replace("#OTHER_USER_NAME#", otherUserName);

  return (
    <Tooltip
      triggerElement={
        <span className={cx(
          "badge-label px-2 py-0.5 text-xs leading-tight cursor-default select-none bg-gray-400 text-white rounded-sm whitespace-nowrap",
          classNames
        )}>
          <FontAwesomeIcon icon={faLock} size="sm" className="mr-1" />
          <small className="text-xs">
            {t("Pages.CollaborativeDocument.Common.QuestionLockedBadgeText")}
          </small>
        </span>
      }
      content={tooltipContent}
    />
  );
};

export default QuestionReadonlyBadge;
