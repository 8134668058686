import { AuthProviderProps } from "react-oidc-context";

export const idServerConfig = {
  clientId: "TTREACT",
  authAddress: process.env.REACT_APP_IDENTITY_AUTH_ADDRESS,
  requestScopes: "openid+TTReportingApi",
  dashboardAppUrl: process.env.REACT_APP_IDENTITY_APP_URL,
};

export const oidcConfig: AuthProviderProps = {
  /*authority: `${idServerConfig.authAddress}`,
  redirect_uri: `${idServerConfig.dashboardAppUrl}/auth/callback`,
  post_logout_redirect_uri: `${idServerConfig.dashboardAppUrl}/index.html`,
  silent_redirect_uri: `${idServerConfig.dashboardAppUrl}/auth/sign-in-silent`,*/
  authority: idServerConfig.authAddress || "invalid-config",
  client_id: idServerConfig.clientId,
  client_secret: "TtReactDashboardsSecret",
  redirect_uri: `${idServerConfig.dashboardAppUrl}/auth/callback`,
  response_type: "code",
  scope: "openid TTReportingApi",
};

const identityServerDetails = {
  idServerConfig,
  oidcConfig,
};

export default identityServerDetails;
