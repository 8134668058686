import {
  addDays,
  endOfDay,
  startOfDay,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";
import { StaticRange } from "react-date-range";

export const staticDateValues = {
  endOfToday: endOfDay(new Date()),
  startOf7DaysAgo: startOfDay(addDays(new Date(), -7)),
  startOf30DaysAgo: startOfDay(addDays(new Date(), -30)),
  startOf3MonthsAgo: startOfDay(addMonths(new Date(), -3)),
  startOf6MonthsAgo: startOfDay(addMonths(new Date(), -6)),
  startOf1YearAgo: startOfDay(addYears(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range: { startDate: number | Date; endDate: number | Date }) {
    // @ts-ignore
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges: any[]): StaticRange[] {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "Last 7 Days",
    range: () => ({
      startDate: staticDateValues.startOf7DaysAgo,
      endDate: staticDateValues.endOfToday,
    }),
  },
  {
    label: "Last 30 Days",
    range: () => ({
      startDate: staticDateValues.startOf30DaysAgo,
      endDate: staticDateValues.endOfToday,
    }),
  },
  {
    label: "Last 3 Months",
    range: () => ({
      startDate: staticDateValues.startOf3MonthsAgo,
      endDate: staticDateValues.endOfToday,
    }),
  },
  {
    label: "Last 6 Months",
    range: () => ({
      startDate: staticDateValues.startOf6MonthsAgo,
      endDate: staticDateValues.endOfToday,
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: staticDateValues.startOf1YearAgo,
      endDate: staticDateValues.endOfToday,
    }),
  },
]);
