import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "react-oidc-context";
import cx from "classnames";
import collabDocApi from "../../api/forms/collabDocApi";
import TinyLoader from "../loaders/TinyLoader";

interface CollabDocPrintPdfButtonProps {
  answerSetUniqueId: string;
  answerSetTitle: string;
}

const CollabDocPrintPdfButton = ({
  answerSetUniqueId,
  answerSetTitle,
}: CollabDocPrintPdfButtonProps) => {
  // Auth/API
  const auth = useAuth();
  const apiCollabDocs = new collabDocApi(auth.user?.access_token);

  // State
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  const handlePrintClick = () => {
    setIsCallingApi(true);
    apiCollabDocs.downloadPdf(
      answerSetUniqueId,
      answerSetTitle,
      onPrintSuccess,
      onPrintError
    );
  };

  const onPrintSuccess = () => {
    setIsCallingApi(false);
  };

  const onPrintError = (error: any) => {
    setIsCallingApi(false);
    console.log("Error downloading PDF", error);
  };

  return (
    <div className="hidden md:block mb-1 text-right">
      <button
        onClick={handlePrintClick}
        className={cx(" text-white px-2 py-1 text-sm hover:underline ", {
          "hover:cursor-wait": isCallingApi,
          "hover:cursor-pointer": !isCallingApi,
        })}
        disabled={isCallingApi}
      >
        <TinyLoader
          colour={"ffffff"}
          classNames="mr-1"
          isLoading={isCallingApi}
        />
        <FontAwesomeIcon icon={faPrint} size="xs" className="mr-1" />
        Print
      </button>
    </div>
  );
};

export default CollabDocPrintPdfButton;
