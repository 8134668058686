import { ReactNode } from "react";
import { ModalPopup } from "../common";
import { TabDetails } from "../common/Tabs";

interface MobileSummaryTabPopUpProps {
  tabModalIsOpen: boolean;
  tabs: TabDetails[];
  customIcon?: ReactNode | undefined;
  setTabModalIsOpen(open: boolean): void;
  onItemClick?(itemIndex: number, customArg: any | undefined): void | undefined;
}

const MobileSummaryTabPopUp = ({
  tabModalIsOpen,
  tabs,
  customIcon,
  setTabModalIsOpen,
  onItemClick,
}: MobileSummaryTabPopUpProps) => {
  const handleItemClick = (index: number) => {
    if (onItemClick) {
      onItemClick(index, index);
      setTabModalIsOpen(false);
    }
  };

  return (
    <ModalPopup
      isOpen={tabModalIsOpen}
      onOpenChange={() => setTabModalIsOpen(!tabModalIsOpen)}
      title={null}
    >
      <div className="py-6 divide-y divide-gray-300">
        {tabs.map((t, tabIndex) => {
          return (
            <div
              key={`${t.title}_${tabIndex}`}
              className="py-4 flex flex-row justify-between font-semibold"
              onClick={() => handleItemClick(tabIndex)}
            >
              <span>
                {t.displayAlertIcon && customIcon && (
                  <span className="pr-2">{customIcon}</span>
                )}
                {t.title}
              </span>
              <button
                className={`text-sm border px-2 border-gray-400 rounded ${
                  t.displayAlertIcon && "bg-primary text-white border-white"
                }`}
              >
                View
              </button>
            </div>
          );
        })}
      </div>
    </ModalPopup>
  );
};

export default MobileSummaryTabPopUp;
