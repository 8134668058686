import dayjs from "dayjs";
import { isDate } from "lodash";
import { dateHelper } from "../../helpers";
import { KeyValuePair, ModifyStatus } from "../generic";
import TaskStatus from "./TaskStatus";
import TaskType from "./TaskType";

export type TaskIdentifierType = number | string;

class BaseEditableTask<TIdType extends TaskIdentifierType> {
  constructor(
    modifyStatus: ModifyStatus,
    taskId: TIdType | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    dateCompleted: Date | null,
    dateCancelled: Date | null,
    status: TaskStatus | null,
    taskType: TaskType
  ) {
    this.modifyStatus = modifyStatus;
    this.taskId = taskId;
    this.title = title;
    this.dateCompleted = dateCompleted;
    this.dateCancelled = dateCancelled;
    this.status = status;

    // If the target date comes in as a string, it's a JSON date from the API,
    // so needs to be converted into a date, then from UTC to local
    if (typeof targetDate === "string") {
      const utcDate = dayjs(targetDate).toDate();
      this.targetDate = dateHelper.convertUtcDateToLocal(utcDate);
    } else {
      this.targetDate = targetDate;
    }
    this.field1 = field1;
    this.taskType = taskType;
  }

  modifyStatus: ModifyStatus;
  taskId: TIdType | null;
  title: string | null;
  targetDate: Date | null;
  field1: string | null;
  dateCompleted: Date | null;
  dateCancelled: Date | null;
  status: TaskStatus | null;
  taskType: TaskType;

  isValid(): boolean {
    return (
      this.title !== null &&
      this.title.length > 0 &&
      this.targetDate !== null &&
      isDate(this.targetDate) &&
      this.field1 !== null &&
      this.field1.length > 0
    );
  }
}

export type EditableTask<TIdType extends TaskIdentifierType> =
  | EditableAction<TIdType>
  | EditableLearningAction<TIdType>
  | EditableGoal<TIdType>;

export class EditableAction<
  TIdType extends TaskIdentifierType
> extends BaseEditableTask<TIdType> {
  constructor(
    modifyStatus: ModifyStatus,
    taskId: TIdType | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    dateCompleted: Date | null,
    dateCancelled: Date | null,
    status: TaskStatus | null
  ) {
    super(
      modifyStatus,
      taskId,
      title,
      targetDate,
      field1,
      dateCompleted,
      dateCancelled,
      status,
      "ACTION"
    );
  }
}

export class EditableLearningAction<
  TIdType extends TaskIdentifierType
> extends BaseEditableTask<TIdType> {
  constructor(
    modifyStatus: ModifyStatus,
    taskId: TIdType | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    field2: string | null,
    field3: string | null,
    dateCompleted: Date | null,
    dateCancelled: Date | null,
    status: TaskStatus | null
  ) {
    super(
      modifyStatus,
      taskId,
      title,
      targetDate,
      field1,
      dateCompleted,
      dateCancelled,
      status,
      "LEARNING"
    );
    this.field2 = field2;
    this.field3 = field3;
  }

  field2: string | null;
  field3: string | null;

  isValid(): boolean {
    return (
      super.isValid() &&
      this.field2 !== null &&
      this.field2.trim().length > 0 &&
      this.field3 !== null &&
      this.field3.trim().length > 0
    );
  }
}

export class EditableGoal<
  TIdType extends TaskIdentifierType
> extends BaseEditableTask<TIdType> {
  constructor(
    modifyStatus: ModifyStatus,
    taskId: TIdType | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    field2: string | null,
    categoryId: number | null,
    dateCompleted: Date | null,
    dateCancelled: Date | null,
    status: TaskStatus | null,
    rolloverFromTaskId: number | null = null
  ) {
    super(
      modifyStatus,
      taskId,
      title,
      targetDate,
      field1,
      dateCompleted,
      dateCancelled,
      status,
      "GOAL"
    );
    this.field2 = field2;
    this.categoryId = categoryId;
    this.rolloverFromTaskId = rolloverFromTaskId;
  }

  field2: string | null;
  categoryId: number | null;

  /** This should only be popupated in journeys/collab docs when rolling over a reviewed goal in a goal setting question */
  rolloverFromTaskId: number | null;

  /** Validation of goal category needs to be handled separately, depending on whether there are goal categories available */
  isValid(): boolean {
    return (
      super.isValid() && this.field2 !== null && this.field2.trim().length > 0
    );
  }

  /** If this user has goal categories, ensure they have selected a valid one from the set */
  goalCategoryIsValid(
    availableGoalCategories: KeyValuePair<number, string>[] | null
  ): boolean {
    // If this user doesn't have goal categories, the field isn't displayed, so is valid
    if (!availableGoalCategories || availableGoalCategories.length === 0)
      return true;
    // User has goal categories, check a selection has been made
    if (!this.categoryId) return false;
    // Return true if a valid category is selected
    return (
      availableGoalCategories.find((x) => x.key === this.categoryId) !==
      undefined
    );
  }
}
