import { useContext } from "react";
import { FormattedDate, SummaryTable } from "../../common";
import { useTranslation } from "react-i18next";
import { ClientTaskTypeCategory } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { NewUserTaskDto } from "../../../types/dtos/tasks/advanced-tasks/add-popup/NewUserTaskDto";
import UserContext from "../../../state/UserContext";
import { simpleFormHelper } from "../../../helpers";
import { SummaryTableRow } from "../../common/SummaryTableRow";
import { SimpleFormContent, SimpleFormAnswer, SimpleFormInstance } from "../../../types/dtos/simple-forms";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";

interface ExistingTaskResponseSummaryTableProps {
  simpleFormInstance: SimpleFormInstance;
  ownerEmployee: BaseUserDetailsDto;
}

const ExistingTaskResponseSummaryTable = ({
  simpleFormInstance,
  ownerEmployee
}: ExistingTaskResponseSummaryTableProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const answers = simpleFormInstance.response.answers;
  let summaryRows: SummaryTableRow[] = [];

  if (answers != null && answers.length > 0) {
    // Loop over each answer that has been given
    answers.forEach((answer) => {
      // Find the corresponding question for this answer
      const matchingQuestion = simpleFormInstance.form.questions.find(
        (q) => q.questionId === answer.questionId
      );
      
      // If there is a matching question then we can proceed
      if (matchingQuestion != null) {
        // Get the question text
        const questionText = simpleFormHelper.getQuestionText(
          matchingQuestion,
          ownerEmployee,
          userContext.user
        );

        // Text Answer Processing...
        if (answer.textAnswer != null) {
          summaryRows.push({
            name: questionText,
            value: answer.textAnswer.answerText,
            sequence: matchingQuestion.sequence
          });
        }

        // Multiple Choice Answer processing...
        if (answer.multiChoiceAnswers != null && answer.multiChoiceAnswers.length > 0) {
          let multiChoiceAnswerText = "";

          // Loop over each multi choice answer
          answer.multiChoiceAnswers.forEach((multiChoiceAnswer, index) => {
            // Get the relevant option from the matchingQuestion for this answer
            const matchingOption = matchingQuestion.options.find(
              (o) => o.optionId === multiChoiceAnswer.selectedOptionId
            );

            // If there is a matching option then we can proceed
            if (matchingOption != null) {

              if (matchingOption.translateDisplayValue && matchingOption.displayValueTranslationKeyIdentifier) {
                multiChoiceAnswerText += t(matchingOption.displayValueTranslationKeyIdentifier);
              } else {
                multiChoiceAnswerText += matchingOption.rawDisplayValue;
              }

              if (index != answer.multiChoiceAnswers!.length - 1) {
                multiChoiceAnswerText += ", ";
              }
            }
          });

          summaryRows.push({
            name: questionText,
            value: multiChoiceAnswerText,
            sequence: matchingQuestion.sequence
          });
        }
      }      
    });

    // Order the summary rows by sequence
    summaryRows = summaryRows.sort((a, b) => a.sequence! - b.sequence!);
  }

  return (
    <SummaryTable
      containerClassNames="mb-2"
      rows={summaryRows}
      fixedFirstColumn={true}
    />
  );
};

export default ExistingTaskResponseSummaryTable;
