import { ReactNode, memo, useEffect, useState } from "react";
import { t } from "i18next";
import MultipleChoiceOptionNumericId, {
  MultipleChoiceOptionStringId,
} from "../../types/forms/MultipleChoiceOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

export interface MultiChoiceOptionItemWithCategoryProps<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
> {
  categoryId: string;
  titleTranslationKeyIdentifier: string;
  options: TOption[];
  forceExpand: boolean;
  getReactNodesForOptions(options: TOption[], hasCategories: boolean): ReactNode;
}

function MultiChoiceOptionItemWithCategory<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
>({
  categoryId,
  titleTranslationKeyIdentifier,
  options,
  forceExpand,
  getReactNodesForOptions,
}: MultiChoiceOptionItemWithCategoryProps<TOption>) {
  const [isExpanded, setIsExpanded] = useState<boolean>(forceExpand);

  useEffect(() => {
    setIsExpanded(isExpanded == true || options.some((o) => o.categoryId === categoryId && o.isSelected));
  }, [options]);

  useEffect(() => {
    // Set expanded based on whether or not its being forced too, or if any of the options are selected
    setIsExpanded(forceExpand || options.some((o) => o.categoryId === categoryId && o.isSelected));
  }, [forceExpand]);

  const toggleExpandableSection = (isExpanded: boolean) => {
    setIsExpanded(!isExpanded);
  }

  return (
    <>
      {options.length > 0 && (
        <div className="border-0 bg-gray-100 p-2 rounded-md mb-2 p-2 text-sm cursor-pointer">
          <div className="flex flex-row" onClick={() => toggleExpandableSection(isExpanded)}>
            <div className="basis-11/12">
              <span className="pl-2 underline">{t(titleTranslationKeyIdentifier)}</span>
            </div>
            <div className="basis-1/12 text-right pr-2">
              {!isExpanded && (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
              {isExpanded && (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </div>
          </div>
          {isExpanded && (
            <div>
              {getReactNodesForOptions(options, true)}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default memo(MultiChoiceOptionItemWithCategory) as typeof MultiChoiceOptionItemWithCategory;
