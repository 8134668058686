import { faCircle, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface IndividualTaskAreaItemProps {
  showTaskPopup(): void;
  activeTaskCount: number;
  taskTypeTranslationKey: string;
}

const IndividualTaskAreaItem = ({
  showTaskPopup,
  activeTaskCount,
  taskTypeTranslationKey,
}: IndividualTaskAreaItemProps) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={showTaskPopup}
      className="flex flex-col flex-1 border border-gray-100 group rounded-md bg-white py-2 relative cursor-pointer border border-gray-200 hover:drop-shadow-md"
    >
      <div className="flex flex-col justify-center self-center">
        <span className="absolute -top-1 -right-1 fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faCircle}
            className="invisible group-hover:visible primary-text absolute top-0 right-0 fa-lg"
          />
          <FontAwesomeIcon
            icon={faPlus}
            className="invisible group-hover:visible text-white"
          />
        </span>
        <span className="text-center text-xl text-gray-500 font-medium">
          {activeTaskCount}
        </span>
        <span className="text-gray-400 text-center text-sm">
          {t(taskTypeTranslationKey)}
        </span>
      </div>
    </div>
  );
};

export default IndividualTaskAreaItem;
