import React from "react";
import { CheckBox, InfoBanner, ModalPopup } from "../common";
import { t } from "i18next";

interface SkipManagerPlanningPopUpProps {
  isOpen: boolean;
  skipPlanningConfirmationTicked: boolean;
  onOpenChange: (open: boolean) => void;
  displayName: string;
  clientFormTitle: string;
  isLoading: boolean;
  onConfirmButtonClick(): void;
  onCancelButtonClick(): void;
  handleSkipPlanningConfirmationTicked(isTicked: boolean): void;
}

export const SkipManagerPlanningPopUp = ({
  isOpen,
  skipPlanningConfirmationTicked,
  displayName,
  clientFormTitle,
  isLoading,
  onOpenChange,
  onConfirmButtonClick,
  onCancelButtonClick,
  handleSkipPlanningConfirmationTicked,
}: SkipManagerPlanningPopUpProps) => {
  const onOpenChangeHandler = (open: boolean) => {
    onOpenChange(open);
  };

  let isPrimaryButtonDisabled = false;
  if (!skipPlanningConfirmationTicked) {
    isPrimaryButtonDisabled = true;
  }

  return (
    <ModalPopup
      title={`Skip your planning for ${displayName}`}
      isOpen={isOpen}
      onOpenChange={onOpenChangeHandler}
      primaryButtonText="Skip Planning"
      onPrimaryButtonClick={onConfirmButtonClick}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onCancelButtonClick}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      preventInitialAutoFocus={true}
      isLoading={isLoading}
    >
      <p>
        You have selected to skip your <strong>{t(clientFormTitle)}</strong>{" "}
        planning for <strong>{displayName}</strong>. If and when {displayName}{" "}
        has submitted their answers, the meeting form will be unlocked straight
        away.
      </p>
      <InfoBanner
        children={
          <div>
            <p>
              Any planning you have already completed will be lost and you won't
              be able to get this planning form back.
            </p>
            <div className="mt-2">
              <CheckBox
                labelText="Are sure you want to proceed?"
                onChange={() =>
                  handleSkipPlanningConfirmationTicked(
                    !skipPlanningConfirmationTicked
                  )
                }
                selected={skipPlanningConfirmationTicked}
                labelClassNames="text-sm pt-1"
                checkboxClassNames="bg-white border border-blue-200 focus:ring-0"
                checkboxPosition="LEFT"
                includeCheckboxContainerClass={false}
              />
              <p className="text-xs italic">
                Please tick the box above if you would like to continue
              </p>
            </div>
          </div>
        }
      />
    </ModalPopup>
  );
};

export default SkipManagerPlanningPopUp;
