import { useTranslation } from "react-i18next";
import GenericDropDownList from "./GenericDropDownList";
import Label from "./Label";
import { KeyValuePair } from "../../types/generic";
import ValidationWarning from "./ValidationWarning";
import { ValidationResult } from "../../types/forms";

interface AppraisalLevelDropDownListProps {
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The current value for the input */
  value: string;
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
  /** The options to display for the appraisal level dropdown */
  options: KeyValuePair<number, string>[]
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

const AppraisalLevelDropDownList = ({
  inputId = "",
  value = "",
  onChange,
  options,
  showValidationErrors = false,
  validationResult = null,
}: AppraisalLevelDropDownListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <Label
        htmlFor={inputId}
        text={t("Pages.Admin.Common.SelectAppraisalLevel")}
      />
      <GenericDropDownList
        currentValue={value ? value : null}
        items={options}
        onChange={onChange}
        className="block w-full"
        inputId={inputId}
        isReadOnly={false}
        includeSelectOption={false}
        applyBorder={true}
      />
    </>
  );
};

export default AppraisalLevelDropDownList;
