import { t } from "i18next";
import FormSummary from "./FormSummary";
import FormSummariesGrid from "./FormSummariesGrid";
import DashboardSummaryTabDto from "../../types/dtos/dashboards/DashboardSummaryTabDto";
import Tabs, { TabDetails } from "../common/Tabs";
import { EditableTask } from "../../types/tasks/EditableTasks";
import { TaskType } from "../../types/tasks";
import ModifyTaskResponseDto from "../../types/dtos/tasks/ModifyTaskResponseDto";

interface MyFormSummariesProps {
  summaries: DashboardSummaryTabDto[];
  isLoading: boolean;
  /** A function to call when the user has selected a different journey to complete from within a summary */
  onTriggerFormFromSummaryTab(
    summaryTab: DashboardSummaryTabDto,
    dualPrepJourneyRef: string | null
  ): void;
  /**A method which calls the api to save a Goal/Learning/Action */
  modifyTaskFromDashboard(
    task: EditableTask<number>,
    onSuccess: () => void
  ): void;
  /**A method to call the api and load the task that has been clicked on in the timeline widget */
  onLoadGoalToEdit(
    taskId: number,
    taskType: TaskType,
    successCallback: (data: ModifyTaskResponseDto<number>) => void
  ): void;
  handleSummaryRefresh(): void;
}

const MyFormSummaries = ({
  summaries,
  isLoading,
  onTriggerFormFromSummaryTab,
  modifyTaskFromDashboard,
  onLoadGoalToEdit,
  handleSummaryRefresh,
}: MyFormSummariesProps) => {
  const formSummaryTabs: Array<TabDetails> = [];

  summaries.map((s) => {
    const tabName = t(s.clientFormTitle);
    formSummaryTabs.push({
      title: tabName,
      content: (
        <FormSummary
          key={s.clientFormId}
          summaryTabData={s}
          onTriggerSectionUpdate={onTriggerFormFromSummaryTab}
          handleSummaryRefresh={handleSummaryRefresh}
          modifyTaskFromDashboard={modifyTaskFromDashboard}
          onLoadGoalToEdit={onLoadGoalToEdit}
        />
      ),
      displayAlertIcon: s.tabHasUpdates,
    });
  });

  return (
    <>
      {isLoading && (
        <FormSummariesGrid>
          <FormSummary
            handleSummaryRefresh={handleSummaryRefresh}
            modifyTaskFromDashboard={modifyTaskFromDashboard}
            onLoadGoalToEdit={onLoadGoalToEdit}
          />
          <FormSummary
            handleSummaryRefresh={handleSummaryRefresh}
            modifyTaskFromDashboard={modifyTaskFromDashboard}
            onLoadGoalToEdit={onLoadGoalToEdit}
          />
          <FormSummary
            handleSummaryRefresh={handleSummaryRefresh}
            modifyTaskFromDashboard={modifyTaskFromDashboard}
            onLoadGoalToEdit={onLoadGoalToEdit}
          />
          <FormSummary
            handleSummaryRefresh={handleSummaryRefresh}
            modifyTaskFromDashboard={modifyTaskFromDashboard}
            onLoadGoalToEdit={onLoadGoalToEdit}
          />
        </FormSummariesGrid>
      )}
      {!isLoading && summaries && summaries.length > 0 && (
        <div className="my-8" id="form-summary">
          <hr></hr>
          <h3 className="pt-4"></h3>

          <Tabs
            tabs={formSummaryTabs}
            selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md"
            maxTabsOnSmallScreens={2}
          />
        </div>
      )}
    </>
  );
};

export default MyFormSummaries;
