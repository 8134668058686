import { useTranslation } from "react-i18next";
import { faUser, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserTagList from "../Tags/UserTagList";

function PeopleHeaderLoadingCard() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex gap-4">
        <div className="pt-1">
          <span className="w-12 h-12 md:ml-1 bg-gray-300 rounded-full animate-pulse ring-2 ring-white text-center flex items-center justify-center font-semibold select-none">
            LD
          </span>
        </div>
        <div className="flex-col">
          <div>
            <h5 className="coloured-heading !mb-0 !mt-0 flex-none text-lg">
              {t("Common.Loading")}...
            </h5>
          </div>
          <div>
            <p className="text-xs text-[#5d5d5d]">
              <span>{t("Dashboards.LiveJourney")}:</span>
              <span className="pl-1">{t("Common.Loading")}...</span>
            </p>
          </div>
          {/* Tags */}
          <div className="mt-1">
            <UserTagList
              jobTitle={t("Pages.Admin.Common.JobTitle")}
              appraisalLevel={t("Common.AppraisalLevel")}
              location={t("Pages.Admin.Common.Location")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PeopleHeaderLoadingCard;
