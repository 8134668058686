import { t } from "i18next";
import cx from "classnames";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FormType } from "../../../../types/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PieChart } from "react-minimal-pie-chart";

interface ReviewedGoalCountProps {
  goalCount: number;
  reviewedGoalIds: number[];
  formType: FormType;
}

function ReviewedGoalCount({
  goalCount,
  reviewedGoalIds,
  formType,
}: ReviewedGoalCountProps) {
  // Set the text for how many goals have been reviewed
  let reviewGoalCountTextIdentifier: string;
  let reviewCompleted: boolean = false;
  if (goalCount === reviewedGoalIds.length) {
    reviewGoalCountTextIdentifier =
      "Forms.Goals.Review.ReviewedGoalCount.AllReviewed";
    reviewCompleted = true;
  } else if (reviewedGoalIds.length === 0) {
    const suffix = goalCount === 1 ? "Singular" : "Plural";
    reviewGoalCountTextIdentifier =
      "Forms.Goals.Review.ReviewedGoalCount.NoneReviewed" + suffix;
  } else {
    reviewGoalCountTextIdentifier =
      "Forms.Goals.Review.ReviewedGoalCount.SomeReviewed";
  }
  const reviewedGoalCountText = t(reviewGoalCountTextIdentifier)
    .replace("#TOTAL_GOALS#", goalCount.toString())
    .replace("#REVIEWED_GOALS#", reviewedGoalIds.length.toString());

  const donutChartSize = "16px";
  let icon = null;
  if (reviewCompleted) {
    icon = <FontAwesomeIcon icon={faCheck} size="1x" />;
  } else {
    const percentComplete = Math.round(
      (100 / goalCount) * reviewedGoalIds.length
    );
    const remainder = 100 - percentComplete;

    icon = (
      <PieChart
        data={[
          { value: percentComplete, color: "#fff" },
          { value: remainder, color: "rgba(255,255,255, 0.4)" },
        ]}
        totalValue={100}
        lineWidth={30}
        style={{ height: donutChartSize, opacity: "0.9" }}
      />
    );
  }

  return (
    <div className={cx("mb-2", formType === "COLLAB-DOC" ? "mt-2" : "")}>
      <div
        className={cx(
          "inline-block text-center text-sm px-2 py-1 rounded-md text-white font-semibold select-none",
          reviewCompleted ? "bg-emerald-400" : "bg-red-400"
        )}
      >
        <div className="flex flex-row gap-x-1 content-center items-center">
          <span
            className="flex items-center"
            style={{ width: donutChartSize, height: donutChartSize }}
          >
            {icon}
          </span>
          <span className="flex items-center">{reviewedGoalCountText}</span>
        </div>
      </div>
    </div>
  );
}

export default ReviewedGoalCount;
