import { AnalyticsPageJourneyFilterRestriction } from "../../types/analytics/AnalyticsFilterInputs";
import { FilterAvailableValuesDto } from "../../types/analytics/FilterAvailableValues";
import { apiClient } from "../apiClient";

class analyticsFiltersApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the filter values available to the logged in user */
  getAvailableFilterValues(
    journeyRestriction: AnalyticsPageJourneyFilterRestriction,
    onSuccess: (data: FilterAvailableValuesDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/filters/available-filters?journeyRestriction=" +
        journeyRestriction
    ).then(
      (data) => {
        const filterValues = data as FilterAvailableValuesDto;
        onSuccess(filterValues);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default analyticsFiltersApi;
