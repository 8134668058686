import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import advancedTasksApi from "../../../../api/task/advancedTasksApi";
import { ScrollableModalContentTemplate } from "../../../common";
import { SimpleFormInstance } from "../../../../types/dtos/simple-forms";
import { UserTaskSaveResponse } from "../../../../types/dtos/tasks/advanced-tasks/api-response/UserTaskSaveResponse";
import { BaseUserDetailsDto } from "../../../../types/dtos/generic";
import InfoAlert from "../../../alerts/InfoAlert";
import WarningAlert from "../../../alerts/WarningAlert";
import ExistingTaskResponseSummaryTable from "../ExistingTaskResponseSummaryTable";
import UserContext from "../../../../state/UserContext";
import { advancedTaskHelper } from "../../../../helpers";
import { TaskEditPopupUsageScenario } from "../../../../types/tasks/TaskEditPopupUsageScenario";

interface AdvancedTaskExistingCloseResponseViewProps {
  userTaskId: string;
  taskTypeId: string;
  closeFormInstance: SimpleFormInstance;
  ownerEmployee: BaseUserDetailsDto;
  usageScenario: TaskEditPopupUsageScenario;
  goBackToBodyDetails(): void;
  onEditResponse(): void;
  onClosed(): void;
}

const AdvancedTaskExistingCloseResponseView = ({
  userTaskId,
  taskTypeId,
  closeFormInstance,
  ownerEmployee,
  usageScenario,
  goBackToBodyDetails,
  onEditResponse,
  onClosed,
}: AdvancedTaskExistingCloseResponseViewProps) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const auth = useAuth();
  const tasksApi = new advancedTasksApi(auth.user?.access_token);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const matchingTaskType = userContext.user.client.taskTypes.find(
    (tt) => tt.id === taskTypeId
  );

  const singularTaskTypeName = matchingTaskType != undefined ? advancedTaskHelper.ToLowerCase(
    matchingTaskType.singularNameTranslationKeyIdentifier
  ) : "task";

  const onCloseWithForm = () => {
    // There's a form to fill out, so validate then post the response
    if (!userTaskId) return;

    const onCloseError = (error: any) => {
      setIsSaving(false);
      console.error("Unable to close task", error);
    };

    const onCloseSuccess = (data: UserTaskSaveResponse) => {
      setIsSaving(false);
      if (data.savedSuccessfully) {
        onClosed();
      } else {
        onCloseError(data.errorReason);
      }
    };

    // Post the response
    setIsSaving(true);
    tasksApi.closeTaskWithForm(
      {
        userTaskId: userTaskId,
        formId: closeFormInstance.form.formId,
        answers: closeFormInstance.response.answers,
        responseId: closeFormInstance.response.responseId || null,
        questionIdsToPullCommentsFrom: matchingTaskType?.commentQuestionIds || []
      },
      usageScenario,
      onCloseSuccess,
      onCloseError
    );
  };

  let headerContent: JSX.Element = (
    <WarningAlert
      message={t("TaskType.Popup.ExistingCloseResponseView.WarningMessage", {
        taskType: singularTaskTypeName,
      })}
      prefix={null}
    />
  );

  let footerContent: JSX.Element = (
    <div>
      <InfoAlert
        message={t("TaskType.Popup.ExistingCloseResponseView.InfoMessage", {
          taskType: singularTaskTypeName,
        })}
        prefix={null}
      />
      <div className="flex flex-row justify-end gap-4">
        <button onClick={goBackToBodyDetails} className="hover:underline">
          {t("TaskType.Popup.Buttons.GoBack")}
        </button>
        <button
          onClick={onEditResponse}
          className="btn-secondary"
          disabled={isSaving}
        >
          {t("TaskType.Popup.Buttons.Edit")}
        </button>
        <button
          onClick={onCloseWithForm}
          className="btn-primary"
          disabled={isSaving}
        >
          {t("TaskType.Popup.Buttons.Submit")}
        </button>
      </div>
    </div>
  );

  return (
    <ScrollableModalContentTemplate
      header={headerContent}
      footer={footerContent}
      bodyRef={scrollableContainerRef}
      containerClassName="flex flex-col gap-1 md:gap-0"
    >
      <div className="pt-2">
        <ExistingTaskResponseSummaryTable
          simpleFormInstance={closeFormInstance}
          ownerEmployee={ownerEmployee}
        />
      </div>
    </ScrollableModalContentTemplate>
  );
};

export default AdvancedTaskExistingCloseResponseView;
