import { useCallback, useState } from "react";
import { t } from "i18next";
import {
  multipleChoiceQuestionHelper,
  questionTypeHelper,
} from "../../helpers";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import {
  CheckBoxListDisplayMode,
  FormQuestion,
  GoalReviewQuestionAnswerValue,
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionTasks,
  ValidationResult,
  BehaviourQuestionAnswerValue,
  DevelopmentQuestionAnswerValue,
  QuestionAnswerType,
  EnforcedCommentSaveErrorStatus,
  QuestionType,
} from "../../types/forms";
import {
  CheckBoxList,
  FormDropDownList,
  RadioButtonGroup,
  Slider,
  TextArea,
  TextInput,
} from "../common";
import {
  BehaviourAttributeQuestion,
  DevelopmentQuestion,
  GoalReviewQuestionStatusAndComment,
  GoalSettingQuestion,
  MultipleBehaviourQuestion,
  TaskQuestion,
} from "../forms/advanced";
import AnswerSaveIndicator from "./AnswerSaveIndicator";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import {
  EnforcedCommentType,
  FormComplexities,
  SavedCommentDto,
} from "../../types/dtos/forms";
import PlanningResponses from "../forms/advanced/dual-prep/PlanningResponses";
import MultipleChoiceOptionNumericId from "../../types/forms/MultipleChoiceOptions";
import TaskManagementQuestion from "../forms/advanced/TaskManagementQuestion";
import AdvancedTaskDto from "../../types/dtos/forms/AdvancedTaskDto";

interface CollabDocQuestionInputProps {
  answerSetUniqueId: string;
  answerSetDateCreated: Date;
  question: FormQuestion;
  userId: number;
  isReadOnly: boolean;
  formActiveQuestionId: string | null;
  inputId: string;
  currentAnswer: QuestionAnswer | null;
  multipleChoiceQuestionOptions: MultipleChoiceOptionNumericId[];
  showValidationErrors: boolean;
  subjectUser: BaseUserDetailsDto;
  formColor: string;
  participants: Array<UserBasicDetailsDto>;
  formComplexities: FormComplexities;
  isPrinting: boolean;
  /** If this question is locked to only employee/manager, and the user's role doesn't match, disable the field */
  isLockedForCurrentUser: boolean;
  /** Recent form answer change to highlight to the user, if there is one.
   * There should be one for normal questions like text, multi-choice, but can be multiple
   * for things like behaviours as it's one QuestionId but multiple fields per behaviour/attribute
   */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;

  /** If validation has been run, this is the validity plus any errors */
  validationResult: ValidationResult | null;
  /** Any tasks added against this question */
  tasks: QuestionTasks | null;
  usesAdvancedTaskTypes: boolean;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(
    questionTasks: QuestionTasks,
    onSuccess: () => void,
    onError: () => void
  ): void;

  /** Any comments for this question */
  comments: Array<SavedCommentDto>;

  showPlanningResponses: boolean | null;
  /** Whether or not we're in Dual Prep prep mode */
  isInPrepMode: boolean;
  /** The onChange event, for handling state changes to the enforced comment */
  onEnforcedCommentChange(
    newValue: string,
    commentFor: EnforcedCommentType,
    objectId: number,
    clientFormId: number
  ): SavedCommentDto;
  /** An event to call when the enforced comment textarea loses focus (to save to the database) */
  onEnforcedCommentSave(
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;

  onMultipleChoiceQuestionValueChange(
    updatedMultiChoiceOptions: MultipleChoiceOptionNumericId[]
  ): void;
  /** Handle the value changing for this question (in the state in parent components) */
  onValueChange(
    newValue: QuestionAnswerValue,
    answerType: QuestionAnswerType
  ): QuestionAnswer | null;

  /** When wanting to save a question's answer via the API */
  onValueSave(
    answer: QuestionAnswer,
    nonStateValue: QuestionAnswer | null,
    onSuccess: () => void,
    onError: () => void
  ): void;

  /** When attempting to save again what is in the answer state (e.g. on save failure) */
  onRetryValueSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;

  /** When attempting to save again what is in the state for tasks (e.g. on save failure) */
  onRetryTasksSave(
    questionId: string,
    onSuccess: () => void,
    onError: () => void
  ): void;

  /** When attempting to save again what is in the state for enforced comments (e.g. on save failure) */
  onRetryEnforcedCommentSave(
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null,
    onSuccess: () => void,
    onError: () => void
  ): void;
  onChangeAdvancedTasks(
    questionId: string,
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[]
  ): void;
}

/** A wrapper for just the input component, i.e. not the label, the comment indicator etc */
function CollabDocQuestionInput({
  answerSetUniqueId,
  answerSetDateCreated,
  question,
  userId,
  isReadOnly,
  formActiveQuestionId,
  inputId,
  currentAnswer,
  multipleChoiceQuestionOptions,
  subjectUser,
  tasks,
  usesAdvancedTaskTypes,
  showValidationErrors,
  validationResult,
  isLockedForCurrentUser,
  formColor,
  participants,
  comments,
  flaggedChanges,
  formComplexities,
  showPlanningResponses,
  isInPrepMode,
  isPrinting,
  onChangeQuestionTasks,
  onMultipleChoiceQuestionValueChange,
  onValueChange,
  onValueSave,
  onEnforcedCommentChange,
  onEnforcedCommentSave,
  onRetryValueSave,
  onRetryTasksSave,
  onRetryEnforcedCommentSave,
  onChangeAdvancedTasks,
}: CollabDocQuestionInputProps) {
  const disableQuestion = isReadOnly || isLockedForCurrentUser;

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingSucceeded, setSavingSucceeded] = useState<boolean>(false);
  const [answerSavingErrored, setAnswerSavingErrored] =
    useState<boolean>(false);
  const [tasksSavingErrored, setTasksSavingErrored] = useState<boolean>(false);
  const [
    enforcedCommentSavingErrorStatus,
    setEnforcedCommentSavingErrorStatus,
  ] = useState<EnforcedCommentSaveErrorStatus>({
    errored: false,
    behaviourId: null,
    goalId: null,
    nonStateValue: null,
  });

  const runPreSaveStateChanges = () => {
    setIsSaving(true);
    setAnswerSavingErrored(false);
    setTasksSavingErrored(false);
    setEnforcedCommentSavingErrorStatus({
      errored: false,
      behaviourId: null,
      goalId: null,
      nonStateValue: null,
    });
  };

  const answerSaveSuccessCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(true);
    setAnswerSavingErrored(false);
    setEnforcedCommentSavingErrorStatus({
      errored: false,
      behaviourId: null,
      goalId: null,
      nonStateValue: null,
    });
  };

  const answerSaveErrorCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(false);
    setAnswerSavingErrored(true);
  };

  const tasksSaveSuccessCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(true);
    setTasksSavingErrored(false);
  };

  const tasksSaveErrorCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(false);
    setTasksSavingErrored(true);
  };

  const enforcedCommentSaveSuccessCallback = () => {
    setIsSaving(false);
    setSavingSucceeded(true);
    setEnforcedCommentSavingErrorStatus({
      errored: false,
      behaviourId: null,
      goalId: null,
      nonStateValue: null,
    });
  };

  const enforcedCommentSaveErrorCallback = (
    behaviourId: number | null,
    goalId: number | null,
    nonStateValue: SavedCommentDto | null
  ) => {
    setIsSaving(false);
    setSavingSucceeded(false);
    setEnforcedCommentSavingErrorStatus({
      errored: true,
      behaviourId: behaviourId,
      goalId: goalId,
      nonStateValue: nonStateValue,
    });
  };

  const handleChangeAdvancedTasks = (
    tasks?: AdvancedTaskDto[],
    cancelledTasks?: AdvancedTaskDto[]
  ) => {
    onChangeAdvancedTasks(question.questionId, tasks, cancelledTasks);
  };

  /** Show the save indicator and call the API to save the answer */
  const handleValueSave = useCallback(
    (
      answerValue: QuestionAnswerValue,
      nonStateValue: QuestionAnswer | null
    ) => {
      if (answerValue === null || answerValue === undefined) {
        return;
      }

      let newAnswer: QuestionAnswer = {
        answer: answerValue,
        answerType: questionTypeHelper.getAnswerTypeForQuestionType(
          question.questionType
        )!,
        formId: question.formId,
        id: null,
        questionId: question.questionId,
        timestamp: new Date(),
        userId: userId,
      };

      runPreSaveStateChanges();
      onValueSave(
        newAnswer,
        nonStateValue,
        answerSaveSuccessCallback,
        answerSaveErrorCallback
      );
    },
    [question, currentAnswer]
  );

  /** Call the API to save the answer for multiple choice questions */
  const handleValueSaveMulti = useCallback(
    (answerValue: MultipleChoiceOptionNumericId[]) => {
      if (answerValue === null || answerValue === undefined) {
        return;
      }

      const multiChoiceAnswerValue =
        multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
          answerValue,
          question
        );

      handleValueSave(multiChoiceAnswerValue, null);
    },
    [question, currentAnswer, onValueSave]
  );

  const handleRetrySave = useCallback(() => {
    if (tasksSavingErrored) {
      // Retry saving tasks
      runPreSaveStateChanges();
      onRetryTasksSave(
        question.questionId,
        answerSaveSuccessCallback,
        answerSaveErrorCallback
      );
    }

    if (answerSavingErrored) {
      // Retry saving answers
      runPreSaveStateChanges();
      onRetryValueSave(
        question.questionId,
        answerSaveSuccessCallback,
        answerSaveErrorCallback
      );
    }

    if (enforcedCommentSavingErrorStatus.errored) {
      // Retry saving enforced comments
      runPreSaveStateChanges();
      onRetryEnforcedCommentSave(
        question.questionId,
        enforcedCommentSavingErrorStatus.behaviourId,
        enforcedCommentSavingErrorStatus.goalId,
        enforcedCommentSavingErrorStatus.nonStateValue,
        answerSaveSuccessCallback,
        () => {
          // Clear the saving flags, but leave
          // `enforcedCommentSavingErrorStatus` as it is
          setIsSaving(false);
          setSavingSucceeded(false);
        }
      );
    }
  }, [
    question,
    currentAnswer,
    onRetryTasksSave,
    onRetryValueSave,
    onRetryEnforcedCommentSave,
    tasksSavingErrored,
    answerSavingErrored,
    enforcedCommentSavingErrorStatus,
  ]);

  const handleTasksSave = (questionTasks: QuestionTasks) => {
    runPreSaveStateChanges();
    onChangeQuestionTasks(
      questionTasks,
      tasksSaveSuccessCallback,
      tasksSaveErrorCallback
    );
  };

  const handleEnforcedBehaviourCommentSave = (
    behaviourId: number,
    nonStateValue: SavedCommentDto | null
  ) => {
    runPreSaveStateChanges();
    onEnforcedCommentSave(
      behaviourId,
      null,
      nonStateValue,
      enforcedCommentSaveSuccessCallback,
      () => enforcedCommentSaveErrorCallback(behaviourId, null, nonStateValue)
    );
  };

  // Note: If the 'handleEnforcedGoalCommentSave' is changed, please check if 'onPlanningResponseCopyClick' needs any adjustments in regards to the goals section.
  // Can't run through this method as adding a SaveCommentDto like 'handleEnforcedBehaviourCommentSave' causes issues.
  const handleEnforcedGoalCommentSave = (goalId: number) => {
    runPreSaveStateChanges();
    onEnforcedCommentSave(
      null,
      goalId,
      null,
      enforcedCommentSaveSuccessCallback,
      () => enforcedCommentSaveErrorCallback(null, goalId, null)
    );
  };

  const onTextValueChange = (
    newValue: QuestionAnswerValue
  ): QuestionAnswer | null => {
    // Just change the value in the state, we'll save it to the API on blur
    return onValueChange(newValue, "TEXT");
  };

  const handleMultiChoiceValueChange = useCallback(
    (updatedMultiChoiceOptions: MultipleChoiceOptionNumericId[]) => {
      onMultipleChoiceQuestionValueChange(updatedMultiChoiceOptions);
    },
    [question, currentAnswer]
  );

  const onBehaviourValueChange = (newValue: QuestionAnswerValue) => {
    onValueChange(newValue, "BEHAVIOUR");
  };

  const onDevelopmentValueChange = (newValue: QuestionAnswerValue) => {
    onValueChange(newValue, "DEVELOPMENT-LIST");
    handleValueSave(newValue, null);
  };

  const onGoalReviewValueChange = (newValue: QuestionAnswerValue) => {
    onValueChange(newValue, "GOAL-REVIEW");
  };

  const onBehaviourEnforcedCommentChange = (
    newValue: string,
    behaviourId: number,
    clientFormId: number
  ): SavedCommentDto => {
    return onEnforcedCommentChange(
      newValue,
      "BEHAVIOUR",
      behaviourId,
      clientFormId
    );
  };

  const onGoalEnforcedCommentChange = (
    goalId: number,
    newValue: string,
    clientFormId: number
  ): SavedCommentDto => {
    return onEnforcedCommentChange(newValue, "GOAL", goalId, clientFormId);
  };

  const questionTypeAllowsPlanningResponsesToBeShown = (
    questionType: QuestionType
  ): boolean => {
    if (
      questionType == "READONLY" ||
      question.questionType == "BEHAVIOUR" ||
      question.questionType == "GOAL-SETTING"
    ) {
      return false;
    } else {
      return true;
    }
  };

  /** Handles the 'Copy' click within Behaviours Planning Responses */
  const onPlanningResponseCopyClick = (
    newValue: string,
    clientFormId: number,
    behaviourId?: number | null,
    goalId?: number | null
  ) => {
    if (behaviourId) {
      // Handles the comment change against behaviours
      const newComment = onBehaviourEnforcedCommentChange(
        newValue,
        behaviourId,
        clientFormId
      );
      handleEnforcedBehaviourCommentSave(behaviourId, newComment);
    } else if (goalId) {
      // Handles the comment change against goals
      const newComment = onGoalEnforcedCommentChange(
        goalId,
        newValue,
        clientFormId
      );
      runPreSaveStateChanges();
      onEnforcedCommentSave(
        null,
        goalId,
        newComment,
        enforcedCommentSaveSuccessCallback,
        () => enforcedCommentSaveErrorCallback(null, goalId, newComment)
      );
    } else {
      // Handles the text answer change
      const newComment = onTextValueChange(newValue);
      handleValueSave(newValue, newComment);
    }
  };

  const isActiveQuestion =
    formActiveQuestionId !== null &&
    formActiveQuestionId === question.questionId;
  const answerSaveIndicator = !isActiveQuestion ? null : (
    <AnswerSaveIndicator
      didSave={savingSucceeded}
      errored={
        answerSavingErrored ||
        tasksSavingErrored ||
        enforcedCommentSavingErrorStatus.errored
      }
      isSaving={isSaving}
      retrySave={handleRetrySave}
    />
  );

  /** Planning Response section for all areas, apart from Behaviours as for those it has had to go further down the levels */
  const planningResponseSection =
    showPlanningResponses &&
    questionTypeAllowsPlanningResponsesToBeShown(question.questionType) ? (
      <PlanningResponses
        subjectUserId={subjectUser.userId}
        participants={participants}
        questionId={question.questionId}
        questionType={question.questionType}
        formId={question.formId}
        onCopyClick={onPlanningResponseCopyClick}
      />
    ) : null;

  switch (question.questionType) {
    case "SLIDER":
      const sliderScoreDisplayMode =
        multipleChoiceQuestionHelper.getSliderScoreDisplayMode(
          multipleChoiceQuestionOptions
        );
      return (
        <>
          {planningResponseSection}
          <Slider
            scaleOptions={multipleChoiceQuestionOptions}
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            selectedValueDisplayMode={sliderScoreDisplayMode}
            isReadOnly={disableQuestion}
            formBackgroundColorStyle={formColor}
            onChange={handleMultiChoiceValueChange}
            onBlur={handleValueSaveMulti}
          />
          {answerSaveIndicator}
        </>
      );
    case "RADIOLIST":
      return (
        <>
          {planningResponseSection}
          <RadioButtonGroup
            uniqueFieldName={`radio_q_${question.questionId}`}
            values={multipleChoiceQuestionOptions}
            onChange={handleMultiChoiceValueChange}
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            isReadOnly={disableQuestion}
            onBlur={handleValueSaveMulti}
            radioColourHexCode={formColor}
          />
          {answerSaveIndicator}
        </>
      );
    case "CHECKLIST":
      const checkboxCount = question.multiChoiceOptions!.length;
      // Change the display mode based on how many checkboxes there are to show
      let displayMode: CheckBoxListDisplayMode = "horizontal";
      if (checkboxCount > 10) {
        displayMode = "grid";
      } else if (checkboxCount > 2) {
        displayMode = "vertical";
      }

      return (
        <>
          {planningResponseSection}
          <CheckBoxList
            uniqueFieldName={`radio_q_${question.questionId}`}
            values={multipleChoiceQuestionOptions}
            onChange={handleMultiChoiceValueChange}
            displayMode={displayMode}
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            isReadOnly={disableQuestion}
            selectMinCount={question.validation.min}
            selectMaxCount={question.validation.max}
            onBlur={handleValueSaveMulti}
            checkboxCheckedBgColourHexCode={formColor}
            iconColourClassName="text-white"
          />
          {answerSaveIndicator}
        </>
      );
    case "LONGTEXT":
      return (
        <>
          {planningResponseSection}
          <TextArea
            inputId={inputId}
            value={currentAnswer ? (currentAnswer.answer as string) : undefined}
            onChange={onTextValueChange}
            onBlur={handleValueSave}
            minRows={1}
            maxRows={6}
            className="mt-2 p-2 w-full rounded-md border border-gray-400"
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            isReadOnly={disableQuestion}
            placeholder={
              question.placeholderText
                ? t(question.placeholderText)
                : t("Common.TextboxDefaultPlaceholder")
            }
          />
          {answerSaveIndicator}
        </>
      );
    case "SHORTTEXT":
      return (
        <>
          {planningResponseSection}
          <TextInput
            inputId={inputId}
            value={currentAnswer ? (currentAnswer.answer as string) : undefined}
            onChange={onTextValueChange}
            onBlur={handleValueSave}
            className="mt-2 p-2 w-full rounded-md border border-gray-400"
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            isReadOnly={disableQuestion}
            placeholder={
              question.placeholderText
                ? t(question.placeholderText)
                : t("Common.TextboxDefaultPlaceholder")
            }
          />
          {answerSaveIndicator}
        </>
      );
    case "DROPDOWNLIST":
      return (
        <>
          {planningResponseSection}
          <FormDropDownList<MultipleChoiceOptionNumericId>
            inputId={inputId}
            values={multipleChoiceQuestionOptions}
            onChange={handleMultiChoiceValueChange}
            onBlur={handleValueSaveMulti}
            className="block my-3 w-full rounded-md border-gray-400"
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            isReadOnly={disableQuestion}
          />
          {answerSaveIndicator}
        </>
      );
    case "BEHAVIOUR":
      if (question.behaviourOptionsArray) {
        // Get some settings from the first element in the collection
        // (they should all be the same anyway)
        const showAddTaskButton =
          !isInPrepMode &&
          !usesAdvancedTaskTypes /* New style advanced tasks haven't been implemented against behaviour questions, so only classic tasks are supported */ &&
          question.behaviourOptionsArray[0].showAddTaskButton;
        const sliderScoreDisplayType =
          question.behaviourOptionsArray[0].sliderScoreDisplayType;
        const enforcedComments = comments.filter(
          (x) => x.commentType === "ENFORCED" && x.behaviourId !== null
        );
        return (
          <>
            <MultipleBehaviourQuestion
              question={question}
              formId={question.formId}
              formType="COLLAB-DOC"
              behaviourOptions={question.behaviourOptionsArray!}
              showAddTaskButton={showAddTaskButton}
              showValidationErrors={showValidationErrors}
              onValueChange={onBehaviourValueChange}
              onBlur={handleValueSave}
              currentValues={
                currentAnswer
                  ? (currentAnswer.answer as BehaviourQuestionAnswerValue[])
                  : undefined
              }
              selectedTrackBgColourClassName="bg-green-300"
              emptyTrackBgColourClassName="bg-gray-300"
              formBackgroundColorStyle={formColor}
              subjectUser={subjectUser}
              sliderScoreDisplayType={sliderScoreDisplayType}
              isReadOnly={disableQuestion}
              tasks={tasks}
              flaggedChanges={flaggedChanges}
              showPlanningResponses={showPlanningResponses}
              participants={participants}
              planningResponseParticipants={participants}
              onChangeQuestionTasks={handleTasksSave}
              enforcedCommentIsOptional={question.enforcedCommentIsOptional}
              enforcedCommentQuestionText={question.enforcedCommentQuestionText}
              enforcedComments={enforcedComments}
              onEnforcedCommentChange={onBehaviourEnforcedCommentChange}
              onEnforcedCommentBlur={handleEnforcedBehaviourCommentSave}
              onPlanningResponseCopyClick={onPlanningResponseCopyClick}
            />
            {answerSaveIndicator}
          </>
        );
      } else {
        const enforcedComment = comments.find(
          (x) =>
            x.commentType === "ENFORCED" &&
            x.behaviourId === question.behaviourOptions!.behaviour!.key
        );
        return (
          <>
            <BehaviourAttributeQuestion
              question={question}
              formId={question.formId}
              formType="COLLAB-DOC"
              attributes={question.behaviourOptions!.attributes}
              behaviour={question.behaviourOptions!.behaviour}
              scalePoints={question.behaviourOptions!.scale}
              singleScaleLabel={question.behaviourOptions!.singleScaleLabel}
              showAddTaskButton={
                !isInPrepMode &&
                !usesAdvancedTaskTypes &&
                question.behaviourOptions!.showAddTaskButton
              }
              showValidationErrors={showValidationErrors}
              onValueChange={onBehaviourValueChange}
              onBlur={handleValueSave}
              currentValues={
                currentAnswer
                  ? (currentAnswer.answer as BehaviourQuestionAnswerValue[])
                  : undefined
              }
              selectedTrackBgColourClassName="bg-green-300"
              emptyTrackBgColourClassName="bg-gray-300"
              formBackgroundColorStyle={formColor}
              subjectUser={subjectUser}
              sliderScoreDisplayType={
                question.behaviourOptions!.sliderScoreDisplayType
              }
              isReadOnly={disableQuestion}
              tasks={tasks}
              flaggedChanges={flaggedChanges}
              showPlanningResponses={showPlanningResponses}
              participants={participants}
              onChangeQuestionTasks={handleTasksSave}
              enforcedCommentIsOptional={question.enforcedCommentIsOptional}
              enforcedCommentQuestionText={question.enforcedCommentQuestionText}
              enforcedComment={enforcedComment}
              onEnforcedCommentChange={onBehaviourEnforcedCommentChange}
              onEnforcedCommentBlur={handleEnforcedBehaviourCommentSave}
              onPlanningResponseCopyClick={onPlanningResponseCopyClick}
            />
            {answerSaveIndicator}
          </>
        );
      }

    case "LEARNING-AND-DEVELOPMENT":
      return (
        <>
          {planningResponseSection}
          <DevelopmentQuestion
            formType="COLLAB-DOC"
            question={question}
            formId={question.formId}
            isReadOnly={disableQuestion}
            tasks={tasks}
            usesAdvancedTaskTypes={usesAdvancedTaskTypes}
            showValidationErrors={showValidationErrors}
            onChangeQuestionTasks={handleTasksSave}
            onValueChange={onDevelopmentValueChange}
            onBlur={handleValueSave}
            currentValues={
              currentAnswer
                ? (currentAnswer.answer as DevelopmentQuestionAnswerValue[])
                : undefined
            }
            validationResult={validationResult}
          />
          {answerSaveIndicator}
        </>
      );
    case "ADDTASK":
      return (
        <>
          <TaskQuestion
            formId={question.formId}
            question={question}
            isReadOnly={false}
            tasks={tasks}
            onChangeQuestionTasks={handleTasksSave}
            showValidationErrors={showValidationErrors}
            validationResult={validationResult}
            formType="COLLAB-DOC"
          />
          {answerSaveIndicator}
        </>
      );
    case "GOAL-SETTING":
      return (
        <>
          <GoalSettingQuestion
            question={question}
            formId={question.formId}
            formType="COLLAB-DOC"
            isReadOnly={disableQuestion}
            showValidationErrors={showValidationErrors}
            tasks={tasks}
            onChangeQuestionTasks={handleTasksSave}
            validationResult={validationResult}
            separateRolledOverGoals={
              formComplexities?.goalRolloverConfig?.validConfig === true
            }
          />
          {answerSaveIndicator}
        </>
      );
    case "GOAL-REVIEW-STATUS-COMMENT":
      const enforcedComments = comments.filter(
        (x) => x.commentType === "ENFORCED" && x.goalId !== null
      );
      return (
        <>
          <GoalReviewQuestionStatusAndComment
            formType="COLLAB-DOC"
            question={question}
            currentValues={
              currentAnswer
                ? (currentAnswer.answer as GoalReviewQuestionAnswerValue[])
                : null
            }
            isReadOnly={disableQuestion}
            showValidationErrors={showValidationErrors}
            subjectUser={subjectUser}
            onValueChange={onGoalReviewValueChange}
            onValueSave={handleValueSave}
            enforcedComments={enforcedComments}
            onEnforcedCommentChange={onGoalEnforcedCommentChange}
            onEnforcedCommentBlur={handleEnforcedGoalCommentSave}
            flaggedChanges={flaggedChanges}
            participants={participants}
            showPlanningResponses={showPlanningResponses}
            onPlanningResponseCopyClick={onPlanningResponseCopyClick}
            isPrinting={isPrinting}
          />
          {answerSaveIndicator}
        </>
      );
    case "ADVANCED-TASK-MANAGEMENT":
      return (
        <>
          {question.taskManagementConfig && (
            <TaskManagementQuestion
              tasks={question.taskManagementConfig.tasks}
              questionValidations={question.validation}
              cancelledTasks={question.taskManagementConfig.cancelledTasks}
              formType="COLLAB-DOC"
              subjectUser={subjectUser}
              config={question.taskManagementConfig}
              isReadOnly={disableQuestion}
              showValidationErrors={showValidationErrors}
              validationResult={validationResult}
              answerSetUniqueId={answerSetUniqueId}
              answerSetDateCreated={answerSetDateCreated}
              participants={participants}
              onChangeAdvancedTasks={handleChangeAdvancedTasks}
            />
          )}
        </>
      );
    default:
      return null;
  }
}

export default CollabDocQuestionInput;
