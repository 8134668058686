import { t } from "i18next";
import SmallLoader from "../../loaders/SmallLoader";

export const TaskPopupTabLoader = () => {
  return (
    <div className="flex h-60">
      <div className="m-auto">
        <SmallLoader />
        <p className="text-center pt-2 text-[#959595] text-sm">
          {t("Common.Loading")}
        </p>
      </div>
    </div>
  );
};
