import { useAuth } from "react-oidc-context";
import { useState } from "react";
import adminApi from "../../../api/dashboard/adminApi";
import { KeyValuePair } from "../../../types/generic";
import EditableField from "./EditableField";
import UserDetailDto from "../../../types/dtos/admin/UserDetailDto";
import ResultStatus from "../../../types/dtos/generic/ResultStatus";
import NewManagerDetailsDto from "../../../types/dtos/admin/NewManagerDetailsDto";
import NewJourneyManagerDetailsDto from "../../../types/dtos/admin/NewJourneyManagerDetailsDto";
import NewStatusDetailsDto from "../../../types/dtos/admin/NewStatusDetailsDto";
import { useTranslation } from "react-i18next";

interface UserDetailEditableFieldsProps {
  userDetails: UserDetailDto;
  employeeStatuses: KeyValuePair<number, string>[];
  setUserDetails(userDetails: UserDetailDto): void;
}

function UserDetailEditableFields({
  userDetails,
  employeeStatuses,
  setUserDetails,
}: UserDetailEditableFieldsProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);

  const [proposedManagerId, setProposedManagerId] = useState<
    number | undefined
  >(userDetails.managerId);
  const [proposedJourneyManagerId, setProposedJourneyManagerId] = useState<
    number | undefined | null
  >(userDetails.journeyManagerId);
  const [proposedStatusId, setProposedStatusId] = useState<number>(
    userDetails.employeeStatusId
  );

  const [managerFieldInEditMode, setManagerFieldInEditMode] =
    useState<boolean>(false);
  const [managerFieldIsSaving, setManagerFieldIsSaving] =
    useState<boolean>(false);
  const [managerFieldHasSaved, setManagerFieldHasSaved] =
    useState<boolean>(false);
  const [managerFieldHasErrored, setManagerFieldHasErrored] =
    useState<boolean>(false);
  const [managerFieldErrorMessage, setManagerFieldErrorMessage] =
    useState<string>("");

  const [journeyManagerFieldInEditMode, setJourneyManagerFieldInEditMode] =
    useState<boolean>(false);
  const [journeyManagerFieldIsSaving, setJourneyManagerFieldIsSaving] =
    useState<boolean>(false);
  const [journeyManagerFieldHasSaved, setJourneyManagerFieldHasSaved] =
    useState<boolean>(false);

  const [statusFieldInEditMode, setStatusFieldInEditMode] =
    useState<boolean>(false);
  const [statusFieldIsSaving, setStatusFieldIsSaving] =
    useState<boolean>(false);
  const [statusFieldHasSaved, setStatusFieldHasSaved] =
    useState<boolean>(false);

  // If there is a JourneyManagerId then use the JourneyManagerName, else default to the ManagerName
  const journeyManagerDisplayValue = userDetails.journeyManagerId
    ? userDetails.journeyManagerName
    : userDetails.managerName;

  const handleManagerInEditMode = (newValue: boolean) => {
    // Only action InEditMode change when saving is not in process
    if (
      !managerFieldIsSaving &&
      !statusFieldIsSaving &&
      !journeyManagerFieldIsSaving
    ) {
      setManagerFieldInEditMode(newValue);
      setJourneyManagerFieldInEditMode(false);
      setStatusFieldInEditMode(false);
      resetProposedValues();
    }
  };

  const handleManagerChange = (selectedUserId: number) => {
    setProposedManagerId(selectedUserId);
  };

  const handleManagerSave = () => {
    // If the managerId has changed hit the API call, if it hasn't just return the field out of edit mode
    if (userDetails.managerId != proposedManagerId) {
      setManagerFieldIsSaving(true);

      const successCallback = (data: ResultStatus) => {
        if (data.success) {
          const newManagerDetails = data.data as NewManagerDetailsDto;
          setManagerFieldIsSaving(false);
          setManagerFieldHasSaved(true);
          setUserDetails({
            ...userDetails,
            managerId: newManagerDetails.managerId,
            managerName: newManagerDetails.managerName,
          });

          // This will be the timeout to return to the original state
          setTimeout(() => {
            setManagerFieldHasSaved(false);
            handleManagerInEditMode(false);
          }, 1500);
        } else {
          setManagerFieldIsSaving(false);
          setManagerFieldHasErrored(true);
          setManagerFieldErrorMessage(data.message);
        }
      };

      const errorCallback = (error: any) => {
        setManagerFieldIsSaving(false);
        setManagerFieldHasErrored(true);
        setManagerFieldErrorMessage(error.message);
      };

      admApi.updateManagerForUserId(
        userDetails.userId,
        proposedManagerId!,
        successCallback,
        errorCallback
      );
    } else {
      handleManagerInEditMode(false);
    }
  };

  const onManagerErrorRetry = () => {
    // Clear error message then set field back to edit mode
    clearManagerErrorMessage();
    setManagerFieldInEditMode(true);
  };

  const clearManagerErrorMessage = () => {
    // Clear the error message
    setManagerFieldHasErrored(false);
    setManagerFieldErrorMessage("");
  };

  const resetProposedValues = () => {
    setProposedManagerId(userDetails.managerId);
    setProposedJourneyManagerId(userDetails.journeyManagerId);
    setProposedStatusId(userDetails.employeeStatusId);
  };

  const handleJourneyManagerInEditMode = (newValue: boolean) => {
    // Only action InEditMode change when saving is not in process
    if (
      !managerFieldIsSaving &&
      !statusFieldIsSaving &&
      !journeyManagerFieldIsSaving
    ) {
      setManagerFieldInEditMode(false);
      setJourneyManagerFieldInEditMode(newValue);
      setStatusFieldInEditMode(false);
      resetProposedValues();
      clearManagerErrorMessage();
    }
  };

  const handleJourneyManagerChange = (selectedUserId: number) => {
    setProposedJourneyManagerId(selectedUserId);
  };

  const handleJourneyManagerSave = () => {
    // If the journeyManagerId has changed hit the API call, if it hasn't just return the field out of edit mode
    if (userDetails.journeyManagerId != proposedJourneyManagerId) {
      setJourneyManagerFieldIsSaving(true);

      const successCallback = (data: ResultStatus) => {
        const newJourneyManagerDetails =
          data.data as NewJourneyManagerDetailsDto;
        setJourneyManagerFieldIsSaving(false);
        setJourneyManagerFieldHasSaved(true);
        setUserDetails({
          ...userDetails,
          journeyManagerId: newJourneyManagerDetails.journeyManagerId,
          journeyManagerName: newJourneyManagerDetails.journeyManagerName,
        });

        // This will be the timeout to return to the original state
        setTimeout(() => {
          setJourneyManagerFieldHasSaved(false);
          handleJourneyManagerInEditMode(false);
        }, 1500);
      };

      const errorCallback = (error: any) => {
        console.error(error.message);
      };

      admApi.updateJourneyManagerForUserId(
        userDetails.userId,
        proposedJourneyManagerId!,
        successCallback,
        errorCallback
      );
    } else {
      handleJourneyManagerInEditMode(false);
    }
  };

  const handleJourneyManagerClearField = () => {
    // Only if the journeyManagerId was set in the first place do we call the API to clear it
    if (
      userDetails.journeyManagerId != undefined &&
      userDetails.journeyManagerId != null
    ) {
      setJourneyManagerFieldIsSaving(true);

      const successCallback = (data: ResultStatus) => {
        const newJourneyManagerDetails =
          data.data as NewJourneyManagerDetailsDto;
        setJourneyManagerFieldIsSaving(false);
        setJourneyManagerFieldHasSaved(true);
        setUserDetails({
          ...userDetails,
          journeyManagerId: newJourneyManagerDetails.journeyManagerId,
          journeyManagerName: newJourneyManagerDetails.journeyManagerName,
        });

        // This will be the timeout to return to the original state
        setTimeout(() => {
          setJourneyManagerFieldHasSaved(false);
          handleJourneyManagerInEditMode(false);
        }, 1500);
      };

      const errorCallback = (error: any) => {
        console.error(error.message);
      };

      admApi.updateJourneyManagerForUserId(
        userDetails.userId,
        null,
        successCallback,
        errorCallback
      );
    } else {
      handleJourneyManagerInEditMode(false);
    }
  };

  const handleStatusInEditMode = (newValue: boolean) => {
    // Only action InEditMode change when saving is not in process
    if (
      !managerFieldIsSaving &&
      !statusFieldIsSaving &&
      !journeyManagerFieldIsSaving
    ) {
      setManagerFieldInEditMode(false);
      setJourneyManagerFieldInEditMode(false);
      setStatusFieldInEditMode(newValue);
      resetProposedValues();
      clearManagerErrorMessage();
    }
  };

  const handleStatusChange = (employeeStatusId: string) => {
    setProposedStatusId(parseInt(employeeStatusId));
  };

  const handleStatusSave = () => {
    // If the statusId has changed hit the API call, if it hasn't just return the field out of edit mode
    if (userDetails.employeeStatusId != proposedStatusId) {
      setStatusFieldIsSaving(true);

      const successCallback = (data: ResultStatus) => {
        const newStatusDetails = data.data as NewStatusDetailsDto;
        setStatusFieldIsSaving(false);
        setStatusFieldHasSaved(true);
        setUserDetails({
          ...userDetails,
          employeeStatusId: newStatusDetails.statusId,
          employeeStatusName: newStatusDetails.statusName,
        });

        // This will be the timeout to return to the original state
        setTimeout(() => {
          setStatusFieldHasSaved(false);
          handleStatusInEditMode(false);
        }, 1500);
      };

      const errorCallback = (error: any) => {
        console.error(error.message);
      };

      admApi.updateStatusForUserId(
        userDetails.userId,
        proposedStatusId,
        successCallback,
        errorCallback
      );
    } else {
      handleStatusInEditMode(false);
    }
  };

  return (
    <>
      <EditableField
        label={t("Pages.Admin.Common.Manager")}
        displayValue={userDetails.managerName}
        initialValue={userDetails.managerName}
        inEditMode={managerFieldInEditMode}
        isSaving={managerFieldIsSaving}
        hasSaved={managerFieldHasSaved}
        hasErrored={managerFieldHasErrored}
        errorMessage={managerFieldErrorMessage}
        fieldType="PEOPLE-PICKER"
        userIdToExcludeFromResults={userDetails.userId}
        autoSuggestModifyWidth={false}
        setInEditMode={handleManagerInEditMode}
        onPeoplePickerChange={handleManagerChange}
        onSaveIconClick={handleManagerSave}
        onErrorTryAgain={onManagerErrorRetry}
      />
      <EditableField
        label={t("Pages.Admin.Common.JourneyManager")}
        displayValue={journeyManagerDisplayValue}
        initialValue={userDetails.journeyManagerName}
        inEditMode={journeyManagerFieldInEditMode}
        isSaving={journeyManagerFieldIsSaving}
        hasSaved={journeyManagerFieldHasSaved}
        fieldType="PEOPLE-PICKER"
        userIdToExcludeFromResults={userDetails.userId}
        tooltip={t("Pages.Admin.UserDetails.Tooltips.JourneyManager")}
        autoSuggestModifyWidth={false}
        setInEditMode={handleJourneyManagerInEditMode}
        onPeoplePickerChange={handleJourneyManagerChange}
        onPeoplePickerClearField={handleJourneyManagerClearField}
        onSaveIconClick={handleJourneyManagerSave}
      />
      <EditableField
        label={t("Pages.Admin.Common.Status")}
        displayValue={userDetails.employeeStatusName}
        inEditMode={statusFieldInEditMode}
        isSaving={statusFieldIsSaving}
        hasSaved={statusFieldHasSaved}
        fieldType="DROPDOWN"
        dropdownItems={employeeStatuses}
        dropdownCurrentItem={proposedStatusId.toString()}
        setInEditMode={handleStatusInEditMode}
        onDropdownChange={handleStatusChange}
        onSaveIconClick={handleStatusSave}
      />
    </>
  );
}

export default UserDetailEditableFields;
