import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

interface TaskManagementRestrictionBannerProps {
  bannerTextHtml: string;
}

function TaskManagementRestrictionBanner({
  bannerTextHtml
}: TaskManagementRestrictionBannerProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row py-2 my-2 px-4 bg-rose-400 text-sm text-white rounded-md">
      <div className="flex basis-12/12 pt-1">
        <FontAwesomeIcon className="pt-0.5" icon={faExclamationCircle}></FontAwesomeIcon>
        <div className="pl-4 styled-list">
          {parse(bannerTextHtml)}
        </div>
      </div>
    </div>
  );
}

export default TaskManagementRestrictionBanner;
