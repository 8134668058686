import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { WindowDto } from "../types/dtos/admin/WindowDto";
import windowValidationStatus from "../types/generic/WindowValidationStatus";
import dateHelper from "./dateHelper";
dayjs.extend(utc);

const journeyWindowHelper = {
  validateStartAndEndDatesForWindow: function (
    startDate: Date,
    endDate: Date,
    existingWindows: WindowDto[] | undefined
  ): windowValidationStatus {
    // Check to see if user set the end date before the start date.
    if (endDate < startDate) {
      return "END-DATE_BEFORE_START-DATE";
    }

    var todaysDate = dayjs().utc().toDate();

    // Using todays date check to see if the start date given is today
    if (todaysDate.toDateString() === startDate.toDateString()) {
      return "START-DATE-IS-TODAY";
    }

    // Using todays date and check the new window isn't in the past
    if (startDate < todaysDate || endDate < todaysDate) {
      return "IN-PAST";
    }

    // Before we check if there is any overlap, first if there is no windows then we can return ok status.
    if (existingWindows === undefined) {
      return "OK";
    }
    // Check to see if the new window overlaps an existing window
    let anyOverlappingDates = existingWindows.find(
      (window) =>
        window.endDate != null &&
        startDate <= dateHelper.convertDateToUtc(window.endDate) &&
        window.startDate != null &&
        dateHelper.convertDateToUtc(window.startDate) <= endDate
    );
    if (anyOverlappingDates !== undefined) {
      return "OVERLAPS";
    }

    // If we have got to this point then it's ok to add this new window
    return "OK";
  },
};

export default journeyWindowHelper;
