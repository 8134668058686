import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { advancedTaskHelper, userDetailsHelper } from "../../helpers";
import ManagerDashboardPersonDto from "../../types/dtos/dashboards/ManagerDashboardPersonDto";
import ManagerDashboardUpdateDto from "../../types/dtos/dashboards/ManagerDashboardUpdateDto";
import { Badge, FormattedDate } from "../common";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";

interface IndividualUpdateProps {
  users: ManagerDashboardPersonDto[];
  update: ManagerDashboardUpdateDto;
  taskType?: ClientTaskType | undefined;
}

function IndividualUpdate({
  users,
  update,
  taskType = undefined,
}: IndividualUpdateProps) {
  const { t } = useTranslation();

  const updateTimestamp = dayjs(update.timestamp);
  const todaysDate = dayjs().startOf("day");
  const weekAfterDate = todaysDate.add(7, "day");

  const displayName = userDetailsHelper.getDisplayName(update.userId, users);

  let descriptorBadgeText: string = "";
  let taskTypePluralName: string = "";

  switch (update.updateType) {
    case "COLLAB-DOC":
    case "DUAL-PREP-MEETING":
      descriptorBadgeText = "Meeting";
      break;
    case "DUAL-PREP-PLANNING":
      descriptorBadgeText = "Planning";
      break;
    case "GENERAL-CATCH-UP":
      descriptorBadgeText = "Catch Up";
      break;
    case "ADVANCED-TASKS-UPDATED-BY-EMPLOYEE":
      if (update.advancedTaskTypeId && taskType) {
        taskTypePluralName = advancedTaskHelper.ToLowerCase(
          taskType.pluralNameTranslationKeyIdentifier
        );
        descriptorBadgeText = t(taskType.pluralNameTranslationKeyIdentifier);
      }
      break;
    default:
      break;
  }

  const isManagerOnlyApprovalFlow =
    update.approvalFlow === "MANAGER-ONLY-APPROVAL";

  return (
    <div className="grid grid-cols-1 grow lg:grid-cols-12">
      <div className="lg:col-span-9">
        {update.updateType === "COLLAB-DOC" && update.needsManagerAttention && (
          <div>
            {update.status === "SAVED-BY-MANAGER-WITHOUT-APPROVING" ||
              (isManagerOnlyApprovalFlow &&
                update.status === "RETURNED-BY-MANAGER") ||
              (update.approvalFlow === "HIDDEN-TO-EMPLOYEE"
                && update.status === "INITIAL-SAVE-FORM-INCOMPLETE") ? (
                <>
                  You need to complete and sign-off your updates on the{" "}
                  <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}Journey for{" "}
                  <span className="font-semibold">{displayName}</span>
                </>
              ) : (
                <>
                  <span className="font-semibold">{displayName}</span> has updated
                  their{" "}<span className="font-semibold">{t(update.clientFormTitle)}</span>. Review and discuss their
                  answers here
                </>
              )}
          </div>
        )}
        {update.updateType === "COLLAB-DOC" &&
          !update.needsManagerAttention && (
            <div>
              <span className="font-semibold">{displayName}</span>{" "}needs to
              review changes made to{" "}<span className="font-semibold">{t(update.clientFormTitle)}</span>
            </div>
          )}
        {/* General Catch-up - Not Started */}
        {update.updateType === "GENERAL-CATCH-UP" &&
          update.status === "INITIAL-SAVE-FORM-INCOMPLETE" && (
            <div>
              Catch up: Between you and{" "}
              <span className="font-semibold">{displayName}</span> with the
              subject{" "}<span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}on{" "}
              <FormattedDate
                displayMode="DATE-AND-TIME"
                convertFromUtc={true}
                date={update.timestamp}
              />
            </div>
          )}
        {/* General Catch-up - In Progress - Needs manager attention */}
        {update.updateType === "GENERAL-CATCH-UP" &&
          update.status !== "INITIAL-SAVE-FORM-INCOMPLETE" && (
            <div>
              Catch up updated: <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}with{" "}
              <span className="font-semibold">{displayName}</span>{" "}awaiting
              approval from you
            </div>
          )}
        {update.updateType === "DUAL-PREP-PLANNING" && (
          <div>
            <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}planning needed for{" "}
            <span className="font-semibold">{displayName}</span>
          </div>
        )}
        {update.updateType === "DUAL-PREP-MEETING" && (
          <div>
            <span className="font-semibold">{t(update.clientFormTitle)}</span>{" "}meeting can now be completed for{" "}
            <span className="font-semibold">{displayName}</span>
          </div>
        )}
        {update.updateType === "ADVANCED-TASKS-UPDATED-BY-EMPLOYEE" && (
          <div>
            <span className="font-semibold">{displayName}</span>{" "}has updated
            their {taskTypePluralName}
          </div>
        )}
      </div>
      <div className="text-right pb-2 order-first lg:pb-0 lg:order-last lg:col-span-3">
        <Badge
          text={descriptorBadgeText}
          textColourClassName="text-gray-400"
          marginClassName=""
        />
        {update.updateType === "GENERAL-CATCH-UP" && (
          <>
            {" "}
            {updateTimestamp < todaysDate ? (
              <Badge
                text="Overdue"
                backgroundColourClassName="bg-rose-100"
                textColourClassName="text-rose-700"
                marginClassName=""
              />
            ) : updateTimestamp > todaysDate &&
              updateTimestamp < weekAfterDate ? (
              <Badge
                text="Due Soon"
                backgroundColourClassName="bg-amber-100"
                textColourClassName="text-amber-700"
                marginClassName=""
              />
            ) : (
              <Badge
                text="New"
                backgroundColourClassName="bg-green-100"
                textColourClassName="text-green-700"
                marginClassName=""
              />
            )}
          </>
        )}
        {update.updateType !== "GENERAL-CATCH-UP" && (
          <>
            {" "}
            <Badge
              text={updateTimestamp.startOf("day").from(todaysDate)}
              backgroundColourClassName="bg-blue-100 rounded-full"
              textColourClassName="text-blue-700"
              borderRadiusClassName="rounded"
              marginClassName="mr-1"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default IndividualUpdate;
