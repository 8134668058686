import { useCallback, useEffect, useState } from "react";
import smartTruncate from "smart-truncate";
import { ModalPopup, TextArea } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import JourneyAnswerSaveIndicator from "./JourneyAnswerSaveIndicator";
import { FormConstants } from "../../config/forms/FormConstants";

interface JourneyCommentControlProps {
  currentValue: string | null | undefined;
  onChange(
    newValue: string | null,
    onSaveSuccess: () => void,
    onSaveError: () => void
  ): void;
  questionId: string;
}

function JourneyCommentControl({
  questionId,
  currentValue,
  onChange,
}: JourneyCommentControlProps) {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [editableCommentValue, setEditableCommentValue] = useState(
    currentValue || ""
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [erroredSaving, setErroredSaving] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);

  // Update the editable value state when a new comment is supplied,
  // e.g. when the question changes
  useEffect(() => {
    setEditableCommentValue(currentValue || "");
  }, [currentValue]);

  const openEditModal = () => {
    setModalIsOpen(true);
  };

  const handleCommentChange = (newValue: string | null) => {
    setEditableCommentValue(newValue || "");

    // Reset properties for the save error indicator
    setIsSaving(true);
    setSaveSuccessful(false);
    setErroredSaving(false);

    const onCommentSaveSuccess = () => {
      // Do nothing other than close the modal, no need to show it has saved successfully
      setSaveSuccessful(true);
      setIsSaving(false);
      setModalIsOpen(false);
    };

    const onCommentSaveError = () => {
      // Show the control to enable them to attempt again to save the comment
      setErroredSaving(true);
      setIsSaving(false);
    };

    onChange(newValue, onCommentSaveSuccess, onCommentSaveError);
  };

  const commitChange = useCallback(() => {
    handleCommentChange(editableCommentValue);
  }, [editableCommentValue]);

  const cancelAndClosePopup = useCallback(() => {
    handleCommentChange(null);
  }, []);

  const hasComment = currentValue && currentValue.trim().length > 0;
  const maxLengthToDisplay: number = 40;

  let displayValue = null;
  if (hasComment) {
    displayValue = smartTruncate(currentValue, maxLengthToDisplay);
  }

  const commentFieldId = `question-comment-${questionId}`;

  return (
    <div className="flex justify-end mt-2 mb-4">
      {hasComment && (
        <>
          <button
            className="text-sm text-white/40 border-b border-b-white/30 hover:text-white hover:border-b-white flex flex-row"
            onClick={openEditModal}
          >
            <p className="italic pr-2">{displayValue}</p>
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </>
      )}
      {!hasComment && (
        <button
          className="text-sm text-white/40 border-b border-b-white/30 hover:text-white hover:border-b-white"
          onClick={openEditModal}
        >
          {t("Journey.Button.AddComment")}{" "}
          <span className="text-xs">{t("Journey.Button.Optional")}</span>
        </button>
      )}
      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={setModalIsOpen}
        onPrimaryButtonClick={commitChange}
        onSecondaryButtonClick={cancelAndClosePopup}
        primaryButtonText="Save"
        secondaryButtonText={
          editableCommentValue.trim().length > 0 ? "Clear comment" : undefined
        }
        title="Edit Comment"
        titleDescription="You can use this to add some context to your answer"
      >
        <>
          <JourneyAnswerSaveIndicator
            didSave={saveSuccessful}
            errored={erroredSaving}
            isSaving={isSaving}
            retrySave={commitChange}
          />
          <TextArea
            inputId={commentFieldId}
            value={editableCommentValue}
            onChange={setEditableCommentValue}
            minRows={3}
            maxRows={6}
            className="mt-2 p-2 w-full bg-gray-100 text-gray-600 rounded-lg border-0"
            showValidationErrors={false}
            maxLength={FormConstants.MaxLengths.AnswerSetComment}
          />
        </>
      </ModalPopup>
    </div>
  );
}

export default JourneyCommentControl;
