import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from "@fortawesome/pro-duotone-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { t } from "i18next";
import {
  GroupedJourneyGroupType,
  GroupedJourneys,
} from "../../../types/analytics/FilterAvailableValues";
import { CheckBox, Tooltip } from "../../common";
import FilterEditIcon from "./FilterEditIcon";
import { JourneyFilterDefaultValueMode } from "../../../types/analytics";
import { AnalyticsPageJourneyFilterRestriction } from "../../../types/analytics/AnalyticsFilterInputs";

const getTitleForJourneyGroupType = (
  groupType: GroupedJourneyGroupType
): string => {
  switch (groupType) {
    case "CLIENT-SENT":
      return "Admin Sent";
    case "EXIT":
      return "Exit Questionnaires";
    case "INDUCTION":
      return "Inductions";
    case "SYSTEM":
      return "System Journeys";
    default:
      return "Unknown";
  }
};

interface JourneyPickerProps {
  defaultValueMode: JourneyFilterDefaultValueMode;
  restriction: AnalyticsPageJourneyFilterRestriction;
  allSelected: boolean;
  items: GroupedJourneys[];
  selectedValues: string[] | null;
  onValueChange(selectedJourneyKeys: string[]): void;
  inEditMode: boolean;
  onBeginEditMode(): void;
  onAllSelectedChange: (allSelected: boolean) => void;
}

function JourneyPicker({
  defaultValueMode,
  restriction,
  allSelected,
  items,
  selectedValues,
  onValueChange,
  inEditMode,
  onBeginEditMode,
  onAllSelectedChange,
}: JourneyPickerProps) {
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");

  const debouncedPerformSearch = useCallback(
    debounce((searchValue: string) => {
      setDebouncedSearchValue(searchValue);
    }, 500),
    []
  );

  const journeyKeyIsSelected = (journeyKey: string) => {
    return (
      selectedValues !== null &&
      selectedValues.length > 0 &&
      selectedValues.indexOf(journeyKey) >= 0
    );
  };

  const groupOptions = items
    .filter((x) => x.journeys && x.journeys.length > 0)
    .map((rootItem) => {
      const groupLabel = getTitleForJourneyGroupType(rootItem.groupType);
      return {
        groupType: rootItem.groupType,
        label: groupLabel,
        options: rootItem.journeys.map((j) => ({
          ...j,
          selected: allSelected || journeyKeyIsSelected(j.key),
        })),
      };
    });

  // Create a flat array of all the options and their group type
  const alljourneyOptions = groupOptions.flatMap(({ groupType, options }) =>
    options.map((opt) => ({ ...opt, groupType }))
  );

  const getSelectedJourneyTitlesCsvForDisplay = (
    selectedJourneys: string[]
  ) => {
    if (!selectedJourneys || selectedJourneys.length === 0)
      return "No selection";

    const maxDisplayCount = 2;

    if (selectedJourneys.length > maxDisplayCount) {
      return `${selectedJourneys.length} Journeys`;
    } else {
      const matchedJourneys = alljourneyOptions.filter(
        (x) => selectedJourneys.indexOf(x.key) >= 0
      );
      return matchedJourneys
        .map((x) => GetJourneyTitle(x.groupType, x.title))
        .join(", ");
    }
  };

  if (!inEditMode) {
    let outputText = "";
    if (allSelected) {
      switch (restriction) {
        case "INDUCTIONS-ONLY":
          outputText = "All Induction Journeys";
          break;
        case "EXITS-ONLY":
          outputText = "All Exit Journeys";
          break;
        default:
          outputText = "All Journeys";
      }
    } else {
      const selectedJourneys = alljourneyOptions
        .filter((x) => x.selected)
        .map((x) => x.key);
      outputText = getSelectedJourneyTitlesCsvForDisplay(selectedJourneys);
    }

    return (
      <button className="cursor-pointer group" onClick={onBeginEditMode}>
        <FontAwesomeIcon icon={faRoute} className="mr-2" />
        <span>{outputText}</span>
        <FilterEditIcon extraClassNames="ml-2" />
      </button>
    );
  }

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchInputValue(inputValue);
    debouncedPerformSearch(inputValue.toLowerCase().trim());
  };

  const handleJourneyChecked = (journeyKey: string, isSelected: boolean) => {
    // Get the keys of the selected items
    // We use `alljourneyOptions` here rather that `selectedValues` as the former
    // takes into account the `allSelected` prop
    const currentValues = alljourneyOptions
      .filter((x) => x.selected)
      .map((x) => x.key);

    let newValues: string[];

    if (isSelected) {
      // Add the journey to the selected values
      newValues = [...currentValues, journeyKey];
    } else {
      // Remove the journeys from the selected values
      newValues = currentValues.filter((x) => x !== journeyKey);
    }

    // Filter out any duplicates
    const uniqueJourneys = Array.from(new Set(newValues));

    // Call the handler
    onValueChange(uniqueJourneys);
  };

  return (
    <div className="pt-3">
      <div className="mb-2">
        <input
          type="text"
          placeholder="Search..."
          value={searchInputValue}
          onChange={handleSearchInputChange}
          className="rounded-md border-none text-[#849095] h-[30px] leading-[30px] py-0 text-[12px] shadow-sm w-full !ring-transparent focus-border-accent"
        />
      </div>
      {defaultValueMode === "MULTI" && (
        <div className="mb-2">
          <CheckBox
            controlId={`chk_AllJourneys`}
            labelText="Select all journeys"
            labelClassNames="font-semibold"
            selected={allSelected}
            onChange={onAllSelectedChange}
            disabled={searchInputValue.length > 0}
          />
        </div>
      )}
      <div className="max-h-64 overflow-y-auto pl-2 pr-4">
        {groupOptions.map((group) => (
          <JourneyChildGroup
            key={group.label}
            label={group.label}
            options={group.options}
            groupType={group.groupType}
            handleJourneyChecked={handleJourneyChecked}
            searchValue={debouncedSearchValue}
          />
        ))}
      </div>
    </div>
  );
}

interface JourneyChildGroupDetails {
  label: string;
  options: { selected: boolean; title: string; key: string }[];
  groupType: GroupedJourneyGroupType;
  handleJourneyChecked: (journeyTitle: string, isSelected: boolean) => void;
  searchValue: string;
}

function GetJourneyTitle(
  groupType: GroupedJourneyGroupType,
  journeyTitleValue: string
) {
  return groupType === "CLIENT-SENT" ? journeyTitleValue : t(journeyTitleValue);
}

function GetHelpText(groupType: GroupedJourneyGroupType): string | null {
  switch (groupType) {
    case "CLIENT-SENT":
      return "These are journeys which an admin has manually sent to people";
    case "EXIT":
      return "These are exit questionnaires for people leaving the company";
    case "INDUCTION":
      return "These are induction journeys for new starters, or people changing role";
    case "SYSTEM":
      return "These are journeys which are triggered by the system";
    default:
      return null;
  }
}

function JourneyChildGroup({
  label,
  options,
  groupType,
  handleJourneyChecked,
  searchValue,
}: JourneyChildGroupDetails) {
  // Translate the journey titles where necessary
  const translatedOptions = options.map((x) => ({
    ...x,
    displayTitle: GetJourneyTitle(groupType, x.title),
  }));
  const displayOptions = translatedOptions.map((x) => ({
    ...x,
    searchValue: x.displayTitle.toLowerCase().trim(),
  }));

  const filteredOptions =
    searchValue.length > 0
      ? displayOptions.filter((x) => x.searchValue.includes(searchValue))
      : displayOptions;

  if (filteredOptions.length === 0) return null;

  const helpText = GetHelpText(groupType);

  return (
    <div className="mb-2">
      <div className="text-xs font-semibold">
        {label}
        {helpText !== null && (
          <Tooltip
            content={helpText}
            triggerElement={
              <span className="ml-1 text-gray-500">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            }
          />
        )}
      </div>
      {filteredOptions.length > 0 &&
        filteredOptions.map((item) => (
          <CheckBox
            key={item.title}
            controlId={`chk_${item.title}`}
            labelText={GetJourneyTitle(groupType, item.title)}
            selected={item.selected}
            onChange={(isSelected: boolean) => {
              handleJourneyChecked(item.key, isSelected);
            }}
          />
        ))}
    </div>
  );
}

export default JourneyPicker;
