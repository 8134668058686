import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import TinyLoader from "../../../components/loaders/TinyLoader";
import UserContext from "../../../state/UserContext";
import { fileHelper } from "../../../helpers";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { t } from "i18next";
import exportsApi from "../../../api/dashboard/exportsApi";
import ExportGenerationRequest from "../../../types/exports/ExportGenerationRequest";
import IsExportReadyResponseDto from "../../../types/exports/IsExportReadyResponseDto";
import InfoAlert from "../../../components/alerts/InfoAlert";

interface TaskExportItemProps {
  item: ClientTaskType;
  dateFrom: Date | null;
  dateTo: Date | null;
  excludeHistoricTasks: boolean;
}

function TaskExportItem({ item, dateFrom, dateTo, excludeHistoricTasks }: TaskExportItemProps) {
  // Context/APIs
  const auth = useAuth();
  const api = new exportsApi(auth.user?.access_token);
  const userContext = useContext(UserContext);

  const [disableDownload, setDisableDownload] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [pollForExport, setPollForExport] = useState<boolean>(false);
  const [exportId, setExportId] = useState<string | null>(null);
  const [exportStatus, setExportStatus] = useState<string | null>(null);

  const [pollIntervalId, setPollIntervalId] = useState<NodeJS.Timer | null>(
    null
  );
  const pollIntervalIdRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (pollForExport == true) {
      setExportStatus("Getting things ready...");
      // Start polling at 6 second intervals to check if export is ready to download
      var interval = setInterval(() => {
        isExportReadyToDownload();
      }, 6000);
      setPollIntervalId(interval);
      pollIntervalIdRef.current = interval;
    } else {
      if (pollIntervalId) {
        clearInterval(pollIntervalId);
      }
    }
    //Clean up method to remove any intervals before unmounting
    return () => {
      if (pollIntervalIdRef != null && pollIntervalIdRef.current != null) {
        clearInterval(pollIntervalIdRef.current);
      }
    };
  }, [pollForExport]);

  useEffect(() => {
    // If both dates are set, and then dateTo is before dateFrom, disable the download button
    if (dateFrom != null && dateTo != null && dateTo < dateFrom) {
      setDisableDownload(true);
    } else {
      setDisableDownload(false);
    }
  }, [dateFrom, dateTo]);

  // Functions
  function queueExportForGeneration() {
    setExportStatus(null);
    setIsDownloading(true);

    // Build request object to send to API
    const request: ExportGenerationRequest = {
      taskTypeId: item.id,
      dateFrom: dateFrom,
      dateTo: dateTo,
      excludeHistoricTasks: excludeHistoricTasks,
    };

    api.queueAdvancedTaskDetailExportForGeneration(
      request,
      // onSuccess
      (data: string) => {
        // Export generation has been queued successfully, now start polling to check if it's ready
        setExportId(data);
        setPollForExport(true);
      },
      // onError
      (error: any) => {
        console.log("Error queueing export", error);
      }
    );
  }

  function isExportReadyToDownload() {
    api.isExportReadyToDownload(
      exportId!,
      // onSuccess
      (data: IsExportReadyResponseDto) => {
        switch (data.status) {
          case "READY-TO-GENERATE":
            setExportStatus("Getting things ready...");
            break;
          case "IN-PROGRESS":
            setExportStatus("Generating your export...");
            break;
          case "FAILED":
            // Show error message, stop the download process and polling
            setExportStatus(
              "There was a problem generating your export. Please try again."
            );
            setIsDownloading(false);
            setPollForExport(false);
            break;
          case "READY-TO-DOWNLOAD":
            // Show success message, stop the polling and download export
            setExportStatus("Export completed. Please check your downloads.");
            setPollForExport(false);
            downloadExport();
            break;
          default:
            // Show error message, stop the download process and polling
            setExportStatus(
              "There was an unexpected issue generating your download. If issue persists please contact support."
            );
            setIsDownloading(false);
            setPollForExport(false);
            break;
        }
      },
      // onError
      (error: any) => {
        console.log("Error checking export", error);
      }
    );
  }

  function downloadExport() {
    const filenameWithoutExtension = fileHelper.createFilenameFromString(
      t(item.singularNameTranslationKeyIdentifier) + "-export",
      true
    );

    api.downloadExport(
      exportId!,
      filenameWithoutExtension,
      // onSuccess
      () => {
        setIsDownloading(false);
      },
      // onError
      (error: any) => {
        setIsDownloading(false);
        console.log("Error downloading export", error);
      }
    );
  }

  return (
    <div className="basis-full md:basis-1/2">
      <div className="px-4 p-2 bg-gray-50 rounded-md border border-gray-200">
        <div className="flex flex-row w-full items-center">
          <div className="grow">
            <span className="block font-medium primary-text">
              {t(item.singularNameTranslationKeyIdentifier)} {"Details"}
            </span>
          </div>
          <div className="flex-none px-2">
            <button
              className="disabled:cursor-not-allowed px-2 bg-gray-200 rounded py-1 hover:bg-gray-300 text-center"
              disabled={isDownloading || disableDownload}
              onClick={() => queueExportForGeneration()}
            >
              {!isDownloading && (
                <FontAwesomeIcon icon={faDownload} size="sm" />
              )}
              {isDownloading && (
                <TinyLoader colour={userContext.user.client.accentHexColour} />
              )}
              <span className="ml-1 text-sm">Download</span>
            </button>
          </div>
        </div>
        {exportStatus && (
          <>
            <hr className="my-2" />
            <div className="text-sm">
              {isDownloading && (
                <InfoAlert
                  prefix={null}
                  message="Preparing your export. Please do not navigate away from this page."
                />
              )}
              <strong>Status: </strong>
              <span>{exportStatus}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TaskExportItem;
