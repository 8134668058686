import { Fragment, ReactNode, memo, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import cx from "classnames";
import produce from "immer";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as LabelPrimitive from "@radix-ui/react-label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { CheckBoxListDisplayMode, ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import formValidationHelper from "../../helpers/formValidationHelper";
import { KeyValuePair } from "../../types/generic";
import { CustomTextInput } from ".";
import { debounce } from "lodash";
import MultipleChoiceOptionNumericId, {
  MultipleChoiceOptionStringId,
} from "../../types/forms/MultipleChoiceOptions";
import FormQuestionOptionCategory from "../../types/forms/FormQuestionOptionCategory";
import MultiChoiceOptionItemWithCategory from "./MultiChoiceOptionItemWithCategory";
import { stringHelper } from "../../helpers";
import MultiChoiceQuestionSearchField from "./MultiChoiceQuestionSearchField";

/** Determine whether the count of selected items is valid within the min/max bounds */
const selectedCountIsInRange = (
  currentCount: number,
  min: number | null,
  max: number | null
): boolean => {
  if (min === null && max === null) return true;
  if (min === null && max !== null && currentCount > max) return false;
  if (min !== null && max === null && currentCount < min) return false;
  if (
    min !== null &&
    max !== null &&
    (currentCount < min || currentCount > max)
  )
    return false;
  return true;
};

export interface CheckBoxListProps<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
> {
  displayMode: CheckBoxListDisplayMode;
  /** Used as a prefix for `id` attributes, so make this unique for the page */
  uniqueFieldName: string;
  /** The question text for this check box list */
  fieldLabel?: string;
  values: TOption[];
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** The method to run when the value changes */
  onChange(newValues: TOption[]): void;
  /** The colour of the labels for the checkboxes */
  textColourClassName?: string;
  /** The icon colour */
  iconColourClassName?: string;
  /** The border colour/style */
  checkboxBorderClassName?: string;
  /** The background colour class name for checkboxes when checked */
  checkboxCheckedBgColourClassName?: string;
  /** The background colour class name for checkboxes when checked hex code without #*/
  checkboxCheckedBgColourHexCode?: string;
  /** The background colour class name for checkboxes when unchecked */
  checkboxUncheckedBgColourClassName?: string;
  isReadOnly?: boolean;

  /** Used to display a message to the user, e.g. "Select at least 3" */
  selectMinCount: number | null;

  /** Used to display a message to the user, e.g. "Select no more than 3" */
  selectMaxCount: number | null;

  /** Used to render out categories and have the options appear under their relevant ones */
  categories?: FormQuestionOptionCategory[] | null;

  /** An event to call when the user may have stopped interacting with the checkbox list */
  onBlur?(answerValue: TOption[]): void | undefined;
}

/** A form field containing multiple radio buttons */
function CheckBoxList<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
>({
  displayMode,
  uniqueFieldName,
  fieldLabel,
  showValidationErrors = false,
  validationResult = null,
  values,
  onChange,
  selectMinCount,
  selectMaxCount,
  textColourClassName = "text-gray-700",
  iconColourClassName = "text-gray-800",
  checkboxBorderClassName = "border border-gray-700",
  checkboxCheckedBgColourClassName = "bg-white/30",
  checkboxUncheckedBgColourClassName = "bg-gray-100",
  checkboxCheckedBgColourHexCode,
  isReadOnly = false,
  categories,
  onBlur = undefined,
}: CheckBoxListProps<TOption>) {
  // State
  const [countSelected, setCountSelected] = useState<number>(0);
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);
  const [optionCustomValues, setOptionCustomValues] = useState<
    KeyValuePair<number | string, string>[]
  >([]);
  const [displayItems, setDisplayItems] = useState<TOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expandAllCategories, setExpandAllCategories] = useState<boolean>(false);

  // `onChange` handles state changes, `handleBlur` can be called to send the
  // current value to the API to be saved (if an onBlur event is defined)
  const handleBlur = (currentOptions: TOption[]) => {
    if (onBlur) {
      onBlur(currentOptions);
    }
  };

  // use the `debouncedHandleBlur` function to avoid sending the possibility
  // of firing multiple onBlur events (potentially causing multiple calls to the API)
  // as users tick multiples boxes in quick succession
  const debouncedHandleBlur = useMemo(
    () => debounce(handleBlur, 500),
    [onBlur]
  );

  // Callback on value change, call on blur function (if one was provided)
  useEffect(() => {
    const customValues = values
      .filter((x) => x.allowCustomText && x.customText)
      .map((x) => ({
        key: x.optionId,
        value: x.customText!,
      }));

    setOptionCustomValues(customValues);
  }, [values]);

  useEffect(() => {
    // Reset `userHasInteracted` so we only show the validation warning
    // after the user has interacted with the form
    setUserHasInteracted(false);
  }, [uniqueFieldName]);

  useEffect(() => {
    // Set the count selected as the question changes, or as the selected values change
    const selectedCount = values.filter((x) => x.isSelected).length;
    setCountSelected(selectedCount);
  }, [uniqueFieldName, values]);

  // Initial load (only has an effect if the categories are not null)
  useEffect(() => {
    if (!categories) return;
    onFilterAndSetDisplayItems(searchTerm);
  }, []);

  // On value change (only has an effect if the categories are not null)
  useEffect(() => {
    if (!categories) return;
    onFilterAndSetDisplayItems(searchTerm);
  }, [values]);

  // On search term change (only has an effect if the categories are not null)
  const onSearch = (newSearchTerm: string) => {
    if (!categories) return;

    onFilterAndSetDisplayItems(newSearchTerm);
    setSearchTerm(newSearchTerm);

    const hasSearchTerm = newSearchTerm.trim().length > 0;
    setExpandAllCategories(hasSearchTerm);
  };

  const onFilterAndSetDisplayItems = (searchTerm: string) => {
    const newState = filterBySearchTerm(
      values,
      searchTerm
    );

    setDisplayItems(newState);
  };

  const filterBySearchTerm = (
    sourceItems: TOption[],
    searchTerm: string | null
  ): TOption[] => {
    const filterBySearchTerm = searchTerm && searchTerm.trim().length > 0;

    const filteredOptions = sourceItems.filter((option) => {
      if (filterBySearchTerm) {
        const optionText = option.translateDisplayValue
          ? t(option.displayValueTranslationKeyIdentifier!)
          : option.text;
        return stringHelper.matchesSearch(optionText, searchTerm);
      }
      return true;
    });

    return filteredOptions;
  };

  // When the user ticks/unticks a checkbox
  const toggleCheckbox = (
    checked: CheckboxPrimitive.CheckedState,
    optionId: number | string
  ) => {
    const isChecked = checked !== "indeterminate" && checked === true;
    const nextState = produce(values, (draft) => {
      const match = draft.find((x) => x.optionId === optionId);
      if (match !== undefined) {
        match.isSelected = isChecked;
      }
    });
    setUserHasInteracted(true);
    onChange(nextState);
    debouncedHandleBlur(nextState);
  };

  let containerClassNames = "";
  let checkboxInputClassNames = "";
  switch (displayMode) {
    case "grid":
      containerClassNames =
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1";
      break;
    case "horizontal":
      containerClassNames = "flex items-center";
      checkboxInputClassNames = "flex";
      break;
    case "vertical":
      containerClassNames = "";
      break;
  }

  const instructionMessage = useMemo(
    () =>
      formValidationHelper.getMultiChoiceMinMaxSelectionMessage(
        selectMinCount,
        selectMaxCount
      ),
    [selectMinCount, selectMaxCount]
  );

  // Apply a subtle animation to the instruction text if the user has selected an invalid number of items
  const warnUserAboutInvalidAnswer =
    !showValidationErrors &&
    userHasInteracted &&
    !selectedCountIsInRange(countSelected, selectMinCount, selectMaxCount);


  const convertOptionsToCheckbox = (options: TOption[], hasCategories: boolean): ReactNode => {
    return <>
      {options.map(
        ({ value, text, optionId, isSelected, allowCustomText }) => {
          const itemKey = `item_${optionId}`;
          const itemCustomText = optionCustomValues.find(
            (x) => x.key === optionId
          )?.value;
          const checkbox = (
            <>
              <div className="flex flex-row m-2 print:m-[2px]">
                <CheckboxPrimitive.Root
                  id={`chk${uniqueFieldName}_${optionId}`}
                  value={value}
                  checked={isSelected}
                  disabled={isReadOnly}
                  onCheckedChange={(
                    checked: CheckboxPrimitive.CheckedState
                  ) => toggleCheckbox(checked, optionId)}
                  className={cx(
                    checkboxInputClassNames,
                    checkboxBorderClassName,
                    "h-5 w-5 flex-shrink-0 items-center justify-center rounded leading-tight",
                    `radix-state-checked:${checkboxCheckedBgColourClassName} radix-state-unchecked:${checkboxUncheckedBgColourClassName}`,
                    "focus:outline-none disabled:cursor-not-allowed"
                  )}
                  style={isSelected && checkboxCheckedBgColourHexCode
                    ? {
                      backgroundColor: `#${checkboxCheckedBgColourHexCode}`,

                      borderColor: `#${checkboxCheckedBgColourHexCode}`,
                    }
                    : {}}
                >
                  <CheckboxPrimitive.Indicator>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={cx(iconColourClassName, "self-center")} />
                  </CheckboxPrimitive.Indicator>
                </CheckboxPrimitive.Root>

                <LabelPrimitive.Label
                  htmlFor={`chk${uniqueFieldName}_${optionId}`}
                  className={cx(
                    textColourClassName,
                    "ml-3 select-none text-sm font-medium opacity-80"
                  )}
                >
                  {text}
                </LabelPrimitive.Label>
              </div>
              {isSelected && allowCustomText && (
                <CustomTextInput<TOption>
                  itemCustomText={itemCustomText}
                  optionId={optionId}
                  values={values}
                  onChange={onChange}
                  optionCustomValues={optionCustomValues}
                  onOptionCustomValueChange={setOptionCustomValues}
                  onBlur={debouncedHandleBlur} />
              )}
            </>
          );

          switch (displayMode) {
            case "grid":
              return (
                <div key={itemKey} className="basis-full md:basis-1/2">
                  {checkbox}
                </div>
              );
            case "horizontal":
              return <Fragment key={itemKey}>{checkbox}</Fragment>;
            case "vertical":
              return <div key={itemKey}>{checkbox}</div>;
            default:
              return null;
          }
        }
      )}
    </>;
  };

  return (
    <>
      {!showValidationErrors && (
        <span
          className={`block mb-1 italic font-light text-sm text-opacity-50 ${warnUserAboutInvalidAnswer ? "animate-shake" : ""
            }`}
        >
          {instructionMessage}
        </span>
      )}
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      {fieldLabel != null && (
        <div
          className={cx(
            textColourClassName,
            "text-sm font-regular leading-4 mb-5"
          )}
        >
          {fieldLabel}
        </div>
      )}

      {/* No Categories */}
      {categories == undefined && (
        <div className={containerClassNames}>
          {convertOptionsToCheckbox(values, false)}
        </div>
      )}

      {/* With Categories */}
      {categories != null && categories.length > 0 && (
        <>
          <MultiChoiceQuestionSearchField
            searchTerm={searchTerm}
            onSearch={onSearch} />

          <div className={containerClassNames}>
            {/* No items to show */}
            {displayItems.length === 0 && (
              <p className="text-center text-sm">
                <span className="italic">{t('Common.EmptyState.Search')}</span>
              </p>
            )}
            {/* Items to show */}
            {displayItems.length > 0 && (
              <>
                {categories.map(
                  ({ id, titleTranslationKeyIdentifier }) => {
                    // Retrieve all of the options that belong to this category
                    const categoryOptions = displayItems.filter(
                      (x) => x.categoryId === id
                    );

                    return (
                      <MultiChoiceOptionItemWithCategory
                        key={id}
                        categoryId={id}
                        titleTranslationKeyIdentifier={titleTranslationKeyIdentifier}
                        options={categoryOptions}
                        forceExpand={expandAllCategories}
                        getReactNodesForOptions={convertOptionsToCheckbox}
                      />
                    );
                  }
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default memo(CheckBoxList) as typeof CheckBoxList;
