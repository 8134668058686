import { ClientTaskTypeCategory } from "../types/dtos/tasks/advanced-tasks/ClientTaskTypeCategory";
import { ValidationResult } from "../types/forms";

export const taskValidationHelper = {
  isCategoryValid(
    categoryId: string | null | undefined,
    categories: ClientTaskTypeCategory[]
  ): ValidationResult {
    const guidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const isGuid = categoryId ? guidPattern.test(categoryId) : false;

    if (
      isGuid &&
      categories.length > 0 &&
      categoryId != null &&
      categoryId != undefined
    ) {
      return new ValidationResult(true, []);
    } else {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }
  },
  isTargtDateValid(date: Date | null | undefined): ValidationResult {
    if (date != null && date != undefined) {
      return new ValidationResult(true, []);
    } else {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }
  },
  isTitleValid(title: string | null | undefined): ValidationResult {
    if (title != null && title != "") {
      return new ValidationResult(true, []);
    } else {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }
  },
};

export default taskValidationHelper;
