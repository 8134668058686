import { t } from "i18next";
import cx from "classnames";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import { KeyValuePair } from "../../types/generic";

export interface GenericDropDownListProps {
  /** The Id attribute for the select element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  bgColorClassName?: string;
  /** The options to show in the list */
  items: KeyValuePair<string | number, string>[];
  /** The current value to display */
  currentValue: string | null;
  /** The onChange event, returning the new state of the options */
  onChange(selectedValue: string | null): void;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
  includeSelectOption?: boolean;
  includeSelectOptionWithStringDefaultValue?: boolean;
  selectOptionCustomText?: string;
  applyBorder?: boolean;
}

/** A form field containing a dropdownlist (i.e. a `select` element) */
const GenericDropDownList = ({
  items,
  currentValue,
  onChange,
  showValidationErrors = false,
  validationResult = null,
  inputId = "",
  className = "",
  bgColorClassName = "",
  isReadOnly = false,
  includeSelectOption = true,
  includeSelectOptionWithStringDefaultValue = false,
  selectOptionCustomText,
  applyBorder = false,
}: GenericDropDownListProps) => {
  /* The plan is to use the RadixUI select component once that is released */
  /* so the current `select` element is just temporary */
  /* https://twitter.com/radix_ui/status/1494784126645329922 */
  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <select
        id={inputId}
        className={cx(
          className,
          bgColorClassName,
          "rounded-md focus:outline-none focus:ring-0",
          isReadOnly ? "cursor-not-allowed" : "",
          applyBorder ? "border-1" : "border-0"
        )}
        value={currentValue ? currentValue : undefined}
        onChange={(e) => onChange(e.target.value)}
        disabled={isReadOnly}
      >
        {includeSelectOption && !includeSelectOptionWithStringDefaultValue && (
          <option className="text-gray-600">
            {selectOptionCustomText ? selectOptionCustomText : t("Common.DropdownDefaultItem")}
          </option>
        )}
        {includeSelectOption && includeSelectOptionWithStringDefaultValue && (
          <option className="text-gray-600" value="default">
            {selectOptionCustomText ? selectOptionCustomText : t("Common.DropdownDefaultItem")}
          </option>
        )}
        {items.map(({ key, value }, ix) => (
          <option
            className="text-gray-600"
            key={`opt_${key}_${ix}`}
            value={key}
          >
            {t(value)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GenericDropDownList;
