import { useTranslation } from "react-i18next";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons";

interface DashboardTaskBarLockedStateProps {
  selectedTaskType: ClientTaskType | undefined;
  journeyTitle?: string | null;
}

function DashboardTaskBarLockedState({
  selectedTaskType,
  journeyTitle,
}: DashboardTaskBarLockedStateProps) {
  const { t } = useTranslation();
  const taskTypePluralTranslationKeyIdentifier =
    selectedTaskType != undefined
      ? selectedTaskType.pluralNameTranslationKeyIdentifier
      : "";

  const displayText = journeyTitle != null
    ? t("DashboardTaskBar.LockedState.TextJourneyNameProvided", {
      taskType: t(taskTypePluralTranslationKeyIdentifier),
      journeyName: journeyTitle != undefined ? journeyTitle : "",
    })
    : t("DashboardTaskBar.LockedState.TextWithNoJourneyName", {
      taskType: t(taskTypePluralTranslationKeyIdentifier),
    });
  return (
    <div className="rounded border border-gray-200 bg-white">
      <div className="text-sm text-center text-gray-400 grow px-4 pb-4 mt-2 pt-1 lg:mt-6">
        <FontAwesomeIcon
          color={"#D1D5DB"}
          size="3x"
          icon={faLock}
          className="mb-2"
        />
        <p>{displayText}</p>
      </div>
    </div>
  );
}

export default DashboardTaskBarLockedState;
