import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { t } from "i18next";
import { Tooltip } from "../common";
import { interactionHelper } from "../../helpers";

const deFocusWidget = () => {
  interactionHelper.deFocusJourney();
};

const FocusedJourneyCloseButton = () => {
  return (
    <Tooltip
      triggerElement={
        <button id="defocus-journey-btn" onClick={deFocusWidget}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            size={"2x"}
            className="text-white"
          />
        </button>
      }
      content={t("Journey.Button.DeFocus")}
    />
  );
};

export default FocusedJourneyCloseButton;
