import { useContext, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { localStorageHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import AppRoutes from "../AppRoutes";

/** Log out the user... */
function AuthLogout() {
  const userContext = useContext(UserContext);
  const auth = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const signOutTriggeredExternally = urlParams.get("remote") === "1";
    const extSsoClientId = Number(urlParams.get("extSso"));

    let externalSsoClientId: number | undefined = Number(extSsoClientId);
    if (isNaN(externalSsoClientId) || externalSsoClientId <= 0) {
      externalSsoClientId = undefined;
    }

    // Sign out the user from this app, then redirect them to the TT (MVC app) signout url as well
    const mvcAppRootUrl =
      userContext.user.client.mvcAppUrl ||
      localStorageHelper.getItem("USER_MVC_APP_URL");

    const postLogoutUrl = AppRoutes.auth.postLogoutRedirectUri(
      mvcAppRootUrl!,
      signOutTriggeredExternally,
      externalSsoClientId
    );

    localStorageHelper.removeItem("USER_MVC_APP_URL");
    if (auth.isAuthenticated) {
      auth.signoutRedirect({
        post_logout_redirect_uri: postLogoutUrl,
        id_token_hint: auth.user?.id_token,
      });
    } else {
      window.location.href = postLogoutUrl;
    }
  }, []);

  return null;
}

export default AuthLogout;
