import { Fragment } from "react";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";
import {
  SimpleFormAnswer,
  SimpleFormContent,
  SimpleFormValidationError,
} from "../../../types/dtos/simple-forms";
import SignedInUserDto from "../../../types/dtos/users/SignedInUserDto";
import { SimpleFormRequiredBadgeMode } from "../../../types/simple-forms";
import { EditableSimpleFormQuestion } from "./EditableSimpleFormQuestion";
import { ReadonlySimpleFormQuestion } from "./ReadonlySimpleFormQuestion";

interface SimpleFormProps {
  /** Determines whether to use second-person text or third-person */
  loggedInUser: SignedInUserDto;
  subjectUser: BaseUserDetailsDto;
  formContent: SimpleFormContent;
  answers: SimpleFormAnswer[];
  validationErrors: SimpleFormValidationError[] | null;
  requiredBadgeMode?: SimpleFormRequiredBadgeMode;
  onChange(newAnswer: SimpleFormAnswer): void;
  isReadOnly?: boolean;
  /** If you want to override the styling, provide a container className then target child elements with CSS */
  containerClassName?: string | undefined;
  /** If you want to show a title or something, pass it in as an element, styled as you want etc */
  header?: JSX.Element | undefined;
  /** Useful if you want to display buttons with their own click event handlers, pass them in */
  footer?: JSX.Element | undefined;
}

export const SimpleForm = ({
  loggedInUser,
  subjectUser,
  formContent,
  answers,
  validationErrors,
  isReadOnly = false,
  requiredBadgeMode = "SHOW-REQUIRED",
  containerClassName = undefined,
  header = undefined,
  footer = undefined,
  onChange,
}: SimpleFormProps) => {
  return (
    <div className={`esf ${containerClassName ? containerClassName : ""}`}>
      {header && <div className="esf-header">{header}</div>}
      <div className="esf-body">
        {formContent.questions.map((question) => {
          // Find the answer for this question, if it exists, otherwise null (should only be one answer object per question max)
          var questionAnswer =
            answers.find((a) => a.questionId === question.questionId) || null;
          var questionErrors =
            validationErrors?.filter(
              (e) => e.questionId === question.questionId
            ) || null;
          return (
            <Fragment key={question.questionId}>
              {!isReadOnly ? (
                <EditableSimpleFormQuestion
                  key={question.questionId}
                  loggedInUser={loggedInUser}
                  subjectUser={subjectUser}
                  requiredBadgeMode={requiredBadgeMode}
                  question={question}
                  answer={questionAnswer}
                  onChange={onChange}
                  validationErrors={questionErrors}
                />
              ) : (
                <ReadonlySimpleFormQuestion
                  key={question.questionId}
                  loggedInUser={loggedInUser}
                  subjectUser={subjectUser}
                  requiredBadgeMode={requiredBadgeMode}
                  question={question}
                  answer={questionAnswer}
                />
              )}
            </Fragment>
          );
        })}
      </div>
      {footer && <div className="esf-footer">{footer}</div>}
    </div>
  );
};

export default SimpleForm;
