import { useState } from "react";

interface TruncatableGoalFieldProps {
  text: string;
}

function TruncatableGoalField({ text }: TruncatableGoalFieldProps) {
  const maxLength = 100;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!text || text.trim().length === 0) return null;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  let displayText = text.trim();

  if (isExpanded || displayText.length < maxLength) {
    return <p>{displayText}</p>;
  }

  displayText = displayText.substring(0, maxLength);

  return (
    <p>
      <span>{displayText}&hellip;</span>
      <button
        className="text-xs underline ml-[2px] pt-[2px] font-semibold"
        onClick={toggleExpand}
      >
        See more
      </button>
    </p>
  );
}

export default TruncatableGoalField;
