import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { t } from "i18next";
import cx from "classnames";
import { useCallback, useState } from "react";
import PeoplePickerUsersApiResponseDataDto from "../../types/dtos/generic/PeoplePickerUsersApiResponseDataDto";
import { ValidationResult } from "../../types/forms";
import AutoCompleteSuggestionList from "./AutoCompleteSuggestionList";
import ValidationWarning from "./ValidationWarning";
import PeoplePickerUserDto from "../../types/dtos/generic/PeoplePickerUserDto";
import { useAuth } from "react-oidc-context";
import userApi from "../../api/user/userApi";
import { debounce } from "lodash";
import PeoplePickerSearchCriteria from "../../types/dtos/generic/PeoplePickerSearchCriteria";

interface PeoplePickerProps {
  /** The onChange event, for handling state changes.
   * For single-select mode, the value is either a number (the Id of the user) or a PeoplePickerUserDto (controlled by the `nonMultiSelectChangeValueType` prop).
   * For multi select it's only an array of DTOs (or null).
   */
  onPeoplePickerChange(
    newValue: number | PeoplePickerUserDto | PeoplePickerUserDto[] | null
  ): void;
  initialInput?: string;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** Bool that allows multiple people to be selected, if false just one person */
  allowMultipleSelection?: boolean;
  /** Only needed for when multiple selections is enabled */
  selectedUsers?: PeoplePickerUserDto[];
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  nonMultiSelectChangeValueType?: "NUMERIC-ID" | "USER-DTO";
  excludeLoggedInUserFromResults?: boolean;
  userIdToExcludeFromResults?: number | null; // This is used for the admin user details page so the admin doesn't see the user in the results
  autoSuggestModifyWidth?: boolean;
  allowClearField?: boolean;
  onClearFieldClick?(): void;
  /** Function to trigger when input is empty from typing (rather than hitting clear 'x') */
  onInputEmpty?(): void;
}

const PeoplePicker = ({
  onPeoplePickerChange,
  initialInput = undefined,
  inputId = "",
  className = "block w-full border-0 border-none",
  allowMultipleSelection = false,
  selectedUsers = [],
  showValidationErrors = false,
  validationResult = null,
  nonMultiSelectChangeValueType = "NUMERIC-ID",
  excludeLoggedInUserFromResults = false,
  userIdToExcludeFromResults = null,
  autoSuggestModifyWidth,
  allowClearField,
  onClearFieldClick,
  onInputEmpty = undefined,
}: PeoplePickerProps) => {
  const auth = useAuth();
  const apiUsers = new userApi(auth.user?.access_token);
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    PeoplePickerUserDto[]
  >([]);
  const [input, setInput] = useState(initialInput != undefined ? initialInput : "");

  const debouncedPerformSearch = useCallback(
    debounce((searchValue: string, userIdsToExclude: number[]) => {
      performSearch(searchValue, userIdsToExclude);
    }, 300),
    []
  );

  const performSearch = (searchValue: string, userIdsToExclude: number[]) => {
    const criteria: PeoplePickerSearchCriteria = {
      searchTerm: searchValue,
      selectedUserIdsToExclude: userIdsToExclude,
      excludeLoggedInUser: excludeLoggedInUserFromResults,
      userIdToExclude: userIdToExcludeFromResults
    };

    apiUsers.getUsersForPeoplePicker(
      criteria,
      (data: PeoplePickerUsersApiResponseDataDto) => {
        setFilteredSuggestions(data.users);
      },
      (error) => {
        console.log("User api error", error); // TODO: Handle error properly
      }
    );
  };

  const onChange = (newValue: string) => {
    setInput(newValue);

    if (newValue.length > 0) {
      debouncedPerformSearch(newValue, selectedUsers.map(x => x.userId));
    } else if (newValue.length === 0 && onInputEmpty) {
      onInputEmpty();
    }
  };

  const onSuggestionClick = (newValue: PeoplePickerUserDto) => {
    if (allowMultipleSelection) {
      const isUserAlreadySelected =
        selectedUsers.findIndex((x) => x.userId === newValue.userId) !== -1;

      // By this point it shouldn't allow a duplicate to be selected as it's filtered out,
      // however for another added level... only add if it's not already been selected
      if (!isUserAlreadySelected) {
        onPeoplePickerChange([...selectedUsers, newValue]);
      }

      setFilteredSuggestions([]);
      setInput("");
    } else {
      setFilteredSuggestions([]);
      setInput(newValue.fullName);
      if (nonMultiSelectChangeValueType === "NUMERIC-ID") {
        onPeoplePickerChange(newValue.userId);
      } else {
        onPeoplePickerChange(newValue);
      }
    }
  };

  const onClearField = () => {
    setInput("");
    onPeoplePickerChange(null);

    if (onClearFieldClick) {
      onClearFieldClick();
    }
  }

  const onRemoveFromSelectedUsers = (person: PeoplePickerUserDto) => {
    let newSelectedUserState = [...selectedUsers];
    let index = newSelectedUserState.findIndex(
      (x) => x.userId === person.userId
    );
    newSelectedUserState.splice(index, 1);
    onPeoplePickerChange(newSelectedUserState);
  };

  return (
    <div className="w-full">
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}

      <div className={cx(allowClearField ? "relative" : "")}>
        <input
          type="text"
          id={inputId}
          onChange={(e) => onChange(e.target.value)}
          value={input ? input : ""}
          className={cx(
            className,
            "disabled:cursor-not-allowed rounded-md focus:outline-none focus:ring-0"
          )}
          autoComplete={"off"}          
        />
        {allowClearField && (
          <FontAwesomeIcon
            size="sm"
            icon={faTimes}
            className="absolute right-4 top-3 cursor-pointer"
            onClick={onClearField}
          ></FontAwesomeIcon>
        )}
      </div>
      {input && (
        <AutoCompleteSuggestionList
          onSuggestionClick={onSuggestionClick}
          filteredSuggestions={filteredSuggestions}
          modifyWidth={autoSuggestModifyWidth}
        />
      )}
      {allowMultipleSelection && selectedUsers.length > 0 && (
        <div>
          <div className="mt-2">
            <p>{t("Pages.Admin.Common.YouHaveSelectedToSendTo")}</p>
            {selectedUsers.map((user) => {
              return (
                <div
                  key={user.userId}
                  onClick={() => onRemoveFromSelectedUsers(user)}
                  className="rounded-full border bg-gray-50 cursor-pointer inline-block p-1 px-3 ml-2 mt-2"
                >
                  <p className="inline-block">{user.fullName}</p>
                  <FontAwesomeIcon
                    size="sm"
                    icon={faTimes}
                    className="inline-block pl-2"
                  ></FontAwesomeIcon>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default PeoplePicker;
