import { AnalyticsWidgetUiDetailsDto } from "../types/analytics";
import { BarChartGroupingType } from "../types/analytics/AnalyticsWidgetConfigs";

const widgetConfigsHelper = {
  /** Returns either "GROUPED" or "STACKED" to render the bar chart in the desired way when there are multiple datasets */
  getBarGroupingMode: function (
    widget: AnalyticsWidgetUiDetailsDto
  ): BarChartGroupingType {
    if (widget && widget.configs && widget.configs.length > 0) {
      var match = widget.configs.find((x) => x.key === "BAR-GROUPING-MODE");
      if (match && match.value === "STACKED") {
        return "STACKED";
      }
    }

    return "GROUPED";
  },
};

export default widgetConfigsHelper;
