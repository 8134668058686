import { chartConstants } from "./ChartConstants";

interface ChartLegendProps {
  keys: string[];
}

/** A custom legend for when the inbuilt legend just won't do */
function ChartLegend({ keys }: ChartLegendProps) {
  const getColour = (index: number) => {
    if (
      chartConstants.coloursArray &&
      chartConstants.coloursArray.length >= index
    ) {
      return chartConstants.coloursArray[index];
    }
    return "#000000";
  };

  return (
    <div className="py-2 flex flex-row space-x-2 justify-center text-[11px] select-none">
      {keys.map((item, itemIx) => (
        <div key={item} className="flex flex-row content-center pr-1">
          <span
            className="rounded-full w-4 h-4 inline-block mr-1"
            style={{ backgroundColor: getColour(itemIx) }}
          ></span>{" "}
          <span className="text-[#999999] hover:text-black">{item}</span>
        </div>
      ))}
    </div>
  );
}

export default ChartLegend;
