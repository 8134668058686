import FilterAvailableValues from "./FilterAvailableValues";
import AnalyticsFilterInputs, {
  AnalyticsFilterComparisonProperty,
} from "./AnalyticsFilterInputs";
import JourneyFilterDefaultValueMode from "./JourneyFilterDefaultValueMode";
import { IDateRange } from "../generic";

export class FilterDefaultSelectedValues implements AnalyticsFilterInputs {
  constructor(
    journeySelectionDefaultValueMode: JourneyFilterDefaultValueMode,
    availableValues: FilterAvailableValues
  ) {
    this.activeUsersOnly = true;
    this.includePartiallyApproved = false;
    this.comparisonProperty = "NONE";
    this.journeyKeys = [];

    // Select "Last year" by default
    this.dateRange =
      availableValues?.definedDateRanges && availableValues.definedDateRanges[4]
        ? availableValues.definedDateRanges[4]
        : null;

    this.locationIds = null;
    this.jobTitleIds = null;
    this.appraisalLevelIds = null;

    if (journeySelectionDefaultValueMode === "MULTI") {
      this.allJourneys = true;
    } else {
      // For singular mode, attempt to select the first journey from the first group,
      // which should be the most recent client-sent journey
      this.allJourneys = false;
      if (availableValues?.journeys) {
        this.journeyKeys = [availableValues.journeys[0].journeys[0].key];
      }
    }

    // If the only available journeys are exit journeys, then we should default to including leaver users
    if (availableValues?.journeys && availableValues.journeys.length > 0) {
      var distinctJourneyGroupTypes = availableValues.journeys
        .map((j) => j.groupType)
        .filter((value, index, self) => self.indexOf(value) === index);
      if (
        distinctJourneyGroupTypes.length === 1 &&
        distinctJourneyGroupTypes[0] === "EXIT"
      ) {
        this.activeUsersOnly = false;
      }
    }
  }

  allJourneys: boolean;
  journeyKeys: string[];
  dateRange: IDateRange | null;
  activeUsersOnly: boolean;
  includePartiallyApproved: boolean;
  comparisonProperty: AnalyticsFilterComparisonProperty;
  locationIds: number[] | null;
  jobTitleIds: number[] | null;
  appraisalLevelIds: number[] | null;
}

export default FilterDefaultSelectedValues;
