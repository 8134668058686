import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

interface WarningAlertProps {
  prefix: String | null;
  message: String | null;
}

function WarningAlert({ prefix, message }: WarningAlertProps) {
  return (
    <div className="bg-orange-50 py-1 px-4 mb-2 text-orange-700 text-sm rounded-sm border-l-2 border-l-orange-800">
      <div>
        <div className="fa-layers fa-fw mr-0.5">
          <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
        </div>
        <span className="text-orange-800 font-medium">{prefix}</span>
        {" "}
        {message}
      </div>
    </div>
  );
}

export default WarningAlert;
