import { useEffect, useState } from "react";
import { DashboardSummaryTabDto } from "../../types/dtos/dashboards";
import ManagerDashboardPersonDto from "../../types/dtos/dashboards/ManagerDashboardPersonDto";
import { AppraisalLevelClientTaskTypeDto } from "../../types/dtos/users/AppraisalLevelClientTaskTypeDto";
import LegacyReviewLink from "../common/LegacyReviewLink";
import LegacyTaskLink from "../common/LegacyTaskLink";
import PeopleCard from "./People/PeopleCard";
import ManagerDashboardEmptyState from "./ManagerDashboardEmptyState";
import { Badge } from "../common";

interface PeopleWidgetProps {
  users: ManagerDashboardPersonDto[];
  appraisalLevelTaskTypes: AppraisalLevelClientTaskTypeDto[];
  isLoading: boolean;
  isLegacyClient: boolean;
  clientHasTaskTypes: boolean;
  employeesHaveLegacyReviews: boolean;
  searchTerm: string | null;
  /** A function to call when the manager has selected to update a section for one of their people */
  onTriggerSectionUpdate(
    userId: number,
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | null
  ): void;
  onOpenUserHistory(userId: number): void;
  onLiveJourneyClick(userId: number): void | undefined;
  onUserTabChange?(
    userId: number,
    clientFormId: number,
    onSuccess: () => void,
    onError: () => void
  ): void | undefined;
  onUpdateUser(user: ManagerDashboardPersonDto): void | undefined;
}

function PeopleWidget({
  users,
  appraisalLevelTaskTypes,
  isLoading,
  isLegacyClient,
  clientHasTaskTypes,
  employeesHaveLegacyReviews,
  searchTerm,
  onTriggerSectionUpdate,
  onOpenUserHistory,
  onLiveJourneyClick,
  onUserTabChange = undefined,
  onUpdateUser,
}: PeopleWidgetProps) {
  const [filteredUsers, setFilteredUsers] =
    useState<ManagerDashboardPersonDto[]>(users);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (searchTerm) {
      const displayItems = [...users].filter((u) => {
        const userIds = users
          .filter((user) =>
            user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((u) => u.userId);
        return userIds.includes(u.userId);
      });

      setFilteredUsers(displayItems);
    } else {
      setFilteredUsers(users);
    }
  }, [searchTerm]);
  return (
    <div className="pt-2">
      <div className="bg-[#F7F8FA] rounded-md">
        <div className="px-5 p-3 mb-3 border border-gray-200 rounded px-5 py-3 bg-gray-100">
          <div className="flex flex-col md:flex-row">
            <h3 className="text-gray-200 grow !mb-1">Your People</h3>
            {isLegacyClient &&
              !isLoading &&
              (!clientHasTaskTypes || employeesHaveLegacyReviews) && (
                <>
                  <div className="hidden group py-2 text-xs text-gray-500 rounded-t w-full md:flex md:flex-row md:text-right md:w-fit md:text-center">
                    <span className="mx-2 text-[#5d5d5d]">Historic data:</span>
                    {!clientHasTaskTypes && <LegacyTaskLink />}
                    {employeesHaveLegacyReviews && <LegacyReviewLink />}
                  </div>
                </>
              )}
          </div>

          {isLoading && (
            <>
              <PeopleCard
                user={null}
                appraisalLevelTaskTypes={[]}
                isLegacyClient={isLegacyClient}
                onTriggerSectionUpdate={onTriggerSectionUpdate}
                onLiveJourneyClick={onLiveJourneyClick}
                onUpdateUser={onUpdateUser}
              />
              <PeopleCard
                user={null}
                appraisalLevelTaskTypes={[]}
                isLegacyClient={isLegacyClient}
                onTriggerSectionUpdate={onTriggerSectionUpdate}
                onLiveJourneyClick={onLiveJourneyClick}
                onUpdateUser={onUpdateUser}
              />
              <PeopleCard
                user={null}
                appraisalLevelTaskTypes={[]}
                isLegacyClient={isLegacyClient}
                onTriggerSectionUpdate={onTriggerSectionUpdate}
                onLiveJourneyClick={onLiveJourneyClick}
                onUpdateUser={onUpdateUser}
              />
            </>
          )}
          {!isLoading && (
            <>
              {filteredUsers.length === 0 && (
                <ManagerDashboardEmptyState optionalText="There are no people that match the search criteria you gave. Please refine your search." />
              )}

              {filteredUsers.length > 0 && (
                <>
                  {filteredUsers.map((u) => {
                    return (
                      <PeopleCard
                        key={`user_${u.userId}`}
                        user={u}
                        appraisalLevelTaskTypes={appraisalLevelTaskTypes}
                        isLegacyClient={isLegacyClient}
                        onTriggerSectionUpdate={onTriggerSectionUpdate}
                        onUserTabChange={onUserTabChange}
                        onOpenUserHistory={
                          u.hasHistoricalFormData
                            ? onOpenUserHistory
                            : undefined
                        }
                        onLiveJourneyClick={onLiveJourneyClick}
                        onUpdateUser={onUpdateUser}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PeopleWidget;
