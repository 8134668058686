import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";

interface FilterEditIconProps {
  extraClassNames?: string | undefined;
}

function FilterEditIcon({ extraClassNames = "" }: FilterEditIconProps) {
  return (
    <FontAwesomeIcon
      icon={faPencilAlt}
      size="xs"
      className={cx(
        "invisible group-hover:visible text-gray-400",
        extraClassNames
      )}
    />
  );
}

export default FilterEditIcon;
