import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";

interface AddCatchUpButtonAreaProps {
  onModalOpen(): void;
}

const AddCatchUpButtonArea = ({ onModalOpen }: AddCatchUpButtonAreaProps) => {
  const { t } = useTranslation();
  return (
    <div className="ml-1 flex mb-44 md:mb-0" id="add-catch-up">
      <div
        className="bg-white block w-full h-20 border rounded border-gray-100 text-center pt-2 group cursor-pointer hover:bg-primary rounded-md border border-gray-200 hover:drop-shadow-md"
        onClick={onModalOpen}
      >
        <div className="flex justify-center pt-0.5">
          <div className="flex w-10 h-10 bg-gray-200 justify-center rounded-full group-hover:[background-color:var(--color-primary)]">
            <span className="fa-layers fa-fw self-center">
              <FontAwesomeIcon
                icon={faUsers}
                className="self-center text-gray-400 group-hover:text-white"
              ></FontAwesomeIcon>
            </span>
          </div>
        </div>

        <span className="text-gray-400 text-sm font-medium">{t("Timeline.Button.AddCatchUp")}</span>
      </div>
    </div>
  );
};

export default AddCatchUpButtonArea;
