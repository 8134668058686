import { t } from "i18next";
import Tooltip from "../../common/Tooltip";

export type ViewingActor = "MANAGER" | "DELEGATED-MANAGER";

interface DelegatedTagProps {
  viewingActor: ViewingActor;
  name: string;
}

export const DelegatedTag = ({
  viewingActor,
  name,
}: DelegatedTagProps) => {
  let displayContent = viewingActor === "MANAGER" 
    ? t("Delegation.DelegatedToOther") 
    : t("Delegation.DelegatedToYou");
  let tooltipContent = viewingActor === "MANAGER" 
    ? t("Delegation.ThisPersonHasBeenDelegatedTo") 
    : t("Delegation.ThisPersonReportsTo");

  displayContent = displayContent.replace("#OTHER_NAME#", name);
  tooltipContent = tooltipContent.replace("#OTHER_NAME#", name);

  const badgeElement = (
    <p className="inline-block text-xs text-[#5d5d5d] bg-[#F5F5F5] rounded-sm px-2 py-1 mr-2">
      {displayContent}
    </p>
  );

  return (
    <Tooltip content={tooltipContent} triggerElement={badgeElement} />
  );
};

export default DelegatedTag;
