import { Link } from "react-router-dom";
import { FallbackProps } from "react-error-boundary";
import AppRoutes from "../../routes/AppRoutes";

function ReactErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className="p-2">
      <h2 className="standard-heading">
        That wasn't supposed to happen
        <span className="accent-text">.</span>
      </h2>
      <pre>Error: {error.message}</pre>
      <p className="mt-2 mb-4">
        We recommend you either try again, or go to{" "}
        <Link to={AppRoutes.yourJourney.root} className="text-link">
          your dashboard
        </Link>
        .
      </p>
      <div className="text-center">
        <button className="btn-primary" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </div>
  );
}

export default ReactErrorFallback;
