import { t } from "i18next";
import AnalyticsWidgetUiDetailsDto from "../AnalyticsWidgetUiDetailsDto";

export interface PieChartItem {
  id: string;
  label: string;
  value: number;
}

export class PieChartDataTransformer {
  /** Get the display text for an item in the pie chart, translated if necessary */
  private getItemDisplayText(
    item: any,
    widget: AnalyticsWidgetUiDetailsDto
  ): string {
    let displayText = item.label;
    const legendItem = widget.legendItems?.find(
      (leg) => leg.key === item.label
    );
    if (legendItem) {
      displayText = legendItem.translatable
        ? t(legendItem.textValue)
        : legendItem.textValue;
    }
    return displayText;
  }

  /** Take the server data and transform and translate it into a shape easier for the chart component to use */
  transformData(widget: AnalyticsWidgetUiDetailsDto): PieChartItem[] {
    try {
      if (
        widget?.datasets &&
        widget.datasets[0] &&
        Array.isArray(widget.datasets[0].items)
      ) {
        return widget.datasets[0].items.map((item: any) => {
          return {
            id: item.label,
            label: this.getItemDisplayText(item, widget),
            value: Number(item.value),
          };
        });
      }
    } catch (error) {
      console.error(
        "Unable to transform data for pie chart: " + widget?.widgetId
      );
    }

    return [];
  }
}
