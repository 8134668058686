import { AnalyticsWidgetUiDetailsDto } from "../../types/analytics";
import AnalyticsFilterInputs from "../../types/analytics/AnalyticsFilterInputs";
import { AnalyticsPageDataDto } from "../../types/analytics/AnalyticsPageDataDto";
import { AnalyticsWidgetRefreshArguments } from "../../types/analytics/AnalyticsWidgetRefreshFilterParams";
import IAnalyticsPageApi from "../../types/analytics/IAnalyticsPageApi";
import { apiClient } from "../apiClient";

class analyticsUsageApi implements IAnalyticsPageApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the whole page of data */
  getPageData(
    filters: AnalyticsFilterInputs,
    onSuccess: (data: AnalyticsPageDataDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "analytics/usage/page", {
      body: JSON.stringify(filters),
    }).then(
      (data) => {
        const pageData = data as AnalyticsPageDataDto;
        onSuccess(pageData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  refreshWidgetData(
    filters: AnalyticsFilterInputs,
    refreshParams: AnalyticsWidgetRefreshArguments,
    onSuccess: (data: AnalyticsWidgetUiDetailsDto) => void,
    onError: (error: any) => void
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }
}

export default analyticsUsageApi;
