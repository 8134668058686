import TaskRelatedTaskBarItemDto from "../../../types/dtos/dashboard-task-bar/TaskRelatedTaskBarItemDto";
import { ClientTaskType } from "../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import TaskRelatedTaskBarItem from "./TaskRelatedTaskBarItem";

interface TaskRelatedTaskBarDisplayProps {
  filterCategory: string;
  items: TaskRelatedTaskBarItemDto[];
  clientColour: string;
  selectedTaskType: ClientTaskType | undefined;
}

function TaskRelatedTaskBarDisplay({
  filterCategory,
  items,
  clientColour,
  selectedTaskType,
}: TaskRelatedTaskBarDisplayProps) {
  return (
    <div>
      <div
        id="scrollable-dashboard-task-bar"
        className="snap-y overflow-y-auto overflow-x-hidden grow ml-1"
        style={{ maxHeight: "340px" }}
      >
        {items.map((item) => (
          <div
            className="snap-start my-2 first-of-type:mt-0 last-of-type:mb-0"
            key={`tr-dashboard-task-bar-item-${item.id}`}
          >
            <TaskRelatedTaskBarItem
              id={item.id}
              itemType={filterCategory}
              title={item.title}
              dueDate={item.dueDate}
              isOverdue={item.isOverdue}
              clientColour={clientColour}
              selectedTaskType={selectedTaskType}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TaskRelatedTaskBarDisplay;
