import cx from "classnames";
// @ts-ignore
import InputNumber from "rc-input-number";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";

interface NumberInputProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: number): void;
  /** The current value for the input */
  value: number;
  /** The minimum value that can be selected */
  minimum: number;
  /** The maximum value that can be selected */
  maximum: number;
  /** The number the value will increase/decrease by */
  step: number;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** A ref for calling methods on the input element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLInputElement> | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
  decimalPlaces?: number | undefined;
}

/** A text input field, with common styling already applied
 */
const NumberInput = ({
  onChange,
  showValidationErrors = false,
  validationResult = null,
  value = 0,
  minimum = 0,
  maximum = 10,
  step = 1,
  inputId = "",
  className = "block w-full",
  placeholder = undefined,
  inputRef = undefined,
  isReadOnly = false,
  decimalPlaces = undefined,
}: NumberInputProps) => {
  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      {/* https://www.npmjs.com/package/rc-input-number */}
      <InputNumber
        onChange={(newValue: number) => onChange(newValue)}
        id={inputId}
        value={value}
        defaultValue={value}
        min={minimum}
        max={maximum}
        step={step}
        className={cx(className, isReadOnly ? "cursor-not-allowed" : "")}
        placeholder={placeholder}
        ref={inputRef}
        disabled={isReadOnly}
        precision={decimalPlaces}
      />
    </div>
  );
};

export default NumberInput;
