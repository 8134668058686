const typeConversionHelper = {
  /** Attempts to convert a string to a number. Returns undefined if not a valid number */
  stringToNumber: function (input: string | undefined): number | undefined {
    const converted = parseInt(input ? input : "");
    if (isNaN(converted)) {
      return undefined;
    }

    return converted;
  },
};

export default typeConversionHelper;
