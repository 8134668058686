import { DevelopmentQuestionAnswerValue, QuestionAnswer } from "../types/forms";

/** Merge the answers which come in an array from the database (one array item per answer, so could be multiple per checkbox list question),
 *  into arrays where there is more than one answer for a QuestionId (e.g. for checklists) */
export const prepareDatabaseAnswersForDisplay = (
  answers: QuestionAnswer[]
): QuestionAnswer[] => {
  const output: QuestionAnswer[] = [];

  for (var i = 0; i < answers.length; i++) {
    const thisAnswer = answers[i];

    const previousEntryIndex = output.findIndex(
      (x) => x.questionId === thisAnswer.questionId
    );

    const newAnswer =
      thisAnswer.answer as unknown as DevelopmentQuestionAnswerValue;

    if (previousEntryIndex === -1) {
      const loopAnswerWithAnswerPropArray: QuestionAnswer = {
        ...thisAnswer,
        answer: [newAnswer],
      };
      output.push(loopAnswerWithAnswerPropArray);
    } else {
      (
        output[previousEntryIndex].answer as DevelopmentQuestionAnswerValue[]
      ).push(newAnswer);
    }
  }

  return output;
};

const developmentQuestionHelper = {
  /** Convert the answers received from the database to the format needed for rendering */
  prepareDatabaseAnswersForDisplay,
};

export default developmentQuestionHelper;
