import { useEffect, useState } from "react";
import TimelineItem from "./TimelineItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import TimelineItemDetailDto from "../../types/dtos/timeline/TimelineItemDetailDto";
import { TaskType } from "../../types/tasks";
import { Link } from "react-router-dom";
import { t } from "i18next";

interface TimelineMobileDisplayProps {
  /** The array of objects to show in the timeline */
  items: TimelineItemDetailDto[];
  clientColor: string;
  triggerEditTaskPopup(taskId: number, taskType: TaskType): void;
}

function TimelineMobileDisplay({
  items,
  clientColor,
  triggerEditTaskPopup,
}: TimelineMobileDisplayProps) {
  const itemsPerPage = 3;
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [maximumPageNumber, setMaximumPageNumber] = useState<number>(1);
  const [displayItems, setDisplayItems] = useState<TimelineItemDetailDto[]>([]);

  useEffect(() => {
    // Set the active page number to the first page, and set the maximum page number
    setActivePageNumber(1);
    setMaximumPageNumber(Math.ceil(items.length / itemsPerPage));

    var clonedItems = items.slice();
    const newDisplayItems = clonedItems.splice(0, itemsPerPage);
    setDisplayItems(newDisplayItems);
  }, [items]);

  const onPreviousClick = () => {
    if (activePageNumber > 1) {
      const newActivePageNumber = activePageNumber - 1;

      var clonedItems = items.slice();
      const newDisplayItems = clonedItems.splice(
        (newActivePageNumber - 1) * itemsPerPage,
        itemsPerPage
      );

      setDisplayItems(newDisplayItems);
      setActivePageNumber(newActivePageNumber);
    }
  };

  const onNextClick = () => {
    if (activePageNumber < maximumPageNumber) {
      const newActivePageNumber = activePageNumber + 1;

      var clonedItems = items.slice();
      const newDisplayItems = clonedItems.splice(
        (newActivePageNumber - 1) * itemsPerPage,
        itemsPerPage
      );
      setDisplayItems(newDisplayItems);

      setActivePageNumber(newActivePageNumber);
    }
  };

  return (
    <div className="block sm:hidden">
      <div>
        {displayItems.map((td, ix) => (
          <div
            className="snap-start"
            key={`timeline-item-${ix}`}
            id={`timeline-item-${ix}`}
          >
            <TimelineItem
              key={td.titleTranslationKey}
              titleTranslationKey={td.titleTranslationKey}
              customTitle={td.customTitle}
              dueDate={td.dueDate}
              type={td.type}
              taskId={td.taskId}
              catchUpId={td.catchUpId}
              taskType={td.taskType}
              triggerEditTaskPopup={triggerEditTaskPopup}
              catchUpOtherParticipantFullName={
                td.catchUpOtherParticipantFullName
              }
              dateCompleted={td.dateCompleted}
              isPlanning={td.isPlanning}
              isCompleted={td.isCompleted}
              clientColour={clientColor}
            />
          </div>
        ))}
      </div>
      {items.length > itemsPerPage && (
        <div className="flex flex-row">
          {activePageNumber > 1 && (
            <Link
              to={""}
              className="basis-1/2 my-1 text-gray-600 font-medium hover:bg-gray-100 py-2 px-4 grow"
              onClick={onPreviousClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="xs" />
              {" " + t("Prevous")}
            </Link>
          )}

          {activePageNumber < maximumPageNumber && (
            <Link
              to={""}
              className="basis-1/2 my-1 text-gray-600 font-medium hover:bg-gray-100 py-2 px-4 text-right grow"
              onClick={onNextClick}
            >
              {t("Next") + " "}
              <FontAwesomeIcon icon={faArrowRight} size="xs" />
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default TimelineMobileDisplay;
