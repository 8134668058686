import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

interface ManagerDashboardEmptyStateProps {
  optionalText?: string;
}

function ManagerDashboardEmptyState({
  optionalText = undefined,
}: ManagerDashboardEmptyStateProps) {
  return (
    <div className="rounded-md p-4 pb-3 mt-1 bg-white text-center border border-gray-200">
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        size="lg"
        className="text-[#5d5d5d] fa-align-center"
      />
      <div className="text-center">
        <p className="text-[#5d5d5d] pl-1 font-semibold">No results found</p>
        {optionalText && (
          <p className="text-sm text-[#5d5d5d] pl-1 pt-2">{optionalText}</p>
        )}
      </div>
    </div>
  );
}

export default ManagerDashboardEmptyState;
