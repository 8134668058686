import TaskCommentsApiResponseDto from "../../types/dtos/tasks/TaskCommentsApiResponseDto";
import NewTaskComment from "../../types/tasks/NewTaskComment";
import TaskCommentDto from "../../types/tasks/TaskCommentDto";
import { apiClient } from "../apiClient";

class simpleTasksApi {
  constructor(authToken: string | undefined) {
    this.accessToken = authToken;
  }

  accessToken: string | undefined;

  /** Get the comments and participants for a task */
  getComments(
    /** The data being submitted to the server */
    taskId: number,
    onSuccess: (data: TaskCommentsApiResponseDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "dashboards/simple-tasks/get-comments?taskId=" + taskId
    ).then(
      (data) => {
        const responseData = data as TaskCommentsApiResponseDto;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Save a new comment against a task */
  submitNewComment(
    /** The data being submitted to the server */
    comment: NewTaskComment,
    onSuccess: (data: TaskCommentDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(this.accessToken, "dashboards/simple-tasks/add-comment", {
      body: JSON.stringify(comment),
    }).then(
      (data) => {
        const responseData = data as TaskCommentDto;
        onSuccess(responseData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Delete a comment against a task */
  deleteComment(
    commentId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `dashboards/simple-tasks/delete-comment?commentId=${commentId}`
    ).then(
      (data) => {
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default simpleTasksApi;
