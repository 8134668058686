import { faRocket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../state/AppContext";
import UserContext from "../../state/UserContext";
import UserwalkthroughType from "../../types/generic/UserWalkthroughType";
import NotificationMenu from "../common/NotificationMenu";
import ProfileNav from "../navigation/ProfileNav";
import ManagerDashWalkthrough from "../user-walkthrough/ManagerDashWalkthrough";
import MyDashWalkthrough from "../user-walkthrough/MyDashWalkthrough";

function TopBar() {
  const appContext = React.useContext(AppContext);
  const [pageTitle, setPageTitle] = useState<string>("");
  const [showPageTitleAccent, setShowPageTitleAccent] = useState<boolean>(true);
  const [launchWalkthrough, setLaunchWalkthrough] = useState<boolean>(false);
  const [
    activeDashboardTypeForWalkthrough,
    setActiveDashboardTypeForWalkthrough,
  ] = useState<UserwalkthroughType | null>(
    appContext.activeDashboardTypeForWalkthrough
  );

  useEffect(() => {
    setActiveDashboardTypeForWalkthrough(
      appContext.activeDashboardTypeForWalkthrough
    );
    setLaunchWalkthrough(false);
  }, [appContext.activeDashboardTypeForWalkthrough]);

  useEffect(() => {
    setPageTitle(appContext.pageTitle);
  }, [appContext.pageTitle]);

  useEffect(() => {
    setShowPageTitleAccent(appContext.showPageTitleAccent);
  }, [appContext.showPageTitleAccent]);
  const userContext = useContext(UserContext);

  const handleCloseWalkthrough = () => {
    setLaunchWalkthrough(false);
  };

  return (
    <div
      id="top-bar"
      className="bg-white pl-6 border-b flex flex-row justify-between"
    >
      <div className="bg-white flex-1 lg:hidden"></div>
      <div id="header">
        <h3 className="text-xl">{pageTitle}</h3>
      </div>
      <div className="lg:hidden flex-1">
        <NotificationMenu
          defaultNotificationData={userContext.user.notifications}
        />
      </div>
      <div className="hidden lg:block pr-8">
        <ProfileNav />
      </div>
    </div>
  );
}

export default TopBar;
