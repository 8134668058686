import produce from "immer";
import { t } from "i18next";
import cx from "classnames";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import MultipleChoiceOptionNumericId, {
  MultipleChoiceOptionStringId,
} from "../../types/forms/MultipleChoiceOptions";

export interface FormDropDownListProps<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
> {
  /** The Id attribute for the select element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The options to show in the list */
  values: TOption[];
  /** The onChange event, returning the new state of the options */
  onChange(updatedValues: TOption[]): void;
  /** An event to call when the input loses focus */
  onBlur?(latestValues: TOption[]): void | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
}

/** A form field containing a dropdownlist (i.e. a `select` element) */
function FormDropDownList<
  TOption extends MultipleChoiceOptionNumericId | MultipleChoiceOptionStringId
>({
  values,
  onBlur = undefined,
  onChange,
  showValidationErrors = false,
  validationResult = null,
  inputId = "",
  className = "",
  isReadOnly = false,
}: FormDropDownListProps<TOption>) {
  /** Ensure that only one selected value exists in the state */
  const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const valueIdIsNumeric = !isNaN(Number(event.target.value));
    const optionId = valueIdIsNumeric
      ? parseInt(event.target.value)
      : event.target.value;
    const nextState = produce(values, (draft) => {
      draft.forEach((option) => {
        option.isSelected = option.optionId === optionId;
      });
    });
    onChange(nextState);

    // Call the onBlur event with the latest value
    if (onBlur) {
      onBlur(nextState);
    }
  };

  const selectedOptionId = values.find((x) => x.isSelected)?.optionId;

  /* The plan is to use the RadixUI select component once that is released */
  /* so the current `select` element is just temporary */
  /* https://twitter.com/radix_ui/status/1494784126645329922 */

  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <select
        id={inputId}
        className={cx(className, "disabled:cursor-not-allowed")}
        value={selectedOptionId}
        onChange={handleValueChange}
        disabled={isReadOnly}
      >
        <option className="text-gray-500">
          {t("Common.DropdownDefaultItem")}
        </option>
        {values.map(({ value, text, isSelected, optionId }) => (
          <option
            className="text-gray-500 hover:bg-gray-100"
            key={optionId}
            value={optionId}
          >
            {text}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FormDropDownList;
