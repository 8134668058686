import i18next from "i18next";

export const i18nHelper = {
  setCurrentLanguage: function (clientId: number, cultureCode: string) {
    const clientTranslation = `translations.${clientId}`;
    i18next.loadNamespaces(clientTranslation);
    i18next.setDefaultNamespace(clientTranslation);
    i18next.changeLanguage(cultureCode, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      //t('key'); // -> same as i18next.t
    });
  },
};

export default i18nHelper;
