import cx from "classnames";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

interface CheckBoxProps {
  controlId?: string | undefined;
  labelText: string;
  checkboxClassNames?: string | undefined;
  labelClassNames?: string | undefined;
  containerClassNames?: string | undefined;
  selected: boolean;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
  checkboxPosition?: "LEFT" | "RIGHT";
  includeCheckboxContainerClass?: boolean;
}

const CheckBox = ({
  controlId,
  labelText,
  selected,
  onChange,
  labelClassNames = "",
  containerClassNames = "",
  checkboxClassNames = "",
  disabled = false,
  checkboxPosition = "LEFT",
  includeCheckboxContainerClass = true,
}: CheckBoxProps) => {
  const handleCheckChange = (checked: Checkbox.CheckedState) => {
    onChange(checked === true);
  };

  const labelElement = (
    <label
      className={cx(
        "select-none",
        checkboxPosition === "LEFT" ? "ml-2" : "mr-2",
        labelClassNames,
        disabled ? "cursor-not-allowed text-gray-600" : ""
      )}
      htmlFor={controlId}
    >
      {labelText}
    </label>
  );

  return (
    <div className={cx(
      "flex", 
      includeCheckboxContainerClass ? "CheckboxContainer" : "",
      containerClassNames
    )}>
      {checkboxPosition === "RIGHT" ? labelElement : null}
      <Checkbox.Root
        className={cx(
          "CheckboxRoot disabled:cursor-not-allowed",
          checkboxClassNames
        )}
        id={controlId}
        checked={selected}
        onCheckedChange={handleCheckChange}
        disabled={disabled}
      >
        <Checkbox.Indicator className="CheckboxIndicator">
          <CheckIcon className={disabled ? "text-gray-600" : ""} />
        </Checkbox.Indicator>
      </Checkbox.Root>
      {checkboxPosition === "LEFT" ? labelElement : null}
    </div>
  );
};

export default CheckBox;
