import React, { useEffect } from "react";
import { MainContainer } from "../components/layout";
import { Link } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AppContext from "../state/AppContext";

type ErrorPageProps = {
  errorTitle: string;
  shortDescription?: string;
};

const ErrorPage = ({ errorTitle, shortDescription }: ErrorPageProps) => {
  const appContext = React.useContext(AppContext);
  const bodyText = shortDescription ? shortDescription : "Something went wrong";

  useEffect(() => {
    appContext.setPageTitle(errorTitle);
    appContext.setShowPageTitleAccent(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContainer>
      <p>{bodyText}</p>
      <div className="text-center mt-4">
        <Link to={AppRoutes.yourJourney.root} className="btn-primary">
          Back to your dashboard
        </Link>
      </div>
    </MainContainer>
  );
};

export default ErrorPage;
