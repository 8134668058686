interface EmptyAnalyticsStateProps {
  classNames?: string | undefined;
}

const EmptyFilteredState = ({ classNames }: EmptyAnalyticsStateProps) => {
  return (
    <svg
      width="123"
      height="109"
      viewBox="0 0 123 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
    >
      <path
        d="M11.5808 25.8756C13.602 25.8756 15.2405 24.2362 15.2405 22.2138C15.2405 20.1915 13.602 18.552 11.5808 18.552C9.55959 18.552 7.92108 20.1915 7.92108 22.2138C7.92108 24.2362 9.55959 25.8756 11.5808 25.8756Z"
        fill="#F1F3F9"
      />
      <path
        d="M111.741 37.0539C113.762 37.0539 115.401 35.4144 115.401 33.392C115.401 31.3697 113.762 29.7302 111.741 29.7302C109.72 29.7302 108.081 31.3697 108.081 33.392C108.081 35.4144 109.72 37.0539 111.741 37.0539Z"
        fill="#EAEEF9"
      />
      <path
        d="M18.9965 13.4447C19.7943 13.4447 20.4411 12.7976 20.4411 11.9993C20.4411 11.201 19.7943 10.5538 18.9965 10.5538C18.1987 10.5538 17.5519 11.201 17.5519 11.9993C17.5519 12.7976 18.1987 13.4447 18.9965 13.4447Z"
        fill="#F1F3F9"
      />
      <path
        d="M107.503 21.5393C108.354 21.5393 109.044 20.849 109.044 19.9975C109.044 19.146 108.354 18.4557 107.503 18.4557C106.652 18.4557 105.963 19.146 105.963 19.9975C105.963 20.849 106.652 21.5393 107.503 21.5393Z"
        fill="#F1F3F9"
      />
      <path
        d="M60.8904 107.881C90.1446 107.881 113.86 84.1522 113.86 54.8811C113.86 25.61 90.1446 1.8811 60.8904 1.8811C31.6363 1.8811 7.92109 25.61 7.92109 54.8811C7.92109 84.1522 31.6363 107.881 60.8904 107.881Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M75.7219 85.2357L29.6867 103.834C28.9163 104.123 28.0495 103.738 27.7606 103.063L1.27589 37.2466C0.986966 36.4757 1.3722 35.6084 2.04635 35.3193L48.0815 16.7211C48.852 16.432 49.7188 16.8175 50.0077 17.492L76.4924 83.212C76.8776 84.0793 76.4924 84.9466 75.7219 85.2357Z"
        fill="white"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M55.4009 46.3048L16.8778 61.8194C16.4925 62.0121 16.1073 61.8194 15.9147 61.4339C15.7221 61.0485 15.9147 60.663 16.2999 60.4703L54.7268 44.9557C55.112 44.763 55.4972 44.9557 55.6898 45.3412C55.8825 45.7266 55.6898 46.1121 55.4009 46.3048Z"
        fill="#F1F3F9"
      />
      <path
        d="M56.8456 50.0629L18.4187 65.5774C18.0335 65.7702 17.6482 65.5774 17.4556 65.192C17.263 64.8065 17.4556 64.4211 17.8409 64.2283L56.2677 48.7138C56.6529 48.5211 57.0382 48.7138 57.2308 49.0992C57.4234 49.4847 57.2308 49.8702 56.8456 50.0629Z"
        fill="#F1F3F9"
      />
      <path
        d="M58.3865 53.7248L19.9596 69.3357C19.5744 69.5285 19.1891 69.3357 18.9965 68.9503C18.8039 68.5648 18.9965 68.1794 19.3818 67.9866L57.8086 52.4721C58.1938 52.2794 58.5791 52.4721 58.7717 52.8575C58.9643 53.1466 58.7717 53.6285 58.3865 53.7248Z"
        fill="#F1F3F9"
      />
      <path
        d="M35.9467 67.2157L21.5006 73.0939C21.1153 73.2866 20.7301 73.0939 20.5375 72.7084C20.3449 72.323 20.5375 71.9375 20.9227 71.7448L35.3689 65.8666C35.7541 65.6739 36.1394 65.8666 36.332 66.2521C36.4283 66.6375 36.332 67.023 35.9467 67.2157Z"
        fill="#F1F3F9"
      />
      <path
        d="M63.3945 63.2648L64.6465 66.4448C64.8391 67.023 64.6465 67.6975 64.0687 67.8902L26.027 83.3084C25.4492 83.5011 24.775 83.3084 24.5824 82.7302L23.3304 79.4539C23.1378 78.8757 23.3304 78.2011 23.9083 78.0084L61.9499 62.6866C62.5277 62.4939 63.1056 62.783 63.3945 63.2648Z"
        fill="#F1F3F9"
      />
      <path
        d="M66.38 70.5884L67.632 73.7684C67.8246 74.3466 67.632 75.0211 67.0542 75.2138L29.0126 90.632C28.4347 90.8247 27.7606 90.632 27.5679 90.0538L26.2196 86.7775C26.027 86.1993 26.2196 85.5247 26.7975 85.332L64.8391 70.0102C65.4169 69.8175 66.0911 70.1066 66.38 70.5884Z"
        fill="#F1F3F9"
      />
      <path
        d="M69.2692 77.9121L70.5213 81.0921C70.7139 81.6703 70.5213 82.3448 69.9434 82.5376L31.9018 97.9557C31.3239 98.1485 30.6498 97.9557 30.4572 97.3776L29.2052 94.1976C29.0125 93.6194 29.2052 92.9448 29.783 92.7521L67.8246 77.4303C68.4025 77.1412 69.0766 77.4303 69.2692 77.9121Z"
        fill="#F1F3F9"
      />
      <path
        d="M27.8569 76.4666L29.9757 81.7666L26.027 83.3084C25.4492 83.5011 24.775 83.3084 24.5824 82.7302L23.3304 79.4538C23.1378 78.8756 23.3304 78.2011 23.9083 78.0084L27.8569 76.4666Z"
        fill="#AAB2C5"
      />
      <path
        d="M26.8938 85.332L38.4507 80.6102L40.5695 85.9102L29.0126 90.632C28.4347 90.8248 27.7606 90.6321 27.568 90.0539L26.2196 86.7775C26.027 86.2957 26.316 85.6211 26.8938 85.332Z"
        fill="#D6DCE8"
      />
      <path
        d="M29.783 92.6557L31.4202 91.9812L33.539 97.2812L31.9018 97.9557C31.3239 98.1485 30.6498 97.9557 30.4572 97.3776L29.2052 94.1976C29.0125 93.6194 29.2052 92.9448 29.783 92.6557Z"
        fill="#D6DCE8"
      />
      <path
        d="M30.3609 43.0285C30.2646 37.7285 25.9307 33.3921 20.5375 33.3921H20.4411C15.0479 33.3921 10.7141 37.8248 10.7141 43.2212C10.8104 48.6175 15.2405 52.9539 20.6338 52.9539C23.6193 52.9539 26.2196 51.6048 28.0495 49.5812C29.5904 47.8466 30.4572 45.6303 30.4572 43.1248C30.3609 43.1248 30.3609 43.1248 30.3609 43.0285ZM20.6338 49.8703C16.9741 49.8703 13.9885 46.883 13.9885 43.2212C13.9885 39.5594 16.9741 36.5721 20.6338 36.5721H20.7301C24.3898 36.5721 27.279 39.463 27.3753 43.0285C27.3753 43.1248 27.3753 43.1248 27.3753 43.2212C27.3753 44.8594 26.7975 46.4012 25.7381 47.5575C24.3898 49.003 22.6562 49.8703 20.6338 49.8703Z"
        fill="#F1F3F9"
      />
      <path
        d="M30.3609 43.0285H27.1827C27.1827 39.463 24.1972 36.5721 20.6338 36.5721H20.5375V33.3921H20.6338C25.9307 33.3921 30.2646 37.7285 30.3609 43.0285Z"
        fill="#D6DCE8"
      />
      <path
        d="M94.5019 100.172L47.9852 82.8266C47.2148 82.5375 46.8295 81.6702 47.1184 80.8993L71.7733 14.5048C72.0622 13.7339 72.929 13.3484 73.6994 13.6375L120.216 30.8866C120.987 31.1757 121.372 32.0429 121.083 32.8139L96.4281 99.3048C96.1392 100.076 95.2724 100.461 94.5019 100.172Z"
        fill="white"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.4"
        d="M88.3383 43.5102L84.5823 42.0648L87.6641 33.4884L91.4201 34.8375L88.3383 43.5102Z"
        fill="#E6EDF5"
      />
      <path
        d="M93.4425 45.3411L89.5902 43.8956L94.3093 30.7902L98.0653 32.2356L93.4425 45.3411Z"
        fill="#AAB2C5"
      />
      <path
        d="M103.555 48.9065L99.7989 47.5574L105.963 30.3083L109.815 31.6574L103.555 48.9065Z"
        fill="#D6DCE8"
      />
      <path
        opacity="0.4"
        d="M108.37 50.6411L104.614 49.292L106.252 44.5702L110.104 46.0156L108.37 50.6411Z"
        fill="#E6EDF5"
      />
      <path
        d="M105.674 57.6757L66.7653 43.2211C66.3801 43.1247 66.1875 42.6429 66.2838 42.2575C66.3801 41.872 66.8616 41.6793 67.2468 41.7757L106.155 56.2302C106.54 56.3266 106.733 56.8084 106.637 57.1938C106.444 57.5793 106.059 57.772 105.674 57.6757Z"
        fill="#F1F3F9"
      />
      <path
        d="M104.229 61.4338L65.3207 46.9793C64.9354 46.8829 64.7428 46.4011 64.8391 46.0157C64.9354 45.6302 65.417 45.4375 65.8022 45.5338L104.711 59.9884C105.096 60.0847 105.288 60.5666 105.192 60.952C105.096 61.3375 104.614 61.6266 104.229 61.4338Z"
        fill="#F1F3F9"
      />
      <path
        d="M102.881 65.192L63.9723 50.7375C63.5871 50.6411 63.3945 50.1593 63.4908 49.7738C63.5871 49.3884 64.0686 49.1956 64.4539 49.292L103.362 63.7466C103.747 63.8429 103.94 64.3247 103.844 64.7102C103.651 65.192 103.266 65.3847 102.881 65.192Z"
        fill="#F1F3F9"
      />
      <path
        d="M77.2629 59.9884L62.6241 54.5921C62.2388 54.4957 62.0462 54.0139 62.1425 53.6284C62.2388 53.243 62.7204 53.0503 63.1056 53.1466L77.7444 58.543C78.1296 58.6394 78.3223 59.1212 78.2259 59.5066C78.0333 59.8921 77.6481 60.0848 77.2629 59.9884Z"
        fill="#DDDDDD"
      />
      <path
        d="M100.184 75.5993L99.0284 78.8757C98.8358 79.4539 98.1617 79.7429 97.5838 79.5502L89.3013 76.4666L59.0607 65.192C58.4828 64.9993 58.1939 64.3248 58.3865 63.7466L59.5422 60.4702C59.7348 59.892 60.409 59.6029 60.9868 59.7957L91.0349 70.9738L99.4137 74.0575C100.088 74.4429 100.377 75.0211 100.184 75.5993Z"
        fill="#F1F3F9"
      />
      <path
        d="M97.3912 83.0193L96.2355 86.2957C96.0429 86.8738 95.3688 87.1629 94.7909 86.9702L91.998 85.9102L56.3641 72.7084C55.7862 72.5157 55.4973 71.8411 55.6899 71.2629L56.8456 67.9866C57.0382 67.4084 57.7124 67.1193 58.2902 67.312L94.0205 80.6102L96.7171 81.6702C97.2949 81.8629 97.5839 82.4411 97.3912 83.0193Z"
        fill="#F1F3F9"
      />
      <path
        d="M94.6945 90.4393L93.5388 93.7157C93.3462 94.2939 92.6721 94.583 92.0942 94.3903L77.8406 89.0903L53.6674 80.1284C53.0895 79.9357 52.8006 79.2612 52.9932 78.683L54.1489 75.4066C54.3415 74.8284 55.0157 74.5393 55.5935 74.7321L80.3446 83.8866L94.0204 88.9939C94.5982 89.1866 94.8871 89.8612 94.6945 90.4393Z"
        fill="#F1F3F9"
      />
      <path
        d="M55.5935 74.732L80.3446 83.8865L77.8406 88.9938L53.6674 80.032C53.0895 79.8393 52.8006 79.1647 52.9932 78.5865L54.1489 75.3102C54.3415 74.8284 55.0157 74.5393 55.5935 74.732Z"
        fill="#D6DCE8"
      />
      <path
        d="M58.3865 63.8429L59.5422 60.5665C59.7348 59.9883 60.409 59.6993 60.9868 59.892L91.0349 71.0702L89.1087 76.3702L59.0607 65.192C58.4828 64.9993 58.1939 64.4211 58.3865 63.8429Z"
        fill="#D6DCE8"
      />
      <path
        d="M94.0205 80.6102L91.998 85.9102L56.3641 72.7084C55.7862 72.5157 55.4973 71.8411 55.6899 71.2629L56.8456 67.9866C57.0382 67.4084 57.7124 67.1193 58.2902 67.312L94.0205 80.6102Z"
        fill="#D6DCE8"
      />
      <path
        d="M68.2099 62.5902L66.2838 67.8902L59.0607 65.192C58.4828 64.9993 58.1939 64.3248 58.3865 63.7466L59.5422 60.4702C59.7348 59.892 60.409 59.6029 60.9868 59.7957L68.2099 62.5902Z"
        fill="#DDDDDD"
      />
      <path
        d="M58.2902 67.3121L65.5133 70.0103L63.5872 75.3103L56.3641 72.6121C55.7862 72.4193 55.4973 71.7448 55.6899 71.1666L56.8456 67.8903C57.1345 67.4084 57.7124 67.1194 58.2902 67.3121Z"
        fill="#DDDDDD"
      />
      <path
        d="M55.5935 74.732L62.8166 77.4302L60.8905 82.7302L53.6674 80.032C53.0895 79.8393 52.8006 79.1647 52.9932 78.5865L54.1489 75.3102C54.3415 74.8284 55.0157 74.5393 55.5935 74.732Z"
        fill="#DDDDDD"
      />
      <path
        d="M86.2195 84.0793H36.6209C35.8504 84.0793 35.1763 83.4048 35.1763 82.6339V11.7102C35.1763 10.9393 35.8504 10.2648 36.6209 10.2648H86.2195C86.9899 10.2648 87.6641 10.9393 87.6641 11.7102V82.5375C87.7604 83.4048 87.0862 84.0793 86.2195 84.0793Z"
        fill="white"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M81.8856 37.5348H40.4732C40.088 37.5348 39.7028 37.2459 39.7028 36.7644C39.7028 36.3791 39.9917 35.9939 40.4732 35.9939H81.8856C82.2709 35.9939 82.6561 36.2828 82.6561 36.7644C82.6561 37.1496 82.2709 37.5348 81.8856 37.5348Z"
        fill="#F1F3F9"
      />
      <path
        d="M81.8856 41.4857H40.4732C40.088 41.4857 39.7028 41.1968 39.7028 40.7153C39.7028 40.3301 39.9917 39.9448 40.4732 39.9448H81.8856C82.2709 39.9448 82.6561 40.2337 82.6561 40.7153C82.6561 41.1968 82.2709 41.4857 81.8856 41.4857Z"
        fill="#F1F3F9"
      />
      <path
        d="M81.8856 45.5329H40.4732C40.088 45.5329 39.7028 45.2439 39.7028 44.7624C39.7028 44.3772 39.9917 43.9919 40.4732 43.9919H81.8856C82.2709 43.9919 82.6561 44.2809 82.6561 44.7624C82.6561 45.2439 82.2709 45.5329 81.8856 45.5329Z"
        fill="#F1F3F9"
      />
      <path
        d="M81.7892 60.662H67.8246C67.4394 60.662 67.0541 60.3731 67.0541 59.8916C67.0541 59.5063 67.3431 59.1211 67.8246 59.1211H81.7892C82.1745 59.1211 82.5597 59.41 82.5597 59.8916C82.4634 60.2768 82.1745 60.662 81.7892 60.662Z"
        fill="#F1F3F9"
      />
      <path
        d="M59.2532 64.8065C59.1569 59.5065 54.823 55.1702 49.4298 55.1702H49.3335C43.9402 55.1702 39.6064 59.6029 39.6064 64.9993C39.6064 70.3956 44.0365 74.8283 49.4298 74.8283C52.4153 74.8283 55.0156 73.4793 56.8455 71.4556C58.3864 69.7211 59.2532 67.5047 59.2532 64.9993C59.2532 64.9029 59.2532 64.8065 59.2532 64.8065ZM49.4298 71.552C45.7701 71.552 42.7845 68.5647 42.7845 64.9029C42.7845 61.2411 45.7701 58.2538 49.4298 58.2538H49.5261C53.1858 58.2538 56.075 61.1447 56.1713 64.7102C56.1713 64.8065 56.1713 64.8065 56.1713 64.9029C56.1713 66.5411 55.5935 68.0829 54.5341 69.2393C53.1858 70.6847 51.4523 71.552 49.4298 71.552Z"
        fill="#F1F3F9"
      />
      <path
        d="M59.2533 64.8065H56.0751C55.9788 61.2411 53.0896 58.3502 49.4299 58.3502H49.3336V55.1702H49.4299C54.8231 55.1702 59.157 59.4102 59.2533 64.8065Z"
        fill="#D6DCE8"
      />
      <path
        d="M59.2533 64.903C59.2533 67.4085 58.3865 69.6248 56.8456 71.3594L54.4379 69.3357C55.4973 68.1794 56.0751 66.6376 56.0751 64.9994C56.0751 64.903 56.0751 64.903 56.0751 64.8066H59.2533V64.903Z"
        fill="#AAB2C5"
      />
      <path
        d="M64.4539 60.952C65.0389 60.952 65.5132 60.4775 65.5132 59.892C65.5132 59.3066 65.0389 58.832 64.4539 58.832C63.8688 58.832 63.3945 59.3066 63.3945 59.892C63.3945 60.4775 63.8688 60.952 64.4539 60.952Z"
        fill="#F1F3F9"
      />
      <path
        d="M81.7892 65.0948H67.8246C67.4394 65.0948 67.0541 64.8058 67.0541 64.3243C67.0541 63.9391 67.3431 63.5538 67.8246 63.5538H81.7892C82.1745 63.5538 82.5597 63.8428 82.5597 64.3243C82.4634 64.7095 82.1745 65.0948 81.7892 65.0948Z"
        fill="#F1F3F9"
      />
      <path
        d="M64.4539 65.3848C65.0389 65.3848 65.5132 64.9102 65.5132 64.3248C65.5132 63.7393 65.0389 63.2648 64.4539 63.2648C63.8688 63.2648 63.3945 63.7393 63.3945 64.3248C63.3945 64.9102 63.8688 65.3848 64.4539 65.3848Z"
        fill="#D6DCE8"
      />
      <path
        d="M81.7892 69.5275H67.8246C67.4394 69.5275 67.0541 69.2386 67.0541 68.757C67.0541 68.3718 67.3431 67.9866 67.8246 67.9866H81.7892C82.1745 67.9866 82.5597 68.2755 82.5597 68.757C82.4634 69.1423 82.1745 69.5275 81.7892 69.5275Z"
        fill="#F1F3F9"
      />
      <path
        d="M64.4539 69.8175C65.0389 69.8175 65.5132 69.3429 65.5132 68.7575C65.5132 68.1721 65.0389 67.6975 64.4539 67.6975C63.8688 67.6975 63.3945 68.1721 63.3945 68.7575C63.3945 69.3429 63.8688 69.8175 64.4539 69.8175Z"
        fill="#AAB2C5"
      />
      <path
        d="M39.9916 32.2357H82.5597V14.9866L75.8181 18.3593L72.2547 20.8648L65.8984 20.7684L60.3125 23.6593L55.4971 24.3338L53.7636 26.2611L48.7556 29.4411L43.8439 30.3084L39.9916 32.2357Z"
        fill="#D6DCE8"
      />
      <path
        d="M39.9916 32.2357H82.5597V19.1302L75.8181 21.6357L72.2547 23.563L65.8984 23.4666L60.3125 25.683L55.4971 26.2612L53.7636 27.6102L48.7556 30.1157L43.8439 30.7902L39.9916 32.2357Z"
        fill="#F1F3F9"
      />
      <path
        d="M39.9916 32.2357H82.5597V24.8157L75.8181 26.2611L72.2547 27.3211H65.8984L60.3125 28.4775L55.4971 28.8629L53.7636 29.6339L48.7556 30.9829L43.8439 31.3684L39.9916 32.2357Z"
        fill="#AAB2C5"
      />
    </svg>
  );
};

export default EmptyFilteredState;
