import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { Avatar, FormattedDate, TextArea } from "../../../common";
import React, { useState } from "react";
import UserContext from "../../../../state/UserContext";

interface IndividualUserTaskCommentProps {
  commentId: string;
  comment: string;
  commentTimestamp: Date;
  authorId: number | undefined;
  authorProfilePicUrl: string | undefined;
  authorFullName: string | undefined;
  authorInitials: string | undefined;
  allowDelete: boolean;
  disableDeleteButton: boolean;
  onDeleteClick(commentId: string | number): void;
  allowEdit: boolean;
  disableEditButton: boolean;
  onEditClick(
    commentId: string,
    comment: string,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
}

function IndividualUserTaskComment({
  commentId,
  comment,
  commentTimestamp,
  authorId,
  authorProfilePicUrl,
  authorFullName,
  authorInitials,
  allowDelete,
  disableDeleteButton,
  onDeleteClick,
  allowEdit,
  disableEditButton,
  onEditClick,
}: IndividualUserTaskCommentProps) {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  const isCommentFromLoggedInUser = userContext.user.id === authorId;

  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [editedComment, setEditedComment] = useState<string>(comment);
  const [confirmDeletePromptVisible, setConfirmDeletePromptVisible] =
    useState<boolean>(false);

  const toggleEditMode = () => {
    setInEditMode(!inEditMode);
    setEditedComment(comment);
  };

  const handleSubmitEditClick = () => {
    if (editedComment.trim().length > 0) {
      onEditClick(
        commentId,
        editedComment,
        () => {
          setInEditMode(false);
        },
        () => {}
      );
    }
  };

  const showConfirmDeletePrompt = () => {
    setConfirmDeletePromptVisible(true);
  };

  const handleCancelDeleteClick = () => {
    setConfirmDeletePromptVisible(false);
  };

  const handleConfirmedDeleteClick = () => {
    onDeleteClick(commentId);
    setConfirmDeletePromptVisible(false);
  };

  return (
    <>
      {isCommentFromLoggedInUser && (
        <div className="my-2" key={`comment_${commentId}`}>
          <div className="grid grid-cols-12">
            <div className="col-span-2 md:col-span-1">
              {authorFullName && authorInitials && (
                <div className="inline-block mr-2 pt-1">
                  <Avatar
                    imageUrl={authorProfilePicUrl}
                    userFullName={authorFullName}
                    userInitials={authorInitials}
                    size={8}
                    initialsTextClassName="text-xs"
                  />
                </div>
              )}
            </div>
            {/* Non-Edit Mode */}
            {!inEditMode && (
              <div className="col-span-9">
                <div
                  style={{ whiteSpace: "pre-wrap" }}
                  className="user-task-comment-owner rounded-md inline-block text-sm p-2 flex-grow whitespace-normal break-words min-w-[1%]"
                >
                  {comment}
                </div>
                <div className="text-xs pt-0.5 text-gray-400">
                  <div className="flex flex-row mt-[2px]">
                    <FormattedDate
                      date={commentTimestamp}
                      displayMode="FROM-NOW"
                      convertFromUtc
                    />
                    {allowEdit && !confirmDeletePromptVisible && (
                      <>
                        <p className="pl-1">|</p>
                        <div className="print:hidden">
                          <button
                            title={t("Common.Edit")}
                            className="ml-1 disabled:text-gray-400 disabled:cursor-not-allowed hover:underline"
                            disabled={disableEditButton}
                            onClick={toggleEditMode}
                          >
                            {t("Common.Edit")}
                          </button>
                        </div>
                      </>
                    )}
                    {allowDelete && (
                      <>
                        <p className="pl-1">|</p>
                        <div className="print:hidden">
                          {!confirmDeletePromptVisible && (
                            <button
                              title={t("Common.Delete")}
                              className="ml-1 disabled:text-gray-400 disabled:cursor-not-allowed"
                              disabled={disableDeleteButton}
                              onClick={showConfirmDeletePrompt}
                            >
                              <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                            </button>
                          )}
                          {confirmDeletePromptVisible && (
                            <div className="flex flex-row items-center">
                              <p className="ml-1 text-gray-400 font-semibold">
                                Are you sure you want to delete this comment?
                              </p>
                              <button
                                className="ml-4 underline"
                                onClick={handleConfirmedDeleteClick}
                              >
                                {t("Common.Yes")}
                              </button>
                              <button
                                className="ml-4 underline"
                                onClick={handleCancelDeleteClick}
                              >
                                {t("Common.No")}
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* Edit Mode */}
            {inEditMode && (
              <div className="col-span-9">
                <div>
                  <TextArea
                    value={editedComment}
                    onChange={setEditedComment}
                    className="rounded-md !border text-gray-700 py-0 shadow-sm w-full !ring-transparent"
                  />
                  <div className="text-xs pt-0.5 text-gray-400">
                    <div className="flex flex-row mt-[2px]">
                      <FormattedDate
                        date={commentTimestamp}
                        displayMode="FROM-NOW"
                        convertFromUtc
                      />
                      <>
                        <p className="pl-1">|</p>
                        <div className="print:hidden">
                          <button
                            title={t("Common.Save")}
                            className="ml-1 disabled:text-gray-400 disabled:cursor-not-allowed hover:underline"
                            onClick={handleSubmitEditClick}
                          >
                            {t("Common.Save")}
                          </button>
                        </div>
                      </>
                      <>
                        <p className="pl-1">|</p>
                        <div className="print:hidden">
                          <button
                            title={t("Common.Cancel")}
                            className="ml-1 disabled:text-gray-400 disabled:cursor-not-allowed hover:underline"
                            disabled={disableEditButton}
                            onClick={toggleEditMode}
                          >
                            {t("Common.Cancel")}
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-span-1 md:col-span-2">&nbsp;</div>
          </div>
        </div>
      )}
      {!isCommentFromLoggedInUser && (
        <div className="my-2" key={`comment_${commentId}`}>
          <div className="grid grid-cols-12">
            <div className="col-span-1 md:col-span-2">&nbsp;</div>
            <div className="col-span-9">
              <div className="float-right">
                <div className="user-task-comment-non-owner rounded-md inline-block text-sm p-2 flex-grow whitespace-normal break-words min-w-[1%]">
                  {comment}
                </div>
                <div className="text-xs pt-0.5 text-gray-400">
                  <div className="flex flex-row justify-end mt-[2px]">
                    <FormattedDate
                      date={commentTimestamp}
                      displayMode="FROM-NOW"
                      convertFromUtc
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 text-right">
              {authorFullName && authorInitials && (
                <div className="inline-block mr-2 pt-1">
                  <Avatar
                    imageUrl={authorProfilePicUrl}
                    userFullName={authorFullName}
                    userInitials={authorInitials}
                    size={8}
                    initialsTextClassName="text-xs"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IndividualUserTaskComment;
