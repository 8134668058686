import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

interface JourneyFormPagerProps {
  enableNextButton: boolean;
  showPrevBtn: boolean;
  showSubmitBtn: boolean;
  disableSubmitBtn: boolean;
  showSubmitSpinner: boolean;
  onNextPageClick(): void;
  onPreviousPageClick(): void;
  onSubmit(): void;
  scrollToWidgetTop(): void;
}

function JourneyFormPager({
  enableNextButton,
  showPrevBtn,
  showSubmitBtn,
  disableSubmitBtn,
  showSubmitSpinner,
  onNextPageClick,
  onPreviousPageClick,
  onSubmit,
  scrollToWidgetTop,
}: JourneyFormPagerProps) {
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();

  const trackSubmission = useTrackEvent(appInsights, "JourneySubmission", {});

  const handlePreviousClick = () => {
    onPreviousPageClick();
    scrollToWidgetTop();
  };

  const handleNextPageClick = () => {
    onNextPageClick();
    scrollToWidgetTop();
  };

  const handleSubmitClick = () => {
    onSubmit();
    trackSubmission({});
    scrollToWidgetTop();
  };

  return (
    <div className="flex flex-row-reverse justify-between">
      <div className="flex flex-col justify-end">
        {!showSubmitBtn && (
          <button
            className="journey-btn-primary"
            onClick={handleNextPageClick}
            disabled={!enableNextButton}
          >
            <span>Next</span>
          </button>
        )}
        {showSubmitBtn && (
          <button
            className="journey-btn-primary"
            onClick={handleSubmitClick}
            disabled={disableSubmitBtn || showSubmitSpinner}
          >
            {t("Journey.Button.Submit")}
            {showSubmitSpinner && (
              <FontAwesomeIcon
                icon={faCircleNotch}
                size="xs"
                className="ml-1 animate-spin"
              />
            )}
          </button>
        )}
      </div>
      {showPrevBtn && (
        <div className="flex flex-col justify-end">
          {" "}
          <button
            className="text-white/70 hover:text-white "
            onClick={handlePreviousClick}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span> Previous</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default JourneyFormPager;
