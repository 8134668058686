import cx from "classnames";
import { t } from "i18next";
import parse from "html-react-parser";
import KeyValuePair from "../../../../types/generic/KeyValuePair";

interface BehaviourSubtextProps {
  /** The id and title (translation key identifier) of the behaviour */
  behaviour: KeyValuePair<number, string>;
  /** Optional subtext content to display underneath the behaviour to the user. Context for the behaviour */
  subtextContent?: string | null;
  /** Class name for the more info label*/
  classNames?: string | null;
}

function BehaviourSubtext({
  behaviour,
  subtextContent,
  classNames = "",
}: BehaviourSubtextProps) {
  return (
    <>
      {subtextContent && (
        <div className="behaviour-subtext">
          <div className={cx(classNames, "py-0.5 text-sm select-none")}>
            {parse(t(subtextContent))}
          </div>
        </div>
      )}
    </>
  );
}

export default BehaviourSubtext;
