import { useState } from "react";
import ManagerDashboardPersonDto from "../../../types/dtos/dashboards/ManagerDashboardPersonDto";
import PeopleContent from "./PeopleContent";
import PeopleHeader from "./PeopleHeader";
import { DashboardSummaryTabDto } from "../../../types/dtos/dashboards";
import PeopleHeaderLoadingCard from "./PeopleHeaderLoadingCard";
import { AppraisalLevelClientTaskTypeDto } from "../../../types/dtos/users/AppraisalLevelClientTaskTypeDto";

interface PeopleCardProps {
  user: ManagerDashboardPersonDto | null;
  appraisalLevelTaskTypes: AppraisalLevelClientTaskTypeDto[];
  isLegacyClient: boolean;
  /** A function to call when the manager has clicked an "Update [Section]" button */
  onTriggerSectionUpdate(
    userId: number,
    summaryTab: DashboardSummaryTabDto,
    incompleteJourneyReference: string | null
  ): void;
  /**If this is undefined then the user doesn't have any history to show */
  onOpenUserHistory?(userId: number): void | undefined;
  onLiveJourneyClick?(userId: number): void | undefined;
  onUserTabChange?(
    userId: number,
    clientFormId: number,
    onSuccess: () => void,
    onError: () => void
  ): void | undefined;
  onUpdateUser(user: ManagerDashboardPersonDto): void | undefined;
}

function PeopleCard({
  user,
  appraisalLevelTaskTypes,
  isLegacyClient,
  onTriggerSectionUpdate,
  onOpenUserHistory = undefined,
  onLiveJourneyClick = undefined,
  onUserTabChange = undefined,
  onUpdateUser,
}: PeopleCardProps) {
  // State
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <div
      className="p-5 !pb-2 bg-white rounded-md mt-3 border border-gray-200"
      onClick={() => setIsExpanded(true)}
      id="people"
    >
      {!user && <PeopleHeaderLoadingCard />}
      {user && (
        <>
          <PeopleHeader
            appraisalLevelTaskTypes={appraisalLevelTaskTypes}
            managedUser={user}
            isLegacyClient={isLegacyClient}
            onOpenUserHistory={onOpenUserHistory}
            onLiveJourneyClick={onLiveJourneyClick}
            onUpdateUser={onUpdateUser}
          />
          <PeopleContent
            userIsExpanded={isExpanded}
            tabs={user.summaries}
            onTriggerSectionUpdate={onTriggerSectionUpdate}
            managedUser={user}
            onUserTabChange={onUserTabChange}
          />
        </>
      )}
    </div>
  );
}

export default PeopleCard;
