import { useContext, useEffect } from "react";
import UserContext from "../../state/UserContext";

/** This loads the Accessibe script for the accessibility plugin */
export const AccessibePlugin = () => {
  const userContext = useContext(UserContext);
  const configSetting =
    process.env.REACT_APP_ACCESSIBE_ENABLED?.toLowerCase()?.trim() === "true";
  const scriptTagIdentifier = "accessibe-script";
  // This should become a module/config, or something else that can be enabled per Client
  const enableAccessibeForClientIds = [
    140, // BaxterStorey
    135, // Demo
    104, // SES
    137, // Page Demo
    60, // Page
    31, // Lexington
    130, // Rosas
    136, // RBH
    104, // SES
    141, // HelloKorero
  ];

  useEffect(() => {
    conditionallyAddScriptToBody();
  }, [userContext?.user?.client?.id]);

  const conditionallyAddScriptToBody = () => {
    if (!configSetting || !userContext?.user?.client?.id) return;
    const enabledForClient =
      enableAccessibeForClientIds.indexOf(userContext.user.client.id) >= 0;

    if (!enabledForClient) return;

    // Don't add the script for a second time
    if (document.getElementById(scriptTagIdentifier)) {
      return;
    }

    const script = document.createElement("script");
    script.id = scriptTagIdentifier;
    script.src = "https://acsbapp.com/apps/app/dist/js/app.js";
    script.async = true;
    script.onload = () => {
      // @ts-ignore
      window.acsbJS.init({
        statementLink: "",
        footerHtml: "",
        hideMobile: false,
        hideTrigger: false,
        disableBgProcess: false,
        language: "en",
        position: "right",
        leadColor: "#146FF8",
        triggerColor: "#146FF8",
        triggerRadius: "50%",
        triggerPositionX: "right",
        triggerPositionY: "bottom",
        triggerIcon: "people",
        triggerSize: "bottom",
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: "small",
          triggerPositionX: "right",
          triggerPositionY: "bottom",
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: "20",
        },
      });
    };
    document.body.appendChild(script);
  };

  return null;
};
export default AccessibePlugin;
