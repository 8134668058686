import { useState } from "react";
import {
  NineBoxGridEditorAxisSettings,
  NineBoxGridEditorDataItemAxisScore,
  NineBoxGridEditorDataItemAxisScoreValues,
  NineBoxGridScoreOverrideAxis,
} from "../../../../types/analytics/charts/NineBoxGridEditorData";
import { GenericDropDownList, NumberInput } from "../../../common";
import { mathsHelper } from "../../../../helpers";
import nineBoxGridHelper from "../../../../helpers/nineBoxGridHelper";

interface NineBoxGridScoreOverrideInputProps extends AxisScoreInputProps {
  /** X or Y */
  editAxis: NineBoxGridScoreOverrideAxis;
}

interface AxisScoreInputProps {
  /** The object with the details we need to render the correct type of input for the axis being edited */
  axisSettings: NineBoxGridEditorAxisSettings;

  /** The score object being edited */
  originalScoreObject: NineBoxGridEditorDataItemAxisScore;

  /** The function to call when the score changes */
  onScoreChange(scoreValues: NineBoxGridEditorDataItemAxisScoreValues): void;
}

const XAxisScoreInput = ({
  originalScoreObject,
  axisSettings,
  onScoreChange,
}: AxisScoreInputProps) => {
  // The x-axis is generally for "Performance", which is either a percentage
  // or is a percentage that has been converted to a scale (e.g. 1-5) for display,
  // so render an input which allows the user to enter a number between 1 and 100
  // if there's no scale conversion, otherwise use the bounds of the scale

  const rangeMin = axisSettings.xAxis.hasValidScaleConversionProperties
    ? axisSettings.xAxis.scaleConversionMin!
    : 0; // 0 to 100 for percentage if no range conversion
  const rangeMax = axisSettings.xAxis.hasValidScaleConversionProperties
    ? axisSettings.xAxis.scaleConversionMax!
    : 100; // 0 to 100 for percentage if no range conversion

  let originalScoreValue: number = 0;
  if (axisSettings.xAxis.hasValidScaleConversionProperties) {
    // There is a scale conversion, so the original score value is the range converted value
    originalScoreValue = nineBoxGridHelper.getScaleValueForXAxis(
      originalScoreObject.scorePercentage,
      axisSettings
    );
  } else {
    // No scale conversion, so the original score value is the percentage
    // but round it as we don't want to show decimal places in the number picker
    originalScoreValue = Math.round(originalScoreObject.scorePercentage);
  }

  const [displayValue, setDisplayValue] = useState<number>(originalScoreValue);

  const handleValueChange = (newValue: number) => {
    setDisplayValue(newValue);

    let newScoreDecimalValue = newValue;
    // Convert the scale value to a percentage (if needed)
    if (axisSettings.xAxis.hasValidScaleConversionProperties) {
      newScoreDecimalValue = mathsHelper.convertScaleToPercentage(
        newValue,
        rangeMin,
        rangeMax
      );
    }

    // Pass the value change up out into the parent, and the display value
    onScoreChange({
      displayValue:
        newValue.toString() +
        (axisSettings.xAxis.hasValidScaleConversionProperties ? "" : "%"),
      scorePercentage: newScoreDecimalValue,
      tooltipValue: mathsHelper.roundForDisplay(newScoreDecimalValue, 2) + "%",
    });
  };

  return (
    <NumberInput
      value={displayValue}
      onChange={handleValueChange}
      minimum={rangeMin}
      maximum={rangeMax}
      step={1}
      className="mt-2 w-20 text-[14px]"
      decimalPlaces={0}
    />
  );
};

const YAxisScoreInput = ({
  originalScoreObject,
  axisSettings,
  onScoreChange,
}: AxisScoreInputProps) => {
  // The y-axis is generally for "Potential", which is generally either "Low", "Medium", or "High",
  // but could be more, say if the 9BG is actually a 16 box grid (which is technically possible).
  // In this case, we need to render a dropdown list with the possible options.
  const axisOptionItems = axisSettings.yAxis.valueSettings.options.map(
    (option, ix) => {
      return {
        indexString: ix.toString(),
        decimalValue: option.saveAsDecimalValue,
        displayText: option.displayText,
      };
    }
  );

  const dropDownItems = axisOptionItems.map((option) => {
    return {
      key: option.indexString,
      value: option.displayText,
    };
  });

  const selectedItemIndex = axisOptionItems.findIndex(
    (option) => option.displayText === originalScoreObject.displayValue
  );

  const [dropDownSelectedIndexString, setDropDownSelectedIndexString] =
    useState<string>(selectedItemIndex.toString());

  const handleDropDownChange = (selectedValue: string | null) => {
    if (selectedValue) {
      const selectedOption = axisOptionItems.find(
        (option) => option.indexString === selectedValue
      );
      if (selectedOption) {
        setDropDownSelectedIndexString(selectedOption.indexString);
        // Pass the value change up out into the parent, and the display value
        onScoreChange({
          displayValue: selectedOption.displayText,
          scorePercentage: selectedOption.decimalValue,
          tooltipValue:
            mathsHelper.roundForDisplay(selectedOption.decimalValue, 2) + "%",
        });
      }
    } else {
      // In theory this isn't possible, as `includeSelectOption` is false
      console.error("No value selected for Y axis dropdown");
    }
  };

  return (
    <GenericDropDownList
      items={dropDownItems}
      onChange={handleDropDownChange}
      currentValue={dropDownSelectedIndexString}
      includeSelectOption={false}
      applyBorder
      className="bg-gray-100 py-1 w-64 border-gray-400 mt-1"
    />
  );
};

const NineBoxGridScoreOverrideInput = ({
  editAxis,
  axisSettings,
  originalScoreObject,
  onScoreChange,
}: NineBoxGridScoreOverrideInputProps) => {
  let scoreComponent: JSX.Element | null = null;

  if (editAxis === "X") {
    scoreComponent = (
      <XAxisScoreInput
        axisSettings={axisSettings}
        originalScoreObject={originalScoreObject}
        onScoreChange={onScoreChange}
      />
    );
  }

  if (editAxis === "Y") {
    scoreComponent = (
      <YAxisScoreInput
        axisSettings={axisSettings}
        originalScoreObject={originalScoreObject}
        onScoreChange={onScoreChange}
      />
    );
  }

  if (scoreComponent === null) return null;

  return (
    <div className="pt-2 pl-2">
      <div className="flex flex-row">
        <div className="w-36">Current score:</div>
        <div className="flex-grow pl-1">{originalScoreObject.displayValue}</div>
      </div>
      <div className="flex flex-row">
        <div className="w-36 content-center">New score:</div>
        <div className="flex-grow">{scoreComponent}</div>
      </div>
    </div>
  );
};

export default NineBoxGridScoreOverrideInput;
