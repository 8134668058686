import { useTranslation } from "react-i18next";
import {
  DateInput,
  GenericDropDownList,
  Label,
  ModalPopup,
} from "../../common";
import TaskManagementReviewDateType from "../../../types/admin/TaskManagementReviewDateType";
import { KeyValuePair } from "../../../types/generic";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

interface EditCriteriaPopupModalProps {
  isModalOpen: boolean;
  clientSentJourneyId?: number;
  taskMinimumDate?: Date | null;
  taskDateType?: TaskManagementReviewDateType | null;
  onUpdate(clientSentJourneyId: number, newMinimumDate: Date | undefined | null, newDateType: TaskManagementReviewDateType | undefined | null): void;
  onModalClose(): void;
}

function EditCriteriaPopupModal({
  isModalOpen,
  clientSentJourneyId,
  taskMinimumDate,
  taskDateType,
  onUpdate,
  onModalClose
}: EditCriteriaPopupModalProps) {
  const { t } = useTranslation();

  let taskDateTypes = [{
    key: "DATE-CREATED",
    value: t("Common.DateCreated"),
  },
  {
    key: "DUE-DATE",
    value: t("Common.DueDate")
  }] as KeyValuePair<TaskManagementReviewDateType, string>[];

  const [dateFrom, setDateFrom] = useState<Date | undefined | null>(taskMinimumDate);
  const [includeBy, setIncludeBy] = useState<TaskManagementReviewDateType | undefined | null>(taskDateType);

  useEffect(() => {
    const utcDate = taskMinimumDate != null ? dayjs(taskMinimumDate).toDate() : null;
    setDateFrom(utcDate);
  }, [taskMinimumDate]);

  useEffect(() => {
    setIncludeBy(taskDateType != null ? taskDateType : "DATE-CREATED");
  }, [taskDateType]);

  const onDropdownChange = (newValue: TaskManagementReviewDateType) => {
    setIncludeBy(newValue);
  };

  const onUpdateClick = () => {
    if (clientSentJourneyId) {
      onUpdate(clientSentJourneyId, dateFrom, includeBy);
    }
  };

  return (
    <ModalPopup
      isOpen={isModalOpen}
      onOpenChange={onModalClose}
      onPrimaryButtonClick={onUpdateClick}
      primaryButtonText={t("Common.Update")}
      onSecondaryButtonClick={onModalClose}
      secondaryButtonText={t("Common.Close")}
      title="Edit Criteria"
      showCloseIcon={true}
    >
      <div className="bg-gray-100 py-1 px-4 mb-2 text-gray-700 text-sm rounded-sm border-l-2 border-l-gray-800">
        <div>
          <div className="fa-layers fa-fw mr-0.5">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          {t("Pages.Admin.SendingHistory.IfLeftBlank")}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="mt-2 col-span-1 md:col-span-3">
          <Label text={t("Common.Date") + ":"} />
          <DateInput
            value={dateFrom}
            onChange={setDateFrom}
            allowDatesInPast={true}
            className="rounded-md w-full"
            isClearable={true}
            placeholder={t("Common.Optional")}
          />
        </div>
        <div className="mt-2 col-span-1 md:col-span-3">
          <Label text={t("Common.IncludeBy") + ":"} />
          <GenericDropDownList
            currentValue={includeBy ? includeBy : null}
            items={taskDateTypes}
            onChange={onDropdownChange}
            className="block w-full"
            isReadOnly={false}
            includeSelectOption={false}
            applyBorder={true}
          />
        </div>
      </div>
    </ModalPopup>
  );
}

export default EditCriteriaPopupModal;
