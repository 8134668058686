import ClientFormAutomationUnitType from "../../../types/dtos/admin/ClientFormAutomationUnitType";
import ClientFormModeType from "../../../types/dtos/admin/ClientFormModeType";
import InfoAlert from "../../alerts/InfoAlert";

interface AutomationWarningMessageProps {
  /** The mode stored in the database currently */
  originalMode: ClientFormModeType | undefined;
  /** The mode selected in the UI */
  selectedMode: ClientFormModeType;
  automationAmount: number | undefined;
  automationUnit: ClientFormAutomationUnitType | undefined;
}

const getAutomationUnitText = (unit: ClientFormAutomationUnitType) => {
  switch (unit) {
    case "DAYS":
      return "days";
    case "WEEKS":
      return "weeks";
    case "MONTHS":
      return "months";
    case "YEARS":
      return "years";
    default:
      return "";
  }
};

export const AutomationWarningMessage = ({
  originalMode,
  selectedMode,
  automationAmount,
  automationUnit,
}: AutomationWarningMessageProps) => {
  // Don't display the alert if the selected mode isn't "automated", or if the original mode was already "automated" (i.e. the user hasn't just changed it to "automated")
  if (selectedMode !== "AUTOMATED" || originalMode === "AUTOMATED") return null;

  const timespan =
    automationAmount && automationUnit
      ? `${automationAmount} ${getAutomationUnitText(automationUnit)}`
      : "";

  return (
    <div className="mt-1">
      <InfoAlert
        prefix="Warning!"
        message={`Once you save these settings, you will set this automation live and Journeys could be sent straight away to people who haven't completed it within the past ${timespan}`}
      />
    </div>
  );
};

export default AutomationWarningMessage;
