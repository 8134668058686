import { Reducer, useContext, useEffect, useReducer, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
//@ts-ignore
import Pagination from "react-js-pagination";
import AppRoutes from "../../routes/AppRoutes";
import ManagerDashboardPersonDto from "../../types/dtos/dashboards/ManagerDashboardPersonDto";
import ManagerDashboardUpdateDto from "../../types/dtos/dashboards/ManagerDashboardUpdateDto";
import managerDashboardApi from "../../api/dashboard/managerDashboardApi";
import IndividualUpdate from "./IndividualUpdate";
import { Badge } from "../common";
import SkipManagerPlanningPopUp from "./SkipManagerPlanningPopUp";
import { userDetailsHelper } from "../../helpers";
import SuccessAlert from "../alerts/SuccessAlert";
import DangerAlert from "../alerts/DangerAlert";
import { AdvancedTasksUpdateNotification } from "./AdvancedTasksUpdateNotification";
import { AppraisalLevelClientTaskTypeDto } from "../../types/dtos/users/AppraisalLevelClientTaskTypeDto";
import ManagerDashboardEmptyState from "./ManagerDashboardEmptyState";
import dualPrepJourneyHelper from "../../helpers/dualPrepJourneyHelper";
import UserContext from "../../state/UserContext";
import ManagerDashboardUpdateSectionDisplayType from "../../types/dtos/dashboards/ManagerDashboardUpdateSectionDisplayType";

interface UpdatesWidgetProps {
  users: ManagerDashboardPersonDto[];
  managerUpdates: ManagerDashboardUpdateDto[];
  isLoading: boolean;
  taskTypes: AppraisalLevelClientTaskTypeDto[];
  reloadUpdates: () => void;
  searchTerm: string | null;
}

interface SkipPlanningPopupState {
  isOpen: boolean;
  subjectUserName: string | null;
  subjectUserId: number | null;
  journeyRef: string | null;
  clientFormTitle: string | null;
  answerSetUniqueId: string | null;
  skipPlanningConfirmationTicked: boolean;
  isLoading: boolean;
}

const getDefaultSkipPlanningPopupState = (): SkipPlanningPopupState => {
  return {
    isOpen: false,
    subjectUserName: null,
    subjectUserId: null,
    journeyRef: null,
    clientFormTitle: null,
    answerSetUniqueId: null,
    skipPlanningConfirmationTicked: false,
    isLoading: false,
  };
};

function UpdatesWidget({
  users,
  isLoading,
  managerUpdates,
  taskTypes,
  reloadUpdates,
  searchTerm,
}: UpdatesWidgetProps) {
  const itemsPerPage = 5;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const userContext = useContext(UserContext);
  const dashboardApi = new managerDashboardApi(auth.user?.access_token);

  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [displayType, setDisplayType] =
    useState<ManagerDashboardUpdateSectionDisplayType>("TO-DOS");
  const [displayItems, setDisplayItems] = useState<ManagerDashboardUpdateDto[]>(
    []
  );

  const [filteredToDos, setFilteredToDos] = useState<
    ManagerDashboardUpdateDto[]
  >([]);
  const [filteredToDosCount, setFilteredToDosCount] = useState<number>(
    filteredToDos.length
  );

  const [filteredUpdates, setFilteredUpdates] = useState<
    ManagerDashboardUpdateDto[]
  >([]);
  const [filteredUpdatesCount, setFilteredUpdatesCount] = useState<number>(
    filteredUpdates.length
  );

  const [sortUpdatesByNewest, setSortUpdatesByNewest] = useState<boolean>(true);
  const [skipPlanningPopUpState, setSkipPlanningPopUpState] = useReducer<
    Reducer<SkipPlanningPopupState, Partial<SkipPlanningPopupState>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    getDefaultSkipPlanningPopupState()
  );
  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [successNotificationMessage, setSuccessNotificationMessage] =
    useState<string>("");
  const [showErrorNotification, setShowErrorNotification] =
    useState<boolean>(false);
  const [errorNotificationMessage, setErrorNotificationMessage] =
    useState<string>("");

  useEffect(() => {
    const filtered = filterItems(managerUpdates);
    updateFilteredStates(filtered);
    setSkipPlanningPopUpState(getDefaultSkipPlanningPopupState()); // Reset Skip Planning Pop Up
  }, [managerUpdates]);

  useEffect(() => {
    const filtered = filterItems(managerUpdates);
    updateFilteredStates(filtered);
  }, [displayType, searchTerm, sortUpdatesByNewest]);

  const showUpdateAsToDo = (update: ManagerDashboardUpdateDto): boolean => {
    return (
      update.updateType !== "ADVANCED-TASKS-UPDATED-BY-EMPLOYEE" &&
      (update.needsManagerAttention ||
        update.updateType === "DUAL-PREP-MEETING")
    );
  };

  const updateFilteredStates = (
    filteredManagerUpdates: ManagerDashboardUpdateDto[]
  ) => {
    const toDos = filteredManagerUpdates.filter((u) => showUpdateAsToDo(u));
    const updates = filteredManagerUpdates.filter((u) => !showUpdateAsToDo(u));

    setFilteredToDos(toDos);
    setFilteredToDosCount(toDos.length);
    setFilteredUpdates(updates);
    setFilteredUpdatesCount(updates.length);

    //Reset the pagination back to the 1st page
    const displayItems = displayType === "TO-DOS" ? toDos : updates;
    handlePaginationChange(1, [...displayItems]);
  };

  const handlePaginationChange = (
    pageNumber: number,
    latestUpdates?: ManagerDashboardUpdateDto[]
  ) => {
    if (managerUpdates != undefined) {
      var startIndex = (pageNumber - 1) * itemsPerPage;
      let displayItems: ManagerDashboardUpdateDto[] = [];

      if (latestUpdates != undefined) {
        displayItems = latestUpdates.splice(startIndex, itemsPerPage);
      } else {
        displayItems =
          displayType === "TO-DOS"
            ? filterItems(filteredToDos).splice(startIndex, itemsPerPage)
            : filterItems(filteredUpdates).splice(startIndex, itemsPerPage);
      }

      setDisplayItems(displayItems);
      setActivePageNumber(pageNumber);
    }
  };

  const filterItems = (unfilteredUpdates: ManagerDashboardUpdateDto[]) => {
    let filtered = [...unfilteredUpdates];

    if (searchTerm) {
      const displayItems = [...managerUpdates].filter((u) => {
        const userIds = users
          .filter((user) =>
            user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((u) => u.userId);
        return userIds.includes(u.userId);
      });

      filtered = filtered.filter((x) => displayItems.includes(x));
    }

    filtered = sortUpdatesByNewest
      ? filtered.sort((a, b) => {
          return (
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        })
      : filtered.sort((a, b) => {
          return (
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          );
        });

    return filtered;
  };

  const triggerSkipConfirmationModalChange = () => {
    setSkipPlanningPopUpState({
      isOpen: !skipPlanningPopUpState.isOpen,
      skipPlanningConfirmationTicked: false,
    });
  };

  const triggerSuccessNotification = (message: string) => {
    setShowSuccessNotification(true);
    setSuccessNotificationMessage(message);
    setShowErrorNotification(false); // Reset Error Notification
    setErrorNotificationMessage(""); // Reset Error Notification
  };

  const triggerErrorNotification = (message: string) => {
    setShowErrorNotification(true);
    setErrorNotificationMessage(message);
    setShowSuccessNotification(false); // Reset error Notification
    setSuccessNotificationMessage(""); // Reset error Notification
  };

  const handleSkipConfirmationModalClick = (
    update: ManagerDashboardUpdateDto
  ) => {
    const subjectUserName = userDetailsHelper.getDisplayName(
      update.userId,
      users
    );
    triggerSkipConfirmationModalChange();
    setSkipPlanningPopUpState({
      isOpen: true,
      subjectUserName: subjectUserName,
      subjectUserId: update.userId,
      journeyRef: update.journeyReference,
      answerSetUniqueId: update.answerSetUniqueId,
      clientFormTitle: update.clientFormTitle,
    });
  };

  const handleSkipPlanningConfirmationTicked = (isTicked: boolean) => {
    setSkipPlanningPopUpState({ skipPlanningConfirmationTicked: isTicked });
  };

  const onConfirmButtonClick = () => {
    setSkipPlanningPopUpState({ isLoading: true });
    if (skipPlanningPopUpState.skipPlanningConfirmationTicked) {
      const successCallback = (data: boolean) => {
        triggerSuccessNotification(
          `${skipPlanningPopUpState.clientFormTitle} planning for ${skipPlanningPopUpState.subjectUserName} has been skipped`
        );
        reloadUpdates();
      };

      const errorCallback = (error: any) => {
        triggerErrorNotification("Error");
        console.error(error);
        setSkipPlanningPopUpState(getDefaultSkipPlanningPopupState()); // Reset Skip Planning Pop Up
      };
      // Reset Skip Planning Pop Up
      dashboardApi.skipManagerPlanning(
        skipPlanningPopUpState.subjectUserId!,
        skipPlanningPopUpState.journeyRef!,
        skipPlanningPopUpState.answerSetUniqueId!,
        successCallback,
        errorCallback
      );
    }
  };

  const onCancelButtonClick = () => {
    setSkipPlanningPopUpState(getDefaultSkipPlanningPopupState());
  };

  const handleLoadDualPrepCollabDoc = (
    employeeId: number,
    journeyRef: string
  ) => {
    const dualPrepHelper = new dualPrepJourneyHelper(dashboardApi, userContext);
    dualPrepHelper.openNewCollabDocByJourneyRef(
      employeeId,
      journeyRef,
      (destinationUrl: string) => {
        navigate(destinationUrl);
      }
    );
  };

  const toggleSortOrder = () => {
    setSortUpdatesByNewest(!sortUpdatesByNewest);
  };

  const getTabHeaderButtonClassNames = (isActive: boolean) => {
    return cx("group !mb-1 mr-3", {
      "hover:cursor-pointer": !isActive,
      "hover:cursor-default": isActive,
      "text-primary": isActive,
      "text-slate-400": !isActive,
      "hover:text-slate-600": !isActive,
    });
  };

  const getTabHeaderTitleClassNames = (isActive: boolean) => {
    return cx("text-xl", {
      "font-bold": isActive,
      "font-semibold": !isActive,
    });
  };

  return (
    <>
      {isLoading && (
        <div
          id="activity-box"
          className="mt-1 mb-3 bg-slate-200 border border-slate-300 rounded px-5 py-3 shadow-lg"
        >
          <div className="flex flex-col md:flex-row">
            <div className="grow">
              <button className={getTabHeaderButtonClassNames(true)}>
                <span className={getTabHeaderTitleClassNames(true)}>To Do</span>
                <TabHeaderCountBadge
                  count={filteredToDosCount}
                  isActive={true}
                />
              </button>
              <button className={getTabHeaderButtonClassNames(false)}>
                <span className={getTabHeaderTitleClassNames(false)}>
                  Updates
                </span>
                <TabHeaderCountBadge
                  count={filteredUpdatesCount}
                  isActive={false}
                />
              </button>
            </div>
          </div>
          <ul className="mt-2 text-sm primary-colour">
            <div className="flex flex-row gap-3 mb-1 last:mb-0">
              <li className="flex-grow px-3 py-2 rounded shadow-sm outline-gray-400 group bg-white">
                {t("Common.Loading")}...
              </li>
            </div>
          </ul>
        </div>
      )}
      {!isLoading && (
        <div
          id="activity-box"
          className="mt-1 mb-3 bg-slate-200 border border-slate-300 rounded-lg px-5 py-3 shadow-lg"
        >
          <div className="flex flex-col md:flex-row">
            <div className="grow">
              <button
                className={getTabHeaderButtonClassNames(
                  displayType === "TO-DOS"
                )}
                onClick={() => setDisplayType("TO-DOS")}
              >
                <span
                  className={getTabHeaderTitleClassNames(
                    displayType === "TO-DOS"
                  )}
                >
                  To Do
                </span>
                <TabHeaderCountBadge
                  count={filteredToDosCount}
                  isActive={displayType === "TO-DOS"}
                />
              </button>
              <button
                className={getTabHeaderButtonClassNames(
                  displayType === "UPDATES"
                )}
                onClick={() => setDisplayType("UPDATES")}
              >
                <span
                  className={getTabHeaderTitleClassNames(
                    displayType === "UPDATES"
                  )}
                >
                  Updates
                </span>
                <TabHeaderCountBadge
                  count={filteredUpdatesCount}
                  isActive={displayType === "UPDATES"}
                />
              </button>
            </div>
            {displayItems && displayItems.length > 0 && (
              <div className="shrink text-right p-2">
                <p className="text-xs text-[#5d5d5d]">
                  Sorting by:
                  <button
                    className="underline inline-block pl-1 primary-colour cursor-pointer"
                    onClick={toggleSortOrder}
                  >
                    {sortUpdatesByNewest ? "Newest" : "Oldest"}
                  </button>
                </p>
              </div>
            )}
          </div>

          <div>
            <hr className="border-slate-300" />
            <div className="mt-2 mb-3">
              {managerUpdates.length === 0 && (
                <div className="rounded-md p-4 pb-3 mt-1">
                  <p className="text-[#5d5d5d] pl-1 text-sm">
                    There are no updates for any of your people at the moment
                  </p>
                </div>
              )}
              {managerUpdates.length > 0 && displayItems.length === 0 && (
                <ManagerDashboardEmptyState optionalText="If you are looking for a completed Journey, click the “Actions” button on the relevant person below and select “Journey History”" />
              )}

              {displayItems && displayItems.length > 0 && (
                <>
                  <ul className="mt-2 text-sm primary-colour">
                    {displayItems.map((u, i) => {
                      return (
                        <div
                          key={i}
                          className="flex flex-row gap-3 mb-1 bg-white rounded-md border border-gray-200 last:mb-0"
                        >
                          <li
                            key={
                              `user_${u.userId}` + `_form_${u.clientFormTitle}`
                            }
                            className="flex-grow px-3 py-2 rounded shadow-sm hover:outline outline-gray-400 group bg-white"
                          >
                            {u.updateType ===
                            "ADVANCED-TASKS-UPDATED-BY-EMPLOYEE" ? (
                              <AdvancedTasksUpdateNotification
                                update={u}
                                users={users}
                                taskTypes={taskTypes}
                              />
                            ) : (
                              <>
                                {u.updateType === "COLLAB-DOC" ||
                                u.updateType === "GENERAL-CATCH-UP" ||
                                u.updateType === "DUAL-PREP-MEETING" ||
                                (u.updateType === "DUAL-PREP-PLANNING" &&
                                  u.answerSetUniqueId !== null) ? (
                                  <Link
                                    to={AppRoutes.collaborativeDocument.generateRoute(
                                      u.answerSetUniqueId!,
                                      false,
                                      null
                                    )}
                                    className="cursor-pointer flex flex-row"
                                  >
                                    <IndividualUpdate
                                      users={users}
                                      update={u}
                                    />
                                  </Link>
                                ) : (
                                  <div
                                    className="cursor-pointer flex flex-row"
                                    role="button"
                                    onClick={() => {
                                      handleLoadDualPrepCollabDoc(
                                        u.userId,
                                        u.journeyReference!
                                      );
                                    }}
                                  >
                                    <IndividualUpdate
                                      users={users}
                                      update={u}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </li>
                          {u.updateType === "DUAL-PREP-PLANNING" &&
                            u.canBeSkipped && (
                              <div
                                role="button"
                                className="btn-secondary bg-white self-center"
                                onClick={() =>
                                  handleSkipConfirmationModalClick(u)
                                }
                              >
                                Skip planning
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </ul>
                </>
              )}
              {showSuccessNotification && (
                <SuccessAlert
                  prefix={t("Common.Success")}
                  message={t(successNotificationMessage)}
                  includeMarginTop={true}
                />
              )}
              {showErrorNotification && (
                <DangerAlert
                  prefix={t("Common.Validation.Oops")}
                  message={t(errorNotificationMessage)}
                />
              )}
            </div>
            {displayItems &&
              displayItems.length > 0 &&
              displayItems.length <= itemsPerPage &&
              managerUpdates.length > itemsPerPage && (
                <div className="text-right mb-2 text-xs">
                  {/* https://www.npmjs.com/package/react-js-pagination */}
                  <Pagination
                    activePage={activePageNumber}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={
                      displayType === "TO-DOS"
                        ? filteredToDosCount
                        : filteredUpdatesCount
                    }
                    pageRangeDisplayed={5}
                    onChange={handlePaginationChange}
                    activeClass="pagination-active-list-item"
                    itemClass="pagination-list-item"
                    itemClassFirst="pagination-first-item"
                    itemClassLast="pagination-last-item"
                  />
                </div>
              )}
          </div>
        </div>
      )}
      <SkipManagerPlanningPopUp
        isOpen={skipPlanningPopUpState.isOpen}
        displayName={skipPlanningPopUpState.subjectUserName!}
        clientFormTitle={skipPlanningPopUpState.clientFormTitle!}
        onOpenChange={triggerSkipConfirmationModalChange}
        onConfirmButtonClick={onConfirmButtonClick}
        onCancelButtonClick={onCancelButtonClick}
        skipPlanningConfirmationTicked={
          skipPlanningPopUpState.skipPlanningConfirmationTicked
        }
        handleSkipPlanningConfirmationTicked={
          handleSkipPlanningConfirmationTicked
        }
        isLoading={skipPlanningPopUpState.isLoading}
      />
    </>
  );
}

const TabHeaderCountBadge = ({
  count,
  isActive,
}: {
  count: number;
  isActive: boolean;
}) => {
  return (
    <span className="inline-block ml-2 font-normal">
      <Badge
        text={count.toString()}
        backgroundColourClassName={cx(
          "hidden md:inline-block",
          isActive ? "accent-bg" : "bg-slate-400 group-hover:bg-slate-600"
        )}
        textColourClassName="text-white"
        marginClassName="ml-0 mr-2"
        classNames="!py-0"
        textSize="text-base"
      />
    </span>
  );
};

export default UpdatesWidget;
