import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import demoApi from "../../api/dashboard/demoApi";
import AppRoutes from "../AppRoutes";
import FullScreenLoaderWithMessage from "../../components/loaders/FullScreenLoaderWithMessage";
import AppContext from "../../state/AppContext";
import GenericApiResult from "../../types/dtos/generic/GenericApiResult";

const DemoManagerReset = () => {
  // Constants
  const appContext = useContext(AppContext);
  const auth = useAuth();
  const navigate = useNavigate();
  const api = new demoApi(auth.user?.access_token);

  const onApiSuccess = (data: GenericApiResult) => {
    if (data.successful) {
      navigate(AppRoutes.yourJourney.root);
    } else {
      onApiError(data.errorMessage);
    }
  };

  const onApiError = (error: any) => {
    alert("An error occurred. Please try again by refreshing this page.");
    console.error(error);
  };

  useEffect(() => {
    // Set the page title
    appContext.setPageTitle("Demo Reset");

    // On load, call the API to reset the demo
    api.resetManagerDemo(onApiSuccess, onApiError);
  });

  return <FullScreenLoaderWithMessage message="Please wait..." />;
};

export default DemoManagerReset;
