import { useContext, useEffect, useState } from "react";
import {
  DateRangePicker,
  RangeKeyDict,
  Range,
  DefinedRangeProps,
} from "react-date-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-duotone-svg-icons";
import UserContext from "../../../state/UserContext";
import FilterEditIcon from "./FilterEditIcon";
import { IDateRange, ILabelledDateRange } from "../../../types/generic";
import { FormattedDate } from "../../common";
import "../../../css/common/date-window-picker.css";

interface DateWindowPickerProps {
  definedRanges: ILabelledDateRange[];
  selectedValue: IDateRange | null;
  onSelectedValueChanged: (newValue: IDateRange | null) => void;
  isEditMode: boolean;
  onBeginEditMode(): void;
}

function DateWindowPicker({
  definedRanges,
  selectedValue,
  isEditMode,
  onSelectedValueChanged,
  onBeginEditMode,
}: DateWindowPickerProps) {
  const userContext = useContext(UserContext);

  const [displayValue, setDisplayValue] = useState<string | null>("");

  useEffect(() => {
    // Set the description on initial load and on selected value change
    const defaultValue = selectedValue
      ? { startDate: selectedValue.startDate, endDate: selectedValue.endDate }
      : null;
    const description = getDescriptionForRange(defaultValue);
    setDisplayValue(description);
  }, [selectedValue]);

  const staticRanges = definedRanges.map((x, i) => {
    return {
      label: x.label || `StaticRange_${i.toString()}`,
      hasCustomRendering: false,
      isSelected: (range: Range) => {
        return range.startDate && range.endDate
          ? range.startDate === x.startDate && range.endDate === x.endDate
          : false;
      },
      range: (props?: DefinedRangeProps) => ({
        startDate: x.startDate,
        endDate: x.endDate,
      }),
    };
  });

  function getDescriptionForRange(
    selectedRange: IDateRange | null,
    predefinedRangeLabel?: string | undefined
  ) {
    let displayText: string | null = "No value selected";

    if (selectedRange) {
      if (predefinedRangeLabel) {
        // Use the value provided
        displayText = predefinedRangeLabel;
      } else {
        // Check whether there's a matching value in the static ranges
        const matchingStaticRange = definedRanges.find(
          (x) =>
            x.startDate === selectedRange.startDate &&
            x.endDate === selectedRange.endDate
        );
        if (matchingStaticRange) {
          displayText = matchingStaticRange.label;
        } else {
          // If not matching a static range, set the display value to null, so we can show the date range
          displayText = null;
        }
      }
    }

    return displayText;
  }

  const handleDateControlSelect = (ranges: RangeKeyDict) => {
    const selectedRange = ranges?.range1;
    const newValue = selectedRange
      ? { startDate: selectedRange.startDate, endDate: selectedRange.endDate }
      : null;
    onSelectedValueChanged(newValue);
  };

  const themeColour = `#${userContext.user.client.accentHexColour}`;

  return (
    <div>
      {!isEditMode && (
        <button onClick={onBeginEditMode} className="group">
          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
          <span>
            {displayValue && displayValue.length > 0 && <>{displayValue}</>}
            {(!displayValue || displayValue.length === 0) &&
              selectedValue &&
              selectedValue.startDate &&
              selectedValue.endDate && (
                <>
                  <FormattedDate
                    date={selectedValue.startDate}
                    displayMode="DATE-ONLY"
                  />
                  {" to "}
                  <FormattedDate
                    date={selectedValue.endDate}
                    displayMode="DATE-ONLY"
                  />
                </>
              )}
          </span>
          <FilterEditIcon extraClassNames="ml-2" />
        </button>
      )}
      {isEditMode && (
        <DateRangePicker
          ranges={selectedValue ? [selectedValue] : undefined}
          onChange={handleDateControlSelect}
          color={themeColour}
          rangeColors={[themeColour]}
          staticRanges={staticRanges}
          inputRanges={[]}
        />
      )}
    </div>
  );
}

export default DateWindowPicker;
