import { t } from "i18next";
import { BaseUserDetailsDto } from "../types/dtos/generic";
import {
  SimpleFormAnswer,
  SimpleFormQuestion,
} from "../types/dtos/simple-forms";
import SignedInUserDto from "../types/dtos/users/SignedInUserDto";

const subjectToken = "#SUBJECT_NAME#";
const employeeToken = "#EMPLOYEE_NAME#";
const clientNameToken = "#CLIENT#";

const simpleFormHelper = {
  getQuestionText: function (
    question: SimpleFormQuestion,
    subjectUser: BaseUserDetailsDto,
    loggedInUser: SignedInUserDto
  ): string {
    const userIsSubject = subjectUser.userId === loggedInUser.id;

    // Use the third person text if the user is not the subject and the question has different text for third person
    let output = t(
      !userIsSubject &&
        question.useDifferentTextFor3rdPerson &&
        question.questionText3rdPerson
        ? question.questionText3rdPerson
        : question.questionText2ndPerson
    );

    output = output.replace(subjectToken, subjectUser!.firstName);
    output = output.replace(employeeToken, subjectUser!.firstName);
    output = output.replace(clientNameToken, loggedInUser.client.name);

    return output;
  },

  /** Do any manipulation of server values, e.g. if we need to convert date strings to date objects in the future */
  prepServerAnswersForDisplay: (
    answers: SimpleFormAnswer[] | null | undefined
  ): SimpleFormAnswer[] => {
    if (!answers || answers.length === 0) return [];

    return answers;
  },

  /** Handle a change to the answer state (creates a new answer array, doesn't mutate the existing collection) */
  updateAnswerState: (
    newAnswer: SimpleFormAnswer,
    existingAnswers: SimpleFormAnswer[]
  ) => {
    const newAnswers = [...existingAnswers];
    const existingAnswerIndex = newAnswers.findIndex(
      (a) => a.questionId === newAnswer.questionId
    );

    const hasTextAnswerValue =
      (newAnswer.textAnswer?.answerText?.trim() ?? "").length > 0;
    const hasMultiChoiceAnswerValue =
      !!newAnswer.multiChoiceAnswers && newAnswer.multiChoiceAnswers.length > 0;

    if (hasTextAnswerValue || hasMultiChoiceAnswerValue) {
      // Insert or replace an existing answer object
      if (existingAnswerIndex > -1) {
        newAnswers[existingAnswerIndex] = newAnswer;
      } else {
        newAnswers.push(newAnswer);
      }
    } else {
      // No answer value, remove it if there was one before
      if (existingAnswerIndex > -1) {
        newAnswers.splice(existingAnswerIndex, 1);
      }
    }

    return newAnswers;
  },
};

export default simpleFormHelper;
