import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../state/UserContext";
import AppRoutes from "../../routes/AppRoutes";
import DashboardSummaryTabDualPrepAnswerSetDto from "../../types/dtos/dashboards/DashboardSummaryTabDualPrepAnswerSetDto";
import FormSummaryActionButton from "./FormSummaryActionButton";

interface DualPrepFormSummaryButtonProps {
  formName: string;
  incompleteAnswerSetUniqueId: string | null | undefined;
  dualPrepAnswerSet: DashboardSummaryTabDualPrepAnswerSetDto | null;
  hasHistoryToShow: boolean;
  isManagerDashboard: boolean;
  isManagerViewingButHasBeenDelegated?: boolean;
  hideButton?: boolean;
  onUpdateButtonClick(): void;
  onHistoryButtonClick(): void;
}

/** The buttons displayed on a summary tab for dual-prep forms */
const DualPrepFormSummaryButtons = ({
  formName,
  incompleteAnswerSetUniqueId,
  dualPrepAnswerSet,
  hasHistoryToShow,
  isManagerDashboard,
  isManagerViewingButHasBeenDelegated = false,
  hideButton = false,
  onUpdateButtonClick,
  onHistoryButtonClick,
}: DualPrepFormSummaryButtonProps) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const isDualPrepEmpPlanningCompleted =
    dualPrepAnswerSet?.employeePrepCompleted;
  const isDualPrepManPlanningCompleted =
    dualPrepAnswerSet?.managerPrepCompleted;

  if (isManagerViewingButHasBeenDelegated && dualPrepAnswerSet == null) {
    hideButton = true;
  }
  // If employee has completed, but waiting for manager to complete... hide button for employee
  else if (
    !isManagerDashboard &&
    isDualPrepEmpPlanningCompleted &&
    !isDualPrepManPlanningCompleted
  ) {
    hideButton = true;
  }
  // If employee hasn't completed yet, but the manager has... hide button for manager
  else if (
    isManagerDashboard &&
    !isDualPrepEmpPlanningCompleted &&
    isDualPrepManPlanningCompleted
  ) {
    hideButton = true;
  }

  const navigateToCollabDoc = () => {
    // Go to manager doc if not completed, else go to the final doc
    let answerSetUniqueId = !isDualPrepManPlanningCompleted
      ? incompleteAnswerSetUniqueId
      : dualPrepAnswerSet?.collabDocAnswerSetId;

    const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
      answerSetUniqueId!,
      false,
      null
    );

    navigate(redirectUrl);
  };

  let showTriggerDocButton = true;
  // If the form is an induction form, and the user has completed this form before,
  // we need to hide the button (as they can't choose to go and complete it again)
  if (hasHistoryToShow && userContext.user.myDashboardMode === "INDUCTION") {
    showTriggerDocButton = false;
  }

  const updateButtonClickEvent =
    incompleteAnswerSetUniqueId !== null ||
    dualPrepAnswerSet?.collabDocAnswerSetId
      ? navigateToCollabDoc
      : onUpdateButtonClick;

  return (
    <FormSummaryActionButton
      formName={formName}
      showTriggerDocButton={showTriggerDocButton}
      hideButton={hideButton}
      updateButtonClickEvent={updateButtonClickEvent}
      isManagerViewingButHasBeenDelegated={isManagerViewingButHasBeenDelegated}
      hasHistoryToShow={hasHistoryToShow}
      isManagerDashboard={isManagerDashboard}
      onHistoryButtonClick={onHistoryButtonClick}
    />
  );
};

export default DualPrepFormSummaryButtons;
