import { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cx from "classnames";

export interface DropDownMenuItem {
  text: string;
  /** Set a value if the menu's EventType is "NAVIGATE" */
  href?: string | undefined;
  /** Used for custom click events on items */
  customArg?: any | undefined;
  customIcon?: ReactNode | undefined;
  disabled?: boolean | undefined;
  disabledMsg?: string;
}

export interface DropDownMenuProps {
  menuButtonClassName?: string;
  outerDivClassName?: string;
  eventType: "NAVIGATE" | "EVENT";
  items: DropDownMenuItem[];
  customButtonContents?: ReactNode | undefined;
  isWithinButtonElement?: boolean;
  onItemClick?(itemIndex: number, customArg: any | undefined): void | undefined;
}

/** A form field containing a dropdownlist (i.e. a `select` element) */
const DropDownMenu = ({
  items,
  eventType,
  onItemClick,
  customButtonContents = undefined,
  isWithinButtonElement = false,
  menuButtonClassName = "inline-flex justify-center w-full bg-white text-sm font-medium text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none",
  outerDivClassName = "relative inline-block text-left",
}: DropDownMenuProps) => {
  const { t } = useTranslation();

  const handleItemClick = (index: number, clickedItem: DropDownMenuItem) => {
    if (onItemClick) {
      onItemClick(index, clickedItem.customArg);
    }
  };

  const handleOnlyItemClick = () => {
    // If there's only one item, we display a single button instead of a dropdown
    // and that needs a different click handler
    if (onItemClick) {
      onItemClick(0, items[0].customArg);
    }
  };

  const menuButtonContents =
    customButtonContents === undefined ? (
      <FontAwesomeIcon icon={faChevronDown} className="text-gray-500 m-4" />
    ) : (
      customButtonContents
    );

  if (items && items.length === 1) {
    const oneItem = items[0];
    return (
      <div className={outerDivClassName}>
        {eventType === "NAVIGATE" && (
          <Link
            to={oneItem.href || ""}
            className={cx("btn-primary !px-4", menuButtonClassName)}
          >
            {t(oneItem.text)}
          </Link>
        )}
        {eventType === "EVENT" && (
          <button
            onClick={handleOnlyItemClick}
            className={cx("btn-primary !px-4", menuButtonClassName)}
          >
            {t(oneItem.text)}
          </button>
        )}
      </div>
    );
  }

  return (
    <Menu as="div" className={cx("z-1", outerDivClassName)}>
      <Menu.Button
        className={menuButtonClassName}
        as={isWithinButtonElement ? "span" : undefined}
      >
        {menuButtonContents}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-1 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
          <div className="py-1">
            {items.map((item, itemIndex) => (
              <Menu.Item key={item.text}>
                <div>
                  {eventType === "NAVIGATE" && (
                    <Link
                      to={item.href || ""}
                      className="text-sm block my-1 text-gray-600 font-medium hover:bg-gray-100 py-2 px-4 cursor-pointer"
                    >
                      {t(item.text)}
                      {item.customIcon ? item.customIcon : null}
                    </Link>
                  )}
                  {eventType === "EVENT" && (
                    <span
                      className={cx(
                        !item.disabled
                          ? "text-sm block my-1 text-gray-600 font-medium hover:bg-gray-100 py-2 px-4 cursor-pointer"
                          : "text-sm block my-1 text-gray-400 font-medium bg-gray-100 py-2 px-4 cursor-not-allowed"
                      )}
                      onClick={
                        !item.disabled
                          ? () => handleItemClick(itemIndex, item)
                          : () => {}
                      }
                    >
                      {t(item.text)}
                      {item.customIcon ? item.customIcon : null}
                      {item.disabled && item.disabledMsg && (
                        <p className="text-xs text-gray-400">
                          {item.disabledMsg}
                        </p>
                      )}
                    </span>
                  )}
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDownMenu;
