import * as SwitchPrimitive from "@radix-ui/react-switch";
import cx from "classnames";

interface SwitchProps {
  checked: boolean;
  onChange(isChecked: boolean): void;
  isReadOnly?: boolean;
  size?: "sm" | "norm";
}

const Switch = ({ checked, onChange, isReadOnly = false, size = "sm" }: SwitchProps) => {
  return (
    <SwitchPrimitive.Root
      checked={checked}
      disabled={isReadOnly}
      onCheckedChange={onChange}
      className={cx(
        "group",
        "radix-state-checked:bg-green-600",
        "radix-state-unchecked:bg-gray-200",
        "relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out",
        "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
        size === "sm" ? "h-[14px] w-[24px]" : "h-[24px] w-[44px]"
      )}
    >
      <SwitchPrimitive.Thumb
        className={cx(
          "group-radix-state-unchecked:translate-x-0",
          "pointer-events-none inline-block transform rounded-full bg-white shadow-lg ring-0 transition duration-100 ease-in-out",
          isReadOnly ? "cursor-not-allowed" : "",
          size === "sm" ? "h-[10px] w-[10px] group-radix-state-checked:translate-x-2.5" 
                        : "h-[20px] w-[20px] group-radix-state-checked:translate-x-5"
        )}
      />
    </SwitchPrimitive.Root>
  );
};

export default Switch;
