import React, { useState } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faNotes,
} from "@fortawesome/pro-regular-svg-icons";

interface CollapsibleProps {
  label: string;
  children: React.ReactNode;
  open: boolean;
  setOpen(): void | undefined;
}

function Collapsible({ label, children, open, setOpen }: CollapsibleProps) {
  return (
    <div>
      <button
        onClick={setOpen}
        className={cx(
          "text-primary p-1 pl-2 pr-2 text-sm rounded text-bold border border-gray-400 shadow-md font-bold",
          open ? "" : ""
        )}
      >
        <FontAwesomeIcon
          size="sm"
          icon={faNotes}
          className="text-primary pt-1 pr-2"
        />
        {label}
        {open && (
          <FontAwesomeIcon
            size="sm"
            className="pl-2 pt-1 text-primary"
            icon={faChevronUp}
          />
        )}
        {!open && (
          <FontAwesomeIcon
            size="sm"
            className="pl-2 pt-1 text-primary"
            icon={faChevronDown}
          />
        )}
      </button>
      {open && (
        <div className=" bg-gray-100 p-1 pl-2 pr-2 text-sm rounded mt-1 border border-gray-200">
          {" "}
          {children}{" "}
        </div>
      )}
    </div>
  );
}

export default Collapsible;
