import { t } from "i18next";
import { AnswerSetApprovalStatus } from "../types/collab-docs";
import { UserBasicDetailsDto } from "../types/dtos/generic";
import userDetailsHelper from "./userDetailsHelper";

const collabDocStatusHelper = {
  /** Get the user-friendly text for the collab doc (answer set) status, along with whether or not to highlight the status badge, and tooltip content */
  getDisplayDetails(
    status: AnswerSetApprovalStatus | null | undefined,
    loggedInUserId: number,
    subjectUserId: number,
    participants: UserBasicDetailsDto[],
    isManagerButBeenDelegated: boolean,
    isDualPrepForm: boolean | null | undefined = null,
    isDualPrepEmpPlanningCompleted: boolean | null | undefined = null,
    isDualPrepManPlanningCompleted: boolean | null | undefined = null
  ): collabDocStatusDisplayDetails {
    const loggedInUserIsSubject = loggedInUserId === subjectUserId;
    const loggedInUserSuffix = loggedInUserIsSubject
      ? "_Emp"
      : isManagerButBeenDelegated
      ? "_MgrDelegated"
      : "_Mgr";

    const statusTransKeyPrefix = "Pages.CollaborativeDocument.Status.";
    const tooltipsTransKeyPrefix = statusTransKeyPrefix + "Tooltips.";
    let statusIdentifier: string = "Unknown";
    let shouldHighlight: boolean = false;

    // For the Dual Prep forms, and only for the planning stages, we need to work the status differently. However when its in the
    // final collab/meeting stage then it should act as standard.
    if (isDualPrepForm) {
      // Neither participant has started the dual prep planning
      if (!isDualPrepEmpPlanningCompleted && !isDualPrepManPlanningCompleted) {
        statusIdentifier = "NeitherPlanningComplete" + loggedInUserSuffix;
        shouldHighlight = true;
      }

      // Only the employee planning has been completed
      else if (
        isDualPrepEmpPlanningCompleted &&
        !isDualPrepManPlanningCompleted
      ) {
        statusIdentifier = "OnlyEmployeePlanningComplete" + loggedInUserSuffix;
        shouldHighlight = true;
      }

      // Only the manager planning has been completed
      else if (
        !isDualPrepEmpPlanningCompleted &&
        isDualPrepManPlanningCompleted
      ) {
        statusIdentifier = "OnlyManagerPlanningComplete" + loggedInUserSuffix;
        shouldHighlight = true;
      }
    }

    // Should always be 'Unknown' at this point, _unless_ we are in a dual prep planning stage
    if (statusIdentifier === "Unknown") {
      switch (status) {
        case "INITIAL-SAVE-FORM-INCOMPLETE":
          if (isDualPrepForm) {
            // If Dual Prep and the collab doc has this status then its the start of the meeting stage
            statusIdentifier = "InitialSaveFormIncomplete_DualPrepMeeting";
          } else {
            statusIdentifier = "InitialSaveFormIncomplete";
          }
          shouldHighlight = true;
          break;
        case "SAVED-BY-EMPLOYEE-WITHOUT-APPROVING":
          statusIdentifier =
            "SavedByEmployeeWithoutApproving" + loggedInUserSuffix;
          shouldHighlight = loggedInUserIsSubject;
          break;
        case "SAVED-BY-MANAGER-WITHOUT-APPROVING":
          statusIdentifier =
            "SavedByManagerWithoutApproving" + loggedInUserSuffix;
          shouldHighlight = !loggedInUserIsSubject;
          break;
        case "APPROVED-BY-EMPLOYEE":
          statusIdentifier = "ApprovedByEmployee" + loggedInUserSuffix;
          shouldHighlight = !loggedInUserIsSubject;
          break;
        case "APPROVED-BY-MANAGER":
          statusIdentifier = "ApprovedByManager" + loggedInUserSuffix;
          shouldHighlight = loggedInUserIsSubject;
          break;
        case "RETURNED-BY-EMPLOYEE":
          statusIdentifier = "ReturnedByEmployee" + loggedInUserSuffix;
          shouldHighlight = !loggedInUserIsSubject;
          break;
        case "RETURNED-BY-MANAGER":
          statusIdentifier = "ReturnedByManager" + loggedInUserSuffix;
          shouldHighlight = loggedInUserIsSubject;
          break;
        case "FULLY-APPROVED":
          statusIdentifier = "FullyApproved";
          break;
        case "NO-APPROVAL-NECESSARY":
          statusIdentifier = "NoApprovalNecessary";
          break;
        default:
          statusIdentifier = "Unknown";
          break;
      }
    }

    const output = {
      shouldHighlight: shouldHighlight,
      statusText: t(statusTransKeyPrefix + statusIdentifier),
      tooltipText: t(tooltipsTransKeyPrefix + statusIdentifier),
    };

    output.statusText = replaceParticipantNameTokens(
      output.statusText,
      loggedInUserIsSubject,
      subjectUserId,
      participants
    );
    output.tooltipText = replaceParticipantNameTokens(
      output.tooltipText,
      loggedInUserIsSubject,
      subjectUserId,
      participants
    );

    return output;
  },
};

interface collabDocStatusDisplayDetails {
  shouldHighlight: boolean;
  statusText: string;
  tooltipText: string;
}

const replaceParticipantNameTokens = (
  inputText: string,
  loggedInUserIsSubject: boolean,
  subjectUserId: number,
  participants: UserBasicDetailsDto[]
): string => {
  if (!inputText) return "(unknown)";

  const manager = participants.find((x) => x.userId !== subjectUserId)!;
  const employee = participants.find((x) => x.userId === subjectUserId)!;

  const managerName = userDetailsHelper.getDisplayName(
    manager.userId,
    participants
  );
  const employeeName = userDetailsHelper.getDisplayName(
    employee.userId,
    participants
  );

  const otherParticipantName = loggedInUserIsSubject
    ? employeeName
    : managerName;

  return inputText
    .replace("#SUBJECT_EMPLOYEE_NAME#", employeeName)
    .replace("#MANAGER_NAME#", managerName)
    .replace("#OTHER_PARTICIPANT#", otherParticipantName);
};

export default collabDocStatusHelper;
