import { t } from "i18next";
import { Markup } from "interweave";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import { ReactComponent as EmptySummaryState } from "../../images/emptySummaryState.svg";

interface FormSummaryEmptyStandardStateProps {
  formTitle: string;
  /** For the first time for a user/form combination there's an active collab doc, show a different empty state */
  incompleteAnswerSetUniqueId: string | null | undefined;
  isManagerDashboard: boolean;
  activeDocumentStatusBadge: JSX.Element;
  showStatusBadge: boolean;
  isManagerViewingButHasBeenDelegated?: boolean;
  delegatedManagerName?: string | null;
  hideButton?: boolean;
  onButtonClick(): void;
}

const FormSummaryEmptyStandardState = ({
  formTitle,
  incompleteAnswerSetUniqueId,
  isManagerDashboard,
  activeDocumentStatusBadge,
  showStatusBadge,
  isManagerViewingButHasBeenDelegated = false,
  delegatedManagerName,
  hideButton = false,
  onButtonClick,
}: FormSummaryEmptyStandardStateProps) => {
  const navigate = useNavigate();

  const goToCollabDoc = () => {
    const collabDocUrl = AppRoutes.collaborativeDocument.generateRoute(
      incompleteAnswerSetUniqueId!,
      false,
      null
    );
    navigate(collabDocUrl);
  };

  let displayText: string;
  if (isManagerDashboard) {
    if (isManagerViewingButHasBeenDelegated && delegatedManagerName != null) {
      displayText = t("Delegation.FormSummaryEmptyState.ThisHasBeenDelegated");
      displayText = displayText.replace(
        "#DELEGATED_MANAGER_NAME#",
        delegatedManagerName
      );
    } else if (incompleteAnswerSetUniqueId) {
      displayText =
        "It looks like there aren't any answers to show here.<br />Complete the open Journey to update this summary.";
    } else {
      displayText =
        "It looks like there aren't any answers to show here.<br />Complete a Journey to update this summary.";
    }
  } else {
    if (incompleteAnswerSetUniqueId) {
      displayText =
        "There isn't anything to show here right now.<br />Your open Journey needs to be completed before you can view this summary.";
    } else {
      displayText =
        "It looks like you haven't got any answers to show here.<br />Complete a Journey to update this summary.";
    }
  }

  const buttonFunction = incompleteAnswerSetUniqueId
    ? goToCollabDoc
    : onButtonClick;

  const updateButtonText = !isManagerViewingButHasBeenDelegated
    ? `${t("Common.Update")} '${t(formTitle)}'`
    : `${t("Common.View")} '${t(formTitle)}'`;

  return (
    <div className="text-center w-full border rounded p-6">
      <span className="text-center text-gray-400 border-gray-200 relative">
        {showStatusBadge && (
          <div className="text-center md:text-right pb-2 mb-2 border-b-2 md:border-0 absolute right-0">
            {activeDocumentStatusBadge}
          </div>
        )}
        <EmptySummaryState className="m-auto" width={80} />
        <div className="mt-2 mb-4 text-sm">
          <Markup content={displayText} />
        </div>
      </span>
      {!hideButton && (
        <div className="mt-2 text-center">
          <button
            className="hover:[background-color:var(--color-primary)] hover:text-white bg-gray-200 text-gray-500 text-center border-0 drop-shadow-sm py-1 px-6 m-0 rounded-md font-medium text-sm"
            onClick={buttonFunction}
            role="link"
          >
            {updateButtonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default FormSummaryEmptyStandardState;
