import { t } from "i18next";
import { Tooltip } from "../common";
import { mathsHelper } from "../../helpers";
import { WidgetDataSetAverageScore } from "../../types/analytics/AnalyticsWidgetUiDetailsDto";
import { byPropertiesOf } from "../../helpers/arraySorting";

interface AverageScoresSummaryProps {
  averageScores: WidgetDataSetAverageScore[] | null;
}

function getScoreDisplayValueWithTooltip(score: WidgetDataSetAverageScore) {
  const integerValue = mathsHelper.roundForDisplay(score.averageScore, 0);
  const displayValue = score.isPercentage ? integerValue + "%" : integerValue;

  const preciseValue = mathsHelper.roundForDisplay(score.averageScore, 3);
  const tooltipValue = score.isPercentage ? preciseValue + "%" : preciseValue;

  return (
    <Tooltip
      content={tooltipValue}
      triggerElement={<span>{displayValue}</span>}
    />
  );
}

function AverageScoresSummary({ averageScores }: AverageScoresSummaryProps) {
  if (averageScores === null || averageScores.length === 0) {
    return null;
  }

  if (averageScores.length === 1 && averageScores[0].groupName == null) {
    const scoreElement = getScoreDisplayValueWithTooltip(averageScores[0]);
    return <div className="text-center mb-4">Average: {scoreElement}</div>;
  }

  const sortedScores = averageScores.sort(
    byPropertiesOf<WidgetDataSetAverageScore>(["-averageScore"])
  );

  return (
    <div className="mb-4 mt-2">
      <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto">
        <table className="datatable">
          <thead>
            <tr>
              <th></th>
              <th className="text-center w-12">Average</th>
            </tr>
          </thead>
          <tbody>
            {sortedScores.map((score) => (
              <tr key={score.groupName}>
                <td>
                  {score.groupName && score.translateGroupName
                    ? t(score.groupName)
                    : score.groupName}
                </td>
                <td className="text-center">
                  {getScoreDisplayValueWithTooltip(score)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AverageScoresSummary;
