import ValidationResult, { ValidationError } from "./ValidationResult";

export class GoalReviewStatusCommentValidationResult extends ValidationResult {
  constructor(
    isValid: boolean,
    errors: Array<ValidationError> = [],
    validGoalIds: number[],
    totalGoals: number
  ) {
    super(isValid, errors);

    this.validGoalIds = validGoalIds;
    this.validGoalCount = validGoalIds.length;
    this.totalGoalCount = totalGoals;
  }

  totalGoalCount: number;
  validGoalCount: number;
  validGoalIds: number[];
}

export default GoalReviewStatusCommentValidationResult;
