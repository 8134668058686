import { useContext } from "react";
import { t } from "i18next";
import SmallLoader from "../loaders/SmallLoader";
import UserContext from "../../state/UserContext";

export const AnalyticsLoadingSpinner = () => {
  const userContext = useContext(UserContext);
  return (
    <div className="my-8 text-center">
      <SmallLoader colour={userContext.user.client.accentHexColour} />
      <span className="block mt-2 text-gray-600 text-sm">
        {t("Common.Loading") + "..."}
      </span>
    </div>
  );
};
