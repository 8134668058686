import { useTranslation } from "react-i18next";
import { FormSummaryBehaviourDataDto } from "../../types/dtos/form-summaries/FormSummaryBehaviourDataDto";
import AreaChartComponent from "./AreaChartComponent";

interface BehaviourFormSummaryContentProps {
  behaviourData: FormSummaryBehaviourDataDto | null | undefined;
}

const BehaviourFormSummaryContent = ({
  behaviourData,
}: BehaviourFormSummaryContentProps) => {
  const { t } = useTranslation();

  // Construct the data the chart needs to display
  const behaviourChartData =
    behaviourData &&
    behaviourData.behaviours &&
    behaviourData.behaviours.map((b) => {
      return {
        name: t(b.title),
        Score: b.score,
        pv: b.score,
        amt: b.score,
      };
    });

  // Return the empty state if there isn't any data to display
  if (!behaviourData?.behaviours) {
    return (
      <div>
        <span className="text-center text-gray-400">
          There is no current information to display. If you would like you
          update this section click the button below
        </span>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col justify-between bg-gray-100 rounded p-4">
        <div className="flex flex-col flex-1">
          <span className="uppercase text-xs text-gray-500 pb-1">
            {t("Summaries.Behaviours.AverageBehaviourScore")}
          </span>
          <div className="font-medium text-gray-700 pl-2 text-sm">
            {behaviourData.averageScoreDisplay}
          </div>
        </div>
        <div className="mt-4 flex flex-col flex-1">
          <div>
            <div className="uppercase text-xs text-gray-500 pb-1">
              {t("Summaries.Behaviours.HighestScoringBehaviour")}
            </div>
            <div className="font-medium text-gray-700 pl-2 text-sm">
              {t(behaviourData.highestScoringBehaviour)}
            </div>
          </div>

          <div className="mt-4">
            <div className="uppercase text-xs text-gray-500 pb-1">
              {t("Summaries.Behaviours.LowestScoringBehaviour")}
            </div>
            <div className="font-medium text-gray-700 pl-2 text-sm">
              {t(behaviourData.lowestScoringBehaviour)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BehaviourFormSummaryContent;
