import { useContext, useState } from "react";
import { t } from "i18next";
import { ReviewableGoalTask } from "../../../../types/tasks/Task";
import { FormattedDate, ModalPopup } from "../../../common";
import UserContext from "../../../../state/UserContext";
import TruncatableGoalField from "./TruncatableGoalField";
import TaskCommentDto from "../../../../types/tasks/TaskCommentDto";
import TaskCommentsApiResponseDto from "../../../../types/dtos/tasks/TaskCommentsApiResponseDto";
import { UserBasicDetailsDto } from "../../../../types/dtos/generic";
import { useAuth } from "react-oidc-context";
import simpleTasksApi from "../../../../api/task/simpleTasksApi";
import TaskCommentsList from "../../../tasks/TaskCommentsList";

interface GoalReviewGoalDetailProps {
  goal: ReviewableGoalTask;
}

function GoalReviewGoalDetail({ goal }: GoalReviewGoalDetailProps) {
  // Context
  const userContext = useContext(UserContext);
  const showCategoryField =
    userContext.user.appLvl.goalCategories !== null &&
    userContext.user.appLvl.goalCategories.length > 0;

  // Auth/API
  const auth = useAuth();
  const tasksApi = new simpleTasksApi(auth.user?.access_token);

  // State
  const [displayComments, setDisplayComments] = useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
  const [comments, setComments] = useState<TaskCommentDto[]>([]);
  const [commentParticipants, setCommentParticipants] = useState<
    UserBasicDetailsDto[]
  >([]);

  // Events
  const showCommentsModal = () => {
    setIsLoadingComments(true);
    // Call the api
    tasksApi.getComments(
      goal.toDoId!,
      (data: TaskCommentsApiResponseDto) => {
        setComments(data.comments);
        setCommentParticipants(data.participants);
        setIsLoadingComments(false);
        setDisplayComments(true);
      },
      (error: any) => {
        setIsLoadingComments(false);
        console.log("Error getting comments", error);
      }
    );
  };

  const closeCommentsModal = () => {
    setDisplayComments(false);
  };

  // Calculated values
  let goalCategory: string | undefined = undefined;
  if (showCategoryField && goal.categoryId) {
    goalCategory = userContext.user.appLvl.goalCategories?.find(
      (x) => x.key === goal.categoryId
    )?.value;
  }

  return (
    <>
      <div className="text-sm mb-2">
        <div className="mb-1">
          <strong className="mr-1">{t("Tasks.Common.TargetDate")}:</strong>
          <FormattedDate
            date={goal.targetDate!}
            displayMode="DATE-ONLY"
            convertFromUtc
          />
        </div>
        {goalCategory && (
          <div className="mb-1">
            <strong className="mr-1">{t("Tasks.Forms.Goal.Category")}:</strong>
            {goalCategory}
          </div>
        )}
        <div className="mb-1">
          <div>
            <strong>{t("Tasks.Forms.Goal.Field1")}</strong>
            <TruncatableGoalField text={goal.field1!} />
          </div>
        </div>
        <div className="mb-1">
          <div>
            <strong>{t("Tasks.Forms.Goal.Field2")}</strong>
            <TruncatableGoalField text={goal.field2!} />
          </div>
        </div>
        {goal.commentCount > 0 && (
          <div className="mb-1">
            <div>
              <strong className="mr-1">
                {t("Forms.Goals.Review.CommentCount")}:
              </strong>
              {goal.commentCount}{" "}
              <button className="underline" onClick={showCommentsModal}>
                {t("Forms.Goals.Review.ViewCommentsButton")}
              </button>
            </div>
          </div>
        )}
      </div>
      <ModalPopup
        isOpen={displayComments}
        onOpenChange={setDisplayComments}
        primaryButtonText={t("Common.Close")}
        onPrimaryButtonClick={closeCommentsModal}
        title="Comments"
      >
        <TaskCommentsList
          comments={comments}
          participants={commentParticipants}
          isReadOnly={true}
        />
      </ModalPopup>
    </>
  );
}

export default GoalReviewGoalDetail;
