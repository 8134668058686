import { t } from "i18next";
import { useEffect, useState } from "react";
import { InfoBanner, ModalPopup } from "../common";
import { useAuth } from "react-oidc-context";
import PeoplePicker from "../common/PeoplePicker";
import React from "react";
import UserContext from "../../state/UserContext";
import SuccessAlert from "../alerts/SuccessAlert";
import managerDashboardApi from "../../api/dashboard/managerDashboardApi";
import DangerAlert from "../alerts/DangerAlert";
import ResultStatus from "../../types/dtos/generic/ResultStatus";
import { ManagerDashboardPersonDto } from "../../types/dtos/dashboards";

interface AssignDelegatedManagerPopupProps {
  title: string;
  isUserCurrentlyDelegated: boolean;
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  delegatedManagerId?: number;
  delegatedManagerName?: string;
  managerFullName: string;
  managedUser: ManagerDashboardPersonDto;
  onUpdateUser(user: ManagerDashboardPersonDto): void | undefined;
}

function AssignDelegatedManagerPopup({
  title,
  isUserCurrentlyDelegated,
  isOpen,
  onOpenChange,
  delegatedManagerId,
  delegatedManagerName,
  managerFullName,
  managedUser,
  onUpdateUser,
}: AssignDelegatedManagerPopupProps) {
  // Context
  const userContext = React.useContext(UserContext);
  // Auth/API
  const auth = useAuth();
  const dashboardApi = new managerDashboardApi(auth.user?.access_token);

  useEffect(() => {
    if (isOpen) {
      setShowFailedMessage(false);
      setShowSuccessMessage(false);
      setProposedJourneyManagerId(delegatedManagerId);
    } else {
      if (userToUpdateOnModalClose != undefined) {
        onUpdateUser(userToUpdateOnModalClose);
        setUserToUpdateOnModalClose(undefined);
      }
    }
  }, [isOpen]);

  // State
  const [proposedJourneyManagerId, setProposedJourneyManagerId]
    = useState<number | undefined>(delegatedManagerId);
  const [showSuccessMessage, setShowSuccessMessage]
    = useState<boolean>(false);
  const [successMessage, setSuccessMessage]
    = useState<string>("");
  const [showFailedMessage, setShowFailedMessage]
    = useState<boolean>(false);
  const [failedMessage, setFailedMessage]
    = useState<string>("");

  const [userToUpdateOnModalClose, setUserToUpdateOnModalClose]
    = useState<ManagerDashboardPersonDto | undefined>(undefined);

  const handlePrimaryButtonClick = () => {
    const successCallback = (data: ResultStatus) => {
      if (data.success) {
        // Set user to be updated once the modal closes
        // Don't want to update straight away as the window will close and no success message show
        const updatedUser = {
          ...managedUser,
          delegatedManagerId: data.data.journeyManagerId,
          delegatedManagerName: data.data.journeyManagerName
        };
        setUserToUpdateOnModalClose(updatedUser);

        setShowFailedMessage(false);
        setSuccessMessage(proposedJourneyManagerId
          ? "You have assigned a Journey Manager for this employee. If you wish to make changes or remove this in the future, you can revisit this page at any time."
          : "You have removed the Journey Manager for this employee. If you aren't the original line manager for this employee then they will shortly be removed from your list of people.");
        setShowSuccessMessage(true);
      } else {
        setShowSuccessMessage(false);
        setFailedMessage(data.message);
        setShowFailedMessage(true);
      }
    };

    const errorCallback = (error: any) => {
      setShowSuccessMessage(false);
      setFailedMessage(error.message);
      setShowFailedMessage(true);
    };

    dashboardApi.updateJourneyManagerForUserId(managedUser.userId, proposedJourneyManagerId, successCallback, errorCallback);
  };

  const onPeoplePickerChange = (selectedUserId: number) => {
    setProposedJourneyManagerId(selectedUserId);
  };

  const label = !isUserCurrentlyDelegated
    ? (<span>Choose a Journey manager to assign to this employee:</span>)
    : (<span>Choose a new Journey manager to assign to this employee, or remove the Current Journey manager to assign them back to <strong>{managerFullName}</strong>:</span>);

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonText={isUserCurrentlyDelegated ? "Change" : "Assign"}
      title={title}
      onSecondaryButtonClick={() => onOpenChange(false)}
      secondaryButtonText={t("Common.Close")}
      isLoading={false}
      preventInitialAutoFocus={true}
    >
      {showSuccessMessage && (
        <SuccessAlert
          prefix={t("Common.Success")}
          message={successMessage}
          includeMarginTop={true}
        />
      )}
      {showFailedMessage && (
        <DangerAlert
          prefix={t("Common.Validation.Oops")}
          message={failedMessage}
        />
      )}
      {label}
      <PeoplePicker
        initialInput={delegatedManagerName}
        inputId="input-id"
        className="block p-2 w-full bg-gray-100 border-0 !select-none"
        onPeoplePickerChange={onPeoplePickerChange}
        userIdToExcludeFromResults={managedUser.userId}
        allowClearField={true}
        onInputEmpty={() => setProposedJourneyManagerId(undefined)}
      />
      <InfoBanner
        prefix="Please note:"
        text="Assigning a Journey manager will grant the specified manager access to all past and current Journeys, along with associated goals and tasks for this employee."
      />
    </ModalPopup>
  );
}

export default AssignDelegatedManagerPopup;
