import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../state/UserContext";
import AppRoutes from "../../routes/AppRoutes";
import FormSummaryActionButton from "./FormSummaryActionButton";

interface StandardFormSummaryButtonProps {
  formName: string;
  incompleteAnswerSetUniqueId: string | null | undefined;
  hasHistoryToShow: boolean;
  isManagerDashboard: boolean;
  isManagerViewingButHasBeenDelegated?: boolean;
  hideButton?: boolean;
  onUpdateButtonClick(): void;
  onHistoryButtonClick(): void;
}

/** The buttons displayed on a summary tab for non-dual-prep forms */
const StandardFormSummaryButtons = ({
  formName,
  incompleteAnswerSetUniqueId,
  hasHistoryToShow,
  isManagerDashboard,
  hideButton = false,
  onUpdateButtonClick,
  onHistoryButtonClick,
}: StandardFormSummaryButtonProps) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const navigateToCollabDoc = () => {
    if (incompleteAnswerSetUniqueId) {
      const redirectUrl = AppRoutes.collaborativeDocument.generateRoute(
        incompleteAnswerSetUniqueId,
        false,
        null
      );
      navigate(redirectUrl);
    }
  };

  let showTriggerDocButton = true;
  // If the form is an induction form, and the user has completed this form before,
  // we need to hide the button (as they can't choose to go and complete it again)
  if (hasHistoryToShow && userContext.user.myDashboardMode === "INDUCTION") {
    showTriggerDocButton = false;
  }

  const updateButtonClickEvent =
    incompleteAnswerSetUniqueId !== null
      ? navigateToCollabDoc
      : onUpdateButtonClick;

  return (
    <FormSummaryActionButton
      formName={formName}
      showTriggerDocButton={showTriggerDocButton}
      hideButton={hideButton}
      updateButtonClickEvent={updateButtonClickEvent}
      hasHistoryToShow={hasHistoryToShow}
      isManagerDashboard={isManagerDashboard}
      onHistoryButtonClick={onHistoryButtonClick}
    />
  );
};

export default StandardFormSummaryButtons;
