import { useEffect, useState } from "react";
import { faCircleNotch } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { t } from "i18next";

interface AnswerSaveIndicatorProps {
  isSaving: boolean;
  errored: boolean;
  didSave: boolean;
  retrySave(): void;
}

/** Display a loader when saving, a save error when it fails, and a momentary positive alert when it save successfully */
const AnswerSaveIndicator = ({
  isSaving,
  errored,
  didSave,
  retrySave,
}: AnswerSaveIndicatorProps) => {
  const [displaySaveNotification, setDisplaySaveNotification] =
    useState<boolean>(false);
  let displaySaveSuccessfulTimer: any;

  useEffect(() => {
    if (!isSaving && didSave) {
      setDisplaySaveNotification(true);
      displaySaveSuccessfulTimer = setTimeout(() => {
        setDisplaySaveNotification(false);
      }, 1500);
    }

    return () => {
      if (displaySaveSuccessfulTimer) {
        clearTimeout(displaySaveSuccessfulTimer);
      }
    };
  }, [didSave, isSaving]);

  if (!isSaving && !errored && !didSave) {
    return null;
  }

  const onRetryClick = () => {
    retrySave();
  };

  // Something to display
  const containerDefaultClassNames =
    "inline-block absolute rounded-l-md pl-2 pr-1 -right-4 mb-2";

  return (
    <div
      className={cx(
        "relative text-sm",
        isSaving || displaySaveNotification || errored ? "mb-4" : ""
      )}
    >
      {(isSaving || displaySaveNotification) && (
        <div
          className={cx(
            containerDefaultClassNames,
            "opacity-50",
            displaySaveNotification
              ? "bg-green-300 text-green-700"
              : "bg-gray-200"
          )}
        >
          {isSaving && !displaySaveNotification && (
            <>
              <FontAwesomeIcon
                icon={faCircleNotch}
                size="xs"
                className="mr-1 animate-spin"
              />
              {t("Common.Saving")}...
            </>
          )}
          {displaySaveNotification && (
            <>
              <FontAwesomeIcon icon={faCheck} size="xs" className="mr-1" />
              {t("Common.Saved")}!
            </>
          )}
        </div>
      )}
      {errored && !isSaving && (
        <div
          className={cx(containerDefaultClassNames, "bg-red-200 text-red-700")}
        >
          {t("Pages.CollaborativeDocument.Common.QuestionDidntSaveError")}{" "}
          <button className="underline cursor-pointer" onClick={onRetryClick}>
            {t("Common.TryAgain")}
          </button>
        </div>
      )}
    </div>
  );
};

export default AnswerSaveIndicator;
