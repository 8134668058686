import { AnalyticsWidgetUiDetailsDto } from "../../../types/analytics";
import { BarChartDataTransformer } from "../../../types/analytics/charts/BarCharts";
import NpsPeopleChart from "./parts/nps/NpsPeopleChart";

export interface NetPromoterScoreProps {
  widget: AnalyticsWidgetUiDetailsDto;
  onDrilldown?: undefined | ((score: number) => void);
}

function NetPromoterScore({
  widget,
  onDrilldown = undefined,
}: NetPromoterScoreProps) {
  if (!widget || !widget.datasets || widget.datasets.length === 0) return null;

  const dataTransformer = new BarChartDataTransformer();
  const barData = dataTransformer.transformData(widget);

  return (
    <div>
      <NpsPeopleChart
        scores={barData}
        widgetId={widget.widgetId}
        onDrilldown={onDrilldown}
      />
    </div>
  );
}

export default NetPromoterScore;
