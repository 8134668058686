import { t } from "i18next";
import UserNotificationDetails from "../types/dtos/notification/UserNotificationDetails";

const notificationHelper = {
  /** Processed the dates (back to local), message and href */
  processNotificationDetails: function (notificationData: UserNotificationDetails): UserNotificationDetails {
    notificationData.recentNotifications.forEach((notification) => {
      // Convert the dates from Utc back to local via JS object
      notification.dateSent = new Date(notification.dateSent);
      if (notification.dateRead != null) {
        notification.dateRead = new Date(notification.dateRead);
      }

      // Find the relevant type for the current notification
      const relevantNotificationType = notificationData.notificationTypes.find(x => x.id == notification.notificationType);
      if (relevantNotificationType != null) {
        // If found then we need to pull out the translation key to use
        const translationKey = notification.useManagerTranslationKeyForMessage 
                        ? relevantNotificationType.managerTranslationKey 
                        : relevantNotificationType.employeeTranslationKey;
        if (translationKey != null) {
          // Translate the key so that we can use the message to parse.
          let message = t(translationKey);

          // Loop over the notificationType paramaters and try to find a match
          relevantNotificationType.parameters.forEach((typeParam) => {            
            const match = notification.paramValues.find(x => x.parameterId == typeParam.id);
            if (match != null) {
              // If a match is found then check the ParamType and see if it needs to be parsed into
              // the Title or Url
              switch (typeParam.paramType) {
                case "Title": 
                  message = message.replace("{" + typeParam.paramKey + "}", match.value);
                  break;
                case "Url":
                  if (notification.href != null) {
                    notification.href = notification.href.replace("{" + typeParam.paramKey + "}", match.value);
                  } 
                  break;
              }
            }
          });

          // Set the message against the notification
          notification.message = message;
        }                        
      }
    })

    return notificationData;
  },
};

export default notificationHelper;
