export async function apiClient(
  authToken: string | undefined,
  endpoint: RequestInfo,
  options?: RequestInit,
  responseType?: "JSON" | "BLOB" | undefined
) {
  const { body, ...customConfig } = options ?? {};
  const headers = { "Content-Type": "application/json" };
  const config: RequestInit = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
      Authorization: `Bearer ${authToken}`,
    },
  };

  if (body) {
    config.body = body;
  }

  let data = undefined;
  try {
    const response = await window.fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      config
    );
    if (response.ok) {
      if (response.status === 204) {
        // Empty result, don't bother trying to parse to JSON/BLOB
        data = null;
      } else if (responseType === undefined || responseType === "JSON") {
        data = await response.json();
      } else if (responseType === "BLOB") {
        data = await response.blob();
      }
      return data;
    }
    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err && err.message ? err.message : data);
  }
}
