
import cx from "classnames";
import AdvancedTaskDto from "../../../../types/dtos/forms/AdvancedTaskDto";
import TaskManagementConfigDto from "../../../../types/dtos/forms/TaskManagementConfigDto";
import TaskStatusBadge from "./TaskStatusBadge";
import { UserContextInterface } from "../../../../state/UserContext";
import { DropDownMenu, HighlightDot } from "../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { advancedTaskHelper } from "../../../../helpers";
import { FormType } from "../../../../types/forms";
import EnforcedCommentsSection from "./EnforcedCommentsSection";

interface AdvancedTaskMobileItemProps {
  userContext: UserContextInterface;
  task: AdvancedTaskDto;
  taskType: ClientTaskType | undefined;
  config: TaskManagementConfigDto;
  isCancelled?: boolean;
  isReadOnly: boolean;
  formType: FormType;
  displayRatingColumn: boolean;
  displayActionsColumn: boolean;
  columnCount: number;
  loggedInUserId: number;
  isLoggedInUserTheSubjectUser: boolean;
  subjectUsersName: string;
  nonSubjectUsersName: string;
  onDropdownMenuItemClick: (userTaskId: string, action: string) => void;
}

function AdvancedTaskMobileItem({
  userContext,
  task,
  taskType,
  config,
  isCancelled = false,
  isReadOnly,
  formType,
  displayRatingColumn,
  displayActionsColumn,
  columnCount,
  loggedInUserId,
  isLoggedInUserTheSubjectUser,
  subjectUsersName,
  nonSubjectUsersName,
  onDropdownMenuItemClick
}: AdvancedTaskMobileItemProps) {
  /// Show enforced comments section if its enabled and isn't for cancelled tasks
  const showEnforceCommentsSection = config.enforceComments == true
    && task.enforcedComments != null
    && isCancelled == false;

  const onDropDownItemClick = (index: number, action: string) => {
    onDropdownMenuItemClick(task.taskId, action);
  }

  const btnDropDownContents = (
    <div>
      <button
        id="manager-dashboard-option"
        className="!px-4 block w-full"
      >
        <FontAwesomeIcon icon={faEllipsisVertical} className="pl-2" />
      </button>
    </div>
  );

  return (
    <div className="bg-white border-b text-gray-800 p-3">
      <div className="flex flex-row">
        <div className={cx(
          displayActionsColumn ? "basis-11/12" : "basis-12/12"
        )}>
          <TaskStatusBadge
            status={task.status}
            isNewlyCreatedInThisJourney={task.isNewlyCreatedInThisJourney}
            isOverdue={task.isOverdue}
            textSize="text-base"
          />
        </div>
        {displayActionsColumn && (
          <div className="basis-1/12">
            <DropDownMenu
              eventType="EVENT"
              items={advancedTaskHelper.getDropDownMenuOptions(task, taskType, formType)}
              onItemClick={onDropDownItemClick}
              customButtonContents={btnDropDownContents}
              menuButtonClassName="cursor-pointer"
            />
          </div>
        )}
      </div>
      <div className="pt-1">
        {advancedTaskHelper.taskIsHighlightedForUser(userContext, task, isReadOnly) && (
          <HighlightDot containerClassNames="mr-1" />
        )}
        {task.title}
      </div>
      {displayRatingColumn && (
        <div className="pt-1 italic">
          {isCancelled && (
            <>
              Rating: N/A
            </>
          )}
          {!isCancelled && (
            <>
              {task.rating == null
                ? "Rating: -"
                : `Rating: ${task.rating}/${config.ratingQuestionMaxValue}`
              }
            </>
          )}
        </div>
      )}
      {showEnforceCommentsSection
        && (
          <tr className="bg-white border-b">
            <td colSpan={columnCount}>
              <EnforcedCommentsSection
                task={task}
                taskType={taskType!}
                formType={formType}
                status={task.status}
                details={task.enforcedComments}
                contentClassName="pt-2"
                loggedInUserId={loggedInUserId}
                isLoggedInUserTheSubjectUser={isLoggedInUserTheSubjectUser}
                subjectUsersName={subjectUsersName}
                nonSubjectUsersName={nonSubjectUsersName}
                onItemAction={onDropdownMenuItemClick}
              />
            </td>
          </tr>
        )}
    </div>
  );
}

export default AdvancedTaskMobileItem;
