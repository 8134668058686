import { useContext, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { debounce } from "lodash";
import {
  FormQuestion,
  FormType,
  ValidationResult,
} from "../../../../types/forms";
import { KeyValuePair } from "../../../../types/generic";
import { RadioButtonGroup } from "../../../common";
import CollabDocFlaggedChangeDto from "../../../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import { UserBasicDetailsDto } from "../../../../types/dtos/generic";
import CollabDocFlaggedChange from "../../../collab-docs/CollabDocFlaggedChange";
import UserContext from "../../../../state/UserContext";
import MultipleChoiceOptionNumericId from "../../../../types/forms/MultipleChoiceOptions";

interface GoalReviewStatusRadiosProps {
  goalId: number;
  question: FormQuestion;
  selectedStatus: number | undefined;
  statusOptions: KeyValuePair<number, string>[];
  isReadOnly: boolean;
  showValidationErrors: boolean;
  formType: FormType;
  onChange(goalId: number, selectedOptionId: number | undefined): void;
  onBlur(goalId: number, selectedOptionId: number | undefined): void;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;
  /** Participant details are used in collab docs for flagged changes */
  participants?: Array<UserBasicDetailsDto> | undefined;
}

// Multiple choice question setup
const getMultipleChoiceOptions = (
  selectedStatus: number | undefined,
  statusOptions: KeyValuePair<number, string>[]
): MultipleChoiceOptionNumericId[] => {
  if (!statusOptions || statusOptions.length === 0) return [];
  const output = statusOptions.map((x) => ({
    allowCustomText: false,
    isSelected: selectedStatus !== undefined && x.key === selectedStatus,
    optionId: x.key,
    text: t(x.value),
    value: x.key,
  }));
  return output;
};

function GoalReviewStatusRadios({
  goalId,
  question,
  selectedStatus,
  statusOptions,
  isReadOnly,
  showValidationErrors,
  formType,
  onChange,
  onBlur,
  flaggedChanges,
  participants,
}: GoalReviewStatusRadiosProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [multipleChoiceQuestionOptions, setMultipleChoiceQuestionOptions] =
    useState<MultipleChoiceOptionNumericId[]>(
      getMultipleChoiceOptions(selectedStatus, statusOptions)
    );
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);

  // Side-effects
  useEffect(() => {
    // Update the options/selected options
    const newOptions = getMultipleChoiceOptions(selectedStatus, statusOptions);
    setMultipleChoiceQuestionOptions(newOptions);

    // Set the validation result
    if (selectedStatus) {
      setValidationResult(null);
    } else {
      setValidationResult({
        isValid: false,
        errors: [{ errorType: "REQUIRED" }],
      });
    }
  }, [goalId, selectedStatus, statusOptions]);

  // use the `debouncedHandleBlur` function to avoid sending the possibility
  // of firing multiple onBlur events (potentially causing multiple calls to the API)
  // as users change the value multiple times in quick succession
  const debouncedOnBlur = useMemo(
    () => debounce(onBlur, 500),
    [goalId, onBlur]
  );

  if (!statusOptions || statusOptions.length === 0) return null;

  /** Handle when a user selects an item to add to their selected list */
  const onRadioClicked = (
    updatedOptions: MultipleChoiceOptionNumericId[]
  ): void => {
    const selectedOptionId = updatedOptions.find((x) => x.isSelected)?.optionId;

    // Update the state
    onChange(goalId, selectedOptionId);

    // Call the API to save
    debouncedOnBlur(goalId, selectedOptionId);
  };

  // Calculated values
  const goalFlaggedChanges = flaggedChanges?.filter((x) => x.goalId === goalId);

  const statusFlaggedChange = goalFlaggedChanges?.find(
    (x) => x.changeType === "GOAL-REVIEW-STATUS"
  );

  // If there's a comment change create
  // the component so we can render it before the text field
  const flaggedChangeComponent =
    statusFlaggedChange && participants ? (
      <CollabDocFlaggedChange
        change={statusFlaggedChange}
        loggedInUserId={userContext.user.id}
        participants={participants}
        question={question}
        relatedComments={[]}
        enforcedCommentText={undefined}
      />
    ) : null;

  return (
    <div>
      <label className="font-medium">
        {t("Forms.Goals.Review.StatusRadioLabel")}
      </label>
      {flaggedChangeComponent}
      <RadioButtonGroup
        values={multipleChoiceQuestionOptions}
        isReadOnly={isReadOnly}
        onChange={onRadioClicked}
        uniqueFieldName={`goalReviewStatusRadios_${goalId}`}
        showValidationErrors={showValidationErrors}
        validationResult={validationResult}
        labelClassNames={formType === "JOURNEY" ? "text-white" : ""}
        layout="HORIZONTAL"
        containerClassNames="mt-2"
      />
    </div>
  );
}

export default GoalReviewStatusRadios;
