import { useNavigate } from "react-router-dom";
import managerDashboardApi from "../api/dashboard/managerDashboardApi";
import AppRoutes from "../routes/AppRoutes";
import { UserContextInterface } from "../state/UserContext";
import { NewOrExistingCollabDocDetails } from "../types/collab-docs";

export class dualPrepJourneyHelper {
  constructor(api: managerDashboardApi, userContext: UserContextInterface) {
    this.api = api;
    this.userContext = userContext;
  }

  readonly api: managerDashboardApi;
  readonly userContext: UserContextInterface;

  openNewCollabDocByJourneyRef(
    employeeId: number,
    journeyRef: string,    
    onSuccess: (destinationUrl: string) => void
  ): void {
    // Call the API to start a blank collab doc and redirect to it once created
    const successCallback = (data: NewOrExistingCollabDocDetails) => {
      const destinationUrl = AppRoutes.collaborativeDocument.generateRoute(
        data.answerSetUniqueId,
        false,
        null
      );

      onSuccess(destinationUrl);
    };

    const errorCallback = (error: any) => {
      console.error(error);
    };

    this.api.OpenNewCollabDocByJourneyRef(
      employeeId,
      journeyRef,
      successCallback,
      errorCallback
    );
  }  
}

export default dualPrepJourneyHelper;
