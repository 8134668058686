import * as SwitchPrimitive from "@radix-ui/react-switch";
import cx from "classnames";

interface SwitchAlternativeProps {
  uncheckedText: string;
  checkedText: string;
  checked: boolean;
  onChange(isChecked: boolean): void;
  isReadOnly?: boolean;
}

const SwitchAlternative = ({ uncheckedText, checked, checkedText, onChange, isReadOnly = false }: SwitchAlternativeProps) => {
  return (
    <div>
      <div className={cx(
        "inline-block pr-2 font-bold cursor-pointer",
        !checked ? "primary-color underline underline-offset-2" : ""
      )}
        onClick={() => onChange(false)}>
        <span>{uncheckedText}</span>
      </div>
      <div className="inline-block">
        <SwitchPrimitive.Root
          id="switch-alternative"
          checked={checked}
          disabled={isReadOnly}
          onCheckedChange={onChange}
          className={cx(
            "group",
            "inline-block",
            "relative inline-flex h-[24px] w-[44px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out",
            "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
          )}
        >
          <SwitchPrimitive.Thumb
            className={cx(
              "group-radix-state-checked:translate-x-5",
              "group-radix-state-unchecked:translate-x-0",
              "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out",
              isReadOnly ? "cursor-not-allowed" : ""
            )}
          />
        </SwitchPrimitive.Root>
      </div>
      <div className={cx(
        "inline-block pl-2 font-bold cursor-pointer",
        checked ? "accent-text underline underline-offset-2" : ""
      )}
        onClick={() => onChange(true)}>
        <span>{checkedText}</span>
      </div>
    </div>
  );
};

export default SwitchAlternative;
