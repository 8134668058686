import BarChart, { BarChartProps } from "./BarChart";

export function HorizontalBarChart({
  widget,
  isExportingImage,
  onBarClick,
}: BarChartProps) {
  return (
    <BarChart
      layout="HORIZONTAL"
      widget={widget}
      isExportingImage={isExportingImage}
      onBarClick={onBarClick}
    />
  );
}

export function VerticalBarChart({
  widget,
  isExportingImage,
  onBarClick,
}: BarChartProps) {
  return (
    <BarChart
      layout="VERTICAL"
      widget={widget}
      isExportingImage={isExportingImage}
      onBarClick={onBarClick}
    />
  );
}
