import NotificationMenu from "../common/NotificationMenu";
import Avatar from "../common/Avatar";
import UserContext from "../../state/UserContext";
import { useContext, useEffect, useState } from "react";
import { DropDownMenu } from "../common";
import NonPrimaryNavItems from "../../routes/NonPrimaryNavItems";
import AppRoutes from "../../routes/AppRoutes";

function ProfileNav() {
  const userContext = useContext(UserContext);
  const [hasLegacyReviews, setHasLegacyReviews] = useState<boolean>(false);

  const reviewHistoryObject = {
    text: "Nav.Profile.ReviewHistory",
    href: AppRoutes.reviewHistory.path,
  };

  const navItemsToShow = [...NonPrimaryNavItems];

  useEffect(() => {
    setHasLegacyReviews(userContext.user.legacyDetails.selfReviews);
  }, [userContext.user.legacyDetails]);

  // Conditionally add the historical reviews link if the user has legacy reviews
  if (
    hasLegacyReviews &&
    navItemsToShow.find((navItem) => navItem.text.includes("ReviewHistory")) ===
      undefined
  ) {
    // the logout option should always be last
    const reviewHistroyPosition = navItemsToShow.length - 1;
    navItemsToShow.splice(reviewHistroyPosition, 0, reviewHistoryObject);
  }

  // Conditionally add the demo reset link if the user is a demo user
  if (
    userContext.user.isManager &&
    userContext.user.client.enabledModules.indexOf("Demo Features") > -1
  ) {
    navItemsToShow.unshift({
      text: "Reset Demo",
      href: AppRoutes.demo.managerReset,
    });
  }

  return (
    <div className="flex flex-row items-center">
      <NotificationMenu
        defaultNotificationData={userContext.user.notifications}
      />
      <Avatar
        userFullName={userContext.user.firstName}
        userInitials={userContext.user.initials}
        size={10}
        imageUrl={userContext.user.profilePic}
      />
      <DropDownMenu eventType="NAVIGATE" items={navItemsToShow} />
    </div>
  );
}

export default ProfileNav;
