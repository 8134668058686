import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import simpleTasksApi from "../../api/task/simpleTasksApi";
import { taskTypeHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import TaskCommentsApiResponseDto from "../../types/dtos/tasks/TaskCommentsApiResponseDto";
import { ValidationResult } from "../../types/forms";
import { TaskType } from "../../types/tasks";
import {
  EditableAction,
  EditableGoal,
  EditableLearningAction,
  EditableTask,
  TaskIdentifierType,
} from "../../types/tasks/EditableTasks";
import NewTaskComment from "../../types/tasks/NewTaskComment";
import TaskCommentDto from "../../types/tasks/TaskCommentDto";
import { AlertPopup, GenericDropDownList, Label, ModalPopup } from "../common";
import SmallLoader from "../loaders/SmallLoader";
import EditActionForm from "./EditActionForm";
import EditGoalForm from "./EditGoalForm";
import EditLearningActionForm from "./EditLearningActionForm";
import TaskCommentsList from "./TaskCommentsList";
import { ReactComponent as NoFilesImage } from "../../images/noFiles.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheck,
  faComment,
} from "@fortawesome/pro-regular-svg-icons";
import myDashboardApi from "../../api/dashboard/myDashboardApi";
import NewTaskCommentForm from "./NewTaskCommentForm";
import SuccessAlert from "../alerts/SuccessAlert";
import InfoAlert from "../alerts/InfoAlert";

const taskTypeInputId = "task-type-select";

/** Get the correct translation key identifier for the task type and popup mode (add/edit) */
const getModalTitle = (
  taskType: TaskType | undefined,
  popupMode: TaskPopupMode,
  isReadOnly: boolean
) => {
  let suffix = popupMode === "ADD" ? "Add" : "Edit";
  if (isReadOnly) {
    suffix = "View";
  }
  let titleTranslationKey = "Tasks.Popup.Titles.";

  if (taskType) {
    switch (taskType) {
      case "ACTION":
        titleTranslationKey += "Action";
        break;
      case "GOAL":
        titleTranslationKey += "Goal";
        break;
      case "LEARNING":
        titleTranslationKey += "Learning";
        break;
      default:
        titleTranslationKey += "Task";
        break;
    }
  } else {
    titleTranslationKey += "Task";
  }

  return t(titleTranslationKey + suffix);
};

export type TaskPopupMode = "ADD" | "EDIT";
export type TaskPopupOrigin = "DASHBOARD" | "COLLAB-DOC" | "JOURNEY";

interface ManageTaskPopupProps {
  /** Whether or not the modal popup is open */
  isOpen: boolean;
  /** Handle modal closing in the parent state */
  onOpenChange(open: boolean): void;
  /** If not specified, the user will be able to choose which task type to add */
  taskType?: TaskType | undefined;
  /** Whether to show in "add" mode or "edit" mode */
  mode: TaskPopupMode;
  /** Where the popup has come from */
  popupOrigin: TaskPopupOrigin;
  /** What to do when the user saves the modal popup */
  onSaveClick(task: EditableTask<TaskIdentifierType>): void;
  /** The task to edit */
  editObject?: EditableTask<number> | null;
  isReadOnly?: boolean;
  setIsReadOnly?(value: boolean): void;
  /**A function to call once you complete or cancel this task */
  refreshParentTaskList(): void;
  /** Whether or not to display buttons to allow the user to cancel/complete tasks */
  displayCancelAndDeleteButtons?: boolean;
  /** E.g. when a goal is under review, and editing should be locked */
  taskLockedByJourney?: string | null;
  disableCommentsTab?: boolean;
}

function ManageTaskPopup({
  isOpen,
  onOpenChange,
  taskType,
  mode,
  editObject,
  popupOrigin,
  onSaveClick,
  refreshParentTaskList,
  isReadOnly = false,
  setIsReadOnly,
  displayCancelAndDeleteButtons = true,
  taskLockedByJourney = null,
  disableCommentsTab = false,
}: ManageTaskPopupProps) {
  // Auth/API
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const tasksApi = new simpleTasksApi(auth.user?.access_token);
  // Refs
  const commentFormInputRef = useRef<HTMLTextAreaElement>(null);

  // Context
  const userContext = useContext(UserContext);

  // State
  const [modalTitle, setModalTitle] = useState<string>(
    getModalTitle(taskType, mode, isReadOnly)
  );
  const [selectedTaskType, setSelectedTaskType] = useState<
    TaskType | undefined
  >(taskType);
  const [taskObject, setTaskObject] = useState<EditableTask<number> | null>(
    null
  );
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const [inCommentsMode, setInCommentsMode] = useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
  const [hasLoadedComments, setHasLoadedComments] = useState<boolean>(false);
  const [taskComments, setTaskComments] = useState<TaskCommentDto[]>([]);
  const [taskCommentsParticipants, setTaskCommentsParticipants] = useState<
    UserBasicDetailsDto[]
  >([]);

  const [showDeleteTaskConfirmationModal, setShowDeleteTaskConfirmationModal] =
    useState<boolean>(false);
  const [taskIsComplete, setTaskIsComplete] = useState<boolean>(false);
  const [taskIdToEdit, setTaskIdToEdit] = useState<number | null>(null);
  const commentsListBottomRef = useRef<null | HTMLDivElement>(null);

  // Lifecycle

  // Initial mount
  const loadEditDetails = () => {
    setTaskObject(editObject ? editObject : null);
    setSelectedTaskType(editObject ? editObject.taskType : undefined);
    setTaskIdToEdit(editObject ? editObject.taskId : null);
  };

  useEffect(() => {
    // Set the task details to edit, if a task was supplied
    loadEditDetails();
  }, []);

  useEffect(() => {
    // When the task to edit changes
    loadEditDetails();
  }, [editObject]);

  // Reset the modal when it closes
  useEffect(() => {
    // If no task type was specified, clear the selection
    if (!isOpen && taskType === undefined) {
      setSelectedTaskType(undefined);
    } else if (isOpen && taskType) {
      setSelectedTaskType(taskType);
    }

    setShowValidationErrors(false);
  }, [isOpen]);

  // When the selectedTaskType changes
  useEffect(() => {
    setModalTitle(getModalTitle(selectedTaskType, mode, isReadOnly));
    setShowValidationErrors(false);
  }, [selectedTaskType, mode, isReadOnly]);

  // Events

  //Call the api to delete the task
  const onConfirmDelete = () => {
    if (taskIdToEdit) {
      const successCallback = (data: boolean | null) => {
        refreshParentTaskList();
        setShowDeleteTaskConfirmationModal(false);
        onOpenChange(false);
      };
      const errorCallback = (error: any) => {
        console.log(error);
      };
      dashboardApi.DeleteTaskFromDashboard(
        taskIdToEdit,
        successCallback,
        errorCallback
      );
    }
  };

  const onCancelDelete = () => {
    setShowDeleteTaskConfirmationModal(false);
  };

  /*TODO: Currently there isnt a place to see completed tasks, however if we want that in the future then we can pass in whether it is completed or not and toggle*/
  const toggleCompleteTask = () => {
    if (taskIdToEdit) {
      const successCallback = (data: boolean | null) => {
        setTaskIsComplete(!taskIsComplete);
        refreshParentTaskList();

        if (setIsReadOnly) {
          setIsReadOnly(true);
        }
      };
      const errorCallback = (error: any) => {
        console.log(error);
      };
      dashboardApi.toggleCompleteTaskFromDashboard(
        taskIdToEdit,
        taskIsComplete,
        successCallback,
        errorCallback
      );
    }
  };

  const handleTaskTypeChange = (selectedValue: string | null) => {
    const newTaskType =
      selectedValue !== null ? (selectedValue as TaskType) : undefined;
    setSelectedTaskType(newTaskType);
  };

  const onDeleteTaskClick = (taskId: number | null) => {
    if (taskId) {
      setShowDeleteTaskConfirmationModal(true);
    }
  };

  const handlePrimaryButtonClick = () => {
    const taskIsValid = taskObject && taskObject.isValid();

    if (selectedTaskType === undefined || !taskIsValid) {
      setShowValidationErrors(true);
      return;
    }

    // Validate the goal category field, if one was displayed
    if (
      selectedTaskType === "GOAL" &&
      !(taskObject as EditableGoal<TaskIdentifierType>).goalCategoryIsValid(
        userContext.user.appLvl.goalCategories
      )
    ) {
      setShowValidationErrors(true);
      return;
    }

    // Reset to not display any validation issues
    setShowValidationErrors(false);

    // Add the new task to the list
    onSaveClick(taskObject);

    // Reset the form
    setTaskObject(null);

    // Close the modal
    onOpenChange(false);
  };

  const handleModalPopupClose = (value: boolean) => {
    onOpenChange(value);
    toggleInCommentMode(value);

    setIsLoadingComments(false);
    setHasLoadedComments(false);
    setTaskComments([]);
    setTaskCommentsParticipants([]);
    setTaskIsComplete(false);
    setTaskIdToEdit(null);
  };

  const toggleInCommentMode = (value: boolean) => {
    // If comment mode has been toggled but the comments not loaded then hit the API for the data
    if (value && !hasLoadedComments) {
      setIsLoadingComments(true);

      let taskId = 0;
      if (
        editObject !== null &&
        editObject !== undefined &&
        editObject.taskId
      ) {
        taskId = editObject!.taskId as number;
      }

      tasksApi.getComments(
        taskId,
        (data: TaskCommentsApiResponseDto) => {
          setTaskComments(data.comments);
          setTaskCommentsParticipants(data.participants);
          setIsLoadingComments(false);
          setHasLoadedComments(true);
          commentsListBottomRef.current?.scrollIntoView({ behavior: "smooth" });
        },
        (error: any) => {
          console.log("Error getting comments", error);
        }
      );
    }

    setInCommentsMode(value);
  };

  const onCommentAdd = (
    newComment: NewTaskComment,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    tasksApi.submitNewComment(
      newComment,
      (newComment: TaskCommentDto) => {
        const nextState = [...taskComments];
        nextState.push(newComment);
        setTaskComments(nextState);
        successCallback();
        commentsListBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      },
      (error: any) => {
        console.log("Error saving new comment", error);
        errorCallback();
      }
    );
  };

  const onCommentDelete = (commentId: number) => {
    // Delete the comment
    tasksApi.deleteComment(
      commentId,
      () => {
        const nextState = taskComments.filter((x) => x.id !== commentId);
        setTaskComments(nextState);
      },
      (error: any) => {
        console.log("Error deleting comment", error);
      }
    );
  };

  // Display values

  let formComponent = null;
  if (selectedTaskType) {
    switch (selectedTaskType) {
      case "ACTION":
        formComponent = (
          <EditActionForm
            editObject={taskObject as EditableAction<number>}
            onChange={setTaskObject}
            showValidationErrors={showValidationErrors}
            isReadOnly={isReadOnly}
          />
        );
        break;
      case "GOAL":
        formComponent = (
          <EditGoalForm
            editObject={taskObject as EditableGoal<number>}
            onChange={setTaskObject}
            showValidationErrors={showValidationErrors}
            isReadOnly={isReadOnly}
          />
        );
        break;
      case "LEARNING":
        formComponent = (
          <EditLearningActionForm
            editObject={taskObject as EditableLearningAction<number>}
            onChange={setTaskObject}
            showValidationErrors={showValidationErrors}
            isReadOnly={isReadOnly}
          />
        );
        break;
    }
  }

  const completeButtonClassName = taskIsComplete
    ? "bg-green-600 text-white font-medium text-center border drop-shadow-sm py-1 px-3 m-0 rounded-md cursor-pointer"
    : "bg-transparent text-gray-300 font-medium text-center border border-gray-300 drop-shadow-sm py-1 px-3 m-0 rounded-md cursor-pointer hover:text-green-600 hover:border-green-600";
  const completeButtonText = taskIsComplete ? "Completed" : "Mark as Complete";

  // On open, check the mode for 'EDIT' and set the header button component appropriately
  const commentModeComponent =
    isOpen && mode === "EDIT" && !disableCommentsTab ? (
      inCommentsMode ? (
        <button
          className="mr-1"
          onClick={() => toggleInCommentMode(!inCommentsMode)}
        >
          Back to details
        </button>
      ) : (
        <div
          onClick={() => toggleInCommentMode(!inCommentsMode)}
          className="bg-transparent text-gray-300 font-medium text-center border border-gray-300 drop-shadow-sm py-1 px-3 m-0 rounded-md cursor-pointer hover:text-gray-500 hover:border-gray-500"
        >
          <FontAwesomeIcon icon={faComment} size="1x" />
        </div>
      )
    ) : null;

  // The options to display in the task type dropdown
  const taskTypeDropDownItems = taskTypeHelper.getTaskTypesAsKeyValuePairs();

  const taskTypeValidationResult = selectedTaskType
    ? null
    : new ValidationResult(false, [{ errorType: "REQUIRED" }]);

  const showFooterButtons = isReadOnly || inCommentsMode;

  const headerComponents = (
    <>
      {taskObject?.taskId && (
        <div className="flex flex-row justify-end gap-2">
          {commentModeComponent}
          {displayCancelAndDeleteButtons &&
            !isReadOnly &&
            !taskLockedByJourney &&
            !inCommentsMode && (
              <>
                <div
                  onClick={() => onDeleteTaskClick(taskObject.taskId)}
                  className="bg-transparent text-gray-300 font-medium text-center border border-gray-300 drop-shadow-sm py-1 px-3 m-0 rounded-md cursor-pointer hover:text-rose-700 hover:border-rose-700"
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </div>
                <div
                  onClick={() => toggleCompleteTask()}
                  className={completeButtonClassName}
                >
                  <FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" />
                  {completeButtonText}
                </div>
              </>
            )}
        </div>
      )}
      {isReadOnly && taskObject?.dateCompleted && (
        <div>
          <SuccessAlert
            prefix=""
            message={
              "Completed on " +
              new Date(taskObject.dateCompleted).toLocaleDateString()
            }
          />
        </div>
      )}
    </>
  );

  const lockedForEditingWarning = taskLockedByJourney ? (
    <div className="mb-2">
      <InfoAlert
        prefix=""
        message="This task is under review in a Journey so cannot be edited until that is completed"
      />
    </div>
  ) : null;

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={handleModalPopupClose}
      title={modalTitle}
      allowYOverflow={false}
      onPrimaryButtonClick={
        showFooterButtons ? undefined : () => handlePrimaryButtonClick()
      }
      primaryButtonText={t("Common.Submit")}
      onSecondaryButtonClick={
        showFooterButtons ? undefined : () => handleModalPopupClose(false)
      }
      secondaryButtonText={showFooterButtons ? undefined : t("Common.Cancel")}
      alertOpenedWithinModal={showDeleteTaskConfirmationModal}
    >
      <>
        {/* Scrollable Content */}
        <div>
          {inCommentsMode && (
            <>
              {isLoadingComments && (
                <div className="grow">
                  <div className="pt-2">
                    <SmallLoader />
                    <p className="text-center pt-1 text-[#959595] text-sm">
                      Loading comments...
                    </p>
                  </div>
                </div>
              )}

              {!isLoadingComments && (
                <>
                  {headerComponents}
                  {taskComments.length == 0 && (
                    <div className="flex justify-center flex-col text-center text-gray-400 mb-4 pt-3">
                      <div className="flex flex-row">
                        <p className="w-1/3">&nbsp;</p>
                        <NoFilesImage
                          className="justify-center w-1/3"
                          width={"6rem"}
                          height={"6rem"}
                        />
                        <p className="w-1/3">&nbsp;</p>
                      </div>
                      <p className="pt-3">There are currently no comments</p>
                    </div>
                  )}

                  {taskComments.length > 0 && (
                    <div className="mt-2 space-y-2 text-gray-500 max-h-64 overflow-y-auto">
                      <TaskCommentsList
                        isReadOnly={isReadOnly}
                        comments={taskComments}
                        participants={taskCommentsParticipants}
                        onDeleteComment={onCommentDelete}
                        containerClassNames="mr-2"
                      />
                      <div ref={commentsListBottomRef} />
                    </div>
                  )}

                  {/* Add new comments */}
                  {!isReadOnly &&
                    popupOrigin != "JOURNEY" &&
                    popupOrigin != "COLLAB-DOC" &&
                    editObject !== null &&
                    editObject !== undefined &&
                    editObject.taskId && (
                      <NewTaskCommentForm
                        taskId={editObject!.taskId as number}
                        onSubmit={onCommentAdd}
                        inputRef={commentFormInputRef}
                      />
                    )}
                </>
              )}
            </>
          )}
          {!inCommentsMode && (
            <>
              <div className="mt-2 space-y-2 text-gray-500 max-h-max overflow-y-auto">
                {headerComponents}
                {lockedForEditingWarning}
                {!taskType && (
                  <>
                    <div className="mb-2">
                      <Label
                        htmlFor={taskTypeInputId}
                        text={t("Tasks.Common.TaskType")}
                      />
                      <GenericDropDownList
                        currentValue={
                          selectedTaskType ? selectedTaskType : null
                        }
                        items={taskTypeDropDownItems}
                        onChange={handleTaskTypeChange}
                        className="block w-full"
                        bgColorClassName="bg-gray-100"
                        inputId={taskTypeInputId}
                        showValidationErrors={showValidationErrors}
                        validationResult={taskTypeValidationResult}
                        isReadOnly={isReadOnly}
                      />
                    </div>
                  </>
                )}
                {formComponent}
                {/* Delete Task Confirmation */}
                <AlertPopup
                  isOpen={showDeleteTaskConfirmationModal}
                  onOpenChange={setShowDeleteTaskConfirmationModal}
                  onPrimaryButtonClick={onConfirmDelete}
                  onSecondaryButtonClick={onCancelDelete}
                  primaryButtonText={t("Common.ConfirmDelete")}
                  secondaryButtonText={t("Common.CancelAction")}
                  bodyText={t(
                    "Pages.CollaborativeDocument.Controls.CommentDeleteBodyText"
                  )}
                  title={t(
                    "Pages.CollaborativeDocument.Controls.CommentDeleteHeading"
                  )}
                />
              </div>
            </>
          )}
        </div>

        {/* If in read only mode the buttons at the bottom won't appear so this makes it look a little better in that scenario */}
        {isReadOnly && (
          <div className="mt-4 pt-4 text-right border-t border-gray-100">
            &nbsp;
          </div>
        )}
      </>
    </ModalPopup>
  );
}

export default ManageTaskPopup;
