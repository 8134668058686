import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird, faTimes } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";
import React from "react";

interface ModalPopupProps {
  children: React.ReactNode;
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  /** Buttons appearing for the popup are optional. To display the primary/secondary buttons make sure to set the relevant button text and click events */
  onPrimaryButtonClick?(): void; // Optional - See above
  onSecondaryButtonClick?(): void; // Optional - See above
  primaryButtonText?: string; // Optional - See above
  /**Optional. Will show a loading spinner in the primary button if the modal is saving*/
  isLoading?: boolean;
  secondaryButtonText?: string; // Optional - See above
  /** Pass a null title to not show the title bar */
  title: string | null;
  titleDescription?: string;
  /** Whether or not to display the `x` button to close the modal */
  showCloseIcon?: boolean;
  allowYOverflow?: boolean;
  bodyRef?: React.LegacyRef<HTMLDivElement> | undefined;
  isPrimaryButtonDisabled?: boolean;
  /** Optional - Essentially used in place of the primary/secondary buttons for more required scenarios */
  footerComponent?: JSX.Element;
  alertOpenedWithinModal?: boolean;
  width?: "NORMAL" | "SMALL";
  preventInitialAutoFocus?: boolean;

  /** To prevent the modal from closing when you click outside the box we need to:
   * - provide: onOpenChange as () => {}
   * - Set onAlternativeOpenChange to what you want the 'X' icon to do instead
   */
  onAlternativeOpenChange?(open: boolean): void;
}

const ModalPopup = ({
  children,
  isOpen,
  onOpenChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  title,
  titleDescription,
  showCloseIcon = true,
  allowYOverflow = true,
  bodyRef = undefined,
  isPrimaryButtonDisabled = false,
  footerComponent,
  alertOpenedWithinModal = false,
  isLoading,
  width = "NORMAL",
  preventInitialAutoFocus = false,
  onAlternativeOpenChange = undefined,
}: ModalPopupProps) => {
  let zIndexClassName = "z-50";
  if (alertOpenedWithinModal) {
    zIndexClassName = "z-100";
  }

  const widthCssClass = width === "NORMAL" ? "md:w-2/3" : "md:w-1/2";

  const onOpenAutoFocus = (e: any) => {
    if (preventInitialAutoFocus) {
      e.preventDefault();
    }
  };

  const closeIcon = (
    <FontAwesomeIcon
      icon={faTimes}
      size="lg"
      className="text-gray-500 hover:text-gray-700"
    />
  );

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPrimitive.Portal container={document.body}>
        <DialogPrimitive.Overlay
          forceMount
          className="fixed inset-0 z-20 bg-black/50"
        />
        <DialogPrimitive.Content
          onOpenAutoFocus={onOpenAutoFocus}
          forceMount
          className={cx(
            `fixed ${zIndexClassName}`,
            "w-[95vw] rounded-md p-4 px-6 md:px-10",
            widthCssClass,
            "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
            "tall:top-[40%] tall:-translate-y-[40%]",
            "bg-white",
            "focus:outline-none"
          )}
        >
          {title !== null && title.length > 0 && (
            <>
              <DialogPrimitive.Title className="text-2xl font-semibold text-gray-700 border-b border-gray-100 text-center py-4 truncate">
                {title}
              </DialogPrimitive.Title>
              {titleDescription && (
                <DialogPrimitive.Description className="mt-2 text-sm font-normal text-gray-700 ">
                  {titleDescription}
                </DialogPrimitive.Description>
              )}
            </>
          )}

          <div
            className={cx(
              "mt-2 space-y-2 text-gray-500 max-h-[500px] tall:max-h-[700px] xtall:max-h-[800px]",
              allowYOverflow ? "overflow-y-auto" : ""
            )}
            ref={bodyRef}
          >
            {children}
          </div>

          {footerComponent && (
            <div className="mt-4 pt-4 text-right border-t border-gray-100">
              {footerComponent}
            </div>
          )}

          {/* Renders primary or/and secondary button as long as their text + click events are given */}
          {((secondaryButtonText && onSecondaryButtonClick) ||
            (primaryButtonText && onPrimaryButtonClick)) && (
            <div className="mt-4 pt-4 text-right border-t border-gray-100">
              {secondaryButtonText && onSecondaryButtonClick && (
                <button
                  className="inline-block btn-secondary m-2"
                  onClick={onSecondaryButtonClick}
                >
                  {secondaryButtonText}
                </button>
              )}
              {primaryButtonText && onPrimaryButtonClick && (
                <button
                  className="inline-block btn-primary m-2"
                  onClick={onPrimaryButtonClick}
                  disabled={isPrimaryButtonDisabled}
                >
                  {primaryButtonText}
                  {isLoading === true && (
                    <FontAwesomeIcon
                      icon={faSpinnerThird}
                      spin
                      className="loader-icon"
                      style={{ color: "#fff" }}
                    />
                  )}
                </button>
              )}
            </div>
          )}

          {showCloseIcon && (
            <>
              {!onAlternativeOpenChange && (
                <DialogPrimitive.Close
                  className={cx(
                    "absolute top-3.5 right-3.5 inline-flex items-center justify-center rounded-full p-1",
                    "focus:outline-none"
                  )}
                >
                  {closeIcon}
                </DialogPrimitive.Close>
              )}
              {onAlternativeOpenChange && (
                <div
                  className="absolute top-3.5 right-3.5 inline-flex cursor-pointer items-center justify-center rounded-full p-1 focus:outline-none"
                  onClick={() => onAlternativeOpenChange(false)}
                >
                  {closeIcon}
                </div>
              )}
            </>
          )}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default ModalPopup;
