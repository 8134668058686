import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const ttUserLanguageDetector = {
  name: "ttUserLanguageDetector",

  lookup(options: any): string {
    // options -> are passed in options
    return "en-GB";
  },

  cacheUserLanguage(lng: any, options: any): void {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  },
};

// Define the custom language detector which hooks into details
// of the currently authenticated user
const ttCultureDetector = new LanguageDetector();
ttCultureDetector.addDetector(ttUserLanguageDetector);

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(ttCultureDetector)
  .init({
    backend: {
      // Config options docs: https://github.com/i18next/i18next-http-backend
      loadPath: `${process.env.REACT_APP_I18N_BASE_URL}/en-GB/{{ns}}.json`, // /{{lng}}/{{ns}}.json`, (Couldn't get language to be en-GB instead of en)
      crossDomain: true,
    },

    detection: { order: ["ttUserLanguageDetector"] },

    fallbackLng: "en-GB",

    ns: ["translations"],

    debug: false, // Set to true to enable logging of missing translations

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
