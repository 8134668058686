import { Reducer, useReducer, useState } from "react";
import { KeyValuePair } from "../../../types/generic";
//@ts-ignore
import Pagination from "react-js-pagination";
import UserDetailJourneyDto from "../../../types/dtos/admin/UserDetailJourneyDto";
import SearchField from "../../common/SearchField";
import React from "react";
import UserContext from "../../../state/UserContext";
import EmptyFilteredState from "../../../images/EmptyFilteredState";
import { useTranslation } from "react-i18next";
import { UserDetailDropdownOptionAction } from "../../../types/admin/UserDetailDropdownOptionAction";
import UserDetailJourneyOptionPopup from "./UserDetailJourneyOptionPopup";
import UserDetailJourneyHistoryItem from "./UserDetailJourneyHistoryItem";
import AppRoutes from "../../../routes/AppRoutes";
import UserDetailDto from "../../../types/dtos/admin/UserDetailDto";
import adminApi from "../../../api/dashboard/adminApi";
import { useAuth } from "react-oidc-context";
import SuccessAlert from "../../alerts/SuccessAlert";
import DangerAlert from "../../alerts/DangerAlert";
import UserDetailJourneyApiResponseDto from "../../../types/dtos/admin/UserDetailJourneyApiResponseDto";
import PaginationAndSearchCriteria from "../../../types/admin/PaginationAndSearchCriteria";
import UserDetailJourneyAnswerSetDto from "../../../types/dtos/admin/UserDetailJourneyAnswerSetDto";
import { ClientSentJourneyApprovalFlow } from "../../../types/admin/ClientSentJourneyApprovalFlow";

interface UserDetailJourneySectionProps {
  user: UserDetailDto;
  journeyHistory?: UserDetailJourneyDto[];
  searchTerm: string | null;
  pageSize: number;
  pageNumber: number;
  totalItemCount: number | undefined;
  isLoading: boolean;
  allowViewOption: boolean;
  onPageNumberChange(pageNumber: number): void;
  onSearchTermChange(searchTerm: string | null): void;
  criteriaForReload: PaginationAndSearchCriteria;
  reloadJourneyHistorySuccessCallback(
    data: UserDetailJourneyApiResponseDto
  ): void;
  onUpdateCurrentJourneyDetails(
    journeyReference: string | undefined,
    journeyName: string | undefined
  ): void;
  onUpdateAlreadySentJourneyReferences(journeyReference: string): void;
}

interface PopupState {
  isOpen: boolean;
  option: UserDetailDropdownOptionAction;
  titleText: string;
  primaryButtonText: string;
  selectedViewDropdownOption: KeyValuePair<string, string> | null;
  viewDropdownOptions: KeyValuePair<string, string>[] | null;
  userName: string | null;
  managerRoleName: string | null;
  journeyId: number | null; // This is either the answerSetId or dualPrepAnswerSetId
  journeyReference: string | null;
  journeyName: string | null;
  isJourneyDualPrep: boolean | null;
  selectedSendReminderDropdownOption: KeyValuePair<string, string> | null;
  sendReminderDropdownOptions: KeyValuePair<string, string>[] | null;
  reopenShowMeetingFormWarning: boolean | null;
  selectedReopenDropdownOption: KeyValuePair<string, string> | null;
  reopenDropdownOptions: KeyValuePair<string, string>[] | null;
  meetingDocForReopenCalculation?: UserDetailJourneyAnswerSetDto | null;
  enforceUserConfirmation: boolean;
  approvalFlow: ClientSentJourneyApprovalFlow | null;
}

const getDefaultPopupState = (): PopupState => {
  return {
    isOpen: false,
    option: "SEND-REMINDER",
    titleText: "Send Reminder",
    primaryButtonText: "Confirm",
    selectedViewDropdownOption: null,
    viewDropdownOptions: null,
    userName: null,
    managerRoleName: null,
    journeyId: null,
    journeyReference: null,
    journeyName: null,
    isJourneyDualPrep: null,
    selectedSendReminderDropdownOption: null,
    sendReminderDropdownOptions: null,
    reopenShowMeetingFormWarning: null,
    selectedReopenDropdownOption: null,
    reopenDropdownOptions: null,
    meetingDocForReopenCalculation: null,
    enforceUserConfirmation: false,
    approvalFlow: null,
  };
};

function UserDetailJourneySection({
  user,
  journeyHistory,
  searchTerm,
  pageSize,
  pageNumber,
  totalItemCount,
  isLoading,
  allowViewOption,
  onPageNumberChange,
  onSearchTermChange,
  criteriaForReload,
  reloadJourneyHistorySuccessCallback,
  onUpdateCurrentJourneyDetails,
  onUpdateAlreadySentJourneyReferences,
}: UserDetailJourneySectionProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const userContext = React.useContext(UserContext);
  const [popupState, setPopupState] = useReducer<
    Reducer<PopupState, Partial<PopupState>>
  >((state, newState) => ({ ...state, ...newState }), getDefaultPopupState());

  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [successNotificationMessage, setSuccessNotificationMessage] =
    useState<string>("");
  const [showErrorNotification, setShowErrorNotification] =
    useState<boolean>(false);
  const [errorNotificationMessage, setErrorNotificationMessage] =
    useState<string>("");

  const triggerSuccessNotification = (message: string) => {
    setShowSuccessNotification(true);
    setSuccessNotificationMessage(message);
    setShowErrorNotification(false); // Reset Error Notification
    setErrorNotificationMessage(""); // Reset Error Notification
    handleClosePopup();
  };

  const triggerErrorNotification = (message: string) => {
    setShowErrorNotification(true);
    setErrorNotificationMessage(message);
    setShowSuccessNotification(false); // Reset Success Notification
    setSuccessNotificationMessage(""); // Reset Success Notification
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setPopupState(getDefaultPopupState());
  };

  const onDropdownChange = (
    journey: UserDetailJourneyDto,
    newValue: UserDetailDropdownOptionAction
  ) => {
    switch (newValue) {
      case "SEND-REMINDER":
        const sendReminderDropdownOptions =
          CreateSendReminderDropdownOptions(user);
        setPopupState({
          isOpen: true,
          option: newValue,
          titleText: t("Pages.Admin.UserDetails.PopupTitle.SendReminder"),
          primaryButtonText: t("Pages.Admin.Common.Send"),
          userName: user.fullName,
          managerRoleName: user.journeyManagerId
            ? user.journeyManagerName
            : user.managerName,
          journeyReference: journey.journeyReference,
          journeyName: journey.title,
          selectedSendReminderDropdownOption: sendReminderDropdownOptions[0],
          sendReminderDropdownOptions: sendReminderDropdownOptions,
        });
        break;
      case "VIEW":
        // Check how many answerSets there, if there is only one then go directly there
        if (journey.answerSets.length === 1) {
          const redirectUrl =
            AppRoutes.collaborativeDocument.viewCollabDoc.replace(
              ":answerSetUniqueId",
              journey.answerSets[0].guidId
            );
          window.open(redirectUrl, "_blank");
          // Else load in the popup for the user to choose
        } else {
          // Create the view dropdown options, if there are any then show the popup with the options
          const viewDropdownOptions = CreateViewDropdownOptions(journey);
          if (viewDropdownOptions.length !== 0) {
            setPopupState({
              isOpen: true,
              option: newValue,
              titleText: t("Pages.Admin.UserDetails.PopupTitle.ViewAnswers"),
              primaryButtonText: t("Pages.Admin.Common.Go"),
              selectedViewDropdownOption: viewDropdownOptions[0],
              viewDropdownOptions: viewDropdownOptions,
            });
          }
        }
        break;
      case "REOPEN":
        const reopenDropdownOptions = CreateReopenDropdownOptions(journey);
        const meetingDocAnswerSet = journey.answerSets.find(
          (answerSet) => answerSet.isDualPrepMeetingDocAnswerSet
        );

        // Trigger the reopen warning (and enforce confirmation) if the meeting doc exists
        // and meeting doc is not the selected option
        const triggerReopenWarningAndEnforce =
          meetingDocAnswerSet != undefined
            ? reopenDropdownOptions &&
              meetingDocAnswerSet.id.toString() != reopenDropdownOptions[0].key
              ? true
              : false
            : false;

        setPopupState({
          isOpen: true,
          option: newValue,
          titleText: t("Pages.Admin.UserDetails.PopupTitle.ReopenJourney"),
          primaryButtonText: t("Pages.Admin.Common.Confirm"),
          userName: user.fullName,
          journeyId: journey.id,
          journeyReference: journey.journeyReference,
          journeyName: journey.title,
          isJourneyDualPrep: journey.isDualPrepJourney,
          reopenShowMeetingFormWarning: triggerReopenWarningAndEnforce,
          reopenDropdownOptions: reopenDropdownOptions,
          selectedReopenDropdownOption: reopenDropdownOptions[0],
          meetingDocForReopenCalculation: meetingDocAnswerSet,
          enforceUserConfirmation:
            !journey.isDualPrepJourney || triggerReopenWarningAndEnforce,
        });
        break;
      case "CANCEL":
        setPopupState({
          isOpen: true,
          option: newValue,
          titleText: t("Pages.Admin.UserDetails.PopupTitle.CancelJourney"),
          primaryButtonText: t("Pages.Admin.Common.Confirm"),
          userName: user.fullName,
          journeyId: journey.id,
          journeyReference: journey.journeyReference,
          journeyName: journey.title,
          isJourneyDualPrep: journey.isDualPrepJourney,
          enforceUserConfirmation: true,
        });
        break;
    }
  };

  const handlePopupPrimaryButtonClick = () => {
    switch (popupState.option) {
      case "SEND-REMINDER":
        performSendReminderOption();
        break;
      case "VIEW":
        performViewOption();
        break;
      case "REOPEN":
        performReopenOption();
        break;
      case "CANCEL":
        performCancelJourneyOption();
        break;
    }
  };

  // --- Start: SEND REMINDER FUNCTIONALITY
  const CreateSendReminderDropdownOptions = (
    user: UserDetailDto
  ): KeyValuePair<string, string>[] => {
    let options = [
      {
        key: user.userId.toString(),
        value: user.fullName,
      },
    ] as KeyValuePair<string, string>[];
    const managerRoleName = user.journeyManagerId
      ? user.journeyManagerName
      : user.managerName;
    const managerRoleId = user.journeyManagerId
      ? user.journeyManagerId
      : user.managerId;

    // If there is a manger (or journeyManager) then add the manager AND 'both' has an option.
    if (managerRoleId) {
      options.push({ key: managerRoleId.toString(), value: managerRoleName });
      options.push({ key: "BOTH", value: t("Pages.Admin.Common.Both") });
    }

    return options;
  };

  const handleSendReminderOptionDropdownChange = (
    selectedValue: KeyValuePair<string, string>
  ) => {
    setPopupState({
      ...popupState,
      selectedSendReminderDropdownOption: selectedValue,
    });
  };

  const performSendReminderOption = () => {
    const successCallback = (data: boolean) => {
      triggerSuccessNotification(
        t("Pages.Admin.UserDetails.SuccessNotification.SendReminder")
      );
    };

    const errorCallback = (error: any) => {
      triggerErrorNotification(
        t("Pages.Admin.UserDetails.ErrorNotification.SendReminder")
      );
    };

    if (popupState.journeyReference) {
      if (popupState.selectedSendReminderDropdownOption!.key == "BOTH") {
        const managerRoleId = user.journeyManagerId
          ? user.journeyManagerId
          : user.managerId;
        admApi.sendReminder(
          popupState.journeyReference!,
          true,
          successCallback,
          errorCallback,
          user.userId,
          managerRoleId
        );
      } else {
        const sendReminderToEmployee =
          popupState.selectedSendReminderDropdownOption!.key ===
          user.userId.toString();
        const selectedRecipientId = parseInt(
          popupState.selectedSendReminderDropdownOption!.key
        );

        if (sendReminderToEmployee) {
          admApi.sendReminder(
            popupState.journeyReference!,
            false,
            successCallback,
            errorCallback,
            selectedRecipientId,
            undefined
          );
        } else {
          admApi.sendReminder(
            popupState.journeyReference!,
            false,
            successCallback,
            errorCallback,
            user.userId,
            selectedRecipientId
          );
        }
      }
    }
  };
  // --- End: SEND REMINDER FUNCTIONALITY

  // --- Start: VIEW FUNCTIONALITY
  const CreateViewDropdownOptions = (
    journey: UserDetailJourneyDto
  ): KeyValuePair<string, string>[] => {
    let options = [] as KeyValuePair<string, string>[];
    journey.answerSets.map((answerSet) => {
      var textValue = t("Pages.Admin.Common.Unknown");
      if (answerSet.isDualPrepSubjectAnswerSet) {
        textValue = t("Pages.Admin.Common.EmployeePrep");
      } else if (answerSet.isDualPrepOtherAnswerSet) {
        textValue = t("Pages.Admin.Common.ManagerPrep");
      } else if (answerSet.isDualPrepMeetingDocAnswerSet) {
        textValue = t("Pages.Admin.Common.MeetingForm");
      }
      options.push({ key: answerSet.guidId, value: textValue });
    });
    return options;
  };

  const handleViewOptionDropdownChange = (
    selectedValue: KeyValuePair<string, string>
  ) => {
    setPopupState({ ...popupState, selectedViewDropdownOption: selectedValue });
  };

  const performViewOption = () => {
    const redirectUrl = AppRoutes.collaborativeDocument.viewCollabDoc.replace(
      ":answerSetUniqueId",
      popupState.selectedViewDropdownOption!.key
    );
    window.open(redirectUrl, "_blank");
  };
  // --- End: VIEW FUNCTIONALITY

  // --- Start: REOPEN FUNCTIONALITY
  const CreateReopenDropdownOptions = (
    journey: UserDetailJourneyDto
  ): KeyValuePair<string, string>[] => {
    let options = [] as KeyValuePair<string, string>[];
    journey.answerSets.map((answerSet) => {
      let answerSetIsCompleted = isCompleted(answerSet.statusNumber);
      if (answerSet.isDualPrepSubjectAnswerSet && answerSetIsCompleted) {
        options.push({
          key: answerSet.id.toString(),
          value: t("Pages.Admin.Common.EmployeePrep"),
        });
      }

      if (answerSet.isDualPrepOtherAnswerSet && answerSetIsCompleted) {
        options.push({
          key: answerSet.id.toString(),
          value: t("Pages.Admin.Common.ManagerPrep"),
        });
      }

      if (answerSet.isDualPrepMeetingDocAnswerSet && answerSetIsCompleted) {
        options.push({
          key: answerSet.id.toString(),
          value: t("Pages.Admin.Common.MeetingForm"),
        });
      }
    });
    return options;
  };

  const isCompleted = (status: number): boolean => {
    return status == 100 || status == 101 || status == 300;
  };

  const handleReopenOptionDropdownChange = (
    selectedValue: KeyValuePair<string, string>
  ) => {
    // If there is a meeting doc we need to trigger a warning if user selects to reopen to planning
    if (popupState.meetingDocForReopenCalculation) {
      // If the selected value is not the meeting doc then show the warning (and enforce user confirmation)
      if (
        selectedValue.key !=
        popupState.meetingDocForReopenCalculation.id.toString()
      ) {
        setPopupState({
          ...popupState,
          selectedReopenDropdownOption: selectedValue,
          reopenShowMeetingFormWarning: true,
          enforceUserConfirmation: true,
        });
      } else {
        // Else we don't need to enable those
        setPopupState({
          ...popupState,
          selectedReopenDropdownOption: selectedValue,
          reopenShowMeetingFormWarning: false,
          enforceUserConfirmation: false,
        });
      }
    } else {
      setPopupState({
        ...popupState,
        selectedReopenDropdownOption: selectedValue,
      });
    }
  };

  const performReopenOption = () => {
    const successCallback = () => {
      // On success we need to get the refresh journey history list
      admApi.paginateHistoricalJourneys(
        user.userId,
        criteriaForReload.pageSize,
        criteriaForReload.pageNumber,
        (data) => {
          reloadJourneyHistorySuccessCallback(data);
          // Then trigger the success notification and close the popup
          triggerSuccessNotification(
            t("Pages.Admin.UserDetails.SuccessNotification.ReopenJourney")
          );
        },
        (error) => {
          console.error(error);
        },
        criteriaForReload.searchTerm
      );
    };

    const errorCallback = () => {
      triggerErrorNotification(
        t("Pages.Admin.UserDetails.ErrorNotification.ReopenJourney")
      );
    };

    if (popupState.isJourneyDualPrep) {
      // For dual prep journey the journeyId will be the dualPrepAnswerSetId
      const selectedAnswerSetId = parseInt(
        popupState.selectedReopenDropdownOption!.key
      );
      admApi.reopenJourney(
        user.userId,
        popupState.journeyReference!,
        selectedAnswerSetId,
        popupState.isJourneyDualPrep!,
        successCallback,
        errorCallback,
        popupState.journeyId
      );
    } else {
      // For standard journey the journeyId will be the answerSetId
      admApi.reopenJourney(
        user.userId,
        popupState.journeyReference!,
        popupState.journeyId!,
        popupState.isJourneyDualPrep!,
        successCallback,
        errorCallback,
        null
      );
    }
  };
  // --- End: REOPEN FUNCTIONALITY

  // --- Start: CANCEL JOURNEY FUNCTIONALITY
  const performCancelJourneyOption = () => {
    const successCallback = () => {
      // If the currentJourneyReference is being cancelled we need to update some userDetail properties,
      // and update the AlreadySentJourneyReferences as part of that.
      if (user.currentJourneyReference === popupState.journeyReference) {
        onUpdateCurrentJourneyDetails(undefined, undefined);
      } else {
        // If we are cancelling a journey that isn't the users current one then we just need to
        // update the AlreadySentJourneyReferences.
        if (popupState.journeyReference) {
          onUpdateAlreadySentJourneyReferences(popupState.journeyReference);
        }
      }

      // On success we need to get the refresh journey history list
      admApi.paginateHistoricalJourneys(
        user.userId,
        criteriaForReload.pageSize,
        criteriaForReload.pageNumber,
        (data) => {
          reloadJourneyHistorySuccessCallback(data);
          // Then trigger the success notification and close the popup
          triggerSuccessNotification(
            t("Pages.Admin.UserDetails.SuccessNotification.CancelJourney")
          );
        },
        (error) => {
          console.error(error);
        },
        criteriaForReload.searchTerm
      );
    };

    const errorCallback = () => {
      triggerErrorNotification(
        t("Pages.Admin.UserDetails.ErrorNotification.CancelJourney")
      );
    };

    if (popupState.journeyReference) {
      const shouldClearUsersCurrentJourneyFields =
        popupState.journeyReference === user.currentJourneyReference;
      admApi.cancelJourney(
        user.userId,
        popupState.journeyReference,
        popupState.isJourneyDualPrep!,
        shouldClearUsersCurrentJourneyFields,
        successCallback,
        errorCallback,
        popupState.journeyId
      );
    }
  };
  // --- End: CANCEL JOURNEY FUNCTIONALITY

  return (
    <>
      <div>
        <div className="mt-1 text-gray-500">
          <small>{t("Pages.Admin.Common.JourneyHistory")}</small>
        </div>
        <SearchField
          onSearch={onSearchTermChange}
          isLoading={isLoading}
          loaderColor={userContext.user.client.accentHexColour}
          searchTerm={searchTerm}
          placeholderText={t("Pages.Admin.Common.SearchForJourney") + "..."}
          inputFieldClassName="text-xs"
          className="mt-2"
        />
        {showSuccessNotification && (
          <SuccessAlert
            prefix={t("Common.Success")}
            message={t(successNotificationMessage)}
            includeMarginTop={true}
          />
        )}
        {showErrorNotification && (
          <DangerAlert
            prefix={t("Common.Validation.Oops")}
            message={t(errorNotificationMessage)}
          />
        )}
        {totalItemCount != undefined && totalItemCount === 0 && (
          <em className="block text-center italic py-4">
            <div className="mb-4 flex justify-center">
              <EmptyFilteredState />
            </div>
            <span>{t("Pages.Admin.Common.NoResultsMatchingCriteria")}</span>
          </em>
        )}
        {totalItemCount != undefined && totalItemCount > 0 && (
          <div className="bg-[#F7F8FA] rounded-md px-5 py-3 mt-2">
            {journeyHistory && (
              <>
                {journeyHistory.map((journey, index) => {
                  return (
                    <UserDetailJourneyHistoryItem
                      key={`journey_item_${journey.id}_${index}`}
                      journey={journey}
                      allowViewOption={allowViewOption}
                      onDropdownChange={onDropdownChange}
                    />
                  );
                })}
                {totalItemCount > pageSize && (
                  <div className="mt-2 text-xs">
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={pageSize}
                      totalItemsCount={totalItemCount}
                      pageRangeDisplayed={5}
                      onChange={onPageNumberChange}
                      activeClass="pagination-active-list-item"
                      itemClass="pagination-list-item"
                      itemClassFirst="pagination-first-item"
                      itemClassLast="pagination-last-item"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <UserDetailJourneyOptionPopup
        isOpen={popupState.isOpen}
        option={popupState.option}
        titleText={popupState.titleText}
        confirmButtonText={popupState.primaryButtonText}
        cancelButtonText={t("Common.Cancel")}
        onOpenChange={handleClosePopup}
        onConfirmButtonClick={handlePopupPrimaryButtonClick}
        onCancelButtonClick={handleClosePopup}
        selectedViewDropdownOption={popupState.selectedViewDropdownOption}
        viewDropdownOptions={popupState.viewDropdownOptions}
        onViewOptionDropdownChange={handleViewOptionDropdownChange}
        userName={popupState.userName}
        managerRoleName={popupState.managerRoleName}
        journeyName={popupState.journeyName}
        isJourneyDualPrep={popupState.isJourneyDualPrep}
        selectedSendReminderDropdownOption={
          popupState.selectedSendReminderDropdownOption
        }
        sendReminderDropdownOptions={popupState.sendReminderDropdownOptions}
        onSendReminderOptionDropdownChange={
          handleSendReminderOptionDropdownChange
        }
        reopenShowMeetingFormWarning={popupState.reopenShowMeetingFormWarning}
        selectedReopenDropdownOption={popupState.selectedReopenDropdownOption}
        reopenDropdownOptions={popupState.reopenDropdownOptions}
        onReopenOptionDropdownChange={handleReopenOptionDropdownChange}
        enforceUserConfirmation={popupState.enforceUserConfirmation}
        approvalFlow={popupState.approvalFlow}
      />
    </>
  );
}

export default UserDetailJourneySection;
