import { useTranslation } from "react-i18next";
import ClientFormModeType from "../../types/dtos/admin/ClientFormModeType";

interface ClientFormModeDisplayProps {
  value: ClientFormModeType;
  className?: string;
}

const ClientFormModeDisplay = ({ value, className = "" }: ClientFormModeDisplayProps) => {
  const { t } = useTranslation();

  switch (value) {
    case "HIDDEN-ON-DASHBOARD":
      return <span className={className}>{t("Journey.Mode.HiddenOnDashboard")}</span>;
    case "AUTOMATED":
      return <span className={className}>{t("Journey.Mode.Automated")}</span>;
    case "WINDOWED":
      return <span className={className}>{t("Journey.Mode.Windowed")}</span>;
    case "MANUAL-ONLY":
      return <span className={className}>{t("Journey.Mode.ManualOnly")}</span>;
    case "INDUCTION":
      return <span className={className}>{t("Journey.Mode.Induction")}</span>;
    case "EXIT":
      return <span className={className}>{t("Journey.Mode.ExitQuestionnaire")}</span>;
    default:
      return <span className={className}>{t("Journey.Mode.Unknown")}</span>;
  }
};

export default ClientFormModeDisplay;
