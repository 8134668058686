import {
  AnalyticsWidgetUiDetailsDto,
  DynamicAnalyticsPageMetaData,
} from "../../types/analytics";
import AnalyticsFilterInputs from "../../types/analytics/AnalyticsFilterInputs";
import { AnalyticsPageDataDto } from "../../types/analytics/AnalyticsPageDataDto";
import IAnalyticsPageApi from "../../types/analytics/IAnalyticsPageApi";
import {
  AnalyticsWidgetRefreshArguments,
  AnalyticsWidgetRefreshFilterParams,
} from "../../types/analytics/AnalyticsWidgetRefreshFilterParams";
import { apiClient } from "../apiClient";
import ComplexScoreOverrideLog from "../../types/analytics/ComplexScoreOverrideLog";

class analyticsDynamicWidgetApi implements IAnalyticsPageApi {
  constructor(authToken: string | undefined, pageId: number) {
    this.accessToken = authToken;
    this.pageId = pageId;
  }

  accessToken: string | undefined;
  pageId: number;

  /** Get the meta data about the page */
  getPageMeta(
    onSuccess: (data: DynamicAnalyticsPageMetaData) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/dynamic-widget/page-meta?pageId=" + this.pageId.toString()
    ).then(
      (data) => {
        const pageData = data as DynamicAnalyticsPageMetaData;
        onSuccess(pageData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the whole page of data */
  getPageData(
    filters: AnalyticsFilterInputs,
    onSuccess: (data: AnalyticsPageDataDto) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      "analytics/dynamic-widget/page?pageId=" + this.pageId.toString(),
      {
        body: JSON.stringify(filters),
      }
    ).then(
      (data) => {
        const pageData = data as AnalyticsPageDataDto;
        onSuccess(pageData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the whole page of data */
  refreshWidgetData(
    filters: AnalyticsFilterInputs,
    refreshParams: AnalyticsWidgetRefreshArguments,
    onSuccess: (data: AnalyticsWidgetUiDetailsDto) => void,
    onError: (error: any) => void
  ) {
    const allParams: AnalyticsWidgetRefreshFilterParams = {
      filters: filters,
      refreshArgs: refreshParams,
    };
    return apiClient(
      this.accessToken,
      "analytics/dynamic-widget/refresh-widget",
      {
        body: JSON.stringify(allParams),
      }
    ).then(
      (data) => {
        const widgetData = data as AnalyticsWidgetUiDetailsDto;
        onSuccess(widgetData);
      },
      (error) => {
        onError(error);
      }
    );
  }

  /** Get the log of score overrides for a complexScoreId */
  getScoreOverrideLogs(
    complexScoreId: string, // Guid
    subjectEmployeeId: number,
    onSuccess: (data: ComplexScoreOverrideLog[]) => void,
    onError: (error: any) => void
  ) {
    return apiClient(
      this.accessToken,
      `analytics/dynamic-widget/score-override-logs?complexScoreId=${complexScoreId}&subjectEmployeeId=${subjectEmployeeId}`
    ).then(
      (data) => {
        const logs = data as ComplexScoreOverrideLog[];
        let displayLogs: ComplexScoreOverrideLog[] = [];
        if (logs && logs.length > 0) {
          // Convert the date to proper date objects
          displayLogs = logs.map((log) => {
            return {
              ...log,
              updatedDate: new Date(log.updatedDate),
            };
          });
        }
        onSuccess(displayLogs);
      },
      (error) => {
        onError(error);
      }
    );
  }
}

export default analyticsDynamicWidgetApi;
