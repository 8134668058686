import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { dateHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { Popover, TextArea } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane as PaperPlaneReg } from "@fortawesome/pro-regular-svg-icons";
import {
  faFaceSmile,
  faPaperPlane as PaperPlaneSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { NewCommentDto } from "../../types/dtos/forms";
import { FormConstants } from "../../config/forms/FormConstants";

interface NewCommentFormProps {
  questionId: string;
  replyToCommentId: string | null;
  onSubmit(
    newComment: NewCommentDto,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
}

export const newCommentFieldElementId = "new-comment-input";

function NewCommentForm({
  questionId,
  replyToCommentId,
  onSubmit,
  inputRef = undefined,
}: NewCommentFormProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const clientColor = "#" + userContext.user.client.primaryHexColour;

  const placeholderTranslationKeyIdentifier =
    replyToCommentId !== null
      ? "Pages.CollaborativeDocument.Controls.NewReplyPlaceholder"
      : "Pages.CollaborativeDocument.Controls.NewNotePlaceholder";

  // State
  const [textValue, setTextValue] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [erroredWhenSaving, setErroredWhenSaving] = useState<boolean>(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [isOverSendIcon, setIsOverSendIcon] = useState(false);

  // Events
  const handleSubmitPress = () => {
    const trimmedComment = textValue?.trim();
    if (trimmedComment && trimmedComment.length > 0) {
      // Construct the comment object
      const newComment: NewCommentDto = {
        authorId: userContext.user.id,
        comment: trimmedComment,
        questionId: questionId,
        timestamp: dateHelper.getCurrentDateUtc(),
        replyToCommentId: replyToCommentId,
        commentType: "STANDARD",
      };

      // Submit the comment and update the list of comments
      const successCallback = () => {
        setIsSaving(false);
        setErroredWhenSaving(false);
        // Reset the form
        setTextValue("");
      };

      const errorCallback = () => {
        setIsSaving(false);
        setErroredWhenSaving(true);
      };

      // Reset variables
      setErroredWhenSaving(false);

      // Show the saving spinner
      setIsSaving(true);

      onSubmit(newComment, successCallback, errorCallback);
    }
  };

  /** Handle when the user selects an emoji to add to their response */
  const addEmojiToTextArea = (emoji: EmojiClickData, event: MouseEvent) => {
    // Get the emoji as a string to add to the value
    let sym = emoji.unified.split("-");
    let codesArray: number[] = [];
    sym.forEach((el) => codesArray.push(Number("0x" + el)));
    let emojiString = String.fromCodePoint(...codesArray);

    // Insert the emoji at the carat
    if (inputRef?.current) {
      const selectionStart = inputRef.current.selectionStart;
      const newTextValue =
        textValue.slice(0, selectionStart) +
        emojiString +
        textValue.slice(selectionStart);
      setTextValue(newTextValue);
    }
  };

  return (
    <div className="border border-gray-300 rounded-lg py-2 mb-2">
      <div className="flex">
        <div className="hidden md:inline-block w-2/12 text-center pt-2">
          <Popover
            openState={popoverIsOpen}
            onOpenStateChange={setPopoverIsOpen}
            placement="left"
            contentClassNames="w-fit bg-white"
            showCloseIcon={false}
            triggerElement={
              <button
                className="hidden md:inline-block text-gray-600 disabled:text-gray-400 disabled:cursor-not-allowed"
                disabled={isSaving}
              >
                <FontAwesomeIcon
                  icon={faFaceSmile}
                  size="1x"
                  title={t(
                    "Pages.CollaborativeDocument.Common.AddEmojiTooltip"
                  )}
                  className="self-center "
                />
              </button>
            }
          >
            <EmojiPicker onEmojiClick={addEmojiToTextArea} />
          </Popover>
        </div>
        <div className="pl-2 w-10/12 md:w-8/12 md:pl-0">
          <TextArea
            inputId={newCommentFieldElementId}
            placeholder={t(placeholderTranslationKeyIdentifier)}
            value={textValue}
            onChange={setTextValue}
            className="block w-full border-0 bg-gray-100 rounded-lg text-sm"
            minRows={1}
            maxRows={4}
            inputRef={inputRef}
            showValidationErrors={false}
            maxLength={FormConstants.MaxLengths.AnswerSetComment}
          />
        </div>
        <div className="w-2/12">
          <div className="text-center pt-2">
            <FontAwesomeIcon
              title={t("Pages.CollaborativeDocument.Controls.NewNoteLabel")}
              className="cursor-pointer"
              onMouseEnter={() => setIsOverSendIcon(true)}
              onMouseLeave={() => setIsOverSendIcon(false)}
              onClick={handleSubmitPress}
              color={clientColor}
              icon={!isOverSendIcon ? PaperPlaneReg : PaperPlaneSolid}
            />
          </div>
        </div>
      </div>
      {erroredWhenSaving && (
        <small className="pl-2">
          <span className="text-red-500 mr-2">
            {t("Pages.CollaborativeDocument.Common.AddCommentErrored")}
          </span>
          <button className="underline" onClick={handleSubmitPress}>
            {t("Common.TryAgain")}
          </button>
        </small>
      )}
    </div>
  );
}

export default NewCommentForm;
