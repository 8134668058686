import { t } from "i18next";
import { SavedCommentDto } from "../../../../types/dtos/forms";
import { FormType, ValidationResult } from "../../../../types/forms";
import { Badge, Label, TextArea } from "../../../common";
import { FormConstants } from "../../../../config/forms/FormConstants";

interface BehaviourCommentProps {
  behaviourId: number;
  formId: number;
  questionIsRequired?: boolean | null;
  questionTextTranslationKey: string;
  formType: FormType;
  currentValue: string | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  isReadOnly?: boolean;
  flaggedChangeComponent?: JSX.Element | null;
  /** The onChange event, for handling state changes */
  onChange(newValue: string, behaviourId: number, clientFormId: number): void;
  /** An event to call when the input loses focus */
  onBlur?(
    behaviourId: number,
    nonStateValue: SavedCommentDto | null
  ): void | undefined;
}

function BehaviourComment({
  behaviourId,
  formId,
  questionIsRequired,
  questionTextTranslationKey,
  formType,
  currentValue,
  showValidationErrors,
  isReadOnly = false,
  flaggedChangeComponent = null,
  onChange,
  onBlur = undefined,
}: BehaviourCommentProps) {
  if (!questionTextTranslationKey) return null;

  const textAreaElementId = `txtBhvComment${behaviourId}`;
  const textAreaClassNames =
    formType === "JOURNEY"
      ? "mt-2 p-2 w-full rounded-md bg-white/20 border-0 placeholder:text-white/40 placeholder:text-sm focus:outline-none focus:ring-0"
      : "mt-2 p-2 w-full rounded-md border border-gray-400";

  const handleTextChange = (newValue: string) => {
    onChange(newValue, behaviourId, formId);
  };

  const handleTextFieldBlur = () => {
    if (onBlur) {
      onBlur(behaviourId, null);
    }
  };

  const commentIsValid = () => {
    return (
      currentValue !== undefined &&
      currentValue !== null &&
      currentValue.trim().length > 0
    );
  };

  // Perform the validation, if we're showing validation errors
  let validationResult: ValidationResult | null = null;
  if (showValidationErrors
    && questionIsRequired == true
    && !commentIsValid()) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }]);
  }

  const placeholderText = questionIsRequired ? "Required" : "Optional";

  return (
    <div>
      <Label
        text={t(questionTextTranslationKey)}
        className={formType === "JOURNEY" ? "text-white" : "text-gray-700"}
        htmlFor={textAreaElementId}
      />
      {questionIsRequired == null ||
       questionIsRequired == false && (
        <Badge text={t("Common.Optional")} marginClassName="mx-1" />
      )}
      {flaggedChangeComponent}
      <TextArea
        inputId={textAreaElementId}
        className={textAreaClassNames}
        minRows={2}
        onChange={handleTextChange}
        onBlur={handleTextFieldBlur}
        value={currentValue}
        isReadOnly={isReadOnly}
        showValidationErrors={showValidationErrors}
        validationResult={validationResult}
        maxLength={FormConstants.MaxLengths.AnswerSetComment}
        placeholder={placeholderText}
      />
    </div>
  );
}

export default BehaviourComment;
