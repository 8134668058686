import cx from "classnames";
import { t } from "i18next";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import parse from "html-react-parser";
import { ModalPopup } from "../../common";
import MoreInfoBadge from "../../common/MoreInfoBadge";
import { FormType } from "../../../types/forms";

interface MoreInfoProps {
  /** Title for the modal, generally the form question text */
  title: string;
  /** Optional: Anything provided will override the default translation 'More info'*/
  triggerText?: string | null;
  /** Content for the modal, to display to the user  */
  content: string;
  formType: FormType;
}

function MoreInfo({ title, triggerText, content, formType }: MoreInfoProps) {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState<boolean>(false);
  const buttonText =
    triggerText != null ? triggerText : "Forms.Generic.MoreInfoButtonText";

  return (
    <>
      <MoreInfoBadge
        textTranslationKeyIdentifier={buttonText}
        formType={formType}
        onClick={() => setInfoModalIsOpen(true)}
      />
      <ModalPopup
        isOpen={infoModalIsOpen}
        onOpenChange={setInfoModalIsOpen}
        onPrimaryButtonClick={() => setInfoModalIsOpen(false)}
        primaryButtonText={t("Common.Close")}
        title={t(title)}
      >
        <div className="text-gray-700 generic-more-info-popup">
          {parse(t(content))}
        </div>
      </ModalPopup>
    </>
  );
}

export default MoreInfo;
