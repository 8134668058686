import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faCalendar, faUser, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { TimelineItemType } from "../../types/dtos/timeline/TimelineItemDetailDto";
import { Badge, FormattedDate, Tooltip } from "../common";
import { TaskType } from "../../types/tasks";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";

interface TimelineItemProps {
  titleTranslationKey: string | null;
  customTitle: string | null;
  dueDate: Date;
  type: TimelineItemType;
  taskId: number | null;
  catchUpId: string | null;
  taskType: TaskType | null;
  catchUpOtherParticipantFullName: string | null;
  dateCompleted: Date | null;
  isPlanning: boolean;
  isCompleted: boolean;
  clientColour: string;

  triggerEditTaskPopup(taskId: number, taskType: TaskType): void;
}

const TimelineItem = ({
  titleTranslationKey,
  customTitle,
  dueDate,
  type,
  taskId,
  catchUpId,
  taskType,
  catchUpOtherParticipantFullName,
  dateCompleted,
  isPlanning,
  isCompleted,
  clientColour,
  triggerEditTaskPopup,
}: TimelineItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let titleToUse = "";
  if (customTitle) {
    titleToUse = customTitle;
  } else if (titleTranslationKey) {
    titleToUse = t(titleTranslationKey);
  }

  const handleTimelineItemClick = () => {
    switch (type) {
      case "TASK":
        if (taskId && taskType) {
          triggerEditTaskPopup(taskId, taskType);
        }
        break;
      case "CATCH-UP":
        if (catchUpId) {
          navigate(
            AppRoutes.collaborativeDocument.generateRoute(
              catchUpId,
              false,
              null
            )
          );
        }
        break;
      case "JOURNEY":
        if (catchUpId) {
          navigate(
            AppRoutes.collaborativeDocument.generateRoute(
              catchUpId,
              false,
              null
            )
          );
        }
        break;
      default:
        console.log("Task type is not valid");
        break;
    }
  };

  let typeTranslationKey: string = "";
  switch (type) {
    case "TASK":
      taskType === "ACTION"
        ? (typeTranslationKey = "Timeline.Type.Action")
        : taskType === "GOAL"
        ? (typeTranslationKey = "Timeline.Type.Goal")
        : (typeTranslationKey = "Timeline.Type.Learning");
      break;
    case "JOURNEY":
      typeTranslationKey = "Timeline.Type.Journey";
      break;
    case "CATCH-UP":
      typeTranslationKey = "Timeline.Type.CatchUp";
      break;
    default:
      typeTranslationKey = "Timeline.Type.Task";
      break;
  }
  const translatedType = t(typeTranslationKey);

  return (
    <div
      onClick={handleTimelineItemClick}
      className="bg-white rounded-md mb-4 cursor-pointer mr-2 border border-gray-200 hover:drop-shadow-md"
    >
      <div className="flex flex-col">
        <div className=" flex flex-row border-b px-5 pb-2 pt-3">
          <div className="flex flex-col grow">
            <div className="text-gray-500 flex flex-row">
              <div className="flex flex-col">
                <Tooltip
                  content={titleToUse}
                  triggerElement={
                    <span className="font-semibold line-clamp-1">
                      {titleToUse}
                    </span> /* Span is necessary otherwise tooltip doesn't work */
                  }
                />

                <div className="text-gray-400 font-medium text-sm">
                  <small>{translatedType}</small>
                </div>

                {type === "JOURNEY" && isPlanning && (
                  <div className="flex flex-row pt-1">
                    <Tooltip
                      content={"This is the planning step of your journey"}
                      triggerElement={
                        <span>
                          <Badge
                            text={"Planning"}
                            backgroundColourClassName="bg-gray-200/75 rounded-full"
                            textColourClassName="text-gray-500"
                            borderRadiusClassName="rounded"
                            marginClassName="mr-1"
                          />
                        </span>
                      }
                    />
                  </div>
                )}
                {type === "TASK" && dateCompleted && (
                  <div className="flex flex-row">
                    <Tooltip
                      content={
                        "Completed on " +
                        new Date(dateCompleted).toLocaleDateString()
                      }
                      triggerElement={
                        <span>
                          <Badge
                            text={
                              "Completed on " +
                              new Date(dateCompleted).toLocaleDateString()
                            }
                            backgroundColourClassName="bg-green-200/75 rounded-full"
                            textColourClassName="text-green-600"
                            borderRadiusClassName="rounded"
                            marginClassName="mr-1"
                          />
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center items-start">
            <div className="justify-self-end">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-gray-300"
              />
            </div>
          </div>
        </div>
        <div className="text-gray-400 font-normal text-xs flex flex-row justify-between px-5 py-2">
          <Tooltip
            content=""
            htmlContent={
              <FormattedDate
                date={dueDate}
                convertFromUtc={true}
                displayMode="DATE-AND-TIME"
              />
            }
            triggerElement={
              <div>
                {isCompleted ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2 text-green-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="mr-2"
                    color={clientColour}
                  />
                )}
                <FormattedDate date={dueDate} displayMode="FROM-NOW" />
              </div>
            }
          />
          {catchUpOtherParticipantFullName && (
            <div>
              <FontAwesomeIcon
                icon={faUser}
                className="mr-2"
                color={clientColour}
              />
              {catchUpOtherParticipantFullName}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
