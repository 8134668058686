import { EmployeeDashboardUpdateDto } from "../../types/dtos/dashboards";
import EmployeeJourneyUpdates from "./updates/EmployeeJourneyUpdates";
import { ReactComponent as NoManagerImage } from "../../images/noGeneralContent.svg";
import { t } from "i18next";

interface JourneyWidgetLayoutProps {
  /** Whether to render the updates section, or just the children prop */
  showUpdates: boolean;
  /** Any updates relating to open collab docs or dual prep flows */
  updates: EmployeeDashboardUpdateDto[];
  /** Whether or not the children contains the empty state component */
  isShowingEmptyState: boolean;

  showNoManagerWarning: boolean;
  /** A function which changes the user's current journey and refreshes the journey widget */
  changeJourney: (journeyRef: string) => void;
  /** This is the empty state or journey form */
  children: React.ReactNode;
}

function JourneyWidgetLayout({
  showUpdates,
  updates,
  children,
  isShowingEmptyState,
  showNoManagerWarning,
  changeJourney,
}: JourneyWidgetLayoutProps) {
  if (showNoManagerWarning) {
    return (
      <div className="text-white text-center">
        <NoManagerImage width="8rem" className="block m-auto mb-2" />
        <h3 className="!text-white">{t("Journey.OnHoldState.Title")}</h3>
        <p className="mb-2 text-white/80">
          {t("Journey.OnHoldState.Content")}
        </p>
      </div>
    );
  }

  if (!showUpdates || updates.length === 0) {
    // Just render this child component(s)
    return <>{children}</>;
  }

  if (isShowingEmptyState && isShowingEmptyState) {
    return (
      <div>
        <EmployeeJourneyUpdates
          useShorterText={false}
          updates={updates}
          changeJourney={changeJourney}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row grow">
      <div className="lg:basis-1/2">
        <div className="lg:pr-8">
          <EmployeeJourneyUpdates
            useShorterText={true}
            updates={updates}
            changeJourney={changeJourney}
          />
        </div>
      </div>
      <hr className="lg:hidden border-dotted border-white/30 my-4" />
      <div className="lg:basis-1/2">
        <div className="lg:pl-8 lg:border-l-[1px] border-dotted border-white/30 lg:min-h-full lg:h-full">
          {children}
        </div>
      </div>
    </div>
  );
}

export default JourneyWidgetLayout;
