import React, { useEffect, useReducer, Reducer } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../../components/layout";
import AppContext from "../../state/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminConfigApiResponseDto from "../../types/dtos/admin/AdminConfigApiResponseDto";
import { DefaultSettingDto } from "../../types/dtos/admin/DefaultSettingDto";
import { OverrideDto } from "../../types/dtos/admin/OverrideDto";
import AppRoutes from "../AppRoutes";
import { TabDetails } from "../../components/common/Tabs";
import { Tabs } from "../../components/common";
import DefaultSettingsTab from "../../components/admin/Configuration/DefaultSettingsTab";
import OverridesTab, {
  ConfigMode,
} from "../../components/admin/Configuration/OverridesTab";
import { NewOverrideDto } from "../../types/dtos/admin/NewOverrideDto";
import { useAuth } from "react-oidc-context";
import adminApi from "../../api/dashboard/adminApi";
import { DefaultSettings } from "../../state/admin/config/DefaultSettings";
import { Overrides } from "../../state/admin/config/Overrides";
import ClientFormModeType from "../../types/dtos/admin/ClientFormModeType";
import ClientFormAutomationUnitType from "../../types/dtos/admin/ClientFormAutomationUnitType";
import overrideHelper from "../../helpers/overrideHelper";
import adminHelper from "../../helpers/adminHelper";
import UserContext from "../../state/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

const setOverrideTitle = (configMode: ConfigMode, appLevelName?: string) => {
  if (configMode === "EDIT" && appLevelName) {
    return (
      t("Pages.Admin.Tabs.Titles.EditOverrideFor") +
      " '" +
      t(appLevelName) +
      "'"
    );
  } else {
    return t("Pages.Admin.Tabs.Titles.AddNewOverride");
  }
};

const createBlankOverride = (
  mode: ClientFormModeType,
  clientFormId: number,
  appLevelId: number,
  appLevelName: string,
  disableJourney: boolean,
  automationAmount?: number,
  automationUnit?: ClientFormAutomationUnitType,
  inductionDaysTo?: number,
  inductionDaysFrom?: number
): OverrideDto => {
  return {
    overrideId: 0,
    clientFormId: clientFormId,
    appraisalLevelId: appLevelId,
    appraisalLevelName: appLevelName,
    mode: mode,
    modeEnum: overrideHelper.getModeEnumValue(mode),
    automationAmount: mode === "WINDOWED" ? undefined : automationAmount,
    automationUnit: mode === "WINDOWED" ? undefined : automationUnit,
    automationUnitEnum:
      mode === "WINDOWED"
        ? undefined
        : automationUnit !== undefined
        ? overrideHelper.getAutomationUnitEnumValue(automationUnit)
        : undefined,
    windows: mode === "WINDOWED" ? [] : undefined,
    inductionDaysTo: inductionDaysTo,
    inductionDaysFrom: inductionDaysFrom,
    disableJourney: disableJourney,
  };
};

interface EditConfigState {
  waitingForApiResult: boolean;
  clientFormId: number;
  displayTabs: TabDetails[];
  defaultSettings: DefaultSettingDto | undefined;
  existingOverrides: OverrideDto[];
  newOverrideData: NewOverrideDto | undefined;
  defaultSettingsState: DefaultSettings;
  overridesState: Overrides;
}

function EditConfiguration() {
  const { t } = useTranslation();
  const { clientFormId } = useParams();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);
  const auth = useAuth();
  const admApi = new adminApi(auth.user?.access_token);
  const navigate = useNavigate();

  const [state, setState] = useReducer<
    Reducer<EditConfigState, Partial<EditConfigState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    clientFormId: 0,
    displayTabs: [],
    defaultSettings: undefined,
    existingOverrides: [],
    newOverrideData: undefined,
    defaultSettingsState: {
      formIsDirty: false,
      showSuccessAlert: false,
      showValidationErrors: false,
      selectedMode: "HIDDEN-ON-DASHBOARD",
      introMessage: "",
      introMessageWordCount: 0,
      introMessageValidation: null,
      themeColour: "",
      themeColourValidation: null,
      automationAmount: undefined,
      automationUnit: undefined,
      windows: undefined,
      inductionDaysTo: undefined,
      inductionDaysFrom: undefined,
      nextDate: undefined,
    },
    overridesState: {
      formIsDirty: false,
      inEditMode: false,
      showSuccessAlert: false,
      successMessage: "",
      showValidationErrors: false,
      title: "",
      overrideObject: {
        overrideId: 0,
        clientFormId: 0,
        appraisalLevelId: 0,
        appraisalLevelName: "",
        mode: "HIDDEN-ON-DASHBOARD",
        modeEnum: 0,
        automationAmount: undefined,
        automationUnit: undefined,
        automationUnitEnum: undefined,
        windows: undefined,
        inductionDaysTo: undefined,
        inductionDaysFrom: undefined,
        disableJourney: undefined,
      },
      appraisalLevelValidation: null,
      inductionDateRangeValidation: null,
      nextDate: undefined,
    },
  });

  useEffect(() => {
    adminHelper.checkAdminAccessAndRedirectWhenNoAccess(
      userContext,
      "JOURNEY-CONFIG",
      navigate
    );

    if (clientFormId !== undefined) {
      // Call the API to load the necessary state
      const successCallback = (data: AdminConfigApiResponseDto) => {
        // Format Dates so they can be sorted on later on.
        if (data.defaultSettings.windows) {
          data.defaultSettings.windows.forEach((window) => {
            window.startDate = new Date(window.startDate);
            window.endDate = new Date(window.endDate);
          });
        }

        // Format Dates so they can be sorted on later on.
        if (data.existingOverrides) {
          data.existingOverrides.forEach((override) => {
            if (override.windows !== undefined) {
              override.windows.forEach((window) => {
                window.startDate = new Date(window.startDate);
                window.endDate = new Date(window.endDate);
              });
            }
          });
        }

        setState({
          waitingForApiResult: false,
          clientFormId: data.clientFormId,
          displayTabs: [],
          defaultSettings: data.defaultSettings,
          existingOverrides: data.existingOverrides,
          newOverrideData: data.newOverride,
          defaultSettingsState: {
            formIsDirty: false,
            showSuccessAlert: false,
            showValidationErrors: false,
            selectedMode: data.defaultSettings.mode,
            introMessage: data.defaultSettings.introMessage,
            introMessageWordCount: data.defaultSettings.introMessage.length,
            introMessageValidation: null,
            themeColour: data.defaultSettings.themeColour,
            themeColourValidation: null,
            automationAmount: data.defaultSettings.automationAmount,
            automationUnit: data.defaultSettings.automationUnit,
            windows: data.defaultSettings.windows,
            inductionDaysTo: data.defaultSettings.inductionDaysTo,
            inductionDaysFrom: data.defaultSettings.inductionDaysFrom,
            nextDate: undefined,
          },
          overridesState: {
            formIsDirty: false,
            inEditMode: false,
            showSuccessAlert: false,
            successMessage: "",
            showValidationErrors: false,
            title: setOverrideTitle("ADD"),
            overrideObject: createBlankOverride(
              data.defaultSettings.mode,
              data.clientFormId,
              data.newOverride.appraisalLevels[0].key,
              data.newOverride.appraisalLevels[0].value,
              false,
              data.defaultSettings.automationAmount,
              data.defaultSettings.automationUnit,
              data.defaultSettings.inductionDaysTo,
              data.defaultSettings.inductionDaysFrom
            ),
            appraisalLevelValidation: null,
            inductionDateRangeValidation: null,
            nextDate: undefined,
          },
        });

        setPageTitle(data.clientFormTitle);
      };

      const errorCallback = (error: any) => {
        console.error(error);
      };

      admApi.getClientFormConfigById(
        parseInt(clientFormId),
        successCallback,
        errorCallback
      );
    }

    setPageTitle("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var tabs = loadDisplayTabs();
    setState({ displayTabs: tabs });
  }, [
    state.defaultSettings,
    state.existingOverrides,
    state.newOverrideData,
    state.defaultSettingsState,
    state.overridesState,
  ]);

  const setPageTitle = (titleSuffix: string) => {
    const pageTitle =
      t("Pages.Admin.PageTitle.Journeys") +
      " - " +
      `${t("Pages.Admin.PageTitle.Configuration")}: ${t(titleSuffix) || ""}`;

    // Set the main page title
    appContext.setPageTitle(pageTitle);
    appContext.setShowPageTitleAccent(true);
  };

  const onDefaultSettingsChange = (value: DefaultSettings) => {
    setState({ defaultSettingsState: value });
  };

  const onOverrideSettingsChange = (value: Overrides) => {
    setState({ overridesState: value });
  };

  const onUpdateExistingOverrides = (overrides: any) => {
    setState({ existingOverrides: overrides });
  };

  const loadDisplayTabs = () => {
    const tabs = Array<TabDetails>();

    if (
      state.defaultSettings !== undefined &&
      state.newOverrideData !== undefined
    ) {
      tabs.push({
        title: t("Pages.Admin.Tabs.Headings.DefaultSettings"),
        content: (
          <DefaultSettingsTab
            clientFormId={clientFormId}
            settings={state.defaultSettings}
            defaultSettings={state.defaultSettingsState}
            onDefaultSettingsChange={onDefaultSettingsChange}
          />
        ),
        displayAlertIcon: false,
      });

      tabs.push({
        title: t("Pages.Admin.Tabs.Headings.Overrides"),
        content: (
          <OverridesTab
            clientFormId={state.clientFormId}
            newOverrideData={state.newOverrideData}
            existingOverrides={state.existingOverrides}
            onUpdateExistingOverrides={onUpdateExistingOverrides}
            overrideSettings={state.overridesState}
            onOverrideSettingsChange={onOverrideSettingsChange}
            setOverrideTitle={setOverrideTitle}
            createBlankOverride={createBlankOverride}
            defaultMode={state.defaultSettings.mode}
            defaultAutomationAmount={state.defaultSettings.automationAmount}
            defaultAutomationUnit={state.defaultSettings.automationUnit}
            defaultInductionDaysTo={state.defaultSettings.inductionDaysTo}
            defaultInductionDaysFrom={state.defaultSettings.inductionDaysFrom}
          />
        ),
        displayAlertIcon: false,
      });
    }

    return tabs;
  };

  return (
    <MainContainer>
      <div className="pt-2 hover:underline">
        <Link to={AppRoutes.admin.journeys.pathOpenWithConfigTabSelected}>
          <FontAwesomeIcon size="xs" icon={faChevronLeft} />
          <span className="pl-1.5">{t("Common.Back")}</span>
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-0.5">
        <div className="mt-4 col-span-1 md:col-span-10 md:col-start-2">
          <Tabs
            tabs={state.displayTabs}
            selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md"
          />
        </div>
      </div>
    </MainContainer>
  );
}

export default EditConfiguration;
