import AddToCalendarSvg from "../../images/AddToCalendarSvg";

interface CatchUpSuccessProps {
  onAddToCalendar(): void;
  isLoading: boolean;
}

function CatchUpSuccess({ onAddToCalendar, isLoading }: CatchUpSuccessProps) {
  return (
    <div className="text-center">
      <div>
        <AddToCalendarSvg isLoading={isLoading} />
        <p className="text-2xl font-bold primary-text">Success!</p>
        <p className="text-gray-400">Your Catch up request has been sent</p>
        <p className="text-gray-400">
          <span
            className="primary-text cursor-pointer"
            onClick={onAddToCalendar}
          >
            Click here
          </span>{" "}
          to add to your calendar or click close to see it in your timeline.
        </p>
      </div>
    </div>
  );
}

export default CatchUpSuccess;
