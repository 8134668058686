import rg4js from "raygun4js";

let instance: ErrorLogger;

class ErrorLogger {
  _logHandler: (error: any) => void;
  _setActiveUser: (user: ActiveUserDetails | null) => void;

  constructor() {
    if (instance) {
      throw new Error("You can only create one instance of the ErrorLogger!");
    }
    instance = this;

    const raygunApiKey = process.env.REACT_APP_RAYGUN_API_KEY;

    if (
      raygunApiKey &&
      raygunApiKey.length > 0 &&
      raygunApiKey !== "DISABLED"
    ) {
      rg4js("enableCrashReporting", true);
      rg4js("apiKey", raygunApiKey);

      this._logHandler = (error: any) => {
        console.log("TT Error", error);
        rg4js("send", error);
      };

      this._setActiveUser = (user: ActiveUserDetails | null) => {
        if (user) {
          const logUserId = `User_${user.id}-Client_${user.clientId}_${user.clientName}`;

          try {
            rg4js("setUser", {
              identifier: logUserId,
              isAnonymous: false,
              fullName: user.fullName,
            });
          } catch (err) {
            console.error("Unable to set error logging user uid", err);
          }
        } else {
          try {
            rg4js("getRaygunInstance").resetAnonymousUser();
          } catch (err) {
            console.error("Unable to reset error logging user uid", err);
          }
        }
      };
    } else {
      this._logHandler = (error: any) => {
        console.log("TT Error", error);
      };
      this._setActiveUser = () => {
        /* Do nothing */
      };
    }
  }

  init() {}

  log(error: any) {
    this._logHandler(error);
  }

  setActiveUser(user: ActiveUserDetails | null) {
    this._setActiveUser(user);
  }
}

interface ActiveUserDetails {
  id: number;
  fullName: string;
  clientId: number;
  clientName: string;
}

const singletonLogger = Object.freeze(new ErrorLogger());
export default singletonLogger;
