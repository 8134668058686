import { t } from "i18next";
import produce from "immer";
import { useContext, useEffect, useState } from "react";
import {
  ActorQuestionText,
  FormQuestion,
  FormType,
  QuestionAnswer,
  QuestionTasks,
  ValidationResult,
} from "../../../types/forms";
import ScaleOption from "../../../types/forms/ScaleOption";
import { KeyValuePair, SliderScoreDisplayType } from "../../../types/generic";
import { Label } from "../../common";
import BehaviourQuestionAnswerValue from "../../../types/forms/BehaviourQuestionAnswerValue";
import { mathsHelper, questionTextHelper } from "../../../helpers";
import UserContext from "../../../state/UserContext";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../types/dtos/generic";
import AddFormTaskControl from "./AddFormTaskControl";
import BehaviourSlider from "./behaviours/BehaviourSlider";
import BehaviourComment from "./behaviours/BehaviourComment";
import { BaseCommentDto, SavedCommentDto } from "../../../types/dtos/forms";
import CollabDocFlaggedChangeDto from "../../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import CollabDocFlaggedChange from "../../collab-docs/CollabDocFlaggedChange";
import PlanningResponses from "./dual-prep/PlanningResponses";

interface BehaviourAttributeQuestionProps {
  question: FormQuestion;
  /** The id of the client form */
  formId: number;
  /** The id and title (translation key identifier) of the behaviour */
  behaviour: KeyValuePair<number, string>;
  /** Participant details are used in collab docs for flagged changes */
  participants?: Array<UserBasicDetailsDto> | undefined;
  /** The scale points for rating this behaviour */
  scalePoints: ScaleOption[];
  /** The label to display for the slider when there aren't any attributes */
  singleScaleLabel: ActorQuestionText | null;
  /** The attributes to rate. There should always be at least one */
  attributes: KeyValuePair<number, ActorQuestionText>[] | null;
  /** Whether or not to show the Add Task button */
  showAddTaskButton: boolean;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** The current value(s) for attributes or for the single behaviour slider */
  currentValues: BehaviourQuestionAnswerValue[] | undefined;
  /** Notify the parent component(s) about a changed answer within this behaviour */
  onValueChange(newState: BehaviourQuestionAnswerValue[]): void;
  /** Specify a CSS class name for the track to the left hand side of the drag handle */
  selectedTrackBgColourClassName: string;
  /**The hex of the form colour used in collaborative docs */
  formBackgroundColorStyle?: string | null;
  /** The class name for the add task button which might changed depending in if it's in the collaborative documents or the journey widget */
  addTaskButtonClassName?: string;
  /** The colour of the track to the right hand side of the drag handle */
  emptyTrackBgColourClassName: string;
  /** Who the form is regarding. Used to determine attribute text, e.g. "How is Chris doing?" before a slider */
  subjectUser: BaseUserDetailsDto;
  /** Whether to show the numeric scale selected on the slider or words */
  sliderScoreDisplayType: SliderScoreDisplayType;
  isReadOnly: boolean;
  tasks: QuestionTasks | null;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChanges?: CollabDocFlaggedChangeDto[] | undefined;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionTasks(questionTasks: QuestionTasks): void;
  /** An event to call when one of the inputs loses focus */
  onBlur?(
    latestValue: BehaviourQuestionAnswerValue[],
    nonStateValue?: QuestionAnswer | null
  ): void | undefined;
  /** Whether this is being rendered in a journey or a collab doc */
  formType: FormType;
  enforcedCommentIsOptional?: boolean | null;
  /** If a comment per behaviour is required, specify the question text */
  enforcedCommentQuestionText: string | null;
  /** The value for the enforced comment, if there is one for this behaviour */
  enforcedComment: BaseCommentDto | undefined;
  /** The onChange event, for handling state changes to the enforced comment */
  onEnforcedCommentChange(
    newValue: string,
    behaviourId: number,
    clientFormId: number
  ): void;
  /** An event to call when the enforced comment textarea loses focus */
  onEnforcedCommentBlur?(
    behaviourId: number,
    nonStateValue: SavedCommentDto | null
  ): void | undefined;
  showPlanningResponses: boolean | null;
  onPlanningResponseCopyClick?(newValue: string, behaviourId: number): void;
}

interface BehaviourSliderDetails {
  attributeId: number | null;
  scaleOptions: ScaleOption[];
  displayText: ActorQuestionText;
}

function BehaviourAttributeQuestion({
  question,
  formId,
  behaviour,
  participants,
  scalePoints,
  singleScaleLabel,
  attributes,
  showValidationErrors,
  currentValues,
  onValueChange,
  onBlur = undefined,
  selectedTrackBgColourClassName,
  formBackgroundColorStyle,
  addTaskButtonClassName = "btn-primary",
  emptyTrackBgColourClassName,
  showAddTaskButton,
  subjectUser,
  sliderScoreDisplayType,
  isReadOnly,
  tasks,
  flaggedChanges,
  showPlanningResponses,
  onChangeQuestionTasks,
  formType,
  enforcedCommentIsOptional,
  enforcedCommentQuestionText,
  enforcedComment,
  onEnforcedCommentChange,
  onEnforcedCommentBlur = undefined,
  onPlanningResponseCopyClick,
}: BehaviourAttributeQuestionProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [sliders, setSliders] = useState<BehaviourSliderDetails[]>([]);
  const [averageAttributeScore, setAverageAttributeScore] = useState<
    number | null
  >(null);
  const hasAttributes = attributes && attributes.length > 0;

  /** Create a copy of the scalePoints array with the scalePoint matching the answer value being selected */
  const getScalePointsCopyWithSelectedValue = (
    selectedValue: number | null
  ) => {
    const output = produce(scalePoints, (draft) => {
      draft.forEach((scalePoint) => {
        scalePoint.isSelected = scalePoint.value === selectedValue;

        // Translate the scale point display text, for the slider
        if (scalePoint.text && scalePoint.text.length > 0) {
          scalePoint.text = t(scalePoint.text);
        }
      });
    });

    return output;
  };

  /** Use the answers, the scale points etc to create the state necessary to power the slider questions */
  const intialiseSliders = () => {
    const newState: BehaviourSliderDetails[] = [];

    if (hasAttributes) {
      attributes.forEach((attr) => {
        const attributeAnswer = currentValues
          ? currentValues.find((x) => x.attributeId === attr.key)
          : null;
        const attributeSliderValue =
          attributeAnswer && attributeAnswer !== null
            ? attributeAnswer.selectedScaleValue
            : null;

        newState.push({
          attributeId: attr.key,
          scaleOptions:
            getScalePointsCopyWithSelectedValue(attributeSliderValue),
          displayText: attr.value,
        });
      });
    } else {
      const singleSliderValue =
        currentValues && currentValues.length > 0
          ? currentValues[0].selectedScaleValue
          : null;
      newState.push({
        attributeId: null,
        scaleOptions: getScalePointsCopyWithSelectedValue(singleSliderValue),
        displayText: singleScaleLabel!,
      });
    }

    setSliders(newState);
  };

  // Initial mount
  useEffect(() => {
    intialiseSliders();
  }, []);

  // On answer change
  useEffect(() => {
    intialiseSliders();
  }, [currentValues, attributes, scalePoints]);

  const calculateAttributesAverageScore = (
    values: BehaviourQuestionAnswerValue[]
  ): number | null => {
    if (values.filter((x) => x.selectedScaleValue === null).length > 0) {
      return null;
    }

    const selectedValues = values.map((x) => x.selectedScaleValue as number);
    const sum = selectedValues.reduce(
      (a, b) => parseFloat(a.toString()) + parseFloat(b.toString()),
      0
    );
    const average = sum / selectedValues.length || 0;
    return average;
  };

  /** Handle the behaviour slider value change, updating the answer state in the parent component */
  const handleSliderChange = (
    attributeId: number | null,
    scaleOptions: ScaleOption[]
  ) => {
    const nextSliderState = produce(sliders, (draft) => {
      const match = draft.find((x) => x.attributeId === attributeId);
      if (match !== undefined) {
        match.scaleOptions = scaleOptions;
      }
    });

    // Convert the slider values into the expected answer type array
    const newAnswerState = getAnswersFromLocalSliderState(nextSliderState);

    // Call the onValueChange prop to store the answers in the form answerState
    onValueChange(newAnswerState);

    // Update the average score, if it is displayed
    if (sliderScoreDisplayType === "NUMERIC") {
      const avg = calculateAttributesAverageScore(newAnswerState);
      setAverageAttributeScore(avg);
    }
  };

  /** Handle the behaviour slider value change completed, e.g. to call the API to save the new value */
  const handleSliderBlur = (
    attributeId: number | null,
    scaleOptions: ScaleOption[]
  ) => {
    // Call the onBlur prop to handle the finished user interaction with this component
    if (onBlur) {
      const sliderValues = produce(sliders, (draft) => {
        const match = draft.find((x) => x.attributeId === attributeId);
        if (match !== undefined) {
          match.scaleOptions = scaleOptions;
        }
      });

      // Convert the slider values into the expected answer type array
      const latestValue = getAnswersFromLocalSliderState(sliderValues);

      onBlur(latestValue);
    }
  };

  /** Convert the slider values into the expected answer type array */
  const getAnswersFromLocalSliderState = (
    sliders: BehaviourSliderDetails[]
  ): BehaviourQuestionAnswerValue[] => {
    return sliders.map((slider) => {
      const selectedScaleItem = slider.scaleOptions.find((x) => x.isSelected);
      return {
        behaviourId: behaviour.key,
        attributeId: slider.attributeId,
        selectedScaleValue: selectedScaleItem
          ? parseInt(selectedScaleItem.value.toString())
          : null,
      };
    });
  };

  /** Validate the slider. Must have a value (i.e. not null) */
  const validateSlider = (slider: BehaviourSliderDetails): ValidationResult => {
    const selectedValue = slider.scaleOptions.find((x) => x.isSelected);
    const isValid = selectedValue !== undefined;
    return new ValidationResult(isValid, [{ errorType: "REQUIRED" }]);
  };

  const behaviourFlaggedChanges = flaggedChanges?.filter(
    (x) => x.behaviourId === behaviour.key
  );

  const commentFlaggedChange = behaviourFlaggedChanges?.find(
    (x) => x.changeType === "ENFORCED-COMMENT"
  );

  // If there's a comment change (potentially without an attribute change) create
  // the component so we can render it before the text field
  const commentFlaggedChangeComponent =
    commentFlaggedChange && participants ? (
      <CollabDocFlaggedChange
        change={commentFlaggedChange}
        loggedInUserId={userContext.user.id}
        participants={participants}
        question={question}
        relatedComments={[]}
        enforcedCommentText={undefined}
      />
    ) : null;

  return (
    <>
      <div className="pt-2">
        {showPlanningResponses && participants && (
          <PlanningResponses
            subjectUserId={subjectUser.userId}
            participants={participants}
            questionId={question.questionId}
            questionType={question.questionType}
            formId={question.formId}
            behaviourId={behaviour.key}
            onCopyClick={onPlanningResponseCopyClick}
          />
        )}

        {sliders.map((attr, ix) => {
          const sliderValidationResult = validateSlider(attr);
          const attributeDisplayText = questionTextHelper.getActorText(
            attr.displayText,
            subjectUser,
            participants!,
            userContext
          );

          const attrScoreFlaggedChange = behaviourFlaggedChanges?.find(
            (x) =>
              x.changeType === "BEHAVIOUR" &&
              x.behaviourAttributeId &&
              x.behaviourAttributeId === attr.attributeId
          );

          return (
            <div className="mb-2 px-4" key={`bhv${behaviour.key}_attr${ix}`}>
              {attrScoreFlaggedChange && participants && (
                <CollabDocFlaggedChange
                  change={attrScoreFlaggedChange}
                  loggedInUserId={userContext.user.id}
                  participants={participants}
                  question={question}
                  relatedComments={[]}
                  enforcedCommentText={undefined}
                />
              )}

              <Label text={attributeDisplayText} className="font-medium" />

              <BehaviourSlider
                behaviourOrAttributeId={attr.attributeId!}
                emptyTrackBgColourClassName={emptyTrackBgColourClassName}
                formBackgroundColorStyle={formBackgroundColorStyle}
                handleSliderChange={handleSliderChange}
                showValidationErrors={showValidationErrors}
                sliderValidationResult={sliderValidationResult}
                selectedTrackBgColourClassName={selectedTrackBgColourClassName}
                isReadOnly={isReadOnly}
                onBlur={handleSliderBlur}
                scaleOptions={attr.scaleOptions}
                sliderScoreDisplayType={sliderScoreDisplayType}
              />
            </div>
          );
        })}
        {sliderScoreDisplayType === "NUMERIC" &&
          sliders.length > 1 &&
          averageAttributeScore !== null && (
            <div className="text-center italic text-sm mt-2 select-none opacity-50">
              {`${t("Common.Average")}: ${mathsHelper.roundForDisplay(
                averageAttributeScore,
                2
              )}`}
            </div>
          )}
        {enforcedCommentQuestionText && (
          <div className="my-2 px-4">
            <BehaviourComment
              behaviourId={behaviour.key}
              formId={formId}
              questionIsRequired={!enforcedCommentIsOptional}
              questionTextTranslationKey={enforcedCommentQuestionText}
              formType={formType}
              currentValue={enforcedComment?.comment}
              onChange={onEnforcedCommentChange}
              onBlur={onEnforcedCommentBlur}
              showValidationErrors={showValidationErrors}
              flaggedChangeComponent={commentFlaggedChangeComponent}
              isReadOnly={isReadOnly}
            />
          </div>
        )}
        {showAddTaskButton && formType !== "JOURNEY" && (
          <AddFormTaskControl
            questionId={question.questionId}
            formId={formId}
            questionTasks={tasks}
            onChange={onChangeQuestionTasks}
            isReadOnly={isReadOnly}
            classNames={addTaskButtonClassName}
            formType={formType}
          />
        )}
      </div>
    </>
  );
}

export default BehaviourAttributeQuestion;
