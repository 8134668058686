import { useEffect, useState } from "react";
import IconButton from "./IconButton";
import FormattedDate, { DateDisplayMode } from "./FormattedDate";
import { useTranslation } from "react-i18next";
import ModalPopup from "./ModalPopup";
import Label from "./Label";
import Badge from "./Badge";
import ValidationWarning from "./ValidationWarning";
import DateInput from "./DateInput";
import { ValidationResult } from "../../types/forms";
import InfoAlert from "../alerts/InfoAlert";

interface EditableDateProps {
  currentValue: Date | null;
  dateDisplayMode: DateDisplayMode;
  dateIsRequired: boolean;
  onChangeSubmitted: (newValue: Date | null) => void;
  includeTimePicker?: boolean;
  convertFromUtc?: boolean;
  allowDatesInPast?: boolean;
  infoMessageText?: string | undefined;
}

/** Shows a date field with a button to allow it to be edited */
export const EditableDate = ({
  currentValue,
  dateDisplayMode,
  dateIsRequired,
  onChangeSubmitted,
  includeTimePicker = false,
  convertFromUtc = false,
  allowDatesInPast = false,
  infoMessageText = undefined,
}: EditableDateProps) => {
  // Constants
  const { t } = useTranslation();
  const dateInputId = "editable-date-input";

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [editedDateValue, setEditedDateValue] = useState(currentValue);
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  // Side effects
  useEffect(() => {
    // When the current value changes, update the edited value
    setEditedDateValue(currentValue);
  }, [currentValue]);

  // Functions
  const beginEditMode = () => {
    setIsEditing(true);
  };

  const validateSelectedDateValue = (newDate: Date | null) => {
    if (dateIsRequired && !newDate) {
      setValidationResult({
        isValid: false,
        errors: [{ errorType: "REQUIRED" }],
      });
    } else if (!allowDatesInPast && newDate && newDate < new Date()) {
      setValidationResult({
        isValid: false,
        errors: [{ errorType: "INVALID-DATE" }],
      });
    } else {
      setValidationResult(null);
    }

    setShowValidationErrors(true);
  };

  const handleDatePickerValueChange = (newDate: Date | null) => {
    setEditedDateValue(newDate);
    validateSelectedDateValue(newDate);
  };

  const handleModalPrimaryButtonClick = () => {
    setIsEditing(false);
    onChangeSubmitted(editedDateValue);
  };

  return (
    <>
      <button className="hover:cursor-pointer mr-1" onClick={beginEditMode}>
        {currentValue ? (
          <FormattedDate
            displayMode={dateDisplayMode}
            date={currentValue}
            convertFromUtc={convertFromUtc}
          />
        ) : (
          <>{t("Common.NotSet")}</>
        )}
      </button>
      <IconButton
        buttonType="EDIT"
        displayMode="ICON-ONLY"
        onClick={beginEditMode}
        buttonClassName="px-1"
      />
      {isEditing && (
        <ModalPopup
          isOpen={isEditing}
          onOpenChange={setIsEditing}
          title={t("Common.EditDate")}
          width="SMALL"
          primaryButtonText={t("Common.Save")}
          onPrimaryButtonClick={handleModalPrimaryButtonClick}
        >
          <>
            {infoMessageText && (
              <div className="mb-1">
                <InfoAlert message={infoMessageText} prefix={null} />
              </div>
            )}
            <div className="mb-1">
              <Label htmlFor={dateInputId} text={t("Common.Date")} />
              {!dateIsRequired && (
                <Badge text={t("Common.Optional")} classNames="text-gray-600" />
              )}
            </div>
            {showValidationErrors && (
              <ValidationWarning
                errors={validationResult ? validationResult.errors : []}
                isValid={validationResult ? validationResult.isValid : true}
              />
            )}
            <div className="inline-block max-w-[200px]">
              <DateInput
                inputId={dateInputId}
                value={editedDateValue}
                onChange={handleDatePickerValueChange}
                allowDatesInPast={false}
                className="rounded-md"
                isClearable={!dateIsRequired}
                showTimeSelect={includeTimePicker}
                placeholder={dateIsRequired ? undefined : t("Common.Optional")}
              />
            </div>
          </>
        </ModalPopup>
      )}
    </>
  );
};

export default EditableDate;
