import { BaseUserDetailsDto } from "../types/dtos/generic";
import SignedInUserDto from "../types/dtos/users/SignedInUserDto";

export const userDetailsHelper = {
  /** Takes a collection of users, and a target user, and pulls out the first name if that first name is unique in the collection.
   * E.g. if the target user is the only "Jane", return "Jane".
   * If there are multiple "Jane"s, return the full name instead.
   */
  getDisplayName(targetUserId: number, allUsers: BaseUserDetailsDto[]): string {
    let output = "";
    const matchedUser = allUsers.find((x) => x.userId === targetUserId);
    if (matchedUser) {
      const usersWithMatchingFirstName = allUsers.filter(
        (x) => x.firstName === matchedUser.firstName
      );
      if (usersWithMatchingFirstName.length > 1) {
        output = matchedUser.fullName;
      } else {
        output = matchedUser.firstName;
      }
    }
    return output;
  },

  // Takes a collection of users, and a target user, and pulls out the first name for the user that ISN'T the target user given.
  getOtherUsersDisplayName(
    targetUserId: number,
    allUsers: BaseUserDetailsDto[]
  ): string {
    let output = "";
    const otherUser = allUsers.find((x) => x.userId !== targetUserId);
    if (otherUser) {
      const usersWithMatchingFirstName = allUsers.filter(
        (x) => x.firstName === otherUser.firstName
      );
      if (usersWithMatchingFirstName.length > 1) {
        output = otherUser.fullName;
      } else {
        output = otherUser.firstName;
      }
    }
    return output;
  },

  getStatusNameFromStatusId(statusId: number): string {
    switch (statusId) {
      case 1:
        return "Active";
      case 2:
        return "Maternity Leave";
      case 4:
        return "Sick Leave";
      case 5:
        return "Left Company";
      case 6:
        return "Exit Review";
      case 7:
        return "Retirement";
      case 8:
        return "Deleted";
      case 9:
        return "Induction";
      default:
        return "Unknown";
    }
  },

  /** Get the number to show on a badge for a count of updates for the my dashboard nav item */
  getOwnUpdateCount: (user: SignedInUserDto): number => {
    // Just a placeholder until we plug something in
    return 0;
  },

  /** Get the number to show on a badge for a count of updates for the manager nav item */
  getManagerUpdateCount: (user: SignedInUserDto): number => {
    return user.managerUpdateCount;
  },
};

export default userDetailsHelper;
