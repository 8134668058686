import LocationSelection from "../../admin/SendNow/AudienceSelection/LocationSelection";
import CheckboxTreeNodeDto from "../../../types/dtos/generic/CheckboxTreeNodeDto";
import { __String } from "typescript";
import AppraisalLevelSelection from "../../admin/SendNow/AudienceSelection/AppraisalLevelSelection";
import JobTitleSelection from "../../admin/SendNow/AudienceSelection/JobTitleSelection";
import EmployeeStatusSelection from "../../admin/SendNow/AudienceSelection/EmployeeStatusSelection";
import { useTranslation } from "react-i18next";

interface AdvancedFilterModalProps {
  filterType: string;
  locationTreeChecked: string[];
  locationTreeExpanded: string[];
  onLocationTreeChecked(newState: string[]): void;
  onLocationTreeExpanded(newState: string[]): void;
  locations?: CheckboxTreeNodeDto[];
  isLocationsLoading: boolean;
  onClearSelections(filterType: string): void;
  appraisalLevels?: CheckboxTreeNodeDto[];
  isAppraisalLevelsLoading: boolean;
  onAppraisalLevelTreeChecked(newState: string[]): void;
  onAppraisalLevelTreeExpanded(newState: string[]): void;
  appraisalLevelTreeChecked: string[];
  appraisalLevelTreeExpanded: string[];
  jobTitles?: CheckboxTreeNodeDto[];
  isJobTitlesLoading: boolean;
  onJobTitleTreeChecked(newState: string[]): void;
  onJobTitleTreeExpanded(newState: string[]): void;
  jobTitleTreeChecked: string[];
  jobTitleTreeExpanded: string[];
  employeeStatuses?: CheckboxTreeNodeDto[];
  isEmployeeStatusesLoading?: boolean;
  onEmployeeStatusTreeChecked?(newState: string[]): void;
  onEmployeeStatusTreeExpanded?(newState: string[]): void;
  employeeStatusTreeChecked?: string[];
  employeeStatusTreeExpanded?: string[];
}

function AdvancedFilterModal({
  filterType,
  locationTreeChecked,
  locationTreeExpanded,
  onLocationTreeChecked,
  onLocationTreeExpanded,
  onClearSelections,
  locations,
  isLocationsLoading = false,
  appraisalLevels,
  isAppraisalLevelsLoading = false,
  onAppraisalLevelTreeChecked,
  onAppraisalLevelTreeExpanded,
  appraisalLevelTreeChecked,
  appraisalLevelTreeExpanded,
  jobTitles,
  isJobTitlesLoading = false,
  onJobTitleTreeChecked,
  onJobTitleTreeExpanded,
  jobTitleTreeChecked,
  jobTitleTreeExpanded,
  employeeStatuses,
  isEmployeeStatusesLoading = false,
  onEmployeeStatusTreeChecked,
  onEmployeeStatusTreeExpanded,
  employeeStatusTreeChecked,
  employeeStatusTreeExpanded,
}: AdvancedFilterModalProps) {
  const { t } = useTranslation();

  const hasLocationItemsToClear =
    filterType === "LOCATION" &&
    locationTreeChecked.length > 0 &&
    !isLocationsLoading;
  const hasAppraisalLevelItemsToClear =
    filterType === "APPRAISAL-LEVEL" &&
    appraisalLevelTreeChecked.length > 0 &&
    !isAppraisalLevelsLoading;
  const hasJobTitleItemsToClear =
    filterType === "JOB-TITLE" &&
    jobTitleTreeChecked.length > 0 &&
    !isJobTitlesLoading;
  const canShowClearButton =
    hasLocationItemsToClear ||
    hasAppraisalLevelItemsToClear ||
    hasJobTitleItemsToClear;

  return (
    <div className="py-3">
      {filterType == "LOCATION" && (
        <LocationSelection
          nodes={locations}
          treeChecked={locationTreeChecked}
          treeExpanded={locationTreeExpanded}
          onTreeChecked={onLocationTreeChecked}
          onTreeExpanded={onLocationTreeExpanded}
          isLoading={isLocationsLoading}
        />
      )}
      {filterType == "APPRAISAL-LEVEL" && (
        <AppraisalLevelSelection
          nodes={appraisalLevels}
          treeChecked={appraisalLevelTreeChecked}
          treeExpanded={appraisalLevelTreeExpanded}
          onTreeChecked={onAppraisalLevelTreeChecked}
          onTreeExpanded={onAppraisalLevelTreeExpanded}
          isLoading={isAppraisalLevelsLoading}
        />
      )}
      {filterType == "JOB-TITLE" && (
        <JobTitleSelection
          nodes={jobTitles}
          treeChecked={jobTitleTreeChecked}
          treeExpanded={jobTitleTreeExpanded}
          onTreeChecked={onJobTitleTreeChecked}
          onTreeExpanded={onJobTitleTreeExpanded}
          isLoading={isJobTitlesLoading}
        />
      )}
      {filterType == "EMPLOYEE-STATUS" && (
        <EmployeeStatusSelection
          nodes={employeeStatuses}
          treeChecked={employeeStatusTreeChecked}
          treeExpanded={employeeStatusTreeExpanded}
          onTreeChecked={onEmployeeStatusTreeChecked}
          onTreeExpanded={onEmployeeStatusTreeExpanded}
          isLoading={isEmployeeStatusesLoading}
        />
      )}
      {canShowClearButton && (
        <button
          className="mt-2 p-2 cursor-pointer"
          onClick={() => onClearSelections(filterType)}
        >
          {t('Common.ClearAll')}
        </button>
      )}
    </div>
  );
}

export default AdvancedFilterModal;
