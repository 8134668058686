import { t } from "i18next";
import UserWalkthrough from "./UserWalkthrough";

const getWalkthroughSteps = (hasClientGotAdvancedTaskTypes: boolean) => {
  if (!hasClientGotAdvancedTaskTypes) {
    return [
      {
        target: "#journey-widget",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.One")
      },
      {
        target: "#journey-widget",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Two")
      },
      {
        target: "#form-summary",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Three")
      },
      {
        target: "#timeline",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Four")
      },
      {
        target: "#task-area",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Five")
      },
      {
        target: "#add-catch-up",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Six")
      },
    ];
  } else { 
    return [
      {
        target: "#journey-widget",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.One")
      },
      {
        target: "#journey-widget",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Two")
      },
      {
        target: "#form-summary",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Three")
      },
      {
        target: "#dashboard-task-bar",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Four")
      },
      {
        target: "#your-dashboard-subnav",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Five_WithAdvancedTasks")
      },
      {
        target: "#add-catch-up",
        contentTranslationKey: t("Walkthrough.MyDashboard.Step.Six")
      },
    ];
  }
};

interface MyDashWalkthroughProps {
  hasClientGotAdvancedTaskTypes: boolean
  onFinished(): void;
}
function MyDashWalkthrough({
  hasClientGotAdvancedTaskTypes,
  onFinished
}: MyDashWalkthroughProps) {
  const steps = getWalkthroughSteps(hasClientGotAdvancedTaskTypes);
  return (
    <UserWalkthrough
      walkthroughType="MY-DASHBOARD"
      steps={steps}
      declinedWalkthrough={false}
      onFinished={onFinished}
    />
  );
}

export default MyDashWalkthrough;
