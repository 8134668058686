import { useState } from "react";
import { t } from "i18next";
import cx from "classnames";
import { FormType } from "../../../../types/forms";

interface DevelopmentOtherItemFormProps {
  /** Whether this question is being displayed in a collab doc or a journey. Controls styling (light vs dark bg) */
  formType: FormType;
  onAdd(customItemName: string): void;
}

function DevelopmentOtherItemForm({
  formType,
  onAdd,
}: DevelopmentOtherItemFormProps) {
  //State
  const [customOtherText, setCustomOtherText] = useState<string>("");

  // Events
  const handleClick = () => {
    if (customOtherText && customOtherText.trim().length > 0) {
      onAdd(customOtherText.trim());
      setCustomOtherText("");
    }
  };

  const enableButton = customOtherText && customOtherText.trim().length > 0;

  let containerBgClass = "bg-gray-200/60";
  let headingClassNames = "text-gray-700";
  let buttonClassNames = "";
  let textAreaClass = "bg-white/20 text-white placeholder-white/70";

  if (formType === "JOURNEY") {
    headingClassNames = "text-white";
    buttonClassNames = "text-gray-700";
    containerBgClass = "";
  } else {
    containerBgClass = "rounded-md bg-gray-200/60";
    textAreaClass = "bg-white text-gray-400 placeholder-text-gray-400";
  }

  return (
    <div className={cx("py-1 my-1 px-2", containerBgClass)}>
      <span className={cx("block text-sm mb-1", headingClassNames)}>
        {t("Common.Other")}
      </span>
      <div className="flex flex-row">
        <div className="flex-grow">
          <input
            type="text"
            value={customOtherText}
            onChange={(e) => setCustomOtherText(e.target.value)}
            placeholder={t(
              "Forms.LearningAndDevelopment.OtherTextboxPlaceholder"
            )}
            className={cx(
              textAreaClass,
              "text-sm py-1 rounded-md w-full border-none focus:outline-none focus:ring-0"
            )}
            maxLength={100}
          />
        </div>
        <div className="flex-initial">
          <button
            onClick={handleClick}
            disabled={!enableButton}
            className={cx(
              "ml-1 px-2 py-1 bg-white text-gray-500 rounded-md text-sm mb-1  hover:bg-white/70 disabled:cursor-not-allowed disabled:hover:bg-white/80",
              buttonClassNames
            )}
          >
            {t("Common.Add")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentOtherItemForm;
