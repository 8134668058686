import { useState } from "react";
import parse from "html-react-parser";
import { faCaretDown, faCaretLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TaskTypeHeaderBannerProps {
  title: string;
  body: string;
}

function TaskTypeHeaderBanner({ title, body }: TaskTypeHeaderBannerProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <div className="bg-slate-200 rounded-md mt-2 border pt-4 pb-6 px-8">
      <div
        className="flex flex-row cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="basis-4/5 text-xl heading py-2">{parse(title)}</div>
        <div className="basis-1/5 text-right">
          <FontAwesomeIcon
            size="lg"
            icon={isCollapsed ? faCaretLeft : faCaretDown}
          />
        </div>
      </div>
      {!isCollapsed && (
        <div className="task-type-banner-content font-medium text-gray-700 leading-7">
          {parse(body)}
        </div>
      )}
    </div>
  );
}

export default TaskTypeHeaderBanner;
