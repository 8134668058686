import { useOnlineStatus } from "../../hooks";
import { ReactComponent as TtLogo } from "../../images/ttNavLogo.svg";
import { ReactComponent as KoreroLogo } from "../../images/koreroNavLogo.svg";
import { ClientThemeKey } from "../../types/dtos/users/SignedInUserClientDetails";
import SignedInUserDto from "../../types/dtos/users/SignedInUserDto";

interface OfflineWarningProps {
  user: SignedInUserDto | null | undefined;
}

const OfflineWarning = ({ user }: OfflineWarningProps) => {
  const isOnline = useOnlineStatus();

  if (isOnline) return null;

  const themeKey: ClientThemeKey = user ? user.client.themeKey : "KORERO";
  const productName = user ? user.client.productName : "Korero";

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[100] overflow-hidden themed-overlay flex flex-col items-center justify-center">
      <div className="w-5/6 lg:w-1/2 py-12 px-4 bg-white text-center rounded-md">
        {themeKey === "KORERO" && (
          <KoreroLogo className="max-w-xs inline-block" />
        )}
        {themeKey === "TT" && <TtLogo className="max-w-xs inline-block" />}
        <p className="mt-6">
          We've detected that you don't currently have an active internet
          connection.
        </p>
        <p>
          Once your connection is restored, {productName} will be available
          again.
        </p>
      </div>
    </div>
  );
};

export default OfflineWarning;
