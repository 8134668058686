import { t } from "i18next";
import { ReactComponent as NoJourneyImage } from "../../images/noJourney.svg";
import { MyDashboardMode } from "../../types/dtos/users/SignedInUserDto";

interface JourneyEmptyStateProps {
  /** Whether or not the user has any (form summary) tabs displayed on their dashboard */
  userSummaryTabCount: number;
  userStatus: MyDashboardMode;
}

function JourneyEmptyState({
  userSummaryTabCount,
  userStatus,
}: JourneyEmptyStateProps) {
  return (
    <div>
      <div className="flex flex-col text-center text-white/80">
        <NoJourneyImage width={"10rem"} height={"8rem"} className="m-auto" />
        <span>{t("Journey.EmptyState.NoQuestionsAtThisTime")}</span>
        {userStatus === "STANDARD" && (
          <>
            {userSummaryTabCount === 1 && (
              <span>{t("Journey.EmptyState.ClickOnTab")}</span>
            )}
            {userSummaryTabCount > 1 && (
              <span>{t("Journey.EmptyState.ClickOnOneOfTheTabs")}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default JourneyEmptyState;
