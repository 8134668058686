import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquare,
  faSquareCheck,
  faChevronRight,
  faChevronDown,
  faPlusSquare,
  faMinusSquare,
  faLocation,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faMapMarkerAlt as fasMapMarkerAlt,
  faLocation as fasLocation,
} from "@fortawesome/pro-solid-svg-icons";
import { t } from "i18next";
import CheckboxTree from "react-checkbox-tree";
import { Label } from "../../../common";
import CheckboxTreeNodeDto from "../../../../types/dtos/generic/CheckboxTreeNodeDto";
import SmallLoader from "../../../loaders/SmallLoader";

interface LocationSelectionProps {
  nodes?: CheckboxTreeNodeDto[];
  treeChecked: string[];
  treeExpanded: string[];
  onTreeChecked(newState: string[]): void;
  onTreeExpanded(newState: string[]): void;
  isLoading: boolean;
  labelTranslationKey?: string | undefined;
}

const LocationSelection = ({
  nodes,
  treeChecked,
  treeExpanded,
  onTreeChecked,
  onTreeExpanded,
  isLoading,
  labelTranslationKey = undefined,
}: LocationSelectionProps) => {
  return (
    <div className="pt-2">
      {isLoading && (
        <div className="pt-2">
          <SmallLoader />
          <p className="text-center pt-1 text-[#959595] text-sm">
            {t("Common.LoadingLocations")}...
          </p>
        </div>
      )}
      {!isLoading && nodes && (
        <div>
          {labelTranslationKey && <Label text={t(labelTranslationKey)} />}
          <CheckboxTree
            checkModel="all"
            iconsClass="fa5"
            icons={{
              check: (
                <FontAwesomeIcon className="text-black" icon={faSquareCheck} />
              ),
              uncheck: (
                <FontAwesomeIcon className="text-black" icon={faSquare} />
              ),
              halfCheck: (
                <FontAwesomeIcon className="text-black" icon={faSquareCheck} />
              ),
              expandClose: (
                <FontAwesomeIcon className="text-black" icon={faChevronRight} />
              ),
              expandOpen: (
                <FontAwesomeIcon className="text-black" icon={faChevronDown} />
              ),
              expandAll: (
                <FontAwesomeIcon className="text-black" icon={faPlusSquare} />
              ),
              collapseAll: (
                <FontAwesomeIcon className="text-black" icon={faMinusSquare} />
              ),
              parentClose: (
                <FontAwesomeIcon
                  className="client-colour-icon"
                  icon={faLocation}
                />
              ),
              parentOpen: (
                <FontAwesomeIcon
                  className="client-colour-icon"
                  icon={fasLocation}
                />
              ),
              leaf: (
                <FontAwesomeIcon
                  className="client-colour-icon"
                  icon={fasMapMarkerAlt}
                />
              ),
            }}
            nodes={nodes}
            checked={treeChecked}
            expanded={treeExpanded}
            onCheck={onTreeChecked}
            onExpand={onTreeExpanded}
          />
        </div>
      )}
    </div>
  );
};

export default LocationSelection;
