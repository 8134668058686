import UserwalkthroughType from "../../types/generic/UserWalkthroughType";
import { ModalPopup } from "../common";
import { t } from "i18next";

interface UserWalkthroughIntroPopUpProps {
  walkthroughType: UserwalkthroughType;
  isOpen: boolean;
  onOpenChange(isOpen: boolean): void;
  runWalkthrough(run: boolean): void;
}

function UserWalkthroughIntroPopUp({
  walkthroughType,
  isOpen,
  onOpenChange,
  runWalkthrough,
}: UserWalkthroughIntroPopUpProps) {
  const myDashboardFinalStepContent = (
    <div className="text-center">
      <p className="text-2xl font-semibold primary-text mb-2 heading">
        {t("Walkthrough.MyDashboard.FinalStep.ReadyToStart")}
      </p>
      <p>{t("Walkthrough.MyDashboard.FinalStep.FurtherSupport")}</p>
    </div>
  );
  const managerDasboardFinalStepContent = (
    <div className="text-center">
      <p className="text-2xl font-semibold primary-text mb-2 heading">
        {t("Walkthrough.ManagerDashboard.FinalStep.YouAreAllSet")}
      </p>
      <p>{t("Walkthrough.ManagerDashboard.FinalStep.FurtherSupport")}</p>
    </div>
  );

  const handleCloseWalkthrough = () => {
    onOpenChange(false);
    runWalkthrough(false);
  };

  return (
    <>
      <ModalPopup
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        title={""}
        width="SMALL"
      >
        <p className="text-center pt-2 px-4">
          {" "}
          {walkthroughType === "MY-DASHBOARD"
            ? myDashboardFinalStepContent
            : managerDasboardFinalStepContent}
        </p>
        <div className="flex flex-col mt-4 mb-4 py-4 items-center">
          <button
            className="btn-primary max-w-fit"
            onClick={handleCloseWalkthrough}
          >
            {t("Walkthrough.FinalStep.Options.Finish")}
          </button>
        </div>
      </ModalPopup>
    </>
  );
}

export default UserWalkthroughIntroPopUp;
