import { Outlet } from "react-router-dom";

function Body() {
  return (
    <div id="app-body">
      <Outlet /> {/* Outlet is where the inner/nested route loads */}
    </div>
  );
}

export default Body;
