
import { t } from "i18next";
import cx from "classnames";

interface MultiChoiceQuestionSearchFieldProps {
  searchTerm: string;
  onSearch(searchTerm: string): void;
}

const MultiChoiceQuestionSearchField = ({
  searchTerm,
  onSearch,
}: MultiChoiceQuestionSearchFieldProps) => {  
  return (
    <div className="flex flex-row-reverse mb-1 print:hidden">
      <div className="flex-initial">
        <input
          type="text"
          placeholder={`${t("Common.Search")}...`}
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)}
          className={cx(
            "text-sm border-0 border-b border-b-gray-200 py-1 bg-transparent focus:outline-none focus:ring-0 focus:border-b-gray-200"
          )}
        />
      </div>
    </div>
  );
};

export default MultiChoiceQuestionSearchField;
