import { t } from "i18next";
import AnalyticsWidgetUiDetailsDto from "../AnalyticsWidgetUiDetailsDto";

export interface BarChartItemGroup {
  id: string;
  label: string;
  items: BarChartItem[];
  totalItemCount: number;
}

export interface BarChartItem {
  id: string;
  label: string;
  value: number;
  translateLabel: boolean;
}

export class BarChartDataTransformer {
  /** Get the display text for an item in the bar chart, translated if necessary */
  private getItemDisplayText(
    item: any,
    widget: AnalyticsWidgetUiDetailsDto
  ): string {
    let displayText = item.label;
    const legendItem = widget.legendItems?.find(
      (leg) => leg.key === item.label
    );

    if (
      item.hasOwnProperty("translateLabel") &&
      item["translateLabel"] === true
    ) {
      displayText = t(item.label);
    } else if (legendItem) {
      displayText = legendItem.translatable
        ? t(legendItem.textValue)
        : legendItem.textValue;
    }
    return displayText;
  }

  private getDataSetItems(
    dataset: any,
    widget: AnalyticsWidgetUiDetailsDto
  ): BarChartItem[] {
    if (
      !dataset ||
      !dataset.items ||
      !Array.isArray(dataset.items) ||
      dataset.items.length === 0
    ) {
      return [];
    }

    return dataset.items.map((item: any) => {
      return {
        id: item.label,
        label: this.getItemDisplayText(item, widget),
        value: Number(item.value),
      };
    });
  }

  /** Take the server data and transform and translate it into a shape easier for the chart component to use */
  transformData(widget: AnalyticsWidgetUiDetailsDto): BarChartItemGroup[] {
    try {
      if (
        widget?.datasets &&
        widget.datasets &&
        Array.isArray(widget.datasets) &&
        widget.datasets.length > 0
      ) {
        return widget.datasets.map((dataset, ix) => {
          const datasetItems = this.getDataSetItems(dataset, widget);
          return {
            id: `dataset${ix}`,
            label: dataset.translateTitle ? t(dataset.title) : dataset.title,
            items: datasetItems,
            totalItemCount: datasetItems.reduce(
              (acc, item) => acc + item.value,
              0
            ),
          };
        });
      }
    } catch (error) {
      console.error(
        "Unable to transform data for bar chart: " + widget?.widgetId
      );
    }

    return [];
  }
}
