import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import cx from "classnames";
import { exportComponentAsJPEG } from "react-component-export-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faChartLine,
  faTable,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { ModalPopup, Popover, SafeRenderHtml } from "../common";
import { AnalyticsWidgetUiDetailsDto } from "../../types/analytics";
import {
  ComplexScoreOverview,
  HistogramChart,
  NetPromoterScore,
  PieChart,
} from "./charts";
import { HorizontalBarChart, VerticalBarChart } from "./charts/BarCharts";
import { ErrorBoundary } from "react-error-boundary";
import ChartError from "./charts/ChartError";
import AnalyticsFilterInputs from "../../types/analytics/AnalyticsFilterInputs";
import AnalyticsDataTable from "./tables/AnalyticsDataTable";
import { GroupedJourneys } from "../../types/analytics/FilterAvailableValues";
import { useAuth } from "react-oidc-context";
import analyticsCustomEndpointApi from "../../api/analytics/analyticsCustomEndpointApi";
import { fileHelper } from "../../helpers";
import NineBoxGrid from "./charts/nineBoxGrid/NineBoxGrid";
import { KeyValuePair } from "../../types/generic";
import { BarChartDrilldownValue } from "../../types/analytics/charts/BarChartDrilldownValue";
import NineBoxGridEditor from "./charts/nineBoxGridEditor/NineBoxGridEditor";
import { AnalyticsPageWidgetSummary } from "../../types/analytics/AnalyticsWidgetUiDetailsDto";

interface ExportableChartAreaProps {
  children: React.ReactNode;
}

const ExportableChartArea = React.forwardRef<
  HTMLDivElement,
  ExportableChartAreaProps
>((props, ref) => <div ref={ref}>{props.children}</div>);

interface AnalyticsWidgetProps {
  widgetDetails: AnalyticsWidgetUiDetailsDto;
  filters: AnalyticsFilterInputs;
  journeys: GroupedJourneys[];
  dynamicPageId?: number | undefined;
  /** This is used for widgets being able to see what else is on the same page, e.g. to refresh 9BG data from the 9BG editor */
  allWidgetsForPage: AnalyticsPageWidgetSummary[];
  onRequestOtherWidgetRefresh?: (
    dynamicWidgetId: number,
    onSuccess: () => void,
    onError: () => void
  ) => void;
}

function AnalyticsWidget({
  widgetDetails,
  filters,
  journeys,
  dynamicPageId,
  allWidgetsForPage,
  onRequestOtherWidgetRefresh,
}: AnalyticsWidgetProps) {
  const auth = useAuth();
  const api = new analyticsCustomEndpointApi(auth.user?.access_token);
  const widgetTitle = t(widgetDetails.widgetTitle);

  const [displayMode, setDisplayMode] = useState<"CHART" | "TABLE">("CHART");
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState<boolean>(false);
  const [isExportingImage, setIsExportingImage] = useState<boolean>(false);
  const [customTableDataApiParams, setCustomTableDataApiParams] = useState<
    KeyValuePair<string, string>[] | null
  >(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const hideDisplayModeButtons =
    widgetDetails.displayType == "NINE-BOX-GRID-EDITOR";

  useEffect(() => {
    // Reset the custom table data API params when the widget changes
    setCustomTableDataApiParams(null);
  }, [widgetDetails.widgetId]);

  useEffect(() => {
    // Reset the display mode when the widget gets manually refreshed via another widget
    // (otherwise the user could be in a table view and then the chart gets refreshed, so that table
    // needs refreshing, which will happen when they drilldown again)
    setDisplayMode("CHART");
  }, [widgetDetails.datasets]);

  useEffect(() => {
    if (isExportingImage) {
      // Do the actual image export after the state change has been applied to render any legends etc
      exportComponentAsJPEG(componentRef, { fileName: "analytics-chart.jpeg" });
      // Hide the legends after the image has exported
      setIsExportingImage(false);
    }
  }, [isExportingImage]);

  const triggerHelpModal = () => {
    setShowHelpModal(true);
  };

  const exportChartArea = () => {
    // Set the state value, which triggers the `useEffect` after, allowing the legends etc
    // to be rendered before generating the image
    setIsExportingImage(true);
  };

  const downloadExcelFile = () => {
    if (!widgetDetails.excelApiEndpoint) return;

    // Disable the export button to avoid batch clicking
    setIsDownloadingExcel(true);

    const onError = (error: any) => {
      // Re-enable the download button
      setIsDownloadingExcel(false);
      console.error("Unable to download file", error);
    };
    const onSuccess = () => {
      // Re-enable the download button
      setIsDownloadingExcel(false);
    };

    const filenameWithoutExtension = fileHelper.createFilenameFromString(
      widgetTitle,
      true
    );

    api.downloadSpreadsheetFile(
      widgetDetails.excelApiEndpoint,
      filenameWithoutExtension,
      filters,
      onSuccess,
      onError
    );
  };

  const onChartDrilldown = (
    drilldownValue: string,
    searchType: "EXACT" | "RANGE",
    /** For stacked bars, we need to know the bar outer value, and the item within the bar which was clicked, which is the normal `drilldownValue` */
    outerDrilldownValue?: string | undefined
  ) => {
    setCustomTableDataApiParams([
      { key: "drilldownValue", value: drilldownValue },
      { key: "drilldownType", value: searchType },
      { key: "outerDrilldownValue", value: outerDrilldownValue || "" },
    ]);
    setDisplayMode("TABLE");
  };

  const onNineBoxGridDrilldown = (boxNumber: number) => {
    setCustomTableDataApiParams([
      { key: "boxNumber", value: boxNumber.toString() },
    ]);
    setDisplayMode("TABLE");
  };

  // If a table API endpoint is provided, show the option to toggle between chart and table
  const canShowTable =
    widgetDetails.tableApiEndpoint && widgetDetails.tableApiEndpoint.length > 0;

  const displayModeButtonClassNames =
    "px-2 py-1 text-sm bg-gray-100 hover:bg-gray-200 border-[1px] border-gray-200 text-gray-400 hover:text-gray-600 disabled:text-gray-700 disabled:cursor-not-allowed disabled:bg-gray-200";

  // Get the help content, if there is any
  let helpContent: string | null = null;
  if (widgetDetails.widgetHelp && widgetDetails.widgetHelp.length > 0) {
    if (widgetDetails.widgetType === "DYNAMIC") {
      helpContent = widgetDetails.widgetHelp;
    } else {
      const translatedHelpContent = t(widgetDetails.widgetHelp);
      if (translatedHelpContent !== widgetDetails.widgetHelp) {
        helpContent = translatedHelpContent;
      }
    }
  }

  const handleBarChartClick = (item: BarChartDrilldownValue) => {
    onChartDrilldown(
      item.value.toString(),
      "EXACT",
      item.stackedOuterValue?.toString()
    );
  };

  // Get the nine box grid widget, if there is one.
  // In theory there could be more than one, but it's unlikely.
  // This code here exists to pass into a NineBoxGridEditor widget, so the user
  // can refresh the 9BG data in another widget after editing the underlying data.
  const nineBoxGridWidget = allWidgetsForPage.find(
    (x) => x.displayType === "NINE-BOX-GRID"
  );

  const exportButtonWithPopover = (
    <Popover
      title="Export..."
      openState={showSubMenu}
      onOpenStateChange={setShowSubMenu}
      triggerElement={
        <button
          className={cx(
            displayModeButtonClassNames,
            "rounded-md",
            canShowTable ? "rounded-l-none" : ""
          )}
        >
          <FontAwesomeIcon icon={faFileExport} size="xs" /> Export
        </button>
      }
    >
      <ul>
        {widgetDetails.excelApiEndpoint &&
          widgetDetails.excelApiEndpoint.length > 0 && (
            <li>
              <button
                className="hover:underline disabled:cursor-not-allowed"
                onClick={downloadExcelFile}
                disabled={isDownloadingExcel}
              >
                Excel file
              </button>
            </li>
          )}
        {displayMode === "CHART" && (
          <li>
            <button className="hover:underline" onClick={exportChartArea}>
              Image file
            </button>
          </li>
        )}
      </ul>
    </Popover>
  );

  return (
    <div data-widget-id={widgetDetails.widgetId}>
      <div className="flex flex-col lg:flex-row ">
        <div className="grow">
          <div>
            <h3 className="text-xl font-semibold inline-block mr-1">
              {widgetTitle}
            </h3>

            {helpContent && (
              <>
                <button
                  onClick={triggerHelpModal}
                  className="ml-2 text-gray-500 bg-gray-100 hover:bg-gray-200 border-gray-200 border-[1px] px-2 rounded-md"
                >
                  <FontAwesomeIcon size="xs" icon={faInfoCircle} />
                  <span className="ml-1 text-sm">Info</span>
                </button>
                <ModalPopup
                  title={widgetTitle}
                  isOpen={showHelpModal}
                  onOpenChange={setShowHelpModal}
                  width="SMALL"
                >
                  <SafeRenderHtml
                    htmlText={helpContent}
                    containerClassName="analytics-widget-help-container"
                  />
                </ModalPopup>
              </>
            )}
          </div>
          <div className="hidden md:block italic mb-2 text-gray-600 text-sm">
            {t(widgetDetails.widgetSubTitle)}
          </div>
        </div>

        {!hideDisplayModeButtons && (
          <div className="hidden flex-none md:block text-right pr-4 pt-2">
            {!canShowTable && <>{exportButtonWithPopover}</>}
            {canShowTable && (
              <>
                <button
                  className={cx(
                    displayModeButtonClassNames,
                    "rounded-md rounded-r-none"
                  )}
                  onClick={() => setDisplayMode("CHART")}
                  disabled={displayMode === "CHART"}
                  title="Show chart"
                >
                  <FontAwesomeIcon icon={faChartLine} size="xs" /> Chart
                </button>
                <button
                  className={cx(displayModeButtonClassNames)}
                  onClick={() => setDisplayMode("TABLE")}
                  disabled={displayMode === "TABLE"}
                  title="Show data"
                >
                  <FontAwesomeIcon icon={faTable} size="xs" /> Data
                </button>
                {exportButtonWithPopover}
              </>
            )}
          </div>
        )}
      </div>

      {displayMode === "CHART" && (
        <ErrorBoundary FallbackComponent={ChartError}>
          <ExportableChartArea ref={componentRef}>
            {widgetDetails.displayType === "PIE" && (
              <PieChart
                widget={widgetDetails}
                onSegmentClick={(value) => onChartDrilldown(value, "EXACT")}
              />
            )}
            {widgetDetails.displayType === "HORIZONTAL-BAR" && (
              <HorizontalBarChart
                widget={widgetDetails}
                isExportingImage={isExportingImage}
                onBarClick={handleBarChartClick}
              />
            )}
            {widgetDetails.displayType === "VERTICAL-BAR" && (
              <VerticalBarChart
                widget={widgetDetails}
                isExportingImage={isExportingImage}
                onBarClick={handleBarChartClick}
              />
            )}
            {widgetDetails.displayType === "HISTOGRAM" && (
              <HistogramChart
                widget={widgetDetails}
                isExportingImage={isExportingImage}
                onBarClick={(item) =>
                  onChartDrilldown(item.value.toString(), "RANGE")
                }
              />
            )}
            {widgetDetails.displayType === "NET-PROMOTER-SCORE" && (
              <NetPromoterScore
                widget={widgetDetails}
                onDrilldown={(score) =>
                  onChartDrilldown(score.toString(), "EXACT")
                }
              />
            )}
            {widgetDetails.displayType === "NINE-BOX-GRID" && (
              <NineBoxGrid
                widget={widgetDetails}
                onDrilldown={onNineBoxGridDrilldown}
              />
            )}
            {widgetDetails.displayType === "COMPLEX-SCORE-OVERVIEW" && (
              <ComplexScoreOverview widget={widgetDetails} />
            )}
            {widgetDetails.displayType === "NINE-BOX-GRID-EDITOR" && (
              <NineBoxGridEditor
                widget={widgetDetails}
                analyticsPageId={dynamicPageId!}
                filters={filters}
                nineBoxGridWidgetId={
                  nineBoxGridWidget ? nineBoxGridWidget.dynamicWidgetId : null
                }
                onRequestOtherWidgetRefresh={onRequestOtherWidgetRefresh}
              />
            )}
          </ExportableChartArea>
        </ErrorBoundary>
      )}
      {displayMode === "TABLE" && (
        <AnalyticsDataTable
          filters={filters}
          apiUrl={widgetDetails.tableApiEndpoint!}
          journeys={journeys}
          legendItems={widgetDetails.legendItems}
          otherApiParams={customTableDataApiParams}
        />
      )}
    </div>
  );
}

export default AnalyticsWidget;
