import React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";

interface LabelProps {
  /** The equivalent of the `for` attribute, pointing to the associated input's id attribute */
  htmlFor?: string;
  /** The text to render inside the label. If there's a more complex scenario for the content, add a child node instead */
  text?: string;
  /** If specified, this will be used instead of the `text` prop */
  children?: React.ReactNode;
  className?: string;
}

const Label = ({
  htmlFor,
  text,
  children,
  className = "font-medium text-gray-700",
}: LabelProps) => {
  return (
    <LabelPrimitive.Root htmlFor={htmlFor} className={className}>
      {text}
      {children}
    </LabelPrimitive.Root>
  );
};

export default Label;
