import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { mathsHelper } from "../../../helpers";
import { useCheckMobileScreen } from "../../../hooks";
import { AnalyticsWidgetUiDetailsDto } from "../../../types/analytics";
import { PieChartDataTransformer } from "../../../types/analytics/charts/PieCharts";
import { chartConstants } from "./ChartConstants";
import ChartLegend from "./ChartLegend";
import CustomChartTooltip from "./CustomChartTooltip";

interface PieDatum {
  fill: string | undefined;
  id: string;
  label: string;
  value: number;
}

export interface PieChartProps {
  widget: AnalyticsWidgetUiDetailsDto;
  onSegmentClick?: undefined | ((segmentValue: string) => void);
}

function PieChart({ widget, onSegmentClick = undefined }: PieChartProps) {
  const isMobile = useCheckMobileScreen();
  const legendIsDisabled = true; // Did the work to show legends, different on mobile and desktop, but they don't seem needed. Leaving this here in case we want to revisit.
  const dataTransformer = new PieChartDataTransformer();
  const data = dataTransformer.transformData(widget);

  // Get the total count of items to render under the pie
  const totalItems = data.reduce((total, current) => {
    return total + current.value;
  }, 0);

  // Set patterns used on pie segments
  const patternedData = data.map((item, ix) => ({
    ...item,
    // Alternate patterns between solid, dots and lines
    fill: ix % 2 === 0 ? "dots" : ix % 3 === 0 ? "lines" : undefined,
  }));

  // Get the legend items for the mobile legend
  const legendKeys = data.map((item) => item.label);

  const handleSegmentClick = (item: ComputedDatum<PieDatum>) => {
    if (!onSegmentClick) return;
    onSegmentClick(item.label.toString());
  };

  const setSegmentCursor = (
    event: React.MouseEvent<SVGPathElement>,
    cursor: "pointer" | "default"
  ) => {
    // Don't set the cursor if it can't be clicked on anyway
    if (!onSegmentClick) return;
    const segmentElement = event.target as SVGPathElement;
    if (!segmentElement || !segmentElement.style) return;
    segmentElement.style.cursor = cursor;
  };

  const handleMouseEnter = (
    data: ComputedDatum<PieDatum>,
    event: React.MouseEvent<SVGPathElement>
  ) => {
    // Set some styles when the user hovers over a segment
    setSegmentCursor(event, "pointer");
  };

  const handleMouseLeave = (
    data: ComputedDatum<PieDatum>,
    event: React.MouseEvent<SVGPathElement>
  ) => {
    // Undo some styles when the user stops hovering over a segment
    setSegmentCursor(event, "default");
  };

  return (
    <>
      <div style={{ height: chartConstants.height }}>
        <ResponsivePie
          data={patternedData}
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={chartConstants.coloursArray}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabel={
            "label" /* Use the `label` prop instead of the `id` prop for the pie labels */
          }
          arcLinkLabelsSkipAngle={5}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          arcLabel={(item) =>
            mathsHelper.getPercentageForDisplay(
              Number(item.value),
              totalItems,
              0
            )
          }
          tooltip={(item) => {
            let tooltipContent = item.datum.value.toString();
            const percentage = mathsHelper.getPercentageForDisplay(
              Number(item.datum.value),
              totalItems,
              1
            );
            if (percentage && percentage.length > 0) {
              tooltipContent = `${tooltipContent} (${percentage})`;
            }
            return (
              <CustomChartTooltip
                text={item.datum.label}
                value={tooltipContent}
              />
            );
          }}
          defs={chartConstants.patterns}
          fill={[
            { match: (d) => d.data?.fill === "dots", id: "dots" },
            { match: (d) => d.data?.fill === "lines", id: "lines" },
          ]}
          legends={
            isMobile || legendIsDisabled
              ? []
              : [
                  {
                    anchor: "left",
                    direction: "column",
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemsSpacing: 10,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]
          }
          onClick={onSegmentClick ? handleSegmentClick : undefined}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      {isMobile && !legendIsDisabled && <ChartLegend keys={legendKeys} />}
      <div className="font-semibold text-gray-500 text-center lg:text-right lg:pr-10">
        Total: {totalItems}
      </div>
    </>
  );
}

export default PieChart;
