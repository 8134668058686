import journeyApi from "../api/forms/journeyApi";
import {
  NewBehaviourAnswerDto,
  NewGoalReviewAnswerDto,
  NewMultiChoiceAnswerDto,
  NewTextAnswerDto,
} from "../types/dtos/collab-docs/answers";
import NewDevelopmentAnswerDto from "../types/dtos/collab-docs/answers/NewDevelopmentAnswerDto";
import { NewCommentDto, NewEnforcedCommentDto } from "../types/dtos/forms";
import {
  JourneyAnswerSetInfo,
  JourneyAnswerSubmissionDto,
  JourneyObjectSubmissionDto,
  JourneySavedAnswerDto,
  JourneySavedObjectDto,
} from "../types/dtos/journeys/answers";
import {
  BehaviourQuestionAnswerValue,
  DevelopmentQuestionAnswerValue,
  GoalReviewQuestionAnswerValue,
  MultipleChoiceQuestionAnswerValue,
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionTasks,
} from "../types/forms";
import AnswerSetDto from "../types/forms/AnswerSetDto";

export class journeyAnswerHelper {
  constructor(api: journeyApi) {
    this.api = api;
  }

  readonly api: journeyApi;

  /** Takes a single answer, and saves it, handling which answer type it
   * is and posting it to the correct API endpoint
   */
  saveAnswer(
    answerSetInfo: JourneyAnswerSetInfo,
    questionId: string,
    answerDetails: QuestionAnswer,
    /** The success callback */
    onSuccess: (saveResponse: JourneySavedAnswerDto) => void,
    /** The error callback */
    onError: () => void
  ): void {
    const submissionDto: JourneyAnswerSubmissionDto<any> = {
      answerObject: {},
      clientFormVersionIds: answerSetInfo.clientFormVersionIds,
      employeeDetailId: answerSetInfo.employeeDetailId,
      journeyReference: answerSetInfo.journeyReference,
      answerSetUniqueId: answerSetInfo.answerSetUniqueId,
      answerSetDateCreated: answerSetInfo.answerSetDateCreated,
    };

    switch (answerDetails.answerType) {
      case "TEXT":
        submissionDto.answerObject = this._getNewTextAnswerDto(
          questionId,
          answerDetails.answer,
          answerDetails.formId
        );
        this.api.saveTextAnswer(submissionDto, onSuccess, onError);
        break;
      case "MULTICHOICE":
        submissionDto.answerObject = this._getNewMultiChoiceAnswerDto(
          questionId,
          answerDetails.answer,
          answerDetails.formId
        );
        this.api.saveMultiChoiceAnswer(submissionDto, onSuccess, onError);
        break;
      case "BEHAVIOUR":
        submissionDto.answerObject = this._getNewBehaviourAnswerDto(
          questionId,
          answerDetails.answer,
          answerDetails.formId
        );
        this.api.saveBehaviourAnswer(submissionDto, onSuccess, onError);
        break;
      case "GOAL-REVIEW":
        submissionDto.answerObject = this._getNewGoalReviewAnswerDto(
          questionId,
          answerDetails.answer,
          answerDetails.formId
        );
        this.api.saveGoalReviewAnswer(submissionDto, onSuccess, onError);
        break;
      case "DEVELOPMENT-LIST":
        submissionDto.answerObject = this._getNewDevelopmentAnswerDto(
          questionId,
          answerDetails.answer,
          answerDetails.formId
        );
        this.api.saveDevelopmentAnswer(submissionDto, onSuccess, onError);
        break;
      default:
        console.log(
          "Answer type save not implemented: " + answerDetails.answerType
        );
        break;
    }
  }

  saveComment(
    answerSetInfo: JourneyAnswerSetInfo,
    loggedInUserId: number,
    questionId: string,
    commentText: string | null,
    /** The success callback */
    onSuccess: (saveResponse: JourneySavedObjectDto) => void,
    /** The error callback */
    onError: () => void
  ) {
    if (!questionId || questionId.length === 0) return;

    const dto: JourneyObjectSubmissionDto<NewCommentDto> = {
      answerSetUniqueId: answerSetInfo.answerSetUniqueId,
      answerSetDateCreated: answerSetInfo.answerSetDateCreated,
      clientFormVersionIds: answerSetInfo.clientFormVersionIds,
      employeeDetailId: answerSetInfo.employeeDetailId,
      journeyReference: answerSetInfo.journeyReference,
      object: {
        authorId: loggedInUserId,
        comment: commentText,
        commentType: "STANDARD",
        questionId: questionId,
        replyToCommentId: null,
        timestamp: new Date(),
      },
    };

    this.api.submitComment(dto, onSuccess, onError);
  }

  saveEnforcedComment(
    answerSetInfo: JourneyAnswerSetInfo,
    loggedInUserId: number,
    questionId: string,
    behaviourId: number | null,
    goalId: number | null,
    clientFormId: number,
    commentText: string | null,
    /** The success callback */
    onSuccess: (saveResponse: JourneySavedObjectDto) => void,
    /** The error callback */
    onError: () => void
  ) {
    if (!questionId || questionId.length === 0 || behaviourId === 0) return;

    const dto: JourneyObjectSubmissionDto<NewEnforcedCommentDto> = {
      answerSetUniqueId: answerSetInfo.answerSetUniqueId,
      answerSetDateCreated: answerSetInfo.answerSetDateCreated,
      clientFormVersionIds: answerSetInfo.clientFormVersionIds,
      employeeDetailId: answerSetInfo.employeeDetailId,
      journeyReference: answerSetInfo.journeyReference,
      object: {
        authorId: loggedInUserId,
        behaviourId: behaviourId,
        goalId: goalId,
        comment: commentText,
        commentType: "ENFORCED",
        questionId: questionId,
        replyToCommentId: null,
        timestamp: new Date(),
        clientFormId: clientFormId
      },
    };

    this.api.submitEnforcedComment(dto, onSuccess, onError);
  }

  saveQuestionTasks(
    answerSetInfo: JourneyAnswerSetInfo,
    tasks: QuestionTasks,
    /** The success callback */
    onSuccess: (saveResponse: JourneySavedObjectDto) => void,
    /** The error callback */
    onError: () => void
  ) {
    const dto: JourneyObjectSubmissionDto<QuestionTasks> = {
      answerSetUniqueId: answerSetInfo.answerSetUniqueId,
      answerSetDateCreated: answerSetInfo.answerSetDateCreated,
      clientFormVersionIds: answerSetInfo.clientFormVersionIds,
      employeeDetailId: answerSetInfo.employeeDetailId,
      journeyReference: answerSetInfo.journeyReference,
      object: {
        ...tasks,
      },
    };

    this.api.saveQuestionTasks(dto, onSuccess, onError);
  }

  getOrCreateAnswerSet(
    answerSetInfo: JourneyAnswerSetInfo,
    /** The success callback */
    onSuccess: (saveResponse: JourneySavedObjectDto) => void,
    /** The error callback */
    onError: () => void
  ) {
    const dto: JourneyObjectSubmissionDto<AnswerSetDto> = {
      answerSetUniqueId: answerSetInfo.answerSetUniqueId,
      answerSetDateCreated: answerSetInfo.answerSetDateCreated,
      clientFormVersionIds: answerSetInfo.clientFormVersionIds,
      employeeDetailId: answerSetInfo.employeeDetailId,
      journeyReference: answerSetInfo.journeyReference,
      object: {
        id: null
      },
    };

    this.api.getOrCreateAnswerSet(dto, onSuccess, onError);
  }

  /** Convert the state object into one ready to send to the API */
  _getNewTextAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    clientFormId: number
  ): NewTextAnswerDto {
    return {
      doc: null,
      docDateCreated: null,
      questionId: questionId,
      text: newValue as string,
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewMultiChoiceAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    clientFormId: number
  ): NewMultiChoiceAnswerDto {
    // Convert the null/number/number[] value into a consistent MultipleChoiceQuestionAnswerValue[] value
    // for passing to the server
    let selectedValues: MultipleChoiceQuestionAnswerValue[] = [];
    if (newValue !== null) {
      if (Array.isArray(newValue)) {
        if (newValue.length > 0) {
          selectedValues = [
            ...(newValue as MultipleChoiceQuestionAnswerValue[]),
          ];
        }
      }
    }

    return {
      doc: null,
      docDateCreated: null,
      questionId: questionId,
      values: selectedValues,
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewBehaviourAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    clientFormId: number
  ): NewBehaviourAnswerDto {
    return {
      doc: null,
      docDateCreated: null,
      questionId: questionId,
      answers: newValue as BehaviourQuestionAnswerValue[],
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewGoalReviewAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    clientFormId: number
  ): NewGoalReviewAnswerDto {
    return {
      doc: null,
      docDateCreated: null,
      questionId: questionId,
      answers: newValue as GoalReviewQuestionAnswerValue[],
      formId: clientFormId,
    };
  }

  /** Convert the state object into one ready to send to the API */
  _getNewDevelopmentAnswerDto(
    questionId: string,
    newValue: QuestionAnswerValue,
    clientFormId: number
  ): NewDevelopmentAnswerDto {
    // Convert the DevelopmentQuestionAnswerValue[] value into a consistent DevelopmentQuestionAnswerValue[] value
    // for passing to the server
    let selectedValues: DevelopmentQuestionAnswerValue[] = [];
    if (newValue !== null) {
      selectedValues = [...(newValue as DevelopmentQuestionAnswerValue[])];
    }

    return {
      doc: null,
      docDateCreated: null,
      questionId: questionId,
      answers: selectedValues,
      formId: clientFormId,
    };
  }
}

export default journeyAnswerHelper;
