import UserTaskComment from "../../../../types/dtos/tasks/advanced-tasks/comments/UserTaskCommentDto";
import { useTranslation } from "react-i18next";
import IndividualUserTaskComment from "./IndividualUserTaskComment";

interface TaskCommentsListReadOnlyProps {
  comments: UserTaskComment[] | null;
}

function TaskCommentsListReadOnly({ comments }: TaskCommentsListReadOnlyProps) {
  const { t } = useTranslation();

  if (!comments || comments.length === 0) {
    return null;
  }

  return (
    <div className="mt-2 pr-2">
      <h3 className="text-lg font-semibold mt-2">{t("Common.Comments")}</h3>
      {comments.map((comment) => (
        <IndividualUserTaskComment
          key={comment.commentId}
          commentId={comment.commentId}
          comment={comment.comment}
          commentTimestamp={comment.dateCreated}
          authorId={comment.employeeId}
          authorProfilePicUrl={comment.employeeProfilePic}
          authorFullName={
            comment.employeeFirstName + " " + comment.employeeLastName
          }
          authorInitials={comment.employeeInitials}
          allowDelete={false}
          disableDeleteButton={true}
          onDeleteClick={() => {}}
          allowEdit={false}
          disableEditButton={true}
          onEditClick={() => {}}
        />
      ))}
    </div>
  );
}

export default TaskCommentsListReadOnly;
