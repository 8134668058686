import SignedInUserDto from "../types/dtos/users/SignedInUserDto";

const moduleAccessHelper = {
  userHasModule: function (user: SignedInUserDto, moduleName: string): boolean {
    return (
      user.client?.enabledModules &&
      user.client.enabledModules.indexOf(moduleName) >= 0
    );
  },
};

export default moduleAccessHelper;
