import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { t } from "i18next";
import cx from "classnames";
import FuzzySearch from "fuzzy-search";
import { ValidationResult } from "../../types/forms";
import {
  AutoComplete,
  DateInput,
  GenericDropDownList,
  Label,
  RadioButtonGroup,
} from "../common";
import PeoplePicker from "../common/PeoplePicker";
import { AutoCompleteSelectedValue, KeyValuePair } from "../../types/generic";
import catchUpApi from "../../api/dashboard/catchUpApi";
import MeetingFormDto from "../../types/catch-ups/MeetingFormDto";
import MeetingFormFields from "../../types/catch-ups/MeetingFormFields";
import { CatchUpValidator, userDetailsHelper } from "../../helpers";
import ValidationWarning from "../common/ValidationWarning";
import UserContext from "../../state/UserContext";
import CatchUpDiscussionType from "../../types/catch-ups/CatchUpDiscussionType";
import PeoplePickerUserDto from "../../types/dtos/generic/PeoplePickerUserDto";
import { BaseUserDetailsDto } from "../../types/dtos/generic";
import { useEffectOnce } from "react-use";
import MultipleChoiceOptionNumericId from "../../types/forms/MultipleChoiceOptions";

const participantInputId = "catch-up-participant";
const datePickerInputId = "catch-up-date";
const subjectFieldId = "catch-up-subject";
const subjectUserRadiosId = "catch-up-subject-user";

interface ManageCatchUpFormProps {
  formFieldValues: MeetingFormFields;
  onFormChange(
    newValues: MeetingFormFields,
    catchUpDiscussionType?: CatchUpDiscussionType
  ): void;
  /** If you want custom content above the form fields, supply a value for this prop */
  customHeaderContent?: undefined | JSX.Element;
  /** If you want custom content/buttons below the form, supply a value for this prop */
  customFooterContent?: undefined | JSX.Element;
  /** If we already know who the catch up will be with, specify a value */
  otherParticipant?: BaseUserDetailsDto | undefined;
  /** If this catch up is already tied to an AnswerSet/CollabDoc, we can hide the subject field */
  answerSetUniqueId?: string | null | undefined;
  /** If this is from a journey or collab doc, a discussion might already exist. */
  discussionExists?: boolean | null | undefined;
  showValidationErrors: boolean;
}

const ManageCatchUpForm = ({
  formFieldValues,
  onFormChange,
  showValidationErrors,
  answerSetUniqueId = undefined,
  customHeaderContent = undefined,
  customFooterContent = undefined,
  otherParticipant = undefined,
  discussionExists,
}: ManageCatchUpFormProps) => {
  // Auth/API
  const auth = useAuth();
  const apiCatchUp = new catchUpApi(auth.user?.access_token);
  const userContext = useContext(UserContext);
  // State
  const [peoplePickerValue, setPeoplePickerValue] = useState<
    number | undefined
  >(otherParticipant?.userId);
  const [autoCompleteValue, setAutoCompleteValue] = useState<
    string | undefined
  >();
  const [
    selectedClientFormValueFromDropdown,
    setSelectedClientFormValueFromDropdown,
  ] = useState<string | undefined>();
  const [availableMeetingForms, setAvailableMeetingForms] = useState<
    MeetingFormDto[]
  >([]);
  const [subjectUserRadioOptions, setSubjectUserRadioOptions] = useState<
    MultipleChoiceOptionNumericId[]
  >([]);

  let autoCompleteSuggestionsList: KeyValuePair<string, string>[] = [];
  let clientFormDropdownList: KeyValuePair<number, string>[] = [];
  const genericCatchUpFormId = userContext.user.client.genericCatchUpFormId;

  if (availableMeetingForms) {
    autoCompleteSuggestionsList = availableMeetingForms.map((mf) => ({
      key: mf.formId.toString(),
      value: t(mf.formName),
    }));

    clientFormDropdownList = availableMeetingForms.map((mf) => ({
      key: mf.formId,
      value: t(mf.formName),
    }));
  }

  useEffectOnce(() => {
    // If the other participant is specified, we set the logged in user
    // as the subject user
    if (otherParticipant) {
      onFormChange({
        ...formFieldValues,
        subjectUserId: userContext.user.id,
        otherParticipantId: otherParticipant.userId,
      });
      setSubjectUserRadioButtonValues(
        otherParticipant.userId,
        otherParticipant.firstName,
        otherParticipant.fullName
      );
    }
  });

  useEffect(() => {
    const subjectUserFieldIsValid = validator.subjectUserFieldIsValid(
      formFieldValues.subjectUserId
    );
    if (subjectUserFieldIsValid) {
      getAutoCompleteFormSuggestions();
    }
  }, [formFieldValues.subjectUserId]);

  // Validation
  const subjectFieldIsRequired = answerSetUniqueId === undefined;
  const validator = new CatchUpValidator(subjectFieldIsRequired);

  const validationResults = {
    valid: new ValidationResult(true, []),
    invalid: new ValidationResult(false, [{ errorType: "REQUIRED" }]),
  };

  // Events
  const searchFormsBySearchTerms = (searchValue: string) => {
    if (!availableMeetingForms || availableMeetingForms.length === 0) {
      return [];
    }

    const searchSource = availableMeetingForms.map((mf) => {
      return {
        formId: mf.formId,
        formName: t(mf.formName),
        searchPhrases: mf.searchPhrases,
      };
    });

    const searcher = new FuzzySearch(
      searchSource,
      ["formName", "searchPhrases"],
      {
        caseSensitive: false,
        sort: true,
      }
    );
    var formMatches = searcher.search(searchValue);
    if (formMatches!.length > 0) {
      return formMatches!.map((mf) => ({
        key: mf.formId.toString(),
        value: t(mf.formName),
      }));
    }
    return [];
  };

  // Events
  const handleAutoCompleteValueSelected = (
    newValue: AutoCompleteSelectedValue | null
  ) => {
    if (newValue && newValue.isCustomValue) {
      setAutoCompleteValue(newValue.displayText);
      if (newValue.key) {
        const formId = parseInt(newValue.key, 10);
        onFormChange(
          {
            ...formFieldValues,
            subjectClientFormId: formId,
            title: newValue.displayText,
          },
          "REVIEW"
        );
      }
    }
  };

  const handleCustomTypedTitle = (customCatchUpTitle: string) => {
    if (genericCatchUpFormId) {
      const formId = genericCatchUpFormId;
      onFormChange(
        {
          ...formFieldValues,
          subjectClientFormId: formId,
          title: customCatchUpTitle,
        },
        "GENERAL-CATCH-UP"
      );
    }
  };

  const handleClientFormChangeFromDropdown = (selectedValue: string | null) => {
    if (selectedValue) {
      const formId = parseInt(selectedValue, 10);
      const catchUpTitle = clientFormDropdownList.find(
        (cf) => formId === cf.key
      )?.value;
      setSelectedClientFormValueFromDropdown(selectedValue);
      onFormChange(
        {
          ...formFieldValues,
          subjectClientFormId: formId,
          title: catchUpTitle,
        },
        "GENERAL-CATCH-UP"
      );
    }
  };

  function setSubjectUserRadioButtonValues(
    userId: number,
    firstName: string,
    fullName: string
  ) {
    // Get the display name for the selected user (full name if their first name matches the logged in user's)
    const otherUserDisplayName = userDetailsHelper.getDisplayName(userId, [
      {
        userId: userId,
        firstName: firstName,
        fullName: fullName,
      },
      {
        userId: userContext.user.id,
        firstName: userContext.user.firstName,
        fullName: userContext.user.fullName,
      },
    ]);

    // Create the radio button values
    const meOption: MultipleChoiceOptionNumericId = {
      isSelected: false,
      text: "Me",
      optionId: userContext.user.id,
      value: userContext.user.id,
      allowCustomText: false,
    };
    const themOption: MultipleChoiceOptionNumericId = {
      isSelected: false,
      text: otherUserDisplayName,
      optionId: userId,
      value: userId,
      allowCustomText: false,
    };

    // Update the state with the radio button options
    setSubjectUserRadioOptions([meOption, themOption]);
  }

  const handleParticipantChange = (selectedUser: PeoplePickerUserDto) => {
    setPeoplePickerValue(selectedUser.userId);
    setSubjectUserRadioButtonValues(
      selectedUser.userId,
      selectedUser.firstName,
      selectedUser.fullName
    );
  };

  const handleSubjectUserRadioChange = (
    radioOptions: MultipleChoiceOptionNumericId[]
  ) => {
    const selectedUser = radioOptions.find((ro) => ro.isSelected);
    const otherUser = radioOptions.find((ro) => !ro.isSelected);

    const selectedSubjectUserId = selectedUser
      ? Number(selectedUser.value)
      : undefined;
    const otherParticipantId = otherUser ? Number(otherUser.value) : undefined;

    // Update the radio button state so we can show which one is selected
    setSubjectUserRadioOptions([...radioOptions]);

    // Update the parent form state
    onFormChange({
      ...formFieldValues,
      subjectUserId: selectedSubjectUserId,
      otherParticipantId: otherParticipantId,
    });
  };

  const handleTargetDateAndTimeChange = (newValue: Date | null) => {
    onFormChange({
      ...formFieldValues,
      targetDate: newValue ? newValue : undefined,
    });
  };

  const getAutoCompleteFormSuggestions = () => {
    // Call the API to load the necessary state
    if (!formFieldValues.subjectUserId) return;

    const successCallback = (meetingForms: MeetingFormDto[]) => {
      setAvailableMeetingForms(meetingForms);
    };
    const errorCallback = (error: any) => {
      console.error(error);
    };

    apiCatchUp.GetAvailableMeetingForms(
      formFieldValues.subjectUserId,
      successCallback,
      errorCallback
    );
  };

  const peoplePickerIsValid = Number(peoplePickerValue) > 0;
  const subjectUserIsValid =
    validator.subjectUserFieldIsValid(formFieldValues.subjectUserId) &&
    validator.participantFieldIsValid(formFieldValues.otherParticipantId);

  return (
    <>
      {customHeaderContent ? customHeaderContent : null}
      {discussionExists == true && (
        <div>
          You already have a meeting scheduled for this. You can change the date
          of this below.
        </div>
      )}
      {!answerSetUniqueId && (
        <>
          {!otherParticipant && (
            <div className="mb-2">
              <Label
                htmlFor={participantInputId}
                text={t("CatchUps.Popup.WhoWith")}
              />
              <PeoplePicker
                inputId={participantInputId}
                className="block mt-2 p-2 w-full bg-gray-100 border-0"
                nonMultiSelectChangeValueType="USER-DTO"
                onPeoplePickerChange={handleParticipantChange}
                showValidationErrors={showValidationErrors}
                excludeLoggedInUserFromResults={true}
                validationResult={
                  peoplePickerIsValid
                    ? validationResults.valid
                    : validationResults.invalid
                }
              />
            </div>
          )}

          {peoplePickerIsValid && (
            <div className="mb-2">
              <Label htmlFor={subjectUserRadiosId} text="Who is this about?" />
              <RadioButtonGroup
                uniqueFieldName={subjectUserRadiosId}
                values={subjectUserRadioOptions}
                onChange={handleSubjectUserRadioChange}
                layout="HORIZONTAL"
                containerClassNames="mt-1"
                showValidationErrors={showValidationErrors}
                validationResult={
                  subjectUserIsValid
                    ? validationResults.valid
                    : validationResults.invalid
                }
              />
            </div>
          )}
        </>
      )}

      <div className="mb-2">
        <Label
          htmlFor={datePickerInputId}
          text={t("CatchUps.Popup.TargetDate")}
          className={cx(
            !answerSetUniqueId ? "font-medium text-gray-700" : "text-white"
          )}
        />
        {showValidationErrors &&
          !validator.dateFieldIsValid(formFieldValues.targetDate) && (
            <ValidationWarning
              isValid={false}
              errors={validationResults.invalid.errors}
            />
          )}
        <div className="flex flex-row w-full gap-4">
          <DateInput
            onChange={handleTargetDateAndTimeChange}
            inputId={datePickerInputId}
            showValidationErrors={showValidationErrors}
            value={formFieldValues.targetDate}
            showTimeSelect={true}
            placeholder="Select a date and time"
            allowDatesInPast={false}
          />
        </div>
      </div>
      {!answerSetUniqueId && (
        <div>
          <Label htmlFor={subjectFieldId} text={t("CatchUps.Popup.Subject")} />
          {showValidationErrors &&
            !validator.subjectFieldIsValid(
              formFieldValues.subjectClientFormId
            ) && (
              <ValidationWarning
                isValid={false}
                errors={validationResults.invalid.errors}
              />
            )}
          {/**If the client has a generic catch up form set up allow the user to type and give them suggestions.  If there isnt a clientFormId show a dropdown*/}
          {genericCatchUpFormId && (
            <AutoComplete
              selectedValue={autoCompleteValue}
              onValueSelected={handleAutoCompleteValueSelected}
              handleCustomTypedTitle={handleCustomTypedTitle}
              valuesList={autoCompleteSuggestionsList}
              placeholder=""
              className="text-gray-400 block mt-2 p-2 w-full bg-gray-100 border-0 rounded-md outline-none focus:ring-0"
              inputId="autocomplete-input"
              suggestionPropertyToDisplay="VALUE"
              allowCustomTypedValues={true}
              customSearchFunction={searchFormsBySearchTerms}
              disabled={!subjectUserIsValid}
            />
          )}
          {!genericCatchUpFormId && (
            <GenericDropDownList
              currentValue={
                selectedClientFormValueFromDropdown
                  ? selectedClientFormValueFromDropdown
                  : null
              }
              items={clientFormDropdownList}
              onChange={handleClientFormChangeFromDropdown}
              className="block w-full"
              bgColorClassName="bg-gray-100"
              inputId={subjectFieldId}
              showValidationErrors={showValidationErrors}
            />
          )}
        </div>
      )}
      {customFooterContent ? customFooterContent : null}
    </>
  );
};

export default ManageCatchUpForm;
