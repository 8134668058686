import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "../common";
import ManageCatchUpPopup from "../catch-ups/ManageCatchUpPopup";
import AddCatchUpButtonArea from "../timeline/AddCatchUpButtonArea";
import { ClientTaskType } from "../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import { KeyValuePair } from "../../types/generic";
import { useAuth } from "react-oidc-context";
import myDashboardApi from "../../api/dashboard/myDashboardApi";
import TaskBarItemDto from "../../types/dtos/dashboard-task-bar/TaskBarItemDto";
import AnswerSetRelatedTaskBarItemDto from "../../types/dtos/dashboard-task-bar/AnswerSetRelatedTaskBarItemDto";
import DashboardTaskBarEmptyState from "./DashboardTaskBarEmptyState";
import AnswerSetRelatedTaskBarDisplay from "./answer-set-related-items/AnswerSetRelatedTaskBarDisplay";
import TaskRelatedTaskBarDisplay from "./task-related-items/TaskRelatedTaskBarDisplay";
import TaskRelatedTaskBarItemDto from "../../types/dtos/dashboard-task-bar/TaskRelatedTaskBarItemDto";
import TaskBarRestrictionBanner from "./TaskBarRestrictionBanner";
import AppRoutes from "../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import TaskRequirementBadge from "../task-management/TaskRequirementBadge";
import taskRestrictionHelper from "../../helpers/taskRestrictionHelper";
import { DashboardTaskBarLoadingItem } from "./DashboardTaskBarLoadingItem";
import { JourneyFormGroupDto } from "../../types/dtos/journeys";
import DashboardTaskBarLockedState from "./DashboardTaskBarLockedState";

interface DashboardTaskBarProps {
  taskTypes: ClientTaskType[];
  clientColour: string;
  formDetails: JourneyFormGroupDto | undefined | null;
  hasJourneyBeenSubmitted: boolean;
}

function DashboardTaskBar({ taskTypes, clientColour, formDetails, hasJourneyBeenSubmitted }: DashboardTaskBarProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const dashboardApi = new myDashboardApi(auth.user?.access_token);
  const navigate = useNavigate();

  // MODAL STATE
  const [manageCatchUpModalIsOpen, setManageCatchUpModalIsOpen] =
    useState<boolean>(false);
  const onManageCatchUpModalOpen = () => {
    setManageCatchUpModalIsOpen(true);
  };
  const onManageCatchUpModalOpenChange = (isOpen: boolean) => {
    setManageCatchUpModalIsOpen(isOpen);
  };

  // COMPONTENT STATE
  const [filterOptions, setFilterOptions] = useState<
    KeyValuePair<string, string>[]
  >([]);
  const [filterCategory, setFilterCategory] = useState<string>();
  const [includeCompleted, setIncludeCompleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<TaskBarItemDto[]>([]);
  const [isLockedDueToFeaturedJourney, setIsLockedDueToFeaturedJourney] = useState<boolean>(false);

  const isJourneyOrCatchUpSelected =
    filterCategory === "JOURNEY" || filterCategory === "CATCH-UP";
  const selectedTaskType = taskTypes.find((x) => x.id === filterCategory);

  let showRestrictionBanner: boolean = false;
  showRestrictionBanner =
    selectedTaskType != null &&
    !taskRestrictionHelper.doesUserHaveTasksThatSatisfyRestrictions(
      items as TaskRelatedTaskBarItemDto[],
      selectedTaskType.minTaskCount,
      selectedTaskType.maxTaskCount,
      selectedTaskType.countRestriction,
      selectedTaskType.categories
    );

  // On initial load set the filter options using the task types and journeys/catch-ups
  useEffect(() => {
    let filterOptions = [] as KeyValuePair<string, string>[];

    taskTypes
      .sort((a, b) => a.sequence - b.sequence)
      .forEach((taskType) => {
        filterOptions.push({
          key: taskType.id,
          value: t(taskType.pluralNameTranslationKeyIdentifier),
        });
      });
    filterOptions.push({
      key: "JOURNEY",
      value: t("DashboardTaskBar.Filters.Journeys"),
    });
    filterOptions.push({
      key: "CATCH-UP",
      value: t("DashboardTaskBar.Filters.CatchUps"),
    });

    setFilterOptions(filterOptions);
    setFilterCategory(filterOptions[0].key);
  }, []);

  useEffect(() => {
    // Before we load the items we need to clear the current items - This was added as state
    // was getting confused beforehand and blending both sets of items together sprodaically
    setItems([]);

    // If the filter category has changed then we need to load the items for the task bar
    if (filterCategory != undefined) {
      setIsLoading(true);

      dashboardApi.getItemsForTaskBar(
        filterCategory,
        includeCompleted,
        (data: TaskBarItemDto[]) => {
          setIsLoading(false);
          setItems(data);
        },
        (error: any) => {
          console.error(error.message);
          setIsLoading(false);
        }
      );
    }
  }, [filterCategory, includeCompleted]);

  const navigateToTaskPage = () => {
    if (selectedTaskType) {
      const redirectUrl = AppRoutes.yourJourney.tasks.generatePathRoute(
        selectedTaskType.id
      );
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    if (formDetails && filterCategory && selectedTaskType) {
      let currentlyFeaturedInJourney = formDetails.forms.some(
        (form) => form.questions.some(
          (question) => question.questionType === "ADVANCED-TASK-MANAGEMENT"
            && question.taskManagementConfig?.advancedTaskTypeId === selectedTaskType?.id
        )
      );

      // If currently in journey, but the journey has been submitted... unlock it and reload items
      if (currentlyFeaturedInJourney == true && hasJourneyBeenSubmitted) {
        currentlyFeaturedInJourney = false;

        dashboardApi.getItemsForTaskBar(
          filterCategory,
          includeCompleted,
          (data: TaskBarItemDto[]) => {
            setIsLoading(false);
            setItems(data);
            setIsLockedDueToFeaturedJourney(currentlyFeaturedInJourney);
          },
          (error: any) => {
            console.error(error.message);
            setIsLoading(false);
          }
        );
      } else {
        setIsLockedDueToFeaturedJourney(currentlyFeaturedInJourney);
      }
    }
  }, [formDetails, selectedTaskType, hasJourneyBeenSubmitted]);

  return (
    <>
      <div className="flex flex-col h-full">
        <div id="dashboard-task-bar ">
          <div className="flex mb-2">
            <select
              className="header-select grow text-left"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              {filterOptions.map((opt) => (
                <option key={opt.key} value={opt.key}>
                  {opt.value}
                </option>
              ))}
            </select>
          </div>
          {isLoading && (
            <div className="mt-4">
              <DashboardTaskBarLoadingItem />
              <DashboardTaskBarLoadingItem />
              <DashboardTaskBarLoadingItem />
            </div>
          )}

          {!isLoading && isJourneyOrCatchUpSelected && (
            <div className="mb-1 text-right pr-1">
              <span className="text-xs pr-1">
                {t("Common.IncludeCompleted")}?
              </span>
              <Switch
                checked={includeCompleted}
                onChange={(isChecked) => setIncludeCompleted(isChecked)}
                size="sm"
              />
            </div>
          )}

          {/* On TaskType selection (not journey or catch up) if there is a min/max task count set we need to show that restriction */}
          {!isLoading &&
            !isJourneyOrCatchUpSelected &&
            !isLockedDueToFeaturedJourney &&
            showRestrictionBanner &&
            selectedTaskType &&
            (selectedTaskType.minTaskCount != null ||
              selectedTaskType.maxTaskCount != null) && (
              <TaskBarRestrictionBanner
                selectedTaskType={selectedTaskType}
                hasActiveTasks={items.length > 0}
              />
            )}
          <div
            className={
              items.length > 0 ? "" : "rounded border border-gray-200 bg-white"
            }
          >
            <>
              {!isLoading && isLockedDueToFeaturedJourney && (
                <DashboardTaskBarLockedState
                  selectedTaskType={selectedTaskType}
                  journeyTitle={formDetails?.title}
                />
              )}
              {!isLoading && !isLockedDueToFeaturedJourney && (
                <>
                  {items.length === 0 && (
                    <DashboardTaskBarEmptyState
                      itemType={filterCategory!}
                      selectedTaskType={selectedTaskType}
                      isJourneyOrCatchUpSelected={isJourneyOrCatchUpSelected}
                      navigateToTaskPage={navigateToTaskPage}
                    />
                  )}
                  {items.length > 0 && (
                    <>
                      {/* Show answer set related items */}
                      {isJourneyOrCatchUpSelected && (
                        <AnswerSetRelatedTaskBarDisplay
                          filterCategory={filterCategory}
                          items={items as AnswerSetRelatedTaskBarItemDto[]}
                          clientColour={clientColour}
                        />
                      )}
                      {/* Show task related items */}
                      {!isJourneyOrCatchUpSelected && (
                        <>
                          {filterCategory && (
                            <TaskRelatedTaskBarDisplay
                              filterCategory={filterCategory}
                              items={items as TaskRelatedTaskBarItemDto[]}
                              clientColour={clientColour}
                              selectedTaskType={selectedTaskType}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
            {!isLoading &&
              !isJourneyOrCatchUpSelected &&
              selectedTaskType != undefined &&
              !isLockedDueToFeaturedJourney && (
                <>
                  {(selectedTaskType.minTaskCount != null ||
                    selectedTaskType.maxTaskCount != null ||
                    selectedTaskType.categories?.find(
                      (x) => x.minTaskCount || x.maxTaskCount
                    ) != null) && (
                      <div className="mt-1 flex flex-row justify-center lg:mb-5">
                        <TaskRequirementBadge
                          tasks={items as TaskRelatedTaskBarItemDto[]}
                          minTasks={selectedTaskType.minTaskCount}
                          maxTasks={selectedTaskType.maxTaskCount}
                          countRestriction={selectedTaskType.countRestriction}
                          activeCategories={selectedTaskType.categories}
                          taskType={selectedTaskType}
                          isTeamTaskManagement={false}
                          origin="OUTSIDE-JOURNEY"
                        />
                      </div>
                    )}
                </>
              )}
          </div>
        </div>
        <hr className="mb-3 mt-3 ml-1" />
        <div>
          <AddCatchUpButtonArea onModalOpen={onManageCatchUpModalOpen} />
        </div>
        <ManageCatchUpPopup
          isOpen={manageCatchUpModalIsOpen}
          onOpenChange={(open) => onManageCatchUpModalOpenChange(open)}
          refreshParentTaskList={() => console.log("Handle Refresh")}
        />
      </div>
    </>
  );
}

export default DashboardTaskBar;
