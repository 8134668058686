import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../state/UserContext";
import { ModalPopup } from "../common";
import { useAuth } from "react-oidc-context";
import catchUpApi from "../../api/dashboard/catchUpApi";
import CatchUpDto from "../../types/catch-ups/CatchUpDto";
import ManageCatchUpForm from "./ManageCatchUpForm";
import MeetingFormFields from "../../types/catch-ups/MeetingFormFields";
import { CatchUpValidator } from "../../helpers";
import CatchUpDiscussionType from "../../types/catch-ups/CatchUpDiscussionType";
import CatchUpSuccess from "./CatchUpSuccess";
import CollabDocCatchUpDetails from "../../types/catch-ups/CollabDocCatchUpDetails";
import collabDocApi from "../../api/forms/collabDocApi";
import { BaseUserDetailsDto } from "../../types/dtos/generic";

const modalTitle = "CatchUps.Popup.RequestCatchUp";

interface ManageCatchUpPopupProps {
  /** Whether or not the modal popup is open */
  isOpen: boolean;
  /**If this modal was opened from within a collab doc then we know the answerSetGuidId and */
  collabDocMeetingDetails?: CollabDocCatchUpDetails | undefined;
  /** Handle modal closing in the parent state */
  onOpenChange(open: boolean): void;
  /**A function to call once you complete or cancel this task.  Optional because we don't need to refresh the task list if this is called from within a collab doc*/
  refreshParentTaskList?(): void;
  /**UserId of the subject to have a catch up with */
  userId?: number;
  /** User details of the subject to have a catch up with */
  userDetails?: BaseUserDetailsDto;
}

function ManageCatchUpPopup({
  isOpen,
  collabDocMeetingDetails = undefined,
  onOpenChange,
  refreshParentTaskList,
  userId = undefined,
  userDetails = undefined,
}: ManageCatchUpPopupProps) {
  // Context
  const userContext = useContext(UserContext);
  // Auth/API
  const auth = useAuth();
  const apiCatchUp = new catchUpApi(auth.user?.access_token);
  const apiCollabDoc = new collabDocApi(auth.user?.access_token);
  // State
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [subjectUserId, setSubjectUserId] = useState<number | undefined>();
  const [otherParticipantId, setOtherParticipantId] = useState<
    number | undefined
  >();
  const [targetDateAndTime, setTargetDateAndTime] = useState<
    Date | undefined
  >();
  const [selectedClientFormId, setSelectedClientFormId] = useState<
    number | undefined
  >();
  const [catchUpTitle, setCatchUpTitle] = useState<string>();
  const [catchUpDiscussionType, setCatchUpDiscussionType] =
    useState<CatchUpDiscussionType>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [savedCatchUpCalendarObject, setSavedCatchUpCalendarObject] =
    useState<CatchUpDto | null>(null);
  const [isLoadingCalendar, setIsLoadindCalendar] = useState<boolean>(false);

  //Effects
  useEffect(() => {
    if (!isOpen) {
      setShowValidationErrors(false);
      setShowSuccessMessage(false);
      setSavedCatchUpCalendarObject(null);
      onResetForm();
    }
    handleFieldChanges({
      subjectUserId: userId,
      otherParticipantId: undefined,
      subjectClientFormId: undefined,
      targetDate: undefined,
      title: undefined,
    });
  }, [isOpen]);

  const formFields: MeetingFormFields = {
    subjectUserId: subjectUserId,
    otherParticipantId: otherParticipantId,
    subjectClientFormId: selectedClientFormId,
    targetDate: targetDateAndTime,
    title: catchUpTitle,
  };

  const handleFieldChanges = (
    newValues: MeetingFormFields,
    catchUpDiscussionType?: CatchUpDiscussionType
  ) => {
    setSubjectUserId(newValues.subjectUserId);
    setOtherParticipantId(newValues.otherParticipantId);
    setSelectedClientFormId(newValues.subjectClientFormId);
    setTargetDateAndTime(newValues.targetDate);
    setCatchUpTitle(newValues.title);
    setCatchUpDiscussionType(catchUpDiscussionType);
  };

  const onResetForm = () => {
    handleFieldChanges({
      subjectUserId: undefined,
      otherParticipantId: undefined,
      subjectClientFormId: undefined,
      targetDate: undefined,
      title: undefined,
    });
  };

  // Validation
  const checkFormIsValid = (): boolean => {
    if (collabDocMeetingDetails) {
      const validator = new CatchUpValidator(false);
      return validator.isValidCollabDocCatchUp(
        targetDateAndTime,
        collabDocMeetingDetails
      );
    } else {
      const validator = new CatchUpValidator(true);
      return validator.isFullyValid({
        subjectUserId: subjectUserId,
        otherParticipantId: otherParticipantId,
        subjectClientFormId: selectedClientFormId,
        targetDate: targetDateAndTime,
        title: catchUpTitle,
      });
    }
  };

  // Events
  const submitAddCatchUp = (
    catchUp: CatchUpDto,
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    const onCatchUpAddSuccess = (newCatchUp: CatchUpDto | null) => {
      successCallback();
      refreshParentTaskList && refreshParentTaskList();
      setSavedCatchUpCalendarObject(newCatchUp);
    };

    const onCatchUpAddError = (error: any) => {
      console.log("Error saving catch up", error);
      errorCallback();
    };
    if (collabDocMeetingDetails) {
      apiCollabDoc.CreateNewOrUpdateMeeting(
        catchUp,
        onCatchUpAddSuccess,
        onCatchUpAddError
      );
    } else {
      apiCatchUp.CreateNewCatchUp(
        catchUp,
        onCatchUpAddSuccess,
        onCatchUpAddError
      );
    }
  };

  const addToCalendar = () => {
    setIsLoadindCalendar(true);
    const onCatchUpAddSuccess = () => {
      setIsLoadindCalendar(false);
    };

    const onCatchUpAddError = (error: any) => {
      console.log("Error adding to calendar", error);
    };
    if (savedCatchUpCalendarObject != null) {
      apiCatchUp.CreateCalendarFileForCatchUp(
        savedCatchUpCalendarObject,
        onCatchUpAddSuccess,
        onCatchUpAddError
      );
    }
  };

  const handleSecondaryButtonClick = () => {
    onResetForm();
    onOpenChange(false);
    setShowValidationErrors(false);
    setShowSuccessMessage(false);
  };

  const handlePrimaryButtonClick = () => {
    setShowValidationErrors(false);
    setIsSaving(true);
    const successCallback = () => {
      // Reset to not display any validation issues
      setShowValidationErrors(false);
      setIsSaving(false);
      onResetForm();

      // Close the modal
      setShowSuccessMessage(true);
    };
    const errorCallback = () => {
      setIsSaving(false);
      console.log("Error saving catch up");
    };
    if (checkFormIsValid()) {
      let catchUpDetails: CatchUpDto;
      if (collabDocMeetingDetails) {
        catchUpDetails = {
          answerSetUniqueId: collabDocMeetingDetails.answerSetUniqueId,
          scheduledDate: targetDateAndTime!,
          scheduledDateLocaleString:
            targetDateAndTime!.toLocaleString([], {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
              hourCycle: "h12",
            }) +
            " (" +
            targetDateAndTime!
              .toLocaleTimeString([], { timeZoneName: "short" })
              .split(" ")[1] +
            ")",
          subjectEmployeeId: collabDocMeetingDetails.subjectUserId,
          participantEmployeeId: collabDocMeetingDetails.participantEmployeeId!,
          discussionType: "REVIEW",
        };
      } else {
        catchUpDetails = {
          clientFormId: selectedClientFormId!,
          scheduledDate: targetDateAndTime!,
          scheduledDateLocaleString:
            targetDateAndTime!.toLocaleString([], {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
              hourCycle: "h12",
            }) +
            " (" +
            targetDateAndTime!
              .toLocaleTimeString([], { timeZoneName: "short" })
              .split(" ")[1] +
            ")",
          initiatedByEmployeeId: userContext.user.id,
          subjectEmployeeId: subjectUserId!,
          participantEmployeeId: otherParticipantId!,
          title: catchUpTitle!,
          discussionType: catchUpDiscussionType!,
        };
      }
      submitAddCatchUp(catchUpDetails, successCallback, errorCallback);
    } else {
      setShowValidationErrors(true);
      setIsSaving(false);
      return;
    }
  };

  const modalContent =
    showSuccessMessage === false ? (
      <>
        <ManageCatchUpForm
          formFieldValues={formFields}
          onFormChange={handleFieldChanges}
          showValidationErrors={showValidationErrors}
          answerSetUniqueId={collabDocMeetingDetails?.answerSetUniqueId}
          discussionExists={collabDocMeetingDetails?.discussionExists}
          otherParticipant={userDetails}
        />
      </>
    ) : (
      <>
        <CatchUpSuccess
          onAddToCalendar={addToCalendar}
          isLoading={isLoadingCalendar}
        />
      </>
    );
  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonText={
        showSuccessMessage === false ? t("Common.Save") : undefined
      }
      title={showSuccessMessage === false ? t(modalTitle) : null}
      onSecondaryButtonClick={handleSecondaryButtonClick}
      secondaryButtonText={t("Common.Close")}
      isLoading={isSaving}
    >
      {modalContent}
    </ModalPopup>
  );
}

export default ManageCatchUpPopup;
