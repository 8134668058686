export const mathsHelper = {
  roundForDisplay(inputNumber: number, decimalPlaces: number): string {
    return inputNumber.toFixed(decimalPlaces);
  },
  getPercentage(
    value: number,
    total: number,
    decimalPlaces: number
  ): number | undefined {
    if (isNaN(value) || isNaN(total)) {
      return undefined;
    }
    const percentValue = (100 / total) * value;
    const roundedValue = mathsHelper.roundForDisplay(
      percentValue,
      decimalPlaces
    );
    return Number(roundedValue);
  },
  getPercentageForDisplay(
    value: number,
    total: number,
    decimalPlaces: number
  ): string {
    if (isNaN(value) || isNaN(total)) {
      return "";
    }

    const percentValue = (100 / total) * value;
    let roundedValue = mathsHelper
      .roundForDisplay(percentValue, decimalPlaces)
      .toString();
    if (roundedValue.endsWith(".0")) {
      roundedValue = roundedValue.replace(".0", "");
    }
    return roundedValue + "%";
  },
  convertPercentageToScale(
    percentage: number,
    scaleMinimum: number,
    scaleMaximum: number,
    roundingMethod: RoundingMethod = "MID-POINT-UP"
  ): number {
    const max = scaleMaximum - scaleMinimum;
    const raw = percentage / 100;
    const adjustedScore = raw * max;
    const score = adjustedScore + scaleMinimum;
    if (roundingMethod === "FLOOR") {
      return Math.floor(score);
    } else if (roundingMethod === "CEILING") {
      return Math.ceil(score);
    } else {
      return Math.round(score);
    }
  },
  convertScaleToPercentage(
    score: number,
    scaleMinimum: number,
    scaleMaximum: number
  ): number {
    const max = scaleMaximum - scaleMinimum;
    const adjustedScore = score - scaleMinimum;
    if (adjustedScore == 0) return 0;
    return (adjustedScore / max) * 100;
  },
};

export type RoundingMethod = "MID-POINT-UP" | "FLOOR" | "CEILING";

export default mathsHelper;
