import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faCalendar, faUser, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Badge, FormattedDate, Tooltip } from "../../common";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../routes/AppRoutes";

interface AnswerSetRelatedTaskBarItemProps {
  itemType: string;
  title: string;
  answerSetGuidId: string;
  answerSetOtherParticipantFullName: string;
  scheduledDate?: Date;
  isPlanning: boolean;
  isCompleted: boolean;
  clientColour: string;
}

const AnswerSetRelatedTaskBarItem = ({
  itemType,
  title,
  answerSetGuidId,
  answerSetOtherParticipantFullName,
  scheduledDate,
  isPlanning,
  isCompleted,
  clientColour,
}: AnswerSetRelatedTaskBarItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleItemClick = () => {
    if (answerSetGuidId) {
      navigate(
        AppRoutes.collaborativeDocument.generateRoute(
          answerSetGuidId,
          false,
          null
        )
      );
    }
  };

  let typeTranslationKey: string = "";
  switch (itemType) {
    case "JOURNEY":
      typeTranslationKey = "Timeline.Type.Journey";
      break;
    case "CATCH-UP":
      typeTranslationKey = "Timeline.Type.CatchUp";
      break;
    default:
      typeTranslationKey = "Timeline.Type.Task";
      break;
  }
  const translatedType = t(typeTranslationKey);

  return (
    <div
      onClick={handleItemClick}
      className="bg-white rounded-md cursor-pointer mr-2 border border-gray-200 hover:drop-shadow-sm"
    >
      <div className="flex flex-col">
        <div className=" flex flex-row border-b px-5 pb-2 pt-3">
          <div className="flex flex-col grow">
            <div className="text-gray-500 flex flex-row">
              <div className="flex flex-col">
                <Tooltip
                  content={title}
                  triggerElement={
                    <span className="font-semibold line-clamp-1">
                      {title}
                    </span> /* Span is necessary otherwise tooltip doesn't work */
                  }
                />

                <div className="text-gray-400 font-medium text-sm">
                  <small>{translatedType}</small>
                </div>

                {itemType === "JOURNEY" && isPlanning && (
                  <div className="flex flex-row pt-1">
                    <Tooltip
                      content={"This is the planning step of your journey"}
                      triggerElement={
                        <span>
                          <Badge
                            text={"Planning"}
                            backgroundColourClassName="bg-gray-200/75 rounded-full"
                            textColourClassName="text-gray-500"
                            borderRadiusClassName="rounded"
                            marginClassName="mr-1"
                          />
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center items-start">
            <div className="justify-self-end">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-gray-300"
              />
            </div>
          </div>
        </div>
        <div className="text-gray-400 font-normal text-xs flex flex-row justify-between px-5 py-2">
          {scheduledDate && (
            <Tooltip
              content=""
              htmlContent={
                <FormattedDate
                  date={scheduledDate}
                  convertFromUtc={true}
                  displayMode="DATE-AND-TIME"
                />
              }
              triggerElement={
                <div>
                  {isCompleted ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-2 text-green-500"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="mr-2"
                      color={clientColour}
                    />
                  )}
                  <FormattedDate date={scheduledDate} displayMode="FROM-NOW" />
                </div>
              }
            />
          )}
          {answerSetOtherParticipantFullName && (
            <div>
              <FontAwesomeIcon
                icon={faUser}
                className="mr-2"
                color={clientColour}
              />
              {answerSetOtherParticipantFullName}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnswerSetRelatedTaskBarItem;
