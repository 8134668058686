import { createContext } from "react";
import SignedInUserDto, {
  SignedInUserUpdatedDetailsDto,
} from "../types/dtos/users/SignedInUserDto";

export interface UserContextInterface {
  /** The current logged in user */
  user: SignedInUserDto;

  /** Update the entire user object in the context */
  setUser(newUserDetails: SignedInUserDto): void;

  /** Update the properties than can change whilst a user is logged in, e.g. manager update count */
  setUpdatedUserDetails(updates: SignedInUserUpdatedDetailsDto): void;

  /** For updating notification counts and highlight dots in the left nav */
  removeTaskHighlight(
    ownerUserId: number,
    taskTypeId: string,
    userTaskId: string
  ): void;
}

export const userContextDefaultValue: UserContextInterface = {
  user: {
    id: 0,
    client: {
      id: 0,
      name: "Client",
      logoFilename: null,
      primaryHexColour: "281F51",
      accentHexColour: "281F51",
      mvcAppUrl: "",
      enabledModules: [],
      moduleConfigs: [],
      genericCatchUpFormId: null,
      productName: "Korero",
      themeKey: "KORERO",
      taskTypes: [],
      usesTaskTypes: false,
    },
    legacyDetails: {
      isLegacyClient: false,
      selfReviews: false,
      teamReviews: false,
    },
    firstName: "User",
    hasAnalyticsAccess: false,
    initials: "AA",
    isAdmin: false,
    isManager: false,
    isJourneyManager: false,
    hasManager: false,
    managerUpdateCount: 0,
    myDashboardMode: "STANDARD",
    dateLoaded: new Date(),
    fullName: "Aaron Aaronson",
    profilePic: null,
    i18n: {
      cultureCode: "en-GB",
      dateFormat: "dd/MM/yyyy",
    },
    highlightedTasks: [],
    appLvl: {
      goalCategories: [
        { key: 1, value: "GoalCategory1" },
        { key: 2, value: "GoalCategory2" },
        { key: 3, value: "GoalCategory3" },
      ],
    },
    notifications: {
      totalNotificationCount: 0,
      totalUnreadNotificationCount: 0,
      recentNotifications: [],
      notificationTypes: [],
    },
    manager: undefined,
    journeyManager: undefined,
    isFullyManagerless: true,
    permissions: [],
    walkthroughSeen: null,
    analyticsNavItems: [],
  },
  setUser: () => {},
  setUpdatedUserDetails: (updates: SignedInUserUpdatedDetailsDto) => {},
  removeTaskHighlight: (
    ownerUserId: number,
    taskTypeId: string,
    userTaskId: string
  ) => {},
};

const UserContext = createContext<UserContextInterface>(
  userContextDefaultValue
);

/** A function for determining whether or not the user details have been populated from the server yet */
export const UserContextIsPopulated = (user: SignedInUserDto): boolean => {
  return user && user.id > 0;
};

export default UserContext;
