import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

let instance: UserBehaviourLogger;

class UserBehaviourLogger {
  loggerInstance: ApplicationInsights | undefined;
  reactPlugin: ReactPlugin;
  _setActiveUser: (user: TrackedUserDetails | null) => void;

  constructor() {
    if (instance) {
      throw new Error(
        "You can only create one instance of the UserBehaviourLogger!"
      );
    }
    instance = this;

    const appInsightsConnectionString =
      process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING;

    this.reactPlugin = new ReactPlugin();

    if (
      appInsightsConnectionString &&
      appInsightsConnectionString.length > 0 &&
      appInsightsConnectionString.toUpperCase().trim() !== "DISABLED"
    ) {
      const browserHistory = createBrowserHistory();

      // *** Add the Click Analytics plug-in. ***
      /* var clickPluginInstance = new ClickAnalyticsPlugin();
               var clickPluginConfig = {
                 autoCapture: true
            }; */
      this.loggerInstance = new ApplicationInsights({
        config: {
          connectionString: appInsightsConnectionString,
          // *** If you're adding the Click Analytics plug-in, delete the next line. ***
          extensions: [this.reactPlugin],
          // *** Add the Click Analytics plug-in. ***
          // extensions: [reactPlugin, clickPluginInstance],
          extensionConfig: {
            [this.reactPlugin.identifier]: { history: browserHistory },
            // *** Add the Click Analytics plug-in. ***
            // [clickPluginInstance.identifier]: clickPluginConfig
          },
        },
      });

      this.loggerInstance.loadAppInsights();

      this._setActiveUser = (user: TrackedUserDetails | null) => {
        if (!this.loggerInstance) {
          console.error("Cannot set active user, app insights not initialised");
          return;
        }

        this.loggerInstance.context.user.setAuthenticatedUserContext(
          user ? user.id.toString() : "",
          user ? user.clientName : ""
        );
      };
    } else {
      this._setActiveUser = (user: TrackedUserDetails | null) => {
        // Do nothing, app insights not being initialised
        console.log("Cannot set active AI user");
      };
    }
  }

  init() {}

  setActiveUser(user: TrackedUserDetails | null) {
    this._setActiveUser(user);
  }
}

interface TrackedUserDetails {
  id: number;
  clientName: string;
}

const singletonLogger = Object.freeze(new UserBehaviourLogger());
export default singletonLogger;
