const fileHelper = {
  /** Takes a string value, e.g. "My Big File" and turns it into a friendly value
   * to use to prepend to a file extension to make a full filename, producing something like
   * "my-big-file.xlsx"
   */
  createFilenameFromString: function (
    inputString: string,
    appendDateStamp: boolean | undefined = false
  ) {
    let output = inputString.replace(/[^a-z0-9]/gi, "-").toLowerCase();

    if (appendDateStamp) {
      const dateStamp = new Date()
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "");
      output += `_${dateStamp}`;
    }

    return output;
  },
};

export default fileHelper;
