import { useTranslation } from "react-i18next";

interface LeavingWarningFooterProps {
  goBackFromWarning(): void;
  proceedAfterWarning(): void;
}

const LeavingWarningFooter = ({
  goBackFromWarning,
  proceedAfterWarning,
}: LeavingWarningFooterProps) => {
  const { t } = useTranslation();

  return (
    <div className="pt-2 pb-2">
      <div className="flex flex-row gap-2 justify-end">
        <button
          className="btn-secondary"
          onClick={goBackFromWarning}
        >
          <span className="ml-1">
            {t("TaskType.Popup.Buttons.NoAndGoBack")}
          </span>
        </button>

        <button
          className="btn-primary"
          onClick={() => proceedAfterWarning()}
        >
          <span className="ml-1">
            {t("TaskType.Popup.Buttons.YesImSure")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default LeavingWarningFooter;
