import { t } from "i18next";
import { useState } from "react";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import ModalPopup from "../../../common/ModalPopup";
import parse from "html-react-parser";
import KeyValuePair from "../../../../types/generic/KeyValuePair";
import MoreInfoBadge from "../../../common/MoreInfoBadge";
import { FormType } from "../../../../types/forms";

interface BehaviourMoreInfoProps {
  /** The id and title (translation key identifier) of the behaviour */
  behaviour: KeyValuePair<number, string>;
  /** Optional "More info" content to display to the user. Context for the behaviour */
  infoTooltipContent?: string | null;
  formType: FormType;
}

function BehaviourMoreInfo({
  behaviour,
  infoTooltipContent,
  formType,
}: BehaviourMoreInfoProps) {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState<boolean>(false);

  return (
    <>
      {infoTooltipContent && (
        <>
          <MoreInfoBadge
            textTranslationKeyIdentifier="Forms.Behaviours.MoreBehaviourInfoButtonText"
            formType={formType}
            onClick={() => setInfoModalIsOpen(true)}
          />
          <ModalPopup
            isOpen={infoModalIsOpen}
            onOpenChange={setInfoModalIsOpen}
            onPrimaryButtonClick={() => setInfoModalIsOpen(false)}
            primaryButtonText={t("Common.Close")}
            title={t(behaviour.value)}
          >
            <div className="text-gray-700 competency-info-popup">
              {parse(t(infoTooltipContent))}
            </div>
          </ModalPopup>
        </>
      )}
    </>
  );
}

export default BehaviourMoreInfo;
