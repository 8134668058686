import { t } from "i18next";
import { useContext, useReducer, Reducer, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import collabDocApi from "../api/forms/collabDocApi";
import CollaborativeDocumentPage from "../components/collab-docs/CollaborativeDocumentPage";
import { dateHelper, formAnswerHelper, taskTypeHelper } from "../helpers";
import AppContext from "../state/AppContext";
import { CollabDocState } from "../state/CollabDoc/CollabDocState";
import UserContext from "../state/UserContext";
import { BaseUserDetailsDto } from "../types/dtos/generic";
import collabDocAnswerHelper from "../helpers/collabDocAnswerHelper";
import userApi from "../api/user/userApi";
import { CollabDocLoadResponseDto } from "../types/dtos/collab-docs";
import CollabDocLoadErrorModal from "../components/collab-docs/CollabDocLoadErrorModal";

function CollabDocViewer() {
  // Auth/API + Constants / context / callbacks
  const auth = useAuth();
  const apiCollabDocs = new collabDocApi(auth.user?.access_token);
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const usersApi = new userApi(auth.user?.access_token);
  const collabDocHelper = new collabDocAnswerHelper(apiCollabDocs, userContext);
  const { answerSetUniqueId } = useParams();

  // State
  const [showLoadingError, setShowLoadingError] = useState<boolean>(false);
  const [state, setState] = useReducer<
    Reducer<CollabDocState, Partial<CollabDocState>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    collabDocHelper.getNewDefaultState()
  );

  /** Select either "My Dashboard" or "My People"  on the left nav
   * depending on whoever this collab doc is for
   */
  const setOverrideActiveNavItem = (subjectUser: BaseUserDetailsDto | null) => {
    if (subjectUser) {
      if (subjectUser.userId === userContext.user.id) {
        appContext.setOverrideNavItem("MyDashboard");
      } else {
        appContext.setOverrideNavItem("ManagerDashboard");
      }
    } else {
      appContext.setOverrideNavItem(null);
    }
  };

  const setPageTitle = (title: string) => {
    appContext.setPageTitle(title);
    appContext.setShowPageTitleAccent(true);
  };

  const loadFormData = () => {
    // If we don't have logged in user details yet (due to CTRL-F5 or similar), quit
    // there's a useEffect which should run when the logged in user id changes
    if (userContext.user.id === 0) return;

    setShowLoadingError(false);
    setState({ waitingForApiResult: true });

    const onLoadSuccess = (json: CollabDocLoadResponseDto) => {
      if (json.successful && json.collabDoc) {
        const docData = json.collabDoc;
        let pageTitle = docData.translateTitle
          ? t(docData.title)
          : docData.title;
        pageTitle += docData.titleSuffix ? docData.titleSuffix : "";

        setPageTitle(pageTitle);
        setOverrideActiveNavItem(docData.subject);
        setState({
          subjectUser: docData.subject,
          status: docData.approvalStatus,
          backgroundColour: docData.backgroundColour,
          lastUpdatedDate: docData.lastUpdated,
          lastUpdatedByUserId: docData.lastUpdatedByUserId,
          isReadOnly: true, // Access for the Viewer will always be read only
          isExitJourney: docData.isExitJourney,
          isLocked: docData.isLocked,
          hasBeenDelegated: docData.hasBeenDelegated,
          participants: docData.participants,
          forms: docData.forms,
          formComplexities: docData.formComplexities,
          answerState: formAnswerHelper.prepareAnswersForInitialDisplay(
            docData.answers
          ),
          discussionExists: docData.discussionExists,
          comments: docData.comments,
          waitingForApiResult: false,
          dateLoadedUtc: dateHelper.getCurrentDateUtc(),
          flaggedChanges: docData.flaggedChanges,
          lockedForSubmissionUntil: dateHelper.convertDateFromString(
            docData.lockedForSubmissionUntil
          ),
          mode: docData.mode,
          isInPrepMode: docData.dualPrepInfo?.isInPrepMode === true,
          showDifferentFormVersionsWarning:
            docData.warnAboutDifferentFormVersions,
          userCanDiscardAnswerSet: docData.userCanDiscard,
          showLoadingErrorPopup: false,
          loadingErrorType: null,
          approvalFlow: docData.approvalFlow,
          managerPlanningWasSkipped: docData.managerPlanningWasSkipped,
          managerPlanningIsOptional: docData.managerPlanningIsOptional,
          answerSetDateCreated: docData.answerSetDateCreated,
        });

        // Auto-select the first question as the selected question, to load the comments
        if (docData.forms) {
          const firstQuestionId = docData.forms[0].questions[0].questionId;
          setState({ activeQuestionId: firstQuestionId });

          // Load the comments from the server response, as the state update may not have happened yet for `setDocComments`
          if (docData.comments && docData.comments.length > 0) {
            const questionComments = docData.comments.filter(
              (x) =>
                x.questionId === firstQuestionId && x.commentType !== "ENFORCED"
            );
            setState({ visibleComments: questionComments });
          }
        }

        // Convert the target date on the tasks to JS date object
        if (docData.tasks && docData.tasks.length > 0) {
          const questionTasks = taskTypeHelper.getQuestionTaskObjects(
            docData.tasks
          );
          setState({ taskState: questionTasks });
        } else {
          setState({ taskState: [] });
        }
      } else {
        // Response object was not successful, no doc could be loaded, show the error
        setState({ waitingForApiResult: false });
        if (json.errorType) {
          setState({
            loadingErrorType: json.errorType,
            showLoadingErrorPopup: true,
          });
        } else {
          onLoadError("Generic error");
        }
      }
    };

    const onLoadError = (error: any) => {
      setShowLoadingError(true);
      console.error("Collab doc load error", error);
    };

    if (!answerSetUniqueId) {
      onLoadError("No answer set id");
      return;
    }

    apiCollabDocs.loadCollabDocForViewer(
      answerSetUniqueId!,
      onLoadSuccess,
      onLoadError
    );
  };

  const handleLoadingErrorPopupOpenChange = (open: boolean) => {
    setState({ showLoadingErrorPopup: open });
  };

  return (
    <>
      <CollaborativeDocumentPage
        state={state}
        setState={setState}
        showLoadingError={showLoadingError}
        setShowLoadingError={setShowLoadingError}
        loadData={loadFormData}
        setOverrideActiveNavItem={setOverrideActiveNavItem}
        userContext={userContext}
        collabDocHelper={collabDocHelper}
        apiCollabDocs={apiCollabDocs}
        usersApi={usersApi}
        appContext={appContext}
        answerSetUniqueId={answerSetUniqueId}
        answerSetDateCreated={state.answerSetDateCreated}
        singleFormId={undefined}
        journeyName={state.journeyName}
        approvalFlow={state.approvalFlow}
      />
      {state.loadingErrorType && (
        <CollabDocLoadErrorModal
          errorType={state.loadingErrorType}
          isOpen={state.showLoadingErrorPopup}
          onOpenChange={handleLoadingErrorPopupOpenChange}
        />
      )}
    </>
  );
}

export default CollabDocViewer;
