import AppRoutes from "./AppRoutes";

export const NonPrimaryNavItems = [
  { text: "Nav.Profile.Profile", href: AppRoutes.profile },
  { text: "Nav.Profile.ChangePassword", href: AppRoutes.changePassword },
  /*{ text: "Nav.Profile.YourGoals", href: AppRoutes.yourGoals },*/
  {
    text: "Nav.Profile.NotificationPreferences",
    href: AppRoutes.notificationPreferences,
  },
  { text: "Nav.Profile.Logout", href: AppRoutes.logout },
];

export default NonPrimaryNavItems;
