import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { t } from "i18next";
import cx from "classnames";
import { DevelopmentListParentDto } from "../../../../types/dtos/forms/DevelopmentOptionsDto";
import { BaseSelectableItem, FormType } from "../../../../types/forms";

interface DevelopmentListGroupProps {
  group: DevelopmentListParentDto;
  /** Whether this question is being displayed in a collab doc or a journey. Controls styling (light vs dark bg) */
  formType: FormType;
  onItemClicked(clickedItem: BaseSelectableItem, selected: boolean): void;
}

function DevelopmentListGroup({
  group,
  formType,
  onItemClicked,
}: DevelopmentListGroupProps) {
  let buttonClassNames = "text-white border-none";
  let primaryTextColorClass = "";
  if (formType !== "JOURNEY") {
    buttonClassNames = "bg-gray-100";
    primaryTextColorClass = "text-gray-500";
  }

  return (
    <div className={cx("p-2 pt-4")}>
      <>
        {group.items.map((item) => {
          return item.isSelected ? (
            <button
              className={cx(
                "group mr-3 px-2 py-1 rounded-md text-sm mb-3 outline outline-offset-2 outline-3 outline-green-300 border hover:bg-white/50",
                buttonClassNames
              )}
              onClick={() => onItemClicked(item, false)}
              key={item.value}
            >
              {t(item.text)}
              <FontAwesomeIcon
                icon={faTimes}
                size="xs"
                className="text-gray-700/70 ml-1 group-hover:text-red-500"
              />
            </button>
          ) : (
            <button
              className={cx(
                "group mr-3 px-2 py-1 bg-white/30 rounded-md text-sm mb-2 border  hover:bg-white/50",
                buttonClassNames,
                primaryTextColorClass
              )}
              onClick={() => onItemClicked(item, true)}
              key={item.value}
            >
              {t(item.text)}
              <FontAwesomeIcon
                icon={faPlus}
                size="xs"
                className="ml-1  group-hover:text-green-500"
              />
            </button>
          );
        })}
      </>
    </div>
  );
}

export default DevelopmentListGroup;
