import { t } from "i18next";
import cx from "classnames";
import { useMatch, NavLink } from "react-router-dom";
import SubNavItem from "../../types/nav/SubNavItem";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, HighlightDot } from "../common";

interface LeftSubNavGroupProps {
  subNavId: string;
  baseRoute: string;
  baseLinkText: string;
  items: SubNavItem[];
  activeIcon: IconDefinition;
  inactiveIcon: IconDefinition;
  showRootLevelBadge?: boolean;
  rootLevelBadgeText?: string;
  rootLevelBadgeTooltip?: string;
}

function LeftSubNavGroup({
  subNavId,
  baseRoute,
  baseLinkText,
  items,
  activeIcon,
  inactiveIcon,
  showRootLevelBadge,
  rootLevelBadgeText,
  rootLevelBadgeTooltip,
}: LeftSubNavGroupProps) {
  const isActive = useMatch({
    path: baseRoute,
    end: false,
  });

  return (
    <div
      className={cx("left-nav-item-subnav", isActive ? "active-subnav" : "")}
    >
      <NavLink
        className={({ isActive }) =>
          isActive ? "active-nav-link" : "inactive-nav-link"
        }
        to={baseRoute}
      >
        <FontAwesomeIcon
          icon={activeIcon}
          size="lg"
          className="nav-icon-active fa-fw"
        />
        <FontAwesomeIcon
          icon={inactiveIcon}
          size="lg"
          className="nav-icon-inactive fa-fw"
        />

        <span className="px-2">{baseLinkText}</span>
        {showRootLevelBadge && rootLevelBadgeText && rootLevelBadgeTooltip && (
          <Badge
            text={rootLevelBadgeText}
            backgroundColourClassName="accent-bg"
            textColourClassName="text-white"
            marginClassName=""
            tooltip={rootLevelBadgeTooltip}
          />
        )}
      </NavLink>
      {isActive && (
        <div id={subNavId} className="left-subnav">
          {items.map((navItem) => (
            <NavLink
              className={({ isActive }) =>
                cx(
                  "",
                  isActive
                    ? "text-gray-500 font-semibold"
                    : "text-gray-400 hover:text-gray-500"
                )
              }
              to={navItem.url}
              key={navItem.title}
            >
              <span>
                {navItem.translateTitle ? t(navItem.title) : navItem.title}
              </span>
              {navItem.badgeCount !== undefined && navItem.badgeCount > 0 && (
                <HighlightDot containerClassNames="ml-1" />
              )}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

export default LeftSubNavGroup;
