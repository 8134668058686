import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { dateHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { Label, Popover, TextArea } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmilePlus, faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import NewTaskComment from "../../types/tasks/NewTaskComment";
import { FormConstants } from "../../config/forms/FormConstants";

interface NewCommentFormProps {
  taskId: number;
  containerClassNames?: string;
  onSubmit(
    newComment: NewTaskComment,
    successCallback: () => void,
    errorCallback: () => void
  ): void;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
}

export const newCommentFieldElementId = "new-comment-input";

function NewTaskCommentForm({
  taskId,
  onSubmit,
  containerClassNames = "",
  inputRef = undefined,
}: NewCommentFormProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  // State
  const [textValue, setTextValue] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [erroredWhenSaving, setErroredWhenSaving] = useState<boolean>(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  // Events
  const handleSubmitPress = () => {
    const trimmedComment = textValue?.trim();
    if (trimmedComment && trimmedComment.length > 0) {
      // Construct the comment object
      const newComment: NewTaskComment = {
        authorId: userContext.user.id,
        comment: trimmedComment,
        taskId: taskId,
        timestamp: dateHelper.getCurrentDateUtc(),
        commentType: "STANDARD",
      };

      // Submit the comment and update the list of comments
      const successCallback = () => {
        setIsSaving(false);
        setErroredWhenSaving(false);
        // Reset the form
        setTextValue("");
      };

      const errorCallback = () => {
        setIsSaving(false);
        setErroredWhenSaving(true);
      };

      // Reset variables
      setErroredWhenSaving(false);

      // Show the saving spinner
      setIsSaving(true);

      onSubmit(newComment, successCallback, errorCallback);
    }
  };

  /** Clear the textarea */
  const handleResetPress = () => {
    setTextValue("");
  };

  /** Handle when the user selects an emoji to add to their response */
  const addEmojiToTextArea = (emoji: EmojiClickData, event: MouseEvent) => {
    // Get the emoji as a string to add to the value
    let sym = emoji.unified.split("-");
    let codesArray: number[] = [];
    sym.forEach((el) => codesArray.push(Number("0x" + el)));
    let emojiString = String.fromCodePoint(...codesArray);

    // Insert the emoji at the carat
    if (inputRef?.current) {
      const selectionStart = inputRef.current.selectionStart;
      const newTextValue =
        textValue.slice(0, selectionStart) +
        emojiString +
        textValue.slice(selectionStart);
      setTextValue(newTextValue);
    }
  };

  const disableSubmitButton = !textValue || textValue.trim().length === 0;
  const showCancelButton = textValue && textValue.length > 0;

  return (
    <div className={containerClassNames}>
      <Label
        className="hidden"
        htmlFor={newCommentFieldElementId}
        text={t("Pages.CollaborativeDocument.Controls.NewCommentLabel")}
      />
      <TextArea
        inputId={newCommentFieldElementId}
        placeholder={t(
          "Pages.CollaborativeDocument.Controls.NewCommentPlaceholder"
        )}
        value={textValue}
        onChange={setTextValue}
        className="block w-full border-0 rounded-0 border-b border-gray-300 bg-transparent"
        minRows={1}
        maxRows={5}
        inputRef={inputRef}
        showValidationErrors={false}
        maxLength={FormConstants.MaxLengths.AnswerSetComment}
      />
      {erroredWhenSaving && (
        <small className="pl-2">
          <span className="text-red-500 mr-2">
            {t("Pages.CollaborativeDocument.Common.AddCommentErrored")}
          </span>
          <button className="underline" onClick={handleSubmitPress}>
            {t("Common.TryAgain")}
          </button>
        </small>
      )}
      <div className="mt-2">
        <div className="flex flex-row">
          <div className="flex-initial w-14">
            <Popover
              openState={popoverIsOpen}
              onOpenStateChange={setPopoverIsOpen}
              placement="left"
              contentClassNames="w-fit bg-white"
              showCloseIcon={false}
              triggerElement={
                <button
                  className="hidden md:inline-block text-gray-600 disabled:text-gray-400 disabled:cursor-not-allowed"
                  disabled={isSaving}
                >
                  <FontAwesomeIcon
                    icon={faSmilePlus}
                    size="lg"
                    title={t(
                      "Pages.CollaborativeDocument.Common.AddEmojiTooltip"
                    )}
                    className="self-center "
                  />
                </button>
              }
            >
              <EmojiPicker onEmojiClick={addEmojiToTextArea} />
            </Popover>
          </div>
          <div className="grow text-right">
            {showCancelButton && !isSaving && (
              <button
                onClick={handleResetPress}
                className="btn-secondary btn-small mr-1"
              >
                {t("Common.Cancel")}
              </button>
            )}
            <button
              onClick={handleSubmitPress}
              className="btn-primary btn-small"
              disabled={disableSubmitButton || isSaving}
            >
              {t("Pages.CollaborativeDocument.Common.AddNewComment")}
              {isSaving && (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  size="xs"
                  className="ml-1 animate-spin"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTaskCommentForm;
