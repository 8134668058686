import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { useAuth } from "react-oidc-context";
import customExportsApi from "../../api/analytics/customExportsApi";
import AnalyticsPage from "../../components/analytics/AnalyticsPage";
import ExportableJourneyItem, {
  ExportableJourneyType,
} from "../../types/analytics/custom-exports/ExportableJourneyItem";
import { AnalyticsLoadingSpinner } from "../../components/analytics/AnalyticsLoadingSpinner";
import UserContext from "../../state/UserContext";
import JourneyExportItem from "./exports/JourneyExportItem";
import { MainContainer } from "../../components/layout";
import TaskExportItem from "./exports/TaskExportItem";
import { CheckBox, DateInput, Label } from "../../components/common";

function CustomReports() {
  // Context/APIs
  const auth = useAuth();
  const api = new customExportsApi(auth.user?.access_token);
  const userContext = useContext(UserContext);

  // Constants
  const journeyTypeNames = {
    clientSentJourney: "Admin Sent Journey",
    induction: "Induction Journey",
    exit: "Exit Journey",
    systemForm: "System Journey",
  };

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloadingJourneys, setDownloadingJourneys] = useState<string[]>([]);
  const [exportableJourneys, setExportableJourneys] = useState<
    ExportableJourneyItem[]
  >([]);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [excludeHistoricTasks, setExcludeHistoricTasks] =
    useState<boolean>(true);

  // Derived state
  const hasJourneys = exportableJourneys && exportableJourneys.length > 0;
  const hasTasks =
    userContext.user.client.taskTypes &&
    userContext.user.client.taskTypes.length > 0;

  // Side effects
  useEffect(() => {
    reloadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Functions
  function reloadData() {
    const onSuccess = (data: ExportableJourneyItem[]) => {
      setIsLoading(false);
      const translatedJourneys = data.map((x) => ({
        ...x,
        title: x.translateTitle ? t(x.title) : x.title,
      }));
      const sortedJourneys = translatedJourneys.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setExportableJourneys(sortedJourneys);
    };

    const onError = (error: any) => {
      setIsLoading(false);
      setExportableJourneys([]);
    };

    setIsLoading(true);

    api.getExportableJourneys(onSuccess, onError);
  }

  const displayItemsForJourneyType = (journeyType: ExportableJourneyType) => {
    return (
      <>
        {exportableJourneys
          .filter((x) => x.journeyType == journeyType)
          .map((j) => {
            const isDownloading = downloadingJourneys.indexOf(j.title) >= 0;
            return (
              <JourneyExportItem
                key={j.title}
                item={j}
                isDownloading={isDownloading}
                downloadingJourneys={downloadingJourneys}
                setDownloadingJourneys={setDownloadingJourneys}
                exportableJourneys={exportableJourneys}
              />
            );
          })}
      </>
    );
  };

  const onExcludeHistoricTasksTickChange = (isChecked: boolean) => {
    setExcludeHistoricTasks(isChecked);
  };

  return (
    <MainContainer>
      <AnalyticsPage
        pageIdentifier="CustomReports"
        title="Pages.Analytics.Exports.PageTitle"
        journeyFilterDefaultValueMode="MULTI"
        contentType="CUSTOM"
        journeyFilterRestriction="SHOW-ALL"
      >
        {isLoading && <AnalyticsLoadingSpinner />}
        {!isLoading && (
          <>
            {hasTasks && (
              <div className="px-5 p-3 mt-2 mb-3 border border-gray-200 rounded px-5 py-3 bg-gray-100">
                <h3 className="!mb-0">Tasks</h3>
                <p className="mb-2 text-sm">
                  Use the date picker to export tasks created between a specific
                  date range:
                </p>
                <hr className="my-3" />
                <div>
                  <div className="flex flex-row gap-4">
                    <div className="flex flex-row">
                      <Label htmlFor="from-date" text="From:" />
                      <DateInput
                        onChange={setDateFrom}
                        inputId="from-date"
                        value={dateFrom}
                        showTimeSelect={false}
                        placeholder=""
                        allowDatesInPast={true}
                        className="ml-1 !pl-1 !p-0 block w-full cursor-pointer border-0 bg-gray-200 rounded-md focus:outline-0 focus:ring-0"
                        isClearable={true}
                      />
                    </div>
                    <div className="flex flex-row">
                      <Label htmlFor="to-date" text="To:" />
                      <DateInput
                        onChange={setDateTo}
                        inputId="to-date"
                        value={dateTo}
                        showTimeSelect={false}
                        placeholder=""
                        allowDatesInPast={true}
                        className="ml-1 !pl-1 !p-0 block w-full cursor-pointer border-0 bg-gray-200 rounded-md focus:outline-0 focus:ring-0"
                        isClearable={true}
                      />
                    </div>
                    <div className="grow">
                      <CheckBox
                        labelText="Exclude historic tasks?"
                        onChange={onExcludeHistoricTasksTickChange}
                        selected={excludeHistoricTasks}
                        labelClassNames="text-sm pt-1"
                        checkboxClassNames="bg-white "
                        containerClassNames="justify-end"
                        controlId="adv-filters-include-leavers"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pt-2">
                    {userContext.user.client.taskTypes.map((tt) => {
                      return (
                        <TaskExportItem
                          key={tt.id}
                          item={tt}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          excludeHistoricTasks={excludeHistoricTasks}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {hasJourneys && (
              <div className="px-5 p-3 mt-2 mb-3 border border-gray-200 rounded px-5 py-3 bg-gray-100">
                <h3 className="!mb-0">Journeys</h3>
                <p className="mb-2 text-sm">
                  Download an export of all completed Journeys:
                </p>
                <hr className="my-3" />
                {/* Client Sent Journeys */}
                {exportableJourneys.filter(
                  (x) => x.journeyType == "CLIENT-SENT-JOURNEY"
                ).length > 0 && (
                  <>
                    <div className="mb-4">
                      <h4 className="font-medium">
                        {journeyTypeNames.clientSentJourney}
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pt-1">
                        {displayItemsForJourneyType("CLIENT-SENT-JOURNEY")}
                      </div>
                    </div>
                    <hr className="my-3" />
                  </>
                )}

                {/* Induction */}
                {exportableJourneys.filter((x) => x.journeyType == "INDUCTION")
                  .length > 0 && (
                  <>
                    <div>
                      <h4 className="font-medium">
                        {journeyTypeNames.induction}
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pt-1">
                        {displayItemsForJourneyType("INDUCTION")}
                      </div>
                    </div>
                    <hr className="my-3" />
                  </>
                )}

                {/* Exit */}
                {exportableJourneys.filter((x) => x.journeyType == "EXIT")
                  .length > 0 && (
                  <>
                    <div>
                      <h4 className="font-medium">{journeyTypeNames.exit}</h4>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pt-1">
                        {displayItemsForJourneyType("EXIT")}
                      </div>
                    </div>
                    <hr className="my-3" />
                  </>
                )}

                {/* System */}
                {exportableJourneys.filter(
                  (x) => x.journeyType == "SYSTEM-FORM"
                ).length > 0 && (
                  <>
                    <div>
                      <h4 className="font-medium">
                        {journeyTypeNames.systemForm}
                      </h4>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pt-1">
                        {displayItemsForJourneyType("SYSTEM-FORM")}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </AnalyticsPage>
    </MainContainer>
  );
}

export default CustomReports;
