import { CSSProperties, useContext } from "react";
import { AnalyticsWidgetUiDetailsDto } from "../../../types/analytics";
import { BarChartItemGroup } from "../../../types/analytics/charts/BarCharts";
import UserContext from "../../../state/UserContext";
import { colourHelper } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/pro-regular-svg-icons";

type BarChartTableFirstColumnType = "RANKING" | "CROWN-FOR-HIGHEST" | "NONE";

interface BarChartMobileAlternativeDisplayProps {
  barChartMode: "GROUPED" | "STACKED";
  dataItems: BarChartItemGroup[];
  widget: AnalyticsWidgetUiDetailsDto;
}

interface BarChartTableDisplayProps {
  firstColumn: BarChartTableFirstColumnType;
  dataItems: BarChartItemGroup[];
  themeColour: string;
}

const MobileTableDataVisualistation = ({
  firstColumn,
  dataItems,
  themeColour,
}: BarChartTableDisplayProps) => {
  const maxTotalItemCount = dataItems
    .map((di) => di.totalItemCount)
    .reduce((a, b) => {
      return b > a ? b : a;
    });

  const lightColourBgStyles: CSSProperties = {
    backgroundColor: colourHelper.shadeColour(themeColour, 45),
  };

  const darkColourBgStyles: CSSProperties = {
    backgroundColor: colourHelper.shadeColour(themeColour, 30),
  };

  return (
    <div>
      <div className="flex border-b-[1px] border-gray-300 text-gray-500">
        {firstColumn === "RANKING" && (
          <>
            <div className="px-2 py-1 text-center w-4 italic text-sm leading-6">
              Rank
            </div>
          </>
        )}
        <div className="px-2 py-1 text-center flex-grow font-semibold">
          Value
        </div>
        <div className="px-2 py-1 text-center w-16 font-semibold">Count</div>
      </div>
      {dataItems.map((dataItem, ix) => {
        const rowDynamicStyles: CSSProperties =
          ix % 2 === 0 ? lightColourBgStyles : darkColourBgStyles;
        const isHighest = dataItem.totalItemCount >= maxTotalItemCount;
        return (
          <div
            key={dataItem.id}
            className="flex text-gray-600"
            style={rowDynamicStyles}
          >
            {firstColumn === "RANKING" && (
              <div className="px-2 py-1 text-center w-4 italic text-sm leading-6 text-gray-500">
                {ix + 1}.
              </div>
            )}
            {firstColumn === "CROWN-FOR-HIGHEST" && (
              <div className="px-2 py-1 text-center w-4 italic text-gray-500">
                {isHighest && (
                  <span
                    className="text-yellow-400 inline-block w-8 bg-white rounded-full text-center border border-yellow-400"
                    title="Top score"
                  >
                    <FontAwesomeIcon icon={faCrown} />
                  </span>
                )}
              </div>
            )}
            <div className="px-2 py-1 flex-grow text-center">
              {dataItem.label}
            </div>
            <div className="px-2 py-1 text-center w-16 font-semibold">
              {dataItem.totalItemCount}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const BarChartMobileAlternativeDisplay = ({
  widget,
  barChartMode,
  dataItems,
}: BarChartMobileAlternativeDisplayProps) => {
  const userContext = useContext(UserContext);

  // If there are no data items, return null to not render anything
  if (dataItems === undefined || dataItems.length === 0) {
    return null;
  }

  let displayDataItems = [...dataItems];
  let firstColumnType: BarChartTableFirstColumnType = "RANKING";
  if (widget.displayType === "HISTOGRAM") {
    firstColumnType = "CROWN-FOR-HIGHEST";
  } else {
    displayDataItems = displayDataItems.sort(
      (a, b) => b.totalItemCount - a.totalItemCount
    );
  }

  return (
    <div className="md:hidden my-4 px-2">
      <MobileTableDataVisualistation
        firstColumn={firstColumnType}
        dataItems={displayDataItems}
        themeColour={userContext.user.client.primaryHexColour}
      />
    </div>
  );
};

export default BarChartMobileAlternativeDisplay;
