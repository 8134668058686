/** Maps to TalentToolbox.Forms.Core.Enums.ClientFormMode */
export enum ClientFormModeEnum {
  HiddenOnDashboard,
  Automated,
  Windowed,
  ManualOnly,
  Induction,
  Exit
}

export default ClientFormModeEnum;
