type LocalStorageItemKey = "USER_MVC_APP_URL" | "AUTH_RETURN_URL";

const localStorageHelper = {
  getItem(keyName: LocalStorageItemKey): string | null {
    try {
      return localStorage.getItem(keyName);
    } catch (error) {
      console.log("localStorageHelper error: ", error);
      return null;
    }
  },
  setItem(keyName: LocalStorageItemKey, value: string): void {
    try {
      localStorage.setItem(keyName, value);
    } catch (error) {
      console.log("localStorageHelper error: ", error);
    }
  },
  removeItem(keyName: LocalStorageItemKey): void {
    try {
      localStorage.removeItem(keyName);
    } catch (error) {
      console.log("localStorageHelper error: ", error);
    }
  },
};

export default localStorageHelper;
