import ClientFormAutomationUnitType from "../types/dtos/admin/ClientFormAutomationUnitType";

export type JourneyAutomationValidationResult = "VALID" | "INVALID" | "NOT_SET";

export const journeyAutomationHelper = {
  _minAutomationDaysAllowed: 7,
  isValid(
    automationNumber: number | null | undefined,
    automationUnit: ClientFormAutomationUnitType | null | undefined
  ): JourneyAutomationValidationResult {
    // Show the validation warning if the automation number is less than or equal to 0 or if the automation unit
    // is DAYS and the automation number is less than the minimum allowed days
    if (automationNumber !== undefined && automationNumber !== null) {
      if (
        Number(automationNumber) <= 0 ||
        (automationUnit === "DAYS" &&
          Number(automationNumber) <
            journeyAutomationHelper._minAutomationDaysAllowed)
      ) {
        return "INVALID";
      }

      return "VALID";
    }

    return "NOT_SET";
  },
};

export default journeyAutomationHelper;
