import { useContext, useEffect } from "react";
import { Routes, Route, useMatch, useNavigate } from "react-router-dom";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import AppRoutes from "../AppRoutes";
import OgAppRedirect from "../OgAppRedirect";
import CustomReports from "./CustomReports";
import UsageReport from "./UsageReport";
import DynamicAnalyticsPage from "./DynamicAnalyticsPage";
import UserContext from "../../state/UserContext";

function AnalyticsRoot() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const isAnalyticsRoot = useMatch({
    path: AppRoutes.analytics.root,
    end: true,
  });
  const appInsights = useAppInsightsContext();
  const trackAnalyticsViewEvent = useTrackEvent(
    appInsights,
    "Analytics-ViewRoot",
    {}
  );

  useEffect(() => {
    // Redirect to the latest overview if this is the root url for analytics
    if (isAnalyticsRoot) {
      trackAnalyticsViewEvent({
        userType: userContext.user?.isAdmin ? "ADMIN" : "MANAGER",
      });
      navigate(AppRoutes.analytics.usage);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path={AppRoutes.analytics.sub.usage} element={<UsageReport />} />
        <Route
          path={AppRoutes.analytics.sub.dynamicPage.path}
          element={<DynamicAnalyticsPage />}
        />
        <Route
          path={AppRoutes.analytics.sub.exports}
          element={<CustomReports />}
        />
        <Route
          path={AppRoutes.analytics.sub.classic}
          element={<OgAppRedirect redirectTo="ANALYTICS" />}
        />
      </Routes>
    </>
  );
}

export default AnalyticsRoot;
