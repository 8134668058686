
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskManagementDropDownMenuArgs } from "../../../../types/tasks/TaskManagementDropDownMenuArgs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface EnforcedCommentsBadgeProps {
  text: string;
  icon: IconProp;
  backgroundColourClassName?: string;
  textClassName?: string;
  onClick?: (userTaskId: string, action: string) => void;
}

function EnforcedCommentsBadge({
  text,
  icon,
  backgroundColourClassName,
  textClassName,
  onClick = undefined,
}: EnforcedCommentsBadgeProps) {
  return (
    <div
      className={cx(
        "pl-2 pr-3 py-1 mr-2 text-xs rounded-full w-fit",
        backgroundColourClassName,
        textClassName
      )}
      onClick={() => onClick && onClick("", TaskManagementDropDownMenuArgs.AddComment)}
    >
      <FontAwesomeIcon className="mr-1" icon={icon} />
      <span>{text}</span>
    </div>
  );
}

export default EnforcedCommentsBadge;
