import { useTranslation } from "react-i18next";
import IndividualTaskAreaItem from "./IndividualTaskAreaItem";

interface TimelineTaskAreaProps {
  activeGoalsCount: number;
  activeLearningCount: number;
  activeActionsCount: number;
  showGoalPopup(): void;
  showActionPopup(): void;
  showLearningPopup(): void;
  triggerAddTaskPopup(): void;
}

const TimelineTaskArea = ({
  activeGoalsCount,
  activeLearningCount,
  activeActionsCount,
  showGoalPopup,
  showActionPopup,
  showLearningPopup,
  triggerAddTaskPopup,
}: TimelineTaskAreaProps) => {
  const { t } = useTranslation();

  const totalTasksCount =
    activeGoalsCount + activeLearningCount + activeActionsCount;

  return (
    <div className="ml-1 border-t border-t-gray-200" id="task-area">
      <div className="mb-2 mt-3 flex justify-between">
        <span className="text-gray-500 font-medium text-sm">Total Tasks: {totalTasksCount} </span>
        <button
          className="text-gray-400 text-sm hover:bg-gray-200 rounded px-2"
          onClick={triggerAddTaskPopup}
        >
          + {t("Timeline.Button.AddTask")}
        </button>
      </div>

      <div className="flex flex-row space-x-2">
        <IndividualTaskAreaItem
          showTaskPopup={showGoalPopup}
          activeTaskCount={activeGoalsCount}
          taskTypeTranslationKey={"Tasks.Types.Goal"}
        />
        <IndividualTaskAreaItem
          showTaskPopup={showActionPopup}
          activeTaskCount={activeActionsCount}
          taskTypeTranslationKey={"Tasks.Types.Action"}
        />
        <IndividualTaskAreaItem
          showTaskPopup={showLearningPopup}
          activeTaskCount={activeLearningCount}
          taskTypeTranslationKey={"Tasks.Types.Learning"}
        />
      </div>
    </div>
  );
};

export default TimelineTaskArea;
