import cx from "classnames";
import { t } from "i18next";
import { useState } from "react";
import { UserBasicDetailsDto } from "../../../../types/dtos/generic";
import {
  PlanningResponseButtonArgument,
  QuestionType,
} from "../../../../types/forms";
import { DropDownMenu } from "../../../common";
import copy from "copy-to-clipboard";
import Collapsible from "./Collapsible";
import DesktopResponsesView from "./DesktopResponsesView";
import MobileResponsesView from "./MobileResponsesView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "react-oidc-context";
import collabDocApi from "../../../../api/forms/collabDocApi";
import PlanningResponsesApiResponseDto, {
  BehaviourResponseDto,
  GoalResponseDto,
  ResponseDto,
  UserResponseDto,
} from "../../../../types/dtos/collab-docs/PlanningResponsesApiResponseDto";
import { useParams } from "react-router-dom";
import SmallLoader from "../../../loaders/SmallLoader";

interface DropDownMenuItemDto {
  text: string;
  customArg: string;
}

interface PlanningResponsesProps {
  questionId: string;
  questionType: QuestionType;
  subjectUserId: number;
  participants: Array<UserBasicDetailsDto>;
  formId: number;
  behaviourId?: number | null;
  goalId?: number | null;
  onCopyClick?(
    newValue: string,
    clientFormId: number,
    behaviourId?: number | null,
    goalId?: number | null
  ): void;
}

function PlanningResponses({
  subjectUserId,
  participants,
  questionId,
  questionType,
  formId,
  behaviourId = null,
  goalId = null,
  onCopyClick,
}: PlanningResponsesProps) {
  // Auth/API
  const auth = useAuth();
  const apiCollabDocs = new collabDocApi(auth.user?.access_token);
  const { answerSetUniqueId } = useParams();

  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropDownMenuItems, setDropDownMenuItems] = useState<
    DropDownMenuItemDto[]
  >([]);
  const [
    subjectParticipantStandardAnswer,
    setSubjectParticipantStandardAnswer,
  ] = useState<string | null>(null);
  const [
    subjectParticipantBehaviourAnswer,
    setSubjectParticipantBehaviourAnswer,
  ] = useState<BehaviourResponseDto | null>(null);
  const [subjectParticipantGoalAnswer, setSubjectParticipantGoalAnswer] =
    useState<GoalResponseDto | null>(null);

  const [otherParticipantStandardAnswer, setOtherParticipantStandardAnswer] =
    useState<string | null>(null);
  const [otherParticipantBehaviourAnswer, setOtherParticipantBehaviourAnswer] =
    useState<BehaviourResponseDto | null>(null);
  const [otherParticipantGoalAnswer, setOtherParticipantGoalAnswer] =
    useState<GoalResponseDto | null>(null);

  const collapsibleLabel = t(
    "Pages.CollaborativeDocument.DualPrep.PlanningResponses"
  );
  const subjectParticipant = participants.find(
    (x) => x.userId == subjectUserId
  );
  const otherParticipant = participants.find((x) => x.userId != subjectUserId);

  const useFullNames =
    subjectParticipant?.firstName == otherParticipant?.firstName;
  let copyNamesResponses = t(
    "Pages.CollaborativeDocument.DualPrep.CopyNamesResponse"
  );
  const subjectParticipantCopyButtonText = copyNamesResponses.replace(
    "#NAME#",
    useFullNames ? subjectParticipant!.fullName : subjectParticipant!.firstName
  );
  const otherParticipantCopyButtonText = copyNamesResponses.replace(
    "#NAME#",
    useFullNames ? otherParticipant!.fullName : otherParticipant!.firstName
  );

  // --------------------------------------
  // |             Events                 |
  // --------------------------------------
  const toggleCollapsible = () => {
    setOpenCollapsible(!openCollapsible);

    // If we have no answers (standard, behaviour or goal) saved in state then we need to retrieve the planning responses
    if (
      subjectParticipantStandardAnswer === null &&
      otherParticipantStandardAnswer === null &&
      subjectParticipantBehaviourAnswer === null &&
      otherParticipantBehaviourAnswer === null &&
      subjectParticipantGoalAnswer === null &&
      otherParticipantGoalAnswer === null
    ) {
      setIsLoading(true);
      retrievePlanningResponses();
    }
  };

  const retrievePlanningResponses = () => {
    const onLoadSuccess = (json: PlanningResponsesApiResponseDto) => {
      setIsLoading(false);
      // Format both the subject and other users' responses into one singular text answer each for display
      const subjectUserResponse = FormatResponse(json.subjectUserResponse);
      const otherUserResponse = FormatResponse(json.otherUserResponse);

      // Build the menu items based on the answers availabile (if there is no 'other user' answer, we shouldn't get the option to copy it)
      const menuItems: DropDownMenuItemDto[] = [];
      if (
        subjectUserResponse != null ||
        json.subjectUserResponse.behaviours != null ||
        json.subjectUserResponse.goalReview != null
      ) {
        menuItems.push({
          text: subjectParticipantCopyButtonText,
          customArg: "SUBJECT",
        });
      }

      if (
        otherUserResponse != null ||
        json.otherUserResponse.behaviours != null ||
        json.otherUserResponse.goalReview != null
      ) {
        menuItems.push({
          text: otherParticipantCopyButtonText,
          customArg: "OTHER",
        });
      }

      if (
        (subjectUserResponse != null ||
          json.subjectUserResponse.behaviours != null ||
          json.subjectUserResponse.goalReview != null) &&
        (otherUserResponse != null ||
          json.otherUserResponse.behaviours != null ||
          json.otherUserResponse.goalReview != null)
      ) {
        menuItems.push({
          text: "Pages.CollaborativeDocument.DualPrep.CopyBothResponses",
          customArg: "BOTH",
        });
      }

      // Set various states
      setSubjectParticipantStandardAnswer(subjectUserResponse);
      setSubjectParticipantBehaviourAnswer(json.subjectUserResponse.behaviours);
      setSubjectParticipantGoalAnswer(json.subjectUserResponse.goalReview);
      setOtherParticipantStandardAnswer(otherUserResponse);
      setOtherParticipantBehaviourAnswer(json.otherUserResponse.behaviours);
      setOtherParticipantGoalAnswer(json.otherUserResponse.goalReview);
      setDropDownMenuItems(menuItems);
    };

    const onLoadError = (error: any) => {
      console.error("Planning response load error", error);
    };

    apiCollabDocs.getPlanningResponse(
      answerSetUniqueId!,
      subjectUserId,
      questionId,
      questionType,
      behaviourId,
      goalId,
      onLoadSuccess,
      onLoadError
    );
  };

  const FormatResponse = (response: UserResponseDto): string | null => {
    if (response.singleValue) {
      return GetAnswerOrTranslatedAnswer(response.singleValue);
    } else if (response.multiValues) {
      let output = [] as string[];

      response.multiValues.forEach((value) => {
        output.push(GetAnswerOrTranslatedAnswer(value));
      });

      return output.join(", ");
    } else {
      return null;
    }
  };

  const GetAnswerOrTranslatedAnswer = (value: ResponseDto): string => {
    if (value.useTranslations) {
      return t(value.answer);
    } else {
      return value.answer;
    }
  };

  // --------------------------------------
  // |         Drop Down Button           |
  // --------------------------------------
  const btnDropDownContents = (
    <div className="btn-secondary bg-white">
      <FontAwesomeIcon icon={faCopy} className="text-gray-500" />
      <span className="pl-1">{t("Common.Copy")}</span>
    </div>
  );

  const handleDropDownItemClick = (
    index: number,
    clickedItemArg: PlanningResponseButtonArgument
  ) => {
    const useFullNames =
      subjectParticipant?.firstName == otherParticipant?.firstName;
    const subjectName = useFullNames
      ? subjectParticipant!.fullName
      : subjectParticipant!.firstName;
    const subjectAnswer =
      subjectParticipantStandardAnswer != null
        ? subjectParticipantStandardAnswer
        : subjectParticipantBehaviourAnswer != null
        ? subjectParticipantBehaviourAnswer.comment
        : subjectParticipantGoalAnswer != null
        ? subjectParticipantGoalAnswer.comment
        : null;
    const otherName = useFullNames
      ? otherParticipant!.fullName
      : otherParticipant!.firstName;
    const otherAnswer =
      otherParticipantStandardAnswer != null
        ? otherParticipantStandardAnswer
        : otherParticipantBehaviourAnswer != null
        ? otherParticipantBehaviourAnswer.comment
        : otherParticipantGoalAnswer != null
        ? otherParticipantGoalAnswer.comment
        : null;

    let copyValue = "";
    switch (clickedItemArg) {
      case "SUBJECT":
        copyValue = subjectName + ": " + subjectAnswer;
        break;
      case "OTHER":
        copyValue = otherName + ": " + otherAnswer;
        break;
      case "BOTH":
        copyValue =
          subjectName +
          ": " +
          subjectAnswer +
          "\n\n" +
          otherName +
          ": " +
          otherAnswer;
        break;
    }

    if (onCopyClick) {
      if (behaviourId) {
        onCopyClick(copyValue, formId, behaviourId, null);
      } else if (goalId) {
        onCopyClick(copyValue, formId, null, goalId);
      } else {
        onCopyClick(copyValue, formId, null, null);
      }
    }
  };

  return (
    <div>
      <Collapsible
        label={collapsibleLabel}
        open={openCollapsible}
        setOpen={toggleCollapsible}
      >
        {isLoading && (
          <div className="py-2">
            <SmallLoader />
            <p className="text-center pt-1 text-[#959595] text-sm">
              {t("Common.Loading")}...
            </p>
          </div>
        )}

        {!isLoading && (
          <>
            {/* Show for Desktop/Hide for mobile */}
            <DesktopResponsesView
              subjectParticipant={subjectParticipant!}
              subjectParticipantStandardAnswer={
                subjectParticipantStandardAnswer
              }
              subjectParticipantBehaviourAnswer={
                subjectParticipantBehaviourAnswer
              }
              subjectParticipantGoalAnswer={subjectParticipantGoalAnswer}
              otherParticipant={otherParticipant!}
              otherParticipantStandardAnswer={otherParticipantStandardAnswer}
              otherParticipantBehaviourAnswer={otherParticipantBehaviourAnswer}
              otherParticipantGoalAnswer={otherParticipantGoalAnswer}
            />

            {/* Hide for Desktop/Show for mobile */}
            <MobileResponsesView
              subjectParticipant={subjectParticipant!}
              subjectParticipantStandardAnswer={
                subjectParticipantStandardAnswer
              }
              subjectParticipantBehaviourAnswer={
                subjectParticipantBehaviourAnswer
              }
              subjectParticipantGoalAnswer={subjectParticipantGoalAnswer}
              otherParticipant={otherParticipant!}
              otherParticipantStandardAnswer={otherParticipantStandardAnswer}
              otherParticipantBehaviourAnswer={otherParticipantBehaviourAnswer}
              otherParticipantGoalAnswer={otherParticipantGoalAnswer}
            />

            {/* Shows the 'Copy' button for text questions */}
            {(questionType === "SHORTTEXT" ||
              questionType === "LONGTEXT" ||
              (questionType === "BEHAVIOUR" &&
                ((subjectParticipantBehaviourAnswer &&
                  subjectParticipantBehaviourAnswer.comment != null) ||
                  (otherParticipantBehaviourAnswer &&
                    otherParticipantBehaviourAnswer.comment != null))) ||
              (questionType === "GOAL-REVIEW-STATUS-COMMENT" &&
                ((subjectParticipantGoalAnswer &&
                  subjectParticipantGoalAnswer.comment != null) ||
                  (otherParticipantGoalAnswer &&
                    otherParticipantGoalAnswer.comment != null)))) && (
              <div className="text-right pt-2 pb-1">
                <DropDownMenu
                  eventType="EVENT"
                  items={dropDownMenuItems}
                  onItemClick={handleDropDownItemClick}
                  customButtonContents={btnDropDownContents}
                  menuButtonClassName=""
                />
              </div>
            )}
          </>
        )}
      </Collapsible>
    </div>
  );
}

export default PlanningResponses;
