import { Link } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import {
  ManagerDashboardPersonDto,
  ManagerDashboardUpdateDto,
} from "../../types/dtos/dashboards";
import IndividualUpdate from "./IndividualUpdate";
import { AppraisalLevelClientTaskTypeDto } from "../../types/dtos/users/AppraisalLevelClientTaskTypeDto";

interface AdvancedTasksUpdateNotificationProps {
  update: ManagerDashboardUpdateDto;
  users: ManagerDashboardPersonDto[];
  taskTypes: AppraisalLevelClientTaskTypeDto[];
}

export const AdvancedTasksUpdateNotification = ({
  update,
  users,
  taskTypes,
}: AdvancedTasksUpdateNotificationProps) => {
  const matchingUserAppraisalLevel = users.find(
    (user) => user.userId === update.userId
  )?.appraisalLevelId;
  if (!matchingUserAppraisalLevel) return null;

  const appLvlTaskTypes = taskTypes.find(
    (taskType) => taskType.appraisalLevelId === matchingUserAppraisalLevel
  )?.taskTypes;
  if (!appLvlTaskTypes) return null;

  const matchingTaskType = appLvlTaskTypes.find(
    (taskType) => taskType.id === update.advancedTaskTypeId
  );
  if (!matchingTaskType) return null;

  return (
    <Link
      to={AppRoutes.yourPeople.tasks.generatePathWithPreselectedEmployeeIdRoute(
        update.advancedTaskTypeId!,
        update.userId
      )}
      className="cursor-pointer flex flex-row"
    >
      <IndividualUpdate
        users={users}
        update={update}
        taskType={matchingTaskType}
      />
    </Link>
  );
};
