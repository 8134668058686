import { t } from "i18next";
import DelegatedTag, { ViewingActor } from "./DelegatedTag";
import UserTag from "./UserTag";

interface UserTagListProps {
  jobTitle?: string | null;
  jobTitleTooltip?: string;
  appraisalLevel?: string | null;
  appraisalLevelTooltip?: string;
  location?: string | null;
  locationTooltip?: string;
  viewingActor?: ViewingActor;
  managerOrDelegatedName?: string;
}

const UserTagList = ({
  jobTitle,
  jobTitleTooltip,
  appraisalLevel,
  appraisalLevelTooltip,
  location,
  locationTooltip,
  viewingActor,
  managerOrDelegatedName
}: UserTagListProps) => {
  return (
    <div>
      {location && (
        <UserTag tooltip={locationTooltip} text={location} />
      )}
      {jobTitle && (
        <UserTag tooltip={jobTitleTooltip} text={t(jobTitle)} />
      )}
      {appraisalLevel && (
        <UserTag tooltip={appraisalLevelTooltip} text={t(appraisalLevel)} />
      )}
      {viewingActor && managerOrDelegatedName && (
        <DelegatedTag viewingActor={viewingActor} name={managerOrDelegatedName} />
      )}
    </div>
  );
};

export default UserTagList;
