
import { t } from "i18next";
import { AdvancedTaskStatus } from "../../../../types/dtos/tasks/advanced-tasks/AdvancedTaskStatus";
import { FormType } from "../../../../types/forms";
import AdvancedTaskEnforcedCommentDto from "../../../../types/dtos/forms/AdvancedTaskEnforcedCommentDto";
import cx from "classnames";
import {
  faComments,
  faCheck,
  faBell
} from "@fortawesome/pro-regular-svg-icons";
import { TaskManagementDropDownMenuArgs } from "../../../../types/tasks/TaskManagementDropDownMenuArgs";
import EnforcedCommentsBadge from "./EnforcedCommentsBadge";
import { ClientTaskType } from "../../../../types/dtos/tasks/advanced-tasks/ClientTaskType";
import AdvancedTaskDto from "../../../../types/dtos/forms/AdvancedTaskDto";

interface EnforcedCommentsSectionProps {
  task: AdvancedTaskDto;
  taskType: ClientTaskType;
  formType: FormType;
  status: AdvancedTaskStatus;
  details: AdvancedTaskEnforcedCommentDto;
  contentClassName?: string;
  loggedInUserId: number;
  isLoggedInUserTheSubjectUser: boolean;
  subjectUsersName: string;
  nonSubjectUsersName: string;
  onItemAction: (userTaskId: string, action: string) => void;
}

function EnforcedCommentsSection({
  task,
  taskType,
  formType,
  status,
  details,
  contentClassName = "",
  loggedInUserId,
  isLoggedInUserTheSubjectUser,
  subjectUsersName,
  nonSubjectUsersName,
  onItemAction,
}: EnforcedCommentsSectionProps) {
  const showTaskDetails = () => {
    onItemAction(task.taskId, TaskManagementDropDownMenuArgs.ViewDetails);
  }

  const showTaskComments = () => {
    onItemAction(task.taskId, TaskManagementDropDownMenuArgs.AddComment);
  }

  const showReviewForm = () => {
    onItemAction(task.taskId, TaskManagementDropDownMenuArgs.ReviewTask);
  }

  return (
    <>
      {/* Journey Display */}
      {formType === "JOURNEY" && (
        <div className={cx(
          "flex flex-row",
          contentClassName
        )}>
          {/* Completed within the journey */}
          {status === "COMPLETED"
            && task.completionOrigin === "JOURNEY-COLLAB-DOC" && (
              <EnforcedCommentsBadge
                text={t("TaskType.EnforceComments.MarkedAsCompleted")}
                icon={faCheck}
                backgroundColourClassName="bg-green-200/50"
                textClassName="text-green-600 cursor-pointer"
                onClick={showTaskDetails}
              />
            )}
          {/* Not completed OR Completed outside of the journey */}
          {(status !== "COMPLETED"
            || status === "COMPLETED" && task.completionOrigin !== "JOURNEY-COLLAB-DOC") && (
              <>
                {/* Has no comments */}
                {details.ownerCount === 0 && (
                  <EnforcedCommentsBadge
                    text={t("TaskType.EnforceComments.PleaseCommentOnYourProgress")}
                    icon={faComments}
                    backgroundColourClassName="bg-red-200/50"
                    textClassName="text-red-600 cursor-pointer"
                    onClick={showTaskComments}
                  />
                )}
                {/* Has added comments */}
                {details.ownerCount > 0 && (
                  <EnforcedCommentsBadge
                    text={details.ownerCount === 1
                      ? t("TaskType.EnforceComments.YouAddedAComment")
                      : t("TaskType.EnforceComments.YouAddedXComments", {
                        count: details.ownerCount
                      })}
                    icon={faComments}
                    backgroundColourClassName="bg-green-200/50"
                    textClassName="text-green-600 cursor-pointer"
                    onClick={showTaskComments}
                  />
                )}
              </>
            )}
        </div>
      )}

      {/* Collab Doc Display */}
      {formType === "COLLAB-DOC" && (
        <div className={cx(
          "flex flex-row",
          contentClassName
        )}>
          {/* Owner Badge */}
          <div>
            {/* Completed */}
            {status === "COMPLETED" && (
              <EnforcedCommentsBadge
                text={t("TaskType.EnforceComments.MarkedAsCompleted")}
                icon={faCheck}
                backgroundColourClassName="bg-green-200/50"
                textClassName="text-green-600 cursor-pointer"
                onClick={showTaskDetails}
              />
            )}
            {/* Not completed */}
            {status !== "COMPLETED" && (
              <>
                {/* Has no comments */}
                {details.ownerCount === 0 && (
                  <EnforcedCommentsBadge
                    text={isLoggedInUserTheSubjectUser
                      ? t("TaskType.EnforceComments.PleaseCommentOnYourProgress")
                      : t("TaskType.EnforceComments.PleaseCommentOnXProgress", {
                        name: subjectUsersName
                      })}
                    icon={faComments}
                    backgroundColourClassName="bg-red-200/50"
                    textClassName="text-red-600 cursor-pointer"
                    onClick={showTaskComments}
                  />
                )}
                {/* Has added comments */}
                {details.ownerCount > 0 && (
                  <EnforcedCommentsBadge
                    text={isLoggedInUserTheSubjectUser
                      ? details.ownerCount === 1
                        ? t("TaskType.EnforceComments.YouAddedAComment")
                        : t("TaskType.EnforceComments.YouAddedXComments", {
                          count: details.ownerCount
                        })
                      : details.ownerCount === 1
                        ? t("TaskType.EnforceComments.XAddedAComment", {
                          name: subjectUsersName
                        })
                        : t("TaskType.EnforceComments.XAddedXComments", {
                          name: subjectUsersName,
                          count: details.ownerCount
                        })}
                    icon={faComments}
                    backgroundColourClassName="bg-green-200/50"
                    textClassName="text-green-600 cursor-pointer"
                    onClick={showTaskComments}
                  />
                )}
              </>
            )}
          </div>

          {/* ManagerRole Badge */}
          <>
            {/* If completed, and taskType has a review form, then show review badge */}
            {status === "COMPLETED"
              && taskType.reviewFormId != null
              && (
                <>
                  {/* Manager hasn't provided a comment */}
                  {!task.enforcedComments.areManagerRoleCountConditionsSatisfied && (
                    <div>
                      <EnforcedCommentsBadge
                        text={isLoggedInUserTheSubjectUser
                          ? t("TaskType.EnforceComments.XNeedsToCommentOnYourProgress", {
                            name: nonSubjectUsersName
                          })
                          : t("TaskType.EnforceComments.PleaseCommentOnNamesProgress", {
                            name: subjectUsersName
                          })}
                        icon={faComments}
                        backgroundColourClassName="bg-red-200/50"
                        textClassName="text-red-600 cursor-pointer"
                        onClick={showTaskComments}
                      />
                    </div>
                  )}

                  {/* Manager has provided a comment */}
                  {task.enforcedComments.areManagerRoleCountConditionsSatisfied && (
                    <div>
                      {!task.hasReviewFormResponse && (
                        <EnforcedCommentsBadge
                          text={t("TaskType.EnforceComments.YouNeedToReviewThisGoal")}
                          icon={faBell}
                          backgroundColourClassName="bg-red-200/50"
                          textClassName="text-red-600 cursor-pointer"
                          onClick={showReviewForm}
                        />
                      )}

                      {task.hasReviewFormResponse && (
                        <EnforcedCommentsBadge
                          text={
                            task.reviewFormLastModifiedByEmployeeId
                              && task.reviewFormLastModifiedByEmployeeId === loggedInUserId
                              ? t("TaskType.EnforceComments.LastReviewedByYou")
                              : t("TaskType.EnforceComments.LastReviewedByX", {
                                name: task.reviewFormLastModifiedByEmployeeName
                              })
                          }
                          icon={faBell}
                          backgroundColourClassName="bg-green-200/50"
                          textClassName="text-green-600 cursor-pointer"
                          onClick={showReviewForm}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            {/* Not Completed */}
            {status !== "COMPLETED" && (
              <div>
                <>
                  {/* Has no comments */}
                  {details.managerRoleCount === 0 && (
                    <EnforcedCommentsBadge
                      text={isLoggedInUserTheSubjectUser
                        ? t("TaskType.EnforceComments.XNeedsToCommentOnYourProgress", {
                          name: nonSubjectUsersName
                        })
                        : t("TaskType.EnforceComments.PleaseCommentOnNamesProgress", {
                          name: subjectUsersName
                        })}
                      icon={faComments}
                      backgroundColourClassName="bg-red-200/50"
                      textClassName="text-red-600 cursor-pointer"
                      onClick={showTaskComments}
                    />
                  )}
                  {/* Has added comments */}
                  {details.managerRoleCount > 0 && (
                    <EnforcedCommentsBadge
                      text={isLoggedInUserTheSubjectUser
                        ? details.managerRoleCount === 1
                          ? t("TaskType.EnforceComments.XAddedAComment", {
                            name: nonSubjectUsersName
                          })
                          : t("TaskType.EnforceComments.XAddedXComments", {
                            name: nonSubjectUsersName,
                            count: details.managerRoleCount
                          })
                        : details.managerRoleCount === 1
                          ? t("TaskType.EnforceComments.YouAddedAComment")
                          : t("TaskType.EnforceComments.YouAddedXComments", {
                            count: details.managerRoleCount
                          })}
                      icon={faComments}
                      backgroundColourClassName="bg-green-200/50"
                      textClassName="text-green-600 cursor-pointer"
                      onClick={showTaskComments}
                    />
                  )}
                </>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
}

export default EnforcedCommentsSection;
