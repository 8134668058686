import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import cx from "classnames";
//@ts-ignore
import Pagination from "react-js-pagination";
import AnalyticsFilterInputs from "../../../types/analytics/AnalyticsFilterInputs";
import {
  AnalyticsTableColumnMeta,
  AnalyticsTableModelPropertyMeta,
  PagedAnalyticsTableDataDto,
} from "../../../types/analytics/tables/AnalyticsTables";
import { AnalyticsLoadingSpinner } from "../AnalyticsLoadingSpinner";
import analyticsCustomEndpointApi from "../../../api/analytics/analyticsCustomEndpointApi";
import { useAuth } from "react-oidc-context";
import IAnalyticsDataItem from "../../../types/analytics/IAnalyticsDataItem";
import { DataRowWithEmployeeDetails } from "../../../types/analytics/tables/DataRowWithEmployeeDetails";
import { GroupedJourneys } from "../../../types/analytics/FilterAvailableValues";
import { FormattedDate } from "../../common";
import { ChartLegendItem } from "../../../types/analytics/AnalyticsWidgetUiDetailsDto";
import { KeyValuePair } from "../../../types/generic";
import TinyLoader from "../../loaders/TinyLoader";
import UserContext from "../../../state/UserContext";
import SearchField from "../../common/SearchField";
import { Link } from "react-router-dom";

type AnalyticsDataTableProps = {
  apiUrl: string;
  filters: AnalyticsFilterInputs;
  journeys: GroupedJourneys[];
  legendItems: ChartLegendItem[] | null;
  /** Allows passing of custom params to the API which loads the table data */
  otherApiParams: KeyValuePair<string, string>[] | null;
};

interface TableRowData {
  employeeId: number;
  data: TableRowDataCell[];
}

interface TableRowDataCell {
  columnName: string;
  displayValue: React.ReactNode;
  classNames: string | undefined;
}

interface TableSearchPageDetails {
  pageNumber: number;
  searchPhrase: string | null;
}

const getHorizontalAlignmentClassName = (
  column: AnalyticsTableColumnMeta | undefined
) => {
  if (
    !column ||
    column.dataType === "TRANSLATED-STRING" ||
    column.dataType === "RAW-STRING" ||
    column.dataType === "RAW-STRING-MULTI" ||
    column.dataType === "JOURNEY-NAME"
  )
    return "text-left";

  return "text-center";
};

const getTableCellValue = (
  row: DataRowWithEmployeeDetails<IAnalyticsDataItem>,
  column: AnalyticsTableColumnMeta,
  columns: AnalyticsTableColumnMeta[],
  modelMeta: AnalyticsTableModelPropertyMeta[],
  journeys: KeyValuePair<string, string>[],
  legendItems: ChartLegendItem[] | null
) => {
  const matchingCol = columns.find((x) => x.name === column.name);
  if (!matchingCol) return null;

  const cellClassNames = getHorizontalAlignmentClassName(matchingCol);

  let rawDisplayValue: string | Array<string> = "";
  const matchingModelProperty = modelMeta.find(
    (x) => x.columnKey === column.name
  );
  if (matchingModelProperty) {
    if (matchingModelProperty.objectKey.startsWith("data.")) {
      rawDisplayValue = (row.data as any)[
        matchingModelProperty.objectKey.substring(5)
      ];
    } else {
      rawDisplayValue = (row as any)[matchingModelProperty.objectKey];
    }
  }

  let outputDisplayValue: string | React.ReactNode;
  // If the item maps to a legend item, try to find it so we can transform an enum to a translation/string value
  if (column.mapToLegendItems && legendItems != null) {
    const matchingLegendItem = legendItems.find(
      (x) => x.key === rawDisplayValue
    );
    if (matchingLegendItem) {
      outputDisplayValue = matchingLegendItem.translatable
        ? t(matchingLegendItem.textValue)
        : matchingLegendItem.textValue;
    }
  } else {
    switch (column.dataType) {
      case "TRANSLATED-STRING":
        outputDisplayValue = t(rawDisplayValue);
        break;
      case "DATE":
        if (rawDisplayValue) {
          outputDisplayValue = (
            <FormattedDate
              date={new Date(rawDisplayValue as string)}
              displayMode="DATE-ONLY"
            />
          );
        }
        break;
      case "JOURNEY-NAME":
        const matchedJourney = journeys.find((x) => x.key === rawDisplayValue);
        if (matchedJourney) {
          outputDisplayValue = matchedJourney.value;
        }
        break;
      case "RAW-STRING-MULTI":
        outputDisplayValue = (
          <>
            {Array.isArray(rawDisplayValue) &&
              rawDisplayValue.map((x: string, ix: number) => (
                <div key={`${x}_${ix}`}>{x}</div>
              ))}
            {!Array.isArray(rawDisplayValue) && <div>{rawDisplayValue}</div>}
          </>
        );
        break;
      case "HYPERLINK":
        if (rawDisplayValue && !Array.isArray(rawDisplayValue)) {
          outputDisplayValue = (
            <>
              <Link to={rawDisplayValue} target="_blank">
                <span className="py-1 px-4 text-gray-600 bg-white border rounded hover:bg-gray-50">
                  {t("Common.View")}
                </span>
              </Link>
            </>
          );
        }
        break;
      default:
        outputDisplayValue = rawDisplayValue;
        break;
    }
  }

  return {
    displayValue: outputDisplayValue,
    cellClassNames,
  };
};

export const AnalyticsDataTable = ({
  apiUrl,
  filters,
  journeys,
  legendItems,
  otherApiParams,
}: AnalyticsDataTableProps) => {
  const auth = useAuth();
  const userContext = useContext(UserContext);
  const api = new analyticsCustomEndpointApi(auth.user?.access_token);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSearchDetails, setPageSearchDetails] =
    useState<TableSearchPageDetails>({ pageNumber: 1, searchPhrase: null });
  const [journeyTitles, setJourneyTitles] = useState<
    KeyValuePair<string, string>[]
  >([]);
  const [modelMeta, setModelMeta] = useState<AnalyticsTableModelPropertyMeta[]>(
    []
  );
  const [columns, setColumns] = useState<AnalyticsTableColumnMeta[]>([]);
  const pageSize = 10;
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [rowData, setRowData] = useState<TableRowData[]>([]);

  const hasSearchValue =
    pageSearchDetails.searchPhrase !== null &&
    pageSearchDetails.searchPhrase.trim().length > 0;

  useEffect(() => {
    loadData();
  }, [pageSearchDetails]);

  useEffect(() => {
    // Create a KeyValuePair<string, string> where the Key is the Journey Guid, and
    // the Value is the translated Journey title (or not translated, for ClientSent journeys)
    const groupedJourneyTitles = journeys.flatMap(({ groupType, journeys }) =>
      journeys.map((journey) => ({
        guids: journey.guids,
        title: groupType === "CLIENT-SENT" ? journey.title : t(journey.title),
      }))
    );

    const individualJourneyGuidTitles = groupedJourneyTitles.flatMap(
      (journey) =>
        journey.guids.map((guid) => ({ key: guid, value: journey.title }))
    );

    setJourneyTitles(individualJourneyGuidTitles);
  }, [journeys]);

  const handleSearchChange = (newSearchPhrase: string | null) => {
    // Reset page number too
    setPageSearchDetails({ pageNumber: 1, searchPhrase: newSearchPhrase });
  };

  const handlePageChange = (pageNumber: number) => {
    setPageSearchDetails({ ...pageSearchDetails, pageNumber });
  };

  const transformTableData = (
    newRowData: DataRowWithEmployeeDetails<IAnalyticsDataItem>[],
    newColumns: AnalyticsTableColumnMeta[],
    newModelMeta: AnalyticsTableModelPropertyMeta[]
  ) => {
    // Take each row and transform it to a display value
    const tableData = newRowData.map((row) => {
      const newRow: TableRowData = {
        employeeId: row.employeeId,
        data: [],
      };

      newColumns.forEach((col) => {
        const cellValue = getTableCellValue(
          row,
          col,
          newColumns,
          newModelMeta,
          journeyTitles,
          legendItems
        );
        newRow.data.push({
          columnName: col.name,
          displayValue: cellValue?.displayValue,
          classNames: cellValue?.cellClassNames,
        });
      });
      return newRow;
    });

    // Remove any columns which don't have any data in them
    const nonEmptyColumns: AnalyticsTableColumnMeta[] = [];
    newColumns.forEach((col) => {
      const nonEmptyCell = tableData.find((row) =>
        row.data.find(
          (item) =>
            item.columnName === col.name &&
            item.displayValue !== null &&
            item.displayValue !== undefined
        )
      );
      if (nonEmptyCell) {
        nonEmptyColumns.push(col);
      }
    });

    setColumns(nonEmptyColumns);
    setRowData(tableData);
    setModelMeta(newModelMeta);
  };

  function loadData() {
    const onLoadError = (error: any) => {
      setIsLoading(false);
      setColumns([]);
      setModelMeta([]);
      setRowData([]);
      setTotalItemCount(0);
      if (!hasSearchValue) {
        console.error("Table load error", error);
      }
    };

    const onLoadSuccess = (
      data: PagedAnalyticsTableDataDto<IAnalyticsDataItem>
    ) => {
      setIsLoading(false);
      setTotalItemCount(data.totalItemCount);
      transformTableData(data.rows, data.meta.columns, data.meta.model);
    };

    // Load the paged table data from the API
    setIsLoading(true);
    api.getUiTableData(
      apiUrl,
      filters,
      pageSearchDetails.searchPhrase,
      pageSearchDetails.pageNumber,
      pageSize,
      otherApiParams,
      onLoadSuccess,
      onLoadError
    );
  }

  if (isLoading && totalItemCount === 0 && !hasSearchValue) {
    // Initial load
    return <AnalyticsLoadingSpinner />;
  }

  const noResultsLoaded = !isLoading && totalItemCount === 0;

  return (
    <>
      <SearchField
        onSearch={handleSearchChange}
        isLoading={isLoading}
        loaderColor={userContext.user.client.accentHexColour}
      />
      {noResultsLoaded && (
        <em className="block text-center italic py-4">
          {t("Pages.Analytics.Common.NoTableData")}
        </em>
      )}
      {!noResultsLoaded && (
        <>
          <div className="w-full overflow-x-auto">
            <table className="datatable mt-2 mb-4">
              <thead>
                <tr>
                  {columns.map((col) => {
                    const classNames = getHorizontalAlignmentClassName(col);
                    return (
                      <th
                        className={cx(classNames, "py-1 px-2")}
                        key={col.name}
                      >
                        {t(col.name)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {rowData.map((row, rowIx) => (
                  <tr key={`${row.employeeId}_${rowIx}`}>
                    {columns.map((col) => {
                      const cell = row.data.find(
                        (x) => x.columnName === col.name
                      );
                      if (!cell) return null;
                      return (
                        <td
                          key={col.name}
                          className={cx(
                            cell.classNames,
                            "border-b-[1px] border-gray-200 py-1 px-2"
                          )}
                        >
                          {cell.displayValue}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalItemCount > pageSize && (
            <div className="flex flex-row mb-4">
              <div>
                <Pagination
                  activePage={pageSearchDetails.pageNumber}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={totalItemCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  activeClass="pagination-active-list-item"
                  itemClass="pagination-list-item"
                  itemClassFirst="pagination-first-item"
                  itemClassLast="pagination-last-item"
                />
              </div>
              {isLoading && (
                <div className="pl-4">
                  <TinyLoader
                    colour={userContext.user.client.accentHexColour}
                  />
                  <span className="ml-1 text-gray-500 text-sm">
                    {t("Common.Loading")}...
                  </span>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AnalyticsDataTable;
