import React from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../state/UserContext";
import { useMatch, useNavigate } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import { ClientFormGroupDto } from "../../types/dtos/admin/JourneyConfigurationTabApiResponseDto";
import ClientFormsGrid from "../../components/admin/Home/ClientFormsGrid";
import SuccessAlert from "../../components/alerts/SuccessAlert";
import { ReactComponent as NoFilesImage } from "../../images/noFiles.svg";
import SmallLoader from "../../components/loaders/SmallLoader";

interface JourneyConfigurationTabState {
  isLoading: boolean;
  clientFormGroups: ClientFormGroupDto[] | undefined;
}

function JourneyConfigurationTab({
  isLoading,
  clientFormGroups
}: JourneyConfigurationTabState) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const hasUserComeFromSendNowComplete = useMatch({
    path: AppRoutes.admin.journeys.pathRedirectFromSendAfterSuccess,
    end: true,
  });

  return (
    <div>
      {isLoading && (
        <div className="mt-8 text-center">
          <SmallLoader colour={userContext.user.client.primaryHexColour} />
          <span className="block mt-2 text-gray-600 text-sm">
            {t("Common.Loading") + "..."}
          </span>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="flex flex-col pt-4">
            <div className="flex flex-row-reverse gap-2">
              <button
                className="btn-primary block mr-4"
                onClick={() => navigate(AppRoutes.admin.journeys.sendingHistory)}
              >
                <span>{t("Pages.Admin.Buttons.SendingHistory")}</span>
              </button>
            </div>
          </div>
          {hasUserComeFromSendNowComplete && (
            <div className="pt-3">
              <SuccessAlert
                prefix={t("Common.Success")}
                message={t("Common.Validation.Successful.UsersWillReceiveShortly")}
              />
            </div>
          )}

          {clientFormGroups != undefined && clientFormGroups.length > 0 && (
            <ClientFormsGrid
              clientFormGroups={clientFormGroups}
              isLoading={isLoading}
            />
          )}

          {clientFormGroups != undefined && clientFormGroups.length === 0 && !isLoading && (
            <div className="flex justify-center flex-col text-center text-gray-400 mb-4 pt-3">
              <div className="flex flex-row">
                <p className="w-1/3">&nbsp;</p>
                <NoFilesImage
                  className="justify-center w-1/3"
                  width={"16rem"}
                  height={"16rem"}
                />
                <p className="w-1/3">&nbsp;</p>
              </div>
              <p className="pt-3">{t("Pages.Admin.EmptyState.NothingToView")}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default JourneyConfigurationTab;
