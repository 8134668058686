import { t } from "i18next";
import { UserContextInterface } from "../state/UserContext";
import { BaseUserDetailsDto } from "../types/dtos/generic";
import { ActorQuestionText, FormQuestion } from "../types/forms";
import TextToken from "../types/generic/TextToken";

const subjectUserToken: TextToken = "#SUBJECT_NAME#";
const otherParticipantToken: TextToken = "#OTHER_PARTICIPANT_NAME#";
const clientNameToken: TextToken = "#CLIENT#";

export const questionTextHelper = {
  /** Get the translated question text for the logged in user, with the subject name and client name tokens replaced for the appropriate values */
  getQuestionText: function (
    question: FormQuestion,
    subjectUser: BaseUserDetailsDto,
    participants: BaseUserDetailsDto[],
    loggedInUser: UserContextInterface
  ): string {
    if (question.questionType === "BEHAVIOUR") {
      if (question.behaviourOptions) {
        return t(question.behaviourOptions!.behaviour.value);
      }
    }

    return this.getActorText(
      question.questionText,
      subjectUser,
      participants,
      loggedInUser
    );
  },
  /** Get the correct, translated text for the logged in user, with the subject name and client name tokens replaced for the appropriate values */
  getActorText: function (
    actorText: ActorQuestionText,
    subjectUser: BaseUserDetailsDto,
    participants: BaseUserDetailsDto[],
    loggedInUser: UserContextInterface
  ) {
    if (!participants || participants.length === 0) {
      return actorText.employee;
    }

    const otherUser = participants.find((x) => x.userId !== subjectUser.userId);

    const untranslatedText =
      subjectUser!.userId === loggedInUser.user.id
        ? actorText.employee
        : actorText.manager;

    let output = t(untranslatedText);
    output = output.replace(subjectUserToken, subjectUser!.firstName);
    output = output.replace(clientNameToken, loggedInUser.user.client.name);

    if (otherUser) {
      output = output.replace(otherParticipantToken, otherUser.firstName);
    }

    return output;
  },
  /** Replace any tokens within the form intro message text */
  replaceTokensInFormIntros: function (
    text: string,
    loggedInUser: UserContextInterface,
    otherUser: BaseUserDetailsDto | null | undefined
  ) {
    let output = text;
    output = output.replace(subjectUserToken, loggedInUser.user.firstName);
    output = output.replace(clientNameToken, loggedInUser.user.client.name);
    if (otherUser) {
      output = output.replace(otherParticipantToken, otherUser.firstName);
    }
    return output;
  },
};

export default questionTextHelper;
